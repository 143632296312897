import React, { useState, useEffect } from 'react'
import { Outlet } from "react-router-dom"
import useRefreshToken from "../hooks/useRefreshToken"
import { authSelector } from "../features/authSlice"
import { useSelector } from "react-redux"
import Loading from './Loading'
const PersistLogin = () => {
    const auth = useSelector(authSelector);
    const [isLoading, setIsLoading] = useState(true)
    const refresh = useRefreshToken();

    useEffect(() => {

        let isMounted = true
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (error) {
                console.error(error)
            } finally {
                isMounted && setIsLoading(false);
            }
        }
        !auth?.token ? verifyRefreshToken() : setIsLoading(false);

        return ()=> isMounted = false
    }, [])

    useEffect(() => {
        console.log(`isLoading ${isLoading}`)
        console.log(`at ${auth.token}`)
    }, [isLoading])

    return (
        <>
            {isLoading
                ? <Loading /> : <Outlet />
            }
        </>
    )

}
export default PersistLogin