import React from 'react'
import { Line } from 'react-chartjs-2';

function TicketNotHandledLine({data}) {
    const chartData = {
        labels: Object?.keys(data).length > 0 ? Object?.keys(data) : ["2023-01-01"],
        datasets: [
            {
                label: 'Card Count',
                data: Object?.keys(data).length > 0 ? Object?.values(data) : [0],
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
        ],
    };

    const chartOptions = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                },
                title: {
                    display: true,
                    text: 'Date',
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Nombre',
                },
            },
        },
    };

    return (
        <Line data={chartData} options={chartOptions} />
    )
}

export default TicketNotHandledLine
