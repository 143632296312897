import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './app/store';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import './i18n'
import 'antd/dist/antd.min.css';
import { RecoilRoot } from 'recoil';
const root = ReactDOM.createRoot(document.getElementById('root'));
if (process.env.NODE_ENV === 'production') {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
    console.warn = () => { }
}
root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <RecoilRoot>
                <Suspense >
                    <App />
                </Suspense>
            </RecoilRoot>
        </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
