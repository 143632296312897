import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import DashboardCard from './cards/DashboardCard';
import MyLineCapital from './chart/MyLineCapital';
import MyLineQty from './chart/MyLineQty';
import Loading from './Loading';
import * as service from "../utils/service"
import Controls from './controls/Controls';


function Evolution(props) {
    const { id } = props
    const [dataCapital, setDataCapital] = useState();
    const [dataAf, setDataAf] = useState();
    const axiosPrivate = useAxiosPrivate()
    const { t } = useTranslation()

    const [values, setValues] = useState({
        actifK: 5000,
        totalK: 1000,
        reserveK: 0,
        recurringK: 150,
    });

    const [summaryRebal, setSummaryRebal] = useState({
        startDate: "DD/MM/YYYY",
        endDate: "DD/MM/YYYY",
        endCapitalRebal: "0.00",
        startCapital: "0.00",
        recurringAmount: "0.00",
    });
    const [addedK, setAddedK] = useState(0);
    const [totalInvested, setTotalInvested] = useState(0);
    const [graphType, setGraphType] = useState("logarithmic");




    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();


        const getAf = async () => {
            try {
                const response = await axiosPrivate.get(`/api/rebalancing/findBy/af/${id}`, {
                    signal: controller.signal,
                });
                isMounted && setDataAf(response.data);

                console.log("oooooooo===============", response.data);

                isMounted && setDataCapital(response.data.filter((f) => f.capital !== null));
            } catch (error) {
                console.log("here", error);
            }
        };

        const getUser = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/users/genericFindOne/${id}`, {
                    signal: controller.signal,
                });
                const decrypt = service.decryptOneUserInfo(data.oneUser);

                // console.log("test", data);

                if (decrypt.rebalancing.length > 0) {
                    isMounted &&
                        setSummaryRebal({
                            startDate: decrypt.rebalancing[0]?.date,
                            endDate: decrypt.rebalancing[decrypt.rebalancing.length - 1]?.date,
                            endCapitalRebal: JSON.parse(decrypt.rebalancing[decrypt.rebalancing.length - 1].totalAssets).usdt || 0,
                            startCapital: Number(JSON.parse(decrypt.rebalancing[0].totalAssets).usdt).toFixed(2) || 0,
                            recurringAmount: Number(decrypt.details.recurringAmount).toFixed(2) || 0,
                        });
                    console.log("Start K", Number(JSON.parse(decrypt.rebalancing[0].totalAssets).usdt).toFixed(2));
                }
                //dispacth(getUserDetails(data));
            } catch (error) {
                console.log("error", error);
                // if (error.response.status != 403) {
                //     navigate("/login", { state: { from: location }, replace: true });
                // }
            }
        };

        const getTotalInvested = async () => {
            try {
                const response = await axiosPrivate.get(`/api/users/totalInvested/${id}`, {
                    signal: controller.signal,
                });
                if (response.data) {
                    console.log("Total Invested", response.data);
                }
                const seuil = response.data.totalInvested;
                setTotalInvested(seuil);
                setAddedK(response.data.addedK);
                console.log("Added K", response.data.added);
            } catch (error) { }
        };


        getAf()
        getUser()
        getTotalInvested()

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [id])

    return (
        <>
            <div className="flex flex-wrap justify-end gap-x-2 my-2">
                <DashboardCard
                    bg={"bg-blue-600"}
                    description={t(`label.initialCapital`)} // Capital Initial
                    number={summaryRebal && summaryRebal?.startCapital}
                    unit={"USDT"}
                />

                {/* <DashboardCard
                bg={"bg-blue-500"}
                description={t(`label.capitalRecurrent`)} // Capital Réccurent
                number={summaryRebal && summaryRebal?.recurringAmount}
                unit={"USDT"}
              /> */}
                <DashboardCard
                    bg={"bg-blue-600"}
                    description={t(`label.depositTotal`)} // Capital Réccurent
                    number={Number(addedK).toFixed(2) || 0}
                    unit={"USDT"}
                />
                <DashboardCard
                    bg={"bg-blue-600"}
                    description={t(`label.sumTotalInvested`)} // Capital Réccurent
                    number={((summaryRebal && Number(summaryRebal?.startCapital)) + (addedK || 0)).toFixed(2)}
                    unit={"USDT"}
                />
            </div>

            <div className="flex flex-col justify-center gap-y-5">
                {/* <div className="flex space-x-2 hidden">
                    <Controls.Button small color={`${graphType == "linear" ? "bg-gray-100" : ""} `} xsFont text="Linear" onClick={() => setGraphType("linear")} />
                    <Controls.Button small color={`${graphType == "logarithmic" ? "bg-gray-100" : ""} `} xsFont text="Logarithmic" onClick={() => setGraphType("logarithmic")} />
                </div> */}

                <div className="flex flex-col space-y-5 md:flex-row  md:gap-x-5 md:space-y-0">
                    <div className=" w-full md:w-1/2 h-[70vh] bg-white dark:bg-slate-800 p-2 rounded">
                        {dataCapital ? <MyLineCapital graphType={"logarithmic"} values={values && values} data={dataCapital && dataCapital} /> : <Loading />}
                    </div>

                    <div className="w-full md:w-1/2 h-[70vh] bg-white dark:bg-slate-800 p-2 rounded">
                        {dataCapital ? <MyLineQty graphType={"linear"} values={values && values} data={dataCapital && dataCapital} /> : <Loading />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Evolution
