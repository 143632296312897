import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Outlet } from "react-router-dom"
import { authSelector } from '../features/authSlice';
import { connect, disconnect, socketSelector } from '../features/socketSlice';
import Header from './Header'
import LanguageSwitcher from './LanguageSwitcher';
import CarrousselNotif from './CarrousselNotif';
function Layout(props) {
    const { t } = props
    const socket = useSelector(socketSelector);
    const auth = useSelector(authSelector);
    console.log("auuthhh ========= %%%%%%%%%", auth);
    const dispatch = useDispatch();
    const handleCustomEvent = (data) => {
        console.log("received: ", data);
    }
    // useEffect(() => {
    //     if (!socket) {
    //         dispatch(connect({ url: process.env.REACT_APP_BASE_URL_SOCKET }));
    //     }

    //     return () => {
    //         if (socket) {
    //             dispatch(disconnect());
    //         }
    //     };
    // }, [socket, dispatch]);

    // useEffect(() => {
    //     if (socket && auth.id != 0) {
    //         socket.on('rejoin', () => {
    //             console.log("Rejoin the existing session");
    //         })
    //         socket.on('all_users', handleCustomEvent);
    //         let roomName = "client room"
    //         if (auth.role.includes(process.env.REACT_APP_SUPPORT)) {
    //             roomName = "support room"
    //             console.log(roomName);
    //         }
    //         socket.emit('new_user', auth.id, roomName)
    //         return () => {
    //             socket.off('all_users', handleCustomEvent);
    //         }
    //     }
    // }, [socket])


    return (
        <main className='bg-slate-200 dark:bg-slate-900 flex flex-col min-h-screen'>
            <Header t={t} />
            {auth.role.includes(process.env.REACT_APP_CLIENT) &&
                <div className='md:ml-52'>
                    <CarrousselNotif />
                </div>}

            {/* <div className='text-right mr-2'>
                <LanguageSwitcher />
            </div> */}
            <Outlet />
        </main>
    )
}

export default Layout
