import { ErrorSharp } from '@mui/icons-material';
import localeValues from 'antd/es/locale/fr_FR';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Controls from './controls/Controls'
import { useFOrm } from './useFOrm';
import BtnShad from './client/btnShadcn';

const initialValues = {
    status: "",
    transferDate: ""
}

function ModifPnl(props) {
    const { uid } = props
    console.log("MODIF PNL ", uid);
    const status = localStorage.getItem("userStatus")
    const { t } = useTranslation()
    const axiosPrivate = useAxiosPrivate();
    const [pnl, setPnl] = useState()
    const [sumPnl, setSumPnl] = useState()
    const [allIdPnl, setAllIdPnl] = useState([])
    const [changeOk, setChangeOk] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    let stat;

    if (status == 15) {
        stat = [
            // { id: 0, title: "Not paid yet" },
            // { id: 1, title: "Already paid" },
            // { id: 2, title: "Paid and sent with email" },
            { id: 4, title: "Déjà récupéré par carte" },
            { id: 16, title: "Resilié PNL < 12 USDT" },
            { id: 17, title: "Résilié API W invalid" },
            { id: 15, title: "Impossible de récupérer" }

        ]
    } else {
        stat = [
            { id: 4, title: "Déjà récupéré par carte" },
            { id: 15, title: "Impossible de récupérer" },
        ]

    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("status" in fieldValues) temp.status = fieldValues.status ? "" : t("error.required");
        if ("transferDate" in fieldValues) temp.transferDate = fieldValues.transferDate ? "" : t("error.required");
        setErrors({
            ...temp,
        });
        console.log("Temp", temp);

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);


    const getPnl = async () => {
        try {
            const { data } = await axiosPrivate.get(`/api/pnl/findBy/${uid}`)
            setPnl(data);
            console.log("PNL", data);

            const sumUsdt = data
                .map(item => JSON.parse(item.totalFivePercent).usdt) // Extract usdt values
                .reduce((acc, value) => acc + value, 0); // Sum usdt values

            console.log("Sum of totalFivePercent usdt:", Number((sumUsdt).toFixed(2)));
            setSumPnl(Number((sumUsdt).toFixed(2)))

            const allPnlId = data.map(item => item.id);
            setAllIdPnl(allPnlId)
            console.log("allPnlId", allPnlId);
        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        getPnl()
    }, [])

    const handleGetAllPnlId = async () => {
        setIsLoading(true)
        try {
            if (validate()) {

                console.log("AllPnlId", allIdPnl);

                if (allIdPnl.length > 0) {
                    for (let p of allIdPnl) {
                        console.log("p", p);
                        await axiosPrivate.put(`/api/pnl/${p}`, values)
                        console.log("lasa niova ", values);
                    }
                }
                getPnl()
                window.location.reload()
                toast.success("PNL modifié avec succès", { closeOnClick: true })
                setChangeOk(true)
            }

        } catch (error) {
            console.log("error", error);
        }
        setIsLoading(false)
    }


    return (
        <div className='flex flex-col space-y-2'>
            <div>
                Total PNL : <strong>{sumPnl}</strong>  usdt
            </div>
            <div className='flex flex-col items-center space-y-2 '>
                <Controls.Select
                    name="status"
                    label={"status"}
                    value={values.status}
                    options={stat}
                    onChange={handleInputChange}
                    error={errors.status}
                />
                <Controls.Input type="date" onChange={handleInputChange} label="transferDate" name="transferDate" value={values.transferDate} error={errors.transferDate} />
                {/* <Controls.Button text={"Modifier status PNL"} onClick={handleGetAllPnlId} widthFull disabled={sumPnl <= 0 ? true : false} /> */}
                <BtnShad text={"Modifier status PNL"}
                    onClick={handleGetAllPnlId}
                    wFull
                    isLoading={isLoading}
                    disabled={sumPnl <= 0 ? true : false}
                    sendHistory={{ action: `Modif PNL by Stop - ${sumPnl} USDT`, who: `${uid}` }}

                />
            </div>
            {changeOk &&
                <div></div>
            }
        </div>
    )
}

export default ModifPnl
