import React from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

function SectionTitle(props) {
  const { text, path, icon, unit, onClick } = props;
  const navigate = useNavigate();
  return (
    <div className="mb-2">
      <div className="mb-2 flex justify-between">
        <div className="space-x-1 flex items-center">
          <div>{icon}</div>

          <div className=" flex items-end space-x-1 text-sm text-slate-600 dark:text-slate-100 font-bold hover:text-sky-500 dark:hover:text-sky-500 hover:cursor-pointer">
            <div onClick={() => navigate(path)}>{text}</div>
            {unit ? <div className="text-xs">{unit}</div> : null}
          </div>
        </div>
        {path &&
          <ChevronRightIcon
          className="w-5 h-5 text-slate-800 hover:text-sky-600 dark:hover:text-sky-500 dark:text-slate-300 hover:cursor-pointer"
          onClick={() => navigate(path)}
        />
        }
      </div>
      <div className="h-[0.5px] bg-slate-200 dark:bg-slate-700 "></div>
    </div>
  );
}

export default SectionTitle;
