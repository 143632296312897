import { InformationCircleIcon } from "@heroicons/react/outline";
import { DownloadIcon } from "@heroicons/react/solid";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import formatedCurrency from "../../utils/currencyFormattre";
import DialogBox from "../DialogBox";
import EmailsDetails from "../EmailsDetails";

//		${zap ? `- <b>Capital de fin de période</b> : ${currencyQtyUsdtEquivalent(realEndCapital)} <br/>` : ``}


function EmailPreview(props) {
    //const { selectedRow } = props;
    const componentRef = useRef();
    const { t } = useTranslation()
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [transaction, setTransaction] = useState({})
    const [totalTransaction, setTotalTransaction] = useState(0)
    const axiosPrivate = useAxiosPrivate()
    let {
        id,
        date,
        isFirst,
        startCapitalPreviousMonth,
        deposit,
        withdrawal,
        endCapital,
        pnl,
        pnlFivePercent,
        listPreviousFivePercent,
        sumPreviousFivePercent,
        transferFees,
        capitalToRebalance,
        totalRebalancedValue,
        transactionFees,
        startCapitalCurrentMonth,
        balance,
        rate,
        zap,
        zapActive,
        users,
        walletHistory,
        couponApply
    } = props.selectedRow;
    // console.log("================================================= COUPON APPLY", couponApply);
    const getTransactionInEmail = async (eid) => {
        try {
            const { data } = await axiosPrivate.get(`/api/transactions/inEmail?eid=${eid}`)
            setTransaction(data)
            setTotalTransaction(data.totalAmount)
        } catch (error) {

        }
    }

    useEffect(() => {
        getTransactionInEmail(id)
    }, [id])

    let allWalletHistory = props.allWalletHistory
    let hasActiveZap = props.hasActiveZap;
    let prevEmailObject = props.prevEmailObject
    let balanceObj;
    let beforeTable;
    let afterTable;
    let email2;
    let tauxEurUsdt;
    let rateObj;
    let five_percent_last_month_array;
    let zapObject;
    let zapSection;
    let voucherApply;
    let abonnement;
    let hasTransfer;
    let cbtSection;
    let capitalHorsAjout;
    let realEndCapital;
    let zapFees = 0;
    let tauxSecured = props.taux || 1
    let appliedCredit = Number(couponApply?.appliedDiscountValue) || 0
    //console.log("users", prevEmailObject)

    let previousWalletHistoryRecord = allWalletHistory?.filter((w) => w?.id < walletHistory?.id)
    //console.log("previousWalletHistoryRecord----------------------------", previousWalletHistoryRecord, previousWalletHistoryRecord.length);

    let prevWalletHistoryActive = previousWalletHistoryRecord?.length > 0 ? previousWalletHistoryRecord[previousWalletHistoryRecord?.length - 1].a : prevEmailObject ? prevEmailObject.startCapitalCurrentMonth : 0
    // console.log("prevWalletHistoryActive", prevWalletHistoryActive);

    let sumOfPrevWalletHistoryReserve = previousWalletHistoryRecord?.length > 0 ? Number(previousWalletHistoryRecord[previousWalletHistoryRecord?.length - 1].r) : 0
    //console.log("sumOfPrevWalletHistoryReserve", sumOfPrevWalletHistoryReserve);

    let sumOfPrevWalletHistorySecured = previousWalletHistoryRecord?.length > 0 ? previousWalletHistoryRecord[previousWalletHistoryRecord?.length - 1].s : 0
    //console.log("sumOfPrevWalletHistorySecured", sumOfPrevWalletHistorySecured);

    let capitalAvecAjout = 0;
    let mttReserve = 0;
    let compareReserve = false;
    if (previousWalletHistoryRecord?.length > 0) {

        if (Number(walletHistory?.r) != previousWalletHistoryRecord[previousWalletHistoryRecord?.length - 1].r) {

            if (Number(walletHistory?.r) < previousWalletHistoryRecord[previousWalletHistoryRecord?.length - 1].r) {
                compareReserve = "RETRAIT"
            }
            else {
                compareReserve = "AJOUT"
            }
            mttReserve = Number(walletHistory?.r) - previousWalletHistoryRecord[previousWalletHistoryRecord?.length - 1].r

        }
    }

    // console.log("compareReserve", compareReserve, mttReserve);

    const [dataEmail, setDataEmail] = useState(props?.selectedRow);
    if (props.selectedRow) {
        //parsing json object
        balanceObj = JSON.parse(balance);
        rateObj = JSON.parse(rate);
        five_percent_last_month_array = JSON.parse(listPreviousFivePercent);

        //variable
        const objLength = Object.keys(rateObj).length
        let currency = objLength > 2 ? users?.details?.currency : "eur"
        tauxEurUsdt = Number(rateObj[currency]);

        const currencyQtyUsdtEquivalent = (value) => {
            let taux = tauxEurUsdt;
            const currencyFinal = currency;
            return formatedCurrency(value, taux, currencyFinal)
        }
        const onlyQtyEquivalent = (value) => {
            let taux = tauxEurUsdt;
            const currencyFinal = currency;
            let withoutUsdt = true;
            return formatedCurrency(value, taux, currencyFinal, withoutUsdt)
        }
        const onlyCurrency = (value) => {
            let taux = tauxEurUsdt;
            const currencyFinal = currency;
            let withoutUsdt = false;
            const onlyEquivalent = true;
            return formatedCurrency(value, taux, currencyFinal, withoutUsdt, onlyEquivalent)
        }



        //get taux if asset securedAsset is not usdt
        let securedAsset = users.details.securedAsset;
        if (securedAsset != "USDT") {
            // console.log("SECURED ASSET **", securedAsset);
            let otheAseetObj = balanceObj.find(b => b.asset === securedAsset)
            if (otheAseetObj) {
                tauxSecured = otheAseetObj.avgPrice.usdt
            }
            // tauxSecured = service.averagePricePublic(`${securedAsset}USDT`);
        }
        // let securedAmountQty = Number(users.details.securedAmount); //qty
        let securedAmountQty = Number(walletHistory?.s) || 0; //qty
        let securedAmountUsdt = Number(securedAmountQty * tauxSecured); //usdt
        let reserveK = Number(walletHistory?.r) || 0;
        let zapAmountQty = 0;
        let zapAmountUsdt = 0;

        let sumPrevSecuredFromWalletHistory = sumOfPrevWalletHistorySecured
        let sumPrevSecuredFromWalletHistoryEquivalentUsdt = sumOfPrevWalletHistorySecured * tauxSecured
        if (zap) {
            zapAmountQty = zap.amountSecure;
        }

        //fix email table view
        balanceObj.forEach((n, index) => {
            if (reserveK > 0) {
                if (n.asset === "USDT") {
                    let qtyUSDTToShow = Number(Number(n.free).toFixed(2)) - reserveK;
                    if (qtyUSDTToShow < 0) {
                        console.log("FREEE RESERVE CONVERT TO 0 ");
                        n.free = 0;
                    } else {
                        n.free = qtyUSDTToShow;
                    }
                    n.value.usdt = qtyUSDTToShow;
                }
            }
        });
        balanceObj.forEach((n, index) => {
            if (n.asset === securedAsset) {
                let qtyToShow = Number(n.free) - securedAmountQty;
                let currentValue = Number(n.avgPrice.usdt);
                let valueToShow;
                switch (true) {
                    case securedAsset == "USDT":
                        qtyToShow = Number(qtyToShow.toFixed(2));
                        valueToShow = qtyToShow;
                        break;
                    case securedAsset == "ETH":
                        qtyToShow = Number(qtyToShow.toFixed(4));
                        valueToShow = qtyToShow * currentValue;
                        break;
                    case securedAsset == "BTC":
                        qtyToShow = Number(qtyToShow.toFixed(8));
                        valueToShow = qtyToShow * currentValue;
                        break;
                    default:
                        qtyToShow = Number(qtyToShow.toFixed(2));
                        valueToShow = qtyToShow;
                        break;
                }
                n.free = qtyToShow;
                n.value.usdt = Number(valueToShow.toFixed(2));
            }
        });
        balanceObj.sort((a, b) => Number(b.value.usdt) - Number(a.value.usdt));
        zapAmountUsdt = Number(zapAmountQty * tauxSecured);

        //cast
        startCapitalPreviousMonth = Number(startCapitalPreviousMonth);
        withdrawal = Number(withdrawal);
        deposit = Number(deposit);
        realEndCapital = Number(endCapital);
        endCapital = Number(realEndCapital) - (Number(sumPrevSecuredFromWalletHistoryEquivalentUsdt) + Number(sumOfPrevWalletHistoryReserve));
        // console.log("endCapital = realEndK", realEndCapital, "- secured", securedAmountUsdt, "+ reserveK", reserveK, "taux", tauxSecured, props.taux);
        pnl = Number(pnl);
        pnlFivePercent = Number(pnlFivePercent);
        sumPreviousFivePercent = Number(sumPreviousFivePercent);
        transferFees = Number(transferFees);
        // Total
        capitalToRebalance = Number(capitalToRebalance) - (Number(securedAmountUsdt) + Number(reserveK));
        totalRebalancedValue = Number(totalRebalancedValue);
        transactionFees = Number(transactionFees);
        startCapitalCurrentMonth = Number(startCapitalCurrentMonth) - (Number(securedAmountUsdt) + Number(reserveK));

        capitalHorsAjout = Number(endCapital) - Number(deposit) - Number(withdrawal);
        capitalAvecAjout = Number(endCapital)
        //+ Number(deposit) + Number(withdrawal);
        zapFees = zap ? zap.fees : 0;

        let securedAmountEur = 0;
        let walletHSecuredEur = 0;
        if (securedAmountUsdt > 0) {
            securedAmountEur = Number(securedAmountUsdt) / Number(tauxEurUsdt);
        }
        if (walletHistory && Number(walletHistory.s) > 0) {
            walletHSecuredEur = Number(walletHistory.s) * Number(tauxSecured);
        }



        let platformName;
        let platformMinPnl = 12;
        let urlAddCapitalReq = "https://www.binance.com/fr/support/faq/24936156c8e440ce852ba9558fd766aa"
        switch (users?.platform) {
            case 1:
                platformName = "Binance"
                platformMinPnl = 12
                urlAddCapitalReq = "https://www.binance.com/fr/support/faq/24936156c8e440ce852ba9558fd766aa"
                break;

            case 2:
                platformName = "Kraken"
                platformMinPnl = 7
                urlAddCapitalReq = "https://drive.google.com/file/d/1NNGU4qWDblv82iKWEuVv6TswcgCwI1D2/view"
                break;

            default:
                break;
        }

        let strategyName = ''
        let strategyColor = 'darkblue'

        switch (users?.strategy) {
            case "ai":
                strategyName = t(`strategy.ai`)
                strategyColor = "#06b6d4"
                break;

            default:
                break;
        }


        // voucher
        // voucherDispo = `
        // ${couponApply ?
        //         `${t(`emailPreview.voucher.totalRemise`, { amount: currencyQtyUsdtEquivalent(couponApply.discountTotalValue) })}

        //   ` : ""
        //     }

        //     `

        // abonnement
        abonnement = `
        ${transaction?.transactions?.length > 0 ?
                `
           ${transaction.transactions.map((transac, i) => `   ${t("emailPreview.subscription.amount", { amount: currencyQtyUsdtEquivalent(transac.amount) })}`).join("")}
            ${t(`emailPreview.subscription.total`, { amount: currencyQtyUsdtEquivalent(transaction.totalAmount) })}

           `
                : ""
            }
            `

        // voucherAplly
        voucherApply = `
        ${couponApply ?
                `
                ${t(`emailPreview.voucher.totalRemise`, { amount: currencyQtyUsdtEquivalent(couponApply.discountTotalValue) })}

          ${t(`emailPreview.voucher.appliedVoucherAmount`, { amount: currencyQtyUsdtEquivalent(couponApply.appliedDiscountValue) })}
        ${t(`emailPreview.voucher.remainingVoucher`, { amount: currencyQtyUsdtEquivalent(couponApply.discountTotalValue - couponApply.appliedDiscountValue) })}
          ` : ``
            }

            `
        // transfert succes
        hasTransfer = `
        ${couponApply ?
                `
                  ${t("emailPreview.pnl.totalSendToCryptoBulot", { amount: currencyQtyUsdtEquivalent(Number(couponApply.actualTransferValue) + totalTransaction) })
                }

          ` : `   ${t("emailPreview.pnl.totalSendToCryptoBulot", { amount: currencyQtyUsdtEquivalent(sumPreviousFivePercent + pnlFivePercent + totalTransaction) })}`

            }

            `




        //zapObject = zap
        zapSection = `
				${zap
                ? `<br/>
                ${t("emailPreview.zap.secured.triggerThisMonth", { trigger: zap && 'OUI' || 'NON' })}
                ${t("emailPreview.zap.secured.type", { type: zap && zap.type === 1 ? "RECOLTE" : zap.type === 2 ? "PALIER" : zap.type === 3 ? "DYNAMIQUE" : zap.type === 4 ? " EXPRESS" : "" })}
                ${t("emailPreview.zap.secured.amountThisMonth", { amount: onlyQtyEquivalent(zap.amountSecure) })}
                ${zap && zap.type === 4 ? `  ${t("emailPreview.zap.secured.amountThisMonth", { amount: zap && zapFees == 0 ? "OFFERT" : onlyQtyEquivalent(zapFees) })}` : ""}
                ${t("emailPreview.zap.secured.totalAmountThisMonth", { qty: Number(securedAmountQty), asset: securedAsset, amount: onlyCurrency(securedAmountUsdt) })}
				`
                : zapActive
                    ? `<br/>
                ${t("emailPreview.zap.secured.triggerThisMonth", { trigger: (zapActive && "NON") || 'NON' })}
                ${t("emailPreview.zap.secured.type", { type: zapActive && zapActive.type === 1 ? "RECOLTE" : zapActive.type === 2 ? "PALIER" : zapActive.type === 3 ? "DYNAMIQUE" : zapActive.type === 4 ? " EXPRESS" : "" })}
                ${t("emailPreview.zap.secured.amountThisMonth", { amount: onlyQtyEquivalent(zapActive.amountSecure) })}
                 ${t("emailPreview.zap.secured.totalAmountThisMonth", { qty: Number(securedAmountQty), asset: securedAsset, amount: onlyCurrency(securedAmountUsdt) })}
				`
                    : ``
            }

		`;

        cbtSection = `
            ${walletHistory && (walletHistory.r > 0 || walletHistory.s > 0)
                ? `
                ${t("emailPreview.walletHistory.detail", { month: "" })}
                ${t("emailPreview.walletHistory.actif", { amount: currencyQtyUsdtEquivalent(walletHistory.a) })}
                ${walletHistory.r > 0 ? `${t("emailPreview.walletHistory.reserve", { amount: currencyQtyUsdtEquivalent(walletHistory?.r) })}` : ``}
                ${walletHistory.s > 0 ? ` ${t("emailPreview.walletHistory.secured", { amount: walletHistory.s, asset: securedAsset })}` : ``}
                `
                : ``
            }

`;

        beforeTable = `

                ${t("emailPreview.msg.intro", { date: date, strategyName: strategyName, platformName: platformName })}

        ${walletHistory && (walletHistory.r > 0 || walletHistory.s > 0)
                ? `
                ${t("emailPreview.walletHistory.detail", { month: t(`emailPreview.label.previousMonth`) })}
                ${t("emailPreview.walletHistory.actif", { amount: currencyQtyUsdtEquivalent(prevWalletHistoryActive) })}
                ${walletHistory.r > 0 ? `${t("emailPreview.walletHistory.reserve", { amount: currencyQtyUsdtEquivalent(sumOfPrevWalletHistoryReserve) })}` : ``}
                ${walletHistory.r > 0 ? `${t("emailPreview.walletHistory.secured", { amount: sumOfPrevWalletHistorySecured, asset: securedAsset })}` : ``}
        <br />` : ``
            }

                ${t("emailPreview.msg.infoReeq")}

                ${!isFirst
                ? `
                    ${t("emailPreview.capital.startPeriodPreviousMonth", { amount: currencyQtyUsdtEquivalent(prevWalletHistoryActive) })}
                    ${t("emailPreview.capital.endPeriodWithoutDeposit", { amount: currencyQtyUsdtEquivalent(capitalHorsAjout) })}
                    ${t("emailPreview.capital.deposit", { amount: currencyQtyUsdtEquivalent(deposit) })}
		            ${Number(deposit) == 0 ? ` ${t("emailPreview.msg.acrTuto", { urlAddCapitalReq: urlAddCapitalReq })}` : ``}


                    ${Number(withdrawal) != 0 ?
                    `${t("emailPreview.capital.variation", { amount: currencyQtyUsdtEquivalent(withdrawal) })}
                         ${t("emailPreview.msg.variationCapital")} `
                    : ``}

		`
                : ``
            }


		 ${isFirst ? `${t("emailPreview.capital.actifToReeq")}` : `${t("emailPreview.capital.actifEndPeriod")}`}  ${currencyQtyUsdtEquivalent(capitalAvecAjout)} <br/>


		${!isFirst ?
                `
                ${t("emailPreview.pnl.CurrentMonth")} ${Number(pnl) > 0 ? `${currencyQtyUsdtEquivalent(pnl)}` : t("emailPreview.pnl.lossPeriod")} <br/>
		${t("emailPreview.pnl.fivePercent")} ${pnlFivePercent > 0 ? `${currencyQtyUsdtEquivalent(pnlFivePercent)} ` : `${t("emailPreview.pnl.noProfit")}`}  <br/>




${Number(pnl) > 0 ?

                    `${pnlFivePercent < platformMinPnl && pnlFivePercent > 0 && Number(sumPreviousFivePercent) + Number(pnlFivePercent) < platformMinPnl
                        ? ` ${t("emailPreview.pnl.lessThan12", { platformMinPnl: platformMinPnl })}`
                        : Number(sumPreviousFivePercent) + Number(pnlFivePercent) > platformMinPnl && transferFees == 0 && couponApply == null
                            ? ` ${t("emailPreview.pnl.errorWhileTransfer")}`

                            : ``
                    } ` : ``
                }
                   ${voucherApply}
            ${abonnement}
		${five_percent_last_month_array.length != 0
                    ? `
		   ${t("emailPreview.pnl.previousMonth")}
		   <span style="font-size:14px; margin-left: 40px">
			   ${five_percent_last_month_array.map((fp, i) => `${t("emailPreview.pnl.listWithDate", { date: fp.date, amount: currencyQtyUsdtEquivalent(fp.amount) })}`).join("")}
		   </span>

		   ${Number((Number(sumPreviousFivePercent) + Number(pnlFivePercent)).toFixed(2)) >= platformMinPnl && Number(pnlFivePercent).toFixed(2) > 0 && Number(transferFees).toFixed(2) == 1
                        ? `
                        ${hasTransfer}

                          `
                        : ` ${t("emailPreview.pnl.totalToSend", { amount: currencyQtyUsdtEquivalent(sumPreviousFivePercent + pnlFivePercent - appliedCredit) })} `
                    }`
                    : ``
                }
		${Number((Number(sumPreviousFivePercent) + Number(pnlFivePercent)).toFixed(2)) < platformMinPnl ||
                    (Number(transferFees).toFixed(2) == 0 && Number((Number(sumPreviousFivePercent) + Number(pnlFivePercent)).toFixed(2)) > platformMinPnl)
                    ? ""
                    : `
                    ${t("emailPreview.fees.transfer", { platformName: platformName, amount: currencyQtyUsdtEquivalent(transferFees) })}`
                }
		${zapSection}


        ${compareReserve ?
                    `
                     ${t("emailPreview.reserve.type", { type: compareReserve })}
                     ${t("emailPreview.reserve.amountThisMonth", { amount: (mttReserve).toFixed(2) })}
                     ${t("emailPreview.reserve.totalThisMonth", { amount: walletHistory.r })}
                 `
                    : ""}


		${t("emailPreview.capital.actifToReeq")} ${currencyQtyUsdtEquivalent(capitalToRebalance)} <br/>
		`
                : ``
            }





        ${t("emailPreview.capital.requiredForReeq", { amount: currencyQtyUsdtEquivalent(totalRebalancedValue) })}
        ${t("emailPreview.fees.transaction", { platformName: platformName, amount: currencyQtyUsdtEquivalent(transactionFees) })}
        ${t("emailPreview.capital.startPeriodThisMonth", { amount: currencyQtyUsdtEquivalent(startCapitalCurrentMonth) })}
		<br />

    ${cbtSection}
    	<br />
        ${t("emailPreview.msg.newRepartition")}

		`;

        afterTable = `
        ${t("emailPreview.msg.strategyCB")}
		<p style="font-size:12px">
        ${t("emailPreview.msg.autoMessageNote")}
		</p>

		`;

        const balanceObjAuthorized = balanceObj.filter(b => b.compliance !== 'Restricted' && b.compliance !== 'Closed')
        const balanceObjRestricted = balanceObj.filter(b => b.compliance === 'Restricted' || b.compliance === 'Closed')

        const maintable = `
            <table width="100%" style=" font-size:12px; text-align:right ;">
            <thead>
                <tr style="font-weight:bold;">
                    <td>#</td>
                    <td>Crypto</td>
                    <td>Qté</td>
                    <td>USDT</td>
                    <td>${users.details.currency.toUpperCase()}</td>
                </tr>
            </thead>
            <tbody>
                ${balanceObjAuthorized.map((n, index) => (
            `
                    <tr style="border-bottom: 1px solid #ccc;">
                    <td style="padding:5px 0px">${index + 1}</td>
                        <td style="padding:5px 0px">${n.asset}</td>
                        <td style="padding:5px 0px">${n.free}</td>
                        <td style="padding:5px 0px">${n.value.usdt}</td>
                        <td style="padding:5px 0px"> ${onlyCurrency(n.value.usdt)}  </td>
                    </tr>
                    `
        )).join('')}
            </tbody>
        </table>
            `;
        const restricetdTable = ` ${balanceObjRestricted.length > 0 ?
            `
         <tr>
                <td style=" display:flex; padding: 10px 30px ">
                <p style="font-size:14px; margin-top: 40px">${t(`emailPreview.msg.restrictedCoin`)}</p>
                </td>
                </tr>
            <tr>
            <td style="padding: 10px 30px;">
                <table width="100%" style=" font-size:12px; text-align:right ;">
                <thead>
                    <tr style="font-weight:bold;">
                        <td>#</td>
                        <td>Crypto</td>
                        <td>Qté</td>
                        <td>USDT</td>
                        <td>${users.details.currency.toUpperCase()}</td>
                    </tr>
                </thead>
                <tbody>
                    ${balanceObjRestricted.map((n, index) => (
                `
                        <tr style="border-bottom: 1px solid #ccc;">
                        <td style="padding:5px 0px">${index + 1}</td>
                            <td style="padding:5px 0px">${n.asset}</td>
                            <td style="padding:5px 0px">${n.free}</td>
                            <td style="padding:5px 0px">${n.value.usdt}</td>
                            <td style="padding:5px 0px">${onlyCurrency(n.value.usdt)} </td>
                        </tr>
                        `
            )).join('')}
                </tbody>
                </table>
            </td>
            </tr>
            `: ``
            }`

        email2 = {
            text: `
	 <!DOCTYPE html>
	 <html lang="en">

	 <head>
		 <meta charset="UTF-8">
		 <meta http-equiv="X-UA-Compatible" content="IE=edge">
		 <meta name="viewport" content="width=device-width, initial-scale=1.0">

		 <style>
			 body {
				 margin: 0;
				 background-color: #cccc;
			 }



			 td {
				 padding: 0;
			 }

			 img {
				 border: 0;
			 }

			 .wrapper {
				 width: 100%;
				 background-color: #cccc;
				 padding-bottom: 40px;
			 }

			 .main {
				 background-color: #fff;
				 margin: 0 auto;
				 width: 100%;
				 max-width: 900px;
				 border-spacing: 0;
				 font-family: sans-serif;
				 color: #4a4a4a;
			 }
			 .image{
				 display:none;
			 }

			 @media screen and (max-width:600px) {}
		 </style>
	 </head>

	 <body>
		 <div class="" width="210mm" >
			 <table class="main" width="100%">
				 <!--  LOGO -->
				 <tr>
					 <td>
						 <table width="100%">
							 <tr>
								 <td style="display:flex; justify-content:center;text-align:center; padding:10px ; background-color:${strategyColor}">
									 <a href=""><img
											 src="https://crypto-bulot.com/wp-content/uploads/2022/01/crypto-bulot-blanc.png"
											 alt="Crypto Bulot" width="180" ></a>
								 </td>
							 </tr>
						 </table>
					 </td>
				 </tr>

				 <!--  TEXt -->
				 <tr>
					 <td>
						 <table width="100%">
							 <tr>
								 <td>
									 <p style="font-size:14px; padding:5px 10px 15px; text-align:justify;">
										${beforeTable}
									 </p>
								 </td>
							 </tr>
						 </table>
					 </td>
				 </tr>

				 <!--  list coin -->

				 <tr>
                         <td style="padding: 10px 30px ;">
                           ${maintable}
                         </td>
                     </tr>
                     ${restricetdTable}

				 <tr>
					 <td>
						 <table width="100%">
						 <p style="font-size:14px; line-height:23px ; text-align:justify ; padding:5px 10px 15px;">
						 ${afterTable}
						 </p>

						 </table>
					 </td>
				 </tr>

			 </table>
		 </div>

	 </body>

	 </html>
	 `,
        };
    }

    const DisplayedValue = ({ value }) => {
        return ` ${Number(value).toFixed(2)} USDT (≈${Number((value / tauxEurUsdt).toFixed(2))}€ )`;
    };
    return (
        <div className="m-2">
            <DialogBox isOpen={detailsOpen} setIsOpen={setDetailsOpen} customClassName={"md:w-1/2 w-full min-h-[300px]"}>
                <EmailsDetails />
            </DialogBox>
            <div className={`${props.selectedRow ? "flex justify-between" : "hidden"}`}>
                <InformationCircleIcon className="h-5 hover:text-blue-500 hover:cursor-pointer" onClick={() => setDetailsOpen(true)} />
                <div>

                    <ReactToPrint
                        trigger={() => (
                            <button className="flex space-x-1 bg-sky-100 border border-sky-700 rounded p-1 items-center mb-1 ">
                                <DownloadIcon className="h-3 w-3 " />
                                <div className="font-semibold text-xs">{t(`label.download`)}</div>
                            </button>
                        )}
                        content={() => componentRef.current}
                    />
                </div>

            </div>

            <div dangerouslySetInnerHTML={{ __html: email2?.text }} ref={componentRef} />
            {/* <div>
        <DownloadPage rootElementId={"pagetodownload"} />
      </div> */}

        </div>
    );
}

export default EmailPreview;
