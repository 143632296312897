import React, { useState, useEffect, useMemo, useCallback } from "react";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";

import useAxiosPrivate from "../hooks/useAxiosPrivate";

import Loading from "../components/Loading";

import { useParams } from "react-router-dom";
import { authSelector } from "../features/authSlice";
import { useSelector } from "react-redux";
import MyLineIndex from "../components/chart/MyLineIndex";
import Tuto from "../components/tuto/Tuto";
import moment from "moment";

const IndexScreenUser = (props) => {
    const { t } = props;
    const { id } = useParams();
    const auth = useSelector(authSelector);
    const axiosPrivate = useAxiosPrivate();
    const [originaldata, setOriginaldata] = useState();
    const [dataRow, setDataRow] = useState();
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [isVideoOpenCBS, setIsVideoOpenCBS] = useState(false);
    const [cbd, setCbd] = useState()


    // useEffect(() => {
    //     let isMounted = true;
    //     const controller = new AbortController();

    //     const getData2 = async (myId) => {
    //         try {
    //             const lastRebalDay = await axiosPrivate.get(`/api/emails/getLast/${myId}`);

    //             if (lastRebalDay) {
    //                 //const query = `{ getRowdata(id: ${lastRebalDay.data.day}) { id date bbinf bbsup evolution order mm20m coefOrder temperateOrder } }`;
    //                 const query = `{ getRowdata(id: 1) { id date bbinf bbsup evolution order mm20m coefOrder temperateOrder coefBbsupIndex } }`;
    //                 const { data } = await axiosPrivate.post(`/graphql`, {
    //                     signal: controller.signal,
    //                     query,
    //                 });
    //                 let formatOriginalData = await data.data.getRowdata.map((d) => {
    //                     return {
    //                         id: d.id,
    //                         date: d.date,
    //                         index: Number(JSON.parse(d.evolution).index).toFixed(0),
    //                         indexCB: Number(JSON.parse(d.evolution).indexCB).toFixed(0),
    //                         percentageEvolution: Number(JSON.parse(d.evolution).percentageEvolution),
    //                         mm20m: d.mm20m,
    //                         bbsup: d.bbsup,
    //                         bbinf: d.bbinf,
    //                         order: d.order,
    //                         coefOrder: d.coefOrder,
    //                         coefBbsupIndex: d.coefBbsupIndex,
    //                         temperateOrder: d.temperateOrder,
    //                     };
    //                 });
    //                 /*  let formatData = {
    //                     labels: data.data.getRowdata.map((d) => d.date),
    //                     index: data.data.getRowdata.map((d) => JSON.parse(d.evolution).index),
    //                     indexCB: data.data.getRowdata.map((d) => JSON.parse(d.evolution).indexCB),
    //                     percentageEvolution: data.data.getRowdata.map((d) => JSON.parse(d.evolution).percentageEvolution),
    //                     mm20m: data.data.getRowdata.map((d) => d.mm20m),
    //                     bbsup: data.data.getRowdata.map((d) => d.bbsup),
    //                     bbinf: data.data.getRowdata.map((d) => d.bbinf),
    //                     order: data.data.getRowdata.map((d) => d.order),
    //                     coefOrder: data.data.getRowdata.map((d) => d.coefOrder),
    //                     coefBbsupIndex: data.data.getRowdata.map((d) => d.coefBbsupIndex),
    //                     temperateOrder: data.data.getRowdata.map((d) => d.temperateOrder),
    //                   };*/
    //                 let formToexcel = await data.data.getRowdata.map((d) => {
    //                     let tempObj = {};

    //                     if (d.date > "2016-07-01") {
    //                         tempObj = {
    //                             date: moment(d.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    //                             index: Number(JSON.parse(d.evolution).index).toFixed(0),
    //                             indexCB: Number(JSON.parse(d.evolution).indexCB).toFixed(0),
    //                         };
    //                     }
    //                     return tempObj;
    //                 });

    //                 isMounted && setDataRow(formToexcel.filter((obj) => Object.keys(obj).length));
    //                 isMounted && setOriginaldata(formatOriginalData.filter((f) => f.id > 639));
    //             }
    //         } catch (error) {
    //             console.log("err", error);
    //         }
    //     };
    //     if (auth?.role.includes(process.env.REACT_APP_CLIENT) || auth?.role.includes(process.env.REACT_APP_PARTENAIRE)) {
    //         getData2(auth.id);
    //     } else {
    //         getData2(id);
    //     }

    //     return () => {
    //         isMounted = false;
    //         controller.abort();
    //     };
    // }, []);

    const getCBD = async () => {
        const { data } = await axiosPrivate.get(`/api/rowdata/getDataCdb`)
        setCbd(data)
    }

    useEffect(() => {
        getCBD()
    }, [])

    return (
        <>
            <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />
            <div className="flex flex-row flex-grow">
                <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />
                <div className="flex flex-row flex-1 md:ml-52">
                    <div className="p-4 flex flex-col w-full h-[93vh]">
                        <div className="flex space-x-4 items-center mb-2 ">

                            <div className="font-bold text-2xl text-slate-700 dark:text-slate-100 ">{t("label.indexCbt")}</div>
                            <div className="flex gap-x-2">
                                <Tuto
                                    t={t}
                                    isVideoOpen={isVideoOpenCBS}
                                    text={`${t("label.tuto")} CBS`}
                                    setIsVideoOpen={setIsVideoOpenCBS}
                                    url={"https://www.youtube.com/watch?v=SinAxiTicz4"}
                                    title={`${t("label.indexCb")} ${t("label.standard")}`}
                                />
                                <Tuto
                                    t={t}
                                    isVideoOpen={isVideoOpen}
                                    text={`${t("label.tuto")} CBT`}
                                    setIsVideoOpen={setIsVideoOpen}
                                    url={"https://www.youtube.com/watch?v=bI66OMsnBTw"}
                                    title={`${t("label.indexCb")} ${t("label.tempere")}`}
                                />
                                {/* <Controls.Button text={t("label.export")} small xsFont onClick={() => exportExcel(dataRow && dataRow)} /> */}
                            </div>
                        </div>
                        <div className="flex-1 bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 ">
                            <div className="flex justify-center">
                                <div className="w-full h-[75vh]">
                                    {cbd ? (
                                        <MyLineIndex
                                            //values={values && values}
                                            t={t}
                                            // data={originaldata && originaldata}
                                            screenName={"user"}
                                            cbdData={cbd && cbd}

                                        />
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IndexScreenUser;
