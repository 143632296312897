import { DataGrid, frFR, GridToolbar } from '@mui/x-data-grid'
import Item from 'antd/lib/list/Item'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { axiosPrivate } from '../axios'
import Controls from '../components/controls/Controls'
import DialogBox from '../components/DialogBox'
import Loading from '../components/Loading'
import { useFOrm } from '../components/useFOrm'
import useAxiosPrivate from '../hooks/useAxiosPrivate'


const initialFvalues = {
    pName: "",
    price: "",
    priceType: "",
    plan: {},
    isActive: true,
    monthly: "",
    yearly: ""
};


function ProductScreen() {
    const { t } = useTranslation()
    const axiosPrivate = useAxiosPrivate();
    const [isOpen, setIsOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState();
    const [allProduct, setAllProduct] = useState();
    const [addLoading, setAddLoading] = useState(false)
    const [plan, setPlan] = useState({
        monthly: 0,
        yearly: 0,
        oneShot: 0
    });


    const validate = (fieldValues = values) => {
        const temp = { ...errors };

        if ("pName" in fieldValues) temp.pName = fieldValues.pName ? "" : t("error.required");
        if ("monthly" in fieldValues) temp.monthly = fieldValues.monthly ? "" : t("error.required");
        if ("yearly" in fieldValues) temp.yearly = fieldValues.yearly ? "" : t("error.required");

        setErrors({
            ...temp,
        });
        if (fieldValues == values) {
            return Object.values(temp).every((x) => x == "");
        }
    };

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getAllProduct = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/products`, {
                    signal: controller.signal,
                })
                setAllProduct(data)
            } catch (error) {
                console.log(error);
            }
        }
        getAllProduct();
    }, [])


    const status = [
        { id: true, title: "Actif" },
        { id: false, title: "Inactif" },
    ];

    const column = [
        { field: "id", headerName: "ID", type: "number", headerClassName: "dark:text-slate-100", hide: true },
        { field: "name", headerName: t("label.product"), width: 200, headerClassName: "dark:text-slate-100" },
        {
            field: "planM", headerName: `${t("label.monthly")} (USDT)`, width: 200, headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    cellValues.row.plan.monthly
                );
            },
        },
        {
            field: "planY", headerName: `${t("label.yearly")} (USDT)`, width: 200, headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    cellValues.row.plan.yearly
                );
            },
        },
        {
            field: "isActive", headerName: t("label.status"), width: 100, headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                if (cellValues.formattedValue) {
                    return (
                        <div className='w-2 h-2 bg-green-500 py-1 px-1 text-xs rounded-lg ' />
                    );
                }
                return (
                    <div className='w-2 h-2 bg-red-500 py-1 px-1 text-xs rounded-lg ' />
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-primary dark:text-slate-100 hover:text-secondary"}
                        text={t("button.edit")}
                        onClick={(event) => {
                            const dataToShow = allProduct.filter((a) => a.id === cellValues.row.id)
                            initialFvalues.pName = dataToShow[0].name
                            initialFvalues.monthly = dataToShow[0].plan.monthly
                            initialFvalues.yearly = dataToShow[0].plan.yearly
                            initialFvalues.isActive = dataToShow[0].isActive
                            setPlan({ ...dataToShow[0].plan })
                            setSelectedRow(dataToShow[0])
                            setIsOpen(true)
                            //handleClick(event, cellValues);
                            // navigate(`/support/user/${cellValues.row.id}`);
                        }}
                    />
                );
            },
        },

    ]

    const newProduct = [
        {
            type: "text",
            name: "pName",
            label: t("label.product"),
            placeholder: t("label.product"),

        },
        // {
        //     type: "number",
        //     name: t(`title.price`),
        //     label: t("title.price"),
        //     placeholder: t("title.price"),

        // },
    ]

    const planForm = [
        {
            type: "number",
            name: "monthly",
            label: "Prix mensuel",
            placeholder: "monthly",
        },
        {
            type: "number",
            name: "yearly",
            label: "Prix annuel",
            placeholder: "yearly",
        },
    ]

    const handleAddProduct = async () => {
        initialFvalues.pName = "";
        initialFvalues.monthly = "";
        initialFvalues.yearly = "";

        resetForm()
        setSelectedRow(null)
        setIsOpen(true)
        setPlan({
            monthly: 0,
            yearly: 0,
            oneShot: 0
        })
    }

    useEffect(() => { resetForm() }, [isOpen])
    const handleSaveNewProduct = async () => {
        if (validate()) {
            setAddLoading(true)
            try {
                values.name = values.pName
                values.plan = {
                    monthly: values.monthly,
                    yearly: values.yearly,
                    oneShot: 0
                }
                const { data } = await axiosPrivate.post(`/api/products`, values)
                setIsOpen(false)
                setAddLoading(false)

                window.location.reload()

            } catch (error) {
                console.log("save tsy metgy", error);
            }
        }
        else {
            console.log("err", values);
        }
    }
    const handleEditProduct = async (id) => {
        if (validate()) {
            try {
                values.name = values.pName
                values.plan = {
                    monthly: values.monthly,
                    yearly: values.yearly,
                    oneShot: 0
                }
                const { data } = await axiosPrivate.put(`/api/products/${id}`, values)
                setIsOpen(false)
                window.location.reload()

            } catch (error) {
                console.log(error);
            }
        }
    }


    return (
        <div className="p-4  gap-3 h-[93vh]">
            <div className=" bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 h-full ">

                <h3 className="font-bold text-xl text-slate-700 dark:text-slate-100">{t("label.productCb")}</h3>
                <Controls.Button
                    text={`${t(`button.add`)} ${t(`label.product`)}`}
                    onClick={handleAddProduct}
                />
                <div className="h-[450px] bg-slate-100 dark:bg-slate-800 mt-2">
                    {/*  {allProduct ? (*/}
                    {allProduct ? (
                        <DataGrid
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: "id", sort: "desc" }],
                                },
                            }}

                            rows={allProduct}
                            // rows={product}
                            columns={column}
                            onCellClick={(value) => console.log("yes", value.id)}
                            disableColumnSelector
                            disableDensitySelector
                            getRowClassName={(params) => `text-slate-800 dark:text-slate-100`}
                            density="compact"
                            className="text-red-500"
                            autoPageSize
                        />
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>

            <DialogBox isOpen={isOpen} setIsOpen={setIsOpen} customClassName="w-full md:w-1/2">
                <div className='flex flex-col space-y-2 justify-center items-center mx-2 md:mx-auto  mt-4 pt-2 px-4  md:w-[70%] py-4'>
                    {selectedRow ? (
                        <div className=" font-medium text-center">
                            {t(`button.update`)}  {t(`label.product`)}
                        </div>
                    ) : (
                        <div className=" font-medium text-center">
                            {t(`button.add`)} {t(`label.product`)}
                        </div>
                    )}
                    <div className=' w-full flex flex-col space-y-2  p-2 items-center'>
                        {selectedRow && <div>{selectedRow?.name}</div>}
                        {selectedRow ?
                            <Controls.Select
                                name="isActive" label={"En ligne"} value={values.isActive} onChange={handleInputChange} options={status} error={errors.isActive} noneValue={"Aucun"} /> : null}

                        {/* <Controls.RadioGroup
                            items={[
                                { id: 1, title: " Fixe" },
                                { id: 2, title: " Variable" }
                            ]}
                            key={""}
                            label={t(`title.price`)}
                            value={values.priceType}
                            onChange={handleInputChange}
                            name={"priceType"}

                        /> */}
                        {newProduct.map((item) => (
                            <Controls.Input
                                type={item.type}
                                label={item.label}
                                value={values[item.name] || ""}
                                name={item.name}
                                onChange={handleInputChange}
                                error={errors[item.name]}

                            />
                        ))}
                        {planForm.map((item) => (
                            <Controls.Input
                                type={item.type}
                                label={item.label}
                                value={values[item.name] || ""}
                                name={item.name}
                                onChange={handleInputChange}
                                error={errors[item.name]}

                            />
                        ))}
                        {/* {planForm.map((item) => (
                            <Controls.Input
                                type={item.type}
                                label={item.label}
                                value={plan[item.name] || ""}
                                name={item.name}
                                onChange={(e) => setPlan({ ...plan, [item.name]: e.target.value })}
                                error={errors[item.name]}

                            />
                        ))} */}

                        {selectedRow ?
                            // Modifier
                            <Controls.Button
                                widthFull
                                text={t(`button.edit`)}
                                onClick={() => handleEditProduct(selectedRow.id)}
                            />
                            :
                            // Add new product
                            <Controls.Button
                                widthFull
                                text={t(`button.save`)}
                                onClick={handleSaveNewProduct}
                                isLoading={addLoading}
                            />
                        }
                    </div>
                </div>
            </DialogBox>
        </div>
    )
}

export default ProductScreen
