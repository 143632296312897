import * as React from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import moment from "moment";
import "moment/locale/fr";
import TableauGrid from "../components/TableauGrid";
import Loading from "../components/Loading";
import { XIcon, CheckIcon, ChevronLeftIcon, XCircleIcon } from "@heroicons/react/solid";

function OnboardingDetailsScreen(props) {
  const { t } = props;
  const [rows, setRows] = React.useState();

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams(); // ma
  const goBack = () => navigate(-1);

  const [number, setNumber] = React.useState(id?.split("-") || []);

  React.useEffect(() => {
    console.log("rere");
    let isMounted = true;
    const controller = new AbortController();
    const getOnboardingData = async () => {
      try {
        const response = await axiosPrivate.get(`/api/reporting/onboarding/${location?.state.type}/${id}`, {
          signal: controller.signal,
        });
        console.log("rere", response.data);
        isMounted && setRows(response.data);
      } catch (error) {
        if (error.response.status != 403) {
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };
    getOnboardingData();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const CustomTitle = ({ text }) => (
    <div className="text-slate-800 dark:text-slate-100 font-bold text-xl px-2 ">{text}</div>
  );

  const columns = [
    { field: "id", headerName: "ID", hide: true, sortable: false, filterable: false, disableExport: true },
    { field: "fullName", headerName: t(`label.lastName`), width: 200 },
    { field: "email", headerName: t(`label.email`), type: "email", width: 200 },
    { field: "initialCapital", headerName: t(`label.initialCapital`), type: "number", width: 150 },
    { field: "onboardingDays", headerName: `${t(`label.onBoarding`)} (j)`, type: "number", width: 150 },
    {
      field: "firstCall",
      headerName: t(`label.nbFirstCall`),
      valueGetter: (params) => {
        if (params.row.details) {
          if (params.row.firstCall == false) return "Non";
          return "Oui";
        } else {
          return null;
        }
      },

      renderCell: (cellValues) => {
        if (cellValues.row.firstCall) return <CheckIcon className="h-4 w-4 mx-auto text-green-500" />;
        return <XIcon className="h-4 w-4 mx-auto text-red-500" />;
      },
    },
    {
      field: "firstRebal",
      headerName: t(`label.nbFirstRebal`),
      valueGetter: (params) => {
        if (params.row.details) {
          if (params.row.firstRebal == false) return "Non";
          return "Oui";
        } else {
          return null;
        }
      },

      renderCell: (cellValues) => {
        if (cellValues.row.firstRebal) return <CheckIcon className="h-4 w-4 mx-auto text-green-500" />;
        return <XIcon className="h-4 w-4 mx-auto text-red-500" />;
      },
    },
    {
      field: "isRecurring",
      headerName: t(`label.reccuringPayment`),
      width: 150,

      valueGetter: (params) => {
        if (params.row.details) {
          if (params.row.recurringCapital.isReccuring == false) return "Non";
          return "Oui";
        } else {
          return null;
        }
      },

      renderCell: (cellValues) => {
        if (cellValues.row.recurringCapital.isRecurring)
          return <CheckIcon className="h-4 w-4 mx-auto text-green-500" />;
        return <XIcon className="h-4 w-4 mx-auto text-red-500" />;
      },
    },
    {
      field: "recurringAmount",
      headerName: t(`label.recurringAmount`),
      type: "number",
      width: 150,
      renderCell: (cellValues) => {
        if (cellValues.row.recurringCapital.recurringAmount) return cellValues.row.recurringCapital.recurringAmount;
        return "-";
      },
    },
  ];
  return (
    <>
      <TopBar menus={sideMenus.menusAdmin(t)} />
      <div className="flex flex-row flex-grow ">
        <Sidebar menus={sideMenus.menusAdmin(t)} />

        <div className="flex flex-row flex-1  mt-2 md:ml-52">
          <div className="flex flex-col w-full p-4 gap-y-10">
            <div className="flex-none h-auto">
              <div className="flex justify-between">
                <div>
                  <div className="flex items-center  gap-x-1">
                    <ChevronLeftIcon
                      className="h-6 rounded-full hover:bg-slate-300 cursor-pointer"
                      onClick={() => goBack()}
                    />
                    <div className="font-semibold text-2xl text-slate-900 dark:text-white">
                      {" "}
                      Détails {t("label.onBoarding")}
                    </div>
                  </div>

                  {/* moment(params.row.id, "M").format("MMMM") */}
                  <span className="text-sm text-slate-500 dark:text-slate-400  ">
                    {location.state.type == "week" ? (
                      <span>
                        Semaine de {moment(location.state.start).format("DD/MM/YYYY")} au{" "}
                        {moment(location.state.end).format("DD/MM/YYYY")}{" "}
                      </span>
                    ) : (
                      <span>{moment(number[0], "M").format("MMMM YYYY").toUpperCase()}</span>
                    )}
                  </span>
                  {/* <span className='text-xs text-slate-500 dark:text-slate-400  '>{moment().format("Do MMMM YYYY")}</span> */}
                </div>
              </div>

              <hr />
            </div>

            <div className="flex flex-col gap-y-5 h-[500px] bg-slate-100 dark:bg-slate-800 mt-2 p-4 rounded">
              {/* <CustomTitle text='Inscription' /> */}
              {rows ? <TableauGrid column={columns} dataRows={rows && rows} /> : <Loading />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OnboardingDetailsScreen;
