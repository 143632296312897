import axios from "../axios";
import { useSelector, useDispatch } from "react-redux";
import { authSelector, updateToken } from "../features/authSlice";
import * as services from "../utils/service.js"
import useAxiosPrivate from "./useAxiosPrivate";
const useRefreshToken = () => {
    const auth = useSelector(authSelector);
    const dispacth = useDispatch();
    // const axiosPrivate = useAxiosPrivate()
    const refresh = async () => {
        // const token = services.decryptCB(localStorage.getItem("token"))

        try {
            const response = await axios.get(`/api/users/token/${auth.id}`, {
                withCredentials: true,
                crossDomain: true
            })
            //console.log("OOOO", response.data.accessToken);
            // localStorage.setItem("accesstoken", response.data.accessToken)
            const cryptToken = response.data.accessToken;
            dispacth(updateToken(cryptToken))
            return response.data.accessToken
        } catch (error) {
            dispacth(updateToken(''))
        }
    };
    return refresh;
};

export default useRefreshToken;
