import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Icon } from "coinmarketcap-cryptocurrency-icons";
import { ArrowDownIcon, ArrowUpIcon, SwitchVerticalIcon } from "@heroicons/react/solid";
export default function AccordionCustom(props) {
    const { children, disabled, title, subTitle, depotTotal, withdrawTotal, differenceTotal, isFaq, design, designTitle } = props;
    return (
        <>
            <Accordion disabled={disabled} className='bg-slate-100 dark:bg-slate-500'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <div className="text-sm grid gap-y-2">
                        <div className={`${designTitle ? designTitle : "text-slate-500 dark:text-slate-300 text-xs font-semibold flex items-center gap-x-3"}`}>
                            {isFaq ? null : <SwitchVerticalIcon className={"h-5 w-5 text-slate-500  bg-slate-300 rounded-full p-[1px]"} />}
                            {title}
                        </div>

                        {isFaq ? null :
                            <div className="flex text-xs font-normal gap-x-4">
                                <div className="text-green-500"> {depotTotal}</div>
                                <div className="text-red-500"> {withdrawTotal > 0 || withdrawTotal == 0.00 ? `+${withdrawTotal}` : withdrawTotal}</div>
                                <div className="text-slate-500 dark:text-slate-300">=</div>
                                {differenceTotal < 0 ?
                                    <div className="font-semibold text-xs text-red-500">{differenceTotal} </div>
                                    : differenceTotal > 0 ? <div className="font-semibold text-xs text-green-500">{differenceTotal} </div> :
                                        <div className="font-semibold text-xs text-slate-500 dark:text-slate-300">{differenceTotal}</div>}
                            </div>
                        }



                    </div>
                    {/* <Typography sx={{ color: 'text.secondary'}}></Typography> */}
                </AccordionSummary>
                <AccordionDetails className={`${design ? design : "bg-slate-100 dark:bg-slate-800"} `}>

                    {children}

                </AccordionDetails>
            </Accordion>
        </>
    );
}
