import { PencilAltIcon, PlusCircleIcon, ShieldCheckIcon, EyeIcon } from '@heroicons/react/outline';
import { DataGrid, frFR, GridToolbar } from '@mui/x-data-grid';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StopSubscription from '../components/admin/StopSubscription';
import IconHoverText from '../components/IconHoverText';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import * as service from "../utils/service";
import BtnClientOk from '../components/BtnClientOk';
import moment from 'moment';

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { ArrowUpDown, MoreHorizontal } from "lucide-react"
import { Button } from "../components/ui/button"
import { DataTable } from '../components/client/data-table';
import useFetch from '../hooks/useFetch';
import { toast } from 'react-toastify';
import SelectShad from '../components/client/SelectShad';
import { useFOrm } from '../components/useFOrm';
import BtnShad from '../components/client/btnShadcn';
import { useSelector } from 'react-redux';
import { authSelector } from '../features/authSlice';
import { TypographyXS } from '../components/Typo';
import { DialogDemo } from '../components/client/dialogShad';

function ListForDev() {
    const [users, setUsers] = useState()
    const { t } = useTranslation()
    const auth = useSelector(authSelector)
    const axiosPrivate = useAxiosPrivate()
    const [nextLoading, setNextLoading] = useState(false)
    const [testLoading, setTestLoading] = useState(false)
    const [prevLoading, setPrevLoading] = useState(false)
    const [uidNewTicket, setUidNewTicket] = useState();
    const [openStopModal, setOpenStopModal] = useState(false);
    const [dataUser, setDataUser] = useState();
    const [currentPage, setCurrentPage] = useState(1)
    // const { isLoading, serverError, apiData } = useFetch(`/api/admin/search?pnl=1`, currentPage)

    const initialValues = {
        status: 2
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("status" in fieldValues) temp.status = fieldValues.status ? "" : t("error.required");
        setErrors({
            ...temp,
        });
        console.log("Temp", temp);

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };
    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);


    const getUsers = async () => {
        try {
            console.log("status", values.status);
            const { data } = await axiosPrivate.get(`/api/admin/search?pnl=1&page=${currentPage}&status=${values.status}`)
            console.log("data", data);

            const formatData = data.map((resp) => ({
                ...resp,
                firstName: service.decryptCBData(resp.firstName),
                lastName: service.decryptCBData(resp.lastName),
                email: service.decryptCBData(resp.email),
            }));
            setUsers(formatData);

        } catch (error) {
            console.log(error);

        }
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        getUsers()

        //getUsersPause()

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(1)
    }, [values.status])

    function calculatePnl(original, statusKeys) {
        let pnl = 0;

        for (const statusKey of statusKeys) {
            pnl += original?.sumsByStatus[statusKey]?.five || 0;
        }

        return (pnl).toFixed(2) || 0;
    }

    const ComponentChip = ({ params, t }) => {
        const getStatusColor = (value) => {
            const colorMap = {
                0: "slate",
                1: "green",
                2: "yellow",
                3: "orange",
                4: "lime",
                5: "red",
                6: "sky",
                7: "amber",
                9: "pink",
                10: "indigo",
                11: "slate",
                12: "slate",
                15: "amber",
                16: "cyan",
                18: "pink"
            };
            return colorMap[value] || "slate"; // Default to "slate" if color not found
        };

        const status = params;
        const color = getStatusColor(status);
        const style = `rounded-full border-2 border-${color}-500 w-fit text-center px-1 text-${color}-500 text-xs`;

        return <span className={style}>{t(`uStatus.${status}`)}</span>;
    };

    const listStatus = [
        { id: 0, item: t("uStatus.0") },
        { id: 1, item: t("uStatus.1") },
        { id: 2, item: t("uStatus.2") },
        { id: 3, item: t("uStatus.3") },
        { id: 4, item: t("uStatus.4") },
        { id: 5, item: t("uStatus.5") },
        { id: 6, item: t("uStatus.6") },
        { id: 7, item: t("uStatus.7") },
        { id: 8, item: t("uStatus.8") },
        { id: 9, item: t("uStatus.9") },
        { id: 10, item: t("uStatus.10") },
        { id: 11, item: t("uStatus.11") },
        { id: 12, item: t("uStatus.12") },
        { id: 13, item: t("uStatus.13") },
        { id: 14, item: t("uStatus.14") },
        { id: 15, item: t("uStatus.15") },
        { id: 16, item: t("uStatus.16") },
        { id: 18, item: t("uStatus.18") },
    ]

    const handleChangeStatus = () => {
        getUsers()
    }

    const columns = [

        {
            accessorKey: "id",
            header: "Id",
            hidden: true
        },
        {
            accessorKey: "uuid",
            header: "uuid",
        },
        // {
        //     accessorKey: "date",
        //     header: "Date",
        //     cell: ({ row: { original } }) => {
        //         return moment(original.createdAt).format("DD-MM-YYYY")
        //     }
        // },
        {
            accessorKey: "email",
            // header: "Email",
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Email
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                )
            },
        },
        {
            accessorKey: "name",
            header: "Name",
            cell: ({ row: { original } }) => {
                return (
                    `${original.firstName} ${original.lastName}`
                )
            }
        },
        // {
        //     accessorKey: "reactivationDate",
        //     header: "Date de création",
        //     cell: ({ row: { original } }) => {
        //         return moment(original.createdAt).format("DD-MM-YYYY")
        //     }
        // },
        {
            accessorKey: "addedBy",
            header: "par",
        },
        {
            accessorKey: "ip",
            header: "IP",
            cell: ({ row: { original } }) => {
                return (
                    // `${original.rebalancing_tasks.dedicatedIp}`
                    <TypographyXS>{original.rebalancing_tasks?.dedicatedIp || " - "}</TypographyXS>
                )
            }
        },
        {
            accessorKey: "platform",
            header: "Plateforme",
            cell: ({ row: { original } }) => {
                const platform = original.platform;

                if (platform == 1) {
                    return <div className="text-yellow-500 w-fit p-1 rounded-lg font-medium text-xs">BINANCE </div>
                } else if (platform == 2) {
                    return <div className="text-purple-500 w-fit p-1 rounded-lg font-medium text-xs">KRAKEN </div>
                }
            }
        },
        {
            accessorKey: "strategy",
            header: "Stratégie",
            cell: ({ row: { original } }) => {
                const strategy = original.strategy;

                if (strategy == "ai") {
                    return <div className="bg-cyan-500 text-white font-bold text-[9px] p-1 rounded-full text-center">{t(`strategy.ai`)}</div>
                } else if (strategy == "normal") {
                    return <div className="bg-teal-600 text-white font-bold text-[9px] rounded-full text-center">{t(`strategy.cbdt`)} </div>
                }
            }

        },
        {
            accessorKey: "lastLogin",
            header: "last login",
            cell: ({ row: { original } }) => {
                if (original.lastLogin) {

                    return moment(original.lastLogin).format("DD-MM-YYYY HH:mm:ss")
                }
                return <div className='text-center'>-</div>
            }
        },



        {
            id: "actions",
            header: "Actions",
            cell: ({ row: { original } }) => {
                //const payment = row.original

                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>

                            <DropdownMenuSeparator />

                            <DialogDemo
                                trigger={
                                    <DropdownMenuItem onSelect={(e) => e.preventDefault()} >
                                        Whitelist IPs
                                    </DropdownMenuItem>
                                }
                                title={`Whitelist IPs `}
                            >
                                <TypographyXS>{original.rebalancing_tasks?.whitelistIPs.join(" - ") || " Pas de IP whitelist pour le moment "}</TypographyXS>

                            </DialogDemo>

                        </DropdownMenuContent>
                    </DropdownMenu>
                )

            },
        },
    ];

    // async function handleResetStatus(uid, oldStatus) {
    //     const dataToUpdate = {
    //         status: oldStatus
    //     }
    //     await axiosPrivate.put(`/api/users/${uid}`, dataToUpdate);
    //     toast.success(`status reset successfully`);
    //     setUsers(users.filter(u => u.id != uid))

    // }

    if (auth?.id != "87e4efd1-376f-4741-8cc7-76b771b05b76") {
        return (
            <div className='text-center pt-10'>Vous n'avez pas l'autorisation d'accéder à cette page.</div>
        )
    }

    const handlePrevPage = () => {
        setNextLoading(true)
        try {
            setCurrentPage(prevPage => prevPage - 1)
            console.log("next", prevPage => prevPage - 1)
        } catch (error) {
            console.error(error);
        }
        setNextLoading(false)

    }
    const handleNextPage = () => {
        setPrevLoading(true)
        try {
            setCurrentPage(prevPage => prevPage + 1)
            console.log("next", prevPage => prevPage + 1)
        } catch (error) {
            console.error(error);
        }
        setPrevLoading(false)

    }

    const handleTest = () => {
        console.log("Test clicked");
        setTestLoading(true)
    }

    return (
        <>
            <div className="p-4  gap-3 ">
                <StopSubscription openStopModal={openStopModal} setOpenStopModal={setOpenStopModal} uid={uidNewTicket} t={t} user={dataUser && dataUser} />
                <div className=" bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 h-full ">
                    <h3 className="font-bold text-xl text-slate-700 dark:text-slate-100">{t(`button.pause`)}</h3>
                    <div> <ComponentChip params={values?.status} t={t} /></div>
                    <div className='flex items-end space-x-2'>
                        <SelectShad
                            className="w-[22rem]"
                            name="status"
                            option={listStatus}
                            placeholder="Choisir le status"
                            onChange={handleInputChange}
                            label="Choisir le status"
                            value={values.status} />
                        <BtnShad text="valider" onClick={handleChangeStatus} />
                        {/* <BtnShad text="Test Send" onClick={handleTest} sendHistory={{  comment: `test ${auth.id}`, by: auth.id }} /> */}
                    </div>
                    {/* <BtnShad text="Griser" onClick={handleTest} isLoading={testLoading}  /> */}


                    <div className="container mx-auto ">
                        <DataTable columns={columns} data={users?.length > 0 ? users : []} setCurrentPage={setCurrentPage} currentPage={currentPage} />
                    </div>
                    <div className='flex justify-between'>
                        <BtnShad text="prev" onClick={handlePrevPage} disabled={currentPage == 1 ? true : false} isLoading={prevLoading} />
                        <div>{currentPage}</div>
                        <BtnShad text="next" onClick={handleNextPage} isLoading={nextLoading} disabled={users?.length > 11 ? false : true} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListForDev
