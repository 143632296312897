import { axiosPrivate } from "../axios";

export async function testApi(type, uid) {
    let url = "";
    let dataKey = "";
    let statusApi = false;

    switch (type) {
        case "ro":
            url = "testro";
            dataKey = "ro";
            break;
        case "w":
            url = "testw";
            dataKey = "w";
            break;
        case "t":
            url = "testt";
            dataKey = "t";
            break;
        default:
            break;
    }

    const response = await axiosPrivate.get(`/api/details/${url}/${uid}`);

    if (response.status === 200) {
        statusApi = true;
    }

    return { statusApi, response };
}
