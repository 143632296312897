import React, { useState, useEffect } from "react";
import { useFOrm, Form } from "../useFOrm";
import Controls from "../controls/Controls";
import { decryptCB, specificKeyCode, encryptCB } from "../../utils/service";
import { toast } from "react-toastify";
import { updateUserDetails, userDataSelector } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { ReactComponent as Refer } from "../../asset/refer.svg";


import { authSelector, logout } from "../../features/authSlice";
import Loading from "../Loading";


function Subscription(props) {
  const { data, t, uid } = props;
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const axiosPrivate = useAxiosPrivate();
  const [free, setfree] = useState()


  const handleAddFree = async () => {
    try {
      console.log("free")
      toast.info("OUIIIIi")
    } catch (error) {

    }
  }
  return (
    <>
      {/* {data ? ( */}
      <div className="flex justify-center items-center w-full gap-x-9 p-4">
        <div className="w-1/3 hidden md:block">
          <Refer />
        </div>

        <div className="w-2/3 flex flex-col space-y-5">
          <Controls.Input
            fullWidth={true}
            // disabled={disabled}
            // readOnly={disabled}
            readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
            label={"montant"}
            value={free || ""}
            onChange={(e) => setfree(e.target.value)}
            name={"free"}
            type={"text"}
          />


          <div className="flex justify-end ">
            <Controls.Button disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false} text={t(`button.save`)} xsFont onClick={() => handleAddFree()} />
          </div>
        </div>
      </div>
      {/* ) : (
        <Loading />
      )} */}
    </>);
}

export default Subscription;
