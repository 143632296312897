import { Checkbox } from "../../components/ui/checkbox"

export function CheckShad(props) {
    const { text, onChange, name, value } = props
    const convertToDefaultEventPara = (name, value) => ({
        target: {
            name,
            value,
        },
    });

    return (
        <div className="flex items-start space-x-2">
            <Checkbox id="terms" onCheckedChange={(e) => onChange(convertToDefaultEventPara(name, e))}
                checked={value}
            />
            <label
                htmlFor="terms"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
                {text}
            </label>
        </div>
    )
}
