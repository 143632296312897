import React, { useMemo } from "react";
import "chartjs-adapter-moment";
import moment from "moment";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Interaction } from "chart.js";
import { Line } from "react-chartjs-2";
import { CrosshairPlugin, Interpolate } from "chartjs-plugin-crosshair";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, zoomPlugin, CrosshairPlugin);
Interaction.modes.interpolate = Interpolate;
export const options = {
    responsive: true,
    maintainAspectRatio: false,

    /*  scales: {
     yAxis: {
         type: 'logarithmic',
         ticks: {
           min: 10,
           color: 'black',
           beginAtZero:false,
           sampleSize: 5,
           callback: function(value) { return abbreviateNumber(value)}
           }

       },
       xAxis: {
         type: 'time',
         time: {
           format: "DD/MM/YYYY"
         },
        }
     },*/

    scales: {
        x: {
            grid: {
                display: false,
            },
            type: "time",
            time: {
                unit: "month",
            },
            /* ticks: {
               autoSkip: false,
               maxRotation: 0,
               major: {
                 enabled: true
               }}*/
        },
        y: {
            display: true,
            type: "logarithmic",
        },
    },

    plugins: {
        legend: {
            position: "bottom",
        },
        title: {
            display: false,
            text: "Chart.js Line Chart",
        },
        tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
                title: (context) => {
                    const d = new Date(context[0].parsed.x);
                    return moment(d).format("DD-MM-YYYY");
                },
            },
        },
        zoom: {
            pan: {
                enabled: true,
                mode: "x",
            },
            limits: {
                x: {
                    min: "original",
                    max: "original",
                },
            },
            zoom: {
                pinch: {
                    enabled: true, // Enable pinch zooming
                },
                wheel: {
                    enabled: true, // Enable wheel zooming
                },
                mode: "x",
            },
        },
        crosshair: {
            line: {
                color: "#64748b", // crosshair line color
                width: 1, // crosshair line width
            },
            sync: {
                enabled: true, // enable trace line syncing with other charts
                group: 2, // chart group
                suppressTooltips: false, // suppress tooltips when showing a synced tracer
            },
            // zoom: {
            //   enabled: true,                                      // enable zooming
            //   zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box
            //   zoomboxBorderColor: '#48F',                         // border color of zoom box
            //   zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            //   zoomButtonClass: 'reset-zoom',                      // reset zoom button class
            // },
            // callbacks: {
            //   beforeZoom: () => function (start, end) {                  // called before zoom, return false to prevent zoom
            //     return true;
            //   },
            //   afterZoom: () => function (start, end) {                   // called after zoom
            //   }
            // }
        },
    },
    /* elements:{
       point:{
         radius:3,
         borderWidth:2,
         backgroundColor:'white',
         hoverRadius:4,
         hoverBorderWidth:4
       }
     }*/
    elements: { line: { borderWidth: 2 } },
};
export const options2 = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                display: false,
            },
            type: "time",
            time: {
                unit: "month",
            },
        },
        y: {
            display: true,
            type: "logarithmic",
        },
    },

    plugins: {
        legend: {
            position: "bottom",
        },
        title: {
            display: true,
            text: "Sans ACR",
        },
        tooltip: {
            mode: "interpolate",
            intersect: false,
            callbacks: {
                title: (context) => {
                    const d = new Date(context[0].parsed.x);
                    return moment(d).format("DD-MM-YYYY");
                },
            },
        },
        zoom: {
            pan: {
                enabled: true,
                mode: "x",
            },
            limits: {
                x: {
                    min: "original",
                    max: "original",
                },
            },
            zoom: {
                pinch: {
                    enabled: true, // Enable pinch zooming
                },
                wheel: {
                    enabled: true, // Enable wheel zooming
                },
                mode: "x",
            },
        },

        crosshair: {
            line: {
                color: "#64748b", // crosshair line color
                width: 1, // crosshair line width
            },
            sync: {
                enabled: true, // enable trace line syncing with other charts
                group: 1, // chart group
                suppressTooltips: false, // suppress tooltips when showing a synced tracer
            },
            // zoom: {
            //   enabled: true,                                      // enable zooming
            //   zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box
            //   zoomboxBorderColor: '#48F',                         // border color of zoom box
            //   zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            //   zoomButtonClass: 'reset-zoom',                      // reset zoom button class
            // },
            // callbacks: {
            //   beforeZoom: () => function (start, end) {                  // called before zoom, return false to prevent zoom
            //     return true;
            //   },
            //   afterZoom: () => function (start, end) {                   // called after zoom
            //   }
            // }
        },
    },
    /* elements:{
       point:{
         radius:3,
         borderWidth:2,
         backgroundColor:'white',
         hoverRadius:4,
         hoverBorderWidth:4
       }
     }*/
    elements: { line: { borderWidth: 2 } },
};
const CustomLegend = ({ text, color, coef }) => (
    <div className="flex  gap-x-2 items-center">
        <div className={`${color} h-4 w-5`} />
        <div className="flex-col">
            <div className="text-xs text-slate-500">{text}</div>
            {coef && <div className="text-xs text-slate-500">Coef {coef}</div>}
        </div>
    </div>
);

const expensiveCalculation2 = (dataRow, values) => {
    let qtyCbArr = [];
    let qtyCbrecurringArr = [];
    let capitalInitial = values.actifK;
    let recurringCapital = values.recurringK;
    let reserveCapital = values.totalK - values.actifK;
    let capitalArr = [];
    let capitalReserveArr = [];
    let sumTotalKArr = [];

    let capitalArrNoAcrArr = [];
    let capitalReserveArrNoAcrArr = [];
    let sumTotalKArrNoAcrArr = [];

    let arrayOfDates = dataRow && dataRow.map((d) => d.date);
    let arrayOfindex = dataRow && dataRow.map((d) => d.indexCB);
    let arrayOfPercentageEvolution = dataRow && dataRow.map((d) => d.percentageEvolution);
    let arrayOfOrder = dataRow && dataRow.map((d) => d.order);
    let arrayOfCoefOrder = dataRow && dataRow.map((d) => d.coefOrder);
    let arrayOfTemperateOrder = dataRow && dataRow.map((d) => d.temperateOrder);
    //console.log("asdsad", arrayOfPercentageEvolution)
    let bf;
    let bfNoAcr;
    let capitalReserve;
    let capitalActif;
    let capitalReserveNoAcr;
    let capitalActifNoAcr;

    let blueLineArr = [];
    let orangeLine = [];

    let bfBlue;

    let capitalReserveBlue;
    let capitalActifBlue;

    arrayOfindex.forEach((el, i) => {
        // if (i >= 1) {
        if (i == 0) {
            // console.log("fewfwe", i, arrayOfindex[i], arrayOfDates[i])
            capitalActifBlue = values.totalK;
            blueLineArr.push(capitalActifBlue);
            // bfBlue = capitalActifBlue
        } else {
            if (arrayOfOrder[i] != null) {
                //calcul evolution of capital based on index evolution nex month
                let capitalEvolution = (Number(blueLineArr[i - 1]) * arrayOfPercentageEvolution[i]) / 100;
                capitalActifBlue = Number(blueLineArr[i - 1]) + Number(capitalEvolution);

                blueLineArr.push(capitalActifBlue);
                //  bf = capitalActifBlue
            }
            // }
        }
    });

    ///orange with green line
    arrayOfindex.forEach((el, i) => {
        if (i == 0) {
            capitalActif = capitalInitial;
            capitalReserve = reserveCapital;
            capitalArr.push(capitalActif);
            capitalReserveArr.push(capitalReserve);
            orangeLine.push(capitalActif + capitalReserve);
        } else {
            if (arrayOfOrder[i] != null) {
                //calcul evolution of capital based on index evolution nex month
                let capitalEvolution = (Number(capitalActif) * arrayOfPercentageEvolution[i]) / 100;
                capitalActif = Number(capitalActif) + Number(capitalEvolution);

                //  if (arrayOfOrder[i] != null) {
                if (arrayOfOrder[i] == 1) {
                    //achat buy bleu -> vert
                    if (capitalReserveArr[i - 1] > 0) {
                        const amountAddToKActif = capitalReserveArr[i - 1] * 0.1 * arrayOfCoefOrder[i];
                        //const amountAddToKActif = (capitalReserve * 0.1)
                        capitalActif += Number(amountAddToKActif);
                        capitalReserve -= Number(amountAddToKActif) || 0;
                    }
                } else {
                    const amountAddToKReserve = capitalArr[i - 1] * 0.1 * arrayOfCoefOrder[i];
                    //const amountAddToKReserve = (capitalActif * 0.1)
                    capitalActif -= Number(amountAddToKReserve) || 0;
                    capitalReserve += Number(amountAddToKReserve);
                }
                //}

                capitalArr.push(capitalActif);
                capitalReserveArr.push(capitalReserve);
                orangeLine.push(capitalActif + capitalReserve);
            }
        }
        //}
    });

    let greenLineArray = [];
    let capitalWithAcr, reserveWithAcr;
    let capitalWithAcrArr = [];
    let capitalReserveWithAcrArr = [];

    /*arrayOfindex.forEach((el, i) => {


      if (i == 0) {
        capitalWithAcr = capitalInitial;
        reserveWithAcr = reserveCapital;
        capitalWithAcrArr.push(capitalWithAcr);
        capitalReserveWithAcrArr.push(reserveWithAcr);
        greenLineArray.push(capitalWithAcr + reserveWithAcr);
        //bf = capitalWithAcr
      } else {
        if (arrayOfOrder[i] != null) {
          //calcul evolution of capital based on index evolution nex month
          let capitalEvolution = (Number(capitalWithAcr) * arrayOfPercentageEvolution[i]) / 100;
          capitalWithAcr = Number(capitalWithAcr) + Number(capitalEvolution);



          //  if (arrayOfOrder[i] != null) {
          if (arrayOfOrder[i] == 1) {
            //achat buy bleu -> vert
            const amountAddToKActif = capitalReserveWithAcrArr[i - 1] * 0.1 * arrayOfCoefOrder[i];
            //const amountAddToKActif = (capitalReserve * 0.1)
            capitalWithAcr += Number(amountAddToKActif);
            reserveWithAcr -= Number(amountAddToKActif) || 0;
          } else {
            const amountAddToKReserve = capitalWithAcrArr[i - 1] * 0.1 * arrayOfCoefOrder[i];
            //const amountAddToKReserve = (capitalWithAcr * 0.1)
            capitalWithAcr -= Number(amountAddToKReserve) || 0;
            reserveWithAcr += Number(amountAddToKReserve);
          }
          //}

          if (recurringCapital > 0) {
            if (arrayOfTemperateOrder[i] == 1) {
              //achat
              capitalWithAcr += Number(recurringCapital);
            } else {
              reserveWithAcr += Number(recurringCapital);
            }
          }
          capitalWithAcrArr.push(capitalWithAcr);
          capitalReserveWithAcrArr.push(reserveWithAcr);
          greenLineArray.push(capitalWithAcr + reserveWithAcr);
          //bf = capitalWithAcr
        }
      }
      //}
    });*/

    /* arrayOfindex.forEach((el, i) => {
       if (i < 19) {
         blueLineArr.push(null)
         capitalArr.push(null)
         capitalReserveArr.push(null)
         orangeLine.push(null)

       }
       if (i >= 19) {
         if (i == 19) {
           capitalActifBlue = capitalInitial
           capitalActif = capitalInitial
           capitalReserve = reserveCapital
           capitalArr.push(capitalActif)
           capitalReserveArr.push(capitalReserve)
           orangeLine.push(capitalActif + capitalReserve)
           blueLineArr.push(capitalActifBlue)
         } else {

           if (arrayOfOrder[i] != null) {
             //calcul evolution of capital based on index evolution nex month
             let capitalEvolution = (Number(blueLineArr[i - 1]) * arrayOfPercentageEvolution[i]) / 100
             capitalActifBlue = (Number(blueLineArr[i - 1]) + Number(capitalEvolution))

             if (arrayOfPercentageEvolution[i] > 0) {
               capitalActifBlue = Number(capitalActifBlue) - Number(capitalEvolution * 0.05)
             }
             blueLineArr.push(capitalActifBlue)
             //  bf = capitalActifBlue

           }
         }
       }
     })*/

    let formatData = {
        labels: arrayOfDates,
        index: arrayOfindex,
        mm20m: dataRow && dataRow.map((d) => d.mm20m),
        bbsup: dataRow && dataRow.map((d) => d.bbsup),
        bbinf: dataRow && dataRow.map((d) => d.bbinf),
        order: dataRow && dataRow.map((d) => d.order),
        coefOrder: dataRow && dataRow.map((d) => d.coefOrder),
        indexCB: dataRow && dataRow.map((d) => d.indexCB),
        capital: capitalArr,
        capitalReserve: capitalReserveArr,
        sumTotalK: sumTotalKArr,
        capitalActifNoAcr: capitalArrNoAcrArr,
        capitalReserveNoAcr: capitalReserveArrNoAcrArr,
        sumTotalKNoAcr: sumTotalKArrNoAcrArr,
        blueLine: blueLineArr,
        orangeLine,
        greenLineArray,
    };

    console.log("my data", formatData);
    return formatData;
};

function MyLineAdminSimulator5(props) {
    moment.locale("fr");
    const { data, values, cbData } = props;
    const getdata = useMemo(() => expensiveCalculation2(data, values), [values]);
    const getGradient = (ctx, chartArea, scales) => {
        const gradientBg = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
        gradientBg.addColorStop(0, "#ef4444");
        gradientBg.addColorStop(1, "#22c55e");

        return gradientBg;
    };

    const data2 = {
        labels: cbData?.capitalObj.daty,
        datasets: [
            {
                label: "CBS",
                data: cbData?.capitalObj?.cbs?.line?.noacr,
                borderColor: "#3b82f6",
                backgroundColor: "#3b82f6",
                pointRadius: 1,
            },
            {
                label: "CBTv1",
                data: cbData?.capitalObj?.cbt?.noacr?.line?.map((c) => c.amount),
                backgroundColor: "#facc15",
                borderColor: "#facc15",
                pointRadius: 1,
            },
            {
                label: "CBTv1 Actif ",
                data: cbData?.capitalObj?.cbt?.noacr?.active?.v1,
                borderColor: "#22c55e",
                backgroundColor: "#22c55e",
                pointRadius: 1,
            },
            {
                label: "CBT Réserve",
                data: cbData?.capitalObj?.cbt?.noacr?.reserve?.v1,
                borderColor: "#6366f1",
                backgroundColor: "#6366f1",
                pointRadius: 1,
            },
            {
                label: "CBDT ",
                data: cbData?.capitalObj?.cbdt?.noacr?.line?.map((c) => c.amount),
                borderColor: "#f43f5e",
                backgroundColor: "#f43f5e",
                pointRadius: 1,
            },
            {
                label: "CBDTv1 Actif",
                data: cbData?.capitalObj?.cbdt?.noacr?.active?.v1,
                borderColor: "#e879f9",
                backgroundColor: "#e879f9",
                pointRadius: 1,
            },
            {
                label: "CBDTv1 Reserve",
                data: cbData?.capitalObj?.cbdt?.noacr?.reserve?.v1,
                borderColor: "#f97316",
                backgroundColor: "#f97316",
                pointRadius: 1,
            },

        ],
    };
    return (
        <>
            <div className="flex gap-x-5 justify-center ">
                {/*
        <CustomLegend text=">= 1.8" coef='3' color="bg-red-500" />
        <CustomLegend text="[1.6 - 1.9[" coef='1' color="bg-orange-500" />
        <CustomLegend text="[1.3 - 1.6[" coef='0.5' color="bg-amber-500" />
        <CustomLegend text="[1.1 - 1.3[" coef='0.1' color="bg-yellow-400" />

        <CustomLegend text=">=1" coef='0.1' color="bg-blue-500" />
        <CustomLegend text="[0.7 - 1[" coef='0.5' color="bg-cyan-500" />
        <CustomLegend text="[0.5 - 0.7[" coef='2' color="bg-teal-500" />
        <CustomLegend text="[0.3 - 0.5[" coef='3' color="bg-emerald-500" />

        <CustomLegend text="Achat" color="bg-green-500" />
        <CustomLegend text="Vente" color="bg-red-500" /> */}
            </div>
            <Line options={options2} data={data2} />
        </>
    );
}

export default MyLineAdminSimulator5;
