import { Loader2 } from "lucide-react"
import { Button } from "../../components/ui/button"
import { cn } from '../../lib/utils';
import moment from "moment";
import { useSelector } from "react-redux";
import { authSelector } from "../../features/authSlice";
import axios, { axiosBQ } from "../../axios";
import { useState } from "react";

function BtnShad(props) {
    const { onClick, disabled, text, variant = "default", wFull, mt, color, isLoading, className, size, sendHistory } = props
    const auth = useSelector(authSelector)
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);


    const handleClick = async (event) => {
        setIsBtnDisabled(true)
        if (onClick) {

            onClick(event);
        }
        if (sendHistory && auth) {
            try {
                let dataToPost = {
                    // date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    button: text,
                    location: window.location.pathname,
                    by: auth?.id,
                    role: Number(auth?.role[0]),
                    ...sendHistory
                }
                const response = await axiosBQ.post(`${process.env.REACT_APP_BASE_URL_BQ}/action-logs/insert`, {
                    // const response = await axios.post('https://bg.flow-corporation.space/history/action-logs/insert', {
                    dataToPost,
                })
                console.log('History sent:', dataToPost)
            } catch (error) {
                console.error('Error sending history:', error);
            }
        }
        setIsBtnDisabled(false)

    };

    if (isLoading ) {
        return (
            <Button variant={variant} size={size} onClick={handleClick} disabled={true} className={cn(`${wFull ? "w-full" : ""} ${mt ? mt : "mt-2"} ${color ? color : ""}`, className)} >
                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                {text
                }</Button>
        )
    }
    return (
        <Button variant={variant} size={size} onClick={handleClick} disabled={disabled} className={cn(`${wFull ? "w-full" : ""} ${mt ? mt : "mt-2"} ${color ? color : ""}`, className)} >
            {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            {text
            }</Button>
    )
}
export default BtnShad
