import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

// const initialState = [
//   {
//     title: "Last Episode",
//     id: "6f18bbd2-3ec4-46d9-bed1-d95c6947782e",
//     cards: [
//       {
//         id: 'cf32f4a0-8401-45c2-b493-3ef6c5ab9f86',
//         text: "create list and card",
//       },
//       {
//         id: 'af04fe1e-eab4-4e6a-9345-1c2904f6300f',
//         text: " component",
//       },
//       {
//         id: 'cac63632-b525-4ab1-b261-11d52fcef807',
//         text: " jbagud",
//       },
//     ],
//   },
//   {
//     title: "This Episode",
//     id: '6a27ed34-80cb-404a-a0a7-ccef73063000',
//     cards: [
//       {
//         id: '2109b6cf-66e8-4bcd-a1c1-e7660e3f5097',
//         text: "create first reducer",
//       },
//       {
//         id: '408d2354-1d7f-4d5d-9d16-d97f435ab90d',
//         text: " render many cards on list ",
//       },
//       {
//         id: '02bc8df5-735c-4002-ac91-a575aadfac98',
//         text: " make some little changes ",
//       },
//     ],
//   },
//   {
//     title: "HAHHHA Episode",
//     id: '6a27ed34-80cb-4w4a-a0a7-ccef73063000',
//     cards: [
//       {
//         id: '2109b6cf-6ge8-4bcd-a1c1-e7660e3f5097',
//         text: "create first reducer",
//       },
//       {
//         id: '408d2354-1d7f-4d5d-dd16-d97f435ab90d',
//         text: " render many cards on list ",
//       },
//       {
//         id: '02bc8df5-735c-4002-ac9f-a575aadfac98',
//         text: " make some little changes ",
//       },
//     ],
//   },
//   {
//     title: "This ",
//     id: '6a27ed34-80cb-404a-a0a7-ccef73003000',
//     cards: [
//       {
//         id: '2109b6cf-66e8-44cd-a1c1-e7660e3f5097',
//         text: "create first reducer",
//       },
//       {
//         id: '408d2354-1d7f-4d5d-9d16-d9ef435ab90d',
//         text: " render many cards on list ",
//       },
//       {
//         id: '02bc8df5-735c-4002-ac91-a575aaefac98',
//         text: " make some little changes ",
//       },
//     ],
//   },
//   {
//     title: " Episode",
//     id: '6a27ed34-80cb-404a-a0a7-ccef73063010',
//     cards: [
//       {
//         id: '2109b5cf-66e8-4bcd-a1c1-e7660e3f5097',
//         text: "create first reducer",
//       },
//       {
//         id: '408d2354-1d7f-4dud-9d16-d97f435ab90d',
//         text: " render many cards on list ",
//       },
//       {
//         id: '02bc8df5-735c-4002-ac91-a575aadfac98',
//         text: " make some little changes ",
//       },
//     ],
//   },
// ];
const initialState = []

export const planitSlice = createSlice({
    name: "planit",
    initialState,
    reducers: {
        setList: (state, action) => {
            const sortedLists = action.payload.map(list => {
                const sortedCards = list.cards.sort((a, b) => a.position - b.position);
                return { ...list, cards: sortedCards };
            });

            return sortedLists;
        },
        addList: (state, action) => {
            // console.log("PAYY", action.payload);
            //   const newList = {
            //     title: action.payload,
            //     cards: [],
            //     id: uuidv4(),
            //   };
            return [...state, action.payload];
        },


        sortPlanit: (state, action) => {
            const { droppableIdStart, droppableIdEnd, droppableIndexEnd, droppableIndexStart, draggableId, type } = action.payload;
            const newState = [...state];

            //  dragging list around
            if (type == "list") {
                const list = newState.splice(droppableIndexStart, 1);
                newState.splice(droppableIndexEnd, 0, ...list);
                const sortedList = newState.map((list, i) => (
                    {
                        ...list,
                        position: i
                    }
                ))
                return sortedList;
            }

            // in the same list
            if (droppableIdStart == droppableIdEnd) {
                const listIndex = newState.findIndex(l => droppableIdStart === l.id);
                const list = { ...newState[listIndex] };
                const cards = [...list.cards];
                const card = cards.splice(droppableIndexStart, 1);
                cards.splice(droppableIndexEnd, 0, ...card);
                const sortedCard = cards.map((c, i) => (
                    {
                        ...c,
                        position: i
                    }
                )).sort((a, b) => a.position - b.position);
                list.cards = sortedCard;
                newState[listIndex] = list;
                return newState;
            }

            // in other list
            if (droppableIdStart !== droppableIdEnd) {
                // find the list where drag happened
                const listStart = newState.findIndex(l => droppableIdStart === l.id);

                // pull out the card from this list
                const list1 = { ...newState[listStart] };
                const cards1 = [...list1.cards];
                const card = cards1.splice(droppableIndexStart, 1);

                // find the list where drag ended
                const listEnd = newState.findIndex(l => droppableIdEnd === l.id);
                // put the card in the new list
                const list2 = { ...newState[listEnd] };
                const cards2 = [...list2.cards]

                //change lid
                const modifiedCard = { ...card[0], lid: newState[listEnd].id };
                console.log("wefwef", modifiedCard, "droppableIdEnd", droppableIdEnd)
                cards2.splice(droppableIndexEnd, 0, modifiedCard);
                const sortedCard = cards2.map((c, i) => (
                    {
                        ...c,
                        position: i
                    }
                )).sort((a, b) => a.position - b.position);
                list1.cards = cards1;
                newState[listStart] = list1;
                list2.cards = sortedCard;
                newState[listEnd] = list2;
                return newState;
            }
            return newState;
        },


        addCard: (state, action) => {
            const newCard = action.payload
            const changedListIndex = state.findIndex((l) => l.id == newCard.lid);
            // console.log("List id", changedListIndex);
            // const cardLength = state[changedListIndex].cards.length + 1 || 1;
            //   const newCard = {
            //     id: uuidv4(),
            //     text: action.payload.input,
            //   };
            const newState = state.map((list) => {
                if (list.id == newCard.lid) {
                    return {
                        ...list,
                        cards: list?.cards?.length > 0 ? [...list?.cards, newCard] : [newCard],
                    };
                } else {
                    return list;
                }
            });

            return newState;
        },
        updateZap: (state, action) => {
            return { ...state, ...action.payload };
        },


        updateList: (state, action) => {
            const { listId, data } = action.payload;
            const changedListIndex = state.findIndex((l) => l.id == listId)
            if (changedListIndex !== -1) {
                const updatedState = [...state];
                updatedState[changedListIndex] = {
                    ...updatedState[changedListIndex],
                    ...data
                };
                return updatedState;
            }
            return state;
            // console.log("ACTIOOOON", action.payload, changedListIndex)
        },
        updateCardBeforeDrag: (state, action) => {
            const { cardId, data, listId } = action.payload;
            // console.log("TELOOOO", data)
            const changedListIndex = state.findIndex((l) => l.id == listId)
            // console.log(".....................", changedListIndex)
            if (changedListIndex !== -1) {
                const newState = [...state];
                const list = { ...newState[changedListIndex] }
                const cards = [...list.cards]
                const updatedCards = cards?.map((c, i) => {
                    if (c.id == cardId) {
                        return { ...c, ...data };
                    }
                    return c;
                })
                list.cards = updatedCards;
                newState[changedListIndex] = list;
                return newState;
            }
            return state;
        },
        updateCard: (state, action) => {
            const { cardId, data, listId } = action.payload;
            // console.log("TELOOOO", data)
            const changedListIndex = state.findIndex((l) => l.id == listId)
            // console.log(".....................", changedListIndex)
            if (changedListIndex !== -1) {
                const newState = [...state];
                const list = { ...newState[changedListIndex] }
                const cards = [...list.cards]
                const updatedCards = cards?.map((c, i) => {
                    if (c.id == cardId) {
                        // console.log(c.id)
                        return { ...c, ...data, lid: listId };
                    }
                    // console.log("66666666666", c)
                    return c;
                })
                list.cards = updatedCards;
                // console.log("LIST CARD", updatedCards)
                newState[changedListIndex] = list;
                return newState;

            }
            return state;
        },

        closeCard: (state, action) => {
            const { cardId, listId } = action.payload;
            const newState = [...state];
            const listIndex = newState.findIndex(l => listId === l.id);
            const list1 = { ...newState[listIndex] };

            const cards1 = [...list1.cards];
            const getCardIndexFromLIstIndex = cards1.findIndex((c) => c.id == cardId)
            cards1.splice(getCardIndexFromLIstIndex, 1)

            list1.cards = cards1
            newState[listIndex] = list1

            return newState

        },

        moveCardToOriginList: (state, action) => {
            const { cardId, toList, fromList, dateRef } = action.payload;
            // console.log("MOVE CARD", dateRef)
            if (toList !== fromList) {
                const newState = [...state];
                const listStart = newState.findIndex(l => fromList === l.id);

                // pull out the card from this list
                const list1 = { ...newState[listStart] };
                const cards1 = [...list1.cards];

                const getCardIndexFromLIstStart = cards1.findIndex((c) => c.id == cardId)
                const card = cards1.splice(getCardIndexFromLIstStart, 1);


                // find the list where drag ended
                const listEnd = newState.findIndex(l => toList === l.id);
                // put the card in the new list
                const list2 = { ...newState[listEnd] };
                const cards2 = [...list2.cards]

                const modifiedCard = { ...card[0], lid: newState[listEnd].id, dateRef };
                cards2.splice(0, 0, modifiedCard);

                const sortedCard = cards2.map((c, i) => (
                    {
                        ...c,
                        position: i
                    }
                )).sort((a, b) => a.position - b.position);
                list1.cards = cards1;
                newState[listStart] = list1;
                list2.cards = sortedCard;
                newState[listEnd] = list2;
                return newState;
            }


            // console.log("TELOOOO", cardId, listId)
            /* const changedListIndex = state.findIndex((l) => l.id == listId)

             console.log("changedListIndex", changedListIndex, listId)
             if (changedListIndex !== -1) {
                 const newState = [...state];
                 const list = { ...newState[changedListIndex] }
                 console.log("list", list)

                 const cards = [...list.cards]
                 const updatedCards = cards?.map((c, i) => {
                     if (c.id == cardId) {
                         console.log(c.id)
                         delete data.lid
                         return { ...c, ...data, lid: listId };
                     }
                     // console.log("66666666666", c)
                     return c;
                 })
                 list.cards = updatedCards;
                 // console.log("LIST CARD", updatedCards)
                 newState[changedListIndex] = list;
                 return newState;

             }
             return state;*/
        },

        removeCard: (state, action) => {
            const { cardId, listId } = action.payload;
            const changedListIndex = state.findIndex((l) => l.id == listId)
            if (changedListIndex !== -1) {
                const newState = [...state];
                const list = { ...newState[changedListIndex] }
                const cards = [...list.cards]
                const cardIndex = cards.findIndex((c) => c.id == cardId)
                if (cardIndex > -1) {
                    cards.splice(cardIndex, 1);
                }
                list.cards = cards;
                newState[changedListIndex] = list;
                return newState;

            }
            return state;
        }
    },
});

export const { setList, addList, addCard, updateZap, sortPlanit, updateList, updateCard, removeCard, moveCardToOriginList, updateCardBeforeDrag, closeCard } = planitSlice.actions;
export const planitSelector = (state) => state.planit;
export const selectPlanitCardById = (state, id) => {
    for (const currentState of state) {
        for (const card of currentState.cards) {
            if (card.id === id) {
                return card;
            }
        }
    }
    return null; // Return null if the card with the given ID is not found
};
export const selectPlanitCardToday = (state) => {
    const today = moment().format("YYYY-MM-DD")
    let todayCards = []
    for (const currentState of state.planit) {
        for (const card of currentState.cards) {
            if (card.dateRef == today) {
                // card.position = 0
                todayCards.push(card)
            }
        }
    }
    return todayCards; // Return null if the card with the given ID is not found
};
export default planitSlice.reducer;
