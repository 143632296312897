import { PencilAltIcon, PlusCircleIcon, ShieldCheckIcon, EyeIcon } from '@heroicons/react/outline';
import { DataGrid, frFR, GridToolbar } from '@mui/x-data-grid';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StopSubscription from '../components/admin/StopSubscription';
import Controls from '../components/controls/Controls';
import IconHoverText from '../components/IconHoverText';
import Loading from '../components/Loading';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import * as service from "../utils/service";
import BtnClientOk from '../components/BtnClientOk';
import moment from 'moment';

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { ArrowUpDown, MoreHorizontal } from "lucide-react"
import { Button } from "../components/ui/button"
import { DataTable } from '../components/client/data-table';
import useFetch from '../hooks/useFetch';
import { toast } from 'react-toastify';
import BtnShad from '../components/client/btnShadcn';

const ComponentChip = ({ params, t }) => {
    if (params.value === t("uStatus.16")) {
        return <span className="rounded-full border-2 border-cyan-500 w-fit text-center px-1 text-cyan-500 text-xs ">{t("uStatus.16")}</span>;
    }
};

function PauseScreen() {
    const [users, setUsers] = useState()
    const { t } = useTranslation()
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate();
    const [ticketIsOpen, setTicketIsOpen] = useState(false);
    const [usersPause, setUsersPause] = useState([]);
    const [openTicketModal, setOpenTicketModal] = useState(false);
    const [uidNewTicket, setUidNewTicket] = useState();
    const [openStopModal, setOpenStopModal] = useState(false);
    const [dataUser, setDataUser] = useState();
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [nextLoading, setNextLoading] = useState(false)
    const [prevLoading, setPrevLoading] = useState(false)

    // const { isLoading, serverError, apiData } = useFetch(`/api/admin/search?pnl=1`, currentPage)

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/admin/search?pnl=1&page=${currentPage}&status=16`, {
                    signal: controller.signal,
                });


                const formatData = data.map((resp) => ({
                    ...resp,
                    firstName: service.decryptCBData(resp.firstName),
                    lastName: service.decryptCBData(resp.lastName),
                    email: service.decryptCBData(resp.email),
                }));
                isMounted && setUsers(formatData);
                console.log("Pause", formatData);

            } catch (error) {
                console.log(error);

            }
        };


        // const getUsersPause = async () => {
        //     try {
        //         const { data } = await axiosPrivate.get(`/api/users/userslist?status=16`, {
        //             signal: controller.signal,
        //         });
        //         // const decryptAllUsers = JSON.parse(service.decryptCBData(data.all));

        //         const formatData = data.map((resp) => ({
        //             ...resp,
        //             firstName: service.decryptCBData(resp.firstName),
        //             lastName: service.decryptCBData(resp.lastName),
        //             email: service.decryptCBData(resp.email),
        //         }));
        //         isMounted && setUsersPause(formatData);

        //     } catch (error) {
        //         console.log(error);
        //     }
        // };

        //getUsersPause();

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [currentPage]);

    function calculatePnl(original, statusKeys) {
        let pnl = 0;

        for (const statusKey of statusKeys) {
            pnl += original?.sumsByStatus[statusKey]?.five || 0;
        }

        return (pnl).toFixed(2) || 0;
    }

    const columns = [

        // {
        //     accessorKey: "id",
        //     header: "Id",
        //     hidden: true
        // },
        {
            accessorKey: "date",
            header: "Date",
            cell: ({ row: { original } }) => {
                return moment(original.pauseInfo?.date).format("DD-MM-YYYY")
            }
        },
        {
            accessorKey: "email",
            // header: "Email",
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Email
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                )
            },
        },
        {
            accessorKey: "name",
            header: "Name",
            cell: ({ row: { original } }) => {
                return (
                    `${original.firstName} ${original.lastName}`
                )
            }
        },
        {
            accessorKey: "reactivationDate",
            header: "Date de réactivation",
            cell: ({ row: { original } }) => {
                if (original.pauseInfo?.reactivation != "") {
                    return moment(original.pauseInfo?.reactivation).format("DD-MM-YYYY")
                }
                return <div className='flex justify-center '> - </div>
            }
        },
        {
            accessorKey: "motifs",
            header: "Motif",
            cell: ({ row: { original } }) => {
                return original.pauseInfo?.motif
            }
        },
        {
            accessorKey: "pnl",
            header: "PNL",
            cell: ({ row: { original } }) => {
                // const statusKeys = ["0", "15", "16", "17"];
                // const pnl = calculatePnl(original, statusKeys);
                return original.totalFive || "0.00"
            }
        },
        {
            accessorKey: "pnlPaid",
            header: "PNL payé",
            cell: ({ row: { original } }) => {
                // let pnl1 = original?.sumsByStatus["1"] ? original?.sumsByStatus["1"]["five"] : 0
                // let pnl2 = original?.sumsByStatus["2"] ? original?.sumsByStatus["2"]["five"] : 0
                // let pnl4 = original?.sumsByStatus["4"] ? original?.sumsByStatus["4"]["five"] : 0
                const statusKeys = ["1", "2", "4"];
                const pnl = calculatePnl(original, statusKeys);
                return pnl || 0
            }
        },
        {
            accessorKey: "pnlNotPaid",
            header: "PNL non payé",
            cell: ({ row: { original } }) => {
                const statusKeys = ["0", "15", "16", "17"];
                const pnl = calculatePnl(original, statusKeys);
                return pnl
            }
        },

        {
            id: "actions",
            header: "Actions",
            cell: ({ row: { original } }) => {
                //const payment = row.original

                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>

                            <DropdownMenuSeparator />
                            <DropdownMenuItem>
                                <BtnClientOk
                                    uid={original?.id}
                                    userData={original}
                                />
                            </DropdownMenuItem>

                            <DropdownMenuItem
                                onClick={(event) => {
                                    const cryptEmail = service.encryptCBData(original?.email)
                                    // navigate(`/pnl/${original?.id}`);
                                    window.open(`/pnl/${original?.id}`, '_blank');
                                }}>
                                Modifier PNL
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                onClick={() => handleResetStatus(original.id, original.pauseInfo?.oldStatus)}>
                                Restaurer status
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ];

    async function handleResetStatus(uid, oldStatus) {
        const dataToUpdate = {
            status: oldStatus
        }
        await axiosPrivate.put(`/api/users/${uid}`, dataToUpdate);
        toast.success(`status reset successfully`);
        setUsers(users.filter(u => u.id != uid))

    }


    const handleGetPause = async () => {
        setIsLoading(true)
        try {

            const { data } = await axiosPrivate.get(`/api/admin/search?pnl=1&page=${currentPage}&status=16`)
            const formatData = data.map((resp) => ({
                ...resp,
                firstName: service.decryptCBData(resp.firstName),
                lastName: service.decryptCBData(resp.lastName),
                email: service.decryptCBData(resp.email),
            }));

            setUsersPause(formatData)
            console.log("OOOOOO====", formatData);

            const formToexcel = formatData.map((d) => {

                const paidStatuses = ["1", "2", "4"];
                const unpaidStatuses = ["0", "15", "16", "17"];

                let pnlPaid = 0;
                let pnlUnpaid = 0;

                for (const [status, amount] of Object.entries(d.sumsByStatus)) {
                    if (paidStatuses.includes(status)) {
                        pnlPaid += amount;
                    }
                    if (unpaidStatuses.includes(status)) {
                        pnlUnpaid += amount;
                    }
                }

                return {
                    date: d.pauseInfo.date,
                    email: d.email,
                    name: d.lastName + " " + d.firstName,
                    date_de_reactivation: d.pauseInfo.reactivation,
                    motif: d.pauseInfo.motif,
                    pnl: (d.totalFive).toFixed(2),
                    pnl_paid: pnlPaid.toFixed(2),
                    pnl_unpaid: pnlUnpaid.toFixed(2)
                }

            })
            service.exportExcel(formToexcel && formToexcel, `pnl.xlsx`)
            console.log("extract pause", formToexcel);
        } catch (error) {
            console.error("handleGetPause", error);
        }
        setIsLoading(false)
    }

    const handlePrevPage = () => {
        setNextLoading(true)
        try {
            setCurrentPage(prevPage => prevPage - 1)
            console.log("next", prevPage => prevPage - 1)
        } catch (error) {
            console.error(error);
        }
        setNextLoading(false)

    }
    const handleNextPage = () => {
        setPrevLoading(true)
        try {
            setCurrentPage(prevPage => prevPage + 1)
            console.log("next", prevPage => prevPage + 1)
        } catch (error) {
            console.error(error);
        }
        setPrevLoading(false)

    }

    return (
        <>
            <div className="p-4  gap-3 ">
                <StopSubscription openStopModal={openStopModal} setOpenStopModal={setOpenStopModal} uid={uidNewTicket} t={t} user={dataUser && dataUser} />
                <div className=" bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 h-full ">
                    <h3 className="font-bold text-xl text-slate-700 dark:text-slate-100">{t(`button.pause`)}</h3>
                    <BtnShad text={`${t(`label.export`)} details Pause`} size={"sm"} width={"md:w-40"} onClick={handleGetPause} isLoading={isLoading} disabled={isLoading} />


                    {/* <div>Nb : {users?.length}</div> */}
                    <div className="container mx-auto ">
                        <DataTable columns={columns} data={users?.length > 0 ? users : []} setCurrentPage={setCurrentPage} currentPage={currentPage} />
                    </div>
                    <div className='flex justify-between'>
                        <BtnShad text="prev" onClick={handlePrevPage} disabled={currentPage == 1 ? true : false} isLoading={prevLoading} />
                        <div>{currentPage}</div>
                        <BtnShad text="next" onClick={handleNextPage} isLoading={nextLoading} disabled={users?.length > 11 ? false : true} />
                    </div>

                </div>
            </div>
        </>
    );
}

export default PauseScreen
