import { cn } from '../lib/utils'
import React from 'react'

export function TypographyH1(props) {
    const { children } = props
    return (
        <h1 className="text-center scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
            {children}
        </h1>
    )
}

export function TypographyH2({ children }) {
    return (
        <h2 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">
            {children}
        </h2>
    )
}

export function TypographyH3({ children, className }) {
    return (
        <h3 className={cn("scroll-m-20 text-2xl font-semibold tracking-tight", className)}>
            {children}
        </h3>
    )
}

export function TypographyH4({ children, className }) {
    return (
        <div className={cn("scroll-m-20 text-xl font-semibold tracking-tight", className)}>
            {children}
        </div>
    )
}

export function TypographyP({ children, className }) {
    return (
        <p className={cn("leading-7 [&:not(:first-child)]:mt-6", className)}>
            {children}
        </p>
    )
}

export function TypographyBlockquote({ children }) {
    return (
        <blockquote className="mt-6 border-l-2 pl-6 italic">
            {children}
        </blockquote>
    )
}

export function TypographySmall({ children, className }) {
    return (
        <div className={cn("text-sm font-medium leading-relaxed", className)}> {children}</div>
    )
}

export function TypographyS({ children }) {
    return (
        <div className="text-sm font-medium leading-relaxed mt-1"> {children}</div>
    )
}

export function TypographySBold({ children }) {
    return (
        <small className="text-sm font-bold leading-relaxed"> {children}</small>
    )
}

export function TypographyXS({ children }) {
    return (
        <small className="text-xs font-medium leading-relaxed"> {children}</small>
    )
}
export function TypoTutoLink({ children, className, onClick }) {
    return (
        <small className={cn("text-sm font-medium leading-relaxed text-blue-500 underline hover:cursor-pointer", className)} onClick={onClick}> {children}</small>
    )
}

export function TypographyXSError({ children, className }) {
    return (
        <small className={cn("text-xs font-medium leading-relaxed text-red-500 ", className)}> {children}</small>
    )
}

export function TypographyMuted({ children, className }) {
    return (
        <p className={cn("text-sm text-muted-foreground", className)}> {children}</p>
    )
}

export function TypographyMutedXS({ children }) {
    return (
        <p className="text-xs text-muted-foreground"> {children}</p>
    )
}
