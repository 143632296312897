import React, { useState, useEffect } from "react";
import Controls from "../components/controls/Controls";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import * as sideMenus from "../utils/sideMenus";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../features/authSlice";
import UserSecurisationInfo from "../components/UserSecurisationInfo";
import Tuto from "../components/tuto/Tuto";

function ClientSecure(props) {
  const { t } = props;
  const { id } = useParams(); // maka id
  const auth = useSelector(authSelector);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  return (
    <>
      <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />
      <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />
      <div className="p-4 md:ml-52">
        <div className="flex space-x-4 items-center gap-x-2">
          <div className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.securePnl`)}</div>
          <Tuto t={t} isVideoOpen={isVideoOpen} text={`${t("label.tuto")} `} setIsVideoOpen={setIsVideoOpen} url={"https://www.youtube.com/watch?v=iL0rTfwJ3ww"} title={t("label.securePnl")} />
        </div>
        {/* </div> */}
        <UserSecurisationInfo t={t} id={auth.id}/>
      </div>
    </>
  );
}

export default ClientSecure;
