import React, { useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import TopBar from "../TopBar";
import Sidebar from "../Sidebar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Controls from "../controls/Controls";
import moment from "moment";
import "moment/locale/fr";
import Loading from "../Loading";
import * as sideMenus from "../../utils/sideMenus";
import DashboradCard from "../cards/DashboardCard";
import TableauGrid from "../TableauGrid";
import * as service from "../../utils/service";
function Transactions(props) {
  const { t } = props;
  const [transactions, setTransactions] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState();
  const goBack = () => navigate(-1);
  const column = [
    {
      field: "uid",
      headerName: "UID",
      type: "number",
      hide: true,
    },
    { field: "id", headerName: "ID Transcation", type: "number" },
    {
      field: "paidDate",
      headerName: "Date de Paiment ",
      type: "date",
      width: 100,
      renderCell: (cellValues) => {
        if (cellValues.row.date) {
          return moment(cellValues.row.date).format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
    },
    {
      field: "fullName",
      headerName: t("label.lastName"),
      width: 150,
      renderCell: (cellValues) => {
        const { firstName, lastName } = cellValues.row;
        return `${service.decryptCBData(cellValues.row.firstName)} ${service.decryptCBData(cellValues.row.lastName)}`;
      },
    },
    {
      field: "total",
      headerName: t("label.sumPaidAll"),
      width: 100,
      type: "number",
      renderCell: (cellValues) => {
        if (cellValues.row.total) {
          return JSON.parse(cellValues.row.total).usdt;
        } else {
          return null;
        }
      },
    },
    { field: "fees", headerName: t("label.fees"), type: "number" },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={"Voir Détails"}
            onClick={(event) => {
              const dataToShow = transactions.allPnl.filter((c) => c.paymentId == cellValues.row.id);
              console.log(dataToShow);

              setSelectedRow(dataToShow);
            }}
          />
        );
      },
    },
  ];

  const columnsDetails = [
    { field: "id", headerName: "ID", width: 200, hide: true },
    {
      field: "paidDate",
      headerName: "Date de payement",
      width: 160,
      type: "date",
      renderCell: (cellValues) => {
        if (cellValues.row.paidDate) {
          return moment(cellValues.row.paidDate, "DD-MM-YYYY").format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
    },
    { field: "paymentId", headerName: "payment Id", width: 200, hide: true },
    { field: "status", headerName: "Status", width: 200 },
    {
      field: "totalOnePercent",
      headerName: "Total 1%",
      width: 150,
      valueGetter: (params) => {
        return JSON.parse(params.row?.totalOnePercent).usdt;
      },
    },
    {
      field: "totalPnl",
      headerName: "PNL Total",
      width: 150,
      valueGetter: (params) => {
        return JSON.parse(params.row?.totalPnl).usdt;
      },
    },
    { field: "uid", headerName: "uid", width: 200, hide: true },
  ];

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const { data } = await axiosPrivate.get(`/api/affiliate-payment/findAll`, {
          signal: controller.signal,
        });
        const decrypt = JSON.parse(service.decryptCBData(data));

        console.log("here", decrypt);
        isMounted && setTransactions(decrypt);
      } catch (error) {
        if (error.response?.status != 403) {
          // navigate('/login', { state: { from: location }, replace: true })
        }
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <>
      <TopBar menus={sideMenus.menusAdmin(t)} />
      <div className="flex flex-row flex-grow ">
        <Sidebar menus={sideMenus.menusAdmin(t)} />
        <div className="flex flex-row flex-1  mt-2 md:ml-52">
          <div className="flex flex-col w-full p-4">
            <div className="flex-none h-auto">
              <div className="flex items-center  gap-x-1">
                <ChevronLeftIcon className="h-6 rounded-full hover:bg-slate-300 cursor-pointer" onClick={() => goBack()} />
                <div className="font-semibold text-2xl text-slate-900 dark:text-white">{t("label.transactionList")}</div>
              </div>

              <span className="text-xs text-slate-500 dark:text-slate-400  ">{moment().format("Do MMMM YYYY")}</span>

              <hr />
              <div className="flex justify-end gap-x-2 ">
                <DashboradCard
                  bg={"bg-indigo-500"}
                  number={transactions?.newArr?.map((p) => JSON.parse(p.total).usdt).reduce((acc, amount) => Number(acc) + Number(amount), 0)}
                  description={t("label.sumPaidAll")}
                />
              </div>
            </div>

            <div className="h-[250px] bg-slate-100 dark:bg-slate-800 mt-2">
              {transactions && transactions ? <TableauGrid column={column} dataRows={transactions && transactions?.newArr} /> : <Loading />}
            </div>

            {/* tableau ambany */}
            {selectedRow ? (
              <>
                <div className="h-[400px] bg-slate-100 my-2 p-2 flex flex-col ">
                  <div className=" flex justify-between text-lg font-light">
                    <div className="  text-lg font-light">
                      {t(`label.billDetail`)}
                      {/* id de la facture no avoaka eto zany */}
                      <span className="font-semibold ml-3">{selectedRow[0].paymentId}</span>
                    </div>

                    <div className="flex flex-wrap justify-end gap-x-2 my-2"></div>
                  </div>
                  {selectedRow && selectedRow ? <TableauGrid column={columnsDetails} dataRows={selectedRow && selectedRow} /> : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Transactions;
