import React from "react";
import Tooltip from "../Tooltip";
function ProgressBar(props) {
	let a = 0;
	const { percentage, limit, unit, pallier } = props;
	let bg = "";
	if (pallier === 200) {
		bg = "bg-blue-600";
	} else if (pallier === 500) {
		bg = "bg-amber-300";
	} else if (pallier === 1000) {
		bg = "bg-orange-500";
	} else if (pallier === 100000) {
		bg = "bg-lime-500";
	}
	return (
		<>
			<div className="flex justify-end space-x-1 items-center">
				{/* <div>
                    {limit}
                </div> */}

				<span className="text-xs">{unit}</span>
			</div>
			<div className="hidden">{(a = ((percentage * pallier) / 100).toFixed(2))}</div>
			<div className="w-full  bg-slate-300 rounded h-4 dark:bg-gray-700">
				<Tooltip message={`${percentage} % de ${pallier} USDT`}>
					<div className={`${bg} h-4 rounded-full`} style={{ width: `${percentage}%` }} />
				</Tooltip>
			</div>
			<div className="ml-2">{pallier}</div>
		</>
	);
}

export default ProgressBar;
