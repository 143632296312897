import React from "react";
import { FormControl, FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material";
export default function Checkbox(props) {
  const { name, value, label, onChange } = props;

  const convertToDefaultEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  return (
    <FormControl>
      <FormControlLabel
        control={
          <MuiCheckbox
            name={name}
            color="primary"
            checked={value}
            onChange={(e) => onChange(convertToDefaultEventPara(name, e.target.checked))}
          />
        }
        label={<div className="text-sm">{label}</div>}
      />
    </FormControl>
  );
}
