import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import TableauGrid from "../TableauGrid";
import Loading from "../Loading";
import BarChart from "../chart/BarChart";
import DashboardCard from "../cards/DashboardCard";
function OnboardingMonth(props) {
  const { t } = props;
  const axiosPrivate = useAxiosPrivate();
  const [weekRows, setweekRows] = useState();
  const [labels, setLabels] = useState([]);
  const [weekRowsSubscription, setweekRowsSubscription] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  let dataForRow;
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getOnboardingWeekData = async () => {
      try {
        const { data } = await axiosPrivate.get(`/api/reporting/onboarding/month`, {
          signal: controller.signal,
        });
        console.log("moth", data);

        const nbrSubs = data?.details.map((d) => d.summary.nbSubscription);
        const firstCallWait = data?.details.map((d) => d.summary.firstCall?.wait);
        const firstCallDone = data?.details.map((d) => d.summary.firstCall?.done);
        const firstRebalWait = data?.details.map((d) => d.summary.firstReabal?.wait);
        const firstReabalDone = data?.details.map((d) => d.summary.firstRebal?.done);
        /* setDataChart(prev => ([{
                     type: 'bar',
                     label: 'Nbr Subscription',
                     data: nbrSubs,
                     backgroundColor: 'rgb(255, 99, 132)',
                     maxBarThickness: 10,
                     options: {
                         plugins: {
                             zoom: {
                                 zoom: {
                                     wheel: {
                                         enabled: true,
                                     },
                                     pinch: {
                                         enabled: true
                                     },
                                     mode: 'xy',
                                 }
                             }
                         }
                     }
                 }, {
                     type: 'bar',
                     label: 'Nbr Subscription',
                     data: nbrSubs,
                     backgroundColor: 'rgb(255, 205, 86)',
                     maxBarThickness: 10
                 }, {
                     type: 'bar',
                     label: 'Attente 1er Call',
                     data: firstCallWait,
                     backgroundColor: 'rgb(153, 102, 255)',
                     maxBarThickness: 10
                 },
                 {
                     type: 'bar',
                     label: '1er Call',
                     data: firstCallDone,
                     backgroundColor: 'rgb(75, 192, 192)',
                     maxBarThickness: 10
                 },
                 {
                     type: 'bar',
                     label: 'Attente 1er Réeq',
                     data: firstRebalWait,
                     backgroundColor: 'rgb(153, 102, 255)',
                     maxBarThickness: 10
                 },
                 {
                     type: 'bar',
                     label: '1er Réeq',
                     data: firstReabalDone,
                     backgroundColor: 'rgb(75, 192, 192)',
                     maxBarThickness: 10
                 }

                 ]))*/
        isMounted && setweekRows(data);
        isMounted && setweekRowsSubscription(data?.details.map((d) => d.summary.nbSubscription));
        isMounted && setLabels(data?.details.map((d) => d.id));
      } catch (error) {
        if (error.response.status != 403) {
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };
    getOnboardingWeekData();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const TextPercentage = ({ amount, percetange }) => (
    <div>
      {amount} <span className="text-xs">({percetange} %)</span>
    </div>
  );

  const columns = [
    {
      field: "id",
      headerName: "Mois",
      valueGetter: (params) => {
        return moment(params.row.id, "M-YYYY").format("MMMM");
      },
      renderCell: (cellValues) => {
        if (cellValues.row.id) {
          return moment(cellValues.row.id, "M-YYYY").format("MMM-YYYY");
        } else {
          return null;
        }
      },
    },
    // {
    //     field: 'start', headerName: 'Date Debut', type: 'date',
    //     valueGetter: (params) => {
    //         return moment(params.row.start).format("DD/MM/YYYY")
    //     },
    //     renderCell: (cellValues) => {
    //         if (cellValues.row.start) {
    //             return moment(cellValues.row.start).format("DD/MM/YYYY")
    //         } else {
    //             return null
    //         }
    //     }
    // },
    // {
    //     field: 'end', headerName: 'Date Fin', type: 'date',
    //     renderCell: (cellValues) => {
    //         if (cellValues.row.end) {
    //             return moment(cellValues.row.end).format("DD/MM/YYYY")
    //         } else {
    //             return null
    //         }
    //     }
    // },
    {
      field: "nbSubscription",
      headerName: t("label.nbSubscription"),
      type: "number",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.nbSubscription || "-";
      },
    },
    {
      field: "waitFirstCall",
      headerName: t("label.waitFirstCall"),
      type: "number",
      headerClassName: "bg-red-200 w-50",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        return params.row.summary.firstCall?.wait || "-";
      },
      renderCell: (cellValues) => {
        if (!isNaN(cellValues.row.summary.firstCall.waitPercentage)) {
          return <TextPercentage amount={cellValues.row.summary.firstCall?.wait} percetange={cellValues.row.summary?.firstCall?.waitPercentage} />;
        } else {
          return "-";
        }
      },
    },
    {
      field: "nbFirstCall",
      headerName: t("label.nbFirstCall"),
      type: "number",
      headerClassName: "bg-orange-200",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        return params.row.summary.firstCall?.done || "-";
      },
      renderCell: (cellValues) => {
        if (!isNaN(cellValues.row.summary.firstCall.donePercentage)) {
          return <TextPercentage amount={cellValues.row.summary.firstCall?.done} percetange={cellValues.row.summary?.firstCall?.donePercentage} />;
        } else {
          return "-";
        }
      },
    },
    {
      field: "waitRebalancing",
      headerName: t("label.waitRebalancing"),
      type: "number",
      headerClassName: "bg-yellow-200",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        return params.row.summary?.firstRebal?.wait || "-";
      },
      renderCell: (cellValues) => {
        if (!isNaN(cellValues.row.summary.firstRebal.waitPercentage)) {
          return <TextPercentage amount={cellValues.row.summary.firstRebal?.wait} percetange={cellValues.row.summary?.firstRebal?.waitPercentage} />;
        } else {
          return "-";
        }
      },
    },
    {
      field: "nbFirstRebal",
      headerName: t("label.nbFirstRebal"),
      type: "number",
      headerClassName: "bg-green-200",
      headerAlign: "center",
      flex: 1,

      valueGetter: (params) => {
        return params.row.summary?.firstRebal?.done || "-";
      },
      renderCell: (cellValues) => {
        if (!isNaN(cellValues.row.summary.firstRebal.donePercentage)) {
          return <TextPercentage amount={cellValues.row.summary.firstRebal?.done} percetange={cellValues.row.summary?.firstRebal?.donePercentage} />;
        } else {
          return "-";
        }
      },
    },

    // {
    //     field: 'waitFirstCallPercentage', headerName: t('label.waitFirstCallPercentage'), type: 'number', headerClassName: "bg-red-200 w-50", headerAlign: 'center',

    //     valueGetter: (params) => {
    //         return params.row.summary?.firstCall?.waitPercentage || "-"
    //     }
    // },
    // {
    //     field: 'nbFirstCallPercentage', headerName: t('label.nbFirstCallPercentage'), type: 'number', headerClassName: "bg-orange-200", headerAlign: 'center',

    //     valueGetter: (params) => {
    //         return params.row.summary?.firstCall?.donePercentage || "-"
    //     }
    // },
    // {
    //     field: 'nbWaitFirstRebalPercentage', headerName: t('label.nbWaitFirstRebalPercentage'), type: 'number', headerClassName: "bg-yellow-200", headerAlign: 'center',
    //     valueGetter: (params) => {
    //         return params.row.summary?.firstRebal?.waitPercentage || "-"
    //     }
    // },
    // {
    //     field: 'nbFirstRebalPercentage', headerName: t('label.nbFirstRebalPercentage'), type: 'number', headerClassName: "bg-green-200", headerAlign: 'center',
    //     valueGetter: (params) => {
    //         return params.row.summary?.firstRebal?.donePercentage || "-"
    //     }
    // },
  ];

  const columnsDuration = [
    {
      field: "id",
      headerName: "Mois",
      valueGetter: (params) => {
        return moment(params.row.id, "M").format("MMMM");
      },
      renderCell: (cellValues) => {
        if (cellValues.row.id) {
          return moment(cellValues.row.id, "M").format("MMM-YYYY");
        } else {
          return null;
        }
      },
    },
    // {
    //     field: 'start', headerName: 'Date Debut', type: 'date',
    //     valueGetter: (params) => {
    //         return moment(params.row.start).format("DD/MM/YYYY")
    //     },
    //     renderCell: (cellValues) => {
    //         if (cellValues.row.start) {
    //             return moment(cellValues.row.start).format("DD/MM/YYYY")
    //         } else {
    //             return null
    //         }
    //     }
    // },
    // {
    //     field: 'end', headerName: 'Date Fin', type: 'date',
    //     renderCell: (cellValues) => {
    //         if (cellValues.row.end) {
    //             return moment(cellValues.row.end).format("DD/MM/YYYY")
    //         } else {
    //             return null
    //         }
    //     }
    // },
    {
      field: "nbFirstRebal",
      headerName: `Nb ${t("label.nbFirstRebal")}`,
      type: "number",
      headerAlign: "center",

      valueGetter: (params) => {
        return params.row.summary?.firstRebal?.done || "-";
      },
    },
    {
      field: "min",
      headerName: "Min",
      type: "number",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.onboardingDays.min || "-";
      },
    },
    {
      field: "q1",
      headerName: "Q1",
      type: "number",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.onboardingDays.q1 || "-";
      },
    },
    {
      field: "median",
      headerName: "Médiane",
      type: "number",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.onboardingDays.median || "-";
      },
    },
    {
      field: "q3",
      headerName: "Q3",
      type: "number",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.onboardingDays.q3 || "-";
      },
    },
    {
      field: "max",
      headerName: "Max",
      type: "number",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.onboardingDays.max || "-";
      },
    },
    {
      field: "avg",
      headerName: "Moyenne",
      type: "number",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.onboardingDays.avg || "-";
      },
    },
  ];

  const columnsCapital = [
    {
      field: "id",
      headerName: "Mois",
      valueGetter: (params) => {
        return moment(params.row.id, "M").format("MMMM");
      },
      renderCell: (cellValues) => {
        if (cellValues.row.id) {
          return moment(cellValues.row.id, "M").format("MMM-YYYY");
        } else {
          return null;
        }
      },
    },
    // {
    //     field: 'start', headerName: 'Date Debut', type: 'date',
    //     valueGetter: (params) => {
    //         return moment(params.row.start).format("DD/MM/YYYY")
    //     },
    //     renderCell: (cellValues) => {
    //         if (cellValues.row.start) {
    //             return moment(cellValues.row.start).format("DD/MM/YYYY")
    //         } else {
    //             return null
    //         }
    //     }
    // },
    // {
    //     field: 'end', headerName: 'Date Fin', type: 'date',
    //     renderCell: (cellValues) => {
    //         if (cellValues.row.end) {
    //             return moment(cellValues.row.end).format("DD/MM/YYYY")
    //         } else {
    //             return null
    //         }
    //     }
    // },
    {
      field: "nbFirstRebal",
      headerName: `Nb ${t("label.nbFirstRebal")}`,
      type: "number",
      headerAlign: "center",

      valueGetter: (params) => {
        return params.row.summary?.firstRebal?.done || "-";
      },
    },
    {
      field: "min",
      headerName: "Min",
      type: "number",
      headerAlign: "center",
      headerClassName: "bg-green-400 w-50",
      valueGetter: (params) => {
        return params.row.summary.initialCapital.min || "-";
      },
    },
    {
      field: "q1",
      headerName: "Q1",
      type: "number",
      headerClassName: "bg-green-400 w-50",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.initialCapital.q1 || "-";
      },
    },
    {
      field: "median",
      headerName: "Médiane",
      type: "number",
      headerClassName: "bg-green-400",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.initialCapital.median || "-";
      },
    },
    {
      field: "q3",
      headerName: "Q3",
      type: "number",
      headerClassName: "bg-green-400",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.initialCapital.q3 || "-";
      },
    },
    {
      field: "max",
      headerName: "Max",
      type: "number",
      headerClassName: "bg-green-400 w-50",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.initialCapital.max || "-";
      },
    },
    {
      field: "avg",
      headerName: "Moyenne",
      type: "number",
      headerClassName: "bg-green-400",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.initialCapital.avg || "-";
      },
    },
    {
      field: "numbers",
      headerName: "Nb",
      type: "number",
      headerClassName: "bg-cyan-400",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.recurringCapital.number || "-";
      },
    },
    {
      field: "percentages",
      headerName: "Abo %",
      type: "number",
      headerClassName: "bg-cyan-400",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.recurringCapital.percentage || "-";
      },
    },
    {
      field: "sums",
      headerName: "Récurrent Cumulé",
      type: "number",
      headerClassName: "bg-cyan-400",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.recurringCapital.sum || "-";
      },
    },
    {
      field: "avgs",
      headerName: "R Moyen / Abo",
      type: "number",
      headerClassName: "bg-cyan-400",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.recurringCapital.avg || "-";
      },
    },

    {
      field: "numberExpansion",
      headerName: "Nb",
      type: "number",
      headerClassName: "bg-yellow-300",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.expansionCapital.number || "-";
      },
    },
    {
      field: "cumulExpansion",
      headerName: "Cumulé",
      type: "number",
      headerClassName: "bg-yellow-300",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.expansionCapital.sum || "-";
      },
    },
    {
      field: "avgExpansion",
      headerName: "Moyen",
      type: "number",
      headerClassName: "bg-yellow-300",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.summary.expansionCapital.avg || "-";
      },
    },
  ];

  const CustomTitle = ({ text }) => <div className="text-xl font-semibold ">{text}</div>;

  return (
    <div className="grid gap-y-2 md:px-5">
      {/* <BarChart data={dataChart && dataChart} labels={labels && labels} type='line' /> */}

      <div className="flex justify-end gap-x-2 ">
        <DashboardCard bg={"bg-indigo-600"} number={weekRows && weekRows.summary.subscription} description={"Subscription"} />

        <DashboardCard bg={"bg-sky-500"} number={weekRows && weekRows.summary.firstCall} description={t("1er Call")} />

        <DashboardCard bg={"bg-blue-500"} number={weekRows && weekRows.summary.firstRebal} description={t("1 Réeq")} />
      </div>

      <div className="flex flex-col gap-y-5 h-[650px] bg-slate-100 dark:bg-slate-800 mt-2 p-4 rounded">
        <div className="flex justify-between">
          <CustomTitle text={"Inscription"} />
          <div className="flex gap-x-2">
            <DashboardCard
              bgLight={`bg-red-200`}
              number={weekRows && weekRows.summaryTable?.firstCall.wait}
              description={"Total Attente 1er Call"}
              unit={`(${weekRows && weekRows.summaryTable.firstCall.waitPercentage} %)`}
            />

            <DashboardCard
              bgLight={`bg-orange-200`}
              number={weekRows && weekRows.summaryTable?.firstCall.done}
              description={"Total 1er Call"}
              unit={`(${weekRows && weekRows.summaryTable.firstCall.donePercentage} %)`}
            />
            <DashboardCard
              bgLight={`bg-yellow-200`}
              number={weekRows && weekRows.summaryTable?.firstRebal.wait}
              description={"Total Attente 1er Rééq"}
              unit={`(${weekRows && weekRows.summaryTable.firstRebal.waitPercentage} %)`}
            />

            <DashboardCard
              bgLight={`bg-green-200`}
              number={weekRows && weekRows.summaryTable?.firstRebal.done}
              description={"Total 1er Rééq"}
              unit={`(${weekRows && weekRows.summaryTable.firstRebal.donePercentage} %)`}
            />
          </div>
        </div>
        {weekRows ? <TableauGrid column={columns} dataRows={weekRows && weekRows.details} isOnboarding hasDate /> : <Loading />}
      </div>

      <div className="flex flex-col gap-y-5 h-[650px] bg-slate-100 dark:bg-slate-800 mt-2 p-4 rounded">
        <div className="flex justify-between">
          <CustomTitle text={"Durée"} />

          <div className="flex gap-x-2">
            <DashboardCard number={weekRows && weekRows.summaryTable?.onboardingDays?.min} description={"Min"} />

            <DashboardCard number={weekRows && weekRows.summaryTable?.onboardingDays.q1} description={"Q1"} />
            <DashboardCard number={weekRows && weekRows.summaryTable?.onboardingDays.median} description={"Médiane"} />
            <DashboardCard number={weekRows && weekRows.summaryTable?.onboardingDays.q3} description={"Q3"} />
            <DashboardCard number={weekRows && weekRows.summaryTable?.onboardingDays.max} description={"Max"} />
            <DashboardCard number={weekRows && weekRows.summaryTable?.onboardingDays.avg} description={"Moyenne"} />
          </div>
        </div>
        {weekRows ? <TableauGrid column={columnsDuration} dataRows={weekRows && weekRows.details} isOnboarding hasDate /> : <Loading />}
      </div>

      <div className="flex flex-col gap-y-5 h-[650px] bg-slate-100 dark:bg-slate-800 mt-2 p-4 rounded">
        <div className="flex justify-between">
          <CustomTitle text={"Capital"} />

          <div className="flex gap-x-2">
            <DashboardCard number={weekRows && weekRows.summaryTable?.initialCapital?.min} description={"Min"} />

            <DashboardCard number={weekRows && weekRows.summaryTable?.initialCapital.q1} description={"Q1"} />
            <DashboardCard number={weekRows && weekRows.summaryTable?.initialCapital.median} description={"Médiane"} />
            <DashboardCard number={weekRows && weekRows.summaryTable?.initialCapital.q3} description={"Q3"} />
            <DashboardCard number={weekRows && weekRows.summaryTable?.initialCapital.max} description={"Max"} />
            <DashboardCard number={weekRows && weekRows.summaryTable?.initialCapital.avg} description={"Moyenne"} />
          </div>
        </div>
        <div className="flex justify-end gap-x-2">
          <div className="bg-green-400 text-xs font-semibold p-1 rounded-full"> K départ </div>
          <div className="bg-cyan-400 text-xs font-semibold p-1 rounded-full"> Ajout K récurrent</div>
          <div className="bg-yellow-300 text-xs font-semibold p-1 rounded-full"> Expansion de K</div>
        </div>
        {weekRows ? <TableauGrid column={columnsCapital} dataRows={weekRows && weekRows.details} isOnboarding hasDate /> : <Loading />}
      </div>
    </div>
  );
}

export default OnboardingMonth;
