import { ArrowLeftIcon, XIcon } from '@heroicons/react/solid';
import { DataGrid, frFR, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Controls from '../components/controls/Controls';
import DialogBox from '../components/DialogBox';
import Loading from '../components/Loading';
import ModifPnl from '../components/ModifPnl';
import TableauGrid from '../components/TableauGrid';
import TransactionListUser from '../components/TransactionListUser';
import { useFOrm } from '../components/useFOrm';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { decryptCBData } from '../utils/service';

const initialFvalues = {
    paymentMethodId: "",
    isComplete: ""
};

function TransactionScreen(props) {
    const { id } = useParams(); // maka id
    const [allSub, setAllSub] = useState()
    const axiosPrivate = useAxiosPrivate()
    const { t } = useTranslation()
    const [openTransaction, setOpenTransaction] = useState(false)
    const [selectedRow, setSelectedRow] = useState()
    const [openEditTransaction, setOpenEditTransaction] = useState(false)
    const [pm, setPm] = useState()
    const [listTransaction, setListTransaction] = useState()

    const validate = (fieldValues = values) => {
        const temp = { ...errors };

        // if ("pName" in fieldValues) temp.pName = fieldValues.pName ? "" : t("error.required");

        setErrors({
            ...temp,
        });
        if (fieldValues == values) {
            return Object.values(temp).every((x) => x == "");
        }
    };

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    const getPaymentMeth = async () => {
        try {
            const { data } = await axiosPrivate.get(`/api/payments/m/`)
            setPm(data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const handleGetAllSub = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/subscriptions?uid=${id}&transactions=2`, {
                    signal: controller.signal,
                })
                setAllSub(data.rows)
                console.log("allSub", data.rows);
            } catch (error) {
                console.log(error);
            }
        }
        getPaymentMeth()
        handleGetAllSub();
    }, [])

    const handleOpenTransaction = async (transactionRow) => {
        try {

            setOpenTransaction(true)
            const { data } = await axiosPrivate.get(`api/transactions?uid=${transactionRow.uid}`)
            setListTransaction(data)

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (selectedRow) {
            handleOpenTransaction(selectedRow)
        }
    }, [openEditTransaction])


    return (
        <div className="p-4 flex flex-col  gap-2 h-[93vh]" >
            <div className="font-semibold text-2xl text-slate-700 dark:text-white">Modification PNL</div>
            <div className="w-full flex justify-center bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 h-auto text-sm" >
                <div className='w-[300px] flex justify-center'><ModifPnl uid={id} /></div>
            </div>

            <div className="font-semibold text-2xl text-slate-700 dark:text-white">Transaction</div>
            <div className=" bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 h-auto text-sm" >

                {/* <div className="h-[200px] bg-slate-100 dark:bg-slate-800 mt-2">
                    {allSub ? <TableauGrid column={column} dataRows={allSub && allSub} showQuickFilter /> : <Loading />}
                </div> */}
                {allSub?.length > 0 ? <div>
                    {allSub?.map((s) => (
                        <div className='flex flex-col space-y-2'>
                            <div className='font-bold'> {decryptCBData(s.u.email)}</div>
                            <div className='flex space-x-20'>
                                <div>
                                    <div> Produit : <b className='text-xs'>{s?.product?.name}</b> </div>
                                    <div>Plan : <b className='text-xs'>{s?.plan}</b> </div>
                                </div>
                                <div>
                                    <div>Durée : {s?.totalDuration}</div>
                                    <div>status : {s?.isActive ? <span className='text-green-500 text-xs font-bold'>Active</span> : <span className='text-red-500 text-xs font-bold'>Stop</span>}</div>
                                </div>
                            </div>
                            <div className=''> <TransactionListUser oneSub={s} /></div>
                        </div>
                    ))}
                </div> :
                    <div className='text-center'>Pas de transaction</div>
                }

            </div>
            <DialogBox isOpen={openTransaction} setIsOpen={setOpenTransaction} customClassName="w-full md:w-1/2 " title={"Détails transactions"}>
                <div className='flex flex-col space-y-2 text-xs'>
                    <div className=''>

                        Transaction pour :  <strong>{decryptCBData(selectedRow && selectedRow?.u?.email)}</strong>
                    </div>
                    <div className='text-xs'>
                        <div>
                            Produit : <strong>{selectedRow && selectedRow?.product?.name}</strong>
                        </div>
                    </div>
                    <TransactionListUser oneSub={selectedRow} />
                </div>
            </DialogBox>

        </div >
    )
}

export default TransactionScreen
