import React from "react";
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from "@mui/material";

import { useTranslation } from "react-i18next";
export default function Select(props) {
    const { t } = useTranslation();
    const { name, label, value, onChange, options, error = null, disabled, noneValue, defaultValue = '', width } = props;
    return (
        <FormControl variant="outlined" sx={{ width: "100%" }} {...(error && { error: true })} size="small">
            <InputLabel className="dark:text-white">{label}</InputLabel>
            <MuiSelect
                disabled={disabled}
                label={label}
                name={name}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                className={`dark:text-white dark:bg-slate-800 ${width ? width : " min-w-[20rem]"}`}
            >
                <MenuItem className="dark:text-white dark:bg-slate-800" value="">
                    {noneValue ? noneValue : t("none")}
                </MenuItem>
                {options.map((item) => (
                    <MenuItem className="dark:text-white dark:bg-slate-800" key={item.id} value={item.id}>
                        {item.title || item.name}
                    </MenuItem>
                ))}
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}
