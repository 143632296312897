import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist()


export const userState = atom({
    key: 'userState',
    default: {
        email: "",
        id: null,
        step: 0,
        lastName: "",
        firstName: "",
        tel: "",
        country: ""

    },
    // effects_UNSTABLE: [persistAtom],
});

export const codeState = atom({
    key: 'codeState',
    default: null,
    // effects_UNSTABLE: [persistAtom],
});

export const stepState = atom({
    key: 'stepState',
    default: null,
    effects_UNSTABLE: [persistAtom],
});

export const userInfoAutonome = atom({
    key: 'userInfoAutonome',
    default: {},
    effects_UNSTABLE: [persistAtom],
});
