import axios from "../axios";
import { axiosPrivate2 } from "../axios";
import axiosGlobal from "axios";
//import * as action from "../actions/types";
import jwt_decode from "jwt-decode";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import setAuthToken from "./setAuthToken";
/*export const getRefreshToken = async (navigate) => {
  try {

    const response = await axios.get(action.BASE_URL + '/api/users/token');
    const accessToken = response.data.accessToken;
    const decoded = jwt_decode(response.data.accessToken);
    const first_name = decoded.name
    const exp = decoded.exp
    const result = {
      accessToken,
      first_name,
      exp
    }
    return result
  } catch (error) {
    if (error.response) {
      navigate("/");
    }
  }
};
const axiosJWT = axios.create();
export const checkExpireTime = async (expire) => {
  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date();
    if (expire * 1000 < currentDate.getTime()) {
      const response = await axios.get(action.BASE_URL + '/api/users/token');
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      const decoded = jwt_decode(response.data.accessToken);
      ///console.log("DECODE EG", decoded)
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

};

export const getUsers = async (token) => {
  const response = await axiosJWT.get(action.BASE_URL + '/api/users/users', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const user = response.data
  return user
}*/

export const encryptCB = (value) => {
    return CryptoJS.AES.encrypt(value, process.env.REACT_APP_CRYPT).toString();
};

export const decryptCB = (value) => {
    try {
        const bytes = CryptoJS.AES.decrypt(value, process.env.REACT_APP_CRYPT);
        const original = bytes.toString(CryptoJS.enc.Utf8);
        return original;
    } catch (error) { }
};

export const decryptCBData = (value) => {
    try {
        const bytes = CryptoJS.AES.decrypt(value, process.env.REACT_APP_U_DATA_KEY);
        const original = bytes.toString(CryptoJS.enc.Utf8);
        return original;
    } catch (error) { }
};

export const encryptCBData = (value) => {
    try {
        return CryptoJS.AES.encrypt(value, process.env.REACT_APP_U_DATA_KEY).toString();
    } catch (error) { }
};



export const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLocaleLowerCase();
};

export const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
};

//allow only specific key code
export const specificKeyCode = (event) => {
    var key = event.which || event.keyCode;
    var ctrl = event.ctrlKey ? event.ctrlKey : key === 17 ? true : false;
    var cmd = event.keyCode ? event.keyCode : key === 91 ? true : false;

    if ((event.which === 86 && ctrl) || (event.which === 86 && cmd) || (event.which === 67 && ctrl) || event.which === 8 || event.which === 13 || event.which === 9) {
        console.log("Ctrl+V is pressed.");
    } else {
        // console.log("Ctrl+V is pressed.", event);
        event.preventDefault();
    }
};

//default value for details when user is created
export const defautValDetails = (response) => {
    try {
        let initialFvalues = {
            uid: response.data.id,
            apiKeyRo: encryptCB(`${response.data.id}aRo`),
            secureKeyRo: encryptCB(`${response.data.id}sRo`),
            apiKeyTransfer: encryptCB(`${response.data.id}aT`),
            secureKeyTransfer: encryptCB(`${response.data.id}skT`),
            apiKeyTrading: encryptCB(`${response.data.id}aTrade`),
            secureKeyTrading: encryptCB(`${response.data.id}skTrade`),
            binance_address: `${response.data.id}`,
            initialCapital: 0,
            memo: `${response.data.id}`,
            network: `${response.data.id}`,
            source_income: `${response.data.id}`,
            isSousCompte: false,
        };

        return initialFvalues;
    } catch (error) { }
};

//k Formatter
export const kFormatter = (num) => {
    return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k" : Math.sign(num) * Math.abs(num);
};
export const getInitital = (nameString) => {
    if (!nameString) return "";
    const fullName = nameString.split(" ");
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials.toUpperCase();
};

//reset password
export const requestNewPassword = async (email) => {
    try {
        const res = await axios.get(`/api/users/findBy/email/${email}`);
        if (res.data) {
            console.log(res.data);
            const uniqueID = uuidv4();
            await axios.put(`/api/users/${res.data.id}/resetPassword`, { refresh_token: uniqueID });
            console.log(uniqueID);

            navigator.clipboard.writeText(`https://cryptobulot.net/token-email/${email}/${uniqueID}`).then(
                () => {
                    toast.success("Lien de réinitialisation copié dans le presse-papiers", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                },
                (err) => {
                    toast.error("Please Tray again", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            );
        }
    } catch (error) { }
};

export const exportExcel = (data, fileName = "indice Crypto Bulot.xlsx") => {
    // Convert the JSON data to an array of arrays
    const arrayData = Object.keys(data).map((key) => Object.values(data[key]));
    // Add headers to the array
    const headers = Object.keys(data[0]);
    arrayData.unshift(headers);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(arrayData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate a binary string
    const binaryString = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Create a Blob object from the binary string
    const blob = new Blob([binaryString], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    // Save the Excel file to the user's device
    FileSaver.saveAs(blob, fileName);
};

export const averagePricePublic = (symbol) => {
    const getPrice = async () => {
        try {
            const response = await axiosGlobal.get(`${process.env.REACT_APP_BINANCE_URL}/api/v3/avgPrice?symbol=${symbol}`);
            //sample return btcusdt
            return Number(response.data.price);
        } catch (error) {
            console.log("Errors on averagePricePublic", error);
        }
    };
    getPrice();
};

const decryptCBDataLocal = (value) => {
    try {
        const bytes = CryptoJS.AES.decrypt(value, process.env.REACT_APP_U_DATA_KEY);
        const original = bytes.toString(CryptoJS.enc.Utf8);
        return original;
    } catch (error) { }
};

export const decryptOneUserInfo = (value) => {
    try {
        const decrypt = JSON.parse(decryptCBDataLocal(value));
        // console.log("decrypt--------------------------------", decrypt);

        for (let key in decrypt) {
            if (key === "firstName" || key === "lastName" || key == "email" || key == "tel") {
                decrypt[key] = decryptCBDataLocal(decrypt[key]);
            }
        }
        return decrypt;
    } catch (error) {
        console.log(error);
    }
};

const decryptCBLocal = (value) => {
    try {
        const bytes = CryptoJS.AES.decrypt(value, process.env.REACT_APP_CRYPT);
        const original = bytes.toString(CryptoJS.enc.Utf8);
        return original;
    } catch (error) { }
};
export const axiosBaseQuery = async ({ baseUrl = "", url = "", method = "GET", data = null }) => {
    try {
        const here = decryptCBLocal(localStorage.getItem("token"));

        await setAuthToken(here);
        const response = await axiosPrivate2.request({
            method,
            url: baseUrl + url,
            data,
        });
        return response.data;
    } catch (error) {
        console.log("--------------errrororororororororo---------", error);
        throw new Error(error.response.data.error);
    }
};
