import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuIndicator,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    NavigationMenuViewport,
} from "../ui/navigation-menu"

import { cn } from "../../lib/utils"
import React from "react";

export function NavMenu(props) {
    const { info } = props

    // return (
    //     <NavigationMenu>
    //         <NavigationMenuList className="bg-lime-400 flex items-center h-full">
    //             {/* Map over each menu item in the navMenu */}
    //             {info.map((menu, index) => (
    //                 <NavigationMenuItem key={index}>
    //                     {/* Render the menu label */}
    //                     <NavigationMenuTrigger className="h-2 bg-transparent">
    //                         {menu.label}
    //                     </NavigationMenuTrigger>
    //                     {/* Render the menu content */}
    //                     <NavigationMenuContent className="flex flex-col bg-red-400">
    //                         {/* Map over each item in the 'info' array of the current menu */}
    //                         {menu.info.map((item, i) => (
    //                             <NavigationMenuLink key={i} onClick={item.onClick}>
    //                                 {/* Render the title of each item */}
    //                                 {item.title}
    //                             </NavigationMenuLink>
    //                         ))}
    //                     </NavigationMenuContent>
    //                 </NavigationMenuItem>
    //             ))}
    //         </NavigationMenuList>
    //     </NavigationMenu>

    // )


    return (
        <NavigationMenu className=" " >
            <NavigationMenuList >
                {info?.map((m) => (
                    <NavigationMenuItem className="bg-rejd-500">

                        <NavigationMenuTrigger className="bg-transparent text-white ">
                            {m.label}
                        </NavigationMenuTrigger>

                        <NavigationMenuContent NavigationMenuContent className="flex flex-col " >
                            <ul className="grid gap-3 p-6 md:w-[300px] lg:w-[400px] lg:grid-cols-[1fr_1fr]">

                                {m.info.map((item, i) => (
                                    <>

                                        {/* <NavigationMenuLink className="w-40 " key={i} onClick={item.onClick}>
                                            <div>

                                                <div>
                                                    {item.icon}
                                                </div>
                                                <div>

                                                    {item.title}
                                                </div>
                                            </div>
                                        </NavigationMenuLink> */}
                                        <ListItem
                                            key={item.title}
                                            title={item.title}
                                            href={item.href}
                                            icon={item.icon}
                                        >
                                            {item.description}
                                        </ListItem>
                                    </>
                                ))}
                            </ul>

                        </NavigationMenuContent >
                    </NavigationMenuItem>
                )
                )}

            </NavigationMenuList>
        </NavigationMenu >

    )

}

const ListItem = React.forwardRef((props, ref) => {
    const { className, title, children, icon, ...rest } = props;
    return (
        <li>
            <NavigationMenuLink asChild>
                <a
                    ref={ref}
                    className={cn(
                        "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
                        className
                    )}
                    {...rest}
                >
                    <div className="text-sm font-medium leading-none " >
                        <div className="flex items-center space-x-1">
                            <div>
                                {icon}
                            </div>
                            <div>
                                {title}
                            </div>
                        </div>
                    </div>
                    {children &&
                        <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                            {children}
                        </p>
                    }

                </a>
            </NavigationMenuLink>
        </li>
    );
});

ListItem.displayName = "ListItem";
