import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { authSelector } from '../features/authSlice';
import { useState } from 'react';
import { useEffect } from 'react';
import { axiosBQ } from '../axios';
import TopBar from '../components/TopBar';
import { TypographyH3, TypographyMutedXS, TypographySmall, TypoTutoLink } from '../components/Typo';
import { ArrowLeft, Info, MoveLeft, TriangleAlert } from 'lucide-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

function NotificationScreen(props) {
    const { userId } = props
    console.log("----------------- userId", userId ? userId : "tsy misy userId");
    const { t } = useTranslation()
    const auth = useSelector(authSelector);
    const [hasNotif, setHasNotif] = useState(false)
    const [isloadingComponent, setIsLoadingComponent] = useState(false)
    const [notif, setNotif] = useState([])
    const [ticketUser, setTicketUser] = useState()
    const [mergedData, setMergedData] = useState([]);

    const [clickedId, setClickedId] = useState(null);
    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate()
    const goBack = () => navigate(-1);

    const getTicket = async (id) => {
        setIsLoadingComponent(true)
        try {
            const { data } = await axiosPrivate.get(`/api/planit/mycard?uid=${id}&isActive=0`)
            setTicketUser(data)
            console.log("All TICKET user", data);

            //Get notif from big query
            let dataSort = []
            let dataTicket = []

            if (userId && auth.role.includes(process.env.REACT_APP_SUPPORT)) {
                console.log("SUPPORT", userId, auth.role);
                const { data } = await axiosPrivate.get(`/api/planit/mycard?uid=${userId}&isActive=0`)
                setTicketUser(data)
                dataTicket = data
                console.log("Support All TICKET user", data);

                const response = await axiosBQ.get(`/notification/find?func=by_uid&uid=${userId}`)
                console.log("notif user jeren'ny support", response.data);
                const dataFilter = response.data.filter(item => item.active === true && item.type == 1);
                console.log("datafilter notif SUpport", dataFilter);
                dataSort = dataFilter.sort((a, b) => b.id - a.id)
            } else {
                console.log("CLIENT", userId, auth.role);
                const { data } = await axiosPrivate.get(`/api/planit/mycard?uid=${id}&isActive=0`)
                setTicketUser(data)
                dataTicket = data
                console.log("All TICKET user", data);

                const response = await axiosBQ.get(`/notification/find?func=by_uid&uid=${auth?.id}`)
                console.log("NOTIF BY ID", response.data);
                // Filter status != 3 (resolu) , wee need to display all active notif need to resolve
                const dataFilter = response.data.filter(item => item.active === true);
                console.log("NOTIF FILTER", dataFilter);
                dataSort = dataFilter.sort((a, b) => b.id - a.id)
            }
            // if (dataFilter.length > 0) {
            //     setHasNotif(true)
            // }


            // if (data ) {
            const formattedDataFromBQ = dataSort?.map(item => ({
                active: item.active,
                createdAt: moment(item.createdAt).format("YYYY-MM-DD "),
                createdBy: item.createdBy,
                endDate: moment(item.endDate).format("YYYY-MM-DD"),
                endTime: moment(item.endTime).format("HH:mm:ss") || "23:45:00",
                id: item.id,
                lastSeen: item.lastSeen,
                link: item.link,
                linkTitle: item.linkTitle,
                severity: item.severity,
                startDate: moment(item.startDate).format("YYYY-MM-DD"),
                startTime: moment(item.startTime).format("HH:mm:ss") || "00:00:00",
                status: item.status,
                text: item.text,
                title: item.title,
                type: item.type,
                uid: item.uid,
                visible: item.visible,
                nature: "notif"
            }));

            const formattedTicket = dataTicket.map(item => ({
                active: true,
                createdAt: moment(item.createdAt).format("YYYY-MM-DD"),
                createdBy: "Reeq",
                endDate: null,
                endTime: null,
                id: item.id,
                lastSeen: null,
                link: null,
                linkTitle: null,
                severity: 1,
                startDate: moment(item.createdAt).format("YYYY-MM-DD"),
                status: 1,
                text: item.commentMotif,
                title: item.motif.motif,
                type: 1,
                uid: item.uid,
                visible: true,
                nature: "ticket"
            }));

            const mergedArray = [...formattedDataFromBQ, ...formattedTicket];
            setMergedData(mergedArray);
            console.log("mergedArray", mergedArray, "formattedDataFromBQ", formattedDataFromBQ.length, "formattedTicket", formattedTicket.length, "dataSort", dataSort);
            setIsLoadingComponent(false)
            // }

        } catch (error) {
            console.log("getTicket", error)
        }
    }

    useEffect(() => {
        // getNotifByID()
        getTicket(auth.id)
    }, [])

    const updateNotif = async (nid, prevStatus) => {
        // setClick(!click)
        if (clickedId == nid) {
            setClickedId(null)
        } else {
            setClickedId(null)
            setClickedId(nid);
        }
        console.log("nid", nid);

        if (auth.role.includes(process.env.REACT_APP_CLIENT)) {
            try {
                let dataToPut = {
                    id: nid,
                    lastSeen: null
                }

                const changedIdx = mergedData.findIndex(n => n.id == nid)
                if (prevStatus == 1) {
                    dataToPut.status = 2
                    if (changedIdx !== -1) {
                        const updatedNotif = [
                            ...mergedData.slice(0, changedIdx),
                            {
                                ...mergedData[changedIdx],
                                // Here, update the properties you want to change
                                status: 2,
                            },
                            ...mergedData.slice(changedIdx + 1)
                        ];

                        // Now updatedNotif contains the updated array with the modified object
                        // You can set it to your state or use it as needed
                        console.log(updatedNotif);
                        console.log('Notification ');
                        // setNotif(updatedNotif)
                        setMergedData(updatedNotif)
                    } else {
                        console.log('Notification with the given ID not found');
                    }
                }
                console.log("changeIndex", changedIdx);

                const finalDataToPut = {
                    dataToPut: {
                        ...dataToPut
                    }
                }

                const { data } = await axiosBQ.put(`/notification/update-single`, finalDataToPut)
                console.log('finalDataToPut', finalDataToPut, notif);
                console.log('data', data);
            } catch (error) {
                console.error(error);
            }
        } else {
            console.log("TSY MISY UPDATE FA SUPPORT");
        }
    }


    function isStringEnclosedInCurlyBraces(str) { const regex = /^\{.*\}$/; return regex.test(str); }

    const formatError = (text) => {
        try {
            console.log("text", typeof text);
            let finalError = text
            if (isStringEnclosedInCurlyBraces(text)) {
                finalError = JSON.parse(text)
                console.log("final --", finalError);
                const code = finalError?.BSC ? JSON.parse(finalError?.BSC).msg : finalError?.msg
                // return t(`error.${Math.abs(code)}`)
                return code
            } else {
                return text
            }

            console.log("jsonComment", typeof finalError, finalError);

        } catch (error) {
            console.error("format Error", error);
        }
    }

    return (
        <>
            <div className={` flex justify-center  ${auth.role.includes(process.env.REACT_APP_SUPPORT) ? " " : "w-full"}`}>
                <div className="w-full md:w-2/3 lg:w-1/2 bg-slate-50 m-4 p-4 rounded">
                    {userId ? "" : <div className="flex items-center gap-x-2">
                        <div className="hover:bg-slate-200 rounded-full w-fit p-1.5 " onClick={goBack}>
                            <MoveLeft className='w-5 h-5 hover:cursor-pointer ' />
                        </div>
                        <div className="font-bold text-xl text-slate-700 dark:text-slate-100">{t(`button.notif`)}</div>
                    </div>}


                    <div className=' scrollbar '>
                        {mergedData.length > 0 ?

                            mergedData.map((d, i) => (
                                <div key={i}  >
                                    <div className={` ${d.severity === 1 ? "bg-red-100" : ""} rounded p-1 my-1`}
                                        // <div className={`${(d.status == 1 || d.status == 2 )? "bg-blue-200 font-bold" : d.status == 3 ? "bg-slate-100" : ""}  rounded p-1 my-1`}
                                        onClick={() => updateNotif(d.id, d.status)}
                                    >
                                        <div className='flex items-center gap-x-1'>
                                            {d.severity == 1 ? <TriangleAlert className='w-4 h-4 text-red-500' /> : <Info className='w-4 h-4 ' />}
                                            <TypographySmall className={'flex-1'} > {d.title}  </TypographySmall>
                                            {d.status == 1 && <div className='bg-blue-500 w-2 h-2 rounded-full' />}

                                        </div>
                                        <TypographySmall className={`${clickedId === d.id ? "" : "truncate"} ${d.status == 1 ? "font-bold text-slate-700" : "text-slate-500 font-normal"} hover:cursor-pointer `}> {formatError(d.text)} </TypographySmall>
                                        {/* {clickedId === d.id ? <TypoTutoLink className={"text-xs"}>{t(`button.viewLess`)}</TypoTutoLink> : <TypoTutoLink className={"text-xs"} >{t(`button.viewMore`)}</TypoTutoLink>} */}
                                        {d.linkTitle && (
                                            <TypographySmall className={`${clickedId === d.id ? "" : "truncate"}`}>
                                                {d.linkTitle} : <a href={d.link} target="_blank" className="hover:cursor-pointer">{d.link}</a>
                                            </TypographySmall>
                                        )}
                                        <TypographyMutedXS>{moment(d.createdAt).fromNow()}</TypographyMutedXS>
                                        {/* {d.linkTitle && <TypographyS className={`${click ? "" : "truncate"}`} >{d.linkTitle} : <a href={d.link} target="_blank" className="hover:cursor-pointer">{d.link}</a> </TypographyS>} */}
                                        <hr />
                                    </div>
                                </div>
                            ))
                            :
                            <>
                                {isloadingComponent ? <div className='h-64'>
                                    < Loading />
                                </div> :
                                    <TypographySmall> {t(`label.noNotif`)}</TypographySmall>
                                }
                            </>

                        }
                    </div>


                </div>
            </div>
        </>
    )
}

export default NotificationScreen
