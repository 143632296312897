import { createSlice } from "@reduxjs/toolkit";
import socket from '../utils/socket';
const initialState = []
export const onlineMemberSlice = createSlice({
    name: 'onlineMember',
    initialState,
    reducers: {
        setOnlineMember: (state, action) => {
            state.splice(0, state.length, ...action.payload);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(setOnlineMember, (state) => {
            socket.on('all_users', (connectedUsers) => {
                state.length = 0;
                state.push(...connectedUsers);
            });
        });
    }
})
export const { setOnlineMember } = onlineMemberSlice.actions;
export const onlineMemberSelector = (state) => state.onlineMember
export default onlineMemberSlice.reducer;
