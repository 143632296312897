import React, { useEffect } from 'react'
import { useFOrm, Form } from '../useFOrm';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import Controls from '../controls/Controls';
import * as services from '../../utils/service'
import axios from '../../axios';
import { toast } from "react-toastify";

const initialFvalues = {
  password: "",
  confPassword: ""
}
//
function ResetPassword(props) {
  const { t } = props
  const { email } = useParams();
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('password' in fieldValues)
      temp.password = (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/).test(fieldValues.password) ? "" : t("error.mdp")
    if ('confPassword' in fieldValues)
      temp.confPassword = (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/).test(fieldValues.confPassword) ? "" : t("error.mdp")
    setErrors({
      ...temp
    })

    if (fieldValues == values)
      return Object.values(temp).every(x => x == "");
  }

  const {
    values,
    setValues,
    errors,
    setErrors,
    resetForm,
    handleInputChange
  } = useFOrm(initialFvalues, true, validate)

  const formReset = [
    {
      type: "password",
      name: "password",
      label: t("label.password"),
      placeholder: "Enter your password",
      password: true,
      required: true,
    },
    {
      type: "password",
      name: "confPassword",
      label: t("label.confirmPassword"),
      placeholder: "******",
      password: true,
      required: true,
    },

  ];

  let navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    if (!location.state) {
      return navigate('/reset')
    }
    //if(location location?.state.email == null && location.state.token == null)
  }, [location])
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      console.log(values)

      const dataToUpdate = {

      }
      //check if confPassWord
      try {
        const res = await axios.put(`api/users/newPassword/${location.state.email}/${location.state.token}`, values)
        //await axios.put(`api/users/update/${location.state.email}/${location.state.token}`)
        if (res.data) {
          console.log(res)
          navigate('/login')
          toast.success("Votre mot de passe a été bien réinitialisé");
        }
      } catch (error) {
        toast.error("La confirmation ne correspond pas au mot de passe");
      }

    }

  }


  return (
    <div className='mt-6 w-sm max-w-sm mx-auto'>
      <div className='flex flex-col gap-y-3'>

        <h2 className=" text-center text-2xl font-extrabold text-gray-900 dark:text-slate-100">Nouveau mot de passe</h2>
        {/* <h2 className=" text-center text-2xl font-extrabold text-gray-900 dark:text-slate-100">{location.state ? location.state.email : null}</h2>
                <h2 className=" text-center text-2xl font-extrabold text-gray-900 dark:text-slate-100">{location.state ? location.state.token : null}</h2> */}
        <div className='text-sm font-light text-slate-900 dark:text-white'>
          <p>{`Veuillez entrer votre nouveau mot de passe en respectant les critères suivants`}</p>
          <ul className='font-medium'>{t("error.mdp").split(',').map(c => (
            <li>. {c}</li>
          ))} </ul>
        </div>

        <Form onSubmit={handleSubmit} noValidate>
          <div className='w-sm max-w-sm flex flex-col space-y-3'>

            {formReset.map((item, i) => {
              return (
                <div key={i}>
                  <Controls.Input
                    fullWidth={true}
                    label={item.label}
                    value={values[item.name]}
                    onChange={handleInputChange}
                    name={item.name}
                    type={item.type}
                    error={errors[item.name]}
                  />
                </div>
              );
            })}
            <div className='mt-5'>
              {/* <Controls.Button type="submit" text={t('button.login')} fullWidth /> */}
              <Controls.Button color={'bg-indigo-600 text-white hover:bg-indigo-700'} type="submit" text={t('button.reset')} widthFull />
            </div>

            <div className="text-xs flex justify-end">
              <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                {t('button.login')} ?
              </Link>
            </div>
          </div>
        </Form >


      </div>
    </div>
  )
}

export default ResetPassword
