import React from 'react';
import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
} from "../components/ui/pagination"

const PaginationComponent = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
    const pageCount = Math.ceil(totalItems / itemsPerPage);
    const pages = [...Array(pageCount).keys()].map(i => i + 1);

    return (
        <Pagination>
            <PaginationContent>
                <PaginationItem>
                    <PaginationPrevious href="#" />
                </PaginationItem>
                <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink first onClick={() => onPageChange(1)} />
                </PaginationItem>
                <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink previous onClick={() => onPageChange(currentPage - 1)} />
                </PaginationItem>
                {pages.map(page => (
                    <PaginationItem active={page === currentPage} key={page}>
                        <PaginationLink onClick={() => onPageChange(page)}>
                            {page}
                        </PaginationLink>
                    </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage >= pageCount}>
                    <PaginationLink next onClick={() => onPageChange(currentPage + 1)} />
                </PaginationItem>
                <PaginationItem disabled={currentPage >= pageCount}>
                    <PaginationLink last onClick={() => onPageChange(pageCount)} />
                </PaginationItem>
                <PaginationItem>
                    <PaginationNext href="#" />
                </PaginationItem>
            </PaginationContent>

        </Pagination>
    );

    return (
        <Pagination>
            <PaginationContent>
                <PaginationItem>
                    <PaginationPrevious href="#" />
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">1</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#" isActive>
                        2
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationEllipsis />
                </PaginationItem>
                <PaginationItem>
                    <PaginationNext href="#" />
                </PaginationItem>
            </PaginationContent>
        </Pagination>
    )
};

export default PaginationComponent;
