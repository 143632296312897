import { createSlice } from "@reduxjs/toolkit";

const initialState = null

export const tutoLinkSlice = createSlice({
    name: "tutoLink",
    initialState,
    reducers: {
        loadLink: (state, action) => (action.payload)

    }
})

export const { loadLink } = tutoLinkSlice.actions
export const tutoLinkSelector = (state) => state.tutoLink
export default tutoLinkSlice.reducer
