import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {}

export const monitoringSlice = createSlice({
    name: 'monitoring',
    initialState,
    reducers: {
        setMonitoringUsers: (state, action) => {
            return action.payload;
        },
        updateUsersProblems: (state, action) => {

            const newState = state.problems
            const changedIdx = newState.findIndex(u => (u.id == action.payload.uid))
            //const newArray = [ current(state)]
            return {
                ...state,
                problems: [
                    ...newState.slice(0, changedIdx),
                    {
                        ...newState[changedIdx],
                        by_followup: action.payload?.by_followup,
                        date_followup: action.payload?.date_followup || '',
                        comment_followup: action.payload?.comment_followup || ''
                    },
                    ...newState.slice(changedIdx + 1)]
            }


        },
        updateUsersProblemsList: (state, action) => {

            const newState = state.problems
            const changedIdx = newState.findIndex(u => (u.id == action.payload.uid))
            //const newArray = [ current(state)]
            const newArr = newState.splice(changedIdx, 1)

            //console.log(newArr)
            /*return {
                ...state,
                problems: newArr
            }*/

        },
        updateUsersList: (state, action) => {
            const changedIdx = state.findIndex(u => (u.id == action.payload.uid))
            //const newArray = [ current(state)]
            return [
                ...state.slice(0, changedIdx),
                {
                    ...state[changedIdx],
                    date_followup: action.payload?.date_followup || '',
                    comment_followup: action.payload?.comment_followup || ''
                },
                ...state.slice(changedIdx + 1)]
        }
    }
});

export const { setMonitoringUsers, updateUsersList, updateUsersProblems, updateUsersProblemsList, updateUsersProblemsViaTickets } = monitoringSlice.actions
export const selectAllUser = (state) => state.monitoring.all;
export const selectUsersActive = (state) => state.monitoring.active;
export const selectUsersWithProblems = (state) => state.monitoring.problems;
export const selectUsersStoped = (state) => state.monitoring.inactive;
export default monitoringSlice.reducer
