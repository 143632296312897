import { ExternalLinkIcon } from "@heroicons/react/solid";
import React from "react";
import ReactPlayer from "react-player";
import Controls from "../controls/Controls";
import DialogBox from "../DialogBox";

function Tuto(props) {
  const { t, isVideoOpen, setIsVideoOpen, url, title, text, bgColor } = props;
  return (
    <>
      <DialogBox isOpen={isVideoOpen} setIsOpen={setIsVideoOpen} title={title}>
        <div className="w-[800px] px-2 pb-2">
          <div className="relative pt-[56.25%] ">
            <ReactPlayer className="absolute top-0 left-0 " url={url} width="100%" height="100%" controls={true} />
          </div>
        </div>
      </DialogBox>
      <div className="text-sm flex justify-end">
        {/* <div className="flex gap-x-1 items-center bg-sky-300 px-2 py-1 text-slate-600 rounded mr-2 hover:bg-sky-500 hover:cursor-pointer hover:text-white" onClick={() => setIsVideoOpen(!isVideoOpen)}>
          <ExternalLinkIcon className="w-4  h-4 rounded-xl p-" />
          {t("label.tuto")}
        </div> */}
        <Controls.Button text={text} small xsFont icon={<ExternalLinkIcon className="w-4  h-4 rounded-xl p-" />} color={`${bgColor ? bgColor : "bg-sky-300 hover:bg-sky-400"} `} onClick={() => setIsVideoOpen(!isVideoOpen)} />
      </div>
    </>
  );
}

export default Tuto;
