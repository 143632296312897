import { FilePenLine, MoreHorizontal, MoveLeft } from 'lucide-react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { TypographyH4, TypographySmall, TypographyXS } from '../components/Typo';
import BtnShad from '../components/client/btnShadcn';
import { useSelector } from 'react-redux';
import { authSelector } from '../features/authSlice';
import { DialogDemo } from '../components/client/dialogShad';
import { InputShadcn } from '../components/client/InputShadcn';
import { Label } from '../components/ui/label';
import { useFOrm } from '../components/useFOrm';
import axios, { axiosBQ } from '../axios';
import { CheckShad } from '../components/client/CheckShad';
import moment from 'moment';
import { useEffect } from 'react';
import { useToast } from '../components/ui/use-toast';
import { DataTable } from '../components/client/data-table';
import SelectShad from '../components/client/SelectShad';
import { DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '../components/ui/dropdown-menu';
import { Textarea } from '../components/ui/textarea';
import TextAreaShad from '../components/client/TextAreaShad';

function ManageNotifByIntern(props) {
    const { t } = props;
    const auth = useSelector(authSelector);
    const navigate = useNavigate()
    const goBack = () => navigate(-1);
    const [addNotifLoading, setAddNotifLoading] = useState(false)
    const [typeNotif, setTypeNotif] = useState(false)
    const [allNotif, setAllNotif] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [prevRow, setPrevRow] = useState();
    const [finalDate, setFinalDate] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);



    const { toast } = useToast()

    const initialValues = {
        title: "",
        text: "",
        severity: 2,
        uid: "",
        link: "",
        linkTitle: "",
        startDate: null,
        endDate: null,
        typeNotif: 1,
        startHour: "",
        startMinutes: "",
        endHour: "",
        endMinutes: ""

    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        const urlRegex = /https:\/\/\S+/;
        // const urlRegex = /https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*/;

        if ("title" in fieldValues) temp.title = fieldValues.title ? "" : t("error.required");
        if ("text" in fieldValues) temp.text = fieldValues.text ? "" : t("error.required");
        if ("severity" in fieldValues) temp.severity = fieldValues.severity ? "" : t("error.required");
        if (values.link) {
            if ("link" in fieldValues) temp.link = urlRegex.test(fieldValues.link) ? "" : t("error.urlInvalid");
            if ("linkTitle" in fieldValues) temp.linkTitle = fieldValues.linkTitle ? "" : t("error.required");
        }
        if (values.startDate) {
            if ("startHour" in fieldValues) temp.startHour = fieldValues.startHour ? "" : t("error.required");
            if ("startMinutes" in fieldValues) temp.startMinutes = fieldValues.startMinutes ? "" : t("error.required");
        }
        if (values.startHour || values.startMinutes) {
            if ("startDate" in fieldValues) temp.startDate = fieldValues.startDate ? "" : t("error.required");
        }
        if (values.endDate) {
            if ("endHour" in fieldValues) temp.endHour = fieldValues.endHour ? "" : t("error.required");
            if ("endMinutes" in fieldValues) temp.endMinutes = fieldValues.endMinutes ? "" : t("error.required");
        }
        if (values.endHour || values.endMinutes) {
            if ("endDate" in fieldValues) temp.endDate = fieldValues.endDate ? "" : t("error.required");
        }

        setErrors({
            ...temp,
        });
        console.log("Temp", temp);

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);


    const handleOpenDialogboxForNewNotif = () => {
        resetForm()
        try {

        } catch (error) {

        }
    }

    const newNotif = [
        {
            name: "uid",
            label: "Client",
            id: "uid",
            type: "text",
            placeholder: "ID du client"
        },
        {
            name: "title",
            label: "Titre *",
            id: "title",
            type: "text",
            placeholder: "Titre"

        },
        // {
        //     name: "text",
        //     label: "Description *",
        //     id: "text",
        //     type: "text",
        //     placeholder: "Description"

        // },



    ]
    const newNotif2 = [

        {
            name: "link",
            label: "Url",
            id: "link",
            type: "text",
            placeholder: "https://example.com"
        },
        {
            name: "linkTitle",
            label: "Url titre",
            id: "linkTitle",
            type: "text",
            placeholder: "Titre du lien"

        },
    ]

    const severity = [
        { id: 1, item: "Important" },
        { id: 2, item: "Information" },
    ]

    const minutes = [
        { id: "00", item: "00" },
        { id: "15", item: "15" },
        { id: "30", item: "30" },
        { id: "45", item: "45" },
    ]

    const hours = Array.from({ length: 24 }, (_, i) => {
        const hour = String(i).padStart(2, '0');
        return { id: hour, item: hour };
    });
    //  console.log("hours", hours);

    // useEffect(() => {
    //     console.log("MIOVA");
    // }, [initialValues])

    const addNewNotif = async () => {
        if (validate()) {
            setAddNotifLoading(true)
            try {
                let dataToPost = {
                    title: values.title,
                    text: values.text,
                    severity: values.severity,
                    createdBy: auth?.id,
                    link: values.link,
                    linkTitle: values.linkTitle,
                    uid: values.uid
                }
                if (values.startDate) {
                    dataToPost.startDate = values.startDate ? values.startDate : null
                    dataToPost.startTime = values.startHour && values.startMinutes ? moment(`${values.startHour}:${values.startMinutes}:00`, "HH:mm:ss").format("HH:mm:ss") : null
                }
                if (values.endDate) {
                    dataToPost.endDate = values.endDate ? values.endDate : null
                    dataToPost.endTime = values.endHour && values.endMinutes ? moment(`${values.endHour}:${values.endMinutes}:00`, "HH:mm:ss").format("HH:mm:ss") : null

                }
                const finalDataToPost = {
                    dataToPost: {
                        ...dataToPost
                    }
                }
                const response = await axiosBQ.post(`${process.env.REACT_APP_BASE_URL_BQ}/notification/insert`, finalDataToPost)
                console.log("data", response);
                console.log("Add notif", dataToPost, finalDataToPost, values.startHour);
                if (response.status == 200) {
                    window.location.reload()
                    toast({
                        title: `Notification enregisté avec succès  `,
                        variant: "success"
                    })
                }

            } catch (error) {
                console.error(error);
                toast({
                    title: `${t(`error.${error.response.data.code}`)} `,
                    variant: "destructive"
                })
            }
            setAddNotifLoading(false)
        }
    }

    const getAllNotif = async () => {
        try {
            const { data } = await axiosBQ.get(`/notification/find`)
            const dataSort = data.sort((a, b) => b.id - a.id)
            console.log("GET NOTIF", dataSort);
            setAllNotif(dataSort)
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        getAllNotif()
    }, [])

    // remove all input when it isnt modified
    function updateField(field, values, prevRow, dataToPut) {
        if (values[field] === prevRow[field]) {
            delete dataToPut[field];
            console.log(`Mitovy ${field} - values:`, values[field], " - prev:", prevRow[field]);
        } else {
            dataToPut[field] = values[field];
            console.log(`TSY Mitovy ${field} - values:`, values[field], " - prev:", prevRow[field]);
        }
    }



    function processTimeField(field, timeValue, dateValue, values, prevRow, dataToPut) {
        if (timeValue === prevRow[field]) {
            // delete dataToPut[field];
            console.log(`delete dataToPut ${field} `);
        } else {
            dataToPut[field] = timeValue;
            if (field === 'startTime') {
                dataToPut['startDate'] = values.startDate;
            } else if (field === 'endTime') {
                dataToPut['endDate'] = values.endDate;
            }
        }
    }

    const updateNotif = async (nid) => {
        // setClick(!click)
        console.log("ID ", nid);
        setAddNotifLoading(true)

        try {

            const startTime = values.startHour && values.startMinutes ? moment(`${values.startHour}:${values.startMinutes}:00`, "HH:mm:ss").format("HH:mm:ss") : null
            const endTime = values.endHour && values.endMinutes ? moment(`${values.endHour}:${values.endMinutes}:00`, "HH:mm:ss").format("HH:mm:ss") : null

            let dataToPut = {
                id: nid,
                // lastSeen: null,
                title: values.title,
                text: values.text,
                uid: values.uid,
                severity: values.severity,
                link: values.link,
                linkTitle: values.linkTitle,
                // startDate: values.startDate,
                // endDate: values.endDate,
                // startTime: values.startHour && values.startMinutes ? moment(`${values.startHour}:${values.startMinutes}:00`, "HH:mm:ss").format("HH:mm:ss") : null,
                // endTime: values.endHour && values.endMinutes ? moment(`${values.endHour}:${values.endMinutes}:00`, "HH:mm:ss").format("HH:mm:ss") : null,
                // createdBy: auth?.id,
            }


            // Fields to check
            const fields = ['title', 'text', 'severity', 'uid', 'link', 'linkTitle'];

            // Process each field
            fields.forEach(field => updateField(field, values, prevRow, dataToPut));

            const prevRowFormatStartDate = moment(prevRow.startDate).format("YYYY-MM-DD")
            // check if the startDAte is changed
            if (moment(values.startDate).isSame(moment(prevRowFormatStartDate))) {
                console.log("Mitovy startDate - values:", values.startDate, " - prev:", moment(prevRow.startDate).format());
            } else {
                dataToPut.startDate = values.startDate;
                dataToPut.startTime = startTime;
                console.log("TSY Mitovy startDate - values:", values.startDate, " - prev:", moment(prevRow.startDate).format());
            }

            // check if the endDate is changed
            const prevRowFormatEndDate = moment(prevRow.endDate).format("YYYY-MM-DD")
            if (moment(values.endDate).isSame(moment(prevRowFormatEndDate))) {
                console.log("Mitovy endDate - values:", values.endDate, " - prev:", moment(prevRow.endDate).format());
            } else {
                dataToPut.endDate = values.endDate;
                dataToPut.endTime = endTime;
                console.log("TSY Mitovy endDate - values:", values.endDate, " - prev:", moment(prevRow.endDate).format());
            }

            // processField('startDate', values, prevRow, dataToPut, startTime);
            // processField('endDate', values, prevRow, dataToPut, endTime);
            processTimeField('startTime', startTime, values.startDate, values, prevRow, dataToPut);
            processTimeField('endTime', endTime, values.endDate, values, prevRow, dataToPut);

            const finalDataToPut = {
                dataToPut: {
                    ...dataToPut
                }
            }

            const response = await axiosBQ.put(`/notification/update-single`, finalDataToPut)
            console.log('data avy any am BQ -', response);
            console.log('data to update', finalDataToPut);
            if (response.status == 200) {
                window.location.reload()
                toast({
                    title: `Modification enregistrée avec succès.  `,
                    variant: "success"
                })
            }


        } catch (error) {
            console.error("updateNotif", error);
            toast({
                title: `${t(`error.${error.response.data.code}`)} `,
                variant: "destructive"
            })
        }
        setAddNotifLoading(false)

    }


    const columns = [
        // {
        //     accessorKey: "createdAt",
        //     header: "Date de création",
        //     cell: ({ row: { original } }) => {
        //         return moment(original.createdAt).format("DD-MM-YYYY")
        //     }
        // },
        {
            accessorKey: "id",
            header: "id notif",
        },
        {
            accessorKey: "title",
            header: "Titre",
            cell: ({ row: { original } }) => {
                return <TypographyXS>{original.title}</TypographyXS>
            }
        },
        {
            accessorKey: "text",
            header: "Description",
            cell: ({ row: { original } }) => {

                return <TypographyXS className="truncate">{`  ${original?.text.slice(0, 50)} ${original?.text.length > 50 ? "..." : ""}`}</TypographyXS>;

            }
        },
        {
            accessorKey: "severity",
            header: "Severity",
        },
        {
            accessorKey: "createdBy",
            header: "Créer par",
            cell: ({ row: { original } }) => {
                const by = original.createdBy;

                const namesMap = {
                    "8dc2a399-7e40-410e-8483-26c7d2a00d60": "Kim",
                    "87e4efd1-376f-4741-8cc7-76b771b05b76": "Sitraka",
                    "0e5c7589-ca34-4226-ae57-74bdf49789c2": "Mombris",
                    "11e88e9f-7a99-4123-9323-4b59d39e755d": "Melanie",
                    "14e022fc-3a6c-4257-8f30-8c1bfce6dbe8": "Annaelle",
                    "3cc53c7c-7bf9-4bd8-8d95-c284bc10e762": "Damien",
                    "7c7fbd68-a81b-4bc6-a9de-cde9617a9ce3": "Megane",
                    "ed3dd483-8cd6-4f8f-97f7-03cee5d3ae7f": "Treesi"
                };

                const name = namesMap[by] || by;

                return <TypographyXS>{name}</TypographyXS>;

            }
        },
        {
            accessorKey: "uid",
            header: "Client",
            cell: ({ row: { original } }) => {
                if (original.uid) {
                    return <TypographyXS TypographyXS > {original.uid}</TypographyXS >
                } else {
                    return <TypographyXS>Broadcast</TypographyXS>;
                }
            }
        },
        {
            accessorKey: "linkTitle",
            header: "Titre du lien",
            cell: ({ row: { original } }) => {
                if (original.linkTitle) {
                    return <TypographyXS>{original.linkTitle}</TypographyXS>
                } else {
                    return " - "
                }
            }
        },
        {
            accessorKey: "link",
            header: "Lien",
            cell: ({ row: { original } }) => {
                if (original.link) {
                    return <TypographyXS>{original.link}</TypographyXS>
                } else {
                    return " - "
                }
            }
        },
        {
            accessorKey: "visible",
            header: "Visible",
            cell: ({ row: { original } }) => {
                if (original.visible == true) {
                    return <div className='w-2 h-2 bg-green-500 rounded-full' />
                } else if (original.visible == false) {
                    return <div className='w-2 h-2 bg-red-500 rounded-full' />
                }
            }
        },
        {
            accessorKey: "startDate",
            header: "Date de début",
            cell: ({ row: { original } }) => {
                // return moment(original.startDate).format("DD-MM-YYYY")
                return <TypographyXS>{moment(original.startDate).format("DD-MM-YYYY")} {original.startTime}</TypographyXS>

            }
        },
        // {
        //     accessorKey: "startTime",
        //     header: "H début",
        //     // cell: ({ row: { original } }) => {
        //     //     return moment(original.startTime).format("HH:mm:ss")
        //     // }
        // },
        {
            accessorKey: "endDate",
            header: "Date de fin",
            cell: ({ row: { original } }) => {
                // return moment(original.endDate).format("DD-MM-YYYY")
                return <TypographyXS>{moment(original.endDate).format("DD-MM-YYYY")} {original.endTime}</TypographyXS>

            }
        },
        // {
        //     accessorKey: "endTime",
        //     header: "H  fin",
        //     // cell: ({ row: { original } }) => {
        //     //     return moment(original.endDate).format("DD-MM-YYYY")
        //     // }
        // },
        {
            id: "actions",
            header: "",
            cell: ({ row: { original } }) => {
                const idNotif = original.id
                return (
                    <>
                        {today > moment(original.createdAt).format("YYYY-MM-DD") ?
                            <BtnShad text={t(`button.update`)} size="small" onClick={() => {
                                setPrevRow(original)
                                setValues((prev) => ({
                                    ...prev,
                                    ...original,
                                    startDate: moment(original.startDate).format("YYYY-MM-DD"),
                                    endDate: moment(original.endDate).format("YYYY-MM-DD"),
                                    id: original?.id,
                                    title: original?.title,
                                    text: original?.text,
                                    uid: original?.uid,
                                    // startHour: original.startTime.split(":")[0],
                                    startHour: moment(original.startTime, "HH:mm:ss").hour().toString().padStart(2, '0'),
                                    startMinutes: moment(original.startTime, "HH:mm:ss").minute().toString().padStart(2, '0'),
                                    endHour: moment(original.endTime, "HH:mm:ss").hour().toString().padStart(2, '0'),
                                    endMinutes: moment(original.endTime, "HH:mm:ss").minute().toString().padStart(2, '0'),
                                }))
                                // initialValues.title = original?.title
                                console.log("VALUES", values, "original", original);

                                setIsDialogOpen(true)
                            }} />
                            :
                            <BtnShad text={t(`button.update`)} disabled size="small" />
                        }
                    </>
                )


            },
        },
    ]

    const today = moment().format('YYYY-MM-DD')
    const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');

    const hNow = moment().format('HH')
    const mNow = moment().format('mm')
    return (
        <div className="p-4  ">

            {/* MODIFIER NOTIF */}
            <DialogDemo
                isControlled
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                title={"Modifier la notification"}
                text={`ID notif : ${values.id}`}
                classy="w-full md:w-2/3 "
            >
                <>

                    <div className=''>
                        <TypographyXS>{finalDate && finalDate || "-"}</TypographyXS>
                        <div className="w-full  flex flex-col  lg:flex-row  gap-x-6    ">
                            <div className='w-full  '>

                                {newNotif.map((d) => (
                                    <div className=' '>
                                        <Label htmlFor="name" className="text-right ">
                                            {d?.label}
                                        </Label>
                                        <InputShadcn
                                            id={d?.id}
                                            onChange={handleInputChange}
                                            name={d.name}
                                            value={values[d.name]}
                                            error={errors[d.name]}
                                            label={d.label}
                                            type={d.type}
                                            placeholder={d.placeholder}
                                        />
                                    </div>
                                ))}
                                <TextAreaShad
                                    id={"text"}
                                    name={"text"}
                                    placeholder="Description"
                                    value={values["text"]}
                                    error={errors["text"]}
                                    onChange={handleInputChange}
                                    label={"Description *"}

                                />
                                <SelectShad
                                    className="mt-1"
                                    name="severity"
                                    option={severity}
                                    placeholder="Sévérité"
                                    onChange={handleInputChange}
                                    label="Sévérité"
                                    labelInput
                                    value={values.severity} />

                            </div>
                            <div className='w-full  '>
                                {newNotif2.map((d) => (
                                    <div className=' '>
                                        <Label htmlFor="name" className="text-right ">
                                            {d?.label}
                                        </Label>
                                        <InputShadcn
                                            id={d?.id}
                                            onChange={handleInputChange}
                                            name={d.name}
                                            value={values[d.name]}
                                            error={errors[d.name]}
                                            label={d.label}
                                            type={d.type}
                                            placeholder={d.placeholder}
                                        />
                                    </div>
                                ))}
                                <div className=' '>
                                    <Label htmlFor="name" className="text-right "> Date de début </Label>
                                    <div className='flex gap-x-2 '>
                                        <InputShadcn
                                            id={"startDate"}
                                            onChange={handleInputChange}
                                            name={"startDate"}
                                            value={values["startDate"]}
                                            error={errors["startDate"]}
                                            label={"startDate"}
                                            type={"date"}
                                            className=" w-36 "
                                            min={today}
                                        />
                                        <SelectShad
                                            className=" w-32 -mt-4 "
                                            name="startHour"
                                            option={values.startDate == today ? hours.filter((h) => Number(h.id) >= Number(hNow)) : hours}
                                            placeholder="Heures"
                                            onChange={handleInputChange}
                                            // label="Heures"
                                            labelInput
                                            value={values.startHour}
                                            error={errors["startHour"]}
                                            defaultValue={values.startHour}
                                        />
                                        <SelectShad
                                            className=" w-32 -mt-4 "
                                            name="startMinutes"
                                            option={values.startDate == today && values.startHour == hNow? minutes.filter((h) => Number(h.id) > Number(mNow)) : minutes}
                                            placeholder="minutes"
                                            onChange={handleInputChange}
                                            // label="Minutes"
                                            labelInput
                                            value={values.startMinutes}
                                            error={errors["startMinutes"]}
                                        />
                                    </div>
                                </div>

                                <div className=' '>
                                    <Label htmlFor="name" className="text-right "> Date de fin </Label>
                                    <div className='flex gap-x-2'>
                                        <InputShadcn
                                            id={"endDate"}
                                            onChange={handleInputChange}
                                            name={"endDate"}
                                            value={values["endDate"]}
                                            error={errors["endDate"]}
                                            label={"endDate"}
                                            type={"date"}
                                            className=" w-36 "
                                            min={values.startDate}
                                        // min={moment(values.startDate, "YYYY-MM-DD").subtract(1, "d").format("YYYY-MM-DD")}
                                        />
                                        <SelectShad
                                            className=" w-32 -mt-4 "
                                            name="endHour"
                                            option={values.startDate == values.endDate ? hours.filter((h) => Number(h.id) > Number(values.startHour)) : hours}
                                            placeholder="Heures"
                                            onChange={handleInputChange}
                                            // label="Heures"
                                            labelInput
                                            value={values.endHour}
                                            error={errors["endHour"]}
                                        />
                                        <SelectShad
                                            className=" w-32 -mt-4 "
                                            name="endMinutes"
                                            // option={values.startDate == values.endDate ? minutes.filter((h) => Number(h.id) > Number(values.startMinutes)) : minutes}
                                            option={minutes}  // Because endHour est supérieur à startHour
                                            placeholder="minutes"
                                            onChange={handleInputChange}
                                            // label="Minutes"
                                            labelInput
                                            value={values.endMinutes}
                                            error={errors["endMinutes"]}
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className='text-right'>
                            <BtnShad disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                onClick={() => {
                                    updateNotif(values?.id)
                                }}
                                text={`${t(`button.confirm`)} `}
                                // wFull
                                isLoading={addNotifLoading}
                                sendHistory={{ action: `Modif notif : ${values.title}`, who: `${values.uid ? values.uid : "Brodcast"}` }}
                            />
                        </div>


                    </div>
                </>

            </DialogDemo>


            <div className="flex items-center mx-2">
                <div className="flex items-center flex-1 my-2">
                    <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
                        <MoveLeft className="h-4 w-4 hover:cursor-pointer" onClick={goBack} />
                    </div>
                    <TypographyH4 className="">{t(`label.manageNotif`)}</TypographyH4>
                </div>
                <div className="">

                    {/* AJOUT NOTIF */}
                    <DialogDemo
                        trigger={<BtnShad disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                            onClick={handleOpenDialogboxForNewNotif}
                            text="Ajouter"
                        />}
                        title={"Créer une notification"}
                        text={"Vous pouvez créer une notification Brodcast ou individuel"}
                        classy="w-full md:w-2/3 "
                    >
                        <div className=''>
                            <div className="w-full  flex flex-col  lg:flex-row  gap-x-6    ">
                                <div className='w-full  '>
                                    {newNotif.map((d) => (
                                        <div className=' '>
                                            <Label htmlFor="name" className="text-right ">
                                                {d?.label}
                                            </Label>
                                            <InputShadcn
                                                id={d?.id}
                                                onChange={handleInputChange}
                                                name={d.name}
                                                value={values[d.name]}
                                                error={errors[d.name]}
                                                label={d.label}
                                                type={d.type}
                                                placeholder={d.placeholder}
                                            />
                                        </div>
                                    ))}
                                    {/* <Textarea placeholder="Description" /> */}
                                    <TextAreaShad
                                        id={"text"}
                                        name={"text"}
                                        placeholder="Description"
                                        value={values["text"]}
                                        error={errors["text"]}
                                        onChange={handleInputChange}
                                        label={"Description *"}

                                    />
                                    <SelectShad
                                        className="mt-1"
                                        name="severity"
                                        option={severity}
                                        placeholder="Sévérité"
                                        onChange={handleInputChange}
                                        label="Sévérité"
                                        labelInput
                                        value={values.severity} />


                                </div>

                                <div className='w-full  '>
                                    {newNotif2.map((d) => (
                                        <div className=' '>
                                            <Label htmlFor="name" className="text-right ">
                                                {d?.label}
                                            </Label>
                                            <InputShadcn
                                                id={d?.id}
                                                onChange={handleInputChange}
                                                name={d.name}
                                                value={values[d.name]}
                                                error={errors[d.name]}
                                                label={d.label}
                                                type={d.type}
                                                placeholder={d.placeholder}
                                            />
                                        </div>
                                    ))}
                                    <div className=' '>
                                        <Label htmlFor="name" className="text-right "> Date de début </Label>
                                        <div className='flex gap-x-2 '>
                                            <InputShadcn
                                                id={"startDate"}
                                                onChange={handleInputChange}
                                                name={"startDate"}
                                                value={values["startDate"]}
                                                error={errors["startDate"]}
                                                label={"startDate"}
                                                type={"date"}
                                                className=" w-36 "
                                                min={tomorrow}

                                            />
                                            <SelectShad
                                                className=" w-32 -mt-5 "
                                                name="startHour"
                                                option={hours}
                                                placeholder="Heures"
                                                onChange={handleInputChange}
                                                // label="Heures"
                                                labelInput
                                                value={values.startHour}
                                                error={errors["startHour"]}
                                            />
                                            <SelectShad
                                                className=" w-32 -mt-5 "
                                                name="startMinutes"
                                                option={minutes}
                                                placeholder="minutes"
                                                onChange={handleInputChange}
                                                // label="Minutes"
                                                labelInput
                                                value={values.startMinutes}
                                                error={errors["startMinutes"]}
                                            />
                                        </div>
                                    </div>

                                    <div className=' '>
                                        <Label htmlFor="name" className="text-right "> Date de fin </Label>
                                        <div className='flex gap-x-2'>
                                            <InputShadcn
                                                id={"endDate"}
                                                onChange={handleInputChange}
                                                name={"endDate"}
                                                value={values["endDate"]}
                                                error={errors["endDate"]}
                                                label={"endDate"}
                                                type={"date"}
                                                className=" w-36 "
                                                min={values.startDate}

                                            />
                                            <SelectShad
                                                className=" w-32 -mt-5 "
                                                name="endHour"
                                                option={values.startDate == values.endDate ? hours.filter((h) => Number(h.id) > Number(values.startHour)) : hours}
                                                placeholder="Heures"
                                                onChange={handleInputChange}
                                                // label="Heures"
                                                labelInput
                                                value={values.endHour}
                                                error={errors["endHour"]}
                                            />
                                            <SelectShad
                                                className=" w-32 -mt-5 "
                                                name="endMinutes"
                                                option={minutes}  // Because endHour est supérieur à startHour
                                                placeholder="minutes"
                                                onChange={handleInputChange}
                                                // label="Minutes"
                                                labelInput
                                                value={values.endMinutes}
                                                error={errors["endMinutes"]}
                                            />
                                        </div>
                                    </div>



                                </div>


                            </div>
                            <div className='text-right'>
                                <BtnShad disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                    onClick={addNewNotif}
                                    text={t(`button.confirm`)}
                                    // wFull
                                    isLoading={addNotifLoading}
                                    sendHistory={{ action: `Add notif : ${values.title}`, who: `${values.uid ? values.uid : "Brodcast"}` }}
                                />
                            </div>


                        </div>

                    </DialogDemo>
                </div>
            </div>
            {/* <div>
                <TextAreaShad
                    id={"text"}
                    name={"text"}
                    placeholder="Description"
                    value={values["text"]}
                    error={errors["text"]}
                    onChange={handleInputChange}
                    label={"Description *"}

                />
                <BtnShad text="textArea" onClick={() => {
                    console.log("AREA", values.text);
                }} />
            </div> */}
            <div className=" bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 h-full mt-2 ">
                <DataTable columns={columns} data={allNotif?.length > 0 ? allNotif : []} setCurrentPage={setCurrentPage} currentPage={currentPage} />
            </div>
        </div >
    )
}


export default ManageNotifByIntern
