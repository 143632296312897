import React, { useState, useEffect, useRef, useMemo } from "react";
import Sidebar from "../Sidebar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import moment from "moment";

import DialogBox from "../DialogBox";
import "moment/locale/fr";
import { UserGroupIcon, UserIcon, UserAddIcon, SwitchVerticalIcon, LinkIcon, CurrencyDollarIcon, CalendarIcon, InformationCircleIcon, GlobeAltIcon, ServerIcon, ChartPieIcon } from "@heroicons/react/outline";
import DashboradCard from "../cards/DashboardCard";
import Title from "../title/Title";

import TopBar from "../TopBar";
import { Icon } from "coinmarketcap-cryptocurrency-icons";
import * as sideMenus from "../../utils/sideMenus";
import PieChartCustom from "../chart/PieChartCustom";
import BoxplotCustom from "../chart/BoxplotCustom";
import { decryptCBData } from "../../utils/service";
import { useDispatch, useSelector } from "react-redux";
import { dashboardDataSelector, setDashboardData } from "../../features/dashboardSlice";
import { useQuery } from "react-query";
// import { ServerIcon, ChartPieIcon } from "@heroicons/react/outline";

moment.locale("fr");

const Myth = ({ text }) => <th className="p-3 text-sm font-semibold tracking-wide text-left"> {text}</th>;

const Mytd = ({ text }) => <td className="p-3 text-sm text-gray-700 whitespace-nowrap"> {text}</td>;
function Dashboard(props) {
    const { t } = props;
    const [price, setPrice] = useState(0);
    const dispatch = useDispatch();
    // const [users, setUsers] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [onboardingOpen, setOnboardingOpen] = useState(false);
    const [capitalOpen, setCapitalOpen] = useState(false);
    const [portefeuilleOpen, setPortefeuilleOpen] = useState(false);
    const [getResult, setGetResult] = useState(null);
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState({
        users: null,
        capital: null,
        capitalManaged: null,
        pnl: null,
        affiliate: null,
        planit: null,
        // Add other data properties as needed
    });

    /*useEffect(() => {
          client.current = new W3CWebSocket(endPoint);
          // socket.current.on("onmessage")
          client.current.onmessage = function (msg) {
              const trade = JSON.parse(msg.data); // parsing single-trade record
              const price = Number(trade.p).toFixed(2)
              setPrice(price);
          };
      }, []);*/

    /*useEffect(() => {
          //assign the ref's current value to the count Hook
          prevCountRef.current = price;
      }, [price]);*/

    //const { data, error, isLoading } = useGetAllUsersQuery();
    //console.log("---------------dartaaaa", data);
    // useEffect(() => {
    //   if (data) {
    //     setUsers(data);
    //     const filetrCbt = data.all.filter((f) => f.details.cbt == true);
    //     setCbt(data.active);
    //   }
    // }, [data]);

    //const users = useMemo(() => data, [data]);
    //const users = useMemo(() => usersList, [usersList]);
    const fetchData = async () => {
        // let isMounted = true;
        // const controller = new AbortController();
        try {
            const requests = [
                axiosPrivate.get(`/api/reporting/findAllUsers`),
                axiosPrivate.get(`/api/reporting/findCurrentCapital`),
                axiosPrivate.get(`/api/reporting/findCurrentCapitalManaged`),
                axiosPrivate.get(`/api/reporting/findAllPnl`),
                axiosPrivate.get(`/api/reporting/findAllAffiliate`),
                axiosPrivate.get(`/api/planit/stat`),
                // Add more requests here
            ];
            const [usersResponse, capitalResponse, capitalManagedResponse, pnlResponse, affiliateResponse, planitStatResponse] = await Promise.all(requests);
            // console.log("pnlResponse", pnlResponse);
            // isMounted && setUsers(usersResponse.data);
            // isMounted && setCapital(capitalResponse.data);
            // isMounted && setCapitalManaged(capitalManagedResponse.data);
            // isMounted && setPnl(pnlResponse.data);
            const decryptAffiliate = JSON.parse(decryptCBData(affiliateResponse.data));
            // isMounted && setAffiliate(decryptAffiliate);

            const dashboardData = {
                users: usersResponse.data,
                capital: capitalResponse.data,
                capitalManaged: capitalManagedResponse.data,
                pnl: pnlResponse.data,
                affiliate: decryptAffiliate,
                planit: planitStatResponse?.data
                // Add other data properties as needed
            };
            console.log("lllllll", dashboardData.users);
            dispatch(setDashboardData(dashboardData));
            // Set other state variables
            // Rest of the code
        } catch (error) {
            console.log(error);
            // Handle errors
        }
    };
    // Call the fetchData function
    // useEffect(() => {
    //   fetchData();
    // }, []);
    useEffect(() => {
        const fetchDataAndSetData = async () => {
            const fetchedData = await fetchData();
            //setData(fetchedData);
        };
        fetchDataAndSetData();
    }, []);
    //const { users = null, capital = null, capitalManaged = null, pnl = null, affiliate = null } = useMemo(() => data, [data]);
    const { users, capital, capitalManaged, pnl, affiliate, planit } = useSelector(dashboardDataSelector);
    /*useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      const getUsers = async () => {
        try {
          const { data } = await axiosPrivate.get(`/api/reporting/findAllUsers`, {
            signal: controller.signal,
          });
          isMounted && setUsers(data);
          console.log("USERS", data);
          //const filetrCbt = data.all.filter((f) => f.details.cbt == true);
          //console.log("CBT", filetrCbt);
          isMounted && setCbt(data.active);
        } catch (error) {
          console.log(error);
          //navigate('/login', { state: { from: location }, replace: true })
        }
      };

      const getCapital = async () => {
        try {
          const response = await axiosPrivate.get(`/api/reporting/findCurrentCapital`, {
            signal: controller.signal,
          });
          console.log("K=====", response.data);
          isMounted && setCapital(response.data);
        } catch (error) {
          console.log(error);
          //navigate('/login', { state: { from: location }, replace: true })
        }
      };

      const getCapitalManaged = async () => {
        try {
          const response = await axiosPrivate.get(`/api/reporting/findCurrentCapitalManaged`, {
            signal: controller.signal,
          });
          isMounted && setCapitalManaged(response.data);
        } catch (error) {
          //console.log(error)
          //navigate('/login', { state: { from: location }, replace: true })
        }
      };

      const getPnl = async () => {
        try {
          const response = await axiosPrivate.get(`/api/reporting/findAllPnl`, {
            signal: controller.signal,
          });
          isMounted && setPnl(response.data);
        } catch (error) {
          console.log(error);
          //navigate('/login', { state: { from: location }, replace: true })
        }
      };

      const getAffiliate = async () => {
        try {
          const response = await axiosPrivate.get(`/api/reporting/findAllAffiliate`, {
            signal: controller.signal,
          });
          isMounted && setAffiliate(response.data);
        } catch (error) {
          console.log(error);
          //navigate('/login', { state: { from: location }, replace: true })
        }
      };
      //getUsers();

      // getPnl();

      // getCapitalManaged();

      //getCapital();
      // getAffiliate();

      return () => {
        isMounted = false;
        controller.abort();
      };
    }, []);*/

    const columns = [
        { label: "Id", accessor: "fullName", sortable: true },
        { label: "First Name", accessor: "firstName", sortable: false },
        { label: "email", accessor: "email", sortable: true },
    ];



    return (
        <>
            <DialogBox isOpen={isOpen} setIsOpen={setIsOpen} title="Informations">
                <div className="p-4 ml-5 text-sm leading-relaxed text-gray-500 dark:text-gray-400">
                    <div>▪️ Total = Clients actifs + Onboarding en cours + Avec problème + Résiliés </div>
                    <div>▪️ Clients actifs = Clients toujours actifs ayant eu au moins 1 rééquilibrage, et représente las somme des Top</div>
                    <div>▪️ Onboarding en cours = Clients toujours actifs ayant eu un 1er call mais pas finalisé et pas de rééquilibrage</div>
                    <div>▪️ Avec problème = Clients actifs ayant eu au moins 1 rééquilibrage et avec un problème après X rééquilibrage</div>
                </div>
            </DialogBox>
            {/* Onboarding Details */}
            <DialogBox isOpen={onboardingOpen} setIsOpen={setOnboardingOpen} title="Informations">
                <div className="flex space-x-8 p-4 ml-5 text-sm leading-relaxed text-gray-600 dark:text-gray-400">
                    <div className="bg-indigo-100 p-2 rounded">
                        <div className="font-semibold pb-2">
                            {t(`label.nbSubscription`)} {"->"}
                            {t(`label.nbFirstCall`)}
                        </div>
                        <div>
                            ▪️ {t(`label.min`)} = {users && users.minDaysOnboardingToFirstCall}
                        </div>
                        <div>▪️ Q1 = {users && users.q1ToFirstCall}</div>
                        <div>
                            ▪️ {t(`label.median`)} = {users && users.medianToFirstCall}
                        </div>
                        <div className="font-semibold text-blue-800">
                            ▪️ {t(`label.average`)} = {users && users.avgDaysOnboardingToFirstCall}
                        </div>
                        <div>▪️ Q3 = {users && users.q3ToFirstCall}</div>
                        <div>
                            ▪️ {t(`label.max`)} = {users && users.maxDaysOnboardingToFirstCall}
                        </div>
                    </div>
                    <div className="bg-indigo-100 p-2 rounded">
                        <div className="font-semibold pb-2">
                            {t(`label.nbFirstCall`)} {"->"} {t(`label.nbFirstRebal`)}
                        </div>
                        <div>
                            ▪️ {t(`label.min`)} = {users && users.minDaysOnboardingCallToRebal}
                        </div>
                        <div>▪️ Q1 = {users && users.q1CallToRebal}</div>
                        <div>
                            ▪️ {t(`label.median`)} = {users && users.medianCallToRebal}
                        </div>
                        <div className="font-semibold text-blue-800">
                            ▪️ {t(`label.average`)} = {users && users.avgDaysOnboardingCallToRebal}
                        </div>
                        <div>▪️ Q3 = {users && users.q3CallToRebal}</div>
                        <div>
                            ▪️ {t(`label.max`)} = {users && users.maxDaysOnboardingCallToRebal}
                        </div>
                    </div>
                    <div className="bg-indigo-100 p-2 rounded">
                        <div className="font-semibold pb-2">
                            {t(`label.nbSubscription`)} {"->"} {t(`label.nbFirstRebal`)}
                        </div>
                        <div>
                            ▪️ {t(`label.min`)} = {users && users.minDaysOnboarding}
                        </div>
                        <div>▪️ Q1 = {users && users.q1}</div>
                        <div>
                            ▪️ {t(`label.median`)} = {users && users.median}
                        </div>
                        <div className="font-semibold text-blue-800">
                            ▪️ {t(`label.average`)} = {users && users.avgDaysOnboarding}
                        </div>
                        <div>▪️ Q3 = {users && users.q3}</div>
                        <div>
                            ▪️ {t(`label.max`)} = {users && users.maxDaysOnboarding}
                        </div>
                    </div>
                </div>
            </DialogBox>

            {/* MBOLA TSY MIASA */}
            <DialogBox isOpen={capitalOpen} setIsOpen={setCapitalOpen} title="Informations ">
                <div className="flex justify-center p-4  ml-5 text-sm text-gray-600 dark:text-gray-400">
                    <div className="bg-indigo-100 p-4 rounded">
                        <div>
                            ▪️ {t(`label.min`)} = {capital && capital.minInitial.length}
                        </div>
                        <div>▪️ Q1 = {capital && capital.q1.length}</div>
                        <div>
                            ▪️ {t(`label.median`)} = {capital && capital.median.length}
                        </div>
                        <div className="font-semibold text-blue-800">
                            ▪️ {t(`label.average`)} = {capital && capital.avgInitialUsdt.length}
                        </div>
                        <div>▪️ Q3 = {capital && capital.q3.length}</div>
                        <div>
                            ▪️ {t(`label.max`)} = {capital && capital.maxInitial.length}
                        </div>
                    </div>
                </div>
            </DialogBox>
            <DialogBox isOpen={portefeuilleOpen} setIsOpen={setPortefeuilleOpen} title="Informations">
                <div className="flex justify-center p-4  ml-5 text-sm text-gray-600 dark:text-gray-400">
                    <div className="bg-indigo-100 p-4 rounded">
                        <div>
                            ▪️ {t(`label.min`)} = {capital && capital.minInitial}
                        </div>
                        <div>▪️ Q1 = {capital && capital.q1}</div>
                        <div>
                            ▪️ {t(`label.median`)} = {capital && capital.median}
                        </div>
                        <div className="font-semibold text-blue-800">
                            ▪️ {t(`label.average`)} = {capital && capital.avgInitialUsdt}
                        </div>
                        <div>▪️ Q3 = {capital && capital.q3}</div>
                        <div>
                            ▪️ {t(`label.max`)} = {capital && capital.maxInitial}
                        </div>
                    </div>
                </div>
            </DialogBox>

            {/* /////////////////// DialogBOX ////////////////////////////////// */}

            <TopBar menus={sideMenus.menusAdmin(t)} />

            <div className="flex flex-row flex-grow ">
                <Sidebar menus={sideMenus.menusAdmin(t)} />
                <div className="flex flex-row flex-1  mt-2 md:ml-52">
                    {/* <div className='flex flex-col w-full md:w-4/5 p-4'> */}
                    <div className="flex flex-col w-full px-2">
                        {/* Title section */}
                        <div className="flex-none h-auto md:p-4 space-y-3">
                            <h1 className="font-semibold text-2xl text-slate-900 dark:text-white">{t("label.dashboard")}</h1>
                            <span className="text-xs text-slate-500 dark:text-slate-400 ">{moment().format("Do MMMM YYYY")}</span>

                            <hr />
                        </div>

                        <div className="flex w-auto space-x-2">
                            {/*Générales*/}
                            <div className="flex-1 space-y-2 ">
                                <div className="   p-4 bg-slate-100">
                                    <Title.SectionTitle
                                        text={"Générales"}
                                        icon={
                                            <div className="flex space-x-2 items-center">
                                                <GlobeAltIcon className="h-5 w-5 text-blue-700 text-xs" />
                                                <InformationCircleIcon className="h-4 w-4 text-slate-600 hover:cursor-pointer" onClick={() => setIsOpen(true)} />
                                            </div>
                                        }
                                        path={`/admin/analytics/client`}
                                    // 	onClick={navigate(`/rebalancing/${id}`)}
                                    />
                                    <div className="flex gap-x-3 rounded-sm">
                                        <DashboradCard number={users && users.all} description={t(`label.sum`)} />
                                        <DashboradCard
                                            // bg={"bg-blue-500"}
                                            number={users && users.active}
                                            description={t(`label.userActif`)}
                                        />
                                        <DashboradCard
                                            //bg={"bg-blue-500"}
                                            number={users && users.notRebalanced}
                                            description={t(`label.onBoardingInProgress`)}
                                        />
                                        <DashboradCard
                                            //bg={"bg-blue-500"}
                                            number={users && users.problems}
                                            description={t(`label.withProblem`)}
                                        />
                                        <DashboradCard number={users && users.inactive} description={"Résiliés"} />
                                        <DashboradCard
                                            //bg={"bg-blue-500"}
                                            number={capitalManaged && capitalManaged.current}
                                            description={t(`label.enGestionActuel`)}
                                            price={price}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row gap-x-2 gap-y-2 h-[50vh]">


                                    <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm w-1/3">
                                        <Title.SectionTitle
                                            text={"Répartition par top"}
                                            // unit={`(${users && users.all.length})`}
                                            icon={<ChartPieIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                        />
                                        <div className="">
                                            <PieChartCustom
                                                data={[
                                                    { name: "Top 2", value: capital && capital.nbTop2 },
                                                    { name: "Top 5", value: capital && capital.nbTop5 },
                                                    { name: "Top 10", value: capital && capital.nbTop10 },
                                                    { name: "Top 30", value: capital && capital.nbTop30 },
                                                ]}
                                                colors={["#f97316", "#facc15", "#0284c7", "#22c55e"]}
                                                //colors={["#0284c7", "#0ea5e9", "#1d4ed8", "#1e3a8a"]}
                                                width={300}
                                                height={300}
                                            />
                                        </div>
                                    </div>
                                    {/* PNL */}

                                    <div className=" bg-slate-100 dark:bg-slate-800 p-4 rounded-sm w-1/3">
                                        <Title.SectionTitle
                                            text="PNL"
                                            icon={<SwitchVerticalIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                            unit={`(${pnl && pnl.totalFivePercent} USDT)`}
                                            path={`/admin/analytics/pnl`}
                                        />
                                        <div className="flex flex-wrap justify-start sm:flex-row sm:flex-wrap gap-x-3 gap-y-3">
                                            <PieChartCustom
                                                data={[
                                                    { name: t(`label.cashed`), value: pnl && pnl.totalFivePercentPaid },
                                                    { name: t(`label.resteCashed`), value: pnl && pnl.totalFivePercentRemaining },
                                                ]}
                                                colors={["#0284c7", "#22c55e"]}
                                                //colors={["#0284c7", "#1e3a8a", "#0ea5e9", "#1d4ed8"]}
                                                width={300}
                                                height={300}
                                            />
                                        </div>
                                    </div>
                                    {/* Section ticket */}
                                    <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm w-1/3">
                                        <Title.SectionTitle
                                            text={t(`label.tickets`)}
                                            unit={`(${planit && planit.cardActiveCount}) `}
                                            icon={<ServerIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                            path={`/analytics-ticket`}
                                        />

                                        <div className="">
                                            <PieChartCustom
                                                data={[
                                                    { name: "tickets non traités", value: planit?.cardLateNotHandled },
                                                    { name: "ticket OK", value: planit?.cardActiveCount - planit?.cardLateNotHandled },
                                                ]}
                                                colors={["#f97316", "#3b82f6"]}
                                                width={300}
                                                height={300}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Onboarding */}
                                <div className=" bg-slate-100 dark:bg-slate-800 p-4 rounded-sm">
                                    <Title.SectionTitle text="Onboarding " icon={<CalendarIcon className="h-5 w-5 text-blue-700 text-xs" />} unit="(Jour)" />

                                    {/* Boxplot Onboarding*/}
                                    <div className="h-72 hover:cursor-pointer" onClick={() => setOnboardingOpen(true)}>
                                        <BoxplotCustom
                                            boxPlots={[
                                                {
                                                    name: ` ${t(`label.nbSubscription`)} -> ${t(`label.nbFirstCall`)}`,
                                                    min: users && users.minDaysOnboardingToFirstCall,
                                                    lowerQuartile: users && users.q1ToFirstCall,
                                                    median: users && users.medianToFirstCall,
                                                    upperQuartile: users && users.q3ToFirstCall,
                                                    max: users && users.maxDaysOnboardingToFirstCall,
                                                    average: users && users.avgDaysOnboardingToFirstCall,
                                                },
                                                {
                                                    name: ` ${t(`label.nbFirstCall`)}  -> ${t(`label.nbFirstRebal`)} `,
                                                    min: users && users.minDaysOnboardingCallToRebal,
                                                    lowerQuartile: users && users.q1CallToRebal,
                                                    median: users && users.medianCallToRebal,
                                                    upperQuartile: users && users.q3CallToRebal,
                                                    max: users && users.maxDaysOnboardingCallToRebal,
                                                    average: users && users.avgDaysOnboardingCallToRebal,
                                                },
                                                {
                                                    name: ` ${t(`label.nbSubscription`)} -> ${t(`label.nbFirstRebal`)}`,
                                                    min: users && users.minDaysOnboarding,
                                                    lowerQuartile: users && users.q1,
                                                    median: users && users.median,
                                                    upperQuartile: users && users.q3,
                                                    max: users && users.maxDaysOnboarding,
                                                    average: users && users.avgDaysOnboarding,
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>

                                <div className="flex space-x-2">
                                    {/* capital BoxPlot */}
                                    {/* <div className="bg-slate-100 w-1/3 dark:bg-slate-800 p-4 rounded-sm">
                    <Title.SectionTitle text={t(`label.capital`)} icon={<SwitchVerticalIcon className="h-5 w-5 text-blue-700 text-xs" />} unit="(USDT)" />

                    <div className="">
                      <div className="h-72 w-72" onClick={() => setCapitalOpen(true)}>
                        <BoxplotCustom
                          boxPlots={[
                            {
                              name: t(`label.capital`),
                              min: capital && capital.minInitial,
                              lowerQuartile: capital && capital.q1,
                              median: capital && capital.avgInitialUsdt,
                              upperQuartile: capital && capital.q3,
                              max: capital && capital.maxInitial,
                              average: capital && capital.avgInitialUsdt,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div> */}

                                    {/* capital BoxPlot */}
                                    <div className="bg-slate-100 w-1/2 dark:bg-slate-800 p-4 rounded-sm">
                                        <Title.SectionTitle text={t(`label.userWallet`)} icon={<SwitchVerticalIcon className="h-5 w-5 text-blue-700 text-xs" />} />
                                        <div className="">
                                            <div className="h-72 w-72" onClick={() => setPortefeuilleOpen(true)}>
                                                <BoxplotCustom
                                                    boxPlots={[
                                                        {
                                                            name: t(`label.capital`),
                                                            min: capital && capital.minInitial,
                                                            lowerQuartile: capital && capital.q1,
                                                            median: capital && capital.avgInitialUsdt,
                                                            upperQuartile: capital && capital.q3,
                                                            max: capital && capital.maxInitial,
                                                            average: capital && capital.avgInitialUsdt,
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* capital BoxPlot */}
                                    <div className="bg-slate-100 w-1/2 dark:bg-slate-800 p-4 rounded-sm">
                                        <Title.SectionTitle text={t(`label.Kactif`)} icon={<SwitchVerticalIcon className="h-5 w-5 text-blue-700 text-xs" />} />

                                        <div className="">
                                            <div className="h-72 w-72" onClick={() => setPortefeuilleOpen(true)}>
                                                <BoxplotCustom
                                                    boxPlots={[
                                                        {
                                                            name: t(`label.capital`),
                                                            min: capital && capital.minInitial,
                                                            lowerQuartile: capital && capital.q1,
                                                            median: capital && capital.avgInitialUsdt,
                                                            upperQuartile: capital && capital.q3,
                                                            max: capital && capital.maxInitial,
                                                            average: capital && capital.avgInitialUsdt,
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Partenaires */}
                                <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm">
                                    <Title.SectionTitle text={t(`label.partenaires`)} icon={<UserAddIcon className="h-5 w-5 text-blue-700 text-xs" />} unit={`(${affiliate && affiliate.length})`} />

                                    <div className="flex flex-wrap justify-start sm:flex-row sm:flex-wrap gap-x-3 gap-y-3"></div>

                                    {/* <Table columns={columns} tableData={users && users.all} /> */}
                                    <div className="overflow-auto roundex-lg shadow hidden md:block max-h-60 overflow-y-auto">
                                        <table className="w-full">
                                            <thead className="bg-gray-50 border-b-2 border-gray-200">
                                                <tr className="">
                                                    <Myth text={`${t(`label.lastName`)} ${t(`label.firstName`)}`} />
                                                    <Myth text={t(`label.children`)} />
                                                    <Myth text={t(`label.SumInvest`)} />
                                                    <Myth text={t(`label.min`)} />
                                                    <Myth text="Q1" />
                                                    <Myth text={t(`label.average`)} />
                                                    <Myth text="Q3" />
                                                    <Myth text={t(`label.max`)} />
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-100">
                                                {affiliate
                                                    ? affiliate.map((a, i) => {
                                                        if (i % 2 == 0) {
                                                            return (
                                                                <tr key={i}>
                                                                    <Mytd text={`${decryptCBData(a.firstName)} ${decryptCBData(a.lastName)}`} />
                                                                    <Mytd text={a.childs.length} />
                                                                    <Mytd text={a.sum} />
                                                                    <Mytd text={a.min} />
                                                                    <Mytd text={a.q1} />
                                                                    <Mytd text={a.avg} />
                                                                    <Mytd text={a.q3} />
                                                                    <Mytd text={a.max} />
                                                                </tr>
                                                            );
                                                        } else {
                                                            return (
                                                                <tr className="bg-slate-300">
                                                                    <Mytd text={`${decryptCBData(a.firstName)} ${decryptCBData(a.lastName)}`} />
                                                                    <Mytd text={a.childs.length} />
                                                                    <Mytd text={a.sum} />
                                                                    <Mytd text={a.min} />
                                                                    <Mytd text={a.q1} />
                                                                    <Mytd text={a.avg} />
                                                                    <Mytd text={a.q3} />
                                                                    <Mytd text={a.max} />
                                                                </tr>
                                                            );
                                                        }
                                                    })
                                                    : null}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:hidden max-h-60 overflow-y-auto">
                                        {affiliate
                                            ? affiliate.map((a, i) => (
                                                <div className="bg-white dark:bg-slate-800 p-4 space-y-2 rounded-lg shadow" key={i}>
                                                    <div className="flex items-center space-x-2 text-sm">
                                                        <div className="text-sm font-semibold text-slate-700 dark:text-slate-100">{`${decryptCBData(a.firstName)} ${decryptCBData(a.lastName)}`}</div>
                                                        <div className="text-sm text-gray-700 flex">
                                                            {" "}
                                                            <LinkIcon className="h-4 w-4" /> {a.childs.length}
                                                        </div>
                                                        <div className="text-sm font-semibold bg-blue-200 px-2 rounded-full ">{a.sum}</div>
                                                        <div className="">
                                                            <Icon i="usdt" size={20} />
                                                        </div>
                                                    </div>
                                                    <div className="text-xs">
                                                        {" "}
                                                        {t(`label.min`)}: {a.min}
                                                    </div>
                                                    <div className="text-xs">Q1: {a.q1}</div>
                                                    <div className="text-xs">
                                                        {t(`label.average`)}: {a.avg}
                                                    </div>
                                                    <div className="text-xs">Q3: {a.q3}</div>
                                                    <div className="text-xs">
                                                        {t(`label.max`)}: {a.max}
                                                    </div>
                                                </div>
                                            ))
                                            : null}
                                    </div>
                                </div>
                            </div>{" "}

                            {/* OBJECTIFS */}
                            <div className="bg-slate-100 w-[250px] py-4 h-fit">
                                <h1 className="font-semibold text-center text-2xl  text-slate-900 dark:text-white">
                                    {t(`label.goal`)} <p className="text-xs">(2023)</p>
                                </h1>
                                <div>
                                    <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm ">
                                        <Title.SectionTitle text={t(`label.client`)} unit={`(obj : ${process.env.REACT_APP_USERGOAL})`} icon={<UserGroupIcon className="h-5 w-5 text-blue-700 text-xs" />} />
                                    </div>
                                    <div className=" w-full -mt-16  ">
                                        <PieChartCustom
                                            data={[
                                                { name: t(`label.actually`), value: users && Number(users.all - users.inactive) },
                                                // { name: "Actuel", value: capitalManaged.current },
                                                { name: t(`label.goal`), value: process.env.REACT_APP_USERGOAL - (users && Number(users.all - users.inactive)) },
                                            ]}
                                            width={100}
                                            height={300}
                                            startAngle={180}
                                            endAngle={0}
                                            outerRadius={100}
                                            colors={["#3730a3", "#3b82f6"]}
                                            noLegend
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm -mt-28">
                                        <Title.SectionTitle text={t(`label.enGestionActuel`)} unit={`(obj:${process.env.REACT_APP_KMANAGED})`} icon={<CurrencyDollarIcon className="h-5 w-5 text-blue-700 text-xs" />} />
                                    </div>
                                    <div className=" w-full -mt-16 ">
                                        <PieChartCustom
                                            data={[
                                                { name: t(`label.actually`), value: capitalManaged && Number(capitalManaged.current) },
                                                // { name: "Actuel", value: capitalManaged.current },
                                                { name: t(`label.goal`), value: process.env.REACT_APP_KMANAGED - (capitalManaged && Number(capitalManaged.current)) },
                                            ]}
                                            width={100}
                                            height={300}
                                            startAngle={180}
                                            endAngle={0}
                                            outerRadius={100}
                                            colors={["#3730a3", "#3b82f6"]}
                                            noLegend
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm -mt-28">
                                        <Title.SectionTitle
                                            text={t(`label.duréeOnboarding`)}
                                            // unit={`(${users && users.all.length})`}
                                            icon={<CalendarIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                        />
                                    </div>
                                    <div className=" w-full text-center flex justify-center ">
                                        <DashboradCard bg={"bg-blue-500"} number={15} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
