import React, { useState, PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend } from "recharts";

function PieChartCustom(props) {
  const { data, colors, width, height, startAngle, endAngle, outerRadius, noLegend } = props;

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <div className={`flex gap-x-3 w-full md:w-[${width}px] h-[${height}px]  `}>
      <>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={200} height={100}>
            <Pie
              className="text-xs text-black"
              data={data}
              endAngle={endAngle}
              startAngle={startAngle}
              // cx={120}
              // cy={200}
              labelLine={false}
              label={renderCustomizedLabel}
              innerRadius={40}
              outerRadius={outerRadius || 100}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
            <Tooltip />
            {!noLegend && <Legend className="text-xs" />}
          </PieChart>
        </ResponsiveContainer>
      </>
    </div>
  );
}

export default PieChartCustom;
