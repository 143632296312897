import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { TypographyP } from "../Typo";
import { Label } from "../ui/label";

export function RadioBtnShad(props) {
    const { name, value, option, placeholder, defaultValue, onChange } = props
    const convertToDefaultEventPara = (name, value) => ({
        target: {
            name,
            value,
        },
    });
    return (
        <RadioGroup defaultValue={defaultValue} name={name} onValueChange={(text) => onChange(convertToDefaultEventPara(name, text))} value={value}>
            <TypographyP>{placeholder}</TypographyP>
            {option?.map((o) => (

                <div className="flex items-center space-x-2">
                    <RadioGroupItem value={o.id} id={o.id} />
                    <Label htmlFor={o.id}> {o.item}</Label>
                </div>
            ))}

        </RadioGroup>
    )
}
