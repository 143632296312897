import React from 'react'
import { FormControl, FormControlLabel, RadioGroup as MuiRadioGroup, FormLabel, Radio, FormHelperText } from '@mui/material'

export default function RadioGroup(props) {
    const { name, label, value, onChange, items, disabled, readOnly, error = null, newline = false } = props

    return (
        <FormControl error={error ? true : false} >

            <div className='font-semibold text-sm'>{label}</div>
            <MuiRadioGroup row
                disabled={disabled}
                readOnly={readOnly}
                name={name}
                value={value}
                onChange={onChange}>
                {
                    items.map(
                        (item) => (
                            <div className={newline ? 'flex flex-col w-full' : ''}>
                                <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.title} />
                            </div>

                        )
                    )
                }

            </MuiRadioGroup>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
