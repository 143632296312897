import React, { useState } from "react";

export function useFOrm(initialFvalues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialFvalues);
  const [errors, setErrors] = useState({});
  /*const updateField = (data, type) => {
        const result = { ...form, [type]: data };
        setForm(result);
        console.log("re", firstNameRef)
    };*/

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    console.log("name", name, value, typeof value);
    if (validateOnChange) {
      validate({ [name]: value });
    }
  };

  const resetForm = () => {
    setValues(initialFvalues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    resetForm,
    handleInputChange,
  };
}

export function Form(props) {
  const { children, ...other } = props;
  return (
    <form autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}
