import React, { useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import TopBar from "../TopBar";
import Sidebar from "../Sidebar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Controls from "../controls/Controls";
import moment from "moment";
import "moment/locale/fr";
import { DataGrid, frFR, GridToolbar } from "@mui/x-data-grid";
import Loading from "../Loading";
import { useParams } from "react-router-dom";
import * as sideMenus from "../../utils/sideMenus";
import { authSelector } from "../../features/authSlice";
import { useFOrm } from "../useFOrm";
import BtnShad from "../client/btnShadcn";
import { useToast } from "../ui/use-toast";



const ComponentChip = ({ params, t }) => {
    if (params.value == 1) {
        return <span className="rounded-full bg-purple-500 w-full text-center p-1 text-white text-xs "> {t("paymentStatus.readyToPay")}</span>;
    } else if (params.value == 2) {
        return <span className="rounded-full bg-blue-500 w-full text-center p-1 text-white text-xs "> {t("paymentStatus.alreadyPaid")}</span>;
    } else if (params.value == 3) {
        return <span className="rounded-full bg-green-500 p-1 w-full text-center text-white text-xs "> {t("paymentStatus.pallierComplet")}</span>;
    } else {
        return <span className="rounded-full bg-amber-300 p-1 w-full text-center text-white text-xs "> {t("paymentStatus.notPayed")}</span>;
    }
};

const initialValues = {
    network: ""
};

function OnePayment(props) {
    const { t } = props;
    const [paymentParent, setPaymentParent] = useState();
    const [selectedRowsTotal, setSelectedRowsTotal] = useState(0);
    const [selectedRowsId, setSelectedRowsId] = useState();
    const [btnPayLoading, setBtnPayLoading] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const auth = useSelector(authSelector);
    const { id } = useParams();
    const dispacth = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const goBack = () => navigate(-1);
    const { toast } = useToast()


    const column = [
        {
            field: "id",
            headerName: "ID",
            type: "number",
            hide: true,
        },
        {
            field: "createdAt",
            headerName: "Date",
            width: 150,
            type: "date",
            renderCell: (cellValues) => {
                if (cellValues.row.createdAt) {
                    return moment(cellValues.row.createdAt).format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },
            valueGetter: (params) => {
                return moment(params.row.createdAt).format("DD/MM/YYYY");
            },
        },
        {
            field: "totalOnePercent",
            headerName: t("label.amount"),
            type: "number",
            renderCell: (cellValues) => {
                if (cellValues.row.totalOnePercent) {
                    return JSON.parse(cellValues.row.totalOnePercent).usdt.toFixed(2);
                } else {
                    return null;
                }
            },
            valueGetter: (params) => {
                return JSON.parse(params.row.totalOnePercent).usdt.toFixed(2);
            },
        },
        {
            field: "paidByCB",
            headerName: t("label.status"),
            width: 150,

            renderCell: (params) => {
                return <ComponentChip params={params} t={t} />;
            },
        },
        {
            field: "paidDate",
            headerName: t("label.paidDate"),
            type: "date",
            cellClassName: "font-tabular-nums",
            renderCell: (cellValues) => {
                if (cellValues.row.paidDate) {
                    return moment(cellValues.row.paidDate).format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },
        },
        { field: "paymentId", headerName: t("label.paymentId"), type: "number" },
    ];

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("network" in fieldValues) temp.network = fieldValues.network ? "" : t("error.required");

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);

    const net = [
        { id: "TRX", title: "TRX" },
        { id: "BSC", title: "BSC" },

    ]

    useEffect(() => {
        console.log(moment(new Date("2022-06-28")).format("DD/MM/YYYY"));
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get(`/api/pnl/affiliate/parent/${id}`, {
                    signal: controller.signal,
                });

                //const dataFilter = response.data.filter((f) => JSON.parse(f.totalOnePercent).usdt > 0);

                const filterData = response.data.all.filter((f) => f.totalOnePercentUSDT > 0);
                //console.log("Boom", filterData);
                isMounted && setPaymentParent(response.data);
                console.log(response.data);
            } catch (error) {
                console.log(error);
                /*if (error.response.status != 403) {
                            navigate('/login', { state: { from: location }, replace: true })
                        }*/
            }
        };

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    /*useEffect(() => {
      console.log("test", selectedRowsTotal);
    }, [selectedRowsTotal]);*/

    const processPayment = async (value) => {
        setBtnPayLoading(true)
        const amountToPay = value.toFixed(2);
        if (!amountToPay) {
            return toast({
                variant: "destructive",
                duration: 30000,
                description: "lease select"
            })

        } else {
            const valuePayment = {
                pid: id,
                total: {
                    usdt: amountToPay,
                },
                pnlIds: selectedRowsId,
                network: values.network
            };
            try {
                console.log("PAY PAY PAY -----------", valuePayment);
                const response = await axiosPrivate.post(`/api/affiliate-payment/create`, valuePayment);
                // toast.success(`MAKE PAYMENT PROCESS, ${amountToPay} USDT`);
                toast({
                    title: `PAYMENT PROCES  `,
                    description: `montant : ${amountToPay} USDT`,
                })
                console.log(response.data.msg);
                setBtnPayLoading(false)
            } catch (error) {
                console.log(error);
                toast({
                    variant: "destructive",
                    duration: 30000,
                    description: error.response.data.msg
                })
                setBtnPayLoading(false)
            }
            setBtnPayLoading(false)
        }
    };
    return (
        <>
            <TopBar menus={sideMenus.menusAdmin(t)} />
            <div className="flex flex-row flex-grow ">
                <Sidebar menus={sideMenus.menusAdmin(t)} />
                <div className="flex flex-row flex-1  mt-2 md:ml-52">
                    <div className="flex flex-col w-full p-4">
                        <div className="flex-none h-auto">
                            <div className="flex items-center  gap-x-1">
                                <ChevronLeftIcon className="h-6 rounded-full hover:bg-slate-300 cursor-pointer" onClick={() => goBack()} />
                                <div className="font-semibold text-2xl text-slate-900 dark:text-white">{t("label.detailsPayment")} </div>
                            </div>
                            <h2 className="font-bold mt-2"> {location.state.fullName}</h2>
                            <span className="text-xs text-slate-500 dark:text-slate-400  ">{moment().format("Do MMMM YYYY")}</span>

                            <hr />


                        </div>
                        <div className="h-[400px] bg-slate-100 dark:bg-slate-800">
                            {paymentParent ? (
                                <DataGrid
                                    isRowSelectable={(params) => params.row.paidByCB != 2}
                                    checkboxSelection
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: false,
                                            quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                    getRowId={(row) => row.id}
                                    // componentsProps={{
                                    //     footer: { total }
                                    // }}
                                    //autoPageSize={true}
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    initialState={{
                                        sorting: {
                                            sortModel: [{ field: "id", sort: "desc" }],
                                        },
                                    }}
                                    rows={paymentParent && paymentParent.all.filter((f) => f.totalOnePercentUSDT > 0)}
                                    columns={column}
                                    //pagination={true}
                                    //onCellClick={(value) => console.log("yes", value.id)}
                                    disableColumnSelector
                                    disableDensitySelector
                                    disableColumnFilter
                                    onSelectionModelChange={(ids) => {
                                        const selectedIDs = new Set(ids);
                                        const selectedRowsTotal = paymentParent.all.filter((row) => selectedIDs.has(row.id));
                                        setSelectedRowsId(ids);
                                        if (selectedRowsTotal.length != 0) {
                                            setSelectedRowsTotal(selectedRowsTotal.map((s) => JSON.parse(s.totalOnePercent).usdt).reduce((acc, amount) => Number(acc) + Number(amount)));
                                        } else {
                                            setSelectedRowsTotal(0);
                                        }
                                    }}
                                />
                            ) : (
                                <Loading />
                            )}
                        </div>
                        <div className="flex-none mt-2">
                            {selectedRowsTotal && selectedRowsTotal < 10 ?
                                <div>Montant inférieur à 10 USDT, pas de paiement possible </div>
                                : selectedRowsTotal && selectedRowsTotal > 10 ?
                                    <>
                                        <div>
                                            Veuillez choisir l'adresse qui a été Whitelister par Crypto Bulot
                                        </div>
                                        <div className="w-[300px] py-2">
                                            <Controls.Select
                                                name="network"
                                                label={"Network"}
                                                value={values.network}
                                                onChange={handleInputChange}
                                                options={net}
                                            />

                                        </div>
                                    </>
                                    : null}

                            <div className="flex items-center space-x-2 ">
                                <div className="text-sm font-semibold">Total: </div>
                                <div className="px-2 bg-slate-300 min-w-64 w-32 rounded">{selectedRowsTotal && selectedRowsTotal.toFixed(2)}</div>

                                {selectedRowsTotal && selectedRowsTotal >= 10 && values.network != "" ? (
                                    <>
                                        <BtnShad text={t(`label.pay`)}
                                            onClick={() => processPayment(selectedRowsTotal)}
                                            isLoading={btnPayLoading}
                                            disabled={auth.id == 82 ? true : false}
                                            sendHistory={{ action: `Payment Partenaire `, who: `${id}` }}
                                            size="small"
                                            mt
                                        />
                                        {/* <Controls.Button disabled={auth.id == 82 ? true : false} isLoading={btnPayLoading} text={t(`label.pay`)} small xsFont onClick={() => processPayment(selectedRowsTotal)} /> */}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OnePayment;
