import { CheckCircleIcon } from '@heroicons/react/outline';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { authSelector } from '../../features/authSlice';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { decryptCB, encryptCB, specificKeyCode } from '../../utils/service';
import Controls from '../controls/Controls';
import { useFOrm } from '../useFOrm';
import { TypographyH3, TypographyMuted, TypographySBold, TypographySmall, TypographyXS } from '../Typo';
import { CircleCheckBig, CircleX } from 'lucide-react';
import BtnShad from '../client/btnShadcn';
import { InputShadcn } from '../client/InputShadcn';
import { AlertCustom } from '../client/alertCustom';
import { testApi as sendTestApi } from '../../utils/apiService';
import { ReactComponent as Done } from "../../asset/apiDone.svg"
import { LinkTuto } from '../LinkTuto';
import CopyTextButton from '../CopyTextButton';
import { addTagToMailerLite } from '../../lib/utils';

const initialFvalues = {
    apiKeyRo: "",
    secureKeyRo: "",
    apiKeyTransfer: "",
    secureKeyTransfer: "",
    apiKeyTrading: "",
    secureKeyTrading: "",
    source_income: "",
    otherSourceOfIncome: "",
    // isSousCompte: false,
};

function groupByCode(dataArray) {
    const codeRegistry = {};

    dataArray.forEach(item => {
        Object.keys(item).forEach(key => {
            if (key !== 'ip') {
                const parsedData = JSON.parse(item[key]);
                const code = parsedData.code;
                if (!codeRegistry[code]) {
                    codeRegistry[code] = {
                        code: code,
                        ips: [],
                        keys: []
                    };
                }
                if (!codeRegistry[code].ips.includes(item.ip)) {
                    codeRegistry[code].ips.push(item.ip);
                }
                if (!codeRegistry[code].keys.includes(key)) {
                    codeRegistry[code].keys.push(key);
                }
            }
        });
    });
    // Convert the registry object to an array of values
    return Object.values(codeRegistry);
}


function ApiSubAutonome(props) {
    const { id, handleNextStep } = props
    const { t } = useTranslation()
    const auth = useSelector(authSelector);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [play, setPlay] = useState("");
    const [testApi, setTestApi] = useState({
        ro: false,
        t: false,
        w: false
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isClicked, setIsClicked] = useState("");
    const [errorApiRo, setErrorApiRo] = useState([]);
    const [errorApiW, setErrorApiW] = useState([]);
    const [errorApiT, setErrorApiT] = useState([]);
    const [step, setStep] = useState()
    const [isVideoOpen, setIsVideoOpen] = useState(false)
    const [info, setInfo] = useState()


    const axiosPrivate = useAxiosPrivate()

    useEffect(() => {
        fetch(`${process.env.REACT_APP_INFO}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // console.log("jsonData.........................1......................", response.json());

                return response.json();
            })
            .then(jsonData => {
                // Access the day1 property from your JSON and update the state
                // console.log("jsonData+++++++++++++++++++++++++++.", jsonData);
                setInfo(jsonData);
            })
            .catch(error => {
                console.error('Error fetching the JSON file:', error);
            });
    }, []);
    // useEffect(() => {
    //     let isMounted = true;
    //     const controller = new AbortController();
    //     const checkApiUpdate = async () => {
    //         try {
    //             setIsPageLoading(true);
    //             const { data } = await axiosPrivate.get(`/api/manage/check?reference=${id}`, { signal: controller.signal });
    //             if (isMounted) {
    //                 switch (true) {
    //                     case data.updateApi == "ON":
    //                         setPlay("ON");
    //                         setTestApi(JSON.parse(data.testApi));
    //                         setIsPageLoading(false);
    //                         break;
    //                     default:
    //                         break;
    //                 }
    //             }
    //             setIsPageLoading(false);
    //             // console.log("Play ++++", data);
    //         } catch (error) {
    //             setIsPageLoading(false);
    //             console.log(error, "Error Api Update");
    //         }
    //     };
    //     checkApiUpdate();
    //     return () => {
    //         isMounted = false;
    //         controller.abort();
    //     };
    // }, []);

    const validate = (fieldValues = values) => {
        const temp = { ...errors };

        setErrors({
            ...temp,
        });

        if (fieldValues == values) {
            return Object.values(temp).every((x) => x == "");
        }
    };

    const decryptDataAPI = ["apiKeyRo", "apiKeyTransfer", "apiKeyTrading"];
    const decryptData = ["secureKeyRo", "secureKeyTransfer", "secureKeyTrading"];

    // initialFvalues.apiKeyRo = decryptCB(data?.apiKeyRo);
    // initialFvalues.apiKeyTransfer = decryptCB(data?.apiKeyTransfer);
    // initialFvalues.apiKeyTrading = decryptCB(data?.apiKeyTrading);
    // initialFvalues.isSousCompte = data.isSousCompte;
    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    const formKeysRo = [
        {
            type: "text",
            name: "apiKeyRo",
            label: t("label.apiKeyRo"),
            placeholder: t("label.apiKeyRo"),
        },
        {
            type: "text",
            name: "secureKeyRo",
            label: t("label.secureKeyRo"),
            placeholder: t("label.secureKeyRo"),
        },
    ];

    const formKeysTransfer = [
        {
            type: "text",
            name: "apiKeyTransfer",
            label: t("label.apiKeyTransfer"),
            placeholder: t("label.apiKeyTransfer"),
        },
        {
            type: "text",
            name: "secureKeyTransfer",
            label: t("label.secureKeyTransfer"),
            placeholder: t("label.secureKeyTransfer"),
        },
    ];

    const formKeysTrading = [
        {
            type: "text",
            name: "apiKeyTrading",
            label: t("label.apiKeyTrading"),
            placeholder: t("label.apiKeyTrading"),
        },
        {
            type: "text",
            name: "secureKeyTrading",
            label: t("label.secureKeyTrading"),
            placeholder: t("label.secureKeyTrading"),
        },
    ];



    const handleSaveApi = async (type) => {
        setIsLoading(true)
        try {
            let dataToUpdate;
            switch (type) {
                case "RO":
                    dataToUpdate = {
                        // uid: auth.id,
                        apiKeyRo: encryptCB(values.apiKeyRo),
                        secureKeyRo: encryptCB(values.secureKeyRo),
                    };
                    break;

                case "T":
                    dataToUpdate = {
                        // uid: auth.id,
                        apiKeyTrading: encryptCB(values.apiKeyTrading),
                        secureKeyTrading: encryptCB(values.secureKeyTrading),
                    };
                    break;

                case "W":
                    dataToUpdate = {
                        // uid: auth.id,
                        apiKeyTransfer: encryptCB(values.apiKeyTransfer),
                        secureKeyTransfer: encryptCB(values.secureKeyTransfer),
                    };
                    break;

                default:
                    break;
            }

            await axiosPrivate.put(`/api/manage/updateSubAccountApi?type=${type}&reference=${id}`,
                dataToUpdate)
            setIsLoading(false)
            toast.success("OK")
            console.log("API");
        } catch (error) {
            setIsLoading(false)
            console.log("error", error);
            toast.error(error.response?.data.msg);

        }
        setIsLoading(false)

    }

    const ro = [
        {
            name: "apiKeyRo",
            label: `${t(`label.apiKeyRo`)} `,
            placeholder: "XXXXXXXXXXX",
            id: "apiKeyRo",
            DefaultValue: `${t(`label.apiKeyRo`)} `,
            type: "text"
        },
        {
            name: "secureKeyRo",
            label: `${t(`label.secureKeyRo`)} `,
            placeholder: "XXXXXXXXXXX",
            id: "secureKeyRo",
            DefaultValue: `${t(`label.secureKeyRo`)} `,
            type: "text"
        },
    ]

    const w = [
        {
            name: "apiKeyTransfer",
            label: `${t(`label.apiKeyTransfer`)} `,
            placeholder: "XXXXXXXXXXX",
            // placeholder: `${t(`label.api`)} ${t(`label.transfert`)}`,
            id: "apiKeyTransfer",
            DefaultValue: `${t(`label.apiKeyTransfer`)} `,
            type: "text"
        },
        {
            name: "secureKeyTransfer",
            label: `${t(`label.secureKeyTransfer`)}`,
            placeholder: "XXXXXXXXXXX",
            id: "secureKeyTransfer",
            DefaultValue: `${t(`label.secureKeyTransfer`)}`,
            type: "text"
        },
    ]

    const trading = [
        {
            name: "apiKeyTrading",
            label: `${t(`label.apiKeyTrading`)} `,
            placeholder: "XXXXXXXXXXX",
            id: "apiKeyTrading",
            DefaultValue: `${t(`label.apiKeyTrading`)} `,
            type: "text"
        },
        {
            name: "secureKeyTrading",
            label: t(`label.secureKeyTrading`),
            placeholder: "XXXXXXXXXXX",
            id: "secureKeyTrading",
            DefaultValue: t(`label.secureKeyTrading`),
            type: "text"
        },
    ]



    const handleTestApiRo = async () => {
        try {
            setErrorApiRo([]);
            setIsLoading(true)
            console.log("ID SUB", id);
            const dataToPut = {
                subId: id,
                apiKeyRo: encryptCB(values.apiKeyRo),
                secureKeyRo: encryptCB(values.secureKeyRo),
            }

            const { data } = await axiosPrivate.put(`/api/onboarding/details?info=apiRo&subaccount=ia`, dataToPut)
            // // setIsTestLoading(true);

            // let { statusApi, response } = await sendTestApi("ro", "3fe58e8c-c454-4046-8269-bf2ec5c418db")
            let { statusApi, response } = await sendTestApi("ro", id)
            console.log("**************** statusApi ************", statusApi);
            setTestApi({
                ...testApi,
                ro: statusApi
            });
            console.log("authhhhh", statusApi);
            setIsLoading(false)
        } catch (error) {
            // setTestApiRo(false)
            setErrorApiRo(error.response.data);
            console.log("Api RO tsy mety", error);
            setIsLoading(false)

        }
        // setIsTestLoading(false);
    };

    // Transfert Withdraw
    const handleTestApiW = async () => {
        try {
            setErrorApiW([]);
            setIsLoading(true)
            const dataToPut = {
                subId: id,
                apiKeyTransfer: encryptCB(values.apiKeyTransfer),
                secureKeyTransfer: encryptCB(values.secureKeyTransfer),
            }
            const { data } = await axiosPrivate.put(`/api/onboarding/details?info=apiW&subaccount=ia`, dataToPut)
            // // setIsTestLoading(true);
            let { statusApi, response } = await sendTestApi("w", id)
            setTestApi({
                ...testApi,
                w: statusApi
            });
            console.log("authhhhh", statusApi);
            setIsLoading(false)

        } catch (error) {
            // setTestApiW(false)
            setErrorApiW(error.response.data);
            const dataArray = error.response.data
            const result = groupByCode(dataArray)
            setErrorApiW(result);
            setIsLoading(false)
            console.log("Api W tsy mety", error);



            // console.log("Api W tsy mety", error, "new", newErrors);
        }
        // setIsTestLoading(false);
    };

    // Trading
    const handleTestApiT = async () => {
        try {
            setErrorApiT([])
            setIsLoading(true)
            const dataToPut = {
                subId: id,
                apiKeyTrading: encryptCB(values.apiKeyTrading),
                secureKeyTrading: encryptCB(values.secureKeyTrading),
            }
            const { data } = await axiosPrivate.put(`/api/onboarding/details?info=apiT&subaccount=ia`, dataToPut)
            // // setIsTestLoading(true);
            let { statusApi, response } = await sendTestApi("t", id)
            setTestApi({
                ...testApi,
                t: statusApi
            });
            // setTestApiT(statusApi);
            console.log("authhhhh", auth.id, statusApi);
            setIsLoading(false)

        } catch (error) {
            // setTestApiT(false)
            setErrorApiT(error.response.data);
            console.log("Api T tsy mety", error);
            setIsLoading(false)

        }
        // setIsTestLoading(false);
    };

    const getApiStatus = async () => {
        try {
            const { data } = await axiosPrivate.get(`/api/onboarding/account-api?subId=${id}`)
            console.log("api", data);
            setTestApi({
                ro: data?.testApi?.ro,
                t: data?.testApi?.t,
                w: data?.testApi?.w
            })
        } catch (error) {
            console.error("error maka API", error);
        }
    }

    useEffect(() => {
        getApiStatus()
    }, [])


    const handleAllApiOK = async () => {
        try {
            setIsLoading(true)
            const dataToPut = {
                onboardingStep: 3
            }
            const { data } = await axiosPrivate.put(`/api/onboarding/obStep?info=step&subId=${id}`, dataToPut)
            setStep(data.step)

            const dataToSendMailerlite = { id: auth.id, gname: "Depot_AI" }
            await addTagToMailerLite(dataToSendMailerlite)
            console.log("LAsa le tag", dataToSendMailerlite);

            setIsLoading(false)
            handleNextStep(dataToPut.onboardingStep)

            console.log("API OK => DEPOT");
        } catch (error) {
            console.log("handleAllApiOK", error);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (testApi?.ro && testApi?.w && testApi?.t) {
            //setTimeout
            // setTimeout(() => {
            //     handleAllApiOK()
            // }, 2000);
            handleAllApiOK()
            console.log("all API ok");
        } else {
            console.log("API false");
        }
    }, [testApi?.ro && testApi?.w && testApi?.t])


    return (
        <div className="h-auto dark:bg-slate-800 p-2 pb-4">
            <div className=''>
                <TypographyH3 className="text-center ">  {t(`obAi.api`)} </TypographyH3>

                <div className='text-left mt-3 '>
                    <TypographySmall>{t(`obAi.apiKeyConnect`)}</TypographySmall>
                    <TypographySmall> {t(`label.IPToWhitelist`)} </TypographySmall>

                    <div className='flex items-center'>
                        <TypographySmall>
                            {info?.ip}
                        </TypographySmall>
                        <CopyTextButton text={info?.ip} />
                    </div>
                    <LinkTuto text={t(`linkTuto.setupApi`)} fit setIsVideoOpen={setIsVideoOpen} isVideoOpen={isVideoOpen} url={info?.bApiSubAccount}
                        title={t(`linkTuto.apiSubAccount`)} />
                    <TypographySmall> {t(`obAi.createApi`)} </TypographySmall>
                    <TypographySmall> - {t(`obAi.apiKey`)} </TypographySmall>
                    <TypographySmall> - {t(`obAi.secretKey`)} </TypographySmall>

                </div>
                {/* Ro new */}
                <div className={`${testApi.ro == true ? "border-green-500" : "border-slate-300"} border-2 rounded-sm p-4 m-2  `}>
                    <div className='flex'>
                        <TypographySBold>
                            Crypto-Bulot RO
                        </TypographySBold>
                        {testApi.ro ? <CircleCheckBig className='h-4 text-green-500' /> : <CircleX className='h-4 text-red-500' />}
                    </div>

                    {/* <div className='grid grid-flow-col grid-cols-3 gap-4 items-end'> */}
                    <div className='flex flex-col md:flex-row md:items-end md:space-x-3 justify-center'>

                        {ro.map((input, i) => (
                            <InputShadcn
                                className="w-full md:w-[24rem]"
                                labelInput={input.label}
                                type={input.type}
                                name={input.name}
                                onChange={handleInputChange}
                                label={input.label}
                                placeholder={input.placeholder}
                                error={errors[input.name]}
                                value={values[input.name]}
                                readOnly={testApi.ro}
                            />
                        ))}
                        {/* <div className='mb-2 col-span-1 w-full'> */}
                        {!testApi.ro && <BtnShad
                            className="w-full md:w-auto md:mb-2"
                            isLoading={isLoading}
                            text={`${t(`button.save`)} `}
                            onClick={() => handleTestApiRo()} disabled={values.apiKeyRo && values.secureKeyRo ? false : true} />}

                        {/* </div> */}
                    </div>

                    {errorApiRo.length > 0 && <AlertCustom
                        // text={errorApiRo.map((ro) => ro.ip).join(" - ")}
                        text={t(`apiError.ip`, { ip: errorApiRo.map((ro) => ro.ip).join(" - "), network: "" })}
                        title={t(`apiError.${Math.abs(errorApiRo[0].code)}`)}
                        variant="destructive" />}

                </div>


                {/* new TRADING */}
                <div className={`${testApi.t == true ? "border-green-500" : "border-slate-300"} border-2 rounded-sm p-4 m-2 `}>
                    <div className='flex'>
                        <TypographySBold>
                            Crypto-Bulot T
                        </TypographySBold>
                        {testApi.t ? <CircleCheckBig className='h-4 text-green-500' /> : <CircleX className='h-4 text-red-500' />}
                    </div>

                    <div className='flex flex-col md:flex-row md:items-end md:space-x-3 justify-center'>
                        {trading.map((input, i) => (
                            <InputShadcn
                                className="w-full md:w-[24rem]"
                                labelInput={input.label}
                                type={input.type}
                                name={input.name}
                                onChange={handleInputChange}
                                label={input.label}
                                placeholder={input.placeholder}
                                error={errors[input.name]}
                                value={values[input.name]}
                                readOnly={testApi.t}

                            />
                        ))}
                        {/* <div className='mb-2 '> */}
                        {!testApi.t && <BtnShad
                            className="w-full md:w-auto md:mb-2"
                            isLoading={isLoading}
                            text={`${t(`button.save`)} `}
                            onClick={() => handleTestApiT()}
                            disabled={values.apiKeyTrading && values.secureKeyTrading ? false : true}
                        />}

                        {/* </div> */}
                    </div>

                    {errorApiT.length > 0 && <AlertCustom
                        // text={errorApiW.map((ro) => ro.ip).join(" - ")}
                        text={t(`apiError.ip`, { ip: errorApiT.map((ro) => ro.ip).join(" - "), network: "" })}
                        title={t(`apiError.${Math.abs(errorApiT[0].code)}`)}
                        variant="destructive" />}



                </div>

                {/* new Withdraw */}
                <div className={`${testApi.w == true ? "border-green-500" : "border-slate-300"} border-2 rounded-sm p-4 m-2 `}>
                    <div className='flex'>
                        <TypographySBold>
                            Crypto-Bulot W
                        </TypographySBold>
                        {testApi.w ? <CircleCheckBig className='h-4 text-green-500' /> : <CircleX className='h-4 text-red-500' />}
                    </div>
                    <TypographyMuted> ** Parametrage de l'API W sur le compte principale</TypographyMuted>

                    <div className='flex flex-col md:flex-row md:items-end md:space-x-3 justify-center'>
                        {w.map((input, i) => (
                            <InputShadcn
                                className="w-full md:w-[24rem]"
                                labelInput={input.label}
                                type={input.type}
                                name={input.name}
                                onChange={handleInputChange}
                                label={input.label}
                                placeholder={input.placeholder}
                                error={errors[input.name]}
                                value={values[input.name]}
                                readOnly={testApi.w}

                            />
                        ))}
                        {/* <div className='mb-2 '> */}
                        {!testApi.w && <BtnShad
                            className="w-full md:w-auto md:mb-2"
                            isLoading={isLoading}
                            text={`${t(`button.save`)} `}
                            onClick={() => handleTestApiW()}
                            disabled={values.apiKeyTransfer && values.secureKeyTransfer ? false : true}
                        />}

                        {/* </div> */}
                    </div>

                    {errorApiW.length > 0 && errorApiW.map((error) => (
                        <AlertCustom
                            title={t(`apiError.${Math.abs(error.code)}`)}
                            text={t(`apiError.ip`, { ip: error?.ips?.map((ro) => ro).join(" - "), network: error?.keys?.map((ro) => ro).join(" - ") })}
                            variant="destructive" />
                    ))
                    }



                </div>


            </div>



        </div>
    )
}

export default ApiSubAutonome
