import React, { useState, useEffect } from "react";
import Controls from "../components/controls/Controls";
import { useSelector } from "react-redux";
import { authSelector } from "../features/authSlice";
import { useFOrm } from "../components/useFOrm";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import DialogBox from "../components/DialogBox";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import TableauGrid from "../components/TableauGrid";
import Loading from "../components/Loading";
import { decryptCBData } from "../utils/service";
import { MoveLeft } from "lucide-react";
import { TypographyH4 } from "../components/Typo";

const initialFvalues = {
    title: "",
    url: "",
    support: "",
    type: "",
    category: "",
    status: true,
    by: "",
    platform: null
};

const AdminManageResources = (props) => {
    const { t } = props;
    const auth = useSelector(authSelector);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const goBack = () => navigate(-1);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [allResources, setAllResources] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [isBtnUpdateLoading, setIsBtnUpdateLoading] = useState();
    const [isBtnUpdateSave, setIsBtnUpdateSave] = useState();

    const validate = (fieldValues = values) => {
        let temp = { ...errors };


        if ("title" in fieldValues) temp.title = !fieldValues.title ? t("error.required") : fieldValues.title.length > 50 ? "maximum 50 caractères" : "";
        ;
        if ("url" in fieldValues) temp.url = fieldValues.category == "Mode opératoire" ? "" : fieldValues.url && fieldValues.category != "Mode opératoire" ? "" : t("error.required");
        if ("type" in fieldValues) temp.type = fieldValues.type ? "" : t("error.required");
        // if ("platform" in fieldValues) temp.platform = fieldValues.platform ? "" : t("error.required")
        if ("category" in fieldValues) temp.category = fieldValues.category ? "" : t("error.required");
        setErrors({
            ...temp,
        });
        if (fieldValues == values) return Object.values(temp).every((x) => x == "");
    };

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    const handleOpenDialogBox = () => {
        setSelectedRow();
        setValues({
            title: "",
            url: "",
            support: "",
            type: "",
            category: "",
            status: true,
            by: "",
            platform: ""
        });
        // console.log("Values --------------------------------------------", values);
        setIsDialogOpen(true);
    };

    const formInput = [
        {
            type: "text",
            name: "title",
            label: t("label.title"),
            placeholder: t("placeholder.title"),
            password: false,
            required: true,
        },
        {
            type: "text",
            name: "url",
            label: "url",
            placeholder: "url",
            password: false,
            required: true,
        },
        {
            type: "text",
            name: "support",
            label: "Lien pdf de la vidéo",
            placeholder: t("placeholder.supportPdf"),
            password: false,
            required: true,
        },
    ];

    const typeResource = [
        { id: "Vidéo", title: "Vidéo" },
        { id: "PDF", title: "PDF" },
    ];

    const platformResource = [
        { id: 1, title: "Binance" },
        { id: 2, title: "Kraken" }
    ]

    const status = [
        { id: true, title: "en ligne" },
        { id: false, title: "hors ligne" },
    ];

    const categoryTResource = [
        { id: "Formation", title: "Formation" },
        { id: "Live mensuel", title: "Live mensuel" },
        { id: "Mode opératoire", title: "Mode opératoire" },
        { id: "Short", title: "Short" },
    ];

    const columns = [
        {
            field: "id",
            headerName: "id",
            hide: true,
        },
        {
            field: "platform",
            headerName: "-",
            width: 20,
            renderCell: (cellValues) => {
                return <div>{cellValues?.row?.platform == 1 ? <div className="bg-yellow-500 py-1 px-1 text-xs rounded-lg w-4 "></div> : cellValues?.row?.platform == 2 ? <div className="bg-purple-500 p-1 text-xs rounded-full w-4"></div> : <div className="bg-blue-500 p-1 text-xs rounded-full w-4"></div>}</div>;
            },
        },
        {
            field: "title",
            headerName: t("label.title"),
            flex: 1,
        },
        {
            field: "url",
            headerName: "url",
            width: 250,
        },
        {
            field: "support",
            headerName: "Lien pdf de la vidéo",
            width: 150,
        },
        {
            field: "type",
            headerName: "Type",
        },
        {
            field: "category",
            headerName: "Catégory",
            width: 150,
        },
        {
            field: "status",
            headerName: "Status",
            align: "center",
            renderCell: (cellValues) => {
                return <div>{Boolean(cellValues?.row?.status) ? <div className="bg-green-400 py-1 px-1 text-xs rounded-lg "></div> : <div className="bg-red-400 p-1 text-xs rounded-full"></div>}</div>;
            },
        },
        {
            field: "action",
            headerName: t(`label.action`),
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-primary dark:text-slate-100 hover:text-secondary"}
                        text={t("button.update")}
                        onClick={(event) => {
                            //console.log(cellValues.row.id)
                            const dataToShow = allResources.filter((a) => a.id === cellValues.row.id);
                            setSelectedRow(dataToShow[0]);
                            for (const key in dataToShow[0]) {
                                if (dataToShow[0].hasOwnProperty(key)) {
                                    if (values.hasOwnProperty(key)) {
                                        values[key] = dataToShow[0][key];
                                    }
                                }
                            }
                            // console.log("selected Row", dataToShow[0]);
                            setIsDialogOpen(true);
                        }}
                    />
                );
            },
        },
    ];

    const handleSave = async () => {
        if (validate()) {
            // console.log("AOO", values);

            try {
                setIsBtnUpdateSave(true);
                values.status = true;
                values.by = auth.name;
                values.title = values.platform == 1 ? "Binance" + " - " + values.title : values.platform == 2 ? "Kraken" + " - " + values.title : values.title
                await axiosPrivate.post(`/api/resources/create`, values);
                console.log("ADALA Mégane", values.title);
                toast.success("Video posté", {
                    // position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                resetForm();
                setIsDialogOpen(false);
                setIsBtnUpdateSave(false);
            } catch (error) { }
        }
    };

    const handleUpdate = async (id) => {
        if (validate()) {
            // console.log("AOO", values);

            try {
                setIsBtnUpdateLoading(true);
                //values.status = values.status == "true" ? true : false;
                values.by = auth.name;
                // values.title = values.platform == 1 ? "Binance" + " - " + values.title : values.platform == 2 ? "Kraken" + " - " + values.title : values.title

                // find index of the updated element
                const index = allResources.findIndex((obj) => obj.id == id);
                if (index != -1) {
                    const updatedArray = [...allResources];
                    updatedArray[index] = {
                        ...values,
                        id: id,
                    };
                    setAllResources((prev) => updatedArray);
                }

                await axiosPrivate.put(`/api/resources/${id}`, values);
                // console.log("ADALA Mégane");
                toast.success("Ressource modifié", {
                    // position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                resetForm();
                setIsDialogOpen(false);
                setIsBtnUpdateLoading(false);
            } catch (error) { }
        }
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getAllResources = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/resources/findAll`, {
                    signal: controller.signal,
                });
                const decrypt = JSON.parse(decryptCBData(data));
                console.log("decrypt 1", decrypt);
                // decrypt?.forEach(d => {
                //     if (d.id % 2 !== 0) {
                //         d.platform = 1;
                //     } else {
                //         d.platform = 2
                //     }
                // })
                console.log("with platform", decrypt);
                // console.log("ATOOOO", decrypt);
                isMounted && setAllResources(decrypt);
            } catch (error) { }
        };

        getAllResources();
    }, []);


    return (
        <>
            <div className="flex items-center mx-2">
                <div className="flex items-center flex-1 my-2">
                    <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
                        <MoveLeft className="h-4 w-4 hover:cursor-pointer" onClick={goBack} />
                    </div>
                    <TypographyH4 className="">{t(`label.manageResource`)}</TypographyH4>
                </div>
                <div className="">
                    <Controls.Button disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false} text={t(`button.add`)} xsFont onClick={handleOpenDialogBox} />
                </div>
            </div>
            <DialogBox isOpen={isDialogOpen} setIsOpen={setIsDialogOpen}>
                <div className=" mx-2 md:mx-auto  mt-4 pt-2 px-4  md:w-[70%] py-4">
                    {selectedRow ? (
                        <div className=" font-medium text-center">
                            {t(`button.update`)}
                            {t(`label.theResource`)}
                        </div>
                    ) : (
                        <div className=" font-medium text-center">
                            {t(`button.add`)}
                            {t(`label.theResource`)}
                        </div>
                    )}
                    <div className="bg-300 p-2 flex flex-col items-center">
                        <div className="space-y-4 max-w-md min-w-md my-4">
                            <Controls.Select
                                name="platform"
                                label="Plateforme"
                                value={values.platform || ""}
                                onChange={handleInputChange}
                                options={platformResource}
                                error={errors.platform}
                                noneValue={"Aucun"} />


                            <Controls.Select
                                name="type"
                                label={"Type"}
                                value={values.type || ""}
                                onChange={handleInputChange}
                                options={typeResource}
                                error={errors.type}
                                noneValue={"Aucun"} />


                            {selectedRow ? <Controls.Select name="status" label={"Status"} value={values.status} onChange={handleInputChange} options={status} error={errors.status} noneValue={"Aucun"} /> : null}

                            <Controls.Select
                                name="category"
                                label={"Catégories"}
                                value={values.category || ""}
                                onChange={handleInputChange}
                                options={categoryTResource}
                                error={errors.category}
                                noneValue={"Aucun"}
                            />

                            {formInput.map((item, i) => {
                                return (
                                    <Controls.Input
                                        fullWidth={true}
                                        key={i}
                                        readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                        label={item.label}
                                        value={values[item.name] || ""}
                                        onChange={handleInputChange}
                                        name={item.name}
                                        type={item.type}
                                        error={errors[item.name]}
                                    />
                                );
                            })}
                        </div>

                        <div className="">
                            {selectedRow ? (
                                <>
                                    <Controls.Button
                                        disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                        isLoading={isBtnUpdateLoading}
                                        text={t(`button.update`)}
                                        xsFont
                                        onClick={() => handleUpdate(selectedRow.id)}
                                    />
                                </>
                            ) : (
                                <>
                                    <Controls.Button
                                        disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                        text={t(`button.save`)}
                                        isLoading={isBtnUpdateSave}
                                        xsFont
                                        onClick={handleSave} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </DialogBox>

            <div className="h-[76vh] bg-slate-100">{allResources && allResources ? <TableauGrid column={columns} dataRows={allResources && allResources} /> : <Loading />}</div>
        </>
    );
};

export default AdminManageResources;
