import currencyFormatter from "currency-formatter"
function formatedCurrency(price, rate, currency, withoutUsdt = false, onlyEquivalent = false) {
    const finalAmount = currency == "eur" ? (Number(price) / rate).toFixed(2) : (Number(price) * rate).toFixed(2)
    if (withoutUsdt) {
        return ` ${Number(price || 0)} ( ≈ ${currencyFormatter.format(finalAmount, { code: currency.toUpperCase(), format: "%v %s" })
            })`
    }
    if (onlyEquivalent) {
        return ` ≈ ${currencyFormatter.format(finalAmount, { code: currency.toUpperCase(), format: "%v %s" })
            }`

    }
    return `${Number(price).toFixed(2)} USDT ( ≈ ${currencyFormatter.format(finalAmount, { code: currency.toUpperCase(), format: "%v %s" })
        })`
}
export default formatedCurrency
