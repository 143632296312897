import React from "react";
import { useTranslation } from "react-i18next";

function EquipeCB(props) {
    const { t, small } = props;
    const equipe = [
        {
            id: 1,
            name: "Florent",
            post: t(`label.CEO`) ,
            urlImage: "https://flow-corporation.net/wp-content/uploads/2021/04/Flow-190x190.jpg",
        },
        {
            id: 2,
            name: "Treesi",
            post: t(`label.CFO`),
            urlImage: "https://flow-corporation.net/wp-content/uploads/2021/04/T-190x190.jpg",
        },
        {
            id: 3,
            name: "Mégane",
            post: t(`label.CTO`),
            urlImage: "https://flow-corporation.net/wp-content/uploads/2021/04/Me-190x190.jpg",
        },
        {
            id: 4,
            name: "Sitraka",
            post: t(`label.dev`),
            urlImage: "https://flow-corporation.net/wp-content/uploads/2021/04/si-190x190.jpg",
        },
        {
            id: 5,
            name: "Lorène",
            post: t(`label.dev`),
            urlImage: "https://flow-corp.net/wp-content/uploads/2023/06/Lorene-2-190x190.jpg",
        },
        {
            id: 6,
            name: "Damien",
            post: t(`label.support`),
            urlImage: "https://flow-corporation.net/wp-content/uploads/2022/06/WhatsApp-Image-2022-06-20-at-12.50.52-190x190.jpeg",
        },
        {
            id: 7,
            name: "Kim",
            post: t(`label.support`),
            urlImage: "https://flow-corp.net/wp-content/uploads/2023/06/Kim-190x190.jpg",
        },
        {
            id: 8,
            name: "Anaelle",
            post: t(`label.support`),
            urlImage: "https://flow-corp.net/wp-content/uploads/2024/04/IMG_0053-1-1-scaled.jpg",
        },
        {
            id: 9,
            name: "Mélanie",
            post: t(`label.support`),
            urlImage: "https://flow-corp.net/wp-content/uploads/2024/04/IMG_0050-scaled.jpg",
        },
    ];
    return (
        <>
            <div className="flex justify-center space-x-6 items-center">
                {equipe.map((team, i) => (
                    <>
                        <div className={`flex flex-col items-center space-y-4 w-40  ${small ? "h-40 hover:scale-125" : "h-64 justify-center hover:scale-125 hover:rounded-xl"} my-1 py-1`}>
                            <img src={team.urlImage} className={`${small ? "w-24 h-24" : "w-32 h-32 hover:drop-shadow-sm hover:bg-white"} border-2 border-red-500 rounded-full object-cover`} />
                            <div className="flex flex-col items-center ">
                                <div className="text-sm font-semibold ">{team.name}</div>
                                <div className="text-xs text-center text-slate-500">{team.post}</div>
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </>
    );
}

export default EquipeCB;
