import io from "socket.io-client";
const path =  undefined            // Create the socket connection

const socket = io(
    process.env.REACT_APP_BASE_URL_SOCKET,
    {
        path, // dev only
        autoConnect: false,
        transports: ['polling']

    }
    // { transports: ["websocket"] }
);
export default socket;
