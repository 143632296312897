import { CalendarIcon, ChatIcon } from '@heroicons/react/outline';
import { ClockIcon } from '@heroicons/react/solid';
import moment from 'moment';
import React, { useState } from 'react'
import { decryptCBData } from '../../utils/service';

function CommentCard(props) {
    const { p } = props
    const [showFullComment, setShowFullComment] = useState(false);
    // console.log("p^^^^^^^^^^^^", p)

    const handleViewMore = () => {
        setShowFullComment(true);
    };
    const handleViewLess = () => {
        setShowFullComment(false);
    };
    return (
        <>
            {p?.comment && p?.dateRDV ?
                <>
                    <div className='flex space-x-1'>
                        <CalendarIcon className='w-4 text-blue-500' />
                        <div>
                            {decryptCBData(p?.byUser?.firstName)} : Nouveau date de RDV : <strong>{moment(p?.dateRDV).format("DD MMMM YYYY à HH:mm")}</strong>
                        </div>
                    </div>
                    <div className='ml-4 text-cyan-600 '> {decryptCBData(p?.byUser?.firstName)} : {p?.comment}</div>
                </>
                :
                p?.comment && p?.dateRef ?
                <div>
                        <div className='flex space-x-1'>
                            <ClockIcon className='w-4 text-blue-500' />
                            <div>
                                 Nouveau date Ref : <strong>{moment(p?.dateRef).format("DD MMMM YYYY")}</strong>
                            </div>
                        </div>
                        <div className=' ml-4 text-cyan-600'> {decryptCBData(p?.byUser?.firstName)} : {p?.comment}  </div>
                </div>
                :
                <div className="flex space-x-1">
                    <ChatIcon className="w-4 h-4 text-blue-500 " />
                    <div className='flex-col w-[50vw] '>
                            <strong>{p?.toList?.name}</strong>: {decryptCBData(p?.byUser?.firstName)}{' '}
                        {p?.comment.length > 20 && !showFullComment ? (
                            <>
                                {`${p?.comment.slice(0, 20)}... `}
                                <button className='text-indigo-300 cursor-pointer hover:text-indigo-600' onClick={handleViewMore}>View More</button>
                            </>
                        ) : (
                            <>
                                {p?.comment === '' ? (
                                    "a effacé le commentaire"
                                ) : (
                                    <>
                                                    a commenté <span className='italic font-bold'>" {p?.comment} "</span>
                                        {p?.comment.length > 20 && showFullComment && (
                                            <button className='text-indigo-300 cursor-pointer hover:text-indigo-600' onClick={handleViewLess}>View Less</button>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>}


        </>
    )
}

export default CommentCard
