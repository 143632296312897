import React, { useEffect, useState } from "react";
import { LogoutIcon, UserCircleIcon, CogIcon, ChartBarIcon, ChipIcon, TableIcon, ChevronDownIcon, ClipboardIcon, ClipboardListIcon, ColorSwatchIcon, SwitchHorizontalIcon, StopIcon, PauseIcon } from "@heroicons/react/outline";
import useDarkMode from "../../hooks/useDarkMode";
import useOpen from "../../hooks/useOpen";
import { ReactComponent as CBLOGO } from "../../asset/logo.svg";
import { authSelector, authTokenSelector, logout } from "../../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import MyPopover from "../MyPopover";
import { ReactComponent as Ampoule } from "../../asset/ampoule.svg";
import BannerInfo from "../../components/BannerInfo";
import { UsersIcon } from "@heroicons/react/solid";
import { decryptCBData } from "../../utils/service";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { socketSelector } from "../../features/socketSlice";
import LanguageSwitcher from "../LanguageSwitcher";
import { NavMenu } from "../client/navMenu";
import { Clipboard, BarChart4, ChevronDown, ChevronUp, LineChart, Network, NotebookText, ShoppingBasket, ReceiptEuro, Ban, CirclePause, MoonIcon, SunIcon, Wallet, Ticket, BarChart3, List, CircleUser, Settings, LogOut, FileStack } from "lucide-react"
import { updateTheme } from "../../features/themeSlice";
import NotifClient from "../NotifClient";
import { ProfileNav } from "../client/profileNav";
import CsLogo from "../../asset/H-Logo-fond-blanc.png"

function Header(props) {
    const { t } = props;
    const dispacth = useDispatch();
    const auth = useSelector(authSelector);
    //const [isOpen, setOpen] = useOpen()
    const [isOpen, setIsOpen] = useState();
    const [isOpen2, setIsOpen2] = useState();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const socket = useSelector(socketSelector);
    const [colorTheme, setColorTheme] = useDarkMode()
    const token = useSelector(authTokenSelector)



    const Hello = () => {
        navigate("/login");
    };
    const goodBye = async () => {
        setIsOpen(false);
        dispacth(logout());
        navigate("/login");
        socket?.emit("client_disconnect")
        await axiosPrivate.get(`/api/users/logout`).then(() => {

        })
    };

    const CustomMenu = ({ icon, title, onClick }) => (
        <div className="flex text-sm items-center p-2 hover:bg-slate-200 hover:cursor-pointer gap-x-2" onClick={onClick}>
            {icon}
            {title}
        </div>
    );

    const redirectGraph = () => {
        const targetOrigin = `${process.env.REACT_APP_LINK_TO_DASHBOARD_ADMIN}?search=${encodeURIComponent(token)}&id=${encodeURIComponent(auth?.id)}`
        // const targetWindow = window.open(targetOrigin)
        console.log("Autonome");
        // targetWindow.postMessage({ authToken: "1234" }, targetOrigin)
        window.open(targetOrigin)
    }

    const navMenu = [
        {
            label: "Menu",
            info: [
                {
                    icon: <Clipboard className="w-5 h-5 " />,
                    title: "Admin",
                    href: "/admin/dashboard",
                },
                {
                    icon: <BarChart4 className="w-5 h-5" />,
                    title: "Indice",
                    href: "/index-cb",
                },
                {
                    icon: < LineChart className="w-5 h-5" />,
                    title: "Simulateur",
                    href: "/simulator",
                },
                {
                    icon: < Network className="w-5 h-5" />,
                    title: "Interne",
                    href: "/user",
                },
                {
                    icon: <Ticket className="w-5 h-5" />,
                    title: "Crédits",
                    href: "/credit",
                }
                // {
                //     icon: <BarChart3 className="w-5 h-5" />,
                //     title: "Autonome",
                //     href: "/chart",
                // }
            ]
        },
        {
            label: "Management",
            info: [
                {
                    icon: < NotebookText className="w-5 h-5" />,
                    title: "Suivi",
                    href: "/board/4aa03c5f-2b8a-11ee-99ee-00155d821527",
                },
                {
                    icon: < ShoppingBasket className="w-5 h-5" />,
                    title: "Produits",
                    href: "/product",
                },
                {
                    icon: <ReceiptEuro className="w-5 h-5  " />,
                    title: "Abonnements",
                    href: "/subscription",
                },
                {
                    icon: <Ban className="w-5 h-5  " />,
                    title: "Attente stop",
                    href: "/waitStop",
                },
                {
                    icon: <CirclePause className="w-5 h-5  " />,
                    title: "Pause",
                    href: "/pause",
                },
                {
                    icon: <Wallet className="w-5 h-5  " />,
                    title: "PNL",
                    href: "/pnl",
                }
            ]
        },

    ]
    const menuDev = [
        {
            label: "dev",
            info: [

                {
                    icon: < List className="w-5 h-5" />,
                    title: "List Dev",
                    href: "/list-dev",
                },
                {
                    icon: < FileStack className="w-5 h-5" />,
                    title: "History",
                    href: "/bg",
                },

            ]
        }


    ]

    const profile = [
        {
            label: <CircleUser className="w-5 h-5 " />,
            info: [
                {
                    icon: < Settings className="w-5 h-5" />,
                    title: t(`label.setting`),
                    onClick: () => navigate("/settings")
                },
                {
                    icon: < LogOut className="w-5 h-5" />,
                    title: t(`logout`),
                    onClick: () => goodBye()

                },
            ]
        }


    ]

    // console.log("INFO", navMenu);
    // console.log("Title", navMenu.map((m) => m.info.map((i) => i.title)));
    return (
        <>
            {auth.id === 381 ? (
                <div
                    className={`z-10 shadow-sm flex justify-between items-center p-2
          bg-gradient-to-r from-pink-500 to-yellow-300  dark:bg-slate-800 sticky top-0 w-full`}
                >
                    {/* <CBLOGO /> */}

                    <div className="flex gap-x-4 items-center">
                        <img className="object-contains h-8 " src={CsLogo} />
                        {/* <Ampoule className="w-6  h-8" /> */}
                    </div>
                    {/* </Link> */}

                    <MyPopover isOpen={isOpen} setIsOpen={setIsOpen} onMouseEnter={() => setIsOpen(false)}>
                        <div className="bg-slate-100 flex-col shadow-xl border-r-2 border-r-indigo-500 ">
                            <div className="flex items-center text-sm  text-slate-800 font-semibold truncate h-10 my-auto px-2">{decryptCBData(auth.email)}</div>

                            {auth.role.includes(process.env.REACT_APP_CLIENT) ? (
                                <CustomMenu icon={<CogIcon className="w-5 h-5 dark:text-slate-200 " />} title={t(`label.setting`)} onClick={() => navigate("/settings")} />
                            ) : null}

                            <CustomMenu icon={<LogoutIcon className="w-5 h-5 dark:text-slate-200 " />} title={t(`logout`)} onClick={goodBye} />
                        </div>

                    </MyPopover>

                    <div className=" flex justify-end space-x-3 items-center">


                        {auth.email ? (
                            <>
                                <div className={`text-xs font-semibold text-slate-700`}>{decryptCBData(auth.email)}</div>


                                <UserCircleIcon className="w-5 h-5 hover:cursor-pointer" onClick={() => setIsOpen(!isOpen)} onMouseEnter={() => setIsOpen(true)} />
                            </>
                        ) : (
                            <>

                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div>
                    <div
                        className={`z-10 shadow-sm flex justify-between items-center p-2  ${auth.role.includes(process.env.REACT_APP_SUPADMIN)
                            ? "bg-gradient-to-r from-blue-900 to-blue-100"
                            : auth.role.includes(process.env.REACT_APP_ADMIN)
                                ? "bg-gradient-to-r from-cyan-800 to-sky-400"
                                : auth.role.includes(process.env.REACT_APP_SUPPORT) ?
                                    "bg-[#00DBBC]"
                                    : auth.role.includes(process.env.REACT_APP_CLIENT) && auth?.strategy == "ai" ?
                                        // "bg-gradient-to-r from-sky-400 to-sky-400-cyan-200"
                                        "bg-slate-100"
                                        : "bg-slate-100"
                            }

          dark:bg-slate-800 sticky top-0 w-full`}
                    >
                        {/* <CBLOGO /> */}

                        <div className="flex gap-x-4 items-center">
                                <img className="object-contains h-4" src={CsLogo} />
                            {auth.role.includes(process.env.REACT_APP_SUPADMIN) && (
                                <>
                                    <div className="text-xs font-normal rounded-md hover:cursor-pointer hover:text-secondary text-white" onClick={() => navigate("/hello")}>
                                        Home
                                    </div>
                                    <div className="text-xs font-normal rounded-md hover:cursor-pointer hover:text-secondary text-white" onClick={redirectGraph}>
                                        Autonome
                                    </div>

                                    <div className="flex items-center ">
                                        <NavMenu info={navMenu} />
                                    </div>

                                </>
                            )}
                            {auth.role.includes(process.env.REACT_APP_ADMIN) && (
                                <>
                                    {/* <div className="text-xs font-normal rounded-md hover:cursor-pointer hover:text-secondary text-white" onClick={() => navigate("/admin/dashboard")}>
                    Admin
                  </div>
                  <div className="text-xs font-normal rounded-md hover:cursor-pointer hover:text-secondary text-white" onClick={() => navigate("/index-cb")}>
                    Indice
                  </div>
                  <div className="text-xs font-normal rounded-md hover:cursor-pointer hover:text-secondary text-white" onClick={() => navigate("/simulator")}>
                    Simulateur
                  </div>
                  <div className="text-xs font-normal rounded-md hover:cursor-pointer hover:text-secondary text-white" onClick={() => navigate("/team")}>
                    Equipe
                  </div> */}

                                    <>
                                        <div className="text-xs font-normal rounded-md hover:cursor-pointer hover:text-secondary text-white" onClick={() => navigate("/admin/dashboard")}>
                                            Home
                                        </div>
                                        <div
                                            className="text-xs font-normal rounded-md hover:cursor-pointer hover:text-secondary text-white relative w-50 flex items-center"
                                            onClick={() => setIsOpen2(!isOpen2)}
                                            onMouseEnter={() => setIsOpen2(true)}
                                        >
                                            <div>Menu</div>
                                            <ChevronDownIcon className={`w-5 h-5 ${isOpen2 ? "rotate-180" : ""}  transform duration-500 ease-in-out`} />
                                            <MyPopover relative isOpen={isOpen2} setIsOpen={setIsOpen2} onMouseEnter={() => setIsOpen2(false)}>
                                                <div className="bg-slate-100 flex-col shadow-xl text-slate-800  ">
                                                    <CustomMenu icon={<ChartBarIcon className="w-5 h-5  text-blue-800  dark:text-slate-200" />} title={"Indice"} onClick={() => navigate("/index-cb")} />
                                                    <CustomMenu icon={<ChipIcon className="w-5 h-5  text-blue-800  dark:text-slate-200" />} title={"Simulateur"} onClick={() => navigate("/simulator")} />
                                                    <CustomMenu icon={<UsersIcon className="w-5 h-5  text-blue-800  dark:text-slate-200" />} title={"Interne"} onClick={() => navigate("/user")} />
                                                </div>

                                            </MyPopover>
                                        </div>


                                    </>
                                </>
                            )}
                            {auth.role.includes(process.env.REACT_APP_SUPPORT) && (
                                <>
                                    <div className="text-xs font-medium rounded-md hover:cursor-pointer hover:text-secondary text-blue-700 " onClick={() => navigate("/support/dashboard")}>
                                        Home

                                    </div>
                                    {auth?.id == "87e4efd1-376f-4741-8cc7-76b771b05b76" &&
                                        <div className="flex items-center ">
                                            <NavMenu info={menuDev} />
                                        </div>
                                    }
                                </>
                            )}

                        </div>



                        {/* <div>
                <ViewListIcon className='h-5 w-5 block md:hidden' onClick={() => { dispacth(updateShow('show')) }} />
            </div> */}
                        <div className=" flex justify-end  space-x-3 items-center">
                            {/* <div className='rounded-full p-1 hover:cursor-pointer ' onClick={() => { setColorTheme(colorTheme); dispacth(updateTheme(colorTheme)) }}>
                                {colorTheme === 'dark' ? (
                                    <MoonIcon className='h-5 w-5 text-indigo-600' />
                                ) : (
                                    <SunIcon className='h-5 w-5 text-indigo-600' />
                                )}

                            </div> */}
                            <div className='text-right -my-2'>
                                <LanguageSwitcher />
                            </div>

                            {auth.email ? (
                                <>
                                    {/* <div className={`text-xs font-semibold text-slate-700`}>{decryptCBData(auth.name)}</div> */}
                                    {/* <CircleUser className="w-5 h-5  hover:cursor-pointer" onClick={() => setIsOpen(!isOpen)} onMouseEnter={() => setIsOpen(true)} /> */}
                                    {/* <NotifClient /> */}
                                    {auth.role.includes(process.env.REACT_APP_CLIENT) && <NotifClient />}
                                    <ProfileNav info={profile} auth={auth} />

                                    {/* <UserCircleIcon className="w-5 h-5 bg-red-500 hover:cursor-pointer" onClick={() => setIsOpen(!isOpen)} onMouseEnter={() => setIsOpen(true)} /> */}
                                </>
                            ) : (
                                <>
                                </>
                            )}
                        </div>
                    </div>


                </div>
            )}
        </>
    );
}

export default Header;
