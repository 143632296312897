import { XIcon } from "@heroicons/react/solid";
import React, { useState, useEffect } from "react";
import DialogBox from "../components/DialogBox";

function BannerInfo(props) {
  const { text, type, hasCloseBtn, viewMore, viewMoretext, noMarging } = props;
  const [color, setColor] = useState("");
  const [show, setShow] = useState(true);
  const [viewMoreOpen, setViewMoreOpen] = useState(false);

  useEffect(() => {
    const getColor = () => {
      switch (true) {
        case type == "success":
          setColor("bg-green-200");
          break;
        case type == "info":
          setColor("bg-sky-300");
          break;
        case type === "warning":
          setColor("bg-yellow-200");
          break;
        case type === "danger":
          setColor("bg-red-300 ");
          break;
        case type === "neutral":
          setColor("bg-indigo-300");
          break;
        case type === "orange":
          setColor("bg-amber-500");
          break;
        default:
          setColor("bg-slate-300");
          break;
      }
    };
    getColor();
  }, [type]);

  return (
    <>
      <DialogBox isOpen={viewMoreOpen} setIsOpen={setViewMoreOpen} title={""}>
        <div className="w-[800px] px-2 pb-2">{viewMoretext}</div>
      </DialogBox>
      <div
        className={`p-3
        ${noMarging ? "" : " my-5 "}
        ${show ? "flex" : "hidden"}
        ${hasCloseBtn ? "justify-between" : "justify-center"}
        items-center
        ${color} rounded text-xs md:text-sm text-center font-normal text-slate-600`}
      >
        {text}
        <div className="flex justify-center items-center space-x-2">
          {viewMore && (
            <div className="text-xs hover:cursor-pointer bg-slate-50 border hover:text-slate-800 border-slate-50 rounded-lg p-1" onClick={() => setViewMoreOpen(!viewMoreOpen)}>
              View More
            </div>
          )}
          {hasCloseBtn && <XIcon className={`w-5 h-5 hover:text-slate-100 hover:bg-slate-800 hover:rounded-full hover:cursor-pointer p-1`} onClick={() => setShow(false)} />}
        </div>
      </div>
    </>
    // <div className="text-xs text-red-400">* {text}</div>
  );
}

export default BannerInfo;
