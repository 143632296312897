import React, { useEffect, useState } from "react";
import { useFOrm, Form } from "../useFOrm";
import Controls from "../controls/Controls";
import axios from "../../axios";
import { toast } from "react-toastify";
import { updateUser } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, logout } from "../../features/authSlice";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { countries, languages } from "../../countries";
import Flag from 'react-world-flags';
import BtnShad from "../client/btnShadcn";



const initialFvalues = {
    firstName: "",
    lastName: "",
    email: "",
    tel: "",
    country: "",
    dob: "",
    lang: "fr"
};

function InfoForm(props) {
    const { data, t, uid } = props;
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);
    const axiosPrivate = useAxiosPrivate();
    const [country, setCountry] = useState()
    const [change, setChange] = useState([])

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("firstName" in fieldValues) temp.firstName = fieldValues.firstName ? "" : t("error.required");
        if ("lastName" in fieldValues) temp.lastName = fieldValues.lastName ? "" : t("error.required");
        if ("email" in fieldValues)
            //temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : t("error.invalidEmail")
            temp.email = fieldValues.email == "" ? t("error.required") : /$^|.+@.+..+/.test(fieldValues.email) ? "" : t("error.invalidEmail");
        if ("tel" in fieldValues) temp.tel = fieldValues.tel > 9 ? "" : t("error.mobileLength");
        if ("country" in fieldValues) temp.country = fieldValues.country ? "" : t("error.required");
        if ("dob" in fieldValues) temp.dob = fieldValues.dob ? "" : t("error.required");
        if ("lang" in fieldValues) temp.lang = fieldValues.lang ? "" : t("error.required");

        setErrors({
            ...temp,
        });

        if (fieldValues == values) return Object.values(temp).every((x) => x == "");
    };

    initialFvalues.email = data?.email;
    initialFvalues.firstName = data?.firstName;
    initialFvalues.lastName = data?.lastName;
    initialFvalues.tel = data?.tel;
    initialFvalues.country = data?.country;
    initialFvalues.dob = data?.dob;
    initialFvalues.lang = data?.lang;

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    const formInfo = [
        {
            type: "text",
            name: "lastName",
            label: t("label.lastName"),
            placeholder: t("placeholder.lastName"),
            password: false,
            required: true,
        },
        {
            type: "text",
            name: "firstName",
            label: t("label.firstName"),
            placeholder: t("placeholder.firstName"),
            password: false,
            required: true,
        },
        {
            type: "email",
            name: "email",
            label: t("label.email"),
            placeholder: t("placeholder.firstName"),
            password: false,
            required: true,
        },
        {
            type: "tel",
            name: "tel",
            label: t("label.tel"),
            placeholder: t("placeholder.tel"),
            password: false,
            required: true,
        },
        {
            type: "date",
            name: "dob",
            label: t("placeholder.dob"),
            placeholder: t("placeholder.dob"),
            password: false,
            required: true,
        },



    ];

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        const changes = [];

        Object.keys(values).forEach(key => {
            if (values[key] !== data[key]) {
                changes.push({ key, value: values[key] });
            }
        });

        if (changes.length > 0) {
            setChange(changes);
        }
        console.log("changes", changes);
    }, [values, data]);

    const handleUpdateInfo = async () => {
        if (validate()) {
            //update user data
            console.log("VALUES", values)
            try {
                axiosPrivate.put(`/api/users/${uid}`, values);
                dispatch(updateUser(values));
                toast.success("User updated", {
                    // position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.log("DOB", values);

            } catch (error) {
                toast.error(error.response?.data.msg);
            }
        }
    };

    return (
        <div className=" flex justify-center">
            <Form onSubmit={handleSubmit} noValidate>
                <div className="space-y-4 max-w-md my-4 ">
                    {formInfo.map((item, i) => {
                        // console.log(data[item.name])
                        return (
                            <Controls.Input
                                fullWidth={true}
                                key={i}
                                // disabled={disabled}
                                // readOnly={disabled}
                                readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                label={item.label}
                                value={values[item.name] || ""}
                                onChange={handleInputChange}
                                name={item.name}
                                type={item.type}
                                error={errors[item.name]}
                            />
                        );
                    })}
                    <div className="flex space-x-2 ">

                        <div className="flex items-center  w-12  ">
                            <Flag code={values?.country} />
                        </div>
                        <Controls.Select
                            width="w-auto"
                            name="country"
                            label={t(`label.country`)}
                            value={values.country}
                            onChange={handleInputChange}
                            options={countries}
                            error={errors.country}
                            noneValue={"-"}
                        />
                        {/* <Controls.Select
                            name="lang"
                            label={t(`label.lang`)}
                            value={values?.lang}
                            onChange={handleInputChange}
                            options={languages}
                            error={errors.lang}
                            noneValue={"-"}
                            width="w-auto"
                        /> */}
                    </div>
                </div>

                {/* <div className="flex space-x-2 items-center bg-red-300">
                    <div className="flex items-center  w-12  ">
                        <Flag code={values?.country} />
                    </div>

                    <Controls.Select
                        name="country"
                        label={t(`label.country`)}
                        value={values.country}
                        onChange={handleInputChange}
                        options={countries}
                        error={errors.countries}
                        noneValue={"-"}
                    />
                </div> */}


                <div className="flex justify-end mt-2">
                    {/* <Controls.Button
                        disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                        text={t(`button.save`)}
                        xsFont
                        onClick={() => handleUpdateInfo()}
                    /> */}
                    <BtnShad
                        disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                        text={t(`button.save`)}
                        size={"sm"}
                        onClick={() => handleUpdateInfo()}
                        sendHistory={{ action: ` Update info user : ${change.map((m, i) => m.key)} `, who: `${uid}` }}
                    />
                </div>
            </Form>
        </div>
    );
}

export default InfoForm;
