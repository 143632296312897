import React from 'react'

function Platform(props) {
    const { type, noBg } = props
    return (
        <div>
            {type == 1 ?
                <div className={`${noBg ? "text-yellow-500" : "bg-yellow-500 text-white "} w-fit p-1 rounded-lg font-medium text-xs`}>BINANCE </div> :
                type == 2 ?
                    <div className={`${noBg ? "text-purple-500" : "bg-purple-500 text-white "} w-fit p-1 rounded-lg font-medium text-xs`}>KRAKEN </div> :
                    null
            }
        </div>
    )
}

export default Platform
