import React, { useState } from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar'
import { authSelector } from '../features/authSlice';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import * as sideMenus from "../utils/sideMenus";
import * as service from "../utils/service"
import ObAi from '../components/ObAi';


function OnboardingStrategy(props) {
    const { t } = props;
    const { id } = useParams(); // maka id
    const auth = useSelector(authSelector);
    const [subAccountList, setSubAccountList] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [assetSummary, setAssetSummary] = useState({});
    const [totalEvolution, setTotalEvolution] = useState();


    // useEffect(() => {
    //     let isMounted = true;
    //     const controller = new AbortController();
    //     let urlParam = "/api/users/sub";
    //     let urlParam2 = "/api/users/getCapitalEvolutionMasterSub"


    //     if (!auth.role.includes(process.env.REACT_APP_CLIENT)) {
    //         urlParam = `/api/users/sub?reference=${id}`
    //         urlParam2 = `/api/users/getCapitalEvolutionMasterSub?reference=${id}`
    //     }
    //     const getSubAccountList = async () => {
    //         try {
    //             const { data } = await axiosPrivate.get(urlParam, {
    //                 signal: controller.signal,
    //             });
    //             const formatData = data.subUserList.map((resp) => ({
    //                 ...resp,
    //                 firstName: service.decryptCBData(resp.firstName),
    //                 lastName: service.decryptCBData(resp.lastName),
    //                 email: service.decryptCBData(resp.email),
    //                 tel: service.decryptCBData(resp.tel),
    //             }));
    //             isMounted && setSubAccountList(formatData)
    //             isMounted && setAssetSummary({
    //                 totalUsdtSumLastRebal: data.totalUsdtSumLastRebal || 0,
    //                 totalActiveEnCours: data.totalActiveEnCours || 0,
    //                 totalReserveEnCours: data.totalReserveEnCours || 0,
    //                 totalSecuredEnCours: data.totalSecuredEnCours || 0,
    //                 totalActiveMasterEnCours: data.totalActiveMasterEnCours,
    //                 totalActiveSubAccountEnCours: data.totalActiveSubAccountEnCours || 0
    //             })
    //             console.log("data", data);
    //         } catch (error) {

    //         }
    //     }


    //     const getTotalEvolution = async () => {
    //         try {
    //             const { data } = await axiosPrivate.get(`/api/rebalancing/findBy/af/${id}`, {
    //                 signal: controller.signal,
    //             })
    //             isMounted && setTotalEvolution(data.filter((f) => f.capital !== null))
    //         } catch (error) {
    //             console.log("error", error);
    //         }
    //     }

    //     const getCapitalEvolutionMasterSub = async () => {
    //         try {
    //             const { data } = await axiosPrivate.get(urlParam2)
    //         } catch (error) {
    //             console.log("error", error);
    //         }
    //     }


    //     getSubAccountList();
    //     getTotalEvolution()
    //     // getCapitalEvolutionMasterSub()

    //     return () => {
    //         isMounted = false;
    //         controller.abort();
    //     };
    // }, [id])


    return (
        <>
            <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />
            <div className="flex flex-row flex-grow">
                <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />
                <div className="flex flex-row flex-1 mt-2 md:ml-52 ">
                    <div className="flex flex-col w-full p-4 ">
                        <div className="flex-none h-auto">
                            {/* <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">Installation de la nouvelle strategie</h1> */}

                            <ObAi />

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default OnboardingStrategy
