import React, { useEffect, useState } from "react";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import DashboardCard from "../components/cards/DashboardCard";
import { useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { authSelector } from "../features/authSlice";
import Title from "../components/title/Title";
import { UserGroupIcon, ScaleIcon, CreditCardIcon, CheckCircleIcon, XCircleIcon, XIcon } from "@heroicons/react/solid";
import WalletScreen from "./WalletScreen";
import * as service from "../utils/service";
import { toast } from "react-toastify";
import BannerInfo from "../components/BannerInfo";
import Tuto from "../components/tuto/Tuto";
import Controls from "../components/controls/Controls";
import BannerApi from "../components/BannerApi";
import moment from "moment";
import BtnShad from "../components/client/btnShadcn";
import CarrousselNotif from "../components/CarrousselNotif";

//import { w3cwebsocket as W3CWebSocket } from "websocket";

function ClientScreen(props) {
    const { t } = props;

    //states
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const auth = useSelector(authSelector);
    const navigate = useNavigate();
    const { id } = useParams(); // maka id
    const [userData, setUserData] = useState();

    // const [coinPrice, setCoinPrice] = useState([]);
    const [summary, setSummary] = useState();
    const [children, setChildren] = useState();
    const [totalInvested, setTotalInvested] = useState(0);
    const [addedK, setAddedK] = useState(0);
    const [total, setTotal] = useState();
    const [actif, setActif] = useState();
    const [secured, setSecured] = useState();
    const [reserved, setReserved] = useState();
    const [walletDetails, setWalletDetails] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [createdAt, setCreatedAt] = useState("00:00");
    const [total5, setTotal5] = useState();
    const [hasAiMailerlite, setHasAiMailerlite] = useState()
    const [info, setInfo] = useState()
    const [inputMailAi, setInputMailAi] = useState(false)

    //const [lastRebalDate, setLastRebalDate] = useState("DD/MM/YYYY");
    const [summaryRebal, setSummaryRebal] = useState({
        startDate: "DD/MM/YYYY",
        endDate: "DD/MM/YYYY",
        endCapitalRebal: "0.00",
        startCapital: "0.00",
        recurringAmount: "0.00",
    });

    const data = {
        "previous": {
            "ro": false,
            "t": false,
            "w": false,
            "bnb": false,
            "bsc": false,
            "trx": false
        },
        "new": {
            "ro": false,
            "t": false,
            "w": false,
            "bnb": false,
            "bsc": false,
            "trx": false
        }
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUser = async (myId) => {
            try {
                const { data } = await axiosPrivate.get(`/api/users/genericFindOne/${myId}`, {
                    signal: controller.signal,
                });
                const decrypt = service.decryptOneUserInfo(data.oneUser);

                console.log("test", data);
                isMounted && setUserData(decrypt);
                // console.log("**************** USER DATA", decrypt)
                if (decrypt.rebalancing.length > 0) {
                    isMounted &&
                        setSummaryRebal({
                            startDate: decrypt.rebalancing[0]?.date,
                            endDate: decrypt.rebalancing[decrypt.rebalancing.length - 1]?.date,
                            endCapitalRebal: JSON.parse(decrypt.rebalancing[decrypt.rebalancing.length - 1].totalAssets).usdt || 0,
                            startCapital: Number(JSON.parse(decrypt.rebalancing[0].totalAssets).usdt).toFixed(2) || 0,
                            recurringAmount: Number(decrypt.details.recurringAmount) || 0,
                        });
                }
                //dispacth(getUserDetails(data));
            } catch (error) {
                //console.log("error", error);
                if (error?.response?.status != 403) {
                    navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };



        // get client transaction data
        const getGainsDetailstransaction = async (myId) => {
            try {
                const { data } = await axiosPrivate.get(`/api/affiliate-payment/find/${myId}`, {
                    signal: controller.signal,
                });
                const decrypt = JSON.parse(service.decryptCBData(data));

                //isMounted && setAllData(data);
                isMounted && setTotal(decrypt?.map((p) => JSON.parse(p.total).usdt).reduce((acc, amount) => Number(acc) + Number(amount), 0));
            } catch (error) {
                if (error?.response?.status != 403) {
                    navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };

        const getTotalInvested = async (myid) => {
            try {
                const response = await axiosPrivate.get(`/api/users/totalInvested/${myid}`, {
                    signal: controller.signal,
                });
                if (response.data) {
                    console.log("Total Invested", response.data);
                }
                const seuil = response.data.totalInvested;
                setTotalInvested(seuil);
                setAddedK(response.data.addedK);
            } catch (error) { }
        };

        // get parrainage data
        const getGainsDetails = async (myId) => {
            try {
                const { data } = await axiosPrivate.get(`/api/affiliate/${myId}`, {
                    signal: controller.signal,
                });
                const decrypt = JSON.parse(service.decryptCBData(data));
                isMounted && setChildren(decrypt?.allAffiliate);
                isMounted && setSummary(decrypt?.summary);
            } catch (error) {
                // if (error.response.status != 403) {
                //   navigate("/login", { state: { from: location }, replace: true });
                // }
            }
        };

        if (auth?.role.includes(process.env.REACT_APP_CLIENT) || auth?.role.includes(process.env.REACT_APP_PARTENAIRE)) {
            getGainsDetails(auth?.id);
            getUser(auth?.id);
            getGainsDetailstransaction(auth?.id);
            getTotalInvested(auth?.id);
        } else {
            getGainsDetails(id);
            getUser(id);
            getGainsDetailstransaction(id);
            getTotalInvested(id);
        }

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getWalletDetails = async (id) => {
            try {
                const response = await axiosPrivate.get(`/api/rebalancing/walletDetails/${id}`, {
                    signal: controller.signal,
                });
                const { active, secured, reserved, dateExchange } = response.data;
                isMounted && setActif(active);
                isMounted && setSecured(secured);
                isMounted && setReserved(reserved);
                isMounted && setCreatedAt(dateExchange);
                console.log("=========fgafs======", dateExchange);
            } catch (error) {
                if (error.response.status == 401) {
                    navigate("/login", { state: { from: location }, replace: true });
                } else {
                    toast.error(t(`error.errorOccurred`));
                }
                console.log("tete", error);
            }
        };

        const getTotalFivePercentPaidBetweenDates = async (id) => {
            try {
                const { data } = await axiosPrivate.get(`/api/pnl/paid/between?uid=${id}`, {
                    signal: controller.signal,
                });
                console.log("datakkkk", data.total);
                isMounted && setTotal5(data.total)
            } catch (error) {

            }
        }

        if (auth?.role.includes(process.env.REACT_APP_CLIENT) || auth?.role.includes(process.env.REACT_APP_PARTENAIRE)) {
            getWalletDetails(auth?.id);
            getTotalFivePercentPaidBetweenDates(auth?.id)
        } else {
            getWalletDetails(id);
            getTotalFivePercentPaidBetweenDates(id)
        }
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsLoading(false);
        }, 2000); // time out after 1 second

        // clean up function
        return () => {
            clearTimeout(timeoutId);
        };
    }, []); //

    const getTotalValue = (array) => {
        let total = 0;
        array?.forEach((a) => {
            total += a.value;
        });

        return total;
    };

    const handleGetTagAiFromMailerlite = async () => {
        try {
            setIsLoading(true)
            const { data } = await axiosPrivate.post(`/api/onboarding/new-strategy`)
            console.log("tag AI", data);
            setHasAiMailerlite(data?.isVerified)
            if (data?.isVerified === true) {
                navigate('/sub-account')
                setIsLoading(false)
            } else {
                setInputMailAi(true)
            }
            setIsLoading(false)
        } catch (error) {
            console.log("error handleGetTagAiFromMailerlite", error.response.data.msg);
            setIsLoading(false)
            // toast({
            //     description: t(`error.${error.response.data.msgCode}`),
            //     variant: "destructive",
            // })
        }
    }


    useEffect(() => {
        fetch(`${process.env.REACT_APP_INFO}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // console.log("jsonData.........................1......................", response.json());

                return response.json();
            })
            .then(jsonData => {
                // Access the day1 property from your JSON and update the state
                console.log("jsonData+++++++++++++++++++++++++++.", jsonData);
                setInfo(jsonData);
            })
            .catch(error => {
                console.error('Error fetching the JSON file:', error);
            });
    }, []);

    return (
        <>
            <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />

            <div className="flex flex-row flex-grow">
                <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />
                <div className="flex flex-row flex-1 mt-2 md:ml-52 ">
                    <div className="flex flex-col w-full p-4 ">
                        <div className="flex-none h-auto">
                            <div className="flex items-center ">
                                <div className="flex-1">

                                    {userData?.firstName ? (
                                        <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">
                                            <span className="font-light">{t("label.hello")} </span>
                                            {userData?.firstName},

                                        </h1>
                                    ) : (
                                        <div className="font-semibold  h-8 w-20 animate-pulse text-slate-700 bg-slate-300 dark:text-white"></div>
                                    )}
                                </div>
                                <div className="">
                                    {auth?.role.includes(process.env.REACT_APP_SUPPORT) ? userData?.platform == 1 ? <div className="bg-yellow-500 w-fit p-1 rounded-lg font-medium text-xs">BINANCE</div> : userData?.platform == 2 ? <div className="bg-purple-500 text-white w-fit p-1 rounded-lg font-medium text-xs">KRAKEN</div> : null
                                        : null}
                                </div>

                            </div>
                            {/* <div className=" p-2 w-fit rounded-xl "></div> */}
                            {/* <hr /> */}
                            {/* banner info for edit API  */}
                            {/* {userData?.platform == 1 ? <BannerApi t={t} data={userData?.details?.majIpAddress || {}} /> : null} */}



                        </div >
                        { }
                        <div className="">
                            {/* {auth?.id && <CarrousselNotif />} */}
                            <BannerInfo text={<div>
                                <div>{t(`taxe.info`, { year: moment().subtract(1, 'years').year() })} : <b>{total5} USDT</b> </div>
                                {/* <div >   {total5 > 310 ? t(`taxe.required`) : t(`taxe.noTaxe`)}</div>
                                <div>{t(`taxe.note`)}</div> */}

                            </div>} type="info" noMarging />

                            {/* <BannerInfo
                                text={
                                    <div>
                                        <div>Banner pour AI, texte à fournir</div>
                                        <BtnShad text={"vérif abonnement AI"} onClick={handleGetTagAiFromMailerlite} isLoading={isLoading} />
                                    </div>}
                                type="neutral" noMarging /> */}
                        </div>

                        {/* Rebalancing */}
                        <div div className=" bg-slate-100 dark:bg-slate-800 p-2 rounded-sm mt-3" >
                            <Title.SectionTitle
                                text={t(`label.rebalancing`)}
                                icon={<ScaleIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                unit="(USDT)"
                                path={`/rebalancing`}
                            // 	onClick={navigate(`/rebalancing/${id}`)}
                            />

                            <div className="flex flex-wrap justify-start text-sm sm:flex-row sm:flex-wrap gap-x-3 gap-y-3">
                                <div className="flex flex-wrap md:space-around md:justify-between w-full m-3 gap-5 ">
                                    <div className="flex flex-col justify-center gap-y-2 ">
                                        <div className=" py-1">
                                            <strong>{t(`label.firstRebal`)}</strong> : {summaryRebal && summaryRebal.startDate}
                                        </div>
                                        <div className=" py-1">
                                            <strong>{t(`label.lastRebal`)}</strong> : {summaryRebal && summaryRebal.endDate}
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap justify-end gap-x-2 mt-2 ">
                                        <DashboardCard description={t(`label.actifLastRebal`)} number={summaryRebal && summaryRebal?.endCapitalRebal} unit={"USDT"} bg={"bg-blue-600"} />
                                        <DashboardCard description={`${t(`label.sumActif`)} (${t(`label.inProgress`)})`} number={isLoading ? walletDetails?.test : actif && getTotalValue(actif).toFixed(2)} unit={"USDT"} bg={"bg-blue-600"} />
                                        <DashboardCard
                                            description={`${t(`label.sumReserve`)} (${t(`label.inProgress`)})`}
                                            number={isLoading ? walletDetails?.test : reserved && getTotalValue(reserved).toFixed(2)}
                                            unit={"USDT"}
                                            bg={"bg-blue-600"}
                                        />
                                        <DashboardCard
                                            description={`${t(`label.sumSecured`)} (${t(`label.inProgress`)})`}
                                            number={isLoading ? walletDetails?.test : secured && getTotalValue(secured).toFixed(2)}
                                            unit={(secured && secured[0]?.asset) || "USDT"}
                                            bg={"bg-blue-600"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <WalletScreen t={t} actif={actif} reserved={reserved} secured={secured} createdAt={createdAt} />
                            </div>
                            <div></div>
                        </div >

                        {/* Parrainage */}
                        <div div className="md:flex gap-x-2" >
                            <div className=" bg-slate-100 dark:bg-slate-800 p-2 rounded-sm mt-3 w-full md:w-2/3">
                                <Title.SectionTitle
                                    text={t(`label.referal`)} // parrainage
                                    icon={<UserGroupIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                    path={`/affiliate`}
                                />

                                <div className="flex flex-wrap md:justify-start gap-2 my-2">
                                    <DashboardCard description={t(`label.children`)} number={children?.length} />
                                    <DashboardCard description={t(`label.totalGains`)} number={summary?.allPnlSum} unit="USDT" />

                                    <DashboardCard description={t(`label.gainsPayés`)} number={summary?.allPnlPaid} unit="USDT" />
                                    <DashboardCard description={t(`label.unpaid`)} number={summary?.allPnlUnpaid} unit="USDT" />
                                </div>
                            </div>

                            {/* Payment */}
                            <div className=" bg-slate-100 dark:bg-slate-800 p-2 rounded-sm mt-3 w-full md:w-1/3">
                                <Title.SectionTitle text={t(`label.payment`)} icon={<CreditCardIcon className="h-5 w-5 text-blue-700 text-xs" />} unit="(USDT)" path={`/transaction`} />

                                <div className="flex flex-wrap md:justify-start gap-2 my-2">
                                    <DashboardCard number={total && total} description={t("label.sumPaidAll")} />
                                </div>
                            </div>
                        </div >
                        <div>

                        </div>
                    </div >
                </div >
            </div >
        </>
    );
}

export default ClientScreen;
