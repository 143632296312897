import React from 'react'
import {
    Select as SelectShadcn,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "../ui/select"
import { cn } from '../../lib/utils';
import { Label } from '../ui/label';
import { TypographySmall, TypographyXSError } from '../Typo';

function SelectShad(props) {
    const { option, placeholder, onChange, name, value, label, labelInput, error = null, className, defaultValue } = props

    const convertToDefaultEventPara = (name, value) => ({
        target: {
            name,
            value,
        },
    });

    return (
        <div>
            {/* <SelectShadcn /> */}
            {/* {(label && !labelInput) && <span className='text-xs ml-2 '> {label} </span>} */}
            {labelInput && <Label htmlFor={name} className="text-left font-medium text-sm ml-2">{label} </Label>}

            <SelectShadcn
                onValueChange={(text) => onChange(convertToDefaultEventPara(name, text))}
                name={name}
                value={value}
                defaultValue={defaultValue}
            >
                <SelectTrigger className={cn(``, className)}>
                    <SelectValue placeholder={placeholder} />
                </SelectTrigger>
                <SelectContent >
                    <SelectGroup >
                        {option?.map((o, i) => (

                            <SelectItem key={i} value={o?.id}>{o?.item}</SelectItem>
                        )
                        )
                        }
                    </SelectGroup>

                </SelectContent>
            </SelectShadcn>
            {/* {error && <span className='text-red-500 text-xs ml-2'> {error}</span>} */}
            {error && <TypographyXSError className="">{error}</TypographyXSError>}

        </div>
    )
}

export default SelectShad
