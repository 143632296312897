import React, { useEffect, useState } from 'react'
import { Show } from '../Show'
import BtnShad from './client/btnShadcn'
import { useTranslation } from 'react-i18next'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import SelectShad from './client/SelectShad'
import Strategy from './badge/Strategy'
import { useFOrm } from './useFOrm'
import { InputShadcn } from './client/InputShadcn'
import ApiSubAutonome from './step/ApiSubAutonome'
import DepotSubAutonome from './step/DepotSubAutonome'
import { useNavigate } from 'react-router-dom'
import TopBar from './TopBar'
import * as service from "../utils/service"
import { useSelector } from 'react-redux'
import { authSelector } from '../features/authSlice'
import { useToast } from './ui/use-toast'
import { PartyPopper, Sparkles, X } from 'lucide-react'
import { TypographyH2, TypographyH3, TypographyH4, TypographyP, TypographySBold, TypographySmall, TypographyXS } from './Typo'
import ProgressBarOb from './client/ProgressBarOb'
import BtnNextPrev from './BtnNextPrev'
import CreateSubAccount from './step/CreateSubAccount'
import { loadLink, tutoLinkSelector } from '../features/tutoLinkSlice'
import { useDispatch } from 'react-redux'
import Acr from './step/Acr'
import Congrats from './step/Congrats'
import Loading from './Loading'
import { addTagToMailerLite } from '../lib/utils'

function LogoHeader() {
    return (
        <div className='font-bold text-center flex items-center justify-center  w-full'>
            <img className="object-contain h-20 " src="https://crypto-bulot.com/wp-content/uploads/2022/01/Crypto-bulot.png" />
            {/* <X className='h-3'/> */}
            <div className='flex justify-center text-cyan-500 items-center'>
                <Sparkles className='mr-2' />
                <div className=" text-2xl"> AI style  </div>
            </div>
        </div>
    )
}

function ObAi() {
    const { t } = useTranslation()
    const auth = useSelector(authSelector);
    console.log("auth", auth.id, auth.email);
    const [step, setStep] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(true)
    const [hasAiMailerlite, setHasAiMailerlite] = useState(null)
    const [newIdSub, setNewIdSub] = useState()
    const [mdp, setMdp] = useState(false)
    const [subAccountList, setSubAccountList] = useState([]);
    const [hasAiDone, setHasAiDone] = useState()
    const [displayFormulaire, setDisplayFormulaire] = useState(false)
    const navigate = useNavigate()
    const { toast } = useToast()
    const tutoLink = useSelector(tutoLinkSelector)
    const dispatch = useDispatch()

    const axiosPrivate = useAxiosPrivate()
    const initialValues = {
        email: "",
        otherEmail: ""
    }
    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        // if ("code" in fieldValues) temp.code = fieldValues.code ? "" : t("error.required");
        if ("code" in fieldValues) {
            temp.code = !fieldValues.code ? t("error.required") : /\s/.test(fieldValues.code) ? "Le texte contient des espaces" : "";
        }

        setErrors({
            ...temp,
        });
        console.log("Temp", temp);

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);



    useEffect(() => {
        fetch(`${process.env.REACT_APP_INFO}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // console.log("jsonData.........................1......................", response.json());

                return response.json();
            })
            .then(jsonData => {
                // Access the day1 property from your JSON and update the state
                // console.log("jsonData+++++++++++++++++++++++++++.", jsonData);
                // setInfo(jsonData);
                if (tutoLink != null) { }
                console.log("Ato amin'ny tuto Link", jsonData);
                dispatch(loadLink(jsonData))
            })
            .catch(error => {
                console.error('Error fetching the JSON file:', error);
            });
    }, []);

    const handleGetTagAiFromMailerlite = async () => {
        try {
            setIsLoading(true)
            const { data } = await axiosPrivate.post(`/api/onboarding/new-strategy`)
            console.log("tag AI", data);
            setHasAiMailerlite(data?.isVerified)
            if (data?.isVerified) {

                const dataToSendMailerlite = { id: auth.id, gname: "CreationSC_AI" }
                await addTagToMailerLite(dataToSendMailerlite)
                console.log("LAsa le tag", dataToSendMailerlite);

                setStep(1)
            } else {
                setHasAiMailerlite(false)
                // setNoTAgAi(true)
            }

            setIsLoading(false)
        } catch (error) {
            console.log("error handleGetTagAiFromMailerlite", error.response.data.msg);
            setIsLoading(false)
            // if (error.response.data.msg === "no strategy found") {
            setHasAiMailerlite(false)
            // }
            toast({
                description: t(`error.${error.response.data.msgCode}`),
                variant: "destructive",
            })
        }
    }


    const handleCreateSubAccount = async () => {
        setIsLoading(true)
        try {
            // check if mdp is OK
            const datatoPost = {
                password: values.password,
                step: 2,
                strategy: values.strategy,
                platform: values.platform,

            }
            const { data } = await axiosPrivate.post(`/api/onboarding/authorize`, datatoPost)
            console.log("create sub Account", datatoPost, "data", data);
            setNewIdSub(data.newId)
            // clone master account
            setStep(data?.step)
            setIsLoading(false)

        } catch (error) {
            console.error("handleCreateSubAccountt", error);
            setIsLoading(false)
        }
        setIsLoading(false)

    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        let urlParam = "/api/users/sub?onlysub=multi";


        // if (!auth.role.includes(process.env.REACT_APP_CLIENT)) {
        //     urlParam = `/api/users/sub?reference=${id}`
        // }
        const getSubAccountList = async () => {
            try {
                const { data } = await axiosPrivate.get(urlParam, {
                    signal: controller.signal,
                });
                const formatData = data.subUserList.map((resp) => ({
                    ...resp,
                    firstName: service.decryptCBData(resp.firstName),
                    lastName: service.decryptCBData(resp.lastName),
                    email: service.decryptCBData(resp.email),
                    tel: service.decryptCBData(resp.tel),
                }));
                isMounted && setSubAccountList(formatData?.filter(f => f.strategy === "ai" && f.status == 18))
                isMounted && setHasAiDone(formatData?.filter(f => f.strategy === "ai" && f.status != 18))
                console.log("getSubAccountList===========", data);
            } catch (error) {
                console.log("getSubAccountList", error);
            }
        }

        getSubAccountList();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [])


    useEffect(() => {
        setTimeout(() => {
            setInitialLoading(false)
        }, 3000);
    }, [])
    
    const continueOnboarding = async (infoSub) => {
        try {
            setNewIdSub(infoSub.id)
            setStep(infoSub.onboardingStep)
            const paramUser = infoSub.id + infoSub.onboardingStep
            navigate(`?strategy=${encodeURIComponent(paramUser)}`, { replace: true })

            console.log("infoSub.onboardingStep", infoSub.onboardingStep);

        } catch (error) {
            console.error("continueOnboarding", error);
        }
    }

    const handleNextStep = (newStep) => {
        setStep(newStep)
        console.log("handleNextStep", newStep);
    }

    if (hasAiDone?.length > 0) {
        return (
            <div className=' w-full md:w-1/2 mx-auto'>
                <LogoHeader />
                <TypographySmall>{t(`obAi.alreadyHaveAI`)}</TypographySmall>
                <TypographySmall>{t(`obAi.goSubAccount`)}</TypographySmall>
                <BtnShad text={"Voir mon compte AI"} onClick={() => {
                    navigate(`/sub-account`)
                }} isLoading={isLoading} className="w-full" />
            </div>
        )
    }

    if (displayFormulaire) {
        return (
            <div>
                <div className=' w-full md:w-1/2 mx-auto'>
                    <LogoHeader />
                    <TypographySmall>{t(`obAi.alreadyHaveAI`)}</TypographySmall>
                    <TypographySmall>{t(`obAi.goSubAccount`)}</TypographySmall>
                    <BtnShad text={"Voir mon compte AI"} onClick={() => {
                        navigate(`/sub-account`)
                    }} isLoading={isLoading} className="w-full" />
                </div>
            </div>
        )
    }
    return (
        <div className='flex justify-center text-justify'>
            <div className={`w-full ${step == 2 ? "md:w-full" : "md:w-1/2 max-w-1/2"} flex flex-col items-center`}>
                {/* subAccountList : {subAccountList.length} */}
                {/* ml:  {hasAiMailerlite} */}
                <LogoHeader />
                {step != 0 && <ProgressBarOb steps={5} currentStep={step} />}

                <Show.When isTrue={step == 0} >
                    {/* Compte AI déjà créer mais installation non terminé */}

                    {initialLoading ? <Loading /> :
                        subAccountList.length > 0 ?
                            <div className=' w-full'>
                                <div className='flex flex-col'>

                                    <TypographySmall>  {t(`obAi.obNotFinish`)}</TypographySmall>
                                    <TypographySmall>  {t(`obAi.infoSub`)}</TypographySmall>
                                    <TypographySmall>  {t(`obAi.continue`)}</TypographySmall>

                                </div>

                                <div className=' mt-4 text-sm border-2 rounded-lg p-2 border-slate-300'>
                                    {subAccountList.map((s, index) => (
                                        <div className='grid grid-cols-3 items-center '>
                                            {/* <div className='flex  bg-red-300'> */}
                                            <div className='col-span-2'>
                                                <TypographySBold> {s.firstName} {s.lastName}</TypographySBold>
                                                <TypographyP className={"truncate "} > {s.email}</TypographyP>

                                                {/* <TypographyXS > {s.email}{s.email}</TypographyXS> */}
                                            </div>
                                            <div className='col-span-1 text-right'>
                                                <BtnShad text={"Continuer"} onClick={() => { continueOnboarding(s) }} isLoading={isLoading} variant={"outline"} />
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            :
                            <div className='flex flex-col  justify-center'>
                                {/* Compte AI pas créer  */}
                                {hasAiMailerlite != false && <div className='flex flex-col items-center text-center space-y-4'>

                                    <TypographyH3>
                                        {t(`obAi.welcome`)}
                                    </TypographyH3>

                                    <TypographySmall>{t(`obAi.verifEmail`)}</TypographySmall>
                                    {/* Check abonnement AI */}
                                    <BtnShad text={"Vérifier mon abonnement AI"} onClick={handleGetTagAiFromMailerlite} isLoading={isLoading} className="w-1/2" />
                                </div>}

                                {/* Ai in other email */}
                                {hasAiMailerlite == false ?
                                    <div className='flex- flex-col space-y-4'>
                                        {/* <div className='text-cyan-500 font-bold text-center flex justify-center '>
                                        <Sparkles className='mr-2' /> AI style
                                    </div> */}

                                        <TypographySmall>
                                            {t(`obAi.emailNoAi`, { email: service.decryptCBData(auth?.email) })}
                                            {/* Votre adresse email <b>{service.decryptCBData(auth?.email)}</b> n'est pas abonné à l'offre AI style */}
                                        </TypographySmall>
                                        <TypographySmall>
                                            {t(`obAi.support`)}
                                            {/* <div>Veuillez contacter le <a href='mailto:support@crypto-bulot.com'>support@crypto-bulot.com</a> pour plus d'info</div> */}
                                        </TypographySmall>

                                    </div>
                                    :
                                    null
                                }

                            </div>
                    }


                    {/* <BtnNextPrev step={step} setStep={setStep} /> */}
                </Show.When >


                {/* Create subAccount  */}
                < Show.When isTrue={step == 1} >
                    <div className='w-full mt-5 border-2 '>
                        <CreateSubAccount step={step} setStep={setStep} newIdSub={newIdSub} setNewIdSub={setNewIdSub} />
                    </div>
                    {/* <BtnNextPrev step={step} setStep={setStep} /> */}
                </Show.When >

                {/* API  */}
                < Show.When isTrue={step == 2} >
                    <div className='w-full mt-5 border-2'>

                        <ApiSubAutonome id={newIdSub} step={step} handleNextStep={handleNextStep} />
                    </div>
                    {/* <BtnNextPrev step={step} setStep={setStep} /> */}
                </Show.When >

                {/* Depot  */}
                < Show.When isTrue={step == 3} >
                    <div className='w-full mt-5 border-2 '>
                        <DepotSubAutonome id={newIdSub} step={step} handleNextStep={handleNextStep} />
                    </div>
                    {/* <BtnNextPrev step={step} setStep={setStep} /> */}
                </Show.When >

                {/* Acr  */}
                < Show.When isTrue={step == 4} >
                    <Acr step={step} handleNextStep={handleNextStep} id={newIdSub} />
                    {/* <BtnNextPrev step={step} setStep={setStep} /> */}

                </Show.When >

                {/* Congrats  */}
                < Show.When isTrue={step == 5} >
                    <Congrats />
                    {/* <BtnNextPrev step={step} setStep={setStep} /> */}
                </Show.When >


            </div>
        </div >
    )
}

export default ObAi
