import React, { useState, useEffect } from "react";
import Controls from "./controls/Controls";
import DialogBox from "./DialogBox";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Loading from "./Loading";
import { CheckCircleIcon, ChevronRightIcon, XCircleIcon } from "@heroicons/react/solid";
import { Hidden } from "@mui/material";
import { useParams } from "react-router-dom";
import { data } from "browserslist";
import { useSelector } from "react-redux";
import { zapSelector } from "../features/zapSlice";
import { toast } from "react-toastify";
import BtnShad from "./client/btnShadcn";
const LineLine = () => (
    <div className="flex ml-2">
        <div className="w-0.5 h-10 bg-slate-300" />
    </div>
);

const initialFvalues = {
    coin: null,
};

const zapCoins = [
    { id: "USDT", title: "USDT" },
    { id: "BTC", title: "BTC" },
    { id: "ETH", title: "ETH" },
];

const CustomLoading = () => (
    <div className="text-left">
        <div role="status">
            <svg className="inline mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                />
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                />
            </svg>
            <span className="sr-only">Loading...</span>
        </div>
    </div>
);
const ExpressRebalancing = (props) => {
    const { isOpen, setIsOpen, auth, t, data, totalInvested, capitalActif, hasRebal } = props;

    const axiosPrivate = useAxiosPrivate();

    const [atsUser, setAtsUser] = useState(0);
    const [atsError, setAtsError] = useState();
    const [check, setCheck] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [errMesage, setErrMessge] = useState("");
    const [values, setValues] = useState();
    const [vita, setVita] = useState(false);
    const [errorReeq, setErrorReeq] = useState(false);
    const [notOk, setNotOk] = useState(false);
    const [step1, setStep1] = useState(false);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [step4, setStep4] = useState(false);
    const [errorCoin, setErrorCoin] = useState("");
    const [coin, setCoin] = useState(null);
    const [isLoading, setIsLoading] = useState();
    const [disabledBtnAsset, setDisabledBtnAsset] = useState(false);
    const [equivalentPrice, setEquivalentPrice] = useState(1);
    const zapObject = useSelector(zapSelector);
    //   console.log("=============== data", data, "total invsti", totalInvested, "capital Actif", capitalActif, "has Rebal", hasRebal);
    //   console.log("=============== Zap", zapObject);
    const { id } = useParams();

    useEffect(() => {
        setCheck(false);
        setAtsUser(0);
        setStep1(false);
        setStep2(false);
        setStep3(false);
        setStep4(false);
        setNotOk(false);
    }, [isOpen, setIsOpen]);

    useEffect(() => {
        data && setCoin(data?.securedAsset);
    }, [data]);

    const handleCheck = async () => {
        // console.log("HEEEE", id, auth?.id);
        try {
            let uID;
            if (auth?.role.includes(process.env.REACT_APP_CLIENT) || auth?.role.includes(process.env.REACT_APP_PARTENAIRE)) {
                uID = auth?.id;
            } else {
                uID = id;
            }
            setCheck(true);
            if (!atsUser || atsUser === 0 || atsUser < 10) {
                // console.log("WELCOME HERE FALSE");
                setCheck(false);
                setAtsError("La valeur minimum à sécuriser est de 10 USDT");
                // throw "EROR";
                //throw "Veuillez ajouter le montant à sécuriser s'il vous plaît !";
            } else {
                // console.log("WELCOME HERE");
                const response = await axiosPrivate.get(`/api/pnl/calculatePnlExpress/${uID}/${atsUser}`);
                const { ats, currentBalanceActif, isOk, sumFive, pnl, zapActive4 } = response.data;
                if (isOk) {
                    setValues({ ats, currentBalanceActif, isOk, sumFive, pnl, zapActive4 });
                    setTimeout(() => {
                        setStep1(true); //ats ok
                    }, 2000);

                    setTimeout(() => {
                        setStep2(true); //pnl ok
                    }, 4000);

                    setTimeout(() => {
                        setStep3(true); // summary
                    }, 6000);

                    setTimeout(() => {
                        setStep4(true); //je confirme
                    }, 8000);
                } else {
                    setNotOk(true);
                    setCheck(false);
                }
            }
        } catch (error) {
            setErrorReeq(true);
            console.log("error in here:", error, auth);
        }
    };

    useEffect(() => {
        atsUser && setAtsError();
    }, [atsUser]);

    const handleProceed = async () => {
        let uID2;
        if (auth?.role.includes(process.env.REACT_APP_CLIENT) || auth?.role.includes(process.env.REACT_APP_PARTENAIRE)) {
            uID2 = auth?.id;
        } else {
            uID2 = id;
        }
        setConfirm(true);
        // setTimeout(() => {
        //     setVita(true);
        // }, 3000);
        try {
            const responseExpress = await axiosPrivate.get(`/api/zap/express/${uID2}/${atsUser}`);
            // await axiosPrivate.get(`/api/zap/express/1/500`)
            if (responseExpress.status == 200 && responseExpress.data) {
                setVita(true);
            } else {
                setErrorReeq(true);
                throw "ERROR";
            }
            //console.log()
        } catch (error) {
            setErrorReeq(true);
            //console.log("ERROR IN handleProceed", error)
        }
    };

    const handleSaveAsset = async () => {
        try {
            setIsLoading(true);
            await axiosPrivate.put(`/api/manage/securedAsset`, { securedAsset: coin });
            toast.success(t(`toast.successModif`))
            setIsLoading(false);
            setDisabledBtnAsset(true);
            //   console.log("ASSEt METY ehh ");
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        if (zapObject || Number(data?.securedAmount) > 0) {
            setDisabledBtnAsset(true);
        }
    }, [zapObject, data]);

    const [avgPrice, setAvgPrice] = useState(1);
    useEffect(() => {
        // Create a new WebSocket instance

        if (data?.securedAsset != "USDT") {
            let url;

            if (data?.securedAsset) {
                url = `wss://stream.binance.com:9443/ws/${(data?.securedAsset).toLowerCase()}usdt@ticker`;
                const socket = new WebSocket(url);
                // Handle WebSocket message event
                socket.onmessage = (event) => {
                    try {
                        const data = JSON.parse(event.data);
                        // Extract the average price from the WebSocket data
                        const avgPrice = data && data.a ? data.a : null;
                        setAvgPrice(avgPrice);
                    } catch (error) {
                        console.error("Error parsing WebSocket data:", error);
                    }
                };

                // Clean up the WebSocket connection when the component unmounts
                return () => {
                    socket.close();
                };
            }
        }
    }, [data?.securedAsset]);

    return (
        <div className={`${isOpen ? "transalet-x-100" : "transalet-x-0 "} bg-slate-100 p-4 h-fit transition-all duration-200 w-full flex text-center`}>
            <div className="w-full md:w-1/2 mx-auto ">
                <h3 className="">{t(`label.secureExpress`)} </h3>
                <div className="flex flex-col text-sm  text-slate-700">
                    <div> Asset de sécurisation : {data?.securedAsset}</div>
                    <div>
                        Total investi : <strong>{totalInvested} USDT</strong>
                    </div>
                    <div>
                        {" "}
                        Capital actif : <strong>{capitalActif} USDT</strong>
                    </div>
                    {capitalActif >= totalInvested && capitalActif > 10000 && hasRebal ? (
                        <>
                            <div>
                                Vous pouvez sécuriser jusqu'à <strong>{Number((capitalActif - totalInvested).toFixed(2))} USDT</strong>
                            </div>
                        </>
                    ) : (
                        <div className="mt-6">⚠️ Les conditions pour effectuer la sécurisation Express ne sont pas remplies</div>
                    )}
                </div>

                {capitalActif >= totalInvested && capitalActif >= 10000 && hasRebal ? (
                    // OK pour sécurisation
                    <>
                        {zapObject || Number(data?.securedAmount) > 0 ? (
                            <div className="text-xs mt-2 text-green-700 mb-4">
                                * Vous avez déjà une sécurisation mise en place. La sécurisation express se fera avec le même asset : <strong>{data?.securedAsset}</strong>
                            </div>
                        ) : (
                            <>
                                <div className="flex flex-col space-y-1 my-4">
                                    <div className="font-light pb-4 ">Veuillez choisir le CRYPTO de sécurisation qui vous convient</div>
                                    <Controls.Select
                                        name="coin"
                                        label={"CRYPTO"}
                                        value={coin}
                                        onChange={(e) => setCoin(e.target.value)}
                                        options={zapCoins}
                                        error={errorCoin}
                                        noneValue={"Aucun"}
                                        disabled={disabledBtnAsset ? true : false}
                                    />
                                    <Controls.Button
                                        text={`Je veux continuer avec ${coin}`}
                                        widthFull
                                        onClick={handleSaveAsset}
                                        isLoading={isLoading}
                                        disabled={coin && !disabledBtnAsset ? false : disabledBtnAsset && coin ? true : true}
                                    />
                                </div>
                            </>
                        )}

                        {/* {zapObject && Number(data?.securedAmount) > 0 ? null : "MISY"} */}

                        {disabledBtnAsset ? (
                            <>
                                {notOk == false ? (
                                    <div className={`flex flex-col  mx-auto gap-x-2 items-center ${check ? "hidden" : "block"}`}>
                                        <Controls.Input type="number" label="Montant à sécuriser en USDT" value={atsUser} onChange={(e) => setAtsUser(e.target.value)} error={atsError} />
                                        {/* <p className="">
                      {coin} à sécurisé ≈ {(atsUser / avgPrice).toFixed(8)}
                    </p> */}
                                        <div className="mt-1 w-full">
                                            <Controls.Button text="Valider" widthFull onClick={handleCheck} disabled={atsUser ? false : true} />
                                            <BtnShad text={"Valider "}
                                                onClick={handleCheck} wFull
                                                disabled={atsUser ? false : true}
                                                sendHistory={{ action: `Securisation EXPRESS `, who: `${auth?.id}` }}
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                {check && atsUser && (
                                    <>
                                        {/* <div className="text-2xl text-red-500">ATS: {ats ? ats : <Loading />}</div>
            <div className="text-2xl text-green-500">pnl today: {pnl ? pnl : <Loading />}</div>
            <div className="text-2xl">Total investi: {totalInvesti ? totalInvesti : <Loading />}</div> */}
                                        {/* <div className='text-2xl'>Previous PNL to pay : {sumFive > 0 ? sumFive : <Loading />}</div> */}

                                        <div className="flex flex-col justify-center  gap-x-5">
                                            {step1 && step1 ? (
                                                <>
                                                    <div className="flex text-sm mt-2">
                                                        <CheckCircleIcon className="text-green-500 w-5 h-5 mr-2" /> <div> {t(`label.amountToSecure`)}</div>
                                                    </div>
                                                </>
                                            ) : (
                                                <CustomLoading />
                                            )}
                                            <LineLine />
                                            {step2 && step2 ? (
                                                <>
                                                    <div className="flex text-sm mt-2">
                                                        <CheckCircleIcon className="text-green-500 w-5 h-5 mr-2" /> <div> {t(`label.condOfSecureOK`)}</div>
                                                    </div>
                                                </>
                                            ) : (
                                                <CustomLoading />
                                            )}
                                            <LineLine />
                                            {step3 && step3 ? (
                                                <>
                                                    <div className=" mt-1 ">
                                                        <div className="grid grid-cols-1 text-sm w-full ">
                                                            <div className="font-light text-left">
                                                                {atsUser == values.ats ? "Montant à sécuriser" : "Nouveau montant à sécuriser"} <strong> : {values.ats.toFixed(2)} USDT</strong>
                                                            </div>
                                                        </div>

                                                        <div className={`grid grid-cols-1 text-sm ${values.pnl > 0 ? "block" : "hidden"}`}>
                                                            <div className="font-light text-left">
                                                                {t(`label.5%CBmonth`)} : <strong>{(values.pnl * 0.05).toFixed(2)} USDT</strong>
                                                            </div>
                                                        </div>

                                                        <div className={`grid grid-cols-1 text-sm ${values.sumFive > 0 ? "block" : "hidden"}`}>
                                                            <div className="font-light text-left text-nowarp">
                                                                {t(`label.report5%CB`)}: <strong>{values.sumFive} USDT</strong>
                                                            </div>
                                                        </div>

                                                        <div className="grid grid-cols-1 text-sm">
                                                            {values.zapActive4 == 0 ? (
                                                                <div className="font-light text-left">
                                                                    {t(`label.secureExpressFees`)}: <strong> {t(`label.free`)} </strong>
                                                                </div>
                                                            ) : (
                                                                <div className="font-light text-left">
                                                                    {t(`label.secureExpressFees`)} : <strong> 20 USDT</strong>
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className={`grid grid-cols-1 text-sm ${values.pnl > 0 ? "block" : "hidden"}`}>
                                                            <div className="font-light text-left">
                                                                {t(`label.transfertFees`)} : <strong> 1 USDT</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <CustomLoading />
                                            )}
                                            <LineLine />

                                            {step4 && step4 ? (
                                                <>
                                                    <div className={`${confirm ? "hidden" : "block"}`}>
                                                        {" "}
                                                        <div className="flex text-sm justify-center ">
                                                            <div className="w-5 text-blue-600">
                                                                <ChevronRightIcon />
                                                            </div>
                                                            <div> {t(`label.confirmSecureExpress`)} ?</div>
                                                        </div>
                                                        <div className="mt-2 flex gap-x-2">
                                                            <Controls.Button small color="bg-slate-400 text-white hover:cursor-pointer hover:bg-slate-500" text="Annuler" widthFull onClick={() => setIsOpen(!isOpen)} />
                                                            <Controls.Button small color="bg-green-500 text-white hover:cursor-pointer hover:bg-green-600" text="Je confirme" widthFull onClick={handleProceed} />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <CustomLoading />
                                            )}
                                        </div>
                                    </>
                                )}
                                {notOk && (
                                    <div className="flex flex-col justify-center w-full ">
                                        <div className=" mx-auto">
                                            <XCircleIcon className="w-20 h-20 text-red-500" />
                                        </div>

                                        <div>{t(`label.secureExpressNotAvailable`)}</div>
                                    </div>
                                )}
                                {vita && vita ? (
                                    <div className="flex flex-col justify-center w-full ">
                                        <div className=" mx-auto">
                                            <CheckCircleIcon className="w-20 h-20 text-green-500" />
                                        </div>

                                        <div>
                                            {t(`label.secure`)} et {t(`label.rebalancing`)} {t(`label.done`)}
                                        </div>
                                    </div>
                                ) : null}

                                {errorReeq && errorReeq ? (
                                    <div className="flex flex-col justify-center w-full ">
                                        <div className=" mx-auto">
                                            <XCircleIcon className="w-20 h-20 text-red-500" />
                                        </div>

                                        <div>
                                            {t(`label.secureError`)}. {t(`label.contactSupport`)} .
                                        </div>
                                    </div>
                                ) : null}
                                {errMesage != "" && <div className="text-2xl text-red-500">{errMesage}</div>}
                            </>
                        ) : null}
                    </>
                ) : (
                    // Tsy misy choix mipoitra, pas de securisation Express
                    <div className="bg-white drop-shadow-md rounded mt-4 text-sm px-6 py-3 mx-auto">
                        <div className="font-semibold text-slate-600">Conditions :</div>
                        <div className="">• Capital actif {">"} Capital investi</div>
                        <div className=""> • Capital actif {">="} 10000 USDT</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExpressRebalancing;
