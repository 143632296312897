import { ArrowLeftIcon, XIcon } from '@heroicons/react/solid';
import { DataGrid, frFR, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Controls from '../components/controls/Controls';
import DialogBox from '../components/DialogBox';
import Loading from '../components/Loading';
import TableauGrid from '../components/TableauGrid';
import TransactionListUser from '../components/TransactionListUser';
import { useFOrm } from '../components/useFOrm';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { decryptCBData } from '../utils/service';

const initialFvalues = {
    paymentMethodId: "",
    status: ""
};

function SubscriptionScreen(props) {
    const [allSub, setAllSub] = useState()
    const axiosPrivate = useAxiosPrivate()
    const { t } = useTranslation()
    const [openTransaction, setOpenTransaction] = useState(false)
    const [selectedRow, setSelectedRow] = useState()
    const [openEditTransaction, setOpenEditTransaction] = useState(false)
    const [pm, setPm] = useState()
    const [oneRow, setOneRow] = useState()
    const [listTransaction, setListTransaction] = useState()

    const validate = (fieldValues = values) => {
        const temp = { ...errors };

        // if ("pName" in fieldValues) temp.pName = fieldValues.pName ? "" : t("error.required");

        setErrors({
            ...temp,
        });
        if (fieldValues == values) {
            return Object.values(temp).every((x) => x == "");
        }
    };

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    const getPaymentMeth = async () => {
        try {
            const { data } = await axiosPrivate.get(`/api/payments/m/`)
            setPm(data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const handleGetAllSub = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/subscriptions`, {
                    signal: controller.signal,
                })
                console.log("suuuubbbbb", data.rows);
                setAllSub(data.rows)
            } catch (error) {
                console.log(error);
            }
        }
        getPaymentMeth()
        handleGetAllSub();
    }, [])

    const handleOpenTransaction = async (transactionRow) => {
        try {

            setOpenTransaction(true)
            const { data } = await axiosPrivate.get(`api/transactions?uid=${transactionRow.uid}`)
            setListTransaction(data)

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (selectedRow) {
            handleOpenTransaction(selectedRow)
        }
    }, [openEditTransaction])



    const column = [
        {
            field: "id", headerName: "ID", type: "number", headerClassName: "dark:text-slate-100"
            , hide: true
        },
        {
            field: "email", headerName: "email", type: "text", headerClassName: "dark:text-slate-100", width: 200,
            valueGetter: (cellValues) => {
                return `${decryptCBData(cellValues.row.u.email)}`;
            },
            renderCell: (cellValues) => {
                return (
                    decryptCBData(cellValues.row.u.email)
                );
            },
        },
        {
            field: "product", headerName: "Produit", type: "text", headerClassName: "dark:text-slate-100", width: 200,
            valueGetter: (cellValues) => {
                return `${cellValues?.row?.product?.name}`;
            },
            renderCell: (cellValues) => {
                return (
                    cellValues?.row?.product?.name
                );
            },
        },

        {
            field: "plan", headerName: "Plan", type: "text", headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    t(`label.${cellValues.row.plan}`)

                );
            },
        },
        {
            field: "planPrice", headerName: "Mtt (USDT)", type: "number", headerClassName: "dark:text-slate-100",
            valueGetter: (cellValues) => {
                if (cellValues.row.triggerSpecialPrice) {
                    return cellValues.row.specialPrice
                }
                let plan = cellValues?.row?.plan
                return cellValues.row.product.plan[plan]
            },
            renderCell: (cellValues) => {
                if (cellValues.row.triggerSpecialPrice) {

                    return (
                        cellValues.row.specialPrice
                    );
                }
                let plan = cellValues?.row?.plan
                return (
                    cellValues.row.product.plan[plan]
                );
            },
        },
        {
            field: "createdAt", headerName: "Date de création", type: "date", headerClassName: "dark:text-slate-100", width: 150,
            valueGetter: (cellValues) => {
                return (moment(cellValues.row.createdAt).format("DD-MM-YYYY, HH:mm "))
            },
            renderCell: (cellValues) => {
                return (moment(cellValues.row.createdAt).format("DD-MM-YYYY, HH:mm "))
            },
        },
        { field: "totalDuration", headerName: "Réc totale", type: "number", headerClassName: "dark:text-slate-100" },
        { field: "remainingDuration", headerName: "Réc restante", type: "number", headerClassName: "dark:text-slate-100" },
        {
            field: "isActive", headerName: "Status", type: "text", headerClassName: "dark:text-slate-100", width: 70,
            valueGetter: (cellValues) => {
                if (cellValues.row.isActive) {
                    return "Active";
                } else {
                    return "Stop";
                }

            },
            renderCell: (cellValues) => {
                if (cellValues.row.isActive) {
                    return (
                        <div className='text-green-500 text-xs font-bold'>Active</div>
                    )
                } else {
                    return (
                        <div className='text-red-500 text-xs font-bold'>Stop</div>
                    )
                }

            },
        },

        {
            field: "startDate", headerName: "Début", type: "date", headerClassName: "dark:text-slate-100", width: 100,
            valueGetter: (cellValues) => {
                return (moment(cellValues.row.startDate, "YYYY-MM-DD").format("MM-YYYY"))
            },
            renderCell: (cellValues) => {
                return (moment(cellValues.row.startDate, "YYYY-MM-DD").format("MM-YYYY"))
            },
        },
        {
            field: "nextPaymentDate", headerName: "Prochain", type: "date", headerClassName: "dark:text-slate-100", width: 100,
            valueGetter: (cellValues) => {
                return (moment(cellValues.row.nextPaymentDate, "YYYY-MM-DD").format("MM-YYYY"))
            },
            renderCell: (cellValues) => {
                let date;
                if (cellValues.row.plan == "oneShot") {
                    date = moment(cellValues.row.startDate, "YYYY-MM-DD").format("MM-YYYY")
                } else {
                    date = moment(cellValues.row.nextPaymentDate, "YYYY-MM-DD").format("MM-YYYY")

                }
                return date
            },
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-primary dark:text-slate-100 hover:text-secondary"}
                        text={"transaction"}
                        onClick={() => {
                            handleOpenTransaction(cellValues.row)
                            setSelectedRow(cellValues.row)

                        }}
                    />
                );
            },
        },
    ]





    return (
        <div className="p-4  gap-3 h-[93vh]" >
            <div className=" bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 h-full " >
                <h3 className="font-bold text-xl text-slate-700 dark:text-slate-100">Abonnements</h3>

                <div className="h-[450px] bg-slate-100 dark:bg-slate-800 mt-2">
                    {/*  {allSub ? (*/}
                    {allSub ? <TableauGrid column={column} dataRows={allSub && allSub} showQuickFilter /> : <Loading />}

                </div>
            </div>
            <DialogBox isOpen={openTransaction} setIsOpen={setOpenTransaction} customClassName="w-full  " title={"Détails transactions"}>
                <div className='flex flex-col space-y-2 text-xs'>
                    <div className=''>

                        Transaction pour :  <strong>{decryptCBData(selectedRow && selectedRow?.u?.email)}</strong>
                    </div>
                    <div className='text-xs'>
                        <div>
                            Produit : <strong>{selectedRow && selectedRow?.product?.name}</strong>
                        </div>
                    </div>
                    <TransactionListUser oneSub={selectedRow} />
                </div>
            </DialogBox>

        </div>
    )
}

export default SubscriptionScreen
