import React, { useMemo, useState } from "react";
import "chartjs-adapter-moment";
import moment from "moment";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { CrosshairPlugin, Interpolate } from "chartjs-plugin-crosshair";
import Controls from '../controls/Controls';
import { exportExcel } from '../../utils/service';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, zoomPlugin, CrosshairPlugin);

export const options = {
    responsive: true,
    maintainAspectRatio: false,

    /*  scales: {
       yAxis: {
           type: 'logarithmic',
           ticks: {
             min: 10,
             color: 'black',
             beginAtZero:false,
             sampleSize: 5,
             callback: function(value) { return abbreviateNumber(value)}
             }

         },
         xAxis: {
           type: 'time',
           time: {
             format: "DD/MM/YYYY"
           },
          }
       },*/

    scales: {
        x: {
            grid: {
                display: false,
            },
            type: "time",
            time: {
                unit: "month",
            },
            /* ticks: {
                     autoSkip: false,
                     maxRotation: 0,
                     major: {
                       enabled: true
                     }}*/
        },
        y: {
            display: true,
            type: "logarithmic",
        },
    },

    plugins: {
        legend: {
            position: "bottom",
        },
        title: {
            display: false,
            text: "Chart.js Line Chart",
        },
        tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
                title: (context) => {
                    const d = new Date(context[0].parsed.x);
                    return moment(d).format("DD-MM-YYYY");
                },
            },
        },
        zoom: {
            pan: {
                enabled: true,
                mode: "x",
            },
            limits: {
                x: {
                    min: "original",
                    max: "original",
                },
            },
            zoom: {
                pinch: {
                    enabled: true, // Enable pinch zooming
                },
                wheel: {
                    enabled: true, // Enable wheel zooming
                },
                mode: "x",
            },
        },
        crosshair: {
            line: {
                color: "#64748b", // crosshair line color
                width: 1, // crosshair line width
            },
            sync: {
                enabled: true, // enable trace line syncing with other charts
                group: 1, // chart group
                //   suppressTooltips: false   // suppress tooltips when showing a synced tracer
            },
            // zoom: {
            //   enabled: true,                                      // enable zooming
            //   zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box
            //   zoomboxBorderColor: '#48F',                         // border color of zoom box
            //   zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            //   zoomButtonClass: 'reset-zoom',                      // reset zoom button class
            // },
            // callbacks: {
            //   beforeZoom: () => function (start, end) {                  // called before zoom, return false to prevent zoom
            //     return true;
            //   },
            //   afterZoom: () => function (start, end) {                   // called after zoom
            //   }
            // }
        },
    },
    /* elements:{
         point:{
           radius:3,
           borderWidth:2,
           backgroundColor:'white',
           hoverRadius:4,
           hoverBorderWidth:4
         }
       }*/
    elements: { line: { borderWidth: 2 } },
};

const CustomLegend = ({ text, color, coef }) => (
    <div className="flex  gap-x-2 items-center">
        <div className={`${color} h-4 w-5`} />
        <div className="flex-col">
            <div className="text-xs text-slate-500">{text}</div>
            {coef && <div className="text-xs text-slate-500">Coef {coef}</div>}
        </div>
    </div>
);

const expensiveCalculation2 = (dataRow) => {
    let arrayOfDates = dataRow && dataRow.map((d) => d.date);
    let arrayOfindex = dataRow && dataRow.map((d) => d.indexCB);
    let arrayOfOrder = dataRow && dataRow.map((d) => d.order);
    let arrayOfPercentageEvolution = dataRow && dataRow.map((d) => d.percentageEvolution);
    let arrayOfCoefOrder = dataRow && dataRow.map((d) => d.coefOrder);
    let arrayOfmm20 = dataRow && dataRow.map((d) => d.mm20m);
    let arrayOfBbsup = dataRow && dataRow.map((d) => d.bbsup);

    let capitalInitial = 20;
    let reserveCapital = 0;
    let capitalArr = [];
    let capitalReserveArr = [];
    let capitalReserve;
    let capitalActif;
    let orangeLine = [];
    let arrayOfmmBbsup = []

    let capitalActifBlue;
    let blueLineArr = [];
    arrayOfindex.forEach((el, i) => {
        // if (i >= 1) {
        if (i == 0) {
            // console.log("fewfwe", i, arrayOfindex[i], arrayOfDates[i])
            capitalActifBlue = 20;
            blueLineArr.push(capitalActifBlue);
            // bfBlue = capitalActifBlue
        } else {
            if (arrayOfOrder[i] != null) {
                //calcul evolution of capital based on index evolution nex month
                let capitalEvolution = (Number(blueLineArr[i - 1]) * arrayOfPercentageEvolution[i]) / 100;
                capitalActifBlue = Number(blueLineArr[i - 1]) + Number(capitalEvolution);

                blueLineArr.push(capitalActifBlue);
                //  bf = capitalActifBlue
            }
            // }
        }
    });

    ///orange with green line
    arrayOfindex.forEach((el, i) => {
        if (i == 0) {
            capitalActif = capitalInitial;
            capitalReserve = reserveCapital;
            capitalArr.push(capitalActif);
            capitalReserveArr.push(capitalReserve);
            orangeLine.push((capitalActif + capitalReserve).toFixed(0));
            arrayOfmmBbsup.push((arrayOfBbsup[i]).toFixed(0))
        } else {
            if (arrayOfOrder[i] != null) {
                //calcul evolution of capital based on index evolution nex month
                let capitalEvolution = (Number(capitalActif) * arrayOfPercentageEvolution[i]) / 100;
                capitalActif = Number(capitalActif) + Number(capitalEvolution);

                //based on order
                if (arrayOfOrder[i] == 1) {
                    //achat buy bleu -> vert
                    if (capitalReserveArr[i - 1] > 0) {
                        const amountAddToKActif = capitalReserveArr[i - 1] * 0.1 * arrayOfCoefOrder[i];
                        capitalActif += Number(amountAddToKActif);
                        capitalReserve -= Number(amountAddToKActif) || 0;
                    }
                } else {
                    const amountAddToKReserve = capitalActif * 0.1 * arrayOfCoefOrder[i];
                    capitalActif -= Number(amountAddToKReserve) || 0;
                    capitalReserve += Number(amountAddToKReserve);
                }

                capitalArr.push(capitalActif);
                capitalReserveArr.push(capitalReserve);
                orangeLine.push((capitalActif + capitalReserve).toFixed(0));
                arrayOfmmBbsup.push((arrayOfBbsup[i]).toFixed(0))
            }
        }

    });

    let formatData = {
        labels: arrayOfDates,
        index: arrayOfindex,
        order: arrayOfOrder,
        coefOrder: dataRow && dataRow.map((d) => d.coefOrder),
        coefBbsupIndex: dataRow && dataRow.map((d) => d.coefBbsupIndex),
        indexCB: dataRow && dataRow.map((d) => d.indexCB),
        orangeLine,
        arrayOfmmBbsup,
        blueLineArr
    };

    return formatData;
};

function MyLineIndex(props) {
    moment.locale("fr");
    const { screenName, t, cbdData, mm } = props;
    console.log("ACCESSS", cbdData)

    // const getdata = useMemo(() => expensiveCalculation2(data), []);

    // let formToexcel = data?.map((d, i) => {
    //     return {
    //         date: getdata?.labels[i],
    //         CBS: getdata?.blueLineArr[i],
    //         CBT: getdata?.orangeLine[i]
    //     }
    // });
    let formToexcel = cbdData?.daty.map((d, i) => {
        return {
            date: cbdData?.daty[i],
            CBS: cbdData?.cbs[i],
            CBT: cbdData?.cbt2[i]?.amount, // cbtv2
            CBD: cbdData?.cbDdata[i]?.amount,
            CBDT: cbdData?.cbdtUp[i]?.amount, //cbdtv2
        }
    })

    let formToexcelAdmin = cbdData?.daty.map((d, i) => {
        return {
            date: cbdData?.daty[i],
            Top30: cbdData?.indexBrut[i],
            MM: cbdData?.arrMmBbsup[i],
            CBS: cbdData?.cbs[i],
            AI_CBS: cbdData?.aiCbs[i] == null ? 0 : cbdData?.aiCbs[i],
            // CBTv1: cbdData?.cbt[i]?.amount,
            CBT: cbdData?.cbt2[i]?.amount,
            AI_CBT: cbdData?.aiCBT[i]?.amount == null ? 0 : cbdData?.aiCBT[i]?.amount,
            CBD: cbdData?.cbDdata[i]?.amount,
            // CBDTv1: cbdData?.cbdt[i]?.amount,
            CBDT: cbdData?.cbdtUp[i]?.amount,
        }
    })



    console.log("daty", cbdData.daty.length);
    const dataAdmin = {
        labels: cbdData?.daty,
        datasets: [
            {
                label: "Top 30",
                data: cbdData?.indexBrut,
                borderColor: "#facc15",
                backgroundColor: "#facc15",
                pointRadius: 1,
            },
            {
                label: "MM + STDEV",
                data: cbdData?.arrMmBbsup,
                borderColor: "#4ade80",
                backgroundColor: "#4ade80",
                pointRadius: 1,
                display: false
            },
            {
                label: "CBS",
                data: cbdData?.cbs,
                borderColor: "#3b82f6",
                backgroundColor: "#3b82f6",
                pointRadius: 1,
            },
            {
                label: "AI CBS",// dec 2023
                data: cbdData?.aiCbs,
                borderColor: "#e879f9",
                backgroundColor: "#e879f9",
                pointRadius: 1,
            },
            {
                label: "AI CBT", // dec 2023
                data: cbdData?.aiCBT?.map((c) => c.amount),
                borderColor: "#2dd4bf",
                backgroundColor: "#2dd4bf",
                pointRadius: 1,
            },
            {
                label: "CBT", // oct 2023
                data: cbdData?.cbt2?.map((c) => c.amount),
                backgroundColor: "#fb923c",
                borderColor: "#fb923c",
                pointRadius: 4,
                pointBoderColor: "transparent",
                borderWidth: 1,

                pointBackgroundColor: function (context) {
                    var index = context.dataIndex;
                    const colorCbtData = cbdData?.cbt2?.map((c) => c.colorcbt);
                    return colorCbtData[index]
                },
            },
            {
                label: "CBD", // oct 2023
                data: cbdData?.cbDdata?.map((c) => c.amount),
                borderColor: "#ef4444",
                backgroundColor: "#ef4444",
                pointRadius: 4,
                pointBoderColor: "transparent",
                borderWidth: 1,

                pointBackgroundColor: function (context) {
                    var index = context.dataIndex;
                    const topData = cbdData?.cbDdata?.map((c) => c.top);
                    switch (topData[index]) {
                        case 5:
                            return "#ef4444"; // red-500
                            break;
                        case 30:
                            return "#06b6d4";
                            break;

                        default:
                            return "transparent";
                            break;
                    }
                },
            },
            // {
            //     label: "CBDTv1",
            //     data: cbdData?.cbdt?.map((c) => c.amount),
            //     borderColor: "#8b5cf6",
            //     backgroundColor: "#8b5cf6",
            //     pointRadius: 4,
            //     pointBoderColor: "transparent",
            //     borderWidth: 1,

            //     pointBackgroundColor: function (context) {
            //         var index = context.dataIndex;
            //         const topData = cbdData?.cbdt?.map((c) => c.top);
            //         switch (topData[index]) {
            //             case 5:
            //                 return "#ef4444"; // red-500
            //                 break;
            //             case 30:
            //                 return "#06b6d4";
            //                 break;

            //             default:
            //                 return "transparent";
            //                 break;
            //         }
            //     },
            // },
            {
                label: "CBDT ",
                data: cbdData?.cbdtUp.map((c) => c.amount),
                borderColor: "#22d3ee",
                backgroundColor: "#22d3ee",
                pointRadius: 1,
                // pointBoderColor: "transparent",
                // borderWidth: 1,

                // pointBackgroundColor: function (context) {
                //     var index = context.dataIndex;
                //     const topData = cbdData?.cbdtUp?.map((c) => c.top);
                //     switch (topData[index]) {
                //         case 5:
                //             return "#ef4444"; // red-500
                //             break;
                //         case 30:
                //             return "#0ea5e9"; // green-500
                //             break;

                //         default:
                //             return "transparent";
                //             break;
                //     }
                // },
            },
            {
                label: "CBDT BullRun",
                data: cbdData?.cbdtUpBullRun?.map((c) => c.amount),
                borderColor: "#6d28d9",
                backgroundColor: "#6d28d9",
                pointRadius: 1,
            },
            {
                label: "Dynamic MM + STDEV",
                data: mm?.map((m) => Number(m.dynamic_mmstdev).toFixed(0)),
                borderColor: "#FF5BAE",
                backgroundColor: "#FF5BAE",
                pointRadius: 1,
            }

        ],
    };

    const dataUser = {
        labels: cbdData?.daty,
        datasets: [

            {
                label: "CBS",
                data: cbdData?.cbs,
                borderColor: "#3b82f6",
                backgroundColor: "#3b82f6",
                pointRadius: 1,
            },
            {
                label: "CBT",
                data: cbdData?.cbt2?.map((c) => c.amount),
                backgroundColor: "#fb923c",
                borderColor: "#fb923c",
                pointRadius: 4,
                pointBoderColor: "transparent",
                borderWidth: 1,

                pointBackgroundColor: function (context) {
                    var index = context.dataIndex;
                    const colorCbtData = cbdData?.cbt2?.map((c) => c.colorcbt);
                    return colorCbtData[index]
                },
            },
            {
                label: "CBD",
                data: cbdData?.cbDdata?.map((c) => c.amount),
                borderColor: "#ef4444",
                backgroundColor: "#ef4444",
                pointRadius: 4,
                pointBoderColor: "transparent",
                borderWidth: 1,

                pointBackgroundColor: function (context) {
                    var index = context.dataIndex;
                    const topData = cbdData?.cbDdata?.map((c) => c.top);
                    switch (topData[index]) {
                        case 5:
                            return "#ef4444"; // red-500
                            break;
                        case 30:
                            return "#06b6d4";
                            break;

                        default:
                            return "transparent";
                            break;
                    }
                },
            },
            {
                label: "CBDT ",
                data: cbdData?.cbdtUp.map((c) => c.amount),
                borderColor: "#22d3ee",
                backgroundColor: "#22d3ee",
                pointRadius: 1,

            },

        ],
    };

    return (
        <>
            <div className="flex gap-x-5 justify-between ">
                {screenName === 'user' ?
                    // user
                    <div className="flex gap-x-5">
                        <CustomLegend text="[1 - 1.1[" coef="0.05" color="bg-yellow-400" />
                        <CustomLegend text="[1.1 - 1.3[" coef="0.25" color="bg-amber-500" />
                        <CustomLegend text="[1.3 - 1.6[" coef="0.5" color="bg-orange-500" />
                        <CustomLegend text=">= 1.6" coef="1.5" color="bg-red-500" />
                        {/* <CustomLegend text="> 1.9" coef="2(1)" color="bg-red-600" /> */}
                    </div>
                    :
                    // admin
                    <div className="flex gap-x-5">
                        <div className="flex gap-x-5">
                            <CustomLegend text="[1 - 1.1[" coef="0.05" color="bg-yellow-400" />
                            <CustomLegend text="[1.1 - 1.3[" coef="0.25" color="bg-amber-500" />
                            <CustomLegend text="[1.3 - 1.6[" coef="0.5" color="bg-orange-500" />
                            <CustomLegend text=">= 1.6" coef="1.5" color="bg-red-500" />
                            {/* <CustomLegend text="> 1.9" coef="2(1)" color="bg-red-600" /> */}
                        </div>
                    </div>}

                {screenName === 'user' ?

                    <div className="flex gap-x-5">
                        <CustomLegend text="[0 - 0.3[" coef="6" color="bg-emerald-300" />
                        <CustomLegend text="[0.3 - 0.5[" coef="4" color="bg-teal-500" />
                        <CustomLegend text="[0.5 - 0.7[" coef="1" color="bg-cyan-500" />
                        <CustomLegend text=">= 0.7 " coef="0.2" color="bg-blue-500" />
                        {/* <CustomLegend text="> 1" coef="0.1" color="bg-blue-600" /> */}
                    </div> :
                    <div className="flex gap-x-5">
                        <CustomLegend text="[0 - 0.3[" coef="6" color="bg-emerald-300" />
                        <CustomLegend text="[0.3 - 0.5[" coef="4" color="bg-teal-500" />
                        <CustomLegend text="[0.5 - 0.7[" coef="1" color="bg-cyan-500" />
                        <CustomLegend text=">= 0.7 " coef="0.2" color="bg-blue-500" />
                        {/* <CustomLegend text="> 1" coef="0.1" color="bg-blue-600" /> */}
                    </div>
                }


                <div>
                    <div className="flex gap-x-1 items-center">
                        <div className="w-2 h-2 bg-red-500 rounded-full" > </div>
                        <div className="text-xs"> CBD Top 5</div>
                    </div>
                    <div className="flex gap-x-1 items-center">
                        <div className="w-2 h-2 bg-sky-500 rounded-full" > </div>
                        <div className="text-xs"> CBD Top 30</div>
                    </div>
                </div>
                <div className='flex justify-end mb-2'>
                    <div>

                    </div>
                    {screenName === 'user' ?
                        <Controls.Button text={t("label.export")} small xsFont onClick={() => exportExcel(formToexcel && formToexcel)} /> :
                        <Controls.Button text={t("label.export")} small xsFont onClick={() => exportExcel(formToexcelAdmin && formToexcelAdmin)} />
                        // <Controls.Button text={t("label.export")} small xsFont onClick={() => exportExcel(formToexcelAdmin && formToexcelAdmin)} />

                    }
                </div>

            </div>

            <Line options={options} data={screenName === 'user' ? dataUser : dataAdmin} />
        </>
    );
}

export default MyLineIndex;
