import {  ViewListIcon, CalendarIcon, ClockIcon, AnnotationIcon } from "@heroicons/react/outline";
import {  InformationCircleIcon, SwitchHorizontalIcon, ArrowCircleLeftIcon, ExclamationIcon } from "@heroicons/react/solid";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { decryptCBData } from "../../utils/service";
import CommentCard from "./CommentCard";



function CardHistory(props) {
    const { card } = props
    const [newCard, setNewCard] = useState(card);
    const [showFullComment, setShowFullComment] = useState(false);
    const today = moment(new Date()).format('YYYY-MM-DD')


    const handleViewMore = () => {
        setShowFullComment(true);
    };
    const handleViewLess = () => {
        setShowFullComment(false);
    };

    return (
        <div className={`w-full md:w-[600px] border rounded-lg ${card?.status ? "bg-slate-300" : card?.status == false && ((card?.dateRef < today) || (card?.dateRDV < today)) ? "border-yellow-500" : card?.status == false && ((card?.dateRef == today) || (card?.dateRDV == today)) ? "border-blue-500" : ""}`}>
            <div className="bg-blume-300 p-6">
                <div className="text-[10px] text-right text-slate-300">{card?.id}</div>
                <div className="text-[10px] text-right  text-slate-400 ">
                    créer le : {moment(card?.createdAt).format("DD MMM YYYY à HH:mm")}
                </div>
                <div className="flex flex-col justify-center space-x-1 ">
                    <div className="flex justify-end ">
                        <div className={`${card?.u?.platform == 1 ? "bg-yellow-500" : card?.u?.platform == 2 ? "bg-purple-500" : null} w-6 h-2 rounded-lg font-semibold text-slate-600`}></div>
                    </div>
                    <div className="flex-1 font-semibold">
                        <div className="font-light text-xs text-slate-600"> Liste : <strong>{card?.list.name}</strong> </div>

                    </div>
                </div>
                <div className=" flex flex-col text-sm my-2">
                    <div className="flex items-center space-x-1 text-slate-600">
                        <div><InformationCircleIcon className="w-4 h-4 text-red-500" /></div>
                        <div className="truncate">{card?.motif?.motif}</div>
                    </div>
                    <div className="flex  space-x-1 text-red-400 text-xs ">
                        <div> <AnnotationIcon className="w-4 h-4 text-red-500" /></div>
                        <div>

                            {card?.commentMotif?.length > 70 && !showFullComment ?
                                <>
                                    {`  ${card?.commentMotif.slice(0, 70)}...`}
                                    <button className='text-indigo-300 cursor-pointer hover:text-indigo-600' onClick={handleViewMore}>View More</button>

                                </>
                                : <>
                                    {card?.commentMotif}
                                    {card?.commentMotif?.length > 70 && showFullComment ?
                                        <button className='text-indigo-300 cursor-pointer hover:text-indigo-600' onClick={handleViewLess}> View Less</button>
                                        : null}

                                </>
                            }
                        </div>
                    </div>


                    <div className="flex items-center space-x-1 ">
                        <div>
                            {card?.dateRDV ?
                                <>
                                    <div className="flex items-center space-x-1 text-slate-600" >
                                        <CalendarIcon className="w-4 h-4 text-blue-500" />
                                        <div className="truncate"> {moment(card?.dateRDV).format("DD MMM YYYY à HH:mm")}</div>
                                    </div>
                                </>
                                :
                                // DATE REF
                                <div>
                                    <div className="flex items-center space-x-1 text-slate-600" >
                                        <ClockIcon className="w-4 h-4 " />
                                        <div className="truncate">{card?.dateRef}</div>
                                    </div>
                                </div>
                            }
                        </div>



                        <div>
                            {card?.late > 0 ? <div className="flex items-center space-x-1 py-[0.5] px-1 rounded bg-red-500/30 text-red-700">
                                <ClockIcon className="w-4 h-4  " />
                                <div className="">{card?.late}j</div>
                            </div> : null}
                        </div>
                    </div>



                </div>

                {/* Paramètre & commentaire */}
                {/* <div className="">
                    <div>
                        <div className="flex items-center space-x-1 text-slate-600 mt-6">
                            <AdjustmentsIcon className="w-4 h-4" />
                            <div>Paramètres</div>
                        </div>

                        {list.rdv == true ? <div className="flex items-center space-x-1 font-bold text-xs text-green-600 ml-2"> <CalendarIcon className="w-4" />RDV</div> :
                            list.nbDay == null ? <div className="font-light text-xs text-slate-600 ml-2">  ... </div>
                                : <div className="font-light text-xs text-slate-600 ml-2"> Nb jours : <strong>{list.nbDay}</strong> </div>
                        }


                    </div>
                    <div className="flex items-center space-x-1 text-slate-600 mt-6">
                        <MenuAlt2Icon className="w-4 h-4" />
                        <div>Commentaires</div>
                    </div>
                    <div className="ml-4 text-sm text-slate-500 ">
                        {isEditing ?
                            <div className="border border-white drop-shadow-lg">
                                <textarea ref={inputRef} className="bg-white text-black w-full h-12" type="text" value={input} onChange={handleInputChange} onBlur={handleInputBlur} />
                            </div>
                            :
                            <div>
                                {card?.comment == null || (card?.comment)?.trim() == '' ? <div className=" bg-slate-50 drop-shadow-lg h-12 p-2 text-sm"
                                    onClick={handleTitleClick}>
                                    ajouter un commentaire ...
                                </div>
                                    :
                                    <div className=" bg-slate-50 drop-shadow-lg h-12 p-2 text-sm truncate"
                                        onClick={handleTitleClick}>
                                        {card.comment}
                                    </div>}

                            </div>}
                    </div>
                </div> */}

                {/* Activités */}
                {/* {auth?.id == "87e4efd1-376f-4741-8cc7-76b771b05b76" ? */}
                <div className="">

                    <div className="flex items-center space-x-1 text-slate-600 mt-6">
                        <ViewListIcon className="w-4 h-4" />
                        <div>Activités</div>

                    </div>

                    <div className=" text-xs text-slate-500 max-h-[200px] overflow-auto mt-2">{newCard?.planitCardHistories?.length > 0 ? newCard?.planitCardHistories?.map((p, i) => (
                        // <div className=" text-xs text-slate-600 bg-yellow-200 max-h-[200px] overflow-auto">{newCard?.planitCardHistories.length > 0 ? newCard?.planitCardHistories?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))?.map((p, i) => (
                        <div className="mb-4" key={i}>
                            {p?.comment != null ?
                                <CommentCard p={p} showFullComment={showFullComment} setShowFullComment={setShowFullComment} />
                                :
                                <>
                                    <div className="flex items-center space-x-2 " >
                                        <div className="flex space-x-1 ">
                                            <SwitchHorizontalIcon className="w-4 h-4 text-green-500 " />
                                            {decryptCBData(p?.byUser?.firstName)} a déplacé cette carte de <strong>{p?.fromList?.name} </strong> à <strong>{p?.toList?.name}</strong>
                                        </div>
                                        <div>
                                            {p?.dateRDV && <div className="flex space-x-1  ">
                                                <CalendarIcon className="w-4 h-4 text-blue-500 " />
                                                <div> {moment(p?.dateRDV).format("DD MMM YYYY à HH:mm")}</div>

                                            </div>}
                                        </div>
                                        <div>
                                            {p?.dateRef && <div className="flex space-x-1  ">
                                                <ClockIcon className="w-4 h-4 text-cyan-500 " />
                                                <div> {moment(p?.dateRef).format("DD MMM YYYY ")}</div>

                                            </div>}
                                        </div>
                                        <div >
                                            {p?.late > 0 ? <div className="flex items-center space-x-1 text-xs rounded bg-yellow-400 text-black px-1 "> Retard : {p?.late} j</div> : null}
                                        </div>

                                    </div>
                                    <div className="ml-2 bg-yellow-100 px-1 rounded w-fit">
                                        {p?.motifLate ?
                                            <div className="flex items-center space-x-1 ">
                                                <ExclamationIcon className="w-3 h-3 text-yellow-500" />
                                                <div>
                                                    Motif du retard : {p?.motifLate == 43 ? "Férié " : p?.motifLate == 44 ? "Bug logiciel" : p?.motifLate == 48 ? "Oublie MAJ " : p?.motifLate == 49 ? "MAJ Admin" : p?.motifLate == 45 ? p?.commentLate : null}</div>
                                            </div>
                                            : null}
                                    </div>
                                    <div className="ml-2 ">
                                        {p?.commentGoBack ? <div className="flex items-center space-x-1 bg-red-100 px-1 rounded w-fit">
                                            <ArrowCircleLeftIcon className="w-3 h-3 text-red-500" />
                                            <div>Motif du retour : {p?.commentGoBack}</div>
                                        </div>
                                            : null}
                                    </div>
                                </>
                            }

                            <div className="font-light pl-3">
                                le {moment(p.createdAt).format("DD MMM YYYY à HH:mm:ss")}

                            </div>

                        </div>
                    )) : <div className="font-light">Pas d'activité</div>}
                    </div>
                </div>
                {/* : null} */}


                {/* Close ticket */}
                {/* {(card?.dateRDV < today && card?.status == 0) || (paramsList == 12345 && auth.role.includes(process.env.REACT_APP_ADMIN)) || (paramsList == 12345 && auth.role.includes(process.env.REACT_APP_SUPADMIN)) ?
                    // {auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ?
                    // for admin & supper admin only
                    <div className="flex items-center space-x-1 mt-6 bg-red-200 hover:bg-red-500 hover:text-white hover:cursor-pointer py-1 px-2 w-fit rounded-lg"
                        onClick={handleCloseCard}>
                        <TrashIcon className="w-4 h-4" />
                        <div>Close ticket</div>
                    </div>
                    :

                    null} */}

            </div>
        </div>
    )
}

export default CardHistory
