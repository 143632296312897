import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import moment from "moment";
import "moment/locale/fr";
import { CurrencyDollarIcon, TrendingUpIcon, FilterIcon, TrashIcon, ArrowNarrowLeftIcon, CalendarIcon, CheckCircleIcon, ClockIcon } from "@heroicons/react/solid";
import Title from "../components/title/Title";
import TopBar from "../components/TopBar";
import * as sideMenus from "../utils/sideMenus";
import PieChartCustom from "../components/chart/PieChartCustom";
import DialogBox from "../components/DialogBox";
import Controls from "../components/controls/Controls";
import { addFilter, cleanFilter, filterSelector } from "../features/filterSlice";
import { useSelector, useDispatch } from "react-redux";
import SimpleLineCustom from "../components/chart/SimpleLineCustom";
import { useNavigate } from "react-router-dom";
import SimpleBarChart from "../components/chart/SimpleBarChart";
import TableauGrid from "../components/TableauGrid";
import DashboardCard from "../components/cards/DashboardCard";
import { decryptCBData, exportExcel } from "../utils/service";

moment.locale("fr");

function AdminPnlAnalytics(props) {
    const { t } = props;
    const navigate = useNavigate();
    const [users, setUsers] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenCummul, setIsOpenCummul] = useState(false);
    const [isDayClicked, setIsDayClicked] = useState(true);
    const [hasFilter, setHasFilter] = useState(false);
    const [isWeekClicked, setIsWeekClicked] = useState(false);
    const [isMonthClicked, setIsMonthClicked] = useState(false);
    const dispacth = useDispatch();
    const [analytics, setAnalytics] = useState();
    const [formattedAnalytics, setFormattedAnalytics] = useState();
    const [filteredAnalytics, setFilteredAnalytics] = useState();
    const [originalAnalytics, setOriginalAnalytics] = useState();
    const [firstId, setFirstId] = useState();
    const [lastId, setLastId] = useState();
    const [selectedBar, setSelectedBar] = useState();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getAnalyticsPnl = async () => {
            try {
                const response = await axiosPrivate.get(`/api/analytics/pnl`, {
                    signal: controller.signal,
                });
                // console.log("ANALYTICS pnl======", response.data);
                let dayFormat2 = response.data.map((o) => {
                    return {
                        date: moment(o.date, "YYYY-MM-DD").format("ll"),
                        pending: o.pending,
                        cashed: o.cashed,
                        cummulativeCashed: o.cummulativeCashed,
                        cummulativePending: o.cummulativePending,
                        cummulativeTotal: o.cummulativeTotal,
                        cashedDetails: o.cashedDetails || [],
                        pendingDetails: o.pendingDetails || [],
                    };
                });
                isMounted && setAnalytics(dayFormat2);
                isMounted && setOriginalAnalytics(response.data);
            } catch (error) {
                console.log(error);
                //navigate('/login', { state: { from: location }, replace: true })
            }
        };

        getAnalyticsPnl();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const reset = () => {
        try {
            //setAnalytics(analytics);
            setHasFilter(false);
            dispacth(cleanFilter());
        } catch (error) { }
    };

    // Day
    const showAnalyticsDay = () => {
        reset();
        let dayFormat = originalAnalytics.map((o) => {
            return {
                date: moment(o.date, "YYYY-MM-DD").format("ll"),
                pending: o.pending,
                cashed: o.cashed,
                cummulativeCashed: o.cummulativeCashed,
                cummulativePending: o.cummulativePending,
                cummulativeTotal: o.cummulativeTotal,
                cashedDetails: o.cashedDetails || [],
                pendingDetails: o.pendingDetails || [],
            };
        });
        setAnalytics(dayFormat);
        setIsDayClicked(true);
        // console.log("OOOOO", dayFormat);
    };

    //onClick day de miverina 0 ny week & month
    useEffect(() => {
        if (isDayClicked) {
            setIsWeekClicked(false);
            setIsMonthClicked(false);
        }
    }, [isDayClicked]);

    // Week
    const showAnalytics = () => {
        // formatage [] , get week number
        reset();
        let newAnalytics = originalAnalytics.map((a) => {
            return {
                ...a,
                pending: a.pending,
                weekNumber: `${moment(a.date, "YYYY-MM-DD").week()}-${moment(a.date, "YYYY-MM-DD").format("YYYY")}`,
            };
        });

        let mergeObj = newAnalytics.reduce((p, c) => {
            const { weekNumber, date, pending, cashed, cummulativeCashed, cummulativePending, cummulativeTotal, cashedDetails, pendingDetails } = c;

            if (!(weekNumber in p)) {
                p[weekNumber] = {
                    dates: [],
                    pendings: [],
                    casheds: [],
                    cummulativesC: [],
                    cummulativesP: [],
                    cummulativesT: [],
                    cashedDetailsArray: [],
                    pendingDetailsArray: [],
                };
            }

            p[weekNumber].dates.push(moment(date, "YYYY-MM-DD").format("ll"));
            p[weekNumber].pendings.push(pending);
            p[weekNumber].casheds.push(cashed);
            p[weekNumber].cummulativesC.push(cummulativeCashed);
            p[weekNumber].cummulativesP.push(cummulativePending);
            p[weekNumber].cummulativesT.push(cummulativeTotal);
            p[weekNumber].cashedDetailsArray.push(cashedDetails);
            p[weekNumber].pendingDetailsArray.push(pendingDetails);
            return p;
        }, {});

        mergeObj = Object.keys(mergeObj).map((key, i) => {
            return {
                // data: mergeObj[key].data,
                date: `${mergeObj[key].dates[0]} - ${mergeObj[key].dates[mergeObj[key].dates.length - 1]}`,
                pending: mergeObj[key].pendings.reduce((acc, curr) => Number(acc) + Number(curr), 0),
                cashed: mergeObj[key].casheds.reduce((acc, curr) => Number(acc) + Number(curr), 0),
                cummulativeCashed: mergeObj[key].cummulativesC[mergeObj[key].cummulativesC.length - 1],
                cummulativePending: mergeObj[key].cummulativesP[mergeObj[key].cummulativesP.length - 1],
                cummulativeTotal: mergeObj[key].cummulativesT[mergeObj[key].cummulativesT.length - 1],
                cashedDetails: mergeObj[key].cashedDetailsArray.flat().filter((f) => f != undefined) || [],
                pendingDetails: mergeObj[key].pendingDetailsArray.flat().filter((f) => f != undefined) || [],
                week: key,
                id: i,
            };
        });
        setIsWeekClicked(true);
        console.log("mergeObjMONth", newAnalytics);
        //setAnalytics(k == null ? mergeObj : mergeObj.filter((f) => f.id >= firstId && f.id <= lastId));

        setAnalytics(mergeObj);

        //console.log("Analytics week", mergeObj);
    };

    useEffect(() => {
        if (isWeekClicked) {
            setIsDayClicked(false);
            setIsMonthClicked(false);
        }
    }, [isWeekClicked]);

    //Month
    const showAnalyticsMonth = () => {
        // formatage [] , get week number
        reset();
        let newAnalytics = originalAnalytics.map((a) => {
            return {
                ...a,
                pending: a.pending,
                monthNumber: `${moment(a.date, "YYYY-MM-DD").format("MM-YYYY")}`,
            };
        });

        let mergeObj = newAnalytics.reduce((p, c) => {
            const { monthNumber, date, pending, cashed, cummulativeCashed, cummulativePending, cummulativeTotal, cashedDetails, pendingDetails } = c;

            if (!(monthNumber in p)) {
                p[monthNumber] = {
                    dates: [],
                    pendings: [],
                    casheds: [],
                    cummulativesC: [],
                    cummulativesP: [],
                    cummulativesT: [],
                    cashedDetailsArray: [],
                    pendingDetailsArray: [],
                };
            }

            p[monthNumber].dates.push(moment(date, "YYYY-MM-DD").format("ll"));
            p[monthNumber].pendings.push(pending);
            p[monthNumber].casheds.push(cashed);
            p[monthNumber].cummulativesC.push(cummulativeCashed);
            p[monthNumber].cummulativesP.push(cummulativePending);
            p[monthNumber].cummulativesT.push(cummulativeTotal);
            p[monthNumber].cashedDetailsArray.push(cashedDetails);
            p[monthNumber].pendingDetailsArray.push(pendingDetails);
            return p;
        }, {});

        mergeObj = Object.keys(mergeObj).map((key, i) => {
            return {
                // data: mergeObj[key].data,
                date: `${mergeObj[key].dates[0]} - ${mergeObj[key].dates[mergeObj[key].dates.length - 1]}`,
                pending: mergeObj[key].pendings.reduce((acc, curr) => Number(acc) + Number(curr), 0),
                cashed: mergeObj[key].casheds.reduce((acc, curr) => Number(acc) + Number(curr), 0),
                cummulativeCashed: mergeObj[key].cummulativesC[mergeObj[key].cummulativesC.length - 1],
                cummulativePending: mergeObj[key].cummulativesP[mergeObj[key].cummulativesP.length - 1],
                cummulativeTotal: mergeObj[key].cummulativesT[mergeObj[key].cummulativesT.length - 1],
                cashedDetails: mergeObj[key].cashedDetailsArray.flat().filter((f) => f != undefined) || [],
                pendingDetails: mergeObj[key].pendingDetailsArray.flat().filter((f) => f != undefined) || [],
                month: key,
                id: i,
            };
        });
        console.log("mergeObjMONth", mergeObj);
        setAnalytics(mergeObj);
        setIsMonthClicked(!isMonthClicked);
    };

    useEffect(() => {
        if (isMonthClicked) {
            setIsDayClicked(false);
            setIsWeekClicked(false);
        }
    }, [isMonthClicked]);

    //filters
    const filters = useSelector(filterSelector);

    const checkRange = (value) => {
        if (value[0] && value[1]) {
            dispacth(addFilter(value));
            setHasFilter(true);

            switch (true) {
                case isDayClicked:
                    //format analytic because the format of date iz weird
                    const formattedAnalytics = analytics.map(item => {
                        const formattedDate = moment(item.date, "DD MMM YYYY", "fr").format("YYYY-MM-DD");
                        return {
                            ...item,
                            date: formattedDate
                        };
                    });
                    console.log("fomate-----", formattedAnalytics);
                    setFormattedAnalytics(formattedAnalytics)

                    // filter the analytics
                    const startDate = moment(value[0], "DD-MM-YYYY").subtract(1, 'days').format("YYYY-MM-DD");
                    const endDate = moment(value[1], "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD");

                    let filteredAnalytics;
                    if (formattedAnalytics && formattedAnalytics.length > 0) {
                        filteredAnalytics = formattedAnalytics && formattedAnalytics.filter((f) => f.date > startDate && f.date < endDate);
                        console.log("filters", value[0], filters[0], "-", value[1], filters[1]);

                    }
                    console.log("formattedAnalytics", formattedAnalytics);
                    console.log("filteredAnalytics", filteredAnalytics);
                    setFilteredAnalytics(filteredAnalytics)

                    break;

                case isWeekClicked:
                    const startWeekNumber = `${moment(value[0], "DD-MM-YYYY").week()}-${moment(value[0], "DD-MM-YYYY").format("YYYY")}`;
                    const endWeekNumber = `${moment(value[1], "DD-MM-YYYY").week()}-${moment(value[1], "DD-MM-YYYY").format("YYYY")}`;
                    const firstIdWeek = analytics && analytics.filter((af) => af.week == startWeekNumber);

                    const lastIdWeek = analytics && analytics.filter((af) => af.week == endWeekNumber);

                    if (firstIdWeek) setFirstId(firstIdWeek[0].id);
                    if (lastIdWeek) setLastId(lastIdWeek[0].id);
                    console.log("MY ID", firstIdWeek, lastIdWeek);
                    break;

                case isMonthClicked:
                    const startMonthNumber = `${moment(value[0], "DD-MM-YYYY").format("MM-YYYY")}`;
                    const endMonthNumber = `${moment(value[1], "DD-MM-YYYY").format("MM-YYYY")}`;
                    const firstIdMonth = analytics && analytics.filter((af) => af.month == startMonthNumber);

                    const lastIdMonth = analytics && analytics.filter((af) => af.month == endMonthNumber);

                    if (firstIdMonth) setFirstId(firstIdMonth[0].id);
                    if (lastIdMonth) setLastId(lastIdMonth[0].id);
                    console.log("MY ID", firstIdMonth, lastIdMonth);
                    break;
                default:
                    break;
            }
        }
        if (value === null) {
            dispacth(cleanFilter());
        }
    };




    const goBack = () => navigate(-1);

    // table details
    const showDetails = (e) => {
        setIsOpen(true);
        setSelectedBar(e);
        console.log('eeeeee', e.activePayload);
    };
    console.log("seeeee", selectedBar);




    let formToExcel =
        analytics && hasFilter && firstId && lastId
            ?
            analytics.filter((f) => f.id >= firstId && f.id <= lastId).map((a, i) => {
                // const dateFormat = moment(a?.date, "DD MMM. YYYY").format("YYYY-MM-DD")
                const dateFormat = a?.date
                return {
                    date: dateFormat,
                    En_attente: a?.pending,
                    Encaisse: a?.cashed
                }
            })
            :
            analytics && hasFilter && isDayClicked
                ? filteredAnalytics.map((a, i) => {
                    // const dateFormat = moment(a?.date, "DD MMM. YYYY").format("YYYY-MM-DD")
                    const dateFormat = a?.date
                    return {
                        date: dateFormat,
                        En_attente: a?.pending,
                        Encaisse: a?.cashed
                    }
                })
                :
                analytics?.map((a, i) => {
                    // const dateFormat = moment(a?.date, "DD MMM. YYYY").format("YYYY-MM-DD")
                    const dateFormat = a?.date
                    return {
                        date: dateFormat,
                        En_attente: a?.pending,
                        Encaisse: a?.cashed
                    }
                })




    const columnDetails = [
        {
            field: "id",
            headerName: "id",
            hide: true,
        },
        {
            field: "createdAt",
            headerName: "Date",
            type: "date",
            renderCell: (cellValues) => {
                if (cellValues.row.createdAt) {
                    return moment(cellValues.row.createdAt, "YYYY-MM-DD").format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },
        },
        {
            field: "uid",
            headerName: "id client",
            // hide: true,
        },
        {
            field: "users",
            headerName: "Nom et Prénom",
            flex: 1,
            renderCell: (cellValues) => {
                return `${decryptCBData(cellValues.row.users.lastName)} ${decryptCBData(cellValues.row.users.firstName)}`;
            },
        },
        {
            field: "totalFivePercent",
            headerName: "Montant",
            renderCell: (cellValues) => {
                return JSON.parse(cellValues.row.totalFivePercent).usdt;
            },
        },
    ];
    console.log("ANA", analytics);

    return (
        <>
            <DialogBox isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="flex flex-col gap-y-4 p-4 ml-5 text-sm leading-relaxed text-gray-600 dark:text-gray-400">
                    <div className="flex gap-x-1 justify-center font-black">
                        <div className="flex gap-x-1 justify-center font-black flex-1">

                            <CalendarIcon className="h-5 w-5 text-cyan-500" />
                            <div>
                                {t("label.periodeOf")} : {selectedBar && selectedBar.activeLabel}
                            </div>
                        </div>


                    </div>
                    <div className="bg-blue-50 p-2 rounded-md">
                        <div className="flex justify-between items-center font-bold">
                            <div className="flex justify-center items-center gap-x-1">
                                <CheckCircleIcon className="h-4 w-4 text-green-500" />
                                <div>{t("label.cashed")}</div>
                            </div>
                            <div>
                                <DashboardCard bg={"bg-green-500 "} number={selectedBar && selectedBar.activePayload[0].payload.cashed} description={t("label.cashed")} unit="usdt" />
                            </div>
                        </div>
                        <div className="h-96 w-[600px] p-2">
                            <TableauGrid column={columnDetails} idRows={"id"} dataRows={selectedBar && selectedBar.activePayload[0].payload.cashedDetails} />
                        </div>
                    </div>
                    <div className="bg-orange-50 p-2 rounded-md">
                        <div className="flex justify-between items-center font-bold">
                            <div className="flex justify-center items-center gap-x-1 ">
                                <ClockIcon className="h-4 w-4 text-yellow-500" />
                                <div>{t("label.pending")}</div>
                            </div>
                            <div>
                                <DashboardCard bg={"bg-yellow-500 "} number={selectedBar && selectedBar.activePayload[0].payload.pending} description={t("label.pending")} unit="usdt" />
                            </div>
                        </div>
                        <div className="h-96 w-[600px] p-2">
                            <TableauGrid column={columnDetails} idRows={"id"} dataRows={selectedBar && selectedBar.activePayload[0].payload.pendingDetails} />
                        </div>
                    </div>
                </div>
            </DialogBox>

            <DialogBox isOpen={isOpenCummul} setIsOpen={setIsOpenCummul} title={t("label.info")}>
                <div className="p-4 ml-5 text-sm leading-relaxed text-gray-500 dark:text-gray-400">
                    <div>{t("title.ChurnDetailsEvolution")}</div>
                </div>
            </DialogBox>
            <TopBar menus={sideMenus.menusAdmin(t)} />

            <div className="flex flex-row flex-grow ">
                <Sidebar menus={sideMenus.menusAdmin(t)} />
                <div className="flex flex-row flex-1  mt-2 md:ml-52">
                    {/* <div className='flex flex-col w-full md:w-4/5 p-4'> */}
                    <div className="flex flex-col w-full px-2">
                        {/* Title section */}
                        <div className="flex-none h-auto md:p-4 space-y-3">
                            <div className="flex items-center ">
                                <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
                                    <ArrowNarrowLeftIcon className="h-5 w-5 hover:cursor-pointer" onClick={goBack} />
                                </div>
                                <div className="font-semibold text-2xl text-slate-900 dark:text-white">{t("label.PNLChurn")}</div>
                            </div>
                            <span className="text-xs text-slate-500 dark:text-slate-400 ">{moment().format("Do MMMM YYYY")}</span>

                            <hr />
                        </div>

                        <div className="flex w-auto space-x-2">
                            {/*Tableau de Bord*/}
                            <div className="flex-1 space-y-2 ">
                                {/* BarChartChurn */}
                                <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm ">
                                    <div className="flex space-x-3 py-2 justify-end mb-3">
                                        <div className="flex-1">
                                            <div className="flex space-x-2 items-center">
                                                <FilterIcon className=" flex-none w-4 h-4 text-blue-500" />
                                                <Controls.RangePicker
                                                    onCalendarChange={(value) => checkRange(value)}
                                                    //value={filters ? filters : []}
                                                    defaultStart={(filters && filters[0]) || ""}
                                                    defaultEnd={(filters && filters[1]) || ""}
                                                />
                                                {hasFilter && (
                                                    <div className="bg-red-500 p-1 rounded hover:cursor-pointer">
                                                        <TrashIcon className="h-4 w-4 text-white" onClick={reset} />
                                                    </div>
                                                )}

                                                {/* <Controls.Button color small xsFont onClick={reset} /> */}
                                            </div>
                                        </div>
                                        <Controls.Button xsFont small variant="none" color={"bg-teal-600 text-white"} onClick={() => exportExcel(formToExcel && formToExcel, "PNL.xlsx")} text={t("label.export")} />
                                        <Controls.Button xsFont small variant="none" color={isDayClicked ? "bg-sky-400 text-white" : "bg-blue-600 text-white"} onClick={showAnalyticsDay} text={t("label.day")} />
                                        <Controls.Button xsFont small variant="none" color={isWeekClicked ? "bg-sky-400 text-white" : "bg-blue-600 text-white"} onClick={showAnalytics} text={t("label.week")} />
                                        <Controls.Button xsFont small variant="none" color={isMonthClicked ? "bg-sky-400 text-white" : "bg-blue-600 text-white"} onClick={showAnalyticsMonth} text={t("label.month")} />
                                    </div>

                                    <Title.SectionTitle
                                        text={t("label.PNL")}
                                        // unit={`(${users && users.all.length})`}
                                        icon={<TrendingUpIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                    />
                                    <div className="h-fit ">
                                        <SimpleBarChart
                                            data={
                                                analytics && hasFilter && firstId && lastId
                                                    ? analytics.filter((f) => f.id >= firstId && f.id <= lastId)
                                                    : analytics && hasFilter && isDayClicked
                                                        ? filteredAnalytics
                                                        // ? formattedAnalytics.filter((f) => f.date > moment(filters[0], "DD-MM-YYYY").format("ll") && f.date < moment(filters[1], "DD-MM-YYYY").format("ll"))
                                                        : analytics
                                            }
                                            // width={300}
                                            height={300}
                                            keyLength={["date", "pending", "cashed", "cummulativeCashed", "cummulativePending", "cummulativeTotal", "week", "id"]}
                                            name={[t("label.pending"), t("label.cashed")]}
                                            // colors={["#f97316", "#84cc16", "#06b6d4", "#d946ef"]}
                                            color1={["#facc15"]}
                                            color2={["#22c55e"]}
                                            datakey1="pending"
                                            datakey2="cashed"
                                            onClick={(e) => showDetails(e)}
                                        />
                                    </div>
                                    <Title.SectionTitle
                                        text={t("label.cummulatif")}
                                        // unit={`(${users && users.all.length})`}
                                        icon={<TrendingUpIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                    />
                                    {/* <div className="h-fit " onClick={() => setIsOpenCummul(true)}> */}
                                    <div className="h-fit ">
                                        <SimpleLineCustom
                                            data={
                                                analytics && hasFilter && firstId && lastId
                                                    ? analytics.filter((f) => f.id >= firstId && f.id <= lastId)
                                                    : analytics && hasFilter && isDayClicked
                                                        ? filteredAnalytics
                                                        // ? analytics.filter((f) => f.date > moment(filters[0], "DD-MM-YYYY").format("ll") && f.date < moment(filters[1], "DD-MM-YYYY").format("ll"))
                                                        : analytics
                                            }
                                            // width={300}
                                            height={300}
                                            keyLength={["date", "pending", "cashed", "cummulativeCashed", "cummulativePending", "cummulativeTotal", "week", "id"]}
                                            name={["date", "pending", "cashed", t("label.cashed"), t("label.pending"), t("label.sum")]}
                                            colors={["#22c55e", "#facc15", "#0ea5e9"]}
                                            manyCummulative
                                        />
                                    </div>
                                </div>
                            </div>{" "}
                            {/* Objectifs */}
                            <div className="bg-slate-100 w-[250px] py-4 h-fit">
                                <h1 className="font-semibold text-center text-2xl  text-slate-900 dark:text-white">
                                    {t("label.goal")} <p className="text-xs">(2022)</p>
                                </h1>

                                <div>
                                    <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm ">
                                        <Title.SectionTitle text={"CA mensuel"} unit={"(EUR)"} icon={<CurrencyDollarIcon className="h-5 w-5 text-blue-700 text-xs" />} />
                                    </div>
                                    <div className=" w-full -mt-16 h-40">
                                        <PieChartCustom
                                            data={[
                                                { name: t("label.actually"), value: users && (users.all.length - users.inactive.length) * 27 },
                                                // { name: "Actuel", value: capitalManaged.current },
                                                { name: t("label.goal"), value: 1000 * 27 },
                                            ]}
                                            width={100}
                                            height={300}
                                            startAngle={180}
                                            endAngle={0}
                                            outerRadius={100}
                                            colors={["#3730a3", "#3b82f6"]}
                                            noLegend
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminPnlAnalytics;
