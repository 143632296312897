import React, { useState, useRef, useEffect } from "react";
import LoginForm from "./LoginForm";
import { useNavigate, Link, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import BannerInfo from "../BannerInfo";
import { authSelector, logout } from "../../features/authSlice";
import { ReactComponent as LoginFreepik } from '../../asset/login.svg';


function Login(props) {
    const { t } = props;
    const dispacth = useDispatch();
    const [attempt, setAttempt] = useState(0);
    const auth = useSelector(authSelector);
    let navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";



    // if (attempt >= 5) {
    //   return (
    //     <>
    //       <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    //         <div className="max-w-md w-full space-y-3">
    //           <div>
    //             <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-slate-100">Connectez-vous à votre compte</h2>
    //             <div>Nooooooooo</div>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   );
    // }

    // useEffect(() => {
    //     // if (auth) {
    //     const rolesArray = (Array.from(auth.role));
    //     console.log('ROLE', typeof rolesArray, rolesArray);
    //     switch (true) {
    //         case rolesArray.length == 1 && rolesArray.includes(process.env.REACT_APP_PARTENAIRE):
    //             navigate("/affiliate");
    //             break;
    //         case rolesArray.includes(process.env.REACT_APP_SUPADMIN):
    //             navigate("/hello");
    //             break;
    //         case rolesArray.includes(process.env.REACT_APP_ADMIN):
    //             navigate("/admin/dashboard");
    //             break;
    //         case rolesArray.includes(process.env.REACT_APP_CLIENT):
    //             navigate("/dashboard");
    //             break;
    //         case rolesArray.includes(process.env.REACT_APP_SUPPORT):
    //             console.log("SUPPORT===============");
    //             navigate("/support/dashboard");
    //             break;
    //         default:
    //             navigate("/login");
    //             dispacth(logout());
    //             // navigate("/login");
    //             //navigate(from, { replace: true });
    //             break;
    //     }
    //     // }
    // }, [])



    return (
        <div className="min-h-[90vh] flex items-center md:space-x-20 justify-center rounded-md w-fit mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="hidden lg:block "><LoginFreepik /></div>
            <div className="max-w-md w-full space-y-3 ">

                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-slate-100">{t(`label.loginAccount`)}</h2>
                    {/* <p className="mt-2 text-center text-sm text-gray-600">
                        Or
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500"> start your 14-day free trial </a>
                    </p> */}
                </div>
                {/* {attempt >= 1 ? ( */}
                {attempt >= 5 ? (
                    <BannerInfo
                        text={
                            <div className="flex flex-col space-y-2 text-xs">
                                <div >
                                    {t(`info.blockAccount`)}                </div>
                                <div >
                                    {t(`info.try24h`)}
                                </div>
                            </div>
                        }
                        type="danger"
                    />
                ) : null}
                <div className="bg-yellow-3000 pb-2 text-sm">{t(`info.infoLogin`)}</div>
                <LoginForm t={t} attempt={attempt} setAttempt={setAttempt} />
                <div className="text-xs flex justify-between">
                    <Link to="/reset" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {t("label.firstLogin")}
                    </Link>
                    <Link to="/reset" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {t("label.forgotPassword")}
                    </Link>
                </div>
                <div className="text-xs flex justify-end">
                </div>
            </div>
        </div>
    );
}

export default Login;
