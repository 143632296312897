import React from 'react'
import { getInitital } from '../utils/service'
function CustomAvatar({ name }) {
    return (
        <div className="flex text-sm items-center w-5 h-5 bg-indigo-500 rounded-full justify-center text-white p-1">
            <span className="text-xs">{getInitital(`${name}`)}</span>
        </div>
    )
}

export default CustomAvatar