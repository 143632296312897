import React, { useState, useEffect } from 'react'
import { SheetShad } from './client/SheetShad'
import BtnShad from './client/btnShadcn'
import { Label } from './ui/label'
import { InputShadcn } from './client/InputShadcn'
import SelectShad from './client/SelectShad'
import { Bell, BellRing, CircleAlert, Info, TriangleAlert } from 'lucide-react'
import { TypographyMuted, TypographyMutedXS, TypographyS, TypographySmall } from './Typo'
import { axiosBQ } from '../axios'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { authSelector } from '../features/authSlice'
import { useNavigate } from 'react-router-dom'

function NotifClient() {
    const { t } = useTranslation()
    const auth = useSelector(authSelector);
    const [hasNotif, setHasNotif] = useState(false)
    const [click, setClick] = useState(false)
    const [notif, setNotif] = useState([])
    const [clickedId, setClickedId] = useState(null);
    const navigate = useNavigate()
    
    const getNotifByID = async () => {
        try {
            const { data } = await axiosBQ.get(`/notification/find?func=by_uid&uid=${auth?.id}`)
            console.log("NOTIF BY ID", data);
            const dataFilter = data.filter(item => item.status == 2 || item.status == 1)
            console.log("dataFilter", dataFilter);
            if (dataFilter.length > 0) {
                setHasNotif(true)
            }
            const dataSort = data.sort((a, b) => b.id - a.id)

            setNotif(dataSort)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getNotifByID()
    }, [])

    const updateNotif = async (nid, prevStatus) => {
        // setClick(!click)
        if (clickedId == nid) {
            setClickedId(null)
        } else {
            setClickedId(null)
            setClickedId(nid);
        }
        try {
            let dataToPut = {
                id: nid,
                lastSeen: null
            }

            const changedIdx = notif.findIndex(n => n.id == nid)
            if (prevStatus == 1) {
                dataToPut.status = 2
                if (changedIdx !== -1) {
                    const updatedNotif = [
                        ...notif.slice(0, changedIdx),
                        {
                            ...notif[changedIdx],
                            // Here, update the properties you want to change
                            status: 2,
                        },
                        ...notif.slice(changedIdx + 1)
                    ];

                    // Now updatedNotif contains the updated array with the modified object
                    // You can set it to your state or use it as needed
                    console.log(updatedNotif);
                    console.log('Notification ');
                    setNotif(updatedNotif)
                } else {
                    console.log('Notification with the given ID not found');
                }
            }
            console.log("changeIndex", changedIdx);

            const finalDataToPut = {
                dataToPut: {
                    ...dataToPut
                }
            }


            const { data } = await axiosBQ.put(`/notification/update-single`, finalDataToPut)
            console.log('data', finalDataToPut, notif);
        } catch (error) {
            console.error(error);
        }
    }

    const handleOpenNotif = () => {
        navigate(`/notif`)
    }
    return (
        <>
            <div className="relative inline-block " onClick={handleOpenNotif}>
                <Bell className={`w-5 h-5 hover:cursor-pointer ${hasNotif ? "" : "  "}`} />
                {hasNotif && (
                    <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-500" />
                )}
            </div>
        </>
    )

    return (
        <>
            <SheetShad
                trigger={
                    <div className="relative inline-block ">
                        <Bell className={`w-5 h-5 hover:cursor-pointer ${hasNotif ? "" : "  "}`} />
                        {hasNotif && (
                            <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-500" />
                        )}
                    </div>
                }
                title="Notifications"
            // description="no non on"

            // closeComponent={<BtnShad onClick={() => console.log("Notif client")} text="Enregistrer"
            // />}
            >
                <div className=' h-screen scrollbar my-4'>
                    {notif.length > 0 ?

                        notif.map((d, i) => (
                            <div key={i}  >
                                <div className={`${(d.status == 1 || d.status == 2) ? "bg-blue-200 " : ""}  rounded p-1 my-1`}
                                    // <div className={`${(d.status == 1 || d.status == 2 )? "bg-blue-200 font-bold" : d.status == 3 ? "bg-slate-100" : ""}  rounded p-1 my-1`}
                                    onClick={() => updateNotif(d.id, d.status)}
                                >
                                    <div className='flex items-center gap-x-1'>
                                        {d.severity == 1 ? <TriangleAlert className='w-4 h-4 text-red-500' /> : <Info className='w-4 h-4 ' />}
                                        <TypographySmall > {d.title} </TypographySmall>

                                    </div>
                                    <TypographySmall className={`${clickedId === d.id ? "" : "truncate"} ${d.status == 2 ? "font-medium text-slate-700" : "text-slate-500 font-normal"} `}>{d.text}</TypographySmall>
                                    {d.linkTitle && (
                                        <TypographySmall className={`${clickedId === d.id ? "" : "truncate"}`}>
                                            {d.linkTitle} : <a href={d.link} target="_blank" className="hover:cursor-pointer">{d.link}</a>
                                        </TypographySmall>
                                    )}
                                    <TypographyMutedXS>{moment(d.createdAt).fromNow()}</TypographyMutedXS>
                                    {/* {d.linkTitle && <TypographyS className={`${click ? "" : "truncate"}`} >{d.linkTitle} : <a href={d.link} target="_blank" className="hover:cursor-pointer">{d.link}</a> </TypographyS>} */}
                                    <hr />
                                </div>
                            </div>
                        ))
                        :
                        <TypographySmall> {t(`label.noNotif`)}</TypographySmall>
                    }
                </div>

                {/* <BtnShad text={"Submit a voucher to client"} onClick={handleSaveVoucherClient} wFull /> */}
            </SheetShad>
        </>
    )
}

export default NotifClient
