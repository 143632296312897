import { InformationCircleIcon } from '@heroicons/react/outline';
import React from 'react'
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Controls from '../controls/Controls'
import { useFOrm } from '../useFOrm';
import BtnShad from '../client/btnShadcn';

const initialValues = {
    currency: ""
};

function Currency(props) {
    const { t, u, auth } = props
    const axiosPrivate = useAxiosPrivate();

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("currency" in fieldValues) temp.currency = fieldValues.currency ? "" : t("error.required");

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);


    const curr = [
        { id: "eur", title: t(`currency.eur`) },
        { id: "cad", title: t(`currency.cad`) },
        { id: "usd", title: t(`currency.usd`) },
        { id: "chf", title: t(`currency.chf`) },
        { id: "gbp", title: t(`currency.gbp`) },
        { id: "mur", title: t(`currency.mur`) },
        { id: "aed", title: t(`currency.aed`) },

    ]

    const handleSaveCurrency = async () => {
        try {
            await axiosPrivate.put(`/api/details/`, values);
            toast.success("Devise sauvegarder avec succèss")
        } catch (error) {
            console.log(error);
            toast.error("Une erreur est survenue")
        }
    }

    initialValues.currency = u?.currency

    return (
        <div className='flex flex-col space-y-2 mt-2 '>
            <div className="text-sm pt-4"> {t(`text.chooseCurrency`)}</div>
            <div className='flex justify-center items-end  gap-x-4 pt-4'>

                <div className=' w-1/2 '>
                    <Controls.Select
                        name="currency"
                        label={t(`label.currency`)}
                        value={values.currency}
                        onChange={handleInputChange}
                        options={curr}
                    />
                </div>
                <div>
                    {/* <Controls.Button text={t(`button.save`)} xsFont onClick={handleSaveCurrency} /> */}
                    <BtnShad
                        sendHistory={{ action: `Change Currency to ${values.currency}  `, who: `${auth?.id}` }}
                        text={t(`button.save`)}
                        onClick={handleSaveCurrency}
                    />
                </div>

            </div>

            {values.currency ?
                <div className='flex  items-center space-x-1 '>
                    <InformationCircleIcon className='w-4 text-yellow-500' />
                    <div className='text-xs '>
                        {t(`text.equivAmountsByClicking`)}
                        <strong>
                            {curr.find(currency => currency.id === values.currency)?.title || " Euro (€)"}
                        </strong>
                    </div>
                </div>
                : null
            }

        </div>
    )
}

export default Currency
