import React from 'react'
import { Textarea } from '../ui/textarea'
import { Label } from '../ui/label'
import { TypographyXSError } from '../Typo'

function TextAreaShad(props) {
    const {label, value, placeholder, name, onChange, error} = props
  return (
    <div>
          <Label htmlFor="message">{label}</Label>

        <Textarea
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        />
          {error && <TypographyXSError className="">{error}</TypographyXSError>}

    </div>
  )
}

export default TextAreaShad
