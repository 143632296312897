import React, { useState, useEffect } from "react";
import { useFOrm, Form } from "../useFOrm";
import Controls from "../controls/Controls";
import { decryptCB, specificKeyCode, encryptCB } from "../../utils/service";
import axios from "../../axios";
import { toast } from "react-toastify";
import { updateUserDetails, userDataSelector } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { authSelector, logout } from "../../features/authSlice";

const initialFvalues = {
  binance_address: "",
  memo: "",
  network: "",
  addressTrc: "",
  addressBsc: "",
  networkTrc: "",
  networkBsc: "",
};
function BinanceForm(props) {
  const { data, t, uid } = props;
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const axiosPrivate = useAxiosPrivate();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("binance_address" in fieldValues) temp.binance_address = fieldValues.binance_address ? "" : t("error.required");
    if ("memo" in fieldValues) temp.memo = fieldValues.memo ? "" : t("error.required");
    if ("network" in fieldValues) temp.network = fieldValues.network ? "" : t("error.required");
    if ("addressTrc" in fieldValues) temp.addressTrc = fieldValues.addressTrc ? "" : t("error.required");
    if ("addressBsc" in fieldValues) temp.addressBsc = fieldValues.addressBsc ? "" : t("error.required");
    if ("networkTrc" in fieldValues) temp.networkTrc = fieldValues.networkTrc ? "" : t("error.required");
    if ("networkBsc" in fieldValues) temp.networkBsc = fieldValues.networkBsc ? "" : t("error.required");
    setErrors({
      ...temp,
    });

    if (fieldValues == values) {
      //values.source_income === "autre" && values.otherSourceOfIncome === "" ? console.log("autre") : console.log("lol")
      //if not null
      return Object.values(temp).every((x) => x == "");

      /*return Object.values(temp).every(([k, v]) => k === "otherSourceOfIncome" || v === ""

            );*/
    }
  };

  initialFvalues.binance_address = data.binance_address;
  initialFvalues.memo = data.memo;
  initialFvalues.network = data.network;
  initialFvalues.addressBsc = data.addressBsc;
  initialFvalues.addressTrc = data.addressTrc;
  initialFvalues.networkBsc = data.networkBsc;
  initialFvalues.networkTrc = data.networkTrc;

  const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

  const formBinance = [
    {
      type: "text",
      name: "binance_address",
      label: t("label.binance_address"),
      placeholder: t("label.binance_address"),
    },
    {
      type: "text",
      name: "memo",
      label: t("label.memo"),
      placeholder: t("label.memo"),
    },
    {
      type: "text",
      name: "network",
      label: t("label.network"),
      placeholder: t("label.network"),
    },
  ];

  const formBinanceTrc = [
    {
      type: "text",
      name: "addressTrc",
      label: t("label.addressTrc"),
      placeholder: t("label.addressTrc"),
    },
    {
      type: "text",
      name: "networkTrc",
      label: t("label.network"),
      placeholder: t("label.network"),
    },
  ];

  const formBinanceBsc = [
    {
      type: "text",
      name: "addressBsc",
      label: t("label.addressBsc"),
      placeholder: t("label.addressBsc"),
    },
    {
      type: "text",
      name: "networkBsc",
      label: t("label.network"),
      placeholder: t("label.network"),
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleUpdateInfo = async () => {
    if (validate()) {
      try {
        values.uid = uid;
        const detailsData = await axiosPrivate.get(`/api/details/findBy/${uid}`);

        if (detailsData.data) {
          try {
            await axiosPrivate.put(`/api/details/${uid}`, values);
            dispatch(updateUserDetails(values));
            toast.success("Details updated");
          } catch (error) {
            toast.error(error.response?.data.msg);
          }
        } else {
          try {
            await axiosPrivate.post(`/api/details/create/`, values);
            dispatch(updateUserDetails(values));
            toast.success("Details Added");
          } catch (error) {
            toast.error(error.response?.data.msg);
          }
        }
      } catch (error) {
        toast.error(error.response?.data.msg);
      }
    }
  };

  const handleReset = () => {
    setValues(initialFvalues);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <div className="flex flex-col p-4 space-y-3">
          <div className="flex flex-col  space-y-6">
            <h6 className="flex-none font-semibold text-sm">Binance </h6>
            <div className="space-y-2 md:flex md:space-x-4 md:space-y-0 items-center">
              <h6>BNB</h6>
              {formBinance.map((item, i) => {
                return (
                  <>
                    <Controls.Input
                      fullWidth={true}
                      key={i}
                      // disabled={disabled}
                      readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                      label={item.label}
                      value={values[item.name] || ""}
                      onChange={handleInputChange}
                      name={item.name}
                      type={item.type}
                      onKeyDown={(event) => {
                        specificKeyCode(event);
                      }}
                      error={errors[item.name]}
                    />
                  </>
                );
              })}
            </div>
            {/* TRC_20 */}
            <div className="space-y-2 md:flex md:space-x-4 md:space-y-0 items-center">
              <h6>TRC</h6>
              {formBinanceTrc.map((item, i) => {
                return (
                  <>
                    <Controls.Input
                      fullWidth={true}
                      key={i}
                      // disabled={disabled}
                      readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                      label={item.label}
                      value={values[item.name] || ""}
                      onChange={handleInputChange}
                      name={item.name}
                      type={item.type}
                      onKeyDown={(event) => {
                        specificKeyCode(event);
                      }}
                      error={errors[item.name]}
                    />
                  </>
                );
              })}
            </div>
            {/* BSC */}
            <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
              <h6>BSC</h6>
              {formBinanceBsc.map((item, i) => {
                return (
                  <>
                    <Controls.Input
                      fullWidth={true}
                      key={i}
                      // disabled={disabled}
                      readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                      label={item.label}
                      value={values[item.name] || ""}
                      onChange={handleInputChange}
                      name={item.name}
                      type={item.type}
                      onKeyDown={(event) => {
                        specificKeyCode(event);
                      }}
                      error={errors[item.name]}
                    />
                  </>
                );
              })}
            </div>
          </div>
          <div className="flex justify-end ">
            <Controls.Button
              disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
              text={t(`button.save`)}
              xsFont
              onClick={() => handleUpdateInfo()}
            />
          </div>
        </div>
      </Form>

      <hr />

      <div className="flex flex-col space-y-4 p-4">
        <div className="text-xs font-bold bg-yellow-300 w-fit px-2 rounded-md">Adresse Binance Crypto Bulot</div>
        <div className="flex space-x-8 ">
          <div className="flex flex-col text-xs font-semibold space-y-2">
            <span>BNB : bnb136ns6lfw4zs5hg4n85vdthaad7hq5m4gtkgf23</span>
            <span>354505026</span>
          </div>
          <div className="flex flex-col text-xs font-semibold space-y-2">
            <span>TRC : TS4UzZpQu2mckofztGtBA9ATRZsi6vE7b9</span>
          </div>
          <div className="flex flex-col text-xs font-semibold space-y-2">
            <span>BSC : 0x18e4de0a2cff95653643a19ba75f397a45d4cfa9</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default BinanceForm;
