import { CheckCircleIcon, AnnotationIcon, ExclamationIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import Controls from "../controls/Controls";
import moment from "moment";
import { authSelector } from "../../features/authSlice";
//import { updateFollowUpChild } from '../../features/ticketSlice'
import { updateFollowUpChild } from "../../features/followSlice";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { updateFollowUp } from "../../features/followSlice";
import Loading from "../Loading";
import * as ticketService from "../../utils/ticketService";
function SuiviCard(props) {
  const { title, data, allTickets, index } = props;
  const auth = useSelector(authSelector);
  const axiosPrivate = useAxiosPrivate();
  const dispacth = useDispatch();
  const [errorInput, setErrorInput] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [motifLate, setMotifLate] = useState("");
  const [listComment, setListComment] = useState([
    { id: " verification de compte", title: " verification de compte" },
    { id: "Attente demande sous-compte", title: "Attente demande sous-compte" },
    { id: "Attente depot (virement bancaire)", title: "Attente depot (virement bancaire)" },
    { id: "other", title: "Autre" },
  ]);
  const [listLate, setListLate] = useState([]);
  const [errorMotifLate, setErrorMotifLate] = useState("");
  const [otherMotifLate, setOtherMotifLate] = useState();
  const [errorOtherMotifLate, setErrorOtherMotifLate] = useState();
  const todayDate = moment().format("YYYY-MM-DD");

  //Difference in number of days
  const calculateDaysLate = (d1) => {
    var given = moment(d1, "YYYY-MM-DD");
    var current = moment().startOf("day");
    return Math.abs(moment.duration(given.diff(current)).asDays());
  };

  //update parent ticket step accoridng to child  update
  const getStep = (title) => {
    switch (true) {
      case title === "JJ":
        return 1;
      case title === "J3":
        return 2;
      case title === "J7":
        return 3;
      case title === "J10":
        return 4;
      case title === "J25":
        return 5;
      default:
        break;
    }
  };

  //update current card
  //id 45 = autre
  const updateCard = async (data, dataUpdate) => {
    console.log("hehehe");
    if (dataUpdate.late > 0 && motifLate) {
      dataUpdate.motifLate = motifLate;
      if (motifLate == 45 && otherMotifLate) {
        dataUpdate.commentLate = otherMotifLate;
        await axiosPrivate.put(`/api/ticket-follow-up/${data.id}`, dataUpdate);
        dispacth(updateFollowUpChild(dataUpdate));
        return "done";
      } else if (motifLate != 45) {
        await axiosPrivate.put(`/api/ticket-follow-up/${data.id}`, dataUpdate);
        dispacth(updateFollowUpChild(dataUpdate));
        return "done";
      } else {
        setErrorOtherMotifLate("*");
        return "error";
      }
    } else if (dataUpdate.late == 0) {
      await axiosPrivate.put(`/api/ticket-follow-up/${data.id}`, dataUpdate);
      dispacth(updateFollowUpChild(dataUpdate));
      return "done";
    } else {
      setErrorMotifLate("*");
      return "error";
    }
  };

  //update nex card data
  const updateNextCard = async (index, dataUpdate) => {
    for (let i = index + 1; i <= 4; i++) {
      const nextCard = allTickets[i];
      const nextDate = moment(nextCard.enableButtonDate, "YYYY-MM-DD").add(dataUpdate.late, "days");
      await axiosPrivate.put(`/api/ticket-follow-up/${nextCard.id}`, { enableButtonDate: moment(nextDate).format("YYYY-MM-DD"), by: auth.name });
      const dataUpdateNextCard = {
        tid: data.tid,
        id: nextCard.id,
        enableButtonDate: moment(nextDate).format("YYYY-MM-DD"),
        by: auth.name,
      };
      dispacth(updateFollowUpChild(dataUpdateNextCard));
    }
  };

  //parent card
  const updateMainCard = async (data) => {
    dispacth(updateFollowUp(data));
  };

  const handleSubmit = async () => {
    switch (true) {
      case data.title === "JJ" || data.title === "J7":
        //prepare json to update
        const dataUpdateEmail = {
          tid: data.tid,
          id: data.id,
          onClickDate: todayDate,
          comment: commentValue ? commentValue : "email sent",
          enableButtonDate: data.enableButtonDate,
          late: calculateDaysLate(data.enableButtonDate),
          by: auth.name,
          status: true,
        };

        const respUdpate = await updateCard(data, dataUpdateEmail);

        if (respUdpate === "done") {
          const step1 = getStep(data.title);
          await axiosPrivate.put(`/api/ticket/${data.tid}`, { step: step1, by: auth.name });
          await updateMainCard({ tid: data.tid, step: step1, by: auth.name });

          //update if late
          if (dataUpdateEmail.late > 0) {
            await updateNextCard(index, dataUpdateEmail);
          }
        }

        break;
      case data.title === "J3" || data.title === "J10":
        if (commentValue) {
          const dataUpdateCall = {
            tid: data.tid,
            id: data.id,
            onClickDate: todayDate,
            comment: commentValue,
            enableButtonDate: data.enableButtonDate,
            late: calculateDaysLate(data.enableButtonDate),
            by: auth.name,
            status: true,
          };

          const respUdpate = await updateCard(data, dataUpdateCall);
          if (respUdpate === "done") {
            const step2 = getStep(data.title);
            await axiosPrivate.put(`/api/ticket/${data.tid}`, { step: step2, by: auth.name });
            await updateMainCard({ tid: data.tid, step: step2, by: auth.name });

            //update if late
            if (dataUpdateCall.late > 0) {
              await updateNextCard(index, dataUpdateCall);
            }
          }
        } else {
          setErrorInput("*");
        }

        break;
      default:
        if (commentValue) {
          const dataUpdateEmailORCall = {
            tid: data.tid,
            id: data.id,
            onClickDate: todayDate,
            comment: commentValue,
            enableButtonDate: data.enableButtonDate,
            late: calculateDaysLate(data.enableButtonDate),
            by: auth.name,
            status: true,
          };
          const step3 = getStep(data.title);
          await axiosPrivate.put(`/api/ticket/${data.tid}`, { step: step3, by: auth.name });

          //update the main card attached with this child card
          await updateMainCard({ tid: data.tid, step: step3, by: auth.name });
          const respUdpate = await updateCard(data, dataUpdateEmailORCall);
        } else {
          setErrorInput("*");
        }
        break;
    }
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getListComment = async () => {
      try {
        const { data } = await axiosPrivate.get(`/api/motif`, {
          signal: controller.signal,
        });
        isMounted && setListComment(data);
      } catch (error) {
        console.log(error);
      }
    };

    const getListLate = async () => {
      try {
        const response = await axiosPrivate.get(`/api/motif/category/Retard suivi`, {
          signal: controller.signal,
        });
        const formatData = response.data.map((d) => ({ id: d.id, title: d.motif }));
        isMounted && setListLate(formatData);
        console.log("Late", formatData);
      } catch (error) {
        console.log(error);
      }
    };
    //getListComment();
    getListLate();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  return (
    // <div className='bg-slate-200 shadow-lg  p-3 rounded-md w-[144px]  h-[160px] relative'>

    <div
      className={` shadow-lg  p-3 rounded-md w-[144px]  h-fit min-h-[210px] relative
    ${data.enableButtonDate < data.onClickDate ? "bg-yellow-200" : data.enableButtonDate == data.onClickDate ? "bg-green-200" : "bg-slate-200"}

    `}
    >
      {!data.onClickDate && data.enableButtonDate < moment().format("YYYY-MM-DD") ? <ExclamationIcon className="h-5 w-5 absolute right-1 text-red-500" /> : null}

      {data.onClickDate ? data.late > 0 ? <ExclamationIcon className="h-5 w-5 absolute right-1 text-yellow-500" /> : <CheckCircleIcon className="h-5 w-5 absolute right-1 text-green-500" /> : null}

      {/* {!data.onClickDate ?
        title === 'JJ' || title === 'J7' ? null : (
          <AnnotationIcon className='h-5 w-5 absolute left-1 text-slate-400 hover:cursor-pointer' onClick={() => setShowInput(!showInput)} />
        ) : null
      } */}

      <div className="flex flex-col">
        <div className="flex-none text-center font-bold text-2xl  ">
          <span>{title}</span>
        </div>

        <div className="h-fit min-h-full ">
          <div className="text-sm font-semibold text-center">{moment(data.enableButtonDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</div>

          <div className="text-xs font-light text-center mt-2 flex flex-col gap-y-2">
            {// title === "JJ" || title === "J7" ? (
            //   data.comment
            // ) :
            data.comment ? (
              data.comment
            ) : (
              <>
                <Controls.Input
                  label="Comment"
                  type="text"
                  value={commentValue}
                  onChange={(e) => setCommentValue(e.target.value)}
                  error={errorInput}
                  readOnly={!data.onClickDate && data.enableButtonDate > moment().format("YYYY-MM-DD") ? true : false}
                />
                {/* <Controls.Select name="type_id" label={"Comment"} value={commentValue || ""} onChange={(e) => setCommentValue(e.target.value)} options={listComment} error={errorInput} /> */}
              </>
            )}

            {!data.onClickDate && data.enableButtonDate < moment().format("YYYY-MM-DD") ? (
              <>
                {/* <Controls.Input
                  label="Motif Late"
                  type="text"
                  value={motifLate}
                  onChange={(e) => setMotifLate(e.target.value)}
                  error={errorMotifLate}
                  readOnly={!data.onClickDate && data.enableButtonDate > moment().format("YYYY-MM-DD") ? true : false}
                /> */}
                <Controls.Select name="late" label={"Late"} value={motifLate || ""} onChange={(e) => setMotifLate(e.target.value)} options={listLate && listLate} error={errorMotifLate} />
                {motifLate && motifLate == 45 ? (
                  <Controls.Input label="Autre" type="text" value={otherMotifLate} onChange={(e) => setOtherMotifLate(e.target.value)} error={errorOtherMotifLate} />
                ) : null}
              </>
            ) : null}
          </div>
        </div>

        <div className="flex-none text-center mt-2">
          {index === 0 && !data.onClickDate && data.enableButtonDate <= moment().format("YYYY-MM-DD") ? (
            <Controls.Button
              xsFont
              small
              color={title === "JJ" || title === "J7" ? null : title === "J25" ? "bg-red-500 text-white" : "bg-green-500 text-white"}
              text={title === "JJ" || title === "J7" ? "Send email" : title === "J25" ? "Last relance" : "Call"}
              onClick={handleSubmit}
            />
          ) : index !== 0 && !data.onClickDate && data.enableButtonDate <= moment().format("YYYY-MM-DD") && allTickets[index - 1].status ? (
            <Controls.Button
              xsFont
              small
              color={title === "JJ" || title === "J7" ? null : title === "J25" ? "bg-red-500 text-white" : "bg-green-500 text-white"}
              text={title === "JJ" || title === "J7" ? "Send email" : title === "J25" ? "Last relance" : "Call"}
              onClick={handleSubmit}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SuiviCard;
