import { CheckIcon } from '@heroicons/react/solid'
import React from 'react'
import { toast } from 'react-toastify'
import Controls from '../components/controls/Controls'
import Header from '../components/Header'

function Tarif(props) {
    const { t, title, price, contenu, url, urlTitle } = props
    // const price = [
    //     {
    //         id: 1,
    //         title: "Basique",
    //         price: "0€/mois",
    //         contenu: [
    //             { id: 1, label: "Carte de visite virtuelle" },
    //             { id: 2, label: "3 Liens" },
    //             { id: 3, label: "Proposer du travail" }
    //         ]
    //     },
    //     {
    //         id: 2,
    //         title: "Pro",
    //         price: "9.99€/mois",
    //         contenu: [
    //             { id: 1, label: "Carte de visite virtuelle" },
    //             { id: 2, label: "Liens illimité" },
    //             { id: 3, label: "Proposer du travail" },
    //             { id: 4, label: "Parution dans Proci Prola" },
    //             { id: 5, label: "Badge professionnel" },

    //         ]
    //     }
    // ]

    const handleValidate = (title) => {
        toast.success(` OK OK ${title}`)
        console.log("OKokO");
    }

    return (


        <div className='flex flex-col gap-2 w-[300px] border border-blue-gray-100 hover:bg-blue-gray-50/30 hover:cursor-pointer rounded-xl p-2 text-xs text-gray-700'>
            <div className='flex flex-col items-center '>
                <h2 className='font-bold ' >
                    {title}
                </h2>
                <div className='text-lg text-gray-800 font-bold  '>
                    {price}
                </div>
            </div>
            <div className='flex justify-center '>
                {/* <Controls.Button text={t("label.begin")} small onClick={() => handleValidate(title)} /> */}
                {url == "noUrl" ? null :

                    <a href={url} target="_blank">
                        <div className="bg-black text-white rounded-lg text-center text-base py-2 w-fit min-w-[18rem] hover:bg-slate-700">
                            {urlTitle}
                        </div>
                    </a>}

            </div>
            <div>
                {contenu.map((c, i) => (
                    <div className='flex items-center gap-2' key={i}>
                        <div>
                            <CheckIcon className='w-5 h-5 text-green-500 ' />
                        </div>
                        <div> {c?.label}</div>
                    </div>
                ))}
            </div>
        </div>

    )
}

export default Tarif
