import React, { useState, useEffect } from "react";
import { useFOrm, Form } from "../components/useFOrm";
import Controls from "../components/controls/Controls";
import { decryptCB, specificKeyCode, encryptCB } from "../utils/service";
import axios, { axiosBQ } from "../axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { updateUserDetails, userDataSelector } from "../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ArrowNarrowLeftIcon, XCircleIcon } from "@heroicons/react/solid";
import { authSelector } from "../features/authSlice";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/outline";
import Loading from "../components/Loading";

const initialFvalues = {
    apiKeyRo: "",
    secureKeyRo: "",
    apiKeyTransfer: "",
    secureKeyTransfer: "",
    apiKeyTrading: "",
    secureKeyTrading: "",
    source_income: "",
    otherSourceOfIncome: "",
    // isSousCompte: false,
};

function UserApiScreen(props) {
    const { t, uid } = props;
    const [data, setData] = useState();
    const dispatch = useDispatch();
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isClicked, setIsClicked] = useState("");
    const auth = useSelector(authSelector);
    const goBack = () => navigate(-1);
    const navigate = useNavigate();
    const [play, setPlay] = useState("");
    const [testApi, setTestApi] = useState({});
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const checkApiUpdate = async () => {
            try {
                setIsPageLoading(true);
                const { data } = await axiosPrivate.get(`/api/manage/check`, { signal: controller.signal });
                if (isMounted) {
                    switch (true) {
                        case data.updateApi == "ON":
                            setPlay("ON");
                            setTestApi(JSON.parse(data.testApi));
                            setIsPageLoading(false);
                            break;
                        default:
                            break;
                    }
                }
                setIsPageLoading(false);
                // console.log("Play ++++", data);
            } catch (error) {
                setIsPageLoading(false);
                console.log(error, "Error Api Update");
            }
        };
        checkApiUpdate();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const validate = (fieldValues = values) => {
        const temp = { ...errors };
        // switch (true) {
        //   case isClicked == "ro":
        //     if ("apiKeyRo" in fieldValues) temp.apiKeyRo = fieldValues.apiKeyRo ? "" : t("error.required");
        //     if ("secureKeyRo" in fieldValues) temp.secureKeyRo = fieldValues.secureKeyRo ? "" : t("error.required");
        //     break;

        //   case isClicked == "transfer":
        //     if ("apiKeyTransfer" in fieldValues) temp.apiKeyTransfer = fieldValues.apiKeyTransfer ? "" : t("error.required");
        //     if ("secureKeyTransfer" in fieldValues) temp.secureKeyTransfer = fieldValues.secureKeyTransfer ? "" : t("error.required");
        //     break;

        //   case isClicked == "trading":
        //     if ("apiKeyTrading" in fieldValues) temp.apiKeyTrading = fieldValues.apiKeyTrading ? "" : t("error.required");
        //     if ("secureKeyTrading" in fieldValues) temp.secureKeyTrading = fieldValues.secureKeyTrading ? "" : t("error.required");
        //     break;

        //   default:
        //     break;
        // }
        // console.log("KKKKK", isClicked);

        setErrors({
            ...temp,
        });

        if (fieldValues == values) {
            return Object.values(temp).every((x) => x == "");
        }
    };

    const decryptDataAPI = ["apiKeyRo", "apiKeyTransfer", "apiKeyTrading"];
    const decryptData = ["secureKeyRo", "secureKeyTransfer", "secureKeyTrading"];

    initialFvalues.apiKeyRo = decryptCB(data?.apiKeyRo);
    initialFvalues.apiKeyTransfer = decryptCB(data?.apiKeyTransfer);
    initialFvalues.apiKeyTrading = decryptCB(data?.apiKeyTrading);
    // initialFvalues.isSousCompte = data.isSousCompte;
    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    const formKeysRo = [
        {
            type: "text",
            name: "apiKeyRo",
            label: t("label.apiKeyRo"),
            placeholder: t("label.apiKeyRo"),
        },
        {
            type: "text",
            name: "secureKeyRo",
            label: t("label.secureKeyRo"),
            placeholder: t("label.secureKeyRo"),
        },
    ];

    const formKeysTransfer = [
        {
            type: "text",
            name: "apiKeyTransfer",
            label: t("label.apiKeyTransfer"),
            placeholder: t("label.apiKeyTransfer"),
        },
        {
            type: "text",
            name: "secureKeyTransfer",
            label: t("label.secureKeyTransfer"),
            placeholder: t("label.secureKeyTransfer"),
        },
    ];

    const formKeysTrading = [
        {
            type: "text",
            name: "apiKeyTrading",
            label: t("label.apiKeyTrading"),
            placeholder: t("label.apiKeyTrading"),
        },
        {
            type: "text",
            name: "secureKeyTrading",
            label: t("label.secureKeyTrading"),
            placeholder: t("label.secureKeyTrading"),
        },
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const checkifUserHasDetails = async (uid, finalObjtToUpdate) => {
        try {
            // checkifExist in database
            const detailsData = await axiosPrivate.get(`/api/details/findBy/${uid}`);

            if (detailsData.data) {
                try {
                    await axiosPrivate.put(`/api/details/${uid}`, finalObjtToUpdate);
                    dispatch(updateUserDetails(finalObjtToUpdate));
                    toast.success("Details updated");
                } catch (error) {
                    toast.error(error.response?.data.msg);
                }
            } else {
                try {
                    await axiosPrivate.post(`/api/details/create/`, finalObjtToUpdate);
                    dispatch(updateUserDetails(finalObjtToUpdate));
                    toast.success("Details Added");
                } catch (error) {
                    toast.error(error.response?.data.msg);
                }
            }
            setIsLoading(false);
        } catch (error) {
            toast.error(error.response?.data.msg);
        }

        // VAOVAO
    };

    const sendHistoryToBigQuery = async (key) => {
        let dataToPost = {
            button: "save",
            location: window.location.pathname,
            by: auth?.id,
            role: Number(auth?.role[0]),
            action: `API ${key} setting`,
            who: auth?.id,
        }
        const response = await axiosBQ.post(`${process.env.REACT_APP_BASE_URL_BQ}/action-logs/insert`, {
        // const response = await axios.post('https://bg.flow-corporation.space/history/action-logs/insert', {
            dataToPost,
        });
        console.log("Send History", dataToPost);

    }

    const handleSaveRo = async () => {
        setIsClicked("ro");
        if (validate()) {
            setIsLoading(true);

            const dataToUpdate = {
                // uid: auth.id,
                apiKeyRo: encryptCB(values.apiKeyRo),
                secureKeyRo: encryptCB(values.secureKeyRo),
            };
            // checkifUserHasDetails(auth.id, dataToUpdate);

            try {
                await axiosPrivate.put(`/api/manage/consultation`, dataToUpdate);
                toast.success("Modification  RO réussi");
                sendHistoryToBigQuery("RO")
                setIsLoading(false);
                // setPlay();
            } catch (error) {
                toast.error(error.response?.data.msg);
            }
        }
    };

    const handleSaveTransfer = async () => {
        setIsClicked("transfer");
        if (validate()) {
            setIsLoading(true);

            const dataToUpdate = {
                // uid: auth.id,
                apiKeyTransfer: encryptCB(values.apiKeyTransfer),
                secureKeyTransfer: encryptCB(values.secureKeyTransfer),
            };
            // checkifUserHasDetails(auth.id, dataToUpdate);
            try {
                await axiosPrivate.put(`/api/manage/transfer`, dataToUpdate);
                toast.success("Modification W réussi");
                sendHistoryToBigQuery("W")
                setIsLoading(false);
                // setPlay();
            } catch (error) {
                toast.error(error.response?.data.msg);
            }
        }
    };

    const handleSaveTrading = async () => {
        setIsClicked("trading");
        if (validate()) {
            setIsLoading(true);

            const dataToUpdate = {
                // uid: auth.id,
                apiKeyTrading: encryptCB(values.apiKeyTrading),
                secureKeyTrading: encryptCB(values.secureKeyTrading),
            };
            // checkifUserHasDetails(auth.id, dataToUpdate);
            try {
                await axiosPrivate.put(`/api/manage/trading`, dataToUpdate);
                toast.success("Modification T réussi");
                sendHistoryToBigQuery("T")
                setIsLoading(false);
                // setPlay();
            } catch (error) {
                toast.error(error.response?.data.msg);
            }
        }
    };

    const handleUpdateInfo = async () => {
        //if (validate()) {
        //console.log("0", values)
        setIsLoading(true);
        //delete empty data form input
        //let obj = Object.fromEntries(Object.entries(values).filter(([_, v]) => v != null && v != ""));
        let obj = Object.entries(values)
            .filter(([key, value]) => value != null && value !== "")
            .reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});
        //console.log("1", obj)
        const dataToUpdate = {
            ...obj,
            uid: uid,
            apiKeyRo: obj.hasOwnProperty("apiKeyRo") ? encryptCB(obj.apiKeyRo) : "",
            secureKeyRo: obj.hasOwnProperty("secureKeyRo") ? encryptCB(obj.secureKeyRo) : "",
            apiKeyTransfer: obj.hasOwnProperty("apiKeyTransfer") ? encryptCB(obj.apiKeyTransfer) : "",
            secureKeyTransfer: obj.hasOwnProperty("secureKeyTransfer") ? encryptCB(obj.secureKeyTransfer) : "",
            apiKeyTrading: obj.hasOwnProperty("apiKeyTrading") ? encryptCB(obj.apiKeyTrading) : "",
            secureKeyTrading: obj.hasOwnProperty("secureKeyTrading") ? encryptCB(obj.secureKeyTrading) : "",
        };

        //values.apiKeyRo = encryptCB(values.apiKeyRo)
        /* values.secureKeyRo = encryptCB(values.secureKeyRo)
             values.apiKeyTransfer = encryptCB(values.apiKeyTransfer)
             values.apiKeyTrading = encryptCB(values.apiKeyTrading)
             values.secureKeyTrading = encryptCB(values.secureKeyTrading)*/

        //console.log("2", dataToUpdate)
        //let finalObjtToUpdate = Object.fromEntries(Object.entries(dataToUpdate).filter(([_, v]) => v != null && v != ""));
        let finalObjtToUpdate = Object.entries(dataToUpdate)
            .filter(([key, value]) => value != null && value !== "")
            .reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});

        // console.log("3", finalObjtToUpdate);
        /*try {
          // checkifExist in database
          const detailsData = await axiosPrivate.get(`/api/details/findBy/${uid}`);

          if (detailsData.data) {
            try {
              await axiosPrivate.put(`/api/details/${uid}`, finalObjtToUpdate);
              dispatch(updateUserDetails(finalObjtToUpdate));
              toast.success("Details updated");
            } catch (error) {
              toast.error(error.response?.data.msg);
            }
          } else {
            try {
              await axiosPrivate.post(`/api/details/create/`, finalObjtToUpdate);
              dispatch(updateUserDetails(finalObjtToUpdate));
              toast.success("Details Added");
            } catch (error) {
              toast.error(error.response?.data.msg);
            }
          }
          setIsLoading(false);
        } catch (error) {
          toast.error(error.response?.data.msg);
        }*/
        setIsLoading(false);
        //}
    };

    const handleReset = () => {
        setValues(initialFvalues);
    };
    const [testApiRo, setTestApiRo] = useState(data && data.testApi.ro);
    const [testApiW, setTestApiW] = useState(data && data.testApi.w);
    const [testApiT, setTestApiT] = useState(data && data.testApi.t);
    const [errorApiRo, setErrorApiRo] = useState("");
    const [errorApiW, setErrorApiW] = useState("");
    const [errorApiT, setErrorApiT] = useState("");

    // if (play && (testApi?.ro || testApi?.w || testApi?.t)) {
    //   return <div className={`flex flex-col  space-y-3 px-4 text-slate-600 rounded-lg drop-shadow-md pt-4`}> Votre APi semble correcte </div>;
    // }

    if (isPageLoading) {
        return <Loading />;
    }

    return (
        <div>
            {" "}
            <Form onSubmit={handleSubmit} noValidate>
                <div className=" mx-2 md:mx-auto bg-slate-50  mt-4 pt-2 px-4 md:w-[70%] py-4">
                    <div className="flex items-center">
                        <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
                            <ArrowNarrowLeftIcon className="h-4 w-4 hover:cursor-pointer" onClick={goBack} />
                        </div>
                        <div className="text-sm font-medium ">{t(`label.apiSetting`)}</div>
                    </div>

                    <div className="flex flex-col p-2 space-y-6">
                        {play && testApi?.ro && testApi?.w && testApi?.t ? (
                            <div className="flex flex-col  space-y-6 px-4 text-slate-600 rounded-lg drop-shadow-md pt-4 mx-auto items-center">
                                <CheckCircleIcon className="w-40 h-40 text-green-300" />
                                <div className="font-semibold text-slate-500">Votre API semble correcte, veuillez contacter nos supports pour configurer votre API</div>
                            </div>
                        ) : play && (testApi?.ro == false || testApi?.w == false || testApi?.t == false) ? (
                            <>
                                {/* PLAY RO */}
                                <div className={`${testApi?.ro ? "hidden" : "flex flex-col "}   space-y-3 px-4 rounded-lg drop-shadow-md pt-4`}>
                                    <div className="flex gap-x-2 ">
                                        <h6 className="flex-none text-lg text-slate-600 font-light">
                                            <strong>API RO </strong> ({t(`label.readOnly`)})
                                        </h6>
                                        {/* {testApiRo ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />} */}
                                    </div>

                                    <div className="space-y-2 md:flex md:space-x-4 md:space-y-0 ">
                                        {formKeysRo.map((item, i) => {
                                            return (
                                                <>
                                                    <Controls.Input
                                                        fullWidth={true}
                                                        key={i}
                                                        // disabled={disabled}
                                                        readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                                        label={item.label}
                                                        value={values[item.name] || ""}
                                                        onChange={handleInputChange}
                                                        name={item.name}
                                                        type={item.type}
                                                        onKeyDown={(event) => {
                                                            specificKeyCode(event);
                                                        }}
                                                        error={errors[item.name]}
                                                    />
                                                </>
                                            );
                                        })}

                                        <Controls.Button
                                            // enable after ok du support
                                            disabled={values.apiKeyRo && values.secureKeyRo ? false : true}
                                            text={t(`button.save`)}
                                            xsFont
                                            onClick={() => handleSaveRo("ro")}
                                            isLoading={isLoading}
                                        />
                                    </div>

                                    <div className="text-xs text-red-500">{errorApiRo && errorApiRo}</div>
                                    <div className="h-[0.5px] w-full bg-slate-200 mx-auto" />
                                </div>

                                {/* PLAY TRANSFER */}
                                <div className={`${testApi?.w ? "hidden" : "flex flex-col "}   space-y-3 px-4 rounded-lg drop-shadow-md pt-4`}>
                                    <div className="flex gap-x-2 ">
                                        <h6 className="flex-none text-lg text-slate-600 font-light">
                                            <strong>API W </strong> ({t(`label.transfer`)})
                                        </h6>{" "}
                                        {/* {testApiW ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />} */}
                                    </div>

                                    <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
                                        {formKeysTransfer.map((item, i) => {
                                            return (
                                                <>
                                                    <Controls.Input
                                                        fullWidth={true}
                                                        key={i}
                                                        // disabled={disabled}
                                                        readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                                        label={item.label}
                                                        value={values[item.name] || ""}
                                                        onChange={handleInputChange}
                                                        name={item.name}
                                                        type={item.type}
                                                        onKeyDown={(event) => {
                                                            specificKeyCode(event);
                                                        }}
                                                        error={errors[item.name]}
                                                    />
                                                </>
                                            );
                                        })}
                                        <Controls.Button
                                            // enable after ok du support
                                            disabled={values.apiKeyTransfer && values.secureKeyTransfer ? false : true}
                                            // disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                            text={t(`button.save`)}
                                            xsFont
                                            onClick={handleSaveTransfer}
                                            isLoading={isLoading}
                                        />
                                    </div>
                                    <div className="flex gap-x-3">
                                        <div className="text-xs text-red-400">{errorApiW && errorApiW}</div>
                                    </div>
                                    <div className="h-[0.5px] w-full bg-slate-200 mx-auto" />
                                </div>

                                {/* PLAY TRADING */}
                                <div className={`${testApi?.t ? "hidden" : "flex flex-col "}   space-y-3 px-4 rounded-lg drop-shadow-md pt-4`}>
                                    <div className="flex gap-x-2 ">
                                        <h6 className="flex-none text-lg text-slate-600 font-light">
                                            <strong>API T </strong> ({t(`label.trading`)})
                                        </h6>{" "}
                                        {/* {testApiT ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />} */}
                                    </div>

                                    <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
                                        {formKeysTrading.map((item, i) => {
                                            return (
                                                <>
                                                    <Controls.Input
                                                        fullWidth={true}
                                                        key={i}
                                                        // disabled={disabled}
                                                        readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                                        label={item.label}
                                                        value={values[item.name] || ""}
                                                        onChange={handleInputChange}
                                                        name={item.name}
                                                        type={item.type}
                                                        onKeyDown={(event) => {
                                                            specificKeyCode(event);
                                                        }}
                                                        error={errors[item.name]}
                                                    />
                                                </>
                                            );
                                        })}
                                        <Controls.Button
                                            // enable after ok du support
                                            disabled={values.apiKeyTrading && values.secureKeyTrading ? false : true}
                                            text={t(`button.save`)}
                                            xsFont
                                            onClick={handleSaveTrading}
                                            isLoading={isLoading}
                                        />
                                    </div>
                                    <div className="flex gap-x-3">
                                        <div className="text-xs text-red-500"> {errorApiT && errorApiT}</div>
                                    </div>
                                </div>
                                <div className="text-sm text-green-600"> *{t(`info.userApiInput`)} </div>
                            </>
                        ) : (
                            <div className={`flex flex-col  space-y-3 px-4 text-slate-600 rounded-lg drop-shadow-md pt-4`}>
                                Pour configurer les paramètres de votre API, veuillez prendre un rendez-vous avec l'un de nos supports afin qu'ils puissent activer la configuration de votre API .
                            </div>
                        )}
                    </div>
                </div>
            </Form>
        </div>
    );
}

export default UserApiScreen;
