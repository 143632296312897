import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from "../components/ui/button"
import { ArrowUpDown, MoreHorizontal } from 'lucide-react';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import BtnClientOk from '../components/BtnClientOk';
import * as service from "../utils/service";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { DataTable } from '../components/client/data-table';
import BtnShad from '../components/client/btnShadcn';
import { Badge } from '../components/ui/badge';
import { DialogDemo } from '../components/client/dialogShad';
import DetailsPnlList from '../components/DetailsPnlList';
import Loading from '../components/Loading';
import { TypographySmall } from '../components/Typo';
import SelectShad from '../components/client/SelectShad';
import { useFOrm } from '../components/useFOrm';



function PnlTable(props) {
    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState(1)
    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const axiosPrivate = useAxiosPrivate()
    const [status, setStatus] = useState("10")
    const [nextLoading, setNextLoading] = useState(false)
    const [prevLoading, setPrevLoading] = useState(false)
    const [category, setCategory] = useState("no")
    const initialValues = {
        categoryPnl: "",
    }
    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        // if ("code" in fieldValues) temp.code = fieldValues.code ? "" : t("error.required");


        setErrors({
            ...temp,
        });
        console.log("Temp", temp);

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);



    let baseQuery = `/api/admin/extract?page=${currentPage}&pageSize=200`

    const getUsers = async () => {
        try {
            if (status != null) {
                baseQuery = `${baseQuery}&status=${status}`
            }
            if (category != null) {
                baseQuery = `${baseQuery}&category=${category}`
                console.log("Niova ny category");
            }
            const { data } = await axiosPrivate.get(`${baseQuery}`);

            setUsers(data.pnlRow);
            console.log("PNL", data.pnlRow);
        } catch (error) {
            console.log(error);

        }
    };

    useEffect(() => {
        // let baseQuery = `/api/admin/search?pnl=1&page=${currentPage}`

        getUsers();

    }, [currentPage, category]);


    function calculatePnl(original, statusKeys) {
        let pnl = 0;

        for (const statusKey of statusKeys) {
            // console.log("statusKey", statusKey);
            pnl += original?.sumByStatus?.[statusKey] || 0;
            // console.log("PNL Calc", pnl, original?.sumByStatus?.[statusKey]);
        }

        return (pnl).toFixed(2) || 0;
    }


    const columns = [

        {
            accessorKey: "user_id",
            header: "User Id",
            // hidden: true
        },
        {
            accessorKey: "email",
            // header: "Email",
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Email
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                )
            },
        },
        {
            accessorKey: "name",
            header: "Name",
            cell: ({ row: { original } }) => {
                return (
                    `${original.first_name} ${original.last_name}`
                )
            }
        },
        {
            accessorKey: "strategy",
            header: "Strategy",
            width: "100px",
            cell: ({ row: { original } }) => {
                if (original.strategy == "ai") {
                    return (
                        <Badge variant="ai">{t(`strategy.ai`)}</Badge>
                    )
                }
                return (
                    <Badge variant="normal">{t(`strategy.cbdt`)}</Badge>
                )
            }
        },
        {
            accessorKey: "platform",
            header: "Plateforme",
            cell: ({ row: { original } }) => {
                if (original.platform == 1) {
                    return (
                        <Badge variant="binance">Binance</Badge>
                    )
                }
                return (
                    <Badge variant="kraken">Kraken</Badge>
                )
            }
        },

        {
            accessorKey: "pnl",
            header: "PNL",
            cell: ({ row: { original } }) => {
                let pnl = 0

                const desiredStatuses = ["1", "2", "4", "18", "0", "15", "16", "17"];
                for (const value of desiredStatuses) {
                    if (original.sumByStatus[value] !== undefined) {

                        pnl += original.sumByStatus[value];
                    }
                }
                return pnl.toFixed(2) || "0.00"
            }
        },
        {
            accessorKey: "pnlPaid",
            header: "PNL payé",
            cell: ({ row: { original } }) => {
                // let pnl1 = original?.sumsByStatus["1"] ? original?.sumsByStatus["1"]["five"] : 0
                // let pnl2 = original?.sumsByStatus["2"] ? original?.sumsByStatus["2"]["five"] : 0
                // let pnl4 = original?.sumsByStatus["4"] ? original?.sumsByStatus["4"]["five"] : 0
                const statusKeys = ["1", "2", "4", "18"];
                const pnl = calculatePnl(original, statusKeys);
                return pnl || 0
            }
        },
        {
            accessorKey: "pnlNotPaid",
            header: "PNL non payé",
            cell: ({ row: { original } }) => {
                const statusKeys = ["0", "15", "16", "17"];
                const pnl = calculatePnl(original, statusKeys);
                return pnl
            }
        },

        {
            id: "actions",
            header: "Actions",
            cell: ({ row: { original } }) => {
                //const payment = row.original

                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>

                            <DropdownMenuSeparator />


                            <DropdownMenuItem
                                onClick={(event) => {
                                    const cryptEmail = service.encryptCBData(original?.email)
                                    // navigate(`/pnl/${original?.id}`);
                                    window.open(`/pnl/${original?.user_id}`, '_blank');
                                }}>
                                Modifier PNL
                            </DropdownMenuItem>

                            <DialogDemo
                                trigger={
                                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                                        Détails
                                    </DropdownMenuItem>
                                }

                                title={"Détails PNL"}
                                text={`${original.email}`}
                            >
                                <div>
                                    <DetailsPnlList details={original.data} />
                                </div>
                            </DialogDemo>

                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ];

    const column2 = [

        {
            accessorKey: "id",
            header: "Id",
            // hidden: true
        },
        {
            accessorKey: "email",
            // header: "Email",
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Email
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                )
            },
        },
        {
            accessorKey: "name",
            header: "Name",
            cell: ({ row: { original } }) => {
                return (
                    `${original.first_name} ${original.last_name}`
                )
            }
        },
        {
            accessorKey: "strategy",
            header: "Strategy",
            cell: ({ row: { original } }) => {
                if (original.strategy == "ai") {
                    return (
                        <Badge variant="ai">{t(`strategy.ai`)}</Badge>
                    )
                }
                return (
                    <Badge variant="normal">{t(`strategy.cbdt`)}</Badge>
                )
            }
        },
        {
            accessorKey: "platform",
            header: "Plateforme",
            cell: ({ row: { original } }) => {
                if (original.platform == 1) {
                    return (
                        <Badge variant="binance">Binance</Badge>
                    )
                }
                return (
                    <Badge variant="kraken">Kraken</Badge>
                )
            }
        },
        {
            accessorKey: "pnl_five_usdt",
            header: "5% PNL",
            cell: ({ row: { original } }) => {
                // const statusKeys = ["0", "15", "16", "17"];
                // const pnl = calculatePnl(original, statusKeys);
                return original.pnl_five_usdt || "0.00"
            }
        },
        {
            accessorKey: "pnl_status",
            header: "Statut PNL",
            cell: ({ row: { original } }) => {
                // const statusKeys = ["0", "15", "16", "17"];
                // const pnl = calculatePnl(original, statusKeys);
                return original.pnl_status || "-"
            }
        },
        // {
        //     accessorKey: "pnlPaid",
        //     header: "PNL payé",
        //     cell: ({ row: { original } }) => {
        //         // let pnl1 = original?.sumsByStatus["1"] ? original?.sumsByStatus["1"]["five"] : 0
        //         // let pnl2 = original?.sumsByStatus["2"] ? original?.sumsByStatus["2"]["five"] : 0
        //         // let pnl4 = original?.sumsByStatus["4"] ? original?.sumsByStatus["4"]["five"] : 0
        //         const statusKeys = ["1", "2", "4", "18"];
        //         const pnl = calculatePnl(original, statusKeys);
        //         return pnl || 0
        //     }
        // },
        // {
        //     accessorKey: "pnlNotPaid",
        //     header: "PNL non payé",
        //     cell: ({ row: { original } }) => {
        //         const statusKeys = ["0", "15", "16", "17"];
        //         const pnl = calculatePnl(original, statusKeys);
        //         return pnl
        //     }
        // },

        {
            id: "actions",
            header: "Actions",
            cell: ({ row: { original } }) => {
                //const payment = row.original

                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>

                            <DropdownMenuSeparator />
                            {/* <DropdownMenuItem>
                                <BtnClientOk
                                    uid={original?.id}
                                    userData={original}
                                />
                            </DropdownMenuItem> */}

                            <DropdownMenuItem
                                onClick={(event) => {
                                    const cryptEmail = service.encryptCBData(original?.email)
                                    // navigate(`/pnl/${original?.id}`);
                                    window.open(`/pnl/${original?.user_id}`, '_blank');
                                }}>
                                Modifier PNL
                            </DropdownMenuItem>
                            {/* <DropdownMenuItem
                                onClick={() => handleResetStatus(original.id, original.pauseInfo?.oldStatus)}>
                                Restaurer status
                            </DropdownMenuItem> */}
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ];

    const handleGetPnl = async () => {
        setIsLoading(true)
        try {
            const { data } = await axiosPrivate.get(`/api/admin/extract`)
            const formToexcel = data?.pnlRow
            service.exportExcel(formToexcel && formToexcel, `pnl.xlsx`)
            console.log("lasa le pnl");
        } catch (error) {
            console.error("handleGetPnl", error);
        }
        setIsLoading(false)
    }

    const handlePrevPage = () => {
        setNextLoading(true)
        try {
            setCurrentPage(prevPage => prevPage - 1)
            console.log("next", prevPage => prevPage - 1)
        } catch (error) {
            console.error(error);
        }
        setNextLoading(false)

    }
    const handleNextPage = () => {
        setPrevLoading(true)
        try {
            setCurrentPage(prevPage => prevPage + 1)
            console.log("next", prevPage => prevPage + 1)
        } catch (error) {
            console.error(error);
        }
        setPrevLoading(false)

    }

    const handleGetPnlUnpaid = async () => {
        setIsLoading(true)
        try {
            setCategory(values.categoryPnl)
            setCurrentPage(1)
            console.log("ao ary", values.categoryPnl);
        } catch (error) {
            console.error("handleGetPnlUnpaid", error);
        }
        setIsLoading(false)

    }



    const listPnl = [
        { id: "unpaid", item: "Non payé" },
        { id: null, item: "Tous les PNL" }
    ]

    return (
        <>
            <div className="p-4  gap-3">
                <div className=" bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 h-full ">
                    <h3 className="font-bold text-xl text-slate-700 dark:text-slate-100">PNL</h3>
                    <div className='flex space-x-2 items-end justify-between'>

                        <BtnShad text={`${t(`label.export`)} details PNL`} size={"sm"} width={"md:w-40"} onClick={handleGetPnl} isLoading={isLoading} disabled={isLoading} />
                        <div className='flex items-end space-x-2'>
                            <SelectShad
                                name="categoryPnl"
                                option={listPnl}
                                placeholder={"Catégorie"}
                                onChange={handleInputChange}
                                label={"Catégorie"}
                                value={values['categoryPnl']}
                                error={errors["categoryPnl"]}
                                className="md:w-[15rem] "
                            />
                            <BtnShad text="Confirmer" variant="secondary" size={"sm"} width={"md:w-40"} onClick={handleGetPnlUnpaid} isLoading={isLoading} disabled={isLoading} />
                        </div>


                    </div>
                    <div className="container mx-auto ">
                        {users ?
                            <DataTable columns={columns} data={users?.length > 0 ? users : []} setCurrentPage={setCurrentPage} />
                            :
                            <Loading />
                        }
                    </div>
                    <div className='flex justify-between'>
                        <BtnShad text="prev" onClick={handlePrevPage} disabled={currentPage == 1 ? true : false} isLoading={prevLoading} />
                        <div>{currentPage}</div>
                        <BtnShad text="next" onClick={handleNextPage} isLoading={nextLoading} disabled={users?.length > 11 ? false : true} />
                    </div>

                </div>

            </div>

        </>
    )
}

export default PnlTable
