import React from "react";
import ListUsers from "../components/admin/ListUsers";

function UsersScreen(props) {
    const { t } = props
    return (
        <>
            <ListUsers t={t} />
        </>
    );
}

export default UsersScreen;