import React from "react";
import { useFOrm, Form } from "../useFOrm";
import Controls from "../controls/Controls";
import axios from "../../axios";
import { toast } from "react-toastify";
import { updateUserDetails } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { authSelector, logout } from "../../features/authSlice";

const isRecuuringItems = [
  { id: true, title: "Oui" },
  { id: false, title: "Non" },
];
const initialFvalues = {
  initialCapital: "",
  obCapital: "",
  obComment: "",
  isRecurring: false,
  recurringAmount: "",
  recurringComment: "",
};

function CapitalForm(props) {
  const { data, t, uid } = props;
  const auth = useSelector(authSelector);
  const axiosPrivate = useAxiosPrivate();

  const dispatch = useDispatch();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("initialCapital" in fieldValues) temp.initialCapital = fieldValues.initialCapital ? "" : t("error.required");
    if ("obCapital" in fieldValues) temp.obCapital = fieldValues.obCapital ? "" : t("error.required");
    if ("obComment" in fieldValues) temp.obComment = fieldValues.obComment ? "" : t("error.required");
    if ("recurringAmount" in fieldValues)
      temp.recurringAmount =
        (fieldValues.isRecurring == true || fieldValues.isRecurring === "true") && (fieldValues.recurringAmount === "" || fieldValues.recurringAmount === null) ? t("error.required") : "";
    // if ('recurringComment' in fieldValues)
    //     temp.recurringComment = fieldValues.recurringComment ? "" : t("error.required")
    if ("recurringComment" in fieldValues)
      temp.recurringComment =
        (fieldValues.isRecurring == false || fieldValues.isRecurring === "false") && (fieldValues.recurringComment === "" || fieldValues.recurringComment === null) ? t("error.required") : "";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) {
      //values.source_income === "autre" && values.otherSourceOfIncome === "" ? console.log("autre") : console.log("lol")
      //if not null
      return Object.values(temp).every((x) => x == "");

      /*return Object.values(temp).every(([k, v]) => k === "otherSourceOfIncome" || v === ""

            );*/
    }
  };

  for (const key in initialFvalues) {
    initialFvalues[key] = data[key];
  }

  const {
    values,
    setValues,
    errors,
    setErrors,
    //resetForm,
    handleInputChange,
  } = useFOrm(initialFvalues, true, validate);

  const formReccuring = [
    {
      type: "radio",
      name: "isRecurring",
      label: t("label.reccuringPayment"),
    },
  ];

  const formReccuringDetails = [
    {
      type: "text",
      name: "recurringAmount",
      label: t("label.recurringAmount"),
      placeholder: t("label.recurringAmount"),
    },
    {
      type: "text",
      name: "recurringComment",
      label: t("label.comment"),
      placeholder: t("label.comment"),
    },
  ];

  const formInitCapital = [
    {
      type: "text",
      name: "initialCapital",
      label: t("label.initialCapital"),
      placeholder: t("label.initialCapital"),
    },
    {
      type: "text",
      name: "obCapital",
      label: t("label.obCapital"),
      placeholder: t("label.obCapital"),
    },
    {
      type: "text",
      name: "obComment",
      label: t("label.comment"),
      placeholder: t("label.comment"),
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleUpdateInfo = async () => {
    if (validate()) {
      // console.log("valuev 1", values)
      const dataToUpdate = {
        ...values,
        uid: uid,
        isRecurring: values.isRecurring === "true" ? true : false,
      };
      try {
        //values.isRecurring = Boolean(values.isRecurring)
        values.uid = uid;
        const detailsData = await axiosPrivate.get(`/api/details/findBy/${uid}`);

        if (detailsData.data) {
          try {
            await axiosPrivate.put(`/api/details/${uid}`, dataToUpdate);
            dispatch(updateUserDetails(dataToUpdate));
            toast.success("Details updated");
          } catch (error) {
            toast.error(error.response?.data.msg);
          }
        } else {
          try {
            await axiosPrivate.post(`/api/details/create/`, dataToUpdate);
            dispatch(updateUserDetails(dataToUpdate));
            toast.success("Details Added");
          } catch (error) {
            toast.error(error.response?.data.msg);
          }
        }
      } catch (error) {
        toast.error(error.response?.data.msg);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <div className="flex flex-col p-4 space-y-3" key="formCapital">
        <div className="flex flex-col  space-y-3">
          <h6 className="flex-none font-semibold text-sm">{t(`label.capital`)} </h6>
          <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
            {formInitCapital.map((item, i) => {
              return (
                <>
                  <Controls.Input
                    fullWidth={true}
                    key={i}
                    // disabled={disabled}
                    readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                    label={item.label}
                    value={values[item.name] || ""}
                    onChange={handleInputChange}
                    name={item.name}
                    type={item.type}
                    error={errors[item.name]}
                  />
                </>
              );
            })}
          </div>
        </div>

        <div className="flex flex-col  space-y-3">
          <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
            {formReccuring.map((item, i) => {
              return (
                <Controls.RadioGroup items={isRecuuringItems} key={i} label={item.label} value={values[item.name] || false} onChange={handleInputChange} name={item.name} error={errors[item.name]} />
              );
            })}
          </div>
        </div>

        <div className="flex flex-col  space-y-3">
          {/* <h6 className='flex-none font-semibold text-sm'>Binance </h6> */}
          <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
            {formReccuringDetails.map((item, i) => {
              return (
                <>
                  <Controls.Input
                    fullWidth={true}
                    key={i}
                    // disabled={disabled}
                    readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                    label={item.label}
                    value={values[item.name] || ""}
                    onChange={handleInputChange}
                    name={item.name}
                    type={item.type}
                    error={errors[item.name]}
                  />
                </>
              );
            })}
          </div>
        </div>

        <div className="flex justify-end ">
          <Controls.Button
            disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
            text={t(`button.save`)}
            xsFont
            onClick={() => handleUpdateInfo()}
          />
        </div>
      </div>
    </Form>
  );
}

export default CapitalForm;
