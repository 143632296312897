import React, { useState } from "react";
import { useFOrm, Form } from "../useFOrm";
import Controls from "../controls/Controls";
import { toast } from "react-toastify";
import { updateDoc } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "../DialogBox";
import { authSelector } from "../../features/authSlice";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

// const sourceOfIncomeItems = [
//   { id: "retraite", title: "Retraité" },
//   { id: "epargne", title: "Epargne" },
//   { id: "employe", title: "Employé" },
//   { id: "heritage", title: "Héritage" },
//   { id: "autre", title: "Autre" },
// ];
const initialFvalues = {
  type_id: "",
  doc_id: "",
  type_address: "",
  doc_address: "",
  source_income: "",
  otherSourceOfIncome: "",
  doc_contract: "",
  googleDriveLink: "",
};

function DocumentsFormSimple(props) {
  const { data, t, uid } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState("");
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

    const sourceOfIncomeItems = [
        { id: "retraite", title: t(`label.retraite`) },
        { id: "epargne", title: t(`label.epargne`) },
        { id: "employe", title: t(`label.employe`) },
        { id: "heritage", title: t(`label.heritage`) },
        { id: "dividend", title: t(`label.dividende`) },
        { id: "autre", title: t(`label.other`) }

    ];

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    //if ('type_id' in fieldValues)
    //   temp.type_id = fieldValues.type_id ? "" : t("error.required")
    //if ('doc_id' in fieldValues)
    //    temp.doc_id = fieldValues.doc_id ? "" : t("error.required")
    //if ('type_address' in fieldValues)
    //   temp.type_address = fieldValues.type_address ? "" : t("error.required")
    //if ('doc_address' in fieldValues)
    // temp.doc_address = fieldValues.doc_address ? "" : t("error.required")
    if ("source_income" in fieldValues) temp.source_income = fieldValues.source_income ? "" : t("error.required");
    if ("otherSourceOfIncome" in fieldValues) {
      /*temp.otherSourceOfIncome = (fieldValues.source_income == "autre" && fieldValues.otherSourceOfIncome != "") ? "" :
                (fieldValues.source_income == "autre" && fieldValues.otherSourceOfIncome == "") ? t("error.required") :
                    (fieldValues.source_income != "autre") ? "" : t("error.required")*/
      temp.otherSourceOfIncome = fieldValues.source_income === "autre" && (fieldValues.otherSourceOfIncome === null || fieldValues.otherSourceOfIncome === "") ? t("error.required") : "";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "" || x === {});
    }
  };

  initialFvalues.type_id = data?.files?.identity?.type;
  initialFvalues.type_address = data?.files?.address?.type;
  initialFvalues.source_income = data?.source_income;
  initialFvalues.otherSourceOfIncome = data?.otherSourceOfIncome;
  initialFvalues.googleDriveLink = data?.googleDriveLink;

  const {
    values,
    //setValues,
    errors,
    setErrors,
    //resetForm,
    handleInputChange,
  } = useFOrm(initialFvalues, true, validate);

  const formSource = [
    {
      type: "radio",
      name: "source_income",
      label: t("label.source_income"),
      placeholder: t("label.source_income"),
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const uploadImg = async () => {
    if (validate()) {
      try {
        setIsLoading(true);
        /*const formData = new FormData();
                formData.append('file', values?.doc_id || "");
                formData.append('fileName', values?.doc_id?.name || "");

                const formData2 = new FormData();
                formData2.append('file', values?.doc_address || "");
                formData2.append('fileName', values?.doc_address?.name || "");

                const formData3 = new FormData();
                formData3.append('file', values?.doc_contract || "");
                formData3.append('fileName', values?.doc_contract?.name || "");


                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                    },
                };

                let response, response2, response3;
                if (values?.doc_id !== "") {
                    response = await axios.post('/uploadfile', formData, config);
                }

                if (values?.doc_address !== "") {
                    response2 = await axios.post('/uploadfile', formData2, config);
                }

                if (values?.doc_contract !== "") {
                    response3 = await axios.post('/uploadfile', formData3, config);
                }


                const docToUpdate = {
                    uid: uid,
                    files: {
                        identity: {
                            filename: response?.data[0] ? response?.data[0].filename
                                : data?.files.identity.filename !== "" ? data.files.identity.filename : "",
                            type: values.type_id,
                        },
                        address: {
                            filename: response2?.data[0] ? response2?.data[0].filename
                                : data?.files.address.filename !== "" ? data.files.address.filename : "",
                            type: values.type_address,
                        },
                        contract: {
                            filename: response3?.data[0] ? response3?.data[0].filename
                                : data?.files.contract.filename !== "" ? data.files.contract.filename : "",
                            type: 'docuSign',
                        }
                    },
                    source_income: values.source_income,
                    otherSourceOfIncome: values.otherSourceOfIncome,
                    googleDriveLink: values.googleDriveLink
                }*/
        const docToUpdate = {
          uid: uid,
          files: {
            identity: {
              filename: "",
              type: values.type_id,
            },
            address: {
              filename: "",
              type: values.type_address,
            },
            contract: {
              filename: "",
              type: "docuSign",
            },
          },
          source_income: values.source_income,
          otherSourceOfIncome: values.otherSourceOfIncome,
          // googleDriveLink: values.googleDriveLink,
        };

        //find row in doc tables
        const docResult = await axiosPrivate.get(`/api/documents/${uid}`);

        if (docResult.data) {
          //update
          await axiosPrivate.put(`/api/documents/${uid}`, docToUpdate);
        } else {
          //create
          await axiosPrivate.post(`/api/documents/create`, docToUpdate);
        }

        dispatch(updateDoc(docToUpdate));
        setIsLoading(false);
        toast.success("Source de revenu updated", uid);
      } catch (error) {
        setIsLoading(false);
        toast.error(error.response?.data.msg);
      }
    }
  };
  const handleSubmitLink = async () => {
    if (validate()) {
      try {
        setIsLoading2(true);
        const docToUpdate = {
          uid: uid,
          files: {
            identity: {
              filename: "",
              type: values.type_id,
            },
            address: {
              filename: "",
              type: values.type_address,
            },
            contract: {
              filename: "",
              type: "docuSign",
            },
          },

          googleDriveLink: values.googleDriveLink,
        };

        //find row in doc tables
        const docResult = await axiosPrivate.get(`/api/documents/${uid}`);

        if (docResult.data) {
          //update
          await axiosPrivate.put(`/api/documents/${uid}`, docToUpdate);
        } else {
          //create
          await axiosPrivate.post(`/api/documents/create`, docToUpdate);
        }

        dispatch(updateDoc(docToUpdate));
        setIsLoading2(false);
        toast.success("Doc updated", uid);
      } catch (error) {
        setIsLoading2(false);
        toast.error(error.response?.data.msg);
      }
    }
  };

  const handleClick = (type) => {
    if (!data) return setImage(null);
    setImage(data?.files[type]?.filename);
    setIsOpen(true);
  };

  return (
    <>
      <DialogBox isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex justify-center max-h-[500px]">
          {image ? <img className="object-contain max-h-[500px]" src={`http://localhost:5000/static/${image}`} alt="Preuve" /> : <div>no Image</div>}
        </div>
      </DialogBox>

      <div className="flex justify-center p-4 space-y-4">
        <Form encType="multipart/form-data" onSubmit={handleSubmit} noValidate className="flex-none max-w-md space-y-4">
          {/* <img src='http://localhost:5000/static/1655106775025.png' /> */}

          <div className="flex flex-col  space-y-4">
            {/* <div className='flex items-center space-x-2'>
                            <span className='flex-none font-semibold text-sm dark:text-slate-100'>{t('label.identityProof')}</span>
                            {data?.files?.identity ? (
                                <span className='bg-indigo-600 w-fit rounded-full px-2 text-xs text-white hover:cursor-pointer hover:bg-indigo-700' onClick={() => handleClick('identity')} > Preview</span>
                            ) : null}

                        </div> */}

            {/*    <div className='space-y-2 '>
                            <Controls.Select
                                name="type_id"
                                label={t('label.identityType')}
                                value={values.type_id || ''}
                                onChange={handleInputChange}
                                options={documentService.getDocummentCollection()}
                                error={errors.type_id}

                            />

                           <InputFile
                                name='doc_id'
                                //
                                onChange={handleInputChange} error={errors.doc_id} />


                        </div>*/}
          </div>

          <div className="flex flex-col  space-y-3">
            {/* <div className='flex items-center space-x-2'>
                            <span className='flex-none font-semibold text-sm dark:text-slate-100'>{t('label.addressProof')}</span>

                            {data?.files?.address ? (
                                <span className='bg-indigo-600 w-fit rounded-full px-2 text-xs text-white hover:cursor-pointer hover:bg-indigo-700' onClick={() => handleClick('address')}> Preview</span>
                            ) : null}
                        </div> */}

            {/*   <div className='space-y-2 '>
                            <Controls.Select
                                name="type_address"
                                label={t('label.addressType')}
                                value={values.type_address || ""}
                                onChange={handleInputChange}
                                options={documentService.getTypeAddressCollection()}
                                error={errors.type_address}

                            />

                             <InputFile name='doc_address' onChange={handleInputChange} error={errors.doc_address} />


                        </div>*/}
          </div>

          {formSource.map((item, i) => {
            return (
              <>
                <Controls.RadioGroup
                  items={sourceOfIncomeItems}
                  key={item.label}
                  label={item.label}
                  //  readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                  // readOnly={true}
                  value={values[item.name] || ""}
                  onChange={handleInputChange}
                  name={item.name}
                  error={errors[item.name]}
                />
              </>
            );
          })}

          <Controls.Input
            fullWidth={true}
            label="Autre source de revenu"
            readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
            value={values.otherSourceOfIncome || ""}
            onChange={handleInputChange}
            name="otherSourceOfIncome"
            error={errors.otherSourceOfIncome}
          />
          <div className="flex justify-end ">
            <Controls.Button
              disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
              text={t(`button.save`)}
              xsFont
              onClick={uploadImg}
              isLoading={isLoading}
            />
          </div>
          <div className="font-semibold text-sm">Lien GOOGLE DRIVE</div>

          <Controls.Input
            fullWidth={true}
            readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
            label="Google Drive link"
            value={values.googleDriveLink || ""}
            onChange={handleInputChange}
            name="googleDriveLink"
            error={errors.googleDriveLink}
          />

          {/* <div className='flex flex-col  space-y-3'>

                        <div className='flex items-center space-x-2'>
                            <span className='flex-none font-semibold text-sm dark:text-slate-100'>{t('label.contract')}</span>
                            {data?.files?.contract ? (
                                <span className='bg-indigo-600 w-fit rounded-full px-2 text-xs text-white hover:cursor-pointer hover:bg-indigo-700' onClick={() => handleClick('contract')}> Preview</span>
                            ) : null}
                        </div>
                        <div className='space-y-2 '>

                            <InputFile name='doc_contract' onChange={handleInputChange} error={errors.doc_contract} />


                        </div>
                    </div> */}

          <div className="flex justify-end ">
            <Controls.Button
              disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
              text={t(`button.save`)}
              xsFont
              onClick={handleSubmitLink}
              isLoading={isLoading2}
            />
          </div>
        </Form>
      </div>
    </>
  );
}

export default DocumentsFormSimple;
