import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import Sidebar from "../components/Sidebar";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import moment from "moment";
import "moment/locale/fr";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import Title from "../components/title/Title";
import TopBar from "../components/TopBar";
import * as sideMenus from "../utils/sideMenus";
import PieChartCustom from "../components/chart/PieChartCustom";
import DialogBox from "../components/DialogBox";
import Controls from "../components/controls/Controls";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardCard from "../components/cards/DashboardCard";
import { CalendarIcon, ChartBarIcon, CollectionIcon, MenuIcon, PresentationChartBarIcon, ServerIcon, ClockIcon, InboxIcon } from "@heroicons/react/outline";
import { Bar } from 'react-chartjs-2';
import CardHistory from "../components/Planit/CardHistory";
import { decryptCBData } from "../utils/service";
import TicketNotHandledLine from "../components/Planit/TicketNotHandledLine";

moment.locale("fr");

function TicketAnalyticScreen(props) {
    const { t } = props;
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [getResult, setGetResult] = useState(null);
    const [isLateOpen, setisLateOpen] = useState(false)
    const [isMTOpen, setisMTOpen] = useState(false)
    const [isTicketNotHandled, setIsTicketNotHandled] = useState(false)
    const [clData, setClData] = useState([])
    const [motifCardArr, setMotifCardArr] = useState({ count: 0, details: [], name: '' })
    const [clnh, setClnh] = useState({ listName: '', details: [], count: 0 })


    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    const getBoard = async () => {
        return await axiosPrivate.get(`/api/planit/stat`);
    }

    const { data, isLoading, isError } = useQuery("board", getBoard,)
    useEffect(() => {
        if (!isLoading && !isError) {
            // Set the data in the getResult state when it's available
            setGetResult(data.data);
        }

    }, [data, isLoading, isError]);

    console.log("Result", getResult);

    if (isLoading) {
        console.log("Loading data...");
    } else if (isError) {
        console.error("Error fetching data");
    } else {
        // console.log("Query", data.data);
    }

    const Table = ({ children }) => {
        return (
            <div className="overflow-x-auto">
                <table className="min-w-full text-xs font-medium text-gray-600">{children}</table>
            </div>
        );
    };

    const TableHeader = ({ columns }) => {
        return (
            <thead>
                <tr>
                    {columns.map((column) => (
                        <th
                            key={column.key}
                            className={`px-6 py-2 text-left text-xs font-medium font-bold text-gray-500 uppercase tracking-wider ${column.className}`}
                        >
                            {column.header}
                        </th>
                    ))}
                </tr>
            </thead>
        );
    };

    const TableRow = ({ children }) => {
        return <tr className="border-t border-gray-200">{children}</tr>;
    };

    const TableCell = ({ children, className, onClick }) => {
        return (
            <td className={`px-6 py-2 whitespace-nowrap ${className}`} onClick={onClick}>
                {children}
            </td>
        );
    };

    const columns = [
        { key: 'motif', header: "Motifs", className: '' },
        { key: 'total', header: "Total", className: '' }
    ]

    const ml = [
        { motif: "Férié", total: getResult?.cardLate43, id: 43 },
        { motif: "Bug Logiciel", total: getResult?.cardLate44, id: 44 },
        { motif: "Oublie MAJ ", total: getResult?.cardLate48, id: 48 },
        { motif: "MAJ admin", total: getResult?.cardLate49, id: 49 },
        { motif: "Autres", total: getResult?.cardLate45, id: 45 }
    ]

    let cl = "test";
    let motifLate = localStorage.getItem("setmotifLate")

    const handleCellClick = (cellData, cellMotif) => {
        // Handle cell click here, for example, log the clicked cell's data
        console.log('Cell clicked:', cellData);
        localStorage.setItem("setmotifLate", cellMotif)

        setisLateOpen(true)

        switch (cellData) {
            case 43:
                cl = "cl43"
                setClData(getResult?.cl43)
                break;

            case 44:
                cl = "cl44"
                setClData(getResult?.cl44)
                break;
            case 45:
                cl = "cl45"
                setClData(getResult?.cl45)
                break;
            case 48:
                cl = "cl48"
                setClData(getResult?.cl48)
                break;
            case 49:
                cl = "cl49"
                setClData(getResult?.cl49)
                break;

            default:
                break;
        }
        console.log("CCCLL", cl, "motif", motifLate);
    }

    const handleMCClicked = async (name, count, key) => {
        console.log(key, 'Cell clicked:', name, 'count', count);
        setisMTOpen(true)
        setMotifCardArr(getResult?.cardParMotif && getResult?.cardParMotif[key])
    }

    const handleNotHandle = (data) => {
        console.log("NOT HANDLED EHHH", data);
        setIsTicketNotHandled(true)
        setClnh(data)
    }

    

    const goBack = () => navigate(-1);
    return (
        <>


            <TopBar menus={sideMenus.menusAdmin(t)} />

            <div className="flex flex-row flex-grow ">
                <Sidebar menus={sideMenus.menusAdmin(t)} />
                <div className="flex flex-row flex-1  mt-2 md:ml-52">
                    <div className="flex flex-col w-full px-2">
                        {/* Title section */}
                        <div className="flex-none h-auto md:p-4 space-y-3">
                            <div className="flex items-center ">
                                <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
                                    <ArrowNarrowLeftIcon className="h-5 w-5 hover:cursor-pointer" onClick={goBack} />
                                </div>
                                <div className="font-semibold text-2xl text-slate-900 dark:text-white">{t("label.tickets")}</div>
                            </div>
                            <span className="text-xs text-slate-500 dark:text-slate-400 ">{moment().format("Do MMMM YYYY")}</span>

                        </div>

                        <div className="flex w-auto space-x-2">
                            {/*Tableau de Bord*/}
                            <div className="flex-1 space-y-2 ">

                                {/* 1er : Dashboard Card */}
                                <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm ">
                                    <Title.SectionTitle
                                        text={"Apperçu"}
                                        // unit={`(${users && users.all.length})`}
                                        icon={<ServerIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                    />
                                    <div className="flex space-x-2">
                                        <div>
                                            <DashboardCard number={getResult?.cardActiveCount} description={t(`label.tickets`)} bg={"bg-yellow-500 "} />
                                        </div>
                                        <div>
                                            <DashboardCard number={getResult?.cardLateNotHandled} description={"Ticket non traités"} bg={"bg-orange-500 "} />
                                        </div>

                                        <div>
                                            <DashboardCard number={getResult?.cardLate45} description={"Ticket to close"} bg={"bg-pink-500 "} />
                                        </div>
                                    </div>
                                </div>

                                {/* 2e : Motif ticket */}
                                <div className=" bg-slate-100 dark:bg-slate-800 p-2 rounded-sm ">
                                    <div className=" flex space-x-2  bg-slate-100 dark:bg-slate-800  rounded-sm  ">

                                        <div className="w-full bg-slate-50 p-1">
                                            <Title.SectionTitle
                                                text={"Motifs cards"}
                                                // unit={`(peut être nul dès fois >> table)`}
                                                icon={<ChartBarIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                            />
                                            <div className="h-64 scrollbar">
                                                <Table>
                                                    <TableHeader columns={columns} />
                                                    <tbody>
                                                        {getResult?.cardParMotif && Object.keys(getResult?.cardParMotif).map((key, i) => (
                                                            <TableRow key={i}>
                                                                <TableCell onClick={() => { getResult?.cardParMotif[key].count > 0 ? handleMCClicked(getResult?.cardParMotif[key].name, getResult?.cardParMotif[key].count, key) : console.log(getResult?.cardParMotif[key].name, "=", getResult?.cardParMotif[key].count); }} className={` hover:bg-blue-50 ${getResult?.cardParMotif[key].count > 0 ? "hover:cursor-pointer" : ""}`}>{getResult?.cardParMotif[key].name}</TableCell>
                                                                <TableCell className=" hover:bg-sky-50 hover:cursor-pointer">{getResult?.cardParMotif[key].count}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>

                                        <div className="w-full bg-slate-50 p-1">
                                            <Title.SectionTitle
                                                text={"Tickets non traités"}
                                                unit={`(à jour)`}
                                                icon={<ChartBarIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                            />
                                            <div>
                                                <Table>
                                                    <TableHeader columns={columns} />
                                                    <tbody>
                                                        {getResult?.clnhByList && Object.keys(getResult?.clnhByList).map((key, i) => (
                                                            <TableRow key={i}>
                                                                <TableCell className={` hover:bg-sky-50  ${getResult?.clnhByList[key].count > 0 ? "hover:cursor-pointer" : ""}`}
                                                                    onClick={() => { getResult?.clnhByList[key].count > 0 ? handleNotHandle(getResult?.clnhByList[key]) : console.log("Tickets not handled", getResult?.clnhByList[key].listName, "=", getResult?.clnhByList[key].count); }}>
                                                                    {getResult?.clnhByList[key].listName}

                                                                </TableCell>
                                                                <TableCell className=" hover:bg-sky-50 hover:cursor-pointer">{getResult?.clnhByList[key].count}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {/* 3e : ticket not handled*/}
                                <div className=" bg-slate-100 dark:bg-slate-800 p-2 rounded-sm ">
                                    <div className=" flex space-x-2  bg-slate-100 dark:bg-slate-800  rounded-sm  ">

                                        <div className="w-full bg-slate-50 p-1">
                                            <Title.SectionTitle
                                                text={"Nouveaux cards"}
                                                unit={`(courbe)`}
                                                icon={<ChartBarIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                            />
                                            <div>
                                                {getResult?.newCardCount && <TicketNotHandledLine data={getResult?.newCardCount} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* 4e : Motif Late */}
                                <div className=" bg-slate-100 dark:bg-slate-800 p-2 rounded-sm ">
                                    <div className=" flex space-x-2  bg-slate-100 dark:bg-slate-800  rounded-sm  ">

                                        <div className="w-1/2 bg-slate-50 p-1">
                                            <Title.SectionTitle
                                                text={"Nb de ticket en retard par jour"}
                                                // unit={`(${users && users.all.length})`}
                                                icon={<ChartBarIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                            />
                                            <Bar data={{
                                                // miova date tous les jours
                                                labels: getResult?.histogram.dates || [],
                                                datasets: [{
                                                    label: "Tickets en retard",
                                                    backgroundColor: "#0891b2",
                                                    borderWidth: 1,
                                                    hoverBackgroundColor: 'rgba(75,192,192,0.4)',
                                                    hoverBorderColor: 'rgba(75,192,192,1)',
                                                    // nb de late par jours
                                                    data: getResult?.histogram.lateCounts || []
                                                }]
                                            }} options={options} />
                                        </div>

                                        {/* Table for motif Late */}
                                        <div className="w-1/2 bg-slate-50 p-1">
                                            <Title.SectionTitle
                                                text={"Tickets en retard"}
                                                // unit={`(${users && users.all.length})`}
                                                icon={<ClockIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                            />
                                            <div>
                                                <Table>
                                                    <TableHeader columns={columns} />
                                                    <tbody>
                                                        {ml.map((m, i) => (
                                                            <TableRow key={i}>
                                                                <TableCell onClick={() => { m?.total > 0 ? handleCellClick(m?.id, m?.motif) : console.log(m?.motif, "=", m?.total); }} className={` hover:bg-blue-50 ${m?.total > 0 ? "hover:cursor-pointer" : ""}`}>{m?.motif}</TableCell>
                                                                <TableCell className=" hover:bg-sky-50 hover:cursor-pointer">{m?.total}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="h-10">
                                </div>


                            </div>

                            {/* Objectifs */}
                            <div className="bg-slate-100 w-[250px] py-4 h-fit">
                                <h1 className="font-semibold text-center text-2xl  text-slate-900 dark:text-white">
                                    Synthèse <p className="text-xs"></p>
                                </h1>

                                <div>
                                    <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm ">
                                        <Title.SectionTitle text={"Suivi"} icon={<CollectionIcon className="h-5 w-5 text-blue-700 text-xs" />} />
                                    </div>
                                    <div className=" w-full -mt-16 h-40">
                                        <PieChartCustom
                                            data={[
                                                { name: "tickets non traités", value: getResult?.cardLateNotHandled },
                                                { name: "ticket OK", value: getResult?.cardActiveCount - getResult?.cardLateNotHandled },
                                            ]}
                                            width={100}
                                            height={300}
                                            startAngle={180}
                                            endAngle={0}
                                            outerRadius={100}
                                            colors={["#f97316", "#3b82f6"]}
                                            noLegend
                                        />
                                    </div>
                                    <div className="text-xs text-slate-500 font-semibold text-center">Total ticket active = {getResult?.cardActiveCount}</div>

                                    <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm ">
                                        <Title.SectionTitle text={"KPI ticket"} icon={<PresentationChartBarIcon className="h-5 w-5 text-blue-700 text-xs" />} />
                                    </div>
                                    <div className=" w-full -mt-16 h-40">
                                        <PieChartCustom

                                            data={getResult?.kpi?.length > 0 ? getResult?.kpi : []}
                                            width={100}
                                            height={300}
                                            startAngle={180}
                                            endAngle={0}
                                            outerRadius={100}
                                            colors={["#facc15", "#06b6d4", "#10b981", "#ec4899"]}
                                            noLegend
                                        />
                                    </div>
                                    <div className="mt-2">
                                        <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm ">
                                            <Title.SectionTitle text={"Nb retard moyen"} icon={<CalendarIcon className="h-5 w-5 text-blue-700 text-xs" />} />
                                        </div>
                                        <div className=" flex justify-center">
                                            <DashboardCard number={getResult?.lateAverage} description={'Retard moyen'} bg={`${getResult?.lateAverage == 0 ? "bg-lime-500" : getResult?.lateAverage != 0 && getResult?.lateAverage < 3 ? "bg-yellow-400" : getResult?.lateAverage > 2 ? "bg-orange-500" : "bg-red-500"}`} unit={"jours"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Tickets en retard */}
                        <DialogBox isOpen={isLateOpen} setIsOpen={setisLateOpen}>
                            <div className="text-slate-500 uppercase font-bold text-xs">
                                <div className="w-fit mx-auto ">
                                    {motifLate}
                                </div>
                            </div>

                            {clData?.map((c, i) => (
                                <div key={i} className="pt-4">
                                    <div className="flex items-center text-xs pb-1 pl-2 font-medium text-slate-500">
                                        <InboxIcon className="w-3 h-3" />
                                        <div> {decryptCBData(c?.u?.email)} </div>
                                    </div>
                                    <CardHistory card={c} key={i} />
                                </div>
                            ))}


                        </DialogBox>

                        {/* Motif ticket */}
                        <DialogBox isOpen={isMTOpen} setIsOpen={setisMTOpen}>
                            <div className="text-slate-500 uppercase font-bold text-xs">
                                <div className=" flex space-x-1 w-fit mx-auto">
                                    <div>
                                        {motifCardArr?.name}
                                    </div>
                                    <div className="text-[8px]">
                                        ({motifCardArr?.count})
                                    </div>
                                </div>
                            </div>

                            {motifCardArr?.details?.map((m, i) => (
                                <div key={i} className=" items-center text-xs pb-1 pl-2 text-slate-500 font-medium pt-4">
                                    <div className="flex items-center text-xs pb-1 pl-2 text-slate-500">
                                        <InboxIcon className="w-3 h-3" />
                                        <div> {decryptCBData(m?.u?.email)} </div>
                                    </div>
                                    <CardHistory card={m} key={i} />
                                </div>
                            ))
                            }
                        </DialogBox>

                        {/* CLNH */}
                        <DialogBox isOpen={isTicketNotHandled} setIsOpen={setIsTicketNotHandled}>
                            <div className="text-slate-500 uppercase font-bold text-xs">
                                <div className=" flex space-x-1 w-fit mx-auto">
                                    <div>
                                        {clnh?.listName}
                                    </div>
                                    <div className="text-[8px]">
                                        ({clnh?.count})
                                    </div>
                                </div>
                            </div>

                            {clnh?.details?.map((m, i) => (
                                <div key={i} className=" items-center text-xs pb-1 pl-2 text-slate-500 pt-4 font-medium">
                                    <div className="flex items-center text-xs pb-1 pl-2 text-slate-500">
                                        <InboxIcon className="w-3 h-3" />
                                        <div> {decryptCBData(m?.u?.email)} </div>
                                    </div>
                                    <CardHistory card={m} key={i} />
                                </div>
                            ))
                            }

                        </DialogBox>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TicketAnalyticScreen;
