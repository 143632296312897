import React, { useState } from 'react'
import { DialogDemo } from '../client/dialogShad';
import BtnShad from '../client/btnShadcn';
import { TypographyMuted, TypographyS, TypographySBold, TypographySmall, TypographyXS } from '../Typo';
import useCountDown from '../../hooks/useCountDown';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useToast } from '../ui/use-toast';
import SelectShad from '../client/SelectShad';
import { useFOrm } from '../useFOrm';
import { ArrowBigLeftDash, ArrowBigRightDash } from 'lucide-react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../features/authSlice';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../features/user/userSlice';
import { useNavigate } from 'react-router-dom';

function Autonome(props) {
    const { t, data } = props;
    const auth = useSelector(authSelector);

    console.log("autonome Data ===", data);
    const [actualStep, setActualStep] = useState(data.onboardingStep)
    const [isLoading, setIsLoading] = useState(false)
    const { secondsLeft, start } = useCountDown()
    const axiosPrivate = useAxiosPrivate()
    const { toast } = useToast()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const initialValues = {
        step: ""

    }
    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("step" in fieldValues) temp.step = fieldValues.step ? "" : t("error.required");

        setErrors({
            ...temp,
        });
        console.log("Temp", temp);

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);

    const stepLabels = [
        { id: 1, item: t(`step.emailVerif`) },
        { id: 2, item: t(`step.passworProfiledSetup`) },
        { id: 3, item: t(`step.kycAml`) },
        { id: 4, item: t(`step.platformChoice`) },
        { id: 5, item: t(`step.walletVerif`) },
        { id: 6, item: t(`step.whitelistWithoutPlatform`) },
        { id: 7, item: t(`step.apiIntegration`) },
        { id: 8, item: `${t(`step.depotVerif`)} / dépot` },
        { id: 9, item: t(`obAi.acr`) },
        { id: 10, item: "Cbdt done" }
    ]

    const stepAi = [
        { id: 1, item: "Création de sous-compte Binance" },
        { id: 2, item: "Api" },
        { id: 3, item: "Depot" },
        { id: 4, item: t(`obAi.acr`) },
        { id: 5, item: "Ai done" }
    ]

    const handleSaveSwitch = async () => {
        try {
            console.log("SELF to ACTUVE");
            // mila mandefa id
            await axiosPrivate.get(`/api/onboarding/toActive/${data?.id}`)
            toast({
                title: `Autonome => Active `,
                description: `Le status du client ${data.email} est désormais ACTIVE`,
                duration: 30000,
            })
            console.log(`Lasa le switch ehhh 😎 ${data.email}`);
            window.location.reload()

        } catch (error) {
            console.error(`TSY NETY LE AUTONOME +> ACTIVE, ${data?.id}`);
            toast({
                title: ` ECHEC de l'opération !! `,
                description: `  ${error.response.data.msgCode}`,
                variant: "destructive",
                duration: 30000,
            })
        }
        start(0)

    }

    const handleChangeStep = async () => {
        setIsLoading(true)
        try {
            const dataToPut = {
                id: data?.id,
                newStep: values.step
            }
            console.log('value', dataToPut);

            await axiosPrivate.put(`/api/onboarding/revert-back`, dataToPut)
            setActualStep(values.step)
            dispatch(updateUser({ onboardingStep: values.step }));

            toast({
                title: ` Changement réussi !! `,
                variant: "success",
                duration: 30000,
            })
            // data?.onboardingStep = values.step
        } catch (error) {
            console.error(`TSY NETY LE  ${data?.id}`, error);
            console.error('value', values.step);
            toast({
                title: ` ECHEC de l'opération !! `,
                description: `  ${t(`error.${error.response.data.msgCode}`)}`,
                variant: "destructive",
                duration: 30000,
            })
        }
        setIsLoading(false)
    }

    const assistUser = async() => {
        try {
            console.log("🪭 Assist user 🪭", data?.id, data);
            // update onboarder
            const dataToPut = {
                onboarder: auth?.id
            }
            await axiosPrivate.put(`/api/users/${data.id}`, dataToPut);

            navigate(`/autonome/${data?.id}?step=${data?.onboardingStep}`)
        } catch (error) {
            console.error("Err assist user", error);

        }
    }

    return (
        <div className='flex flex-col space-y-6 p-2'>
            {/* Btn prendre en main */}
            <div className='flex justify-between pr-6 border-2 rounded-sm items-center p-2'>
                <TypographySmall> Le client autonome souhaite continuer avec le support</TypographySmall>
                {data.status == 18 &&
                    <DialogDemo
                        trigger={<BtnShad text={t(`Prendre en main`)} disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                        />
                        }
                        title={"Prendre en main"}
                        text={"Cette action permet de changer le status du client autonome en active, et cela permet au support de prendre en main la suite de l'onboarding. Le client pourrait entrer dans la zone membre après cette action"}
                    >
                        <>
                            {
                                data?.onboardingStep && data?.onboardingStep > 3 ?
                                    <TypographyMuted>Le client peut se connecter avec le mot de passe qu'il a choisi lors de l'onboarding autonome</TypographyMuted> : <TypographyMuted>
                                        Le client doit réinitialiser son mot de passe lors de la première connexion.</TypographyMuted>
                            }
                            <div className="flex justify-center w-full ">

                                {secondsLeft > 0 ? <BtnShad text={`${t(`button.confirm`)} (${secondsLeft} s)`} onClick={handleSaveSwitch} sendHistory={{ action: `Autonome => avec support  `, who: `${data?.id}` }} /> :
                                    <BtnShad text={t(`button.continue`)} xsFont onClick={() => start(30)} variant="tertiary" />

                                }
                            </div>
                        </>

                    </DialogDemo>
                }

            </div>
            <div className='flex justify-between pr-6 border-2 rounded-sm items-center p-2'>
                <TypographyS> Accompagner le client sur l'onboarding autonome</TypographyS>
                <BtnShad text={t(`button.assist`)} xsFont onClick={assistUser} variant="tertiary" />

            </div>
            <div className='flex flex-col border-2 rounded-sm p-2 justify-center pl-6'>
                {/* Liste étapes */}

                <div className='flex space-x-20 '>

                    <div className=''>
                        <TypographySBold>Les étapes de l'onboarding autonome (CBDT) </TypographySBold>
                        <div className='pl-4'>
                            {stepLabels.map((s) => (
                                <div className='flex '>
                                    {/* {data?.strategy == "normal" && data?.onboardingStep == s.id ? <ArrowBigRightDash className="w-4 h-fit -ml-6" /> : null} */}
                                    {data?.strategy == "normal" && data?.onboardingStep == s.id ? <ArrowBigRightDash className="w-4 h-fit -ml-6" /> : null}
                                    {/* <TypographySmall className={`text-xs text-muted-foreground ${data?.strategy == "normal" && data?.onboardingStep == s.id ? " bg-primary w-fit px-2 text-secondary rounded-sm" : ""}`}> */}
                                    <TypographySmall className={`text-xs text-muted-foreground ${data?.strategy == "normal" && data?.onboardingStep == s.id ? " bg-primary w-fit px-2 text-secondary rounded-sm" : ""}`}>
                                        {s.id} : {s.item}
                                    </TypographySmall>
                                </div>
                            ))}
                        </div>
                    </div>



                    <div className='pl-6'>
                        <TypographySBold>Les étapes de l'onboarding autonome (AI style) </TypographySBold>
                        <div className='pl-4'>
                            {stepAi.map((s) => (
                                <div className='flex '>
                                    {data?.strategy == "ai" && data?.onboardingStep == s.id ? <ArrowBigRightDash className="w-4 h-fit -ml-6" /> : null}

                                    <TypographySmall className={`text-xs text-muted-foreground ${data?.strategy == "ai" && data?.onboardingStep == s.id ? " bg-primary w-fit px-2 text-secondary rounded-sm" : ""}`}>{s.id} : {s.item}</TypographySmall>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>



                {/* Dropdown select step */}
                {data.strategy == "normal" && data?.onboardingStep &&
                    <div className='mt-10'>

                        <TypographySBold>Modification de l'étape</TypographySBold>
                        <div className='flex  items-end  space-x-2  '>
                            <SelectShad
                                className="w-[24rem]"
                                name="step"
                                // option={data.onboardingStep && data.onboardingStep == 3 ? stepLabels.filter((d) => d.id == 4) : data.onboardingStep > 3 ? stepLabels.filter((d) => d.id >= 4 && d.id < data.onboardingStep) : []}
                                option={
                                    auth?.id == "87e4efd1-376f-4741-8cc7-76b771b05b76" ? stepLabels :
                                        data?.onboardingStep && data?.onboardingStep == 3 ? stepLabels.filter((d) => d.id == 4) : data?.onboardingStep > 3 ? stepLabels.filter((d) => d.id >= 4 && d.id < data?.onboardingStep) : []

                                }
                                placeholder="Choisir"
                                onChange={handleInputChange}
                                label="Liste des étapes"
                                value={values.step}
                            />
                            {values.step &&
                                <BtnShad text={t(`button.confirm`)} xsFont onClick={handleChangeStep} variant="success" disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                    sendHistory={{ action: `Change step CBDT Autonome - step : ${values.step}`, who: `${data?.id}` }}
                                />
                            }
                        </div>
                    </div>}

                {data.strategy == "ai" && data?.onboardingStep == 3 &&
                    <div className='mt-10'>

                        <TypographySBold>Modification de l'étape</TypographySBold>
                        <div className='flex  items-end  space-x-2  '>
                            <SelectShad
                                className="w-[24rem]"
                                name="step"
                                // option={data.onboardingStep && data.onboardingStep == 3 ? stepLabels.filter((d) => d.id == 4) : data.onboardingStep > 3 ? stepLabels.filter((d) => d.id >= 4 && d.id < data.onboardingStep) : []}
                                option={
                                    auth?.id == "87e4efd1-376f-4741-8cc7-76b771b05b76" ? stepAi :
                                        data?.onboardingStep == 3 && stepAi.filter((d) => d.id == 2) || []}
                                placeholder="Choisir"
                                onChange={handleInputChange}
                                label="Liste des étapes"
                                value={values.step}
                            />
                            {values.step &&
                                <BtnShad text={t(`button.confirm`)} xsFont onClick={handleChangeStep} variant="tertiary"
                                    disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                    sendHistory={{ action: `Change step AI Autonome - step : ${values.step}`, who: `${data?.id}` }}

                                />
                            }
                        </div>
                    </div>}

            </div>

            {/* {values.step && <div> step : {values.step}</div>} */}

        </div>
    )
}

export default Autonome
