import React, { useState, useEffect } from "react";
import Controls from "../controls/Controls";
import DialogBox from "../DialogBox";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { authSelector } from "../../features/authSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFOrm } from "../../components/useFOrm";

const initialValues = {
  type: null,
  motif: null,
  //otherMotifCreate: null,
  commentMotif: null,
};

const CreateTicket = (props) => {
  const { t } = props;
  const { openTicketModal, setOpenTicketModal, uid } = props;
  const [type, setType] = useState([]);
  const [motif, setMotif] = useState([]);
  // const [otherMotifCreate, setOtherMotifCreate] = useState();
  const [errorOtherMotifCreate, setErrorOtherMotifCreate] = useState();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(authSelector);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("type" in fieldValues) temp.type = fieldValues.type == "-" || fieldValues.type == null || fieldValues.type == "" ? t("error.required") : "";
    if ("motif" in fieldValues) temp.motif = fieldValues.motif == "-" || fieldValues.motif == null || fieldValues.motif == "" ? t("error.required") : "";
    /*if ("otherMotifCreate" in fieldValues)
      temp.otherMotifCreate =
        (fieldValues.motif == 9 || fieldValues.motif == 18 || fieldValues.motif == 27) && (fieldValues.otherMotifCreate == null || fieldValues.otherMotifCreate == "") ? t("error.required") : "";
*/
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);

  const handleSubmit = async () => {
    if (validate()) {
      const newticket = {
        uid: uid,
        motif: values.motif,
        step: 0,
        by: auth.name,
        status: false,
        commentMotif: values.commentMotif,
      };

      try {
        const resp = await axiosPrivate.post(`/api/ticket/create`, newticket);
        if (!resp) throw "Errow While creating tickets";
        toast.success("Ticket creation successfully");
        setOpenTicketModal(false);
      } catch (error) {
        toast.error(error);
      }

      console.log(newticket);
    }
  };
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    //maka type pour ouverture ticket
    const getTypeCreateTicket = async () => {
      try {
        //axios call
        const { data } = await axiosPrivate.get(`/api/motif/category/Ticket`, { signal: controller.signal });
        console.log("Type", data);

        const result = Object.values(
          data.reduce((item, next) => {
            if (!item[next.type]) {
              item[next.type] = {
                type: next.type,
                data: [],
              };
            }

            item[next.type].data.push(next);
            return item;
          }, {})
        );

        let formatType = result.map((t, i) => {
          const tempArr = t.data.map((d) => ({
            title: d.motif,
            id: d.id,
          }));
          return {
            title: t.type,
            id: i + 1,
            data: tempArr,
          };
        });

        isMounted && setType(formatType);
        console.log("Res", formatType);
      } catch (error) {
        console.log("Error in getTypeCreateTicket ", error);
      }
    };
    getTypeCreateTicket();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (values.type && values.type != "-") {
      values.motif = "";
      const arrayMotifSelected = type[values?.type - 1]?.data;
      setMotif(arrayMotifSelected);
      console.log("IF", type[values.type - 1].data);
    }
  }, [values.type]);

  useEffect(() => {
    if (openTicketModal == false) {
      resetForm();
    }
    console.log(values.type);
  }, [openTicketModal]);

  // useEffect(() => {
  //   if (values.motif && (values.motif == 9 || values.motif == 18 || values.motif == 27) && (otherMotifCreate == "" || otherMotifCreate == null)) {
  //     setErrorOtherMotifCreate(t("error.required"));
  //   }
  // }, [values.motif]);

  // useEffect(() => {
  //   if (otherMotifCreate) {
  //     setErrorOtherMotifCreate();
  //   }
  // }, [otherMotifCreate]);

  return (
    <DialogBox isOpen={openTicketModal} setIsOpen={setOpenTicketModal}>
      <div className="flex flex-col gap-y-3">
        {/* <Controls.Input type="text" label="Motif" value={motif} onChange={(e) => setMotif(e.target.value)} /> */}
        <Controls.Select name="type"
        label={"Type"}
        value={values.type || ""}
        onChange={handleInputChange}
        options={type} error={errors.type}
        noneValue={"-"} />
        <Controls.Select name="motif" label={"Motif"} value={values.motif || ""} onChange={handleInputChange} options={motif} error={errors.motif} noneValue={"-"} />
        {/* {values.motif && (values.motif == 9 || values.motif == 18 || values.motif == 27) ? (
          <Controls.Input type="text" label="Autre" name="otherMotifCreate" value={values.otherMotifCreate || ""} onChange={handleInputChange} error={errors.otherMotifCreate} />
        ) : // <Controls.Input type="text" label="Autre" value={otherMotifCreate} onChange={(e) => setOtherMotifCreate(e.target.value)} error={errorOtherMotifCreate} />
        null} */}
        <Controls.Input type="text" label="Comment" name="commentMotif" value={values.commentMotif || ""} onChange={handleInputChange} error={errors.commentMotif} />
        <Controls.Button text="Créer" onClick={handleSubmit} />
      </div>
    </DialogBox>
  );
};

export default CreateTicket;
