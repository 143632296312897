import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import React, { useState, useEffect, useRef } from "react";
import VideoResource from "./VideoResource";

function SliderRowNetflix(props) {
  const { t, data } = props;
  const isMobile = window.innerWidth <= 768;
  const sliderRef = useRef(null);

  /*const slideLeft = () => {
    let slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    let slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };*/

  const [width, setWidth] = useState(window.innerWidth);
  const [show, setShow] = useState(0);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const slideLeft = (w) => {
    sliderRef.current.scrollLeft -= w;
    setShow(w);
  };
  const slideRight = (w) => {
    sliderRef.current.scrollLeft += w;
    setShow(w);
  };

  let firstItemVisible = sliderRef.current ? sliderRef.current.scrollLeft === 0 : false;

  return (
    <>
      {/* <div>The window width is {width} pixels.</div> */}
      {(isMobile && data && data.length > 1) || (!isMobile && data && data.length > 3) ? (
        <div className="relative flex items-center">
          <ChevronLeftIcon onClick={() => slideLeft(width - 120)} className="w-10 h-10 text-slate-500 opacity-50 cursor-pointer hover:opacity-100 hover:scale-105 transition-all" />

          {/* <ChevronLeftIcon onClick={() => slideLeft(width - 120)} className="w-10 h-10 text-slate-500 opacity-50 cursor-pointer hover:opacity-100 hover:scale-150 transition-all" /> */}
          {/* Row */}
          <div id="slider" ref={sliderRef} className=" w-full flex overflow-x-scroll scroll whitespace-nowrap scroll-smooth mt-4 ">
            {data &&
              data.map((r, i) => (
                <div className="flex flex-col" key={i}>
                  <div className="m-2 w-60 md:w-80  hover:scale-105 transition-all ">
                    {" "}
                    <VideoResource xsFont title={r.title} url={r.url} key={r.id} support={r.support} platform={r.platform}/>{" "}
                  </div>
                </div>
              ))}
          </div>

          <ChevronRightIcon onClick={() => slideRight(width - 120)} className="w-10 h-10 text-slate-500 opacity-50 cursor-pointer hover:opacity-100 hover:scale-105 transition-all" />
        </div>
      ) : (
        <div className="my-4">
          {/* Row */}
          <div className="  flex overflow-x-auto scroll mt-4 ">
            {data &&
              data.map((r, i) => (
                <div className="flex flex-col" key={i}>
                  <div className="m-2 w-60 md:w-80 hover:scale-105 transition-all hover:cursor-pointer">
                    {" "}
                          <VideoResource xsFont title={r.title} url={r.url} key={r.id} support={r.support} platform={r.platform} />{" "}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}

export default SliderRowNetflix;
