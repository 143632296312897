import React from "react";
import { DataGrid, frFR, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import "moment/locale/fr";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate, useLocation } from "react-router-dom";
import { authSelector } from "../../features/authSlice";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function TableauGrid(props) {
    const { column, dataRows, idRows, showQuickFilter, isOnboarding, hasDate, asc } = props;
    const navigate = useNavigate();
    const auth = useSelector(authSelector)
    const { t } = useTranslation()


    function NoRowsOverlay() {
        return <div className="h-full flex items-center justify-center">{t(`label.noResult`)}</div>;
    }
    return (
        <>
            <DataGrid
                rows={dataRows ? dataRows : []}
                components={{
                    Toolbar: GridToolbar,
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay,
                }}
                getRowId={(row) => (row.id ? row.id : row.date ? row.date : idRows)}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: showQuickFilter,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                localeText={auth?.lang == "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : null}
                initialState={{
                    sorting: {
                        sortModel: [{ field: `${hasDate ? "date" : "id"}`, sort: asc ? "asc" : "desc" }],
                    },
                    pinnedColumns: { left: ["id"] },
                }}
                columns={column}
                disableColumnSelector
                disableDensitySelector
                disableColumnFilter
                autoPageSize
                density="compact"
                disableSelectionOnClick={true}
                onRowClick={(rowData) =>
                    isOnboarding
                        ? navigate(`/onboarding-details/${rowData.row.id}`, {
                            state: {
                                type: rowData.row.type,
                                start: rowData.row.start,
                                end: rowData.row.end,
                            },
                        })
                        : null
                }
            //getRowClassName={(params) => `text-slate-800 dark:text-slate-100`}
            // getCellClassName={(params) => `text-slate-800 dark:text-slate-100`}
            />
        </>
    );
}

export default TableauGrid;
