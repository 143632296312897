import React, { useState } from 'react'
import Button from './controls/Button';
import { ArrowUpDown } from 'lucide-react';
import { Badge } from './ui/badge';
import { useTranslation } from 'react-i18next';
import { DataTable } from './client/data-table';
import moment from 'moment';
import { TypographyS, TypographySmall } from './Typo';
import BtnShad from './client/btnShadcn';

function DetailsPnlList(props) {
    const { details } = props
    const t = useTranslation()
    console.log("Details Pnl", details.data);
    const [currentPage, setCurrentPage] = useState(1);
    const [nextLoading, setNextLoading] = useState(false)
    const [prevLoading, setPrevLoading] = useState(false)

    const rowsPerPage = 5;

    const column = [

        {
            accessorKey: "id",
            header: "Pnl id ",
            // hidden: true
        },

        {
            accessorKey: "date_creation",
            header: "date de creation",
            cell: ({ row: { original } }) => {
                return moment(original.date_creation).format("DD-MM-YYYY")
            }
            // hidden: true
        },
        {
            accessorKey: "pnl_five_usdt",
            header: "5% PNL",
            cell: ({ row: { original } }) => {
                return original.pnl_five_usdt || "0.00"
            }
        },
        {
            accessorKey: "pnl_status",
            header: "Statut PNL",
            cell: ({ row: { original } }) => {
                return original.pnl_status || "-"
            }
        },

    ];

    // Calculate the current data to display
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = details.slice(indexOfFirstRow, indexOfLastRow);
    console.log("CurrentData", currentData);

    // Calculate the total number of pages
    const totalPages = Math.ceil(details.length / rowsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    return (
        <div>

            {/* <TypographySmall> Nombre de PNL = {details?.length > 0 ? details?.length : 0}</TypographySmall> */}

            <div className="container mx-auto ">
                <DataTable columns={column} data={currentData?.length > 0 ? currentData : []}  />
            </div>
            <div className='flex justify-between'>
                <BtnShad text="prev" onClick={handlePreviousPage} disabled={currentPage == 1 ? true : false} isLoading={prevLoading} />
                <div>{currentPage}</div>
                <BtnShad text="next" onClick={handleNextPage} isLoading={nextLoading} disabled={currentPage == totalPages ? true : false} />
            </div>

        </div>
    )
}

export default DetailsPnlList
