import { ArrowNarrowLeftIcon, EyeIcon, KeyIcon, PencilAltIcon, PresentationChartLineIcon, ScaleIcon, ShieldCheckIcon } from '@heroicons/react/outline';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DialogBox from '../components/DialogBox';
import IconHoverText from '../components/IconHoverText';
import Loading from '../components/Loading';
import Sidebar from '../components/Sidebar';
import TableauGrid from '../components/TableauGrid';
import TopBar from '../components/TopBar'
import { authSelector } from '../features/authSlice';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import * as sideMenus from "../utils/sideMenus";
import * as service from "../utils/service"
import Evolution from '../components/Evolution';
import { ChevronRight } from '@mui/icons-material';
import Platform from '../components/badge/Platform';
import Strategy from '../components/badge/Strategy';
import Rebalancing from '../components/userDetails/Rebalancing';
import DashboardCard from '../components/cards/DashboardCard';
import MyLineCapital from '../components/chart/MyLineCapital';
import MyLineQty from '../components/chart/MyLineQty';
import ManageSubApi from '../components/ManageSubApi';
import UpdateEmail from '../components/subAccount/UpdateEmail';
import { CodeIcon } from '@heroicons/react/solid';
import ObAi from '../components/ObAi';


function SubAccountScreen(props) {
    const { t } = props;
    const { id } = useParams(); // maka id
    const auth = useSelector(authSelector);
    const navigate = useNavigate();
    const [viewDetails, setViewDetails] = useState("list")
    const [evolutionOpen, setEvolutionOpen] = useState(false);
    const [rebalOpen, setRebalOpen] = useState(false);
    const [subAccountList, setSubAccountList] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [selectedRow, setSelectedRow] = useState(null);
    const [assetSummary, setAssetSummary] = useState({});
    const [totalEvolution, setTotalEvolution] = useState();
    const [openManageInfo, setOpenManageInfo] = useState(false)
    const [selectedRowEmail, setSelectedRowEmail] = useState(null)
    const [values, setValues] = useState({
        actifK: 5000,
        totalK: 1000,
        reserveK: 0,
        recurringK: 150,
    });


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        let urlParam = "/api/users/sub";
        let urlParam2 = "/api/users/getCapitalEvolutionMasterSub"


        if (!auth.role.includes(process.env.REACT_APP_CLIENT)) {
            urlParam = `/api/users/sub?reference=${id}`
            urlParam2 = `/api/users/getCapitalEvolutionMasterSub?reference=${id}`
        }
        const getSubAccountList = async () => {
            try {
                const { data } = await axiosPrivate.get(urlParam, {
                    signal: controller.signal,
                });
                const formatData = data.subUserList.map((resp) => ({
                    ...resp,
                    firstName: service.decryptCBData(resp.firstName),
                    lastName: service.decryptCBData(resp.lastName),
                    email: service.decryptCBData(resp.email),
                    tel: service.decryptCBData(resp.tel),
                }));
                isMounted && setSubAccountList(formatData.filter(f => f.status != 18))
                isMounted && setAssetSummary({
                    totalUsdtSumLastRebal: data.totalUsdtSumLastRebal || 0,
                    totalActiveEnCours: data.totalActiveEnCours || 0,
                    totalReserveEnCours: data.totalReserveEnCours || 0,
                    totalSecuredEnCours: data.totalSecuredEnCours || 0,
                    totalActiveMasterEnCours: data.totalActiveMasterEnCours,
                    totalActiveSubAccountEnCours: data.totalActiveSubAccountEnCours || 0
                })
                console.log("data", data);
                console.log("formatData", formatData.filter(f => f.status != 18));
            } catch (error) {

            }
        }


        const getTotalEvolution = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/rebalancing/findBy/af/${id}`, {
                    signal: controller.signal,
                })
                isMounted && setTotalEvolution(data.filter((f) => f.capital !== null))
            } catch (error) {
                console.log("error", error);
            }
        }

        const getCapitalEvolutionMasterSub = async () => {
            try {
                const { data } = await axiosPrivate.get(urlParam2)
            } catch (error) {
                console.log("error", error);
            }
        }


        getSubAccountList();
        getTotalEvolution()
        // getCapitalEvolutionMasterSub()

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [id])



    const column = [
        {
            field: "id",
            headerName: "id",
            width: 150,
            headerClassName: "dark:text-slate-100",
            // hide: true,
        },
        //Platform
        {
            field: "platform", headerName: t("label.platform"), type: "string", headerClassName: "dark:text-slate-100",
            valueGetter: (params) => {
                let parameter = params.row.platform;
                // console.log("PASADHA", params.row);
                let plat = "";
                if (parameter == 1) {
                    plat = "Binance"
                }
                else if (parameter == 2) {
                    plat = "kraken"
                }
                else {
                    plat = null
                }

                return plat
            },

            renderCell: (params) => {
                return (
                    <>
                        <Platform type={params.row.platform} noBg />
                    </>
                );
            },
        },
        //Strategy
        {
            field: "strategy", headerName: t("label.strategy"), type: "string", headerClassName: "dark:text-slate-100", width: 80,

            valueGetter: (params) => {
                let parameter = params.row.strategy;
                // console.log("STRATTTTTT", params.row);
                let strat = "";
                if (parameter == "ai") {
                    strat = t(`strategy.ai`)
                }
                else if (parameter == "normal") {
                    strat = t(`strategy.cbdt`)
                }
                else {
                    strat = null
                }

                return strat
            },

            renderCell: (params) => {
                return (
                    <>
                        {/* {params.row.strategy == "ai" ? <div className="bg-cyan-500 text-white font-bold text-[9px] p-1 rounded-full text-center">{t(`strategy.ai`)} </div>
                            : params.row.strategy == "normal" ? <div className="bg-teal-600 text-white font-bold text-[9px] p-1 rounded-full text-center">{t(`strategy.cbdt`) } </div>
                                : null} */}
                        <Strategy type={params.row.strategy} />{ }

                    </>
                );
            },
        },

        {
            field: "lastName",
            headerName: t("label.lastName"),
            width: 150,
            headerClassName: "dark:text-slate-100",
        },
        {
            field: "firstName",
            headerName: t("label.firstName"),
            width: 150,
            headerClassName: "dark:text-slate-100",
        },
        {
            field: "email",
            headerName: t("label.email"),
            width: 200,
            headerClassName: "dark:text-slate-100",
        },
        //Last Rebal
        {
            field: "lastRebal",
            headerName: t("label.actifLastRebal"),
            type: "number",
            headerClassName: "dark:text-slate-100",
            width: 150,


            renderCell: (params) => {
                if (params.row.rebalancing && params.row.rebalancing.length > 0) {
                    const totalAssetsObj = JSON.parse(params.row.rebalancing[0]?.totalAssets) || 0;
                    // console.log("pppp", JSON.parse(params.row.rebalancing[0]?.totalAssets).usdt);
                    return (
                        <>
                            {totalAssetsObj.usdt}
                        </>
                    );
                } else {
                    return 0
                }

            },
        },
        // Actif en cours
        {
            field: "actif",
            headerName: `${t(`label.sumActif`)} (${t(`label.inProgress`)})`,
            type: "number",
            headerClassName: "dark:text-slate-100",
            width: 150,


            renderCell: (params) => {
                if (params.row.activeEncours) {
                    const totalAssetsObj = params.row.activeEncours || 0;
                    console.log("pppp", params.row.activeEncours);
                    return (
                        <>
                            {totalAssetsObj}
                        </>
                    );
                } else {
                    return 0
                }
                // return <div>33456</div>

            },
        },
        // Reserve en cours
        {
            field: "reserve",
            headerName: `${t(`label.sumReserve`)} (${t(`label.inProgress`)})`,
            type: "number",
            headerClassName: "dark:text-slate-100",
            width: 150,


            renderCell: (params) => {
                if (params.row.reservedEncours) {
                    const totalAssetsObj = params.row.reservedEncours || 0;
                    return (
                        <>
                            {totalAssetsObj}
                        </>
                    )

                } else {
                    return 0
                }
                // return <div>122,88</div>

            },
        },
        // Secured en cours
        {
            field: "secured",
            headerName: `${t(`label.sumSecured`)} (${t(`label.inProgress`)})`,
            type: "number",
            headerClassName: "dark:text-slate-100",
            width: 150,


            renderCell: (params) => {
                if (params.row.securedEncours) {
                    const totalAssetsObj = params.row.securedEncours || 0;
                    return (
                        <>
                            {totalAssetsObj}
                        </>
                    )

                } else {
                    return 0
                }
                // return <div>1242,88</div>

            },
        },
        // evolution - Réeq - API
        {
            field: "clients",
            headerName: "",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100 ",
            width: 150,

            renderCell: (cellValues) => {
                return (

                    <>
                        <div className="flex gap-x-2 ">

                            {/* Evolution */}
                            <IconHoverText
                                icon={<PresentationChartLineIcon />}
                                classIcon={"w-5 h-5 text-slate-500 hover:text-blue-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                                text={t("label.evolution")}
                                onClick={async (e) => {
                                    setEvolutionOpen(true)
                                    const sub = cellValues.row
                                    // setSelectedRowEmail(null);
                                    setSelectedRow(sub);
                                    setViewDetails("evo")
                                    console.log("subaaaaaaaa", sub);
                                }
                                }
                            />

                            {/* Reeq */}
                            <IconHoverText
                                icon={<ScaleIcon />}
                                classIcon={"w-5 h-5 text-slate-500 hover:text-blue-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                                text={t(`label.rebalancing`)}
                                onClick={async (e) => {
                                    // setOpenManageInfo(true)
                                    const sub = cellValues.row
                                    setSelectedRow(sub)
                                    setViewDetails("rebal")
                                    // setSelectedRowEmail(sub);
                                    console.log("REBAL", sub);
                                }
                                }
                            />
                            {/* API */}
                            {auth.role.includes(process.env.REACT_APP_CLIENT) &&
                                <IconHoverText
                                    icon={<KeyIcon />}
                                    classIcon={"w-5 h-5 text-slate-500 hover:text-blue-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                                    text={"APIs"}
                                    onClick={async (e) => {
                                        setRebalOpen(true)
                                        const sub = cellValues.row
                                        setSelectedRow(sub)
                                        setViewDetails("api")
                                        // setOpenManageInfo(true)
                                        // setSelectedRowEmail(sub);
                                        console.log("REBAL", sub);
                                    }
                                    }
                                />}


                        </div>

                    </>
                );
            },
        },


        //email & password
        auth.role.includes(process.env.REACT_APP_CLIENT) && {
            field: "emailpass",
            headerName: "API",
            headerName: "",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100 ",
            width: 150,

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 text-xs font-medium justify-center w-full hover:text-blue-500 hover:cursor-pointer"
                        onClick={async (e) => {
                            // setRebalOpen(true)
                            const sub = cellValues.row
                            // setSelectedRow(sub)
                            // setViewDetails("api")
                            setOpenManageInfo(true)
                            setSelectedRowEmail(sub);
                            console.log("info", sub);
                        }}>
                        {t(`button.update`)} {t(`label.password`)}


                    </div>
                );
            },
        },
        auth.role.includes(process.env.REACT_APP_SUPPORT) ? {
            field: "vueClients",
            headerName: "",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100 ",
            width: 150,

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 ">
                        {/* Vue Clients */}
                        <IconHoverText
                            icon={<EyeIcon />}
                            classIcon={"w-5 h-5 text-indigo-500 hover:bg-indigo-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Vue Clients"}
                            onClick={(event) => {
                                navigate(`/dashboard/${cellValues.row.id}`);
                            }}
                        />
                        {/* Modifier client */}
                        <IconHoverText
                            icon={<PencilAltIcon />}
                            classIcon={"w-5 h-5 text-purple-500 hover:bg-purple-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Modifier"}
                            onClick={(event) => {
                                navigate(`/support/user/${cellValues.row.id}`);
                            }}
                        />

                    </div>
                );
            },
        } :
            {
                field: "action2",
                headerName: "",
                width: 0,
                headerClassName: "dark:text-slate-100",
                hide: true
            }
        ,

    ]


    return (
        <>
            <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />
            <div className="flex flex-row flex-grow">
                <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />
                <div className="flex flex-row flex-1 mt-2 md:ml-52 ">
                    <div className="flex flex-col w-full p-4 ">
                        <div className="flex-none h-auto">
                            <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.multiAccount`)}</h1>

                            {selectedRow ? null :
                                <div className='flex flex-col xl:flex-row items-center text-sm py-2 space-x-2'>

                                    {/* <div className='flex gap-x-6 flex-1 text-sm '> */}
                                    <div className='flex flex-col'>
                                        <h1 className=''>{t(`label.masterAccount`)}</h1>
                                        <div>
                                            <DashboardCard description={t(`label.inProgress`)} number={assetSummary?.totalActiveMasterEnCours} unit={"USDT"}
                                                bg={"bg-sky-500"}
                                            />
                                        </div>
                                    </div>

                                    <div className=' mt-2 lg:mt-0 flex-1'>
                                        <h1 className=' '>{t(`label.allSubAccount`)} </h1>
                                        <div>
                                            <DashboardCard description={t(`label.inProgress`)} number={assetSummary?.totalActiveSubAccountEnCours} unit={"USDT"}
                                                bg={"bg-sky-500"}
                                            />
                                        </div>
                                        {/* </div> */}

                                    </div>

                                    <div className=' mt-2 lg:mt-0'>

                                        <h1 className='text-sm' >{t(`label.masterAccount`)} & {t(`label.subAccount`)} </h1>
                                        <div className="flex flex-wrap  justify-center lg:justify-end gap-2 lg:gap-x-2  ">
                                            <DashboardCard description={t(`label.actifLastRebal`)} number={assetSummary?.totalUsdtSumLastRebal} unit={"USDT"}
                                                bg={"bg-sky-500"}
                                            />
                                            <DashboardCard description={`${t(`label.sumActif`)} (${t(`label.inProgress`)})`}
                                                number={assetSummary?.totalActiveEnCours}
                                                unit={"USDT"}
                                                bg={"bg-sky-500"}
                                            />
                                            <DashboardCard
                                                description={`${t(`label.sumReserve`)} (${t(`label.inProgress`)})`}
                                                number={assetSummary?.totalReserveEnCours}
                                                unit={"USDT"}
                                                bg={"bg-sky-500"}
                                            />
                                            <DashboardCard
                                                description={`${t(`label.sumSecured`)} (${t(`label.inProgress`)})`}
                                                number={assetSummary?.totalSecuredEnCours}
                                                unit={"USDT"}
                                                bg={"bg-sky-500"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }


                            {selectedRow ?
                                <div>

                                    <div className="flex items-center  ">
                                        <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
                                            <ArrowNarrowLeftIcon className="h-5 w-5 hover:cursor-pointer" onClick={() => setSelectedRow(null)} />
                                        </div>
                                        <div className="font-semibold ml-2">{t("button.back")}</div>
                                        <div className='ml-5 text-sm'> {selectedRow.email} </div>

                                    </div>

                                    <div className='flex items-center gap-x-2'>

                                        <Platform type={selectedRow.platform} />

                                        <Strategy type={selectedRow.strategy} />
                                    </div>

                                    <div className='mt-2'>


                                        {viewDetails && viewDetails == "evo" ?

                                            <Evolution id={selectedRow?.id} /> :

                                            viewDetails == "rebal" ?
                                                <div>
                                                    <Rebalancing t={t} uid={selectedRow?.id} />
                                                </div>

                                                : viewDetails == "api" ?
                                                    <ManageSubApi id={selectedRow?.id} />
                                                    :


                                                    null
                                        }
                                    </div>
                                </div>

                                :

                                <div className="h-[76vh] bg-slate-100 mt-2">

                                    {subAccountList && subAccountList ? <TableauGrid column={column} dataRows={subAccountList && subAccountList} /> : <Loading />}
                                    {/* {subAccountList && subAccountList ? <TableauGrid column={column} dataRows={subAccountList && subAccountList} /> : <div> <ObAi /> </div>} */}
                                    {/* <div className="flex flex-col space-y-5 md:flex-row  md:gap-x-5 md:space-y-0">
                                        <div className=" w-full md:w-1/2 h-[70vh] bg-white dark:bg-slate-800 p-2 rounded">
                                            {totalEvolution ? <MyLineCapital graphType={"logarithmic"} values={values && values} data={totalEvolution && totalEvolution} /> : <Loading />}
                                        </div>
                                        <div className="w-full md:w-1/2 h-[70vh] bg-white dark:bg-slate-800 p-2 rounded">
                                            {totalEvolution ? <MyLineQty graphType={"linear"} values={values && values} data={totalEvolution && totalEvolution} /> : <Loading />}
                                        </div>
                                    </div> */}
                                </div>
                            }

                        </div>
                    </div>
                </div>

                <DialogBox isOpen={openManageInfo} setIsOpen={setOpenManageInfo} title={` ${t(`button.update`)} ${t(`label.password`)}`} customClassName="max-w-md">
                    <UpdateEmail data={selectedRowEmail} setIsOpen={setOpenManageInfo} />
                </DialogBox>

            </div>
        </>
    )
}

export default SubAccountScreen
