import { PartyPopper } from 'lucide-react'
import React, { useState } from 'react'
import { TypographyH3, TypographyH4, TypographySmall } from '../Typo'
import { useTranslation } from 'react-i18next'
import { RadioBtnShad } from '../client/radioBtnShad'
import { useFOrm } from '../useFOrm'
import BtnShad from '../client/btnShadcn'
import { InputShadcn } from '../client/InputShadcn'
import { useToast } from '../ui/use-toast'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

function Acr(props) {
    const { id, handleNextStep } = props
    const { t } = useTranslation()
    const axiosPrivate = useAxiosPrivate()
    const [isAiLoading, setIsAiLoading] = useState(false)
    const [isFinish, setIsFinish] = useState(false)
    const [aiChoice, setAiChoice] = useState(false)
    const { toast } = useToast()
    const [step, setStep] = useState()



    const initialValues = {
        // initialCapital: "",
        recurringAmount: "",
        isRecurring: false,
        confirmDepotOk: false,
        acr: false,
        noAcr: false

    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("recurringAmount" in fieldValues)
            temp.recurringAmount =
                (fieldValues.acr === 1) && (fieldValues.recurringAmount === "" || fieldValues.recurringAmount === null) ?
                    t("error.required") :
                    (!/^-?\d+(\.\d+)?$/.test(fieldValues.recurringAmount) || parseFloat(fieldValues.recurringAmount) < 50) ?
                        t("error.mustBeNumberAndAtLeast50", { amount: 50 }) : "";

        setErrors({
            ...temp,
        });
        console.log("Temp", temp);

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);


    const text = [
        {
            id: 1,
            item: t(`obAi.firstRebal`)
        },
        {
            id: 2,
            item: t(`obAi.nextRebal`)
        },
        {
            id: 3,
            item: t(`obAi.addAcr`)
        },
        {
            id: 4,
            item: t(`obAi.step4`)
        },
        {
            id: 5,
            item: t(`obAi.infoAcr`)
        }

    ]

    const radio = [
        {
            id: 1,
            item: t(`label.acrInput`)
        },
        {
            id: 2,
            item: t(`label.noAcr`)
        }
    ]

    const rec = [
        {
            name: "recurringAmount",
            label: `${t(`label.amount`)} ${t(`obAi.acr`)}`,
            placeholder: `${t(`label.amount`)} ${t(`obAi.acr`)}`,
            id: "recurringAmount",
            DefaultValue: "recurringAmount",
            type: "number"
        }
    ]

    const validateAcr = async () => {
        if (validate()) {
            try {
                setIsAiLoading(true)
                const dataToPut = {
                    subId: id,
                    ...values
                }
                const { data } = await axiosPrivate.put(`/api/onboarding/details?info=acr&subaccount=ia`, dataToPut)
                console.log("Capital", values, data);
                console.log("VIta ny ACR eh");
                setIsFinish(true)
                setAiChoice(true)
                cbdtDone()
                toast({
                    description: t(`info.acrOk`),
                    success: "success",
                })
                setIsAiLoading(false)


            } catch (error) {
                console.log("validate ACR", error);
                toast({
                    description: error,
                    variant: "destructive",
                })
                setIsAiLoading(false)

            }
        }
    }

    const cbdtDone = async () => {
        try {
            setIsAiLoading(true)
            const dataToPut = {
                onboardingStep: 5
            }
            console.log("AI done");
            const { data } = await axiosPrivate.put(`/api/onboarding/obStep?info=step&subId=${id}`, dataToPut)
            // checkStrategy()
            handleNextStep(data.step)
            setIsAiLoading(false)

        } catch (error) {
            console.log("Ai done error",error);
            setIsAiLoading(false)

        }
    }
    return (
        <div className='w-full mt-5 border-2 '>
            <TypographyH3 className="text-center ">   {t(`obAi.acr`)} </TypographyH3>

            {text.map((m) => (
                <div className='mt-2'>

                    <TypographySmall>
                        {m.item}
                    </TypographySmall>
                </div>
            ))}

            <RadioBtnShad option={radio} onChange={handleInputChange} value={values.acr} name="acr" />
            {values.acr == 1 ?
                <div className='flex flex-col md:flex-row md:space-x-2 items-end mt-4'>
                    {rec.map((input, i) => (
                        <InputShadcn
                            labelInput={input.label}
                            type={input.type}
                            name={input.name}
                            onChange={handleInputChange}
                            label={input.label}
                            placeholder={input.placeholder}
                            error={errors[input.name]}
                            value={values[input.name]}
                            min={50}
                            className="w-[24rem] "

                        // readOnly={values.acr == 1}
                        />
                    ))}
                    <BtnShad text={t('button.save')} className='my-2' size={"default"} variant="default" onClick={validateAcr} isLoading={isAiLoading} width={"md:w-40"} disabled={values.recurringAmount ? false : true} />

                </div> :
                <div className='my-10 text-right'>
                    {!isFinish && < BtnShad text={t('button.finish')} size={"default"} variant="default" isLoading={isAiLoading} onClick={cbdtDone} width={"md:w-[24rem]"} disabled={values.acr == 2 ? false : true} />
                    }
                </div>

            }

        </div>
    )
}

export default Acr
