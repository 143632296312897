import React, { useState, useEffect } from "react";
import { useFOrm, Form } from "../useFOrm";
import Controls from "../controls/Controls";
import { decryptCB, specificKeyCode, encryptCB } from "../../utils/service";
import axios from "../../axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { updateUserDetails, userDataSelector } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleIcon, ExternalLinkIcon, XCircleIcon } from "@heroicons/react/solid";
import { PauseIcon, PlayIcon } from "@heroicons/react/outline";
import { yellow } from "@mui/material/colors";
import { authSelector } from "../../features/authSlice";
import * as apiService from "../../utils/apiService"
import BtnShad from "../client/btnShadcn";
import { useToast } from "../ui/use-toast";
import CopyTextButton from "../CopyTextButton";

const isSousCompteItems = [
    { id: true, title: "Oui" },
    { id: false, title: "Non" },
];
const initialFvalues = {
    apiKeyRo: "",
    secureKeyRo: "",
    apiKeyTransfer: "",
    secureKeyTransfer: "",
    apiKeyTrading: "",
    secureKeyTrading: "",
    source_income: "",
    otherSourceOfIncome: "",
    isSousCompte: false,
};
function KeyForm(props) {
    const { data, platform, t, uid } = props;
    console.log("platform", platform);

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isPlayLoading, setIsPlayLoading] = useState(false);
    const [isTestLoading, setIsTestLoading] = useState(false);
    const auth = useSelector(authSelector);
    const axiosPrivate = useAxiosPrivate();
    const { toast } = useToast()

    const [apiExist, setApiExist] = useState();
    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("apiKeyRo" in fieldValues) temp.apiKeyRo = fieldValues.apiKeyRo ? "" : t("error.required");
        if ("secureKeyRo" in fieldValues) temp.secureKeyRo = fieldValues.secureKeyRo ? "" : t("error.required");
        if ("apiKeyTransfer" in fieldValues) temp.apiKeyTransfer = fieldValues.apiKeyTransfer ? "" : t("error.required");
        if ("secureKeyTransfer" in fieldValues) temp.secureKeyTransfer = fieldValues.secureKeyTransfer ? "" : t("error.required");
        if ("apiKeyTrading" in fieldValues) temp.apiKeyTrading = fieldValues.apiKeyTrading ? "" : t("error.required");
        if ("secureKeyTrading" in fieldValues) temp.secureKeyTrading = fieldValues.secureKeyTrading ? "" : t("error.required");
        setErrors({
            ...temp,
        });

        if (fieldValues == values) {
            //values.source_income === "autre" && values.otherSourceOfIncome === "" ? console.log("autre") : console.log("lol")
            //if not null
            return Object.values(temp).every((x) => x == "");

            /*return Object.values(temp).every(([k, v]) => k === "otherSourceOfIncome" || v === ""

                  );*/
        }
    };

    const decryptDataAPI = ["apiKeyRo", "apiKeyTransfer", "apiKeyTrading"];
    const decryptData = ["secureKeyRo", "secureKeyTransfer", "secureKeyTrading"];

    /* for (const key in initialFvalues) {
       //check if exit in array then decrypt it
       if (decryptDataAPI.includes(key)) {
         initialFvalues[key] = decryptCB(data[key]);
       } else {
         initialFvalues[key] = data[key];
       }
     }*/

    initialFvalues.apiKeyRo = decryptCB(data?.apiKeyRo);
    initialFvalues.apiKeyTransfer = decryptCB(data?.apiKeyTransfer);
    initialFvalues.apiKeyTrading = decryptCB(data?.apiKeyTrading);
    initialFvalues.isSousCompte = data.isSousCompte;
    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    const formKeysRo = [
        {
            type: "text",
            name: "apiKeyRo",
            label: t("label.apiKeyRo"),
            placeholder: t("label.apiKeyRo"),
        },
        {
            type: "text",
            name: "secureKeyRo",
            label: t("label.secureKeyRo"),
            placeholder: t("label.secureKeyRo"),
        },
    ];

    const formKeysTransfer = [
        {
            type: "text",
            name: "apiKeyTransfer",
            label: t("label.apiKeyTransfer"),
            placeholder: t("label.apiKeyTransfer"),
        },
        {
            type: "text",
            name: "secureKeyTransfer",
            label: t("label.secureKeyTransfer"),
            placeholder: t("label.secureKeyTransfer"),
        },
    ];

    const formKeysTrading = [
        {
            type: "text",
            name: "apiKeyTrading",
            label: t("label.apiKeyTrading"),
            placeholder: t("label.apiKeyTrading"),
        },
        {
            type: "text",
            name: "secureKeyTrading",
            label: t("label.secureKeyTrading"),
            placeholder: t("label.secureKeyTrading"),
        },
    ];

    const formSousCompte = [
        {
            type: "radio",
            name: "isSousCompte",
            label: t("label.subAccount"),
        },
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleUpdateInfo = async () => {
        setIsLoading(true);
        //delete empty data form input
        let obj = Object.entries(values)
            .filter(([key, value]) => value != null && value !== "")
            .reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});
        //console.log("1", obj)
        const dataToUpdate = {
            isSousCompte: values.isSousCompte,
            uid: uid,
        };

        let finalObjtToUpdate = Object.entries(dataToUpdate)
            .filter(([key, value]) => value != null && value !== "")
            .reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});

        try {
            // checkifExist in database
            const detailsData = await axiosPrivate.get(`/api/details/findBy/${uid}`);
            if (detailsData.data) {
                try {
                    await axiosPrivate.put(`/api/details/${uid}`, finalObjtToUpdate);
                    dispatch(updateUserDetails(finalObjtToUpdate));
                    // toast.success("Details updated");
                    toast({
                        title: `Modification réussie !! `,
                        variant: "success",

                    })
                } catch (error) {
                    // toast.error(error.response?.data.msg);
                    toast({
                        title: error.response?.data.msg,
                        variant: "destructive",

                    })

                }
            } else {
                try {
                    await axiosPrivate.post(`/api/details/create/`, finalObjtToUpdate);
                    dispatch(updateUserDetails(finalObjtToUpdate));
                    // toast.success("Details Added");
                    toast({
                        title: `Création réussie !! `,
                        variant: "success",

                    })

                } catch (error) {
                    toast({
                        title: error.response?.data.msg,
                        variant: "destructive",

                    })
                }
            }
            setIsLoading(false);
        } catch (error) {
            toast.error(error.response?.data.msg);
        }

        toast({
            title: `Modification réussie !! `,
            // description: `Modification réussie !!`,
            variant: "success",

        })
        setIsLoading(false);
        //}
    };

    const handleReset = () => {
        setValues(initialFvalues);
    };
    const [testApiRo, setTestApiRo] = useState(data && data.testApi.ro);
    const [testApiW, setTestApiW] = useState(data && data.testApi.w);
    const [testApiT, setTestApiT] = useState(data && data.testApi.t);
    const [errorApiRo, setErrorApiRo] = useState([]);
    const [errorApiW, setErrorApiW] = useState([]);
    const [errorApiT, setErrorApiT] = useState([]);
    const [play, setPlay] = useState(data?.updateApi);
    const [playRo, setPlayRo] = useState(false);
    const [playTransfer, setPlayTransfer] = useState(false);
    const [playTrading, setPlayTrading] = useState(false);

    const updateApi = async (api, uid) => {
        try {
            const dataToUpdate = { updateApi: api };
            await axiosPrivate.put(`/api/manage/updateApi/${uid}`, dataToUpdate);
            console.log("APII iza ?? +++++++ ", dataToUpdate);
        } catch (error) {
            console.log(error, "error put api");
            toast.error("Error");
        }
    };

    const handlePlay = async (api) => {
        console.log("ato ary");
        setIsPlayLoading(true);
        try {
            await updateApi(api, uid);

            setPlay(api);
        } catch (error) {
            toast.error("Error");
        }
        setIsPlayLoading(false);
    };

    const handleTestApiRoOriginal = async () => {
        try {
            setIsTestLoading(true);
            const response = await axiosPrivate.get(`/api/details/testro/${uid}`);
            console.log("RO", response);
            if (response.status === 200) {
                setTestApiRo(true);
                const dataToUpdate = {
                    ...data.testApi,
                    ro: true,
                };
                await axiosPrivate.put(`/api/details/${uid}`, { testApi: dataToUpdate });
                dispatch(updateUserDetails({ testApi: dataToUpdate }));
                //setErrorApiRo(response.data);
            }
        } catch (error) {
            console.log("RO", error.response.status);
            setErrorApiRo(JSON.stringify(error.response.data));
            setTestApiRo(false);
            const dataToUpdate2 = {
                ...data.testApi,
                ro: false,
            };
            await axiosPrivate.put(`/api/details/${uid}`, { testApi: dataToUpdate2 });
            dispatch(updateUserDetails({ testApi: dataToUpdate2 }));
        }
        setIsTestLoading(false);
    };

    const handleTestApiRo = async () => {
        try {
            setErrorApiRo([])
            setIsTestLoading(true);
            let { statusApi, response } = await apiService.testApi("ro", uid)
            setTestApiRo(statusApi);
            console.log("____ RO", response.data);
            const resp = response.data.filter((r) => !r.isIpWorking)
            if (resp.length > 0) {
                setErrorApiRo(resp);
                console.log("resp", resp.length, resp);
            }
        } catch (error) {
            console.log("Err Test RO", error);

            setTestApiRo(false)
            setErrorApiRo(error.response.data);
        }
        setIsTestLoading(false);
    };

    const handleTestApiW = async () => {

        try {
            setErrorApiW([])
            setIsTestLoading(true);
            let { statusApi, response } = await apiService.testApi("w", uid)

            const resp = response.data.filter((r) => !r.isIpWorking)
            console.log("response Wwwwwwww", resp)
            if (resp.length > 0) {
                setErrorApiW(resp);
            }
            setTestApiW(statusApi);
        } catch (error) {
            console.log("Err Test W", error);
            setTestApiW(false);
            setErrorApiW(error.response.data);

        }
        setIsTestLoading(false);
    };

    const handleTestApiT = async () => {

        try {
            setErrorApiT([])
            setIsTestLoading(true);
            let { statusApi, response } = await apiService.testApi("t", uid)
            setTestApiT(statusApi);

            const resp = response.data.filter((r) => !r.isIpWorking)

            if (resp.length > 0) {
                setErrorApiT(resp);
            }
        } catch (error) {
            console.log("Err Test T", error);
            setTestApiT(false);
            setErrorApiT(error.response.data);

        }
        setIsTestLoading(false);
    };



    const testApiDev = async () => {
        try {
            const { data } = await axiosPrivate.get(`/api/admin/client-ok/${uid}`)
            console.log("TEST DEV", data);
        } catch (error) {
            console.error("testApiDev", error);
        }
    }

    const testWsurRO = async () => {
        try {
            const response = await axiosPrivate.get(`/api/lastbalance/fromMasterw/${uid}`);
            console.log("from MAster W", response.data);
        } catch (error) {
            console.error("testWsurRO", error);
        }
    }

    const equivIp = [
        { public: "196.46.57.108", private: "10.47.148.11" },
        { public: "196.46.57.165", private: "10.47.148.21" },
        { public: "196.46.57.167", private: "10.47.148.31" },
        { public: "196.46.57.168", private: "10.47.148.41" },
        { public: "196.46.57.109", private: "10.47.148.12" },
        { public: "196.46.57.169", private: "10.47.148.22" },
        { public: "196.46.57.170", private: "10.47.148.32" },
        { public: "196.46.57.171", private: "10.47.148.42" },
    ]

    const ErrorMessages = ({ errors }) => {

        if (errors?.length > 0) {
            const renderErrorMsg = (error) => {
                if (error.type === 'w') {
                    // Dynamically parse and display the error messages for each network (BSC, TRX, etc.)
                    return Object.keys(error.errorMsg).map((network) => {
                        let parsedError = "";
                        if (data.platform == 2) {
                            parsedError = error.errorMsg[network] || '{}'
                        } else {
                            parsedError = JSON.parse(error.errorMsg[network] || '{}');
                        }

                        return (
                            <div key={network}>
                                <strong>Network {network} Error:</strong> {parsedError.msg || 'Unknown error'} <br />
                            </div>
                        );
                    });
                } else {
                    return (
                        <div>
                            <strong>Reason:</strong> {error.errorMsg?.msg || 'Unknown error'} <br />
                        </div>
                    );
                }
            };
            return (
                <div id="error-messages" className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-2" >
                    {errors?.filter(error => !error?.isIpWorking).map((error, index) => (
                        <div key={index}

                            className="bg-white shadow-md p-4 rounded-lg w-full"
                            style={{ minWidth: '250px' }}
                        >
                            <strong>IP:</strong>
                            {equivIp.find((e) => e.private == error.ip) ? equivIp.find((e) => e.private == error.ip).public : error.ip}
                            {/* {error.ip} */}
                            <br />
                            <strong>Status:</strong> {error.isIpWorking ? 'Working' : 'Not Working'} <br />
                            {/* {!error.isIpWorking && (
                                <div>
                                    <strong>Code:</strong> {error.errorMsg?.code || 'Unknown code'}
                                    <strong>Reason:</strong> {error.errorMsg?.msg || 'Unknown error'}
                                </div>
                            )} */}
                            {!error.isIpWorking && renderErrorMsg(error)}
                        </div>
                    ))}
                </div>
            );
        }
        return null

    };

    return (
        <>
            <Form onSubmit={handleSubmit} noValidate>
                <div className="flex flex-col p-4 space-y-3">
                    <div className="flex justify-between space-y-3 ">
                        <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
                            {formSousCompte.map((item, i) => {
                                return (
                                    <Controls.RadioGroup
                                        items={isSousCompteItems}
                                        key={i}
                                        label={item.label}
                                        value={values[item.name] || false}
                                        onChange={handleInputChange}
                                        name={item.name}
                                        error={errors[item.name]}
                                    />
                                );
                            })}
                        </div>
                        {/* <div className="flex justify-end"> */}
                        <BtnShad text={t(`button.save`)}
                            onClick={() => handleUpdateInfo()}
                            isLoading={isLoading}
                            disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                            sendHistory={{ action: `isSousCompte : ${values?.isSousCompte} `, who: `${uid}` }}
                        />

                    </div>

                    <hr />

                    <div className="flex flex-col w-fit gap-y-2">
                        <div className="font-semibold text-sm">Configuration API</div>

                        <BtnShad
                            disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                            text={play == "ON" ? "ON" : "OFF"}
                            variant={play == "ON" ? "success" : "destructive"}
                            isLoading={isPlayLoading}
                            onClick={() => handlePlay(play == "ON" ? null : "ON")}
                            sendHistory={{ action: `Configuration API :  ${play == "ON" ? "turn Off" : "Turn ON"} `, who: `${uid}` }}
                        />
                        {auth.id == "87e4efd1-376f-4741-8cc7-76b771b05b76" &&
                            <div className="flex ">

                                <BtnShad
                                    text={"test"}
                                    isLoading={isPlayLoading}
                                    onClick={testApiDev}
                                />
                                <BtnShad
                                    text={"test W sur RO"}
                                    isLoading={isPlayLoading}
                                    onClick={testWsurRO}
                                />
                            </div>
                        }
                    </div>
                    <hr />

                    <div className="flex flex-col  space-y-3">
                        <div className="flex gap-x-2">
                            <h6 className="flex-none font-semibold text-sm">Crypto-bulot-RO</h6>
                            {testApiRo ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />}
                        </div>

                        <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">

                            {formKeysRo.map((item, i) => {
                                return (
                                    <>
                                        <Controls.Input
                                            fullWidth={true}
                                            key={i}
                                            // disabled={disabled}
                                            // readOnly={auth.role.includes(process.env.REACT_APP_ADMIN, process.env.REACT_APP_SUPPORT, process.env.REACT_APP_SUPADMIN) ? true : false}
                                            readOnly={true}
                                            label={item.label}
                                            value={values[item.name] || ""}
                                            onChange={handleInputChange}
                                            name={item.name}
                                            type={item.type}
                                            onKeyDown={(event) => {
                                                specificKeyCode(event);
                                            }}
                                            error={errors[item.name]}
                                        />
                                    </>
                                );
                            })}

                            <BtnShad
                                disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                text="Tester"
                                variant={'warning'}
                                isLoading={isTestLoading}
                                onClick={() => handleTestApiRo()}
                                sendHistory={{ action: ` Test API RO  `, who: `${uid}` }}
                            />
                        </div>
                        <div className="text-xs text-red-500">
                            <ErrorMessages errors={errorApiRo && errorApiRo} />
                        </div>
                    </div>

                    <div className="flex flex-col  space-y-3">
                        <div className="flex gap-x-2 ">
                            <h6 className="flex-none font-semibold text-sm">Crypto-bulot-W</h6>
                            {testApiW ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />}
                        </div>

                        <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">

                            {formKeysTransfer.map((item, i) => {
                                return (
                                    <>
                                        <Controls.Input
                                            fullWidth={true}
                                            key={i}
                                            // disabled={disabled}
                                            readOnly={true}
                                            label={item.label}
                                            value={values[item.name] || ""}
                                            onChange={handleInputChange}
                                            name={item.name}
                                            type={item.type}
                                            onKeyDown={(event) => {
                                                specificKeyCode(event);
                                            }}
                                            error={errors[item.name]}
                                        />
                                    </>
                                );
                            })}

                            <BtnShad
                                disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                text="Tester"
                                variant={'warning'}
                                isLoading={isTestLoading}
                                onClick={() => handleTestApiW()}
                                sendHistory={{ action: ` Test API W  `, who: `${uid}` }}
                            />
                        </div>
                        <div className="flex gap-x-3">
                            {/* <div className="text-xs">
                {" "}
                 {errorApiW && errorApiW}
                code: -4028, msg: 'Withdrawal amount must be greater than the transaction fee.'
              </div> */}
                            <div className="text-xs text-red-400">
                                <ErrorMessages errors={errorApiW && errorApiW} />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col  space-y-3">
                        <div className="flex gap-x-2 ">
                            <h6 className="flex-none font-semibold text-sm">Crypto-bulot-T</h6>
                            {testApiT ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />}
                        </div>

                        <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
                            {/* <Controls.Button
                disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                icon={play == "T" ? <PlayIcon className="w-6  h-6 rounded-xl p-" /> : <PauseIcon className="w-6  h-6 rounded-xl p-" />}
                color={play == "T" ? "bg-green-400" : "bg-red-400"}
                xsFont
                isLoading={isLoading}
                onClick={() => handlePlay(play == "T" ? null : "T")}
              /> */}
                            {formKeysTrading.map((item, i) => {
                                return (
                                    <>
                                        <Controls.Input
                                            fullWidth={true}
                                            key={i}
                                            // disabled={disabled}
                                            readOnly={true}
                                            label={item.label}
                                            value={values[item.name] || ""}
                                            onChange={handleInputChange}
                                            name={item.name}
                                            type={item.type}
                                            onKeyDown={(event) => {
                                                specificKeyCode(event);
                                            }}
                                            error={errors[item.name]}
                                        />
                                    </>
                                );
                            })}
                            {/* <Controls.Button
                                disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                text="Tester"
                                color={"bg-yellow-400"}
                                isLoading={isTestLoading}
                                xsFont
                                onClick={() => handleTestApiT()}
                            /> */}
                            <BtnShad
                                disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                text="Tester"
                                variant={'warning'}
                                isLoading={isTestLoading}
                                onClick={() => handleTestApiT()}
                                sendHistory={{ action: ` Test API T  `, who: `${uid}` }}
                            />

                        </div>

                        <div className="text-xs text-red-500">
                            <ErrorMessages errors={errorApiT && errorApiT} />
                        </div>
                    </div>


                </div>
            </Form>
            <hr />

            <div className="p-4">
                <div className="flex ">

                    <div className="text-xs font-bold bg-blue-300 w-fit px-2 rounded-md"> IP à Whitelister</div>
                    {/* <CopyTextButton text={
                        "196.46.57.108 196.46.57.165 196.46.57.167 196.46.57.168 196.46.57.109 196.46.57.169 196.46.57.170 196.46.57.171 82.165.54.146 212.227.11.132 82.165.236.139 93.90.192.83 212.227.215.195 82.165.123.110 82.165.64.224 93.90.192.21"
                    } /> */}
                    <CopyTextButton text={
                        "102.67.137.226 102.67.137.227 102.67.137.228 102.67.137.229 102.67.137.230 196.46.57.108 196.46.57.165 196.46.57.167 196.46.57.168  196.46.57.109 196.46.57.169 196.46.57.170 196.46.57.171 82.165.54.146 212.227.11.132 82.165.236.139 93.90.192.83 212.227.215.195 82.165.123.110 82.165.64.224 93.90.192.21 102.67.137.234 102.67.137.235 102.67.137.236 102.67.137.237 102.67.137.238"
                    } />
                </div>
                <div className="flex flex-col text-xs font-semibold">
                    <div>
                        196.46.57.108 196.46.57.165 196.46.57.167 196.46.57.168 196.46.57.109 196.46.57.169 196.46.57.170 196.46.57.171
                    </div>
                    <div>
                        82.165.54.146 212.227.11.132 82.165.236.139 93.90.192.83 212.227.215.195 82.165.123.110 82.165.64.224 93.90.192.21
                    </div>
                    {/* <CopyTextButton/> */}

                    {/* <span>212.227.215.195</span>
                    <span>82.165.54.146</span>
                    <span>196.46.57.108</span>
                    <span>196.46.57.109</span> */}
                </div>
                <div className="flex flex-col text-xs font-semibold">
                    102.67.137.226 102.67.137.227 102.67.137.228 102.67.137.229 102.67.137.230 196.46.57.108 196.46.57.165 196.46.57.167 196.46.57.168 196.46.57.109 196.46.57.169 196.46.57.170 196.46.57.171 82.165.54.146 212.227.11.132 82.165.236.139 93.90.192.83 212.227.215.195 82.165.123.110 82.165.64.224 93.90.192.21 102.67.137.234 102.67.137.235 102.67.137.236 102.67.137.237 102.67.137.238
                </div>
            </div>
        </>
    );
}

export default KeyForm;
