import React, { useEffect, useState } from 'react'
import { TypographyH3, TypographyP, TypographySmall, TypographyXS } from '../Typo'
import { useTranslation } from 'react-i18next'
import { LinkTuto } from '../LinkTuto'
import { useFOrm } from '../useFOrm'
import { InputShadcn } from '../client/InputShadcn'
import { CheckShad } from '../client/CheckShad'
import BtnShad from '../client/btnShadcn'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useToast } from '../ui/use-toast'
import useBinancePrice from '../../hooks/useBinancePrice'
import { CircleAlert, TriangleAlert } from 'lucide-react'
import { addTagToMailerLite } from '../../lib/utils'
import { useSelector } from 'react-redux'
import { authSelector } from '../../features/authSlice'

function DepotSubAutonome(props) {
    const { id, handleNextStep } = props
    const { t } = useTranslation()
    const auth = useSelector(authSelector);

    const [isVideoOpen, setIsVideoOpen] = useState(false)
    const [user, setUser] = useState()
    const [info, setInfo] = useState()
    const [forceOk, setForceOk] = useState(false)
    const [amount, setAmount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const axiosPrivate = useAxiosPrivate()
    const { toast } = useToast()
    const [isOk, setIsOk] = useState(false)
    const [minK, setMinK] = useState(false)
    const rate = useBinancePrice("EURUSDT");


    useEffect(() => {
        fetch(`${process.env.REACT_APP_INFO}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // console.log("jsonData.........................1......................", response.json());

                return response.json();
            })
            .then(jsonData => {
                // Access the day1 property from your JSON and update the state
                // console.log("jsonData+++++++++++++++++++++++++++.", jsonData);
                setInfo(jsonData);
            })
            .catch(error => {
                console.error('Error fetching the JSON file:', error);
            });
    }, []);

    const initialValues = {
        initialCapital: "",
        confirmDepotOk: false,
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        // if ("initialCapital" in fieldValues) fieldValues.initialCapital < 500 ? t(`error.minAmount`) : temp.initialCapital = fieldValues.initialCapital ? "" : t("error.required");

        if ("initialCapital" in fieldValues) {
            if (fieldValues.initialCapital === "" || fieldValues.initialCapital === null) {
                temp.initialCapital = t("error.required");
            } else if (parseFloat(fieldValues.initialCapital) < 500) {
                temp.initialCapital = t("error.mustBeNumberAndAtLeast50", { amount: 500 });
            } else {
                temp.initialCapital = "";
            }
        }

        setErrors({
            ...temp,
        });
        console.log("Temp", temp);

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);


    const aff = [
        {
            name: "initialCapital",
            label: t(`label.initialCapital`),
            placeholder: 500,
            id: "initialCapital",
            DefaultValue: "initialCapital",
            type: "number"
        }
    ]

    const handleSubmit = async () => {

        try {
            setIsLoading(true)
            const dataToPut = {
                subId: id,
                ...values
            }
            const { data } = await axiosPrivate.put(`/api/onboarding/details?info=capital&subaccount=ia`, dataToPut)
            // setUser({ ...user, details: data.idDetails })

            const response = await axiosPrivate.get(`/api/onboarding/balance?info=ob&subId=${id}`)
            const dataTotal = response.data?.totalUsdt
            setAmount({
                usdt: dataTotal,
                // eur: dataTotal?.eur
            })
            console.log("amount", dataTotal);
            //le montant minimum d'investissement et de 500 usdt
            if (dataTotal <= 500) {
                toast({
                    description: t('info.minimumAmount', { amount: dataTotal }),
                    variant: "destructive"
                })

                const dataToSendMailerlite = { id: auth.id, gname: "Done_AI" }
                await addTagToMailerLite(dataToSendMailerlite)
                console.log("LAsa le tag", dataToSendMailerlite);


                setMinK(true)
            } else if (dataTotal < values.initialCapital && dataTotal >= 500) {
                setForceOk(true)
            } else {
                setIsOk(true)
                const dataToPut = {
                    onboardingStep: 4
                }
                const { data } = await axiosPrivate.put(`/api/onboarding/obStep?info=step&subId=${id}`, dataToPut)
                handleNextStep(dataToPut.onboardingStep)
            }

            console.log("ooopp", response);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error("handleSubmit", error)
            toast({
                description: t('error.4001'),
                variant: "destructive"
            })
        }
    }

    const handleForceOK = async () => {
        try {
            setIsLoading(true)
            console.log("FORCE amzay aru");
            const response = await axiosPrivate.get(`/api/onboarding/balance?info=ob&subId=${id}`)
            const dataToPut = {
                onboardingStep: 4
            }
            const { data } = await axiosPrivate.put(`/api/onboarding/obStep?info=step&subId=${id}`, dataToPut)
            handleNextStep(dataToPut.onboardingStep)
            setIsLoading(false)
        } catch (error) {
            console.error("error force OK", error)
            setIsLoading(false)

        }
        // const price = averagePricePublic("EURUSDT")
        // console.log("ppp", price);
    }

    return (
        <div className='mx-2 '>
            <TypographyH3 className={"text-center"}>{t(`step.depotVerif`)}</TypographyH3>
            <div className='flex flex-col '>
                <TypographySmall>  {t(`obAi.step3`)} </TypographySmall>
                <div className='flex flex-col mt-4 border-2  border-yellow-500 p-2 rounded-md'>
                    {/* <div>
                        <TriangleAlert className=' text-yellow-500 h-5 ' />
                    </div> */}
                    <TypographySmall>  {t(`obAi.warningDepot`)} </TypographySmall>
                    <TypographyXS>  * {t(`obAi.minK`)} </TypographyXS>
                </div>


                <div className='text-left mt-3 '>
                    <TypographySmall>{t(`linkTuto.tuto`)}</TypographySmall>
                    <LinkTuto text={t(`linkTuto.alimentationSubAccount`)} fit setIsVideoOpen={setIsVideoOpen} isVideoOpen={isVideoOpen} url={info?.depotSubAccount}
                        title={t(`linkTuto.alimentationSubAccount`)} />
                    <LinkTuto
                        text={t(`linkTuto.stackinsat`)}
                        fit type="pdf"

                        pdfLink={info?.stackinsat}
                        title={t(`linkTuto.stackinsat`)}
                    />


                </div>
                <div className='mt-4'>
                    <TypographySmall>{t(`label.depotOk`)} : </TypographySmall>
                </div>
                {/* <div className='w-full flex flex-col items-center mt-4'> */}

                {aff.map((input, i) => (
                    <InputShadcn
                        labelInput={input.label}
                        type={input.type}
                        name={input.name}
                        onChange={handleInputChange}
                        label={input.label}
                        placeholder={input.placeholder}
                        error={errors[input.name]}
                        value={values[input.name]}
                        min={20}
                        className="w-[24rem] "
                    />
                ))}
                <CheckShad text={t(`label.confirmDepotOk`)} onChange={handleInputChange} name={"confirmDepotOk"} />
                {/* </div> */}
            </div>

            <div className='text-right mt-6'>

                {!minK && !forceOk && <BtnShad className="w-40 text-right" text={t('button.save')} isLoading={isLoading} size={"default"} variant="default" onClick={handleSubmit} disabled={values.initialCapital && values.confirmDepotOk ? false : true} />
                }
            </div>
            {forceOk &&
                <div className='flex flex-col items-center mt-4 border-2 border-yellow-500 p-2 rounded-md'>
                    <TypographyP>{t(`info.KlessthanBalanceNow`, { amount: (amount?.usdt * rate).toFixed(1) })}</TypographyP>

                    <BtnShad className="w-1/2" isLoading={isLoading} text={t(`button.forceOK`)} onClick={handleForceOK} icon={<CircleAlert className="h-4" />} variant={"warning"} />

                </div>
            }
            {minK &&
                <div className='flex flex-col items-center mt-4 border-2 border-red-500 p-2 rounded-md'>
                    <TypographyP>{t(`info.minK`, { amount: (amount?.usdt * rate).toFixed(1) })}</TypographyP>
                </div>}
        </div>
    )
}

export default DepotSubAutonome
