import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Sidebar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import moment from "moment";
import "moment/locale/fr";
//import { w3cwebsocket as W3CWebSocket } from "websocket";
import Title from "../title/Title";
import CapitalChart from "../chart/CapitalChart";
import TopBar from "../TopBar";
import Table from "../table/Table";
import { Icon } from "coinmarketcap-cryptocurrency-icons";
import Users from "../Users";
import Controls from "../controls/Controls";
import { useNavigate, useLocation } from "react-router-dom";
import * as sideMenus from "../../utils/sideMenus";
import { getallUsers } from "../../features/listUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../features/authSlice";

import { setMonitoringUsers, selectUsersWithProblems } from "../../features/monitoringSlice";
import Loading from "../Loading";
import TicketCard from "../cards/TicketCard";
import { setFollowUp, selectFollowUpCard } from "../../features/followSlice";
import TicketsScreen from "../../screens/TicketsScreen";
import { selectPlanitCardToday, setList } from "../../features/planitSlice";
import ConnectedUser from "../../screens/ConnectedUser";
import SwitchPlatform from "../SwitchPlatform";
//import SocketIOClient from "socket.io-client";
//import { connect, disconnect, socketSelector } from '../../features/socketSlice'

// import SupTicketsScreen from "../../screens/SupTicketsScreen";
moment.locale("fr");
const Myth = ({ text }) => <th className="p-3 text-sm font-semibold tracking-wide text-left"> {text}</th>;

const Mytd = ({ text }) => <td className="p-3 text-sm text-gray-700 whitespace-nowrap"> {text}</td>;
function DashboardSupport(props) {
    const { t } = props;
    const [showTicket, setShowTicket] = useState(false);
    const [uidSelected, setUidSelected] = useState();
    const [isSwitch, setIsSwitch] = useState(false);
    const [search, setSearch] = useState();
    const auth = useSelector(authSelector)

    const axiosPrivate = useAxiosPrivate();
    const endPoint = `wss://stream.binance.com:9443/ws/eurusdt@trade`;
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const usersWithProblems = useSelector(selectUsersWithProblems);
    const followUpCards = useSelector(selectFollowUpCard);

    const todayCard = useSelector(selectPlanitCardToday)

    // console.log("yyyyyy", todayCard.length)

    /*const socket = useSelector(socketSelector);

    const handleCustomEvent = (data) => {
      console.log("received: ", data);
    }
    useEffect(() => {
      if (!socket) {
        dispatch(connect({ url: process.env.REACT_APP_BASE_URL_SOCKET }));
      }

      return () => {
        if (socket) {
          dispatch(disconnect());
        }
      };
    }, [socket, dispatch]);

    useEffect(() => {
      if (socket) {
        socket.on('all_users', handleCustomEvent);
        socket.emit('new_user', "sisisewrerew")
        return () => {
          socket.off('all_users', handleCustomEvent);
        }
      }
    }, [socket])*/

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getList = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/planit/list/findBy/4aa03c5f-2b8a-11ee-99ee-00155d821527`, { signal: controller.signal });
                // console.log("=============", data)
                isMounted && dispatch(setList(data))

            } catch (error) {
                console.log(error)
            }
        }

        const getAllTickets = async () => {
            try {
                const response = await axiosPrivate.get(`/api/ticket/findAll`, {
                    signal: controller.signal,
                });
                //console.log("tttttttttttttttttttttrrrrrrrrr", response.data);
                // isMounted && setAllTickets(response.data.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt)));
                isMounted && dispatch(setFollowUp(response.data.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt))));
            } catch (error) {
                if (error.response?.status != 403) {
                    navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };
        //getReportingUsers();
        getAllTickets();
        getList();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const handleOpenBasculer = async () => {
        setIsSwitch(!isSwitch);
    };

    const filtered = !search ? followUpCards : followUpCards.filter((card) => card.u.email.toLowerCase().includes(search.toLowerCase()));

    return (
        <>
            <TopBar menus={sideMenus.menusSupport(t, todayCard.length)} />
            <div className="flex justify-center items-center md:hidden h-full w-full">
                <h3>Please use desktop to view this page</h3>
            </div>
            <div className=" hidden md:flex flex-row flex-grow ">
                <Sidebar menus={sideMenus.menusSupport(t, todayCard.length)} hide={showTicket} />
                <div className={`flex flex-row flex-auto ${showTicket ? "md:ml-0" : "md:ml-52"} overflow-hidden  `}>
                    {/* <div className={`${auth.role.includes(process.env.REACT_APP_SUPPORT) ? 'flex-1':""}`}> */}

                    <div className={`${showTicket ? "-translate-x-full md:w-0" : "md:w-full flex flex-col translate-x-0"} transition-all duration-500 ease-in-out transfomr-gpu`}>
                        <div className={`${showTicket ? "hidden" : "flex-none h-auto mt-2 px-4"}`}>
                            <h1 className="font-semibold text-2xl text-slate-900 dark:text-white">{t("label.client")}</h1>
                            <span className="text-xs text-slate-500 dark:text-slate-400 ">{moment().format("Do MMMM YYYY")}</span>

                            <div className="m-2 flex space-x-2 justify-end">
                                <Controls.Button text={t("button.addClient")} small onClick={() => navigate("/support/lb")} xsFont />
                                <Controls.Button text="Basculer" color="bg-lime-500" small onClick={() => setIsSwitch(!isSwitch)} xsFont />
                            </div>
                        </div>
                        <div className={`${isSwitch ? "block" : "hidden"} bg-slate-100 m-1 p-4 transition-all duration-500 ease-in-out`}>
                            <SwitchPlatform t={t} />
                        </div>
                        {auth?.id == "87e4efd1-376f-4741-8cc7-76b771b05b76" ? <ConnectedUser /> : null}
                        <div className={`${showTicket ? "hidden" : " flex-auto space-y-4 mt-4 p-4  overflow-y-auto  bg-slate-100 dark:bg-slate-800"} transition-all duration-500 ease-in-out`}>
                            <Users t={t} />
                        </div>

                        <div className="flex-none h-10 lg:h-20  xl:h-32 "></div>
                    </div>
                    {/* </div> */}


                    {/* Ancien ticket */}
                    {/* {auth.role.includes(process.env.REACT_APP_SUPPORT) ? null : <div>
                        <div className="hidden md:flex md:w-1/5 flex-col bg-slate-300">
                            <div className="flex-none h-auto mt-2 mx-4">
                                <div className="flex justify-between items-center ">
                                    <div className="font-semibold text-xl text-slate-900 dark:text-white">{"Suivi"}</div>
                                    <ArrowLeftIcon
                                        className={`${showTicket ? "rotate-180" : "transform-none"
                                            } h-6 w-6 transition-transform duration-500 ease-in-out hover:cursor-pointer hover:bg-blue-100 hover:scale-150 p-1 rounded-full`}
                                        onClick={() => setShowTicket(!showTicket)}
                                    />
                                </div>
                                <div>
                                    <input
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder={"Search..."}
                                        type="text"
                                        id="last_name"
                                        className="text-xs bg-gray-50 border border-gray-300 text-gray-900 rounded-lg !focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    />
                                </div>
                            </div>
                            <div className="flex-auto space-y-4 mt-4 p-4  overflow-y-auto scrollbar">
                                <div className=" flex-none h-40 space-y-2 ">
                                    {usersWithProblems ? usersWithProblems.map((u, i) => <NotificationCard data={u} t={t} />) : <Loading />}

                                    {filtered && filtered.map((a, i) => <TicketCard key={i} data={a} setUidSelected={setUidSelected} />)}
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className={`${showTicket ? "md:w-4/5 mt-4 block" : "w-0 hidden"} `}>
                        <TicketsScreen setUidSelected={setUidSelected} uidSelected={uidSelected} />
                    </div> */}

                </div>
            </div>
        </>
    );
}

export default DashboardSupport;
