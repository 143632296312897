import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Toaster } from "./components/ui/toaster"
import { useTranslation } from "react-i18next";
// import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "react-toastify/dist/ReactToastify.css";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import LoginScreen from "./screens/LoginScreen";
// admin
import DashboardScreen from "./screens/DashboardScreen";
import UsersScreen from "./screens/UsersScreen";
import Payment from "./components/admin/Payment";
import OnePayment from "./components/admin/OnePayment";
import Transactions from "./components/admin/Transactions";

import AddUser from "./components/support/AddUser";
import UnauthorizedScreen from "./screens/UnauthorizedScreen";

//support
import DashboardSupport from "./components/support/DashboardSupport";
import OneUser from "./components/support/OneUser";

// Clients
import ClientScreen from "./screens/ClientScreen";
import ClientRebalancingScreen from "./screens/ClientRebalancingScreen";
import ParrainageScreen from "./screens/ParrainageScreen";
import HistoryScreen from "./screens/HistoryScreen";

import ClientTransactionScreen from "./screens/ClientTransactionScreen";

//Forgot password
import ForgotPassword from "./components/forgotPasword/ForgotPassword";
import EnterToken from "./components/forgotPasword/EnterToken";
import ResetPassword from "./components/forgotPasword/ResetPassword";
import PageNotFound from "./components/pageNotFound";

import Layout from "./components/Layout";
import TestScreen from "./screens/TestScreen";
import OnboardingScreen from "./screens/OnboardingScreen";
import RessourcesScreen from "./screens/RessourcesScreen";
import OnboardingDetailsScreen from "./screens/OnboardingDetailsScreen";
import SettingScreen from "./screens/SettingScreen";
import SecureScreen2 from "./screens/SecureScreen2";
import CpScreen from "./screens/CpScreen";
import EvolutionScreen from "./screens/EvolutionScreen";
import TicketsScreen from "./screens/TicketsScreen";
import SuiviOnboardingScreen from "./screens/SuiviOnboardingScreen";
import ClientSecure from "./screens/ClientSecure";
import MaintenanceScreen from "./screens/MaintenanceScreen";
import ClientProfileScreen from "./screens/ClientProfileScreen";
import AdminClientAnalytics from "./screens/AdminClientAnalytics";
import SimulatorScreenAdmin from "./screens/SimulatorScreenAdmin";
import DashboardSupScreen from "./screens/DashboardSupScreen";
import AdminPnlChurn from "./screens/AdminPnlChurn";
import IndexScreenAdmin from "./screens/IndexScreenAdmin";
import SocketIOClient from "socket.io-client";
import IndexScreenUser from "./screens/IndexScreenUser";
import axios from "./axios";
import AuthSettingScreen from "./screens/AuthSettingScreen";
import AllUsersScreen from "./screens/AllUsersScreen";
import BulotAbsoluScreen from "./screens/BulotAbsoluScreen";
import UserApiScreen from "./screens/UserApiScreen";
import AdminManageResources from "./screens/AdminManageResources";
import WalletScreen from "./screens/WalletScreen";
import TeamScreen from "./screens/TeamScreen";
import BoardScreen from "./screens/BoardScreen";
import PlanitListScreen from "./screens/PlanitListScreen";
import ContactUsScreen from "./screens/ContactUsScreen";
import TicketAnalyticScreen from "./screens/TicketAnalyticScreen"

import { connect, disconnect, socketSelector } from './features/socketSlice'

import { QueryClient, QueryClientProvider } from "react-query";
import { useSelector } from "react-redux";
import { authSelector } from "./features/authSlice";
import { useDispatch } from "react-redux";
import { decryptCBData } from "./utils/service";
import ConnectedUser from "./screens/ConnectedUser";
import socket from "./utils/socket";
import { setOnlineMember } from "./features/onlineMemberSlice";
import HeritageScreen from "./screens/HeritageScreen";
import SubAccountScreen from "./screens/SubAccountScreen";
import ProductScreen from "./screens/ProductScreen";
import SubscriptionScreen from "./screens/SubscriptionScreen";
import TransactionScreen from "./screens/TransactionScreen";
import WaittingStopScreen from "./screens/WaittingStopScreen";
import PnlUserScreen from "./screens/PnlUserScreen";
import PauseScreen from "./screens/PauseScreen";
import PnlTable from "./screens/PnlTable";
import CouponScreen from "./screens/CouponScreen";
import ObAi from "./components/ObAi";
import OnboardingStrategy from "./screens/OnboardingStrategy";
import GraphScreen from "./screens/GraphScreen";
import ListForDev from "./screens/ListForDev";
import ListActionFromBigQuery from "./screens/ListActionFromBigQuery";
import ManageNotifByIntern from "./screens/ManageNotifByIntern";
import NotificationScreen from "./screens/NotificationScreen";
import LayoutNoBanner from "./components/LayoutNoBanner";
import SuiviAirtable from "./screens/SuiviAirtable";
// import UpdateNotif from "./screens/UpdateNotif";
const queryClient = new QueryClient();

const ROLES = {
    SuperAdmin: process.env.REACT_APP_SUPADMIN,
    Admin: process.env.REACT_APP_ADMIN,
    Client: process.env.REACT_APP_CLIENT,
    Support: process.env.REACT_APP_SUPPORT,
    Partenaire: process.env.REACT_APP_PARTENAIRE,
    Test: "1234",
};

const GA4_MEASUREMENT_ID = process.env.REACT_APP_GA4
// Replace with your measurement ID

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(GA4_MEASUREMENT_ID);
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname
    })
}
/*const colors = {
  primary: "#01285A",
  secondary: "#48C4F1",
  success: "#52b788",
  error: "#e63946",
};*/

/*const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      light: "#90e0ef",
    },
    secondary: {
      main: colors.secondary,
      light: "#fb8500",
    },
    error: {
      main: colors.error,
      light: "#fb8500",
    },
    background: {
      default: "#f4f5fd",
    },
  },
  typography: {
    fontSize: 13,
    fontFamily: "Roboto, sans-serif",
    button: {
      textTransform: "none",
    },
  },
});*/

function App() {
    const handleCustomEvent = (data) => {
        console.log("received: ", data);
    }
    // const socket = useSelector(socketSelector);
    const auth = useSelector(authSelector);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     if (socket && auth && auth.id != 0) {
    //         socket.connect();
    //         let roomName = "client room"
    //         if (auth.role.includes(process.env.REACT_APP_SUPPORT)) {
    //             roomName = "support room"
    //             console.log(roomName);
    //         }
    //         socket.emit('new_user', auth.id, roomName)
    //     }
    //     return () => {
    //         // if (socket) {
    //         socket.off('new_user');
    //         socket.off('all_users');
    //         socket.disconnect();
    //         socket?.emit("client_disconnect")
    //         // }
    //     };
    // }, [auth, socket])

    // useEffect(() => {
    //     socket.on("all_users", (connectedUsers) => {
    //         dispatch(setOnlineMember(connectedUsers));
    //     });
    //     return () => {

    //         socket.off('all_users');

    //     };
    // }, []);

    useEffect(() => {
        if (auth && auth.id !== 0) {
            const roomName = auth.role.includes(process.env.REACT_APP_SUPPORT) ? "support room" : "client room";
            const handleDisconnect = () => {
                socket.emit("client_disconnect");
                // You can also leave the room here if needed.
            };
            socket.connect();
            socket.emit('new_user', auth.id, roomName);
            //socket.on('disconnect', handleDisconnect);
            return () => {
                socket.off('new_user');
                //socket.off('disconnect', handleDisconnect);
                handleDisconnect();
                socket.disconnect();
            };
        }
    }, [auth.id]);

    // useEffect(() => {
    //     const handleAllUsers = (connectedUsers) => {
    //         dispatch(setOnlineMember(connectedUsers));
    //     };
    //     socket.on("all_users", handleAllUsers);
    //     return () => {
    //         socket.off('all_users', handleAllUsers);
    //     };
    // }, [socket]);


    /*const [progress, setProgress] = useState({
      percent: 0,
      autoIncrement: true,
    }); */

    //test   const { t, i18n } = useTranslation();
    const { t } = useTranslation();
    /*let socket = SocketIOClient(process.env.REACT_APP_BASE_URL_SOCKET, {

      path: '/dev/socket.io'
    });*/
    const [maintenance, setMaintenance] = useState(false);
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getMaintenanceStatus = async () => {
            try {
                const response = await axios.get(`/problem`);
                isMounted && setMaintenance(response.data.etat);
            } catch (error) {
                console.log(error);
                //setMaintenance(false);
            }
        };
        getMaintenanceStatus();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    // const maintenance = false;
    return (
        <QueryClientProvider client={queryClient}>
            {maintenance ? (
                <MaintenanceScreen />
            ) : (
                <>
                    <BrowserRouter>
                        <ToastContainer hideProgressBar={true} position={"bottom-right"} theme="colored" />
                        <Toaster />
                        <Routes>
                            <Route path="login" element={<LoginScreen t={t} />} />

                            <Route exact path="/" element={<LoginScreen t={t} />} />
                            <Route path="/" element={<Layout t={t} />}>
                                {/* Public Route */}
                                <Route path="unauthorized" element={<UnauthorizedScreen />} />
                                <Route path="/team" element={<TeamScreen t={t} />} />
                                <Route path="/online" element={<ConnectedUser t={t} />} />

                                <Route path="/reset" element={<ForgotPassword t={t} />} />
                                <Route path="/token-email/:emailtest/:tokentest" element={<EnterToken t={t} />} />
                                <Route path="/reset-password" element={<ResetPassword t={t} />} />
                                {/* <Route path="/test" element={<TestScreen t={t} />} /> */}
                                <Route path="/reset-password" element={<ResetPassword t={t} />} />
                                {/* <Route exact path="/affilier2/:id" element={<Affilier2 />} /> */}
                                <Route path="/settings" element={<SettingScreen t={t} />} />
                                <Route element={<PersistLogin />}>
                                    {/* Super admin Route */}
                                    <Route element={<RequireAuth allowedRole={[ROLES.SuperAdmin]} />}>
                                        <Route path="/hello" element={<CpScreen t={t} />} />
                                        <Route path="/tickets" element={<TicketsScreen t={t} />} />
                                        <Route path="/home" element={<DashboardSupScreen t={t} />} />
                                        {/* <Route path="/dashboard/:id" element={<ClientScreen t={t} />} />
                    <Route path="/rebalancing/:id" element={<ClientRebalancingScreen t={t} />} />
                    <Route path="/affiliate/:id" element={<ParrainageScreen t={t} />} />
                    <Route path="/transaction/:id" element={<ClientTransactionScreen t={t} />} />
                    <Route path="/resources/:id" element={<RessourcesScreen t={t} />} />
                    <Route path="/secure/:id" element={<ClientSecure t={t} />} /> */}
                                    </Route>

                                    {/* admin Route */}
                                    <Route element={<RequireAuth allowedRole={[ROLES.Admin, ROLES.SuperAdmin]} />}>
                                        <Route path="/admin/dashboard" element={<DashboardScreen t={t} />} />
                                        {/* <Route path="/admin" >
                                            <Route path="/users" element={<UsersScreen t={t} />} />
                                        </Route> */}
                                        <Route path="/admin/users" element={<UsersScreen t={t} />} />
                                        <Route path="/admin/payments" element={<Payment t={t} />} />
                                        <Route path="/admin/payment/:id" element={<OnePayment t={t} />} />
                                        <Route path="/admin/transactions" element={<Transactions t={t} />} />
                                        <Route path="/onboarding" element={<OnboardingScreen t={t} />} />
                                        <Route path="/onboarding-details/:id" element={<OnboardingDetailsScreen t={t} />} />
                                        <Route path="/admin/analytics/client" element={<AdminClientAnalytics t={t} />} />
                                        <Route path="/admin/analytics/pnl" element={<AdminPnlChurn t={t} />} />
                                        <Route path="/analytics-ticket" element={<TicketAnalyticScreen t={t} />} />
                                        <Route path="/product" element={<ProductScreen t={t} />} />
                                        <Route path="/subscription" element={<SubscriptionScreen t={t} />} />
                                        <Route path="/transactionSub/:id" element={<TransactionScreen t={t} />} />
                                        <Route path="/pnl/:id" element={<PnlUserScreen t={t} />} />
                                        <Route path="/waitStop" element={<WaittingStopScreen t={t} />} />
                                        <Route path="/pause" element={<PauseScreen t={t} />} />
                                        <Route path="/pnl" element={<PnlTable t={t} />} />
                                        <Route path="/credit" element={<CouponScreen t={t} />} />
                                        <Route path="/chart" element={<GraphScreen t={t} />} />


                                        <Route path="/simulator" element={<SimulatorScreenAdmin t={t} />} />
                                        <Route path="/index-cb" element={<IndexScreenAdmin t={t} />} />
                                        <Route path="/user" element={<AllUsersScreen t={t} />} />
                                    </Route>

                                    {/* support Route */}
                                    <Route element={<RequireAuth allowedRole={[ROLES.Support, ROLES.SuperAdmin, ROLES.Admin]} />}>
                                        <Route path="/support/dashboard" element={<DashboardSupport t={t} />} />
                                        <Route path="/support/lb" element={<AddUser t={t} />} />
                                        <Route path="/support/manage-resources" element={<AdminManageResources t={t} />} />
                                        <Route path="/support/manage-notif" element={<ManageNotifByIntern t={t} />} />
                                        <Route path="/support/user/:id" element={<OneUser t={t} />} />
                                        <Route path="/support/secure/:id" element={<SecureScreen2 t={t} />} />
                                        <Route path="/board" element={<BoardScreen t={t} />} />
                                        <Route path="/board/:bid" element={<SuiviAirtable t={t} />} />
                                        {/* <Route path="/updateNotif/:nid" element={<UpdateNotif t={t} />} /> */}
                                        {auth.id == "87e4efd1-376f-4741-8cc7-76b771b05b76" && <Route path="/list-dev" element={<ListForDev />} />}
                                        {auth.id == "87e4efd1-376f-4741-8cc7-76b771b05b76" && <Route path="/bg" element={<ListActionFromBigQuery />} />}





                                        {/* Vue Client */}
                                        <Route path="/dashboard/:id" element={<ClientScreen t={t} />} />
                                        <Route path="/rebalancing/:id" element={<ClientRebalancingScreen t={t} />} />
                                        <Route path="/affiliate/:id" element={<ParrainageScreen t={t} />} />
                                        <Route path="/transaction/:id" element={<ClientTransactionScreen t={t} />} />
                                        <Route path="/resources/:id" element={<RessourcesScreen t={t} />} />
                                        <Route path="/contact/:id" element={<ContactUsScreen t={t} />} />
                                        <Route path="/secure/:id" element={<ClientSecure t={t} />} />
                                        <Route path="/evolution/:id" element={<EvolutionScreen t={t} />} />
                                        <Route path="/index-cbt/:id" element={<IndexScreenUser t={t} />} />
                                        <Route path="/wallet/:id" element={<WalletScreen t={t} />} />
                                        <Route path="/heritage/:id" element={<HeritageScreen t={t} />} />
                                        <Route path="/sub-account/:id" element={<SubAccountScreen t={t} />} />

                                    </Route>

                                    {/* Client Route */}
                                    <Route element={<RequireAuth allowedRole={[ROLES.Client, ROLES.Partenaire]} />}>
                                        <Route path="/dashboard" element={<ClientScreen t={t} />} />
                                        <Route path="/rebalancing" element={<ClientRebalancingScreen t={t} />} />
                                        <Route path="/affiliate" element={<ParrainageScreen t={t} />} />
                                        <Route path="/transaction" element={<ClientTransactionScreen t={t} />} />
                                        <Route path="/resources" element={<RessourcesScreen t={t} />} />
                                        <Route path="/contact" element={<ContactUsScreen t={t} />} />
                                        <Route path="/secure" element={<ClientSecure t={t} />} />
                                        <Route path="/absolute" element={<BulotAbsoluScreen t={t} />} />
                                        <Route path="/user-api" element={<UserApiScreen t={t} />} />
                                        {/* <Route path="/suivi" element={<SuiviOnboardingScreen t={t} />} /> */}
                                        {/* <Route path="/history" element={<HistoryScreen t={t} />} /> */}
                                        {/* <Route path="/profile" element={<ClientProfileScreen t={t} />} /> */}
                                        <Route path="/evolution" element={<EvolutionScreen t={t} />} />
                                        <Route path="/index-cbt" element={<IndexScreenUser t={t} />} />
                                        <Route path="/wallet" element={<WalletScreen t={t} />} />
                                        <Route path="/heritage" element={<HeritageScreen t={t} />} />
                                        <Route path="/sub-account" element={<SubAccountScreen t={t} />} />
                                        <Route path="/onboarding-ai" element={<OnboardingStrategy t={t} />} />

                                    </Route>

                                    <Route element={<RequireAuth allowedRole={[ROLES.Client, ROLES.Partenaire, ROLES.SuperAdmin, ROLES.Admin, ROLES.Support]} />}>
                                        {/* <Route path="/settings" element={<SettingScreen t={t} />} /> */}
                                        <Route path="/reauth" element={<AuthSettingScreen t={t} />} />
                                    </Route>
                                </Route>

                                {/* not found */}
                                <Route path="*" element={<PageNotFound />} />
                            </Route>

                            <Route path="/" element={<LayoutNoBanner t={t} />}>
                                <Route element={<RequireAuth allowedRole={[ROLES.Client, ROLES.Partenaire]} />}>
                                    <Route path="/notif" element={<NotificationScreen t={t} />} />
                                    <Route path="/settings" element={<SettingScreen t={t} />} />

                                </Route>
                            </Route>

                        </Routes>
                    </BrowserRouter>
                    <CssBaseline />
                </>
            )}
        </QueryClientProvider>
    );
}

export default App;
