import React, { useState, useEffect } from "react";
import Controls from "../components/controls/Controls";
import setAuthToken from "../utils/setAuthToken";
import * as services from "../utils/service.js";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../features/authSlice";
import { toast } from "react-toastify";
import axios from "../axios";

function TwoFAScreen(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || "/";
    const { token, t, dataLogin } = props;
    const [code, setCode] = useState();
    const [codeError, setCodeError] = useState();
    const axiosPrivate = useAxiosPrivate();
    const dispacth = useDispatch();
    const handleVerifyCode = async () => {
        // console.log("tee", code, token, userDataLogin)
        try {
            if (!code) throw t("error.required");
            const here = services.decryptCB(token);
            // await setAuthToken(here);
            const authToken = here
            const response = await axios.get(`/api/users/verifyTfa/${code}`, {
                headers: {
                    'x-auth-token': authToken,
                },
            }); if (response.data) {
                console.log("OOOKKK");

                if (!dataLogin.isFirstLogin) {
                    dispacth(login(dataLogin));
                    const rolesArray = dataLogin.role.split(",");

                    switch (true) {
                        case rolesArray.length == 1 && rolesArray.includes(process.env.REACT_APP_PARTENAIRE):
                            localStorage.setItem("token", token);
                            navigate("/affiliate");
                            break;
                        case rolesArray.includes(process.env.REACT_APP_SUPADMIN):
                            localStorage.setItem("token", token);
                            navigate("/hello");
                            break;
                        case rolesArray.includes(process.env.REACT_APP_ADMIN):
                            localStorage.setItem("token", token);
                            navigate("/rebalancing");
                            break;
                        case rolesArray.includes(process.env.REACT_APP_CLIENT):
                            localStorage.setItem("token", token);
                            navigate("/dashboard");
                            break;
                        case rolesArray.includes(process.env.REACT_APP_SUPPORT):
                            localStorage.setItem("token", token);
                            navigate("/support/dashboard");
                            break;
                        default:
                            navigate(from, { replace: true });
                            break;
                    }
                } else {
                    navigate("/reset", { state: { isFirstLogin: dataLogin.isFirstLogin } });
                }
            } else {
                //setCode("");
                toast.error("Code invalide");
                setCodeError("Code invalide");
            }
        } catch (error) {
            setCodeError(error);
            console.log(error);
        }
    };

    useEffect(() => {
        if (code) {
            setCodeError("");
        }
    }, [code]);

    return (
        <>
            <div className="flex flex-col gap-y-4 ">
                <div className="text-xs font-medium text-center"> {t(`info.2FAObligatoire`)}</div>
                <div className="text-xs font-light text-justify">{t(`info.6chiffresCode`)}</div>
                <div className="flex  gap-x-2">
                    <div className="flex-1">
                        <Controls.Input type="number" label={t(`label.verificationCode`)} value={code} name={t(`label.code`)} error={codeError} onChange={(e) => setCode(e.target.value)} />
                    </div>
                    <div className="text-right ">
                        <Controls.Button text={t(`button.continue`)} onClick={handleVerifyCode} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default TwoFAScreen;
