import React, { useState } from 'react'
import { CheckShad } from '../client/CheckShad'
import { useTranslation } from 'react-i18next'
import { useFOrm } from '../useFOrm'
import BtnShad from '../client/btnShadcn'
import { CircleAlert } from 'lucide-react'
import { TypographyH3, TypographySmall } from '../Typo'
import { LinkTuto } from '../LinkTuto'
import { useSelector } from 'react-redux'
import { tutoLinkSelector } from '../../features/tutoLinkSlice'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useNavigate } from 'react-router-dom'
import { useToast } from '../ui/use-toast'
import { authSelector } from '../../features/authSlice'
import { addTagToMailerLite } from '../../lib/utils'

function CreateSubAccount(props) {
    const { step, setStep, newIdSub, setNewIdSub } = props
    const { t } = useTranslation()
    const auth = useSelector(authSelector);
    console.log("auth", auth.id, auth.email);

    const [isLoading, setIsLoading] = useState(false)
    const [isVideoOpen, setIsVideoOpen] = useState(false)
    const tutoLink = useSelector(tutoLinkSelector)
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate()
    const { toast } = useToast()

    const initialValues = {
        createSub: ""
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        // if ("initialCapital" in fieldValues) fieldValues.initialCapital < 500 ? t(`error.minAmount`) : temp.initialCapital = fieldValues.initialCapital ? "" : t("error.required");

        if ("initialCapital" in fieldValues) {
            if (fieldValues.createSub === "" || fieldValues.createSub === null) {
                temp.createSub = t("error.required");
            } else if (parseFloat(fieldValues.initialCapital) < 500) {
                temp.initialCapital = t("error.mustBeNumberAndAtLeast50", { amount: 500 });
            } else {
                temp.initialCapital = "";
            }
        }

        setErrors({
            ...temp,
        });
        console.log("Temp", temp);

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);

    const handleConfirmCreationSubAccount = async () => {
        setIsLoading(true)
        try {
            // check if mdp is OK
            const datatoPost = {
                step: 2,
                strategy: "ai",
                platform: 1,
            }
            const { data } = await axiosPrivate.post(`/api/onboarding/authorize`, datatoPost)
            console.log("create sub Account", datatoPost, "data", data);
            setNewIdSub(data.newId)
            const vaovao = data.newId + data.step
            // clone master account
            setStep(data.step)
            setIsLoading(false)
            console.log("vsovso", vaovao);
            const dataToSendMailerlite = { id: auth.id, gname: "API_AI" }
            await addTagToMailerLite(dataToSendMailerlite)
            console.log("LAsa le tag", dataToSendMailerlite);

            navigate(`?strategy=${encodeURIComponent(vaovao)}`, { replace: true })


        } catch (error) {
            console.error("handleConfirmCreationSubAccount", error);
            toast({
                description: t(`error.${error.response.data.msgCode}`),
                variant: "destructive",
            })
            setIsLoading(false)
            window.location.reload()
        }
        setIsLoading(false)

    }

    return (
        <div>
            <TypographyH3 className="text-center "> {t(`obAi.step1Title`)} </TypographyH3>
            <TypographySmall>{t(`obAi.step1`)}</TypographySmall>
            <LinkTuto text={t(`linkTuto.createSubAccount`)} fit setIsVideoOpen={setIsVideoOpen} isVideoOpen={isVideoOpen} url={tutoLink?.bCreationSubAccount}
                title={t(`linkTuto.createSubAccount`)} />
            <div className='mt-6 w-full text-center'>
                <div className='text-left'>

                    <CheckShad text={t(`label.confirmCreateSubAccount`)} onChange={handleInputChange} name={"createSub"} />
                </div>
                <BtnShad className="mt-6 w-1/2" isLoading={isLoading} text={t(`button.confirm`)} onClick={handleConfirmCreationSubAccount} icon={<CircleAlert className="h-4" />} disabled={values.createSub ? false : true} />
            </div>

        </div>
    )
}

export default CreateSubAccount
