import React, { useState, useEffect } from "react";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FileResource from "../components/FileResource";
import { authSelector } from "../features/authSlice";
import { useSelector } from "react-redux";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Title from "../components/title/Title";
import { DocumentTextIcon, VideoCameraIcon } from "@heroicons/react/solid";
import SliderRowNetflix from "../components/SliderRowNetflix";
import * as service from "../utils/service";
import Controls from "../components/controls/Controls";
import { FilterIcon } from "@heroicons/react/outline";

function RessourcesScreen(props) {
    const { t } = props;
    const { id } = useParams(); // maka
    const [resources, setRessources] = useState();
    const [platformFilter, setPlatformFilter] = useState(null);
    const auth = useSelector(authSelector);
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getAllResources = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/resources/findAll`, {
                    signal: controller.signal,
                });
                let decrypt = JSON.parse(service.decryptCBData(data));
                console.log("decrypt 1", decrypt);

                console.log("with platform", decrypt);

                isMounted && setRessources(decrypt.filter((f) => f.status == true));
                // isMounted && setShorts(decrypt.filter((f) => f.category == "Short" && f?.type == "Vidéo" && f.status == true));
                // isMounted && setLive(decrypt.sort((a, b) => b.id - a.id).filter((f) => f?.category == "Live mensuel" && f?.type == "Vidéo" && f?.status == true));
                // isMounted && setFormations(decrypt.filter((f) => f.category == "Formation" && f.type == "Vidéo" && f?.status == true));
            } catch (error) {
                // navigate("/login", { state: { from: location }, replace: true });
                console.log(error);
            }
        };
        getAllResources();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const filteredPlatform = platformFilter ? resources?.sort((a, b) => b.id - a.id).filter((r) => r.platform == platformFilter) : resources?.sort((a, b) => b.id - a.id)
    console.log("", filteredPlatform);

    return (
        <>
            <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />
            <div className="flex flex-row">
                <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />
                <div className="flex-1 max-w-[100%] ">
                    <div className="md:ml-52 p-4  h-[92vh] overflow-y-scroll">
                        <div className="flex gap-x-2">
                            <div className="flex w-full p-2 ">
                                <div className="flex-1 font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.ressource`)}</div>
                                <hr />

                                <div className="flex space-x-2">
                                    <FilterIcon className="w-5 text-slate-500" />
                                    {platformFilter == 2 || platformFilter == 1 ? <Controls.Button
                                        text={t(`label.all`)}
                                        xsFont small
                                        onClick={() => { setPlatformFilter(null); console.log("reset", platformFilter) }}
                                        color={platformFilter == null ? "bg-cyan-500 text-white hover:bg-cyan-600" : "bg-blue-700 hover:bg-blue-500 text-white"} />
                                        : null}

                                    <Controls.Button
                                        text={"Binance"}
                                        xsFont small
                                        onClick={() => { setPlatformFilter(1); console.log("Binance", platformFilter) }}
                                        color={platformFilter == 1 ? "bg-yellow-500 hover:bg-yellow-400" : "bg-yellow-400 text-black hover:bg-yellow-500 "} />

                                    <Controls.Button
                                        text={"Kraken"}
                                        xsFont small
                                        onClick={() => { setPlatformFilter(2); console.log("Kraken", platformFilter) }}
                                        color={platformFilter == 2 ? "bg-purple-500  hover:bg-purple-400 text-white" : "bg-purple-400 text-white hover:bg-purple-500 "} />




                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col space-y-4 ">
                            {/* Mode Opératoire */}
                            <div className="bg-slate-100 p-2">
                                <Title.SectionTitle
                                    text={t(`label.operatingProcedure`)}
                                    // unit={`(${users && users.all.length})`}
                                    icon={<DocumentTextIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                />
                                <div className="my-4 ">
                                    <div className="flex overflow-x-auto mt-4 border space-x-2">
                                        {/* Row */}
                                        {filteredPlatform &&
                                            filteredPlatform
                                                .filter((f) => f.category == "Mode opératoire" && f.type == "PDF")
                                                .map((r, i) => (
                                                    <div className="m-2 w-60 md:w-80 " key={i}>
                                                        <FileResource
                                                            pdfLink={r.url}
                                                            title={r.title}
                                                            color={r.platform == 1 ? "border-2 border-yellow-500 hover:bg-yellow-500/25" : r.platform == 2 ? "border-2 border-purple-500 hover:bg-purple-500/25" : "border-2 border-blue-500 "}
                                                        />
                                                    </div>
                                                ))}
                                    </div>
                                </div>
                            </div>
                            {/* Formations */}
                            <div className="bg-slate-100 dark:bg-slate-800 p-2">
                                <Title.SectionTitle text={"Formations"} icon={<VideoCameraIcon className="h-5 w-5 text-blue-700 text-xs" />} />
                                {/* <SliderRowNetflix data={formations && formations} /> */}
                                <SliderRowNetflix data={filteredPlatform && filteredPlatform.filter((f) => (f.category == "Formation" || f.category == "Mode opératoire") && f.type == "Vidéo")} />
                            </div>
                            {/* Fonctionalités */}
                            <div className="bg-slate-100 p-2">
                                <Title.SectionTitle text={t(`label.features`)} icon={<VideoCameraIcon className="h-5 w-5 text-blue-700 text-xs" />} />
                                {/* <SliderRowNetflix data={shorts && shorts} /> */}
                                <SliderRowNetflix data={filteredPlatform && filteredPlatform.filter((f) => f.category == "Short" && f.type == "Vidéo")} />

                            </div>
                            {/* Live mensuel */}
                            <div className="bg-slate-100 p-2">
                                <Title.SectionTitle
                                    text={t(`label.monthlylive`)}
                                    // unit={`(${users && users.all.length})`}
                                    icon={<VideoCameraIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                />
                                {/* <SliderRowNetflix data={live && live} /> */}
                                <SliderRowNetflix data={filteredPlatform && filteredPlatform.filter((f) => f.category == "Live mensuel" && f.type == "Vidéo")} />

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RessourcesScreen;
