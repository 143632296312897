import React, { useState, useEffect } from "react";
import { useFOrm, Form } from "../useFOrm";
import Controls from "../controls/Controls";
import { decryptCB, specificKeyCode, encryptCB } from "../../utils/service";
import axios from "../../axios";
import { toast } from "react-toastify";
import { updateUserDetails, userDataSelector } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { authSelector, logout } from "../../features/authSlice";

const initialFvalues = {
    tron: "",
    polygon: "",
    solana: "",
    tronUsdc: "",
    polygonUsdc: "",
    solanaUsdc: "",
};
function KrakenForm(props) {
    const { data, t, uid } = props;
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);
    const axiosPrivate = useAxiosPrivate();

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("tron" in fieldValues) temp.tron = fieldValues.tron ? "" : t("error.required");
        if ("polygon" in fieldValues) temp.polygon = fieldValues.polygon ? "" : t("error.required");
        if ("solana" in fieldValues) temp.solana = fieldValues.solana ? "" : t("error.required");
        if ("tronUsdc" in fieldValues) temp.tronUsdc = fieldValues.tronUsdc ? "" : t("error.required");
        if ("polygonUsdc" in fieldValues) temp.polygonUsdc = fieldValues.polygonUsdc ? "" : t("error.required");
        if ("solanaUsdc" in fieldValues) temp.solanaUsdc = fieldValues.solanaUsdc ? "" : t("error.required");
        setErrors({
            ...temp,
        });

        if (fieldValues == values) {
            //values.source_income === "autre" && values.otherSourceOfIncome === "" ? console.log("autre") : console.log("lol")
            //if not null
            return Object.values(temp).every((x) => x == "");

            /*return Object.values(temp).every(([k, v]) => k === "otherSourceOfIncome" || v === ""

                  );*/
        }
    };

    console.log("DATA KRAKEN", data);
    let krakenAddressesObj = data?.krakenAddresses ? JSON.parse(data?.krakenAddresses) : {}
    console.log("DATA KRAKEN", krakenAddressesObj );
    initialFvalues.tron = krakenAddressesObj?.tron;
    initialFvalues.polygon = krakenAddressesObj?.polygon;
    initialFvalues.solana = krakenAddressesObj?.solana;
    initialFvalues.tronUsdc = krakenAddressesObj?.tronUsdc;
    initialFvalues.polygonUsdc = krakenAddressesObj?.polygonUsdc;
    initialFvalues.solanaUsdc = krakenAddressesObj?.solanaUsdc;

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);


    const adressKraken = [

        {
            type: "text",
            name: "tron",
            label: "TRON",
            placeholder: "adresse Kraken TRON",
        },

        {
            type: "text",
            name: "polygon",
            label: "POLYGON",
            placeholder: "adresse Kraken POLYGON",
        },

        {
            type: "text",
            name: "solana",
            label: "SOLANA",
            placeholder: "adresse Kraken SOLANA",
        },

    ];
    const adressKrakenUsdc = [

        {
            type: "text",
            name: "tronUsdc",
            label: "TRON",
            placeholder: "adresse Kraken TRON",
        },

        {
            type: "text",
            name: "polygonUsdc",
            label: "POLYGON",
            placeholder: "adresse Kraken POLYGON",
        },

        {
            type: "text",
            name: "solanaUsdc",
            label: "SOLANA",
            placeholder: "adresse Kraken SOLANA",
        },

    ];


    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleUpdateInfo = async () => {
        if (validate()) {
            const dataToAdd = {
                krakenAddresses: JSON.stringify(values)
            }

            try {
                dataToAdd.uid = uid;
                const detailsData = await axiosPrivate.get(`/api/details/findBy/${uid}`);

                if (detailsData.data) {
                    try {

                        await axiosPrivate.put(`/api/details/${uid}`, dataToAdd);
                        dispatch(updateUserDetails(dataToAdd));
                        toast.success("Details updated");
                    } catch (error) {
                        toast.error(error.response?.data.msg);
                    }
                } else {
                    try {
                        await axiosPrivate.post(`/api/details/create/`, dataToAdd);
                        dispatch(updateUserDetails(dataToAdd));
                        toast.success("Details Added");
                    } catch (error) {
                        toast.error(error.response?.data.msg);
                    }
                }
                console.log("++++++++++++ Kraken values", dataToAdd)
            } catch (error) {
                toast.error(error.response?.data.msg);
            }
        }

    };

    const handleReset = () => {
        setValues(initialFvalues);
    };

    return (
        <>
            <Form onSubmit={handleSubmit} noValidate>
                <div className="flex flex-col p-4 space-y-3">
                    <div className="flex flex-col  space-y-6">
                        {/* <h6 className="flex-none font-semibold text-sm">Kraken </h6> */}
                        <div className="flex flex-col gap-y-4  justify-center items-center">

                            <div className=" w-full md:w-2/3 lg:w-1/2  flex flex-col items-center space-y-4">
                                <h6 className="flex-none font-semibold text-sm text-start  w-full">USDT </h6>
                                {adressKraken.map((item, i) => {
                                    return (
                                        <div className=" w-full " key={i}>
                                            <Controls.Input
                                                fullWidth={true}
                                                key={i}
                                                // disabled={disabled}
                                                readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                                label={item.label}
                                                value={values[item.name] || ""}
                                                onChange={handleInputChange}
                                                name={item.name}
                                                type={item.type}
                                                placeholder={item.placeholder}
                                                onKeyDown={(event) => {
                                                    specificKeyCode(event);
                                                }}
                                                error={errors[item.name]}
                                            />
                                        </div>
                                    );
                                }
                                )}
                            </div>

                            <div className=" w-full md:w-2/3 lg:w-1/2  flex flex-col items-center space-y-4">
                                <h6 className="flex-none font-semibold text-sm text-start  w-full">USDC </h6>

                                {adressKrakenUsdc.map((item, i) => {
                                    return (
                                        <div className=" w-full  " key={i}>
                                            <Controls.Input
                                                fullWidth={true}
                                                key={i}
                                                // disabled={disabled}
                                                readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                                label={item.label}
                                                value={values[item.name] || ""}
                                                onChange={handleInputChange}
                                                name={item.name}
                                                type={item.type}
                                                placeholder={item.placeholder}
                                                onKeyDown={(event) => {
                                                    specificKeyCode(event);
                                                }}
                                                error={errors[item.name]}
                                            />
                                        </div>
                                    );
                                }
                                )}
                            </div>

                        </div>

                    </div>
                    <div className="flex justify-end ">
                        <Controls.Button
                            disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                            text={t(`button.save`)}
                            xsFont
                            onClick={() => handleUpdateInfo()}
                        />
                    </div>
                </div>
            </Form>

            <hr />

            <div className="flex flex-col space-y-4 p-4">
                <div className="text-xs font-bold bg-purple-500 w-fit px-2 rounded-md text-white">Adresse Kraken Crypto Bulot</div>
                <div className="flex space-x-8 ">
                    <div className=" w-1/3 text-xs font-semibold space-y-2 ">
                        <span>kraken-trx-usdt : <br />TJ3w4LSgGXVP8r7vHzBE2yxfHfDnQKF8Wq</span>
                    </div>
                    <div className="w-1/3 text-xs font-semibold space-y-2">
                        <span>kraken-solana-usdt : <br />GZ3guHoKDQZT3trpDwcehH1mZzsJf4uW2M2KZPxUzEkW</span>
                    </div>
                    <div className="w-1/3 text-xs font-semibold space-y-2">
                        <span>kraken-polygon-usdt : <br />0x5bd333478c66cb1ccbbef5f0e120725c3bf41761</span>
                    </div>
                </div>


                <div className="flex flex-col space-y-4 py-4">

                    <div className="text-xs font-bold bg-yellow-500 w-fit px-2 rounded-md text-white">Adresse Binance Crypto Bulot</div>

                    <div className="flex space-x-8 ">

                        <div className="w-1/3 text-xs font-semibold space-y-2">
                            <span className=" w-fit">binance-trx-usdt: <br />TS4UzZpQu2mckofztGtBA9ATRZsi6vE7b9</span>
                        </div>
                        <div className="w-1/3 text-xs font-semibold space-y-2">
                            <span className=" w-fit">binance-trx-usdc: <br />TFQUrR8xHtzmD2qvebQzA7KMzgZd5qun8E</span>
                        </div>
                        <div className="w-1/3 text-xs font-semibold space-y-2">
                            <span className=" w-fit">binance-solana-usdc: <br />BJ76j9Usvwz5CzWLWS7ka69i5zqyAHk5U2vX3kriXN4A
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default KrakenForm;
