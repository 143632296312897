import { MoveLeft, SquareKanban } from 'lucide-react';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { TypographyH4, TypographyS } from '../components/Typo';
function SuiviAirtable() {
    const navigate = useNavigate()
    const goBack = () => navigate(-1);

    return (
        <>
            <div className='flex items-center gap-x-2 '>
                <MoveLeft className='text-slate-700 hover:bg-slate-200 hover:rounded-full hover:cursor-pointer '
                    onClick={() => {
                        goBack();
                        console.log("Go back eh ...");
                    }}
                />

                <TypographyH4 className={"text-primary"}>Suivi ticket</TypographyH4>
            </div>
            <TypographyS>Le suivi se fera désormais sur
                <a href="https://airtable.com/appo4izhOMhi201cC/tblip3Y4haXjoOy5m/viw4XRvLdTkevxr64?blocks=hide" target='_blank' className='text-primary hover:cursor-pointer hover:underline'> Airtable</a>.
            </TypographyS>
            <div className='flex justify-center items-center space-x-2 h-32'  >
                <SquareKanban size={40} />
                <TypographyH4>
                    <a href="https://airtable.com/appo4izhOMhi201cC/tblip3Y4haXjoOy5m/viw4XRvLdTkevxr64?blocks=hide" target='_blank' className=' hover:cursor-pointer hover:underline'> Ouvrir Airtable</a>
                </TypographyH4>
            </div>

        </>
    )

}

export default SuiviAirtable
