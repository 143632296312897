import React, { useEffect, useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useNavigate, useLocation } from "react-router-dom";
import { selectUsersWithProblems } from "../features/monitoringSlice";
import { setFollowUp, selectFollowUpCard } from "../features/followSlice";
import { authSelector } from "../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import DashboardCard from "../components/cards/DashboardCard";
import moment from "moment";
import Users from "../components/Users";
import TicketCard from "../components/cards/TicketCard";
import TicketsScreen from "./TicketsScreen";
import { decryptCBData } from "../utils/service";
function CpScreen(props) {
    const { t } = props;
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);
    const followUpCards = useSelector(selectFollowUpCard);
    const [users, setUsers] = useState();
    const [showTicket, setShowTicket] = useState();
    const [uidSelected, setUidSelected] = useState();
    const GridMenu = (props) => {
        const { icon, text, path } = props;
        return (
            <div className="bg-sky-100 w-32 shadow-lg hover:bg-sky-200 hover:cursor-pointer rounded-lg p-2 mt-10" onClick={() => navigate(path)}>
                <div className="">{icon}</div>
                <div className="text-center">{text}</div>
            </div>
        );
    };
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getReportingUsers = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/reporting/countUsers`, {
                    signal: controller.signal,
                });
                isMounted && setUsers(data);
                console.log("++++++++++++ USER +++++++++++", data);
                //dispatch(setMonitoringUsers(response.data));
            } catch (error) {
                //console.log(error)
                //navigate('/login', { state: { from: location }, replace: true })
                // if (error.response.status != 403) {
                //   navigate("/login", { state: { from: location }, replace: true });
                // }
            }
        };

        const getAllTickets = async () => {
            try {
                const response = await axiosPrivate.get(`/api/ticket/findAll`, {
                    signal: controller.signal,
                });
                console.log("tttttttttttyttryrjhfhfjkkj", response.data);
                //isMounted && setAllTickets(response.data.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt)));
                isMounted && dispatch(setFollowUp(response.data.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt))));
            } catch (error) {
                // if (error.response.status != 403) {
                //   navigate("/login", { state: { from: location }, replace: true });
                // }
            }
        };
        getReportingUsers();
        getAllTickets();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    return (
        <>
            <div className="flex h-full ">


                <div className={`${showTicket ? "hidden" : " flex-1 h-[89vh] p-4"}`}>

                    <div className={`${showTicket ? "hidden" : "flex-none h-auto mt-2 px-4"} `}>
                        <h1 className="font-thin text-2xl text-slate-700 dark:text-white">
                            Hello, <span className="font-semibold">{decryptCBData(auth.name)}</span>{" "}
                        </h1>
                        <span className="text-xs text-slate-500 dark:text-slate-400 ">{moment().format("Do MMMM YYYY")}</span>

                        <div className="flex gap-x-2 mt-2">
                            <DashboardCard bg={"bg-blue-900 "} number={users && users.all} description={"Total"} />
                            <DashboardCard bg={"bg-blue-900 "} number={users && users.active} description={"Active Total"} />
                            <DashboardCard bg={"bg-blue-900 "} number={users && users.activeCbdt} description={"Active CBDT"} />
                            <DashboardCard bg={"bg-blue-900 "} number={users && users.activeAI} description={"Active AI style"} />
                            <DashboardCard bg={"bg-blue-900 "} number={users && users.inactive} description={"Résiliés Total"} />
                            <DashboardCard bg={"bg-blue-900 "} number={users && users.inactiveCbdt} description={"Résiliés CBDT"} />
                            <DashboardCard bg={"bg-blue-900 "} number={users && users.inactiveAI} description={"Résiliés AI style"} />
                            <DashboardCard bg={"bg-blue-600 "} number={users && users.kim} description={"Kim"} unit={t("label.client")} />
                            <DashboardCard bg={"bg-blue-600 "} number={users && users.damien} description={"Damien"} unit={t("label.client")} />
                        </div>
                    </div>

                    <div className="flex-auto space-y-4 mt-4 p-4  h-[500px] overflow-y-auto  bg-slate-100 dark:bg-slate-800">
                        <Users t={t} />
                    </div>
                </div>


            </div>
        </>
    );
}

export default CpScreen;
