import { useState } from "react"
import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "../ui/sheet"

export function SheetShad(props) {
    // const [open, setOpen] = useState(false)
    const { trigger, title, description, children, close, closeComponent } = props;
    return (
        <Sheet
        //  open={open}
        //  onOpenChange={setOpen}
         >
            <SheetTrigger asChild>
                {trigger}
            </SheetTrigger>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>{title}</SheetTitle>
                    <SheetDescription>
                        {description}
                    </SheetDescription>
                </SheetHeader>
                {children}
                <SheetFooter>
                    {closeComponent && <SheetClose asChild>
                        {closeComponent}
                    </SheetClose>}

                </SheetFooter>
            </SheetContent>
        </Sheet>
    )
}
