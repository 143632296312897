import React from "react";
import { DatePicker, ConfigProvider } from "antd";
import frFR from "antd/es/locale/fr_FR";
import moment from "moment";
import { FilterIcon } from "@heroicons/react/solid";
export default function RangePicker(props) {
  const { RangePicker } = DatePicker;
  const dateFormat = "DD-MM-YYYY";
  const { onCalendarChange, value, defaultStart, defaultEnd } = props;

  const convertToDefaultEventPara = (date, dateString) => ({
    startDate: date[0]?._d || null,
    endDate: date[1]?._d || null,
  });

  const startDate = defaultStart ? moment(`${defaultStart}`, dateFormat) : undefined;
  const endDate = defaultEnd ? moment(`${defaultEnd}`, dateFormat) : undefined;
  return (
    <ConfigProvider locale={frFR}>
      <RangePicker
        //defaultValue={defaultValue}
        //value={value ? [moment(`${value[0]}`, dateFormat), moment(`${value[1]}`, dateFormat)] : undefined}
        value={[startDate, endDate]}
        format={dateFormat}
        // onCalendarChange={((date, dateString) => onCalendarChange(convertToDefaultEventPara(date, dateString)))}
        onCalendarChange={(date, dateString) => onCalendarChange(dateString)}
        className={"dark:!bg-slate-800"}
        allowEmpty={[true, true]}
        disabledDate={(d) => !d || d.isAfter(moment().format("YYYY-MM-DD")) || d.isSameOrBefore("1960-01-01")}
        allowClear={false}
      />
    </ConfigProvider>
  );
}
