import React, { useEffect, useState } from "react";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import DashboardCard from "../components/cards/DashboardCard";
import TableauGrid from "../components/TableauGrid";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { authSelector } from "../features/authSlice";
import Loading from "../components/Loading";
import { InformationCircleIcon } from "@heroicons/react/solid";
import Controls from "../components/controls/Controls";
import ProgressBar from "../components/ProgressBar";
import { ReactComponent as Refer } from "../asset/refer.svg";
import BannerInfo from "../components/BannerInfo";
import DialogBox from "../components/DialogBox";
import { ChevronDownIcon } from "@heroicons/react/solid";
import * as service from "../utils/service";

function ParrainageScreen(props) {
    const { t } = props;
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const auth = useSelector(authSelector);
    const navigate = useNavigate();
    const { id } = useParams(); // maka id
    const [selectedRow, setSelectedRow] = useState();
    const [eligible, setEligible] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [children, setChildren] = useState();
    const [allData, setAllData] = useState();
    const [summary, setSummary] = useState();
    const [summarySelected, setSummarySelected] = useState();
    const openModal = () => {
        setIsOpen(true);
        setTimeout(() => {
            setIsOpen(false);
        }, 7000);
    };

    const CustomChip = ({ text, color }) => {
        return <div className={`border border-${color}-100 text-xs bg-${color}-500 text-white px-3 py-0.5 w-fit rounded-xl shadow`}>{text}</div>;
    };
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getGainsDetails = async (myId) => {
            try {
                const { data } = await axiosPrivate.get(`/api/affiliate/${myId}`, {
                    signal: controller.signal,
                });
                const decrypt = JSON.parse(service.decryptCBData(data));

                // console.log("ETO ywqgduiqwgduiwqd", decrypt.allAffiliate);
                isMounted && setAllData(decrypt);
                const decryptAffiliate = decrypt?.allAffiliate.map((a, i) => ({
                    ...a,
                    firstName: service.decryptCBData(a.firstName),
                    lastName: service.decryptCBData(a.lastName),
                    email: service.decryptCBData(a.email),
                    pnl: a.pnl.map((p, i) => ({
                        ...p,
                        firstName: service.decryptCBData(p.firstName),
                        lastName: service.decryptCBData(p.lastName),
                    })),
                }));
                isMounted && setChildren(decryptAffiliate);
                console.log("ETO yaaaaaaaa", decryptAffiliate);

                isMounted && setSummary(decrypt?.summary);
                for (let key in decrypt.parentDetail) {
                    if (key === "firstName" || key === "lastName") {
                        decrypt.parentDetail[key] = service.decryptCBData(decrypt.parentDetail[key]);
                    }
                }
                isMounted && setEligible(decrypt?.parentDetail?.eligible);
            } catch (error) {
                //console.log(error)
                if (error.response.status != 403) {
                    navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };

        if (auth?.role.includes(process.env.REACT_APP_CLIENT) || auth?.role.includes(process.env.REACT_APP_PARTENAIRE)) {
            getGainsDetails(auth?.id);
        } else {
            getGainsDetails(id);
        }
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const columns = [
        { field: "id", headerName: "ID" },
        { field: "email", headerName: t(`label.email`), width: 180, hide: auth?.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false },
        { field: "lastName", headerName: t(`label.lastName`), width: 180 },
        { field: "firstName", headerName: t(`label.firstName`) },
        { field: "platform", headerName: t(`label.platform`) ,
            valueGetter: (params) => {
                let parameter = params.row.platform;
                let plat = "";
                if (parameter == 1) {
                    plat = "Binance"
                }
                else if (parameter == 2) {
                    plat = "kraken"
                }
                else {
                    plat = null
                }

                return plat
            },

            renderCell: (params) => {
                return (
                    <>
                        {params.row.platform == 1 ? <div className="text-yellow-500 w-fit p-1 rounded-lg font-medium text-xs">BINANCE </div>
                            : params.row.platform == 2 ? <div className="text-purple-500 w-fit p-1 rounded-lg font-medium text-xs">KRAKEN </div>
                                : null}


                    </>
                );
            },

        },
        {
            field: "status",
            headerName: "Statut",
            width: 100,
            valueGetter: (params) => {
                if (params.row?.status == 0) {
                    return t(`text.inactive`);
                } else {
                    return t(`text.active`);
                }
            },
            renderCell: (cellValues) => {
                if (cellValues.row?.status == 0) {
                    return <CustomChip text={t(`text.inactive`)} color={"red"} />;
                } else {
                    return <CustomChip text={t(`text.active`)} color={"green"} />;
                }
            },
        },
        {
            field: "pallier",
            headerName: "Palier",
            headerAlign: "center",
            renderCell: (cellValues) => {
                if (cellValues.row.pallier == 200) {
                    return (
                        <div className="flex justify-center w-full">
                            <div className="w-3 h-3 bg-blue-600 mt-1 rounded-lg" />
                        </div>
                    );
                } else if (cellValues.row.pallier == 500) {
                    return (
                        <div className="flex justify-center w-full">
                            <div className=" w-3 h-3 bg-amber-300 mt-1 rounded-lg" />
                        </div>
                    );
                } else if (cellValues.row.pallier == 1000) {
                    return (
                        <div className="flex justify-center w-full">
                            <div className=" w-3 h-3 bg-orange-300 mt-1 rounded-lg" />
                        </div>
                    );
                } else {
                    return (
                        <div className="flex justify-center w-full">
                            <div className=" w-3 h-3 bg-lime-300 mt-1 rounded-lg" />
                        </div>
                    );
                }
            },
        },
        {
            field: "percentage",
            headerName: `% ${t(`label.sumPaidAll`)}`,
            width: 200,
            headerAlign: "center",
            renderCell: (cellValues) => {
                if (cellValues.row.pallier !== 100000) {
                    if (cellValues.row.percentage) {
                        return <ProgressBar percentage={cellValues.row.percentage} pallier={cellValues.row.pallier} />;
                    } else {
                        return <ProgressBar percentage={0} pallier={cellValues.row.pallier} />;
                    }
                }
            },
        },
        {
            field: "comm",
            headerName: t(`label.paid`),
            width: 150,
            headerAlign: "center",

            renderCell: (cellValues) => {
                return (
                    <div className="text-center w-full ">
                        {(cellValues.row.summary.allPnlPaidSingle).toFixed(2)}
                        <span className="text-xs ml-1 font-light">USDT</span>
                    </div>
                );
            },
        },
        {
            field: "pnl",
            headerName: `${t(`label.to`)} ${t(`label.pay`)}`,
            width: 150,
            headerAlign: "center",

            renderCell: (cellValues) => {
                return (
                    <div className="text-center w-full ">
                        {cellValues.row.summary.allPnlUnpaidSingle}
                        <span className="text-xs ml-1 font-light">USDT</span>
                    </div>
                );
            },
        },
        {
            field: "test",
            headerName: t(`label.reported`),
            width: 150,
            headerAlign: "center",

            renderCell: (cellValues) => {
                return (
                    <div className="text-center w-full ">
                        {cellValues.row.summary.allPnlReportedSingle}
                        <span className="text-xs ml-1 font-light">USDT</span>
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-primary dark:text-slate-100 hover:text-secondary"}
                        text={"Détail"}
                        onClick={(event) => {
                            //const dataToShow = allData.pnlList.filter((c) => c.uid == cellValues.row.id);
                            const dataToShow = cellValues.row.pnl;
                            // console.log(cellValues.row.pnl);
                            setSummarySelected(cellValues.row.summary);
                            setSelectedRow(dataToShow);
                            setIsOpen2(true);
                        }}
                    />
                );
            },
        },
    ];

    let formToexcel = children?.map((d, i) => {
        return {
            email: d.email,
            nom: d.lastName,
            prenom: d.firstName

        }
    })

    const columnsDetails = [
        {
            field: "createdAt",
            headerName: t(`label.RebalDate`),
            type: "date",
            width: 200,
            valueGetter: (params) => {
                return moment(params.row?.createdAt).format("DD/MM/YYYY");
            },
        },
        { field: "id", headerName: "ID", width: 200, hide: true },
        { field: "totalOnePercentUSDT", headerName: `${t(`label.amount`)} USDT`, width: 170 },
        {
            field: "status",
            headerName: "Statut",
            width: 160,
            renderCell: (cellValues) => {
                if (cellValues.row?.status === "Payé") {
                    return <CustomChip text={t(`label.paid`)} color={"sky"} />;
                } else if (cellValues.row?.status === "A payer") {
                    return <CustomChip text={`${t(`label.to`)} ${t(`label.pay`)}`} color={"purple"} />;
                } else if (cellValues.row?.status === "Reporté") {
                    return <CustomChip text={t(`label.reported`)} color={"yellow"} />;
                } else if (cellValues.row?.status === "Inéligible") {
                    return <CustomChip text={t(`label.ineligible`)} color={"gray"} />;
                }
            },
        },
        {
            field: "paidDate",
            headerName: t(`label.PaidDate`),
            width: 200,
            valueGetter: (params) => {
                return moment(params.row?.paidDate, "DD-MM-YYYY").format("DD/MM/YYYY");
            },
            renderCell: (cellValues) => {
                if (!cellValues.row.paidDate) return "-";
                return moment(cellValues.row?.paidDate, "DD-MM-YYYY").format("DD/MM/YYYY");
            },
        },
    ];

    const [copied, setCopied] = useState(false);
    const [isTextShown, setIsTextShown] = useState(false);
    const [isCopyLinkPressed, setIsCopyLinkPressed] = useState(false);
    const [isTextCopy, setIsTextCopy] = useState(false);
    let [textArea, setTextArea] = useState();
    const copyToClipboard = () => {
        navigator.clipboard.writeText(allData && allData?.parentDetail?.code).then(
            () => {
                setCopied(true);
                // changing back to default state after 2 seconds.
                setTimeout(() => {
                    setCopied(false);
                }, 5000);
            },
            (err) => {
                console.log("failed to copy", err.mesage);
            }
        );
    };

    const handleTextShow = () => {
        setIsTextShown(!isTextShown);
    };

    // function to copy link parrainage
    const copyLink = (link) => {
        navigator.clipboard.writeText(link).then(
            () => {
                setIsCopyLinkPressed(true);
                // changing back to default state after 2 seconds.
                setTimeout(() => {
                    setIsCopyLinkPressed(false);
                }, 5000);
            },
            (err) => {
                console.log("failed to copy", err.mesage);
            }
        );
    };

    const textToCopy = () => {
        setIsTextCopy(true);
        const el = textArea;
        el.select();

        //document.execCommand("copy");
        navigator.clipboard.writeText(el.innerHTML);
        //console.log(el.innerHTML);

        setTimeout(() => {
            setIsTextCopy(false);
        }, 5000);
    };

    return (
        <>
            <DialogBox isOpen={isOpen} setIsOpen={setIsOpen} title={t(`info.eligibilityCriteria`)}>
                <div className="p-4 ml-5 text-md leading-relaxed text-gray-500 dark:text-gray-400">
                    <div>- {t(`info.activeUser`)}</div>
                    <div>- {t(`info.paymentUpToDate`)}</div>
                    <div>- {t(`info.capitalMorePNLParrain`)}</div>
                </div>
            </DialogBox>
            <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />

            <div className="flex flex-row flex-grow">
                <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />

                <div className="flex flex-row flex-1 md:ml-52">
                    <div className="flex flex-col w-full p-4 ">
                        <div className="flex-none h-auto">
                            <div className="flex gap-x-2 items-center">
                                <div className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.referal`)}</div>
                                {/* <span className="text-xs text-slate-500 dark:text-slate-400  ">{moment().format("Do MMMM YYYY")}</span> */}
                                <div className={`w-3 h-3  ${eligible && eligible ? "bg-lime-500" : "bg-red-600"} rounded-lg`} />

                                <div>
                                    <InformationCircleIcon className="h-4 w-4 text-slate-600 hover:cursor-pointer" onClick={() => setIsOpen(true)} />
                                </div>

                                <hr />
                            </div>
                        </div>
                        <div className="flex items-center justify-between space-x-2">
                            <div className="flex flex-col ml-5">
                                <div className="flex items-center text-xs  dark:text-slate-50 gap-x-4">
                                    <span className="font-semibold">{t(`label.referalCode`)} :</span>
                                    {/* <div className="bg-yellow-500 p-1 w-fit rounded-xl shadow">{allData && allData.parentDetail?.code}</div> */}
                                    {allData && allData.parentDetail?.code ? (
                                        <div className="bg-yellow-300 p-1 w-fit rounded-xl shadow">{allData && allData.parentDetail?.code}</div>
                                    ) : (
                                        <div className="bg-yellow-500 p-1 animate-pulse rounded-xl shadow w-20 h-8"></div>
                                    )}
                                    <button
                                        onClick={copyToClipboard}
                                        className={`${copied ? "bg-gray-500 text-white" : ""}  text-sm border border-gray-500 hover:bg-gray-500 hover:text-white rounded h-7 px-1 transition dark:text-slate-50 `}
                                    >
                                        {copied ? t(`button.copied`) : t(`button.copy`)}
                                    </button>
                                </div>
                                <div className="flex items-center text-xs gap-x-4">
                                    <span className="font-semibold">{t(`label.subscriptionLink`)} : </span>
                                    <a href="https://bdc.crypto-bulot.com/offre-parrainage/" target="_blank" className="text-xs">
                                        https://bdc.crypto-bulot.com/offre-parrainage/
                                    </a>
                                    <button
                                        onClick={() => copyLink("https://bdc.crypto-bulot.com/offre-parrainage/")}
                                        className={`${isCopyLinkPressed ? "bg-gray-500 text-white" : ""
                                            }  text-sm border border-gray-500 hover:bg-gray-500 hover:text-white rounded h-7 px-1 transition dark:text-slate-50 `}
                                    >
                                        {isCopyLinkPressed ? t(`button.copied`) : t(`button.copy`)}
                                    </button>
                                </div>
                                <div className="flex items-center gap-x-6">
                                    <div className="flex items-center text-xs font-semibold">
                                        {t(`label.textToCopy`)} <ChevronDownIcon className="ml-1 w-4 border border-slate-500 rounded transition-all duration-200 delay-200" onClick={handleTextShow} />
                                    </div>

                                    <button
                                        onClick={() => textToCopy()}
                                        className={`${isTextCopy ? "bg-gray-500 text-white" : ""}  text-sm border border-gray-500 hover:bg-gray-500 hover:text-white rounded h-7 px-1 transition dark:text-slate-50 `}
                                    >
                                        {isTextCopy ? t(`button.copied`) : t(`button.copy`)}
                                    </button>
                                </div>
                                <div className={`text-xs bg-slate-50 my-2 p-4 rounded ${isTextShown ? "block" : "hidden"} transition duration-2000`}>
                                    <div>
                                        <textarea
                                            ref={(textarea) => (textArea = textarea)}
                                            className=" w-full whitespace-pre-line h-fit hidden"
                                            value={`Bonjour,\n\nJe te partage ci dessous mon code de parrainage pour Crypto-Bulot\n\nCrypto Bulot est un service d’épargne en cryptomonnaie qui rééquilibre ton portefeuille Binance une fois par mois.\n\nEn passant par mon lien, les frais d’inscription sont offerts (au lieu de 200€ si tu t’inscris de ton côté).\n\nPour t’inscrire, tu n’as qu’à suivre les instructions suivantes:\n1. Clique sur le lien suivant: https://bdc.crypto-bulot.com/offre-parrainage/\n2.Complète tes informations personnelles\n3.Insère mon nom et prénom dans la section correspondante: ${allData &&
                                                allData.parentDetail?.firstName} ${allData && allData.parentDetail?.lastName}\n4.Insère mon code de parrainage dans la section correspondante: ${allData &&
                                                allData.parentDetail?.code}\n\nA très vite,\n
${allData && allData.parentDetail?.firstName} ${allData && allData.parentDetail?.lastName}  `}
                                        />
                                    </div>

                                    <div className="">
                                        {t(`label.hello`)},
                                        <br /> {t(`text.shareCode`)}
                                        <br />
                                        <br />
                                        {t(`text.cb`)}
                                        <br />
                                        <br /> {t(`text.subscriptionLinkPass`)}
                                        <br />{t(`text.forSubscription`)}
                                        <br />
                                        <br />
                                        1. {t(`text.clicLink`)} : <span className="text-blue-500">https://bdc.crypto-bulot.com/offre-parrainage/</span>
                                        <br />
                                        2. {t(`text.personalInfo`)}
                                        <br />
                                        3. {t(`text.insertMyInfo`)} :
                                        <strong>
                                            {allData && allData.parentDetail?.firstName} {allData && allData.parentDetail?.lastName}
                                        </strong>
                                        <br />
                                        4. {t(`text.insertMyReferralCode`)} :
                                        <strong>{allData && allData.parentDetail?.code}</strong>
                                        <br />
                                        <br />{t(`text.seeYou`)}
                                        <br /> {allData && allData.parentDetail?.firstName} {allData && allData.parentDetail?.lastName}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {children && children.length !== 0 ? (
                            <>
                                {" "}
                                <div className="flex flex-wrap justify-end gap-2 my-2">
                                    <DashboardCard bg={"bg-blue-500"} description={t(`label.children`)} number={children?.length} />

                                    <DashboardCard bg={"bg-indigo-500"} description={t(`label.totalGains`)} number={summary?.allPnlSum} unit="USDT" />

                                    <DashboardCard bg={"bg-blue-600"} description={t(`label.gainsPayés`)} number={summary?.allPnlPaid} unit="USDT" />
                                    <DashboardCard bg={"bg-indigo-600"} description={t(`label.unpaid`)} number={summary?.allPnlUnpaid} unit="USDT" />
                                    <DashboardCard bg={"bg-sky-500"} description={t(`label.gainsReported`)} number={summary?.allPnlReported} unit="USDT" />
                                </div>
                                <div className=" ">
                                    {auth?.role.includes(process.env.REACT_APP_SUPADMIN) &&
                                        <div className="mb-2">
                                            <Controls.Button text={t("label.export")} small xsFont onClick={() => service.exportExcel(formToexcel && formToexcel, `filleul de ${allData?.parentDetail?.lastName}.xlsx`)} />
                                        </div>
                                    }
                                    {/* => Palier */}
                                    <div className="flex gap-5 text-xs items-center">
                                        <div className="flex space-x-1">
                                            <div className="w-3 h-3 bg-blue-600 mt-1 rounded-lg"></div>
                                            <div className="dark:text-slate-50">{t(`text.until`)} 200 USDT</div>
                                        </div>
                                        <div className="flex space-x-1">
                                            <div className=" w-3 h-3 bg-amber-300 mt-1 rounded-lg"></div>
                                            <div className="dark:text-slate-50">{t(`text.until`)} 500 USDT</div>
                                        </div>
                                        <div className="flex space-x-1">
                                            <div className="w-3 h-3 bg-orange-500 mt-1 rounded-lg"></div>
                                            <div className="dark:text-slate-50">{t(`text.until`)} 1000 USDT</div>
                                        </div>
                                        <div className="flex space-x-1">
                                            <div className="w-3 h-3  bg-lime-500 mt-1 rounded-lg"></div>
                                            <div className="dark:text-slate-50">{t(`text.unlimited`)}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* Tableau liste filleuls */}
                                <div className="h-[60vh] bg-slate-100">{children && children ? <TableauGrid column={columns} dataRows={children && children} /> : <Loading />}</div>
                                {/* tableau détaillé de chaque filleuls */}
                                <DialogBox
                                    isOpen={isOpen2}
                                    setIsOpen={setIsOpen2}
                                    // title={"Détails"}
                                    customClassName={"md:w-3/5 w-full  min-h-[300px]"}
                                >
                                    {selectedRow ? (
                                        <>
                                            <div className="h-[80vh] bg-slate-100 my-2 p-2 flex flex-col ">
                                                {/* Card détaillé pour chaque filleuls */}

                                                {selectedRow && selectedRow.length !== 0 ? (
                                                    <>
                                                        <div className=" flex justify-between text-lg font-light">
                                                            <div className="  text-md font-light">
                                                                {t(`text.detailsFor`)}
                                                                <span className="font-semibold ml-3">
                                                                    {selectedRow[0]?.lastName} {selectedRow[0]?.firstName}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap justify-end gap-2 my-2">
                                                            {/* <TableauGrid column={columns} dataRows={allEmail && allEmail} /> */}

                                                            <DashboardCard bg={"bg-indigo-600"} description={t(`label.totalGains`)} number={summarySelected?.allPnlSumSingle.toFixed(2)} unit="USDT" />

                                                            <DashboardCard bg={"bg-blue-600"} description={t(`label.gainsPayés`)} number={summarySelected?.allPnlPaidSingle.toFixed(2)} unit="USDT" />
                                                            <DashboardCard bg={"bg-sky-600"} description={t(`label.gainsEnAttente`)} number={summarySelected?.allPnlUnpaidSingle.toFixed(2)} unit="USDT" />
                                                            <DashboardCard bg={"bg-blue-600"} description={t(`label.gainsReported`)} number={summarySelected?.allPnlReportedSingle.toFixed(2)} unit="USDT" />
                                                        </div>
                                                        <TableauGrid column={columnsDetails} dataRows={selectedRow && selectedRow} />
                                                    </>
                                                ) : (
                                                    <BannerInfo text={t(`info.noPnl`)} type="info" />
                                                )}
                                            </div>
                                        </>
                                    ) : null}
                                </DialogBox>
                            </>
                        ) : (
                            <>
                                <BannerInfo text={t(`info.noFilleuls`)} type="info" />
                                <div className="w-1/3 mx-auto ">
                                    <Refer />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ParrainageScreen;
