import {
    ClipboardListIcon, ShieldCheckIcon, ViewGridIcon,
    UserGroupIcon,
    CreditCardIcon,
    SwitchHorizontalIcon,
    ViewBoardsIcon,
    ScaleIcon,
    ColorSwatchIcon,
    ArrowNarrowLeftIcon,
    ClipboardCheckIcon,
    PresentationChartLineIcon,
    TrendingUpIcon,
    BriefcaseIcon,
    CalendarIcon,
    CollectionIcon
} from "@heroicons/react/outline";
import { BellPlus } from "lucide-react";
// import {
//     ViewGridIcon,
//     UserGroupIcon,
//     CreditCardIcon,
//     SwitchHorizontalIcon,
//     ViewBoardsIcon,
//     ScaleIcon,
//     ColorSwatchIcon,
//     ArrowNarrowLeftIcon,
//     ClipboardCheckIcon,
//     PresentationChartLineIcon,
//     TrendingUpIcon,
//     BriefcaseIcon,
//     CalendarIcon
// } from "@heroicons/react/solid";

export const menusAdmin = (t) => {
    return [
        { name: t("label.dashboard"), icon: <ViewGridIcon className="h-5 w-5 text-slate-500" />, path: "/admin/dashboard" },
        { name: t("label.client"), icon: <UserGroupIcon className="h-5 w-5 text-slate-500" />, path: "/admin/users" },
        { name: t("label.payment"), icon: <CreditCardIcon className="h-5 w-5 text-slate-500" />, path: "/admin/payments" },
        {
            name: t("label.transaction"),
            icon: <SwitchHorizontalIcon className="h-5 w-5 text-slate-500" />,
            path: "/admin/transactions",
        },
        { name: t("label.onBoarding"), icon: <ViewBoardsIcon className="h-5 w-5 text-slate-500" />, path: "/onboarding" },
    ];
};

export const menusSupport = (t, ticketCount = 0) => {
    return [
        { name: t("label.client"), icon: <ViewGridIcon className="h-5 w-5 text-slate-500" />, path: "/support/dashboard" },
        // { name: t("label.lbUsers"), icon: <UserGroupIcon className="h-5 w-5 text-slate-500" />, path: "/support/lb" },
        { name: t("label.ressource"), icon: <ColorSwatchIcon className="h-5 w-5 text-slate-500" />, path: "/support/manage-resources" },
        { name: t("label.manageNotif"), icon: <BellPlus className="h-5 w-5 text-slate-500" />, path: "/support/manage-notif" },
        {
            name: 'Suivi', icon: <ClipboardListIcon className="h-5 w-5 text-slate-500" />, path: `/board/4aa03c5f-2b8a-11ee-99ee-00155d821527`, notif: `${ticketCount}`
        },
    ];
};

// menu for clients
export const menusClient = (t, id, role) => {
    switch (true) {
        case role.length == 1 && role.includes(process.env.REACT_APP_PARTENAIRE):
            return [
                {
                    name: t("label.parrain"),
                    icon: <UserGroupIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/affiliate/${id}` : `/affiliate`,
                },
                {
                    name: t("label.payment"),
                    icon: <CreditCardIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/transaction/${id}` : `/transaction`,
                },
            ];

        case role.length == 1 && (role.includes(process.env.REACT_APP_ADMIN) || role.includes(process.env.REACT_APP_SUPADMIN)):
            return [
                {
                    name: "TDB Admin",
                    icon: <ArrowNarrowLeftIcon className="h-5 w-5 text-blue-500" />,
                    path: `/admin/dashboard`,
                },

                {
                    name: t("label.dashboard"),
                    icon: <ViewGridIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/dashboard/${id}` : `/dashboard`,
                },
                // {
                //   name: t("label.wallet"),
                //   icon: <BriefcaseIcon className="h-5 w-5 text-slate-500" />,
                //   path: id ? `/wallet/${id}` : `/wallet`,
                // },

                {
                    name: t("label.rebalancing"),
                    icon: <ScaleIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/rebalancing/${id}` : `/rebalancing`,
                },
                {
                    name: t("label.evolution"),
                    icon: <PresentationChartLineIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/evolution/${id}` : `/evolution`,
                },
                {
                    name: t("label.indexCbt"),
                    icon: <TrendingUpIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/index-cbt/${id}` : `/index-cbt`,
                },
                {
                    name: t("label.parrain"),
                    icon: <UserGroupIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/affiliate/${id}` : `/affiliate`,
                },
                {
                    name: t("label.payment"),
                    icon: <CreditCardIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/transaction/${id}` : `/transaction`,
                },
                {
                    name: t("label.securePnl"),
                    icon: <CreditCardIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/secure/${id}` : `/secure`,
                },
                {
                    name: t("label.multiAccount"),
                    icon: <CollectionIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/sub-account/${id}` : `/sub-account`,
                },
                {
                    name: t("label.ressource"),
                    icon: <ColorSwatchIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/resources/${id}` : `/resources`,
                },
                {
                    name: t("label.contactUs"),
                    icon: <CalendarIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/contact/${id}` : `/contact`,
                },

            ];

        default:
            return [
                {
                    name: t("label.dashboard"),
                    icon: <ViewGridIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/dashboard/${id}` : `/dashboard`,
                },
                // {
                //   name: t("label.wallet"),
                //   icon: <BriefcaseIcon className="h-5 w-5 text-slate-500" />,
                //   path: id ? `/wallet/${id}` : `/wallet`,
                // },
                {
                    name: t("label.rebalancing"),
                    icon: <ScaleIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/rebalancing/${id}` : `/rebalancing`,
                },
                {
                    name: t("label.evolution"),
                    icon: <PresentationChartLineIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/evolution/${id}` : `/evolution`,
                },
                {
                    name: t("label.indexCbt"),
                    icon: <TrendingUpIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/index-cbt/${id}` : `/index-cbt`,
                },
                {
                    name: t("label.parrain"),
                    icon: <UserGroupIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/affiliate/${id}` : `/affiliate`,
                },
                {
                    name: t("label.payment"),
                    icon: <CreditCardIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/transaction/${id}` : `/transaction`,
                },
                {
                    name: t("label.secure"),
                    icon: <ClipboardCheckIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/secure/${id}` : `/secure`,
                },

                {
                    name: ("Prévoyance"),
                    icon: <ShieldCheckIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/heritage/${id}` : `/heritage`,
                },
                {
                    name: t("label.multiAccount"),
                    icon: <CollectionIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/sub-account/${id}` : `/sub-account`,
                },
                {
                    name: t("label.ressource"),
                    icon: <ColorSwatchIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/resources/${id}` : `/resources`,
                },
                {
                    name: t("label.contactUs"),
                    icon: <CalendarIcon className="h-5 w-5 text-slate-500" />,
                    path: id ? `/contact/${id}` : `/contact`,
                },


            ];
    }
    if (role.length == 1 && role.includes(process.env.REACT_APP_PARTENAIRE)) {
        return [
            {
                name: t("label.parrain"),
                icon: <UserGroupIcon className="h-5 w-5 text-slate-500" />,
                path: id ? `/affiliate/${id}` : `/affiliate`,
            },
            {
                name: t("label.payment"),
                icon: <CreditCardIcon className="h-5 w-5 text-slate-500" />,
                path: id ? `/transaction/${id}` : `/transaction`,
            },
        ];
    }
    return [
        {
            name: t("label.dashboard"),
            icon: <ViewGridIcon className="h-5 w-5 text-slate-500" />,
            path: id ? `/dashboard/${id}` : `/dashboard`,
        },
        {
            name: t("label.rebalancing"),
            icon: <ScaleIcon className="h-5 w-5 text-slate-500" />,
            path: id ? `/rebalancing/${id}` : `/rebalancing`,
        },
        {
            name: t("label.parrain"),
            icon: <UserGroupIcon className="h-5 w-5 text-slate-500" />,
            path: id ? `/affiliate/${id}` : `/affiliate`,
        },
        {
            name: t("label.payment"),
            icon: <CreditCardIcon className="h-5 w-5 text-slate-500" />,
            path: id ? `/transaction/${id}` : `/transaction`,
        },
        // {
        //   name: t("label.wallet"),
        //   icon: <ColorSwatchIcon className="h-5 w-5 text-slate-500" />,
        //   path: id ? `/resources/${id}` : `/resources`,
        // },
    ];
};

//menu parrain only

// menu for parrain
export const menusParrain = (t, id) => {
    return [
        {
            name: t("label.parrain"),
            icon: <UserGroupIcon className="h-5 w-5 text-slate-500" />,
            path: id ? `/affiliate/${id}` : `/affiliate`,
        },
        {
            name: t("label.payment"),
            icon: <CreditCardIcon className="h-5 w-5 text-slate-500" />,
            path: id ? `/transaction/${id}` : `/transaction`,
        },
    ];
};
