import React from 'react'
import moment from "moment"
import 'moment/locale/fr'
import Users from "../Users"
import Sidebar from '../Sidebar'
import Controls from '../controls/Controls';
import { ChevronLeftIcon } from '@heroicons/react/solid'
import Header from "../Header"

import * as sideMenus from "../../utils/sideMenus"
import { useNavigate, useLocation } from 'react-router-dom';
import TopBar from '../TopBar'

function ListUsers(props) {
    const { t } = props
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    return (
        <>

            <TopBar menus={sideMenus.menusAdmin(t)} />

            <div className='flex flex-row  flex-grow'>
                <Sidebar menus={sideMenus.menusAdmin(t)} />
                <div className='flex flex-col flex-1 md:ml-52 mt-2'>

                    <div className='flex flex-col w-full p-4'>
                        <div className='flex-none h-auto'>
                            <div className='flex items-center  gap-x-1'>
                                <ChevronLeftIcon className='h-6 rounded-full hover:bg-slate-300 cursor-pointer' onClick={() => goBack()} />
                                <div className='font-semibold text-2xl text-slate-900 dark:text-white'>{t('label.client')}</div>
                            </div>

                            <span className='text-xs text-slate-500 dark:text-slate-400  '>{moment().format("Do MMMM YYYY")}</span>

                            <hr />
                            {/* 
                            <div className='m-2 flex space-x-2 justify-end'>
                                <Controls.Button text={t('button.addClient')} small onClick={() => navigate('/admin/user/new')} xsFont />

                            </div> */}
                        </div>
                        <div className='h-[500px] bg-slate-100 dark:bg-slate-800'>
                            <Users t={t} />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ListUsers