import { clsx, } from "clsx"
import { twMerge } from "tailwind-merge"
import { axiosPrivate } from "../axios";

export function cn(...inputs) {
    return twMerge(clsx(inputs))
}


export async function addTagToMailerLite(data) {
    try {
        await axiosPrivate.post(`/api/onboarding/add-tags`, data)
        console.log("addTagToMailerLite", data);
    } catch (error) {
        console.error("addTagToMailerLite", error);
    }
}
