import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { ChevronRight } from "@mui/icons-material";
import { authSelector } from "../features/authSlice";
import { useSelector } from "react-redux";

function SettingScreen(props) {
    const { t } = props;
    const navigate = useNavigate();
    const auth = useSelector(authSelector);
    const goBack = () => navigate(-1);
    const ListSetting = ({ path, label }) => (
        <div className="bg-slate-100 mx-auto mt-4 p-2 rounded max-w-md flex ">
            <div className="flex-1 cursor-pointer hover:text-sky-500" onClick={() => navigate(path)}>
                {/* {t(`label.resetPassword`)} */}
                {label}
            </div>
            <ChevronRight className="h-5 w-5 cursor-pointer hover:text-sky-500" onClick={() => navigate(path)} />
        </div>
    );
    return (
        <div className="p-4">
            <div className="flex items-center">
                <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
                    <ArrowNarrowLeftIcon className="h-5 w-5 hover:cursor-pointer" onClick={goBack} />
                </div>
                <div className="font-semibold ml-2">{t("label.setting")}</div>
            </div>

            {/* <div className="bg-slate-100 mx-auto mt-4 p-2 rounded max-w-md flex ">
        <div className="flex-1 cursor-pointer hover:text-sky-500" onClick={() => navigate("/secure")}>
          {t(`label.securePnl`)}
        </div>
        <ChevronRight className="h-5 w-5 cursor-pointer hover:text-sky-500" onClick={() => navigate("/secure")} />
      </div> */}
            {auth.role.includes(process.env.REACT_APP_CLIENT) ? <ListSetting path="/absolute" label={t(`label.myAccount`)} /> : null}
            
            <ListSetting path="/reauth" label={t(`label.accountSecurity`)} />
            <ListSetting path="/reset" label={t(`label.resetPassword`)} />

            {auth.role.includes(process.env.REACT_APP_CLIENT) ? <ListSetting path="/user-api" label={t(`label.apiSetting`)} /> : null}
            {/* {auth.role.includes(process.env.REACT_APP_CLIENT) ? <ListSetting path="/absolute" label={"Bulot absolu"} /> : null} */}
        </div>
    );
}

export default SettingScreen;
