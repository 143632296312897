import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { onlineMemberSelector, setOnlineMember } from '../features/onlineMemberSlice';
import { socketSelector } from '../features/socketSlice';
import socket from '../utils/socket';

function ConnectedUser() {


    const onlineMember = useSelector(onlineMemberSelector)
    const dispatch = useDispatch();
    useEffect(() => {
        socket.on("all_users", (connectedUsers) => {
            dispatch(setOnlineMember(connectedUsers));
        });
        return () => {
            socket.off('all_users');
        };
    }, [])

    return (
        <div className='p-4 bg-red-300 text-xs'>Online : {onlineMember?.length} </div>
    )
}

export default ConnectedUser
