import React from 'react'
import { useTranslation } from 'react-i18next'
import BtnShad from './client/btnShadcn'

function BtnNextPrev(props) {
    const { noPrev, step, setStep } = props
    const { t } = useTranslation()

    const previousStep = () => {
        setStep(step - 1)
    }
    const nextStep = () => {
        setStep(step + 1)
    }
    return (
        <div className='flex justify-between'>
            <BtnShad text={t(`button.previous`)} size={"sm"} variant="destructive" width={"md:w-40"} onClick={previousStep}  />
            <BtnShad text={t(`button.next`)} size={"sm"} variant="destructive" width={"md:w-40"} onClick={nextStep} />

        </div>
    )
}

export default BtnNextPrev
