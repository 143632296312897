import React, { useState } from 'react'
import { TypographyH3, TypographySmall } from '../components/Typo'
import BtnShad from '../components/client/btnShadcn'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { authTokenSelector } from '../features/authSlice'

function GraphScreen() {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const token = useSelector(authTokenSelector)

    const redirectGraph = () => {
        const targetOrigin = `http://localhost:5173?search=${encodeURIComponent(token)}`
        // const targetWindow = window.open(targetOrigin)

        // targetWindow.postMessage({ authToken: "1234" }, targetOrigin)
        window.open(targetOrigin)
    }

    return (
        <div className="p-4  gap-3 h-[93vh] ">
            <div className=" bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 h-full ">
                <TypographyH3 className="font-bold text-xl text-slate-700 dark:text-slate-100">Graphe</TypographyH3>
                <div className="container mx-auto ">
                    <TypographySmall>Le nombre de nouveau rééquilibrage par jour</TypographySmall>
                    <BtnShad text={"Afficher la graphe"} onClick={redirectGraph} isLoading={isLoading} />
                </div>
            </div>
        </div>
    )
}

export default GraphScreen
