import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import * as sideMenus from "../utils/sideMenus";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Loading from "../components/Loading";
import OnboardingWeek from "../components/admin/OnboardingWeek";
import OnboardingMonth from "../components/admin/OnboardingMonth";
import { ChevronLeftIcon } from "@heroicons/react/solid";

const CustomTitle = ({ text }) => (
  <div className="text-slate-800 dark:text-slate-100 font-bold text-xl px-2 ">{text}</div>
);
function OnboardingScreen(props) {
  const { t } = props;
  const [rows, setRows] = useState();
  const [weekRows, setweekRows] = useState();
  const [filter, setFilter] = useState("week");
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const goBack = () => navigate(-1);

  // useEffect(() => {
  // 	let isMounted = true;
  // 	const controller = new AbortController();
  // 	const getOnboardingData = async () => {
  // 		try {
  // 			console.log("week fsew");
  // 			const response = await axiosPrivate.get(`/api/reporting/onboarding/week/4`, {
  // 				signal: controller.signal,
  // 			});

  // 			console.log("week", response.data);
  // 			isMounted && setRows(response.data);
  // 		} catch (error) {
  // 			if (error.response.status != 403) {
  // 				navigate("/login", { state: { from: location }, replace: true });
  // 			}
  // 		}
  // 	};
  // 	getOnboardingData();
  // 	return () => {
  // 		isMounted = false;
  // 		controller.abort();
  // 	};
  // }, []);

  const MyFilter = ({ text, filterOption }) => (
    <div
      className={`${
        filterOption === filter ? "bg-indigo-500 text-white" : "bg-blue-50"
      } h-fit p-1 font-semibold rounded-md text-sm hover:bg-blue-100 hover:cursor-pointer min-w-[100px] text-center`}
      onClick={() => setFilter(filterOption)}
    >
      {text}
    </div>
  );

  return (
    <>
      <TopBar menus={sideMenus.menusAdmin(t)} />
      <div className="flex flex-row flex-grow ">
        <Sidebar menus={sideMenus.menusAdmin(t)} />

        <div className="flex flex-row flex-1  mt-2 md:ml-52">
          <div className="flex flex-col w-full p-4 gap-y-10">
            <div className="flex-none h-auto">
              <div className="flex justify-between">
                <div>
                  <div className="flex items-center  gap-x-1">
                    <ChevronLeftIcon
                      className="h-6 rounded-full hover:bg-slate-300 cursor-pointer"
                      onClick={() => goBack()}
                    />
                    <div className="font-semibold text-2xl text-slate-900 dark:text-white">{t("label.onBoarding")}</div>
                  </div>

                  <span className="text-xs text-slate-500 dark:text-slate-400  ">
                    {moment().format("Do MMMM YYYY")}
                  </span>
                </div>
                <div className="flex gap-x-2">
                  <MyFilter text={"Semaine"} filterOption={"week"} />
                  <MyFilter text={"Mois"} filterOption={"month"} />
                </div>
              </div>

              <hr />
            </div>

            {filter === "week" ? (
              <div>
                <OnboardingWeek t={t} />
              </div>
            ) : (
              <div>
                <OnboardingMonth t={t} />
              </div>
            )}

            {/*
                        <div className='flex flex-col gap-y-5 h-[450px] bg-slate-100 dark:bg-slate-800 mt-2'>
                            <CustomTitle text='Inscription' />
                            {rows ? (<TableauGrid column={columns} dataRows={rows && rows.details} />) : (<Loading />)}
                        </div>

                        <div className='flex flex-col gap-y-5 h-[450px] bg-slate-100 dark:bg-slate-800 mt-2'>
                            <CustomTitle text='Durée' />


                            {rows ? (<TableauGrid column={columnsDuration} dataRows={rows && rows.details} />) : (<Loading />)}

                        </div>

                        <div className=' flex flex-col gap-y-5 h-[450px] bg-slate-100 dark:bg-slate-800 mt-2'>

                            <CustomTitle text='Capital' />
                            {rows ? (<TableauGrid column={columnsCapital} dataRows={rows && rows.details} />) : (<Loading />)}
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default OnboardingScreen;
