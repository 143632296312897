import React from "react";
import Dashboard from "../components/admin/Dashboard";

function DashboardScreen(props) {
  const { t } = props;
  return (
    <>
      <Dashboard t={t} />
    </>
  );
}

export default DashboardScreen;
