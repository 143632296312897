import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authSelector } from '../features/authSlice';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { testApi } from '../utils/apiService';
import Controls from './controls/Controls'
import { useFOrm } from './useFOrm';
import * as apiService from "../utils/apiService"
import { decryptOneUserInfo } from '../utils/service';
import moment from 'moment';
import { ExclamationCircleIcon, ExclamationIcon } from '@heroicons/react/solid';
import Loading from './Loading';
import BtnShad from './client/btnShadcn';


const initialValues = {
    platform: "",
    strategy: "normal",
    product: "",
    plan: "",
    totalDuration: "",
    specialPrice: "",
    chooseDate: "",
    startDate: moment().add(1, "days").format("DD-MM-YYYY")
};

function AddSub(props) {
    const { subscriptionUser, allProduct } = props
    const { t } = useTranslation()
    const [inputDateRef, setInputDateRef] = useState();
    const [availablePlan, setAvailablePlan] = useState([]);
    const [isTestLoading, setIsTestLoading] = useState(false);
    const [testApiW, setTestApiW] = useState(false);
    const [errorApiW, setErrorApiW] = useState("");
    const [step, setStep] = useState(0)
    const dateRefInputRef = useRef(null);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const auth = useSelector(authSelector);
    const [oneUser, setOneUser] = useState(
        {
            lastReeq: null,
            lastDayReeq: moment().add(1, 'days').format("DD"),
            status: '',
            nextReeq: null
        }
    )
    initialValues.totalDuration = 999999
    const handleInputDateRef = (event) => {
        setInputDateRef(event.target.value)
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("strategy" in fieldValues) temp.strategy = fieldValues.strategy ? "" : t("error.required");
        if ("platform" in fieldValues) temp.platform = fieldValues.platform ? "" : t("error.required");
        setErrors({
            ...temp,
        });
        console.log("Temp", temp);

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);

    useEffect(() => {
        if (values.product) {
            const oneProduct = allProduct?.find((q) => (q.id == values?.product))
            let planArray = Object.entries(oneProduct?.plan)
                .map(([key, values]) => ({
                    id: key,
                    title: `  ${values} USDT / ${t(`label.${key}`)}`,
                    price: values
                }))
            setAvailablePlan(planArray)
            // console.log("PDOOOO", planArray);

        }

    }, [
        values.product
    ])

    const testApi = async () => {
        setStep(1)
        try {
            const { statusApi, response } = await apiService.testApi("w", subscriptionUser.id)
            setTestApiW(statusApi)
            setIsTestLoading(false);
            // console.log("W", statusApi, response);
        } catch (error) {
            console.log("W error", error.response);
            setTestApiW(false);
            setErrorApiW(JSON.stringify(error.response.data));
        }
        setIsTestLoading(false);
    }

    const handleAddSub = async () => {
        try {
            setIsTestLoading(true);
            testApi()
            setIsTestLoading(false);
            console.log("9999", values);
            // resetForm()
        } catch (error) {
            console.log(error);
        }
    }

    const handleCreateTicketSub = async () => {
        try {
            // endPoint to create ticket
            console.log("Create ticket");
            const newticket = {
                uid: subscriptionUser.id,
                motif: 17,
                step: 0,
                by: auth.name,
                status: false,
                commentMotif: "Création abonnement",
            };
            console.log("PPPPPP", newticket);
            const resp = await axiosPrivate.post(`/api/ticket/create?pb=subscription`, newticket);
            console.log("resp", resp);

            navigate(`/board/4aa03c5f-2b8a-11ee-99ee-00155d821527`)

            toast.success(`Ticket pour ${subscriptionUser.email} créer`,
                {
                    autoClose: false,
                    pauseOnHover: true,
                }
            )
        } catch (error) {
            console.log("error create ticket sub", error);
        }
    }

    const handleConfirmSub = async () => {
        try {
            // console.log("Add sub to DB");
            values.pid = values.product
            values.uid = subscriptionUser.id
            values.startDate = moment(values.startDate, "DD-MM-YYYY").format("YYYY-MM-DD")

            //endPoint add sub to DB
            const { data } = await axiosPrivate.post(`/api/subscriptions`, values)
            navigate(`/subscription`)
            // navigate(`/dashboard/${subscriptionUser.id}`)
            console.log("PPPPPP", values);

            toast.success(`Subscription pour ${subscriptionUser.email} créer`,
                {
                    // position: "top-right",
                    autoClose: false,
                    pauseOnHover: true,
                }
            )

        } catch (error) {
            console.log(error);

        }
    }

    const getUser = async () => {
        try {
            const { data } = await axiosPrivate.get(`/api/users/genericFindOne/${subscriptionUser.id}?limit=1`);
            const decrypt = decryptOneUserInfo(data.oneUser)
            let date = null;
            let lastDayEmail = moment().add(1, 'days').format("DD");
            let expectedReeqDate;

            if (decrypt.emails.length == 1) {
                let lastEmailRecord = moment(decrypt.emails[0].date, "DD-MM-YYYY")
                let expectedNextEmailRecord = lastEmailRecord.add(1, "months").format("YYYY-MM-DD")
                date = moment(decrypt.emails[0].date, "DD-MM-YYYY").format("YYYY-MM-DD")
                let today = moment().format("YYYY-MM-DD")
                let daytoday = moment().format("DD")
                lastDayEmail = moment(decrypt.emails[0].date, "DD-MM-YYYY").format("DD")
                console.log("daytoday", daytoday, "lastDayEmail", lastDayEmail);

                if (expectedNextEmailRecord < today) {
                    expectedReeqDate = moment().add(1, 'days').format("YYYY-MM-DD")
                } else {
                    expectedReeqDate = expectedNextEmailRecord
                }
                // if (lastEmailRecord.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD") && lastDayEmail < daytoday) {
                //     // expectedReeqDate = moment().add(1, 'days').format("YYYY-MM-DD")
                //     console.log("ato 1");
                // } else if (lastEmailRecord.format("DD") > moment().format("DD")) {
                //     // expectedReeqDate = `${moment().format("YYYY-MM")}-${lastEmailRecord.format("DD")}`
                //     console.log("ato 2");

                // }
                // else {
                //     // expectedReeqDate = moment(decrypt.emails[0].date, "DD-MM-YYYY").add(1, "months").format("YYYY-MM-DD")
                //     console.log("ato 3");
                // }
                console.log("expectedReeqDate", expectedReeqDate);


            }
            setOneUser({
                lastReeq: date,
                status: decrypt.status,
                nextReeq: expectedReeqDate
            })
            // console.log("data", date, decrypt.status, "decrypt", decrypt, "expectedReeqDate", expectedReeqDate);
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        getUser()
    }, [subscriptionUser.id])


    //reponse API
    if (step == 1) {
        return (
            <div className='text-sm w-full'>
                {testApiW ?
                    <div className='flex flex-col items-center space-y-2'>
                        {/* <div> True : </div> */}
                        <div className='flex space-x-1 items-center'>
                            <CheckCircleIcon className='w-4 h-4 text-green-500' />
                            <div>L'API W de <strong>{subscriptionUser?.email}</strong> semble correct</div>
                        </div>
                        <div className='flex justify-center '>
                            Souhaitez-vous confirmer l'ajout du subscription ?
                        </div>
                        {/* <Controls.Button
                            text={t(`button.confirm`)}
                            onClick={handleConfirmSub}
                            xsFont
                            disabled={(values.product && values.plan && (values.totalDuration || values.specialPrice)) ? false : true}
                            isLoading={isTestLoading}
                        /> */}
                        <BtnShad text={t(`button.confirm`)}
                            onClick={handleConfirmSub}
                            disabled={(values.product && values.plan && (values.totalDuration || values.specialPrice)) ? false : true}
                            isLoading={isTestLoading}
                            className="w-1/2"
                            sendHistory={{ action: `Create abonnement - product:  ${values.product} - plan : ${values.plan} ${values.plan == "oneShot" ? `${values.specialPrice} usdt` : ""}  `, who: `${subscriptionUser.id}` }}
                        />
                    </div>
                    :
                    <div className='flex flex-col items-center space-y-2'>
                        <div className='flex space-x-1 justify-center'>
                            <XCircleIcon className='w-4 h-4 text-red-500' />
                            <div>L'API W de <strong>{subscriptionUser?.email}</strong> ne fonctionne pas </div>
                        </div>
                        {errorApiW ? <div className='text-xs text-red-600'>
                            erreur API : {errorApiW}
                        </div> :
                            <Loading />}

                        <div className='flex justify-center '>
                            Souhaitez-vous créer un ticket pour cela ? En le créant, tous les tickets existants seront fermées.
                        </div>
                        <div className='flex justify-center space-x-2'>
                            <Controls.Button
                                text={t(`button.cancel`)}
                                onClick={() => window.location.reload()}
                                xsFont
                                color="bg-red-500 text-white"
                                disabled={(values.product && values.plan && values.totalDuration) ? false : true}
                            />
                            <Controls.Button
                                text={`${t(`button.create`)} ${t(`label.tickets`)}`}
                                onClick={handleCreateTicketSub}
                                xsFont
                                disabled={(values.product && values.plan && values.totalDuration) ? false : true}
                                isLoading={isTestLoading}
                            />

                        </div>
                    </div>
                }
            </div>
        )
    }



    //Formulaire for sub
    return (
        <>
            <div className='flex flex-col space-y-2 justify-center items-center mx-2 md:mx-auto w-full pt-2 px-4 py-4 '>
                <div className='text-sm font-bold'>{subscriptionUser?.email}</div>



                {oneUser?.status == 2 ?

                    <div className='bg-yellow-100 rounded-lg p-2 text-justify flex flex-col items-center'>
                        <ExclamationIcon className='w-10 h-10 text-yellow-500' />
                        <div> Vous ne pouvez pas ajouter d'abonnement pour le moment car le client est entrain d'être rééquilibré. <br /> <br />Veuillez rééssayer plus tard .</div>
                    </div>

                    :
                    <div className='flex flex-col space-y-2 justify-center items-center mx-2 md:mx-auto w-full pt-2 py-4 '>
                        <Controls.Select
                            name="product"
                            label={t(`label.product`)}
                            value={values.product}
                            onChange={handleInputChange}
                            options={allProduct} />

                        <div className="flex flex-col text-sm my-2 w-full">

                            <Controls.RadioGroup
                                items={[
                                    { id: 1, title: moment().add(1, "days").format("DD-MM-YYYY") },
                                    { id: 2, title: "Personalisé " },
                                ]
                                }
                                key={""}
                                label={"Début de l'abonnement  :"}
                                value={values.chooseDate}
                                onChange={handleInputChange}
                                name={"chooseDate"}
                            />
                            {values.chooseDate == 2 &&
                                <div className=" w-full">
                                    <Controls.Input
                                        name="startDate"
                                        type="date"
                                        value={values.startDate}
                                        onChange={handleInputChange}
                                        label="Date de l'abonnement"
                                        min={moment().add(1, "days").format("YYYY-MM-DD")}
                                    />
                                </div>
                            }
                            <div className='text-xs text-blue-500'>
                                <i>L'abonnement sera déduis lors du rééquilibrage {oneUser?.lastReeq && <span>, le dernier rééquilibrage de ce client est le <b>{moment(oneUser?.lastReeq).format("DD-MM-YYYY")}</b></span>}</i>

                            </div>



                        </div>
                        <Controls.Select
                            name="plan"
                            label={"Plan"}
                            value={values?.plan}
                            onChange={handleInputChange}
                            options={availablePlan}
                            disabled={values?.product ? false : true}
                        />
                        {values.plan == "oneShot" && <Controls.Input
                            name="specialPrice"
                            type="number"
                            label="specialPrice"
                            value={values.specialPrice}
                            onChange={handleInputChange}
                            error={errors.specialPrice} />}

                        {values.plan != "oneShot" && <Controls.Input
                            name="totalDuration"
                            type="number"
                            label="Durée"
                            value={values.totalDuration}
                            onChange={handleInputChange}
                            error={errors.totalDuration}
                        />}

                        <Controls.Button
                            text={t(`button.save`)}
                            onClick={handleAddSub}
                            widthFull
                            disabled={(values.product && values.plan != "oneShot" && oneUser?.status != 2) ? false :
                                values.product && values.plan == "oneShot" && values.specialPrice && oneUser?.status != 2 ? false : true}
                            isLoading={isTestLoading}
                        />
                    </div>}

            </div>
            {/* {testApi ? <div>TRUE</div> : <div>FALSE</div>} */}

        </>

    )
}

export default AddSub
