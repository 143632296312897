import { Copy } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BtnShad from './client/btnShadcn';
import { useToast } from './ui/use-toast';

function CopyTextButton(props) {
    const { text, onClick, textClass, isBtn } = props
    const [isHover, setIsHover] = useState(false)
    const { toast } = useToast()
    const { t } = useTranslation()

    const copyText = () => {
        navigator.clipboard.writeText(text)
            .then(() => {
                // alert('Text copied successfully!');
                console.log("COPY");
                toast({
                    description: t(`toast.copySuccess`),
                    variant: "success"
                })
            })
            .catch((error) => {
                console.error('Failed to copy text: ', error);
            });
    };

    const handleMouseEnter = () => {
        setIsHover(true)
    }

    const handleMouseLeave = () => {
        setIsHover(false)
    }


    if (isBtn) {

        return (
            // <button onClick={copyText}>{text}</button>
            <BtnShad text={t(`button.copy`)} onClick={copyText} icon={<Copy className='h-4 ' />} />

        );
    }

    return (
        // <button onClick={copyText}>{text}</button>
        <div className={`relative ${isHover ? "z-10" : ""}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onClick}>
            <Copy onClick={copyText} className='h-4 text-muted-foreground hover:text-primary hover:cursor-pointer' />
            {isHover && <div className={`${textClass ? textClass : "max-w-32 bottom-3 left-5 "}absolute rounded-md p-1 bg-muted-foreground text-white text-xs`}>{t(`button.copy`)}</div>}
        </div>
    );
}

export default CopyTextButton;
