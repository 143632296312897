import React from "react";
import AllUsers from "../components/Users/AllUsers";
import EquipeCB from "../components/EquipeCB";

function AllUsersScreen(props) {
  const { t } = props;

  return (
    <>
      <div className="p-4  gap-3 h-[93vh]">
        <div className=" bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 h-full ">
          <h3 className="font-bold text-xl text-slate-700 dark:text-slate-100">{t("label.allUsers")}</h3>

          <div className="flex flex-col">
            <div className=" space-y-4 mt-2 p-4  h-[50vh] overflow-y-auto  bg-slate-100 dark:bg-slate-800">
              <AllUsers t={t} />
            </div>
            <div className=" py-2">
              <div className="text-slate-600 font-light text-center mb-2 text-xl ">L'équipe Crypto Bulot</div>
              <div className="">
                <EquipeCB t={t} small />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllUsersScreen;
