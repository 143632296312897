import React, { PureComponent } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

function SimpleLineCustom(props) {
  const { data, colors, width, height, keyLength, manyCummulative, name } = props;

  return (
    <div className={`p-4 flex gap-x-3 w-full md:w-[${width}px] h-[${height}px] `}>
      <>
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={keyLength && keyLength[0]} />
            <YAxis />
            <Tooltip />
            <Legend />
            {manyCummulative ? (
              <>
                <Line type="monotone" dataKey={keyLength[3]} name={name[3]} stroke={colors[0]} dot={{ fill: colors[0], stroke: "", strokeWidth: 2, r: 0 }} />
                <Line type="monotone" dataKey={keyLength[4]} name={name[4]} stroke={colors[1]} dot={{ fill: colors[1], stroke: "", strokeWidth: 2, r: 0 }} />
                <Line type="monotone" dataKey={keyLength[5]} name={name[5]} stroke={colors[2]} dot={{ fill: colors[2], stroke: "", strokeWidth: 2, r: 0 }} />
              </>
            ) : (
              keyLength && <Line type="monotone" dataKey={keyLength[3]} name="Cummulé" stroke="#8884d8" dot={{ fill: "#1d4ed8", stroke: "", strokeWidth: 2, r: 0 }} />
            )}
          </LineChart>
        </ResponsiveContainer>
      </>
    </div>
  );
}

export default SimpleLineCustom;
