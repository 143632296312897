import { createSlice } from "@reduxjs/toolkit";
const initialState = [];

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    addFilter: (state, action) => {
      return action.payload;
    },
    cleanFilter: (state) => {
      return initialState;
    },
  },
});

export const { addFilter, cleanFilter } = filterSlice.actions;
export const filterSelector = (state) => state.filter;
export default filterSlice.reducer;
