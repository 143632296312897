import React, { useState } from "react";
import { useFOrm, Form } from "../useFOrm";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Controls from "../controls/Controls";
import * as services from "../../utils/service";
import { v4 as uuidv4 } from "uuid";
import axios from "../../axios";
import { toast } from "react-toastify";
import BannerInfo from "../BannerInfo";
import { ReactComponent as ResetFreepeik } from '../../asset/resetPassword.svg';


const initialFvalues = {
    email: "",
};
function ForgotPassword(props) {
    const { t } = props;
    const location = useLocation();
    const [isClicked, setIsClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [attempt, setAttempt] = useState(0);
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("email" in fieldValues) temp.email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(fieldValues.email) ? "" : t("error.invalidEmail");
        setErrors({
            ...temp,
        });

        if (fieldValues == values) return Object.values(temp).every((x) => x == "");
    };

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    const formReset = [
        {
            type: "email",
            name: "email",
            label: t("label.email"),
            placeholder: "Enter your email",
            password: false,
            required: true,
        },
    ];

    let navigate = useNavigate();
    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        if (validate()) {
            //console.log('values', services.encryptCB(values.email))
            //navigate(`/reset/${values.email}`);

            //find user with givin email
            try {
                const res = await axios.get(`api/users/findBy/email/${values.email}`);
                if (res.data) {
                    console.log(res.data);
                    const uniqueID = uuidv4();
                    await axios.put(`/api/users/${res.data.id}/resetPassword`, { refresh_token: uniqueID });
                    console.log(uniqueID);
                    const dataToEmail = {
                        id: res.data.id,
                        token: uniqueID,
                    };

                    const response = await axios.post(`/api/users/sendResetPasswordEmail`, dataToEmail);

                    if (response.status == 200) {
                        // navigate(`/token-email`, { state: { email: values.email } })
                        //toast.success(response.data.msg);
                        setIsLoading(false);
                        toast.success("Envoi email ....");
                        setIsClicked(true);
                    }

                    //send email with unique id uniqueID
                }
            } catch (error) {
                setIsLoading(false);
                setAttempt((prev) => error.response.data.attempt);
                console.log("att", error.response.data.attempt);
                return toast.error("L'adresse email n'existe pas");
            }

            //update user table with uuid
        }
    };
    let attempt5 = attempt >= 5;

    return (
        <div className="w-md max-w-md  my-auto mx-auto px-4  ">
            <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900 dark:text-slate-100">{t(`button.reset`)} {t(`label.password`)}</h2>
            {location?.state?.isFirstLogin ? (
                <div className="text-sm font-semibold">
                    <p>{t(`info.firstLoginResetMdp`)}</p>
                </div>
            ) : null}

            {isClicked ? (
                <>
                    <div className="text-sm text-center text-indigo-700 font-semibold ">{t(`info.checkEmailInbox`)}</div>
                </>
            ) : (
                <>
                    {attempt >= 5 ? (
                        <BannerInfo
                            text={<div className="flex flex-col space-y-2 text-xs">
                                <div >
                                    {t(`info.blockAccount`)}
                                </div>
                                <div >
                                    {t(`info.try24h`)}
                                </div>
                            </div>}
                            type="danger"
                        />
                    ) : (
                        <div className="flex flex-col justify-center items-center space-y-4 pb-4 text-sm font-light">
                            <div><ResetFreepeik /></div>
                                    <div>{t(`info.enterEmailAdress`)}</div>
                        </div>
                    )}

                    <Form onSubmit={handleSubmit} noValidate>
                        <div className="w-md max-w-md flex flex-col space-y-2">
                            {formReset.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <Controls.Input
                                            readOnly={attempt5 ? true : false}
                                            fullWidth={true}
                                            label={item.label}
                                            value={values[item.name]}
                                            onChange={handleInputChange}
                                            name={item.name}
                                            type={item.type}
                                            error={errors[item.name]}
                                        />
                                    </div>
                                );
                            })}
                            <div>
                                {/* <Controls.Button type="submit" text={t('button.login')} fullWidth /> */}

                                <Controls.Button disabled={attempt5 ? true : false} type="submit" text={t("button.confirm")} widthFull isLoading={isLoading} />
                            </div>
                            {location?.state?.isFirstLogin ? null : (
                                <div className="text-xs flex justify-end">
                                    <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                                        {t("button.login")} ?
                                    </Link>
                                </div>
                            )}
                            {/* <div>a{attempt}</div> */}
                        </div>
                    </Form>
                </>
            )}
        </div>
    );
}

export default ForgotPassword;
