import { ArrowNarrowLeftIcon, ShieldCheckIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useFOrm } from "../components/useFOrm";
import Controls from "../components/controls/Controls";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { authSelector } from "../features/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as service from "../utils/service";
import BtnShad from "../components/client/btnShadcn";
const initialFvalues = {
    password: "",
};

// Authentificator screen
function AuthScreen(props) {
    const { t } = props;
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [showMdpInput, setShowMdpInput] = useState(false);
    const [qrCode, setQrCode] = useState("");
    const [code, setCode] = useState();
    const [codeError, setCodeError] = useState();
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const auth = useSelector(authSelector);
    const goBack = () => navigate(-1);
    const handleMdp = () => {
        setShowMdpInput(true);
        console.log("MDP", showMdpInput);
    };

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("password" in fieldValues) temp.password = fieldValues.password ? "" : t("error.required");
        setErrors({
            ...temp,
        });

        if (fieldValues == values) return Object.values(temp).every((x) => x == "");
    };

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);
    const formLogin = [
        {
            type: "password",
            name: "password",
            label: t("label.password"),
            placeholder: "******",
            password: true,
            required: true,
        },
    ];

    const verifyPassword = async () => {
        if (validate()) {
            try {
                setIsLoading(true);
                const dataToCheck = {
                    password: values.password,
                };
                const result = await axiosPrivate.post(`api/users/reauth`, dataToCheck);
                if (result) {
                    setQrCode(result.data);
                    setIsLoading(false);
                    setShowCodeInput(true);
                }
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        }
    };

    const deleteTfa = async () => {
        if (validate()) {
            try {
                setIsLoading(true);
                const dataToCheck = {
                    password: values.password,
                };
                const result = await axiosPrivate.post(`api/users/deleteTfa`, dataToCheck);
                if (result.data.msg == "delete") {
                    setUser({
                        ...user,
                        tfa: false,
                    });
                    setShowMdpInput(false);
                    setIsLoading(false);
                    toast.warning(t(`info.2FAisDisabled`));
                    navigate(-1);
                }
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        }
    };

    const handleVerifyCode = async () => {
        try {
            if (!code) throw t("error.required");
            const response = await axiosPrivate.get(`/api/users/verifyTfa/${code}`);
            if (response.data) {
                console.log("OOOKKK");
                setUser({
                    ...user,
                    tfa: response.data,
                });
                setShowCodeInput(false);
                toast.success(t(`info.2FAisEnabled`));
                navigate(-1);
            } else {
                toast.error("code à 6 chiffres incorrect");
            }
        } catch (error) {
            setCodeError(error);
            console.log(error);
        }
    };

    useEffect(() => {
        if (code) {
            setCodeError("");
        }
    }, [code]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getOneUser = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/users/genericFindOne/${auth.id}`, {
                    signal: controller.signal,
                });
                const decrypt = service.decryptOneUserInfo(data.oneUser);
                // console.log("====users", data);
                isMounted && setUser(decrypt);
            } catch (error) {
                toast.error("error");
                console.log(error);
            }
        };
        getOneUser();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);
    return (
        <div className=" mx-2 md:mx-auto bg-slate-50  mt-4 pt-2 px-4  md:w-[70%] py-4">
            <div className="flex items-center">
                <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
                    <ArrowNarrowLeftIcon className="h-4 w-4 hover:cursor-pointer" onClick={goBack} />
                </div>
                <div className="text-sm font-medium ">{t(`label.accountSecurity`)}</div>
            </div>

            <div className="  px-2 ">
                <div className=" ">
                    {user && user.tfa ? (
                        <>
                            <div className=" text-sm pt-2 ">
                                <div className="flex flex-col items-center gap-y-4">
                                    <div>
                                        <ShieldCheckIcon className="w-16 h-16 text-green-400 " />
                                    </div>
                                    <div>{t(`label.2FAactive`)}</div>
                                    <div className="text-xs font-light text-center">{t(`info.2FAnotActive`)}</div>
                                    <div className="  border border-red-400 h-fit px-6 py-1 my-2 rounded-lg hover:cursor-pointer " onClick={handleMdp}>
                                        {t(`button.desactiver`)}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className=" text-sm pt-2 ">
                                <div className="flex flex-col items-center gap-y-4">
                                    <div>
                                        <ShieldCheckIcon className="w-16 h-16 text-sky-300 " />
                                    </div>
                                    <div>{t(`label.use2FA`)}</div>
                                    <div className="text-xs font-light text-center">{t(`info.2FAactive`)}</div>
                                    <div className="  border border-sky-400 h-fit px-6 py-1 my-2 rounded-lg hover:cursor-pointer " onClick={handleMdp}>
                                        {t(`button.activer`)}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={`${showMdpInput && qrCode == "" ? " block text-sm pt-4 text-center mx-2 px-2 mt-4 " : "hidden"}`}>
                <div className="h-0.5 bg-slate-200 w-1/2 mx-auto mb-6" />
                <div className="pb-2 font-semibold">{t(`info.enterMdpToContinue`)}</div>
                <div className="text-xs font-light pb-2 ">{t(`info.toActiveOrDesactive2FA`)}</div>
                <div className="pb-2 mx-6">
                    {formLogin.map((item, i) => {
                        return (
                            <div className="flex items-end space-x-2 w-1/2 mx-auto mt-2 ">
                                <Controls.Input fullWidth={true} label={item.label} value={values[item.name]} onChange={handleInputChange} name={item.name} type={item.type} error={errors[item.name]} />
                                <div className=" flex justify-center mt-2  ">
                                    {user && user.tfa ? (
                                        // <Controls.Button onClick={deleteTfa} text="Valider" small xsFont isLoading={isLoading} />
                                        <BtnShad text={" Valider "} onClick={deleteTfa} sendHistory={{ action: `Delete 2FA `, who: ` ${auth?.id} ` }}
                                        />
                                    ) : (
                                        // <Controls.Button onClick={verifyPassword} text={t(`button.continue`)} small isLoading={isLoading} />
                                        <BtnShad text={t(`button.continue`)} onClick={verifyPassword} isLoading={isLoading}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {showCodeInput && (
                <>
                    <div className="mt-2 bg-slate-200 w-full h-0.5" />
                    <div className="text-xs font-light mt-4 text-center">
                        {t(`info.scanQrCode`)}
                        <p className="pt-2">{t(`info.downloadGen2FA`)}</p>
                    </div>
                    <div className="py-3 flex justify-center ">{qrCode && <img src={qrCode && qrCode} className="w-48 h-48 " />}</div>
                    <div className="text-xs font-light text-center mt-2">{t(`info.6chiffresCode`)}</div>
                    <div className="flex gap-x-2 mt-4 items-end max-w-1/2 w-1/2 mx-auto">
                        <Controls.Input type="number" label={t(`label.verificationCode`)} name={t(`label.code`)} error={codeError} onChange={(e) => setCode(e.target.value)} />

                        <div className=" text-right">
                            {/* <Controls.Button text={"OKoo"} onClick={handleVerifyCode} /> */}
                            <BtnShad text={" OK "}
                                onClick={handleVerifyCode}
                                sendHistory={{ action: `Add 2FA `, who: ` ${auth?.id}` }}
                                className="w-20"
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default AuthScreen;
