import React, { useEffect, useState } from 'react'
import { useFOrm, Form } from '../useFOrm';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import Controls from '../controls/Controls';
import * as services from '../../utils/service'
import { toast } from "react-toastify";
import axios from '../../axios';

const initialFvalues = {
  email: "",
  token: ""
}
function EnterToken(props) {
  const { t } = props
  let { emailtest, tokentest } = useParams();
  const [token, setToken] = useState();
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('email' in fieldValues)
      temp.email = (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(fieldValues.email) ? "" : t("error.invalidEmail")

    if ('token' in fieldValues)
      temp.token = fieldValues.token ? "" : t("error.required")
    setErrors({
      ...temp
    })

    if (fieldValues == values)
      return Object.values(temp).every(x => x == "");
  }

  const {
    values,
    setValues,
    errors,
    setErrors,
    resetForm,
    handleInputChange
  } = useFOrm(initialFvalues, true, validate)


  const formReset = [
    {
      type: "email",
      name: "email",
      label: t("label.email"),
      placeholder: "Enter your email",
      password: false,
      required: true,
    },
    {
      type: "token",
      name: "token",
      label: t("label.token"),
      placeholder: "Enter your token",
      password: false,
      required: true,
    }
  ];



  let location = useLocation()
  initialFvalues.email = emailtest
  initialFvalues.token = tokentest
  if (location.state && location?.state.email) {
    initialFvalues.email = location.state.email
  }




  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      //cross check if email and uuid matches
      const tokenTrim = (values.token).trim()
      //console.log(tokenTrim)
      if (tokenTrim.length != 36) return toast.error("Veuillez entrer un token valide");

      try {
        const res = await axios.get(`api/users/findBy/token/${values.email}/${tokenTrim}`)

        if (res.data) {
          navigate(`/reset-password`, { state: { email: values.email, token: tokenTrim } })
        }
      } catch (error) {
        toast.error("Erreur de Token ou Email");
      }

    }

  }




  return (
    <div className='mt-6 w-md max-w-md mx-auto'>
      <div className='flex flex-col gap-y-3'>

        <h2 className=" text-center text-2xl font-extrabold text-gray-900 dark:text-slate-100">Réinitialisation mot de passe</h2>
        <div className='text-sm font-light text-slate-900 dark:text-white'>
          {/* <p> We sent an Email to <span className='text-sm font-semibold'> {location.state ? location.state.email : null}</span> with a token , please copy and paste it in the field Token below to reset your password</p> */}
          {/* <p> We sent an Email to <span className='text-sm font-semibold'> {values ? values.email : null}</span> with a token , please copy and paste it in the field Token below to reset your password</p> */}
          <p> Veuillez vérifier et insérer le token qui vous a été envoyé par e-mail</p>

        </div>

        <Form onSubmit={handleSubmit} noValidate>

          <div className='w-md max-w-md flex flex-col space-y-5'>

            {formReset.map((item, i) => {
              return (
                <div key={i}>
                  <Controls.Input
                    fullWidth={true}
                    label={item.label}
                    value={values[item.name]}
                    onChange={handleInputChange}
                    name={item.name}
                    type={item.type}
                    error={errors[item.name]}
                  />
                </div>
              );
            })}
            <div className='mt-5'>
              {/* <Controls.Button type="submit" text={t('button.login')} fullWidth /> */}
              <Controls.Button type="submit" text={t('button.next')} widthFull />
            </div>
          </div>
        </Form >

        <div className='text-xs font-light flex justify-end'>
          <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
            {t('button.login')} ?
          </Link>
        </div>


      </div>
    </div>
  )
}

export default EnterToken
