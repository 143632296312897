import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import Controls from "../controls/Controls";
import Loading from "../Loading";
import axios from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateUser } from "../../features/user/userSlice";
import { ReactComponent as Refer } from "../../asset/refer.svg";
import { authSelector } from "../../features/authSlice";

function Parrainage(props) {
    const { data, t, uid } = props;
    console.log("parrain data", data, data.filter((f) => (f.strategy == "normal" && f.status != 11)));
    const axiosPrivate = useAxiosPrivate();
    const [parent, setParent] = useState({});
    const { id } = useParams();
    const auth = useSelector(authSelector);

    const dispatch = useDispatch();
    const handleInputChange = (event, value) => {
        /* setParent(value)*/
        //selected.parent = value || ""
        setParent(value);
    };

    useEffect(() => {
        // console.log("I", parent)
        //get Parent object
    }, [parent]);

    const handleUpdateInfo = async () => {
        //update user data
        const dataToUpdate = {
            parent: parent.id,
        };
        try {
            axiosPrivate.put(`/api/users/${uid}`, dataToUpdate);
            dispatch(updateUser(dataToUpdate));
            toast.success("User updated");
        } catch (error) {
            toast.error(error.response?.data.msg);
        }
    };
    return (
        <>
            {data ? (
                <div className="flex justify-center items-center w-full gap-x-9 p-4">
                    <div className="w-1/3 hidden md:block">
                        <Refer />
                    </div>

                    <div className="w-2/3 flex flex-col space-y-5">
                        <Controls.AutoComplete
                            className={"max-w-xs"}
                            options={
                                data &&
                                data?.filter((f) => (f.strategy == "normal" && f.status != 11))?.map((u) => {
                                    return {
                                        id: u.id,
                                        label: `${u?.firstName} ${u?.lastName}` || "",
                                        code: u.code,
                                    };
                                })
                            }
                            label={"Parrain"}
                            onChange={(e, value) => handleInputChange(e, value)}
                        // value={parent?s.label || ""}
                        />
                        <div className="card">
                            <div className="text-xs font-medium">
                                Nom : <span>{`${parent?.label ? parent.label : ""}`}</span>
                            </div>

                            <div className="text-xs font-medium">
                                Code : <span>{`${parent?.code ? parent.code : ""}`}</span>
                            </div>
                        </div>

                        <div className="flex justify-end ">
                            <Controls.Button disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false} text={t(`button.save`)} xsFont onClick={() => handleUpdateInfo()} />
                        </div>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </>
    );
}

export default Parrainage;
