import React, { useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import TopBar from "../components/TopBar";
import Sidebar from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import { DataGrid, frFR, GridToolbar } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Controls from "../components/controls/Controls";
import * as sideMenus from "../utils/sideMenus";
import { authSelector } from "../features/authSlice";
import { useFOrm } from "../components/useFOrm";
import Loading from "../components/Loading"
import { decryptCBData } from "../utils/service";
import { useSearchParams } from 'react-router-dom'
import BtnShad from "../components/client/btnShadcn";



const initialValues = {
    status: "",
    transferDate: ""
};

function PnlUserScreen(props) {
    const { t } = props;
    const [allIdPnlSelectd, setAllIdPnlSelected] = useState([])
    const [changeOk, setChangeOk] = useState(false)
    const [user, setUser] = useState();
    const [pnl, setPnl] = useState(0);
    const [selectedPnl, setSelectedPnl] = useState(0);
    const [selectedRowsId, setSelectedRowsId] = useState();
    const [btnPayLoading, setBtnPayLoading] = useState(false);
    const [params, setParams] = useSearchParams()
    console.log("EID", params);
    const axiosPrivate = useAxiosPrivate();
    const auth = useSelector(authSelector);
    const { id } = useParams();
    const dispacth = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const goBack = () => navigate(-1);
    let stat;

    stat = [
        { id: 4, title: "Déjà récupéré par carte" },
        { id: 16, title: "Resilié PNL < 12 USDT" },
        { id: 17, title: "Résilié API W invalid" },

    ]

    const column = [
        {
            field: "id",
            headerName: "ID",
            type: "number",
            hide: true
        },
        {
            field: "status",
            headerName: "Status",
            type: "number"
        },
        {
            field: "createdAt",
            headerName: "Date de création",
            type: "date",
            width: 120,
            // valueGetter: (params) => {
            //     if (params.row.details) {
            //         const date = moment(params.row.createdAt, "DD-MM-YYYY").format("YYYY-MM-DD");
            //         return date.toLocaleDateString();
            //     } else {
            //         return null;
            //     }
            // },

            renderCell: (cellValues) => {
                if (cellValues?.row?.createdAt) {
                    return moment((cellValues?.row?.createdAt)).format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },
        },
        {
            field: "totalFivePercent",
            headerName: "PNL 5%",
            type: "number",
            width: 100,

            renderCell: (cellValues) => {
                if (cellValues?.row?.createdAt) {
                    return (JSON.parse(cellValues?.row?.totalFivePercent).usdt);
                } else {
                    return null;
                }
            },
        },

    ];

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("status" in fieldValues) temp.status = fieldValues.status ? "" : t("error.required");
        if ("transferDate" in fieldValues) temp.transferDate = fieldValues.transferDate ? "" : t("error.required");

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);

    useEffect(() => {
        console.log(moment(new Date("2022-06-28")).format("DD/MM/YYYY"));
        let isMounted = true;
        const controller = new AbortController();

        const getAllPnl = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/pnl/findAll?uid=${id}&status=0&orderType=DESC&onlyPositive=1`,
                    { signal: controller.signal }
                )
                console.log("PNLLLLL=====", data, data.length);
                setPnl(data.pnl)
                setUser(data.user)
            } catch (error) {
                console.log(error);

            }
        };
        getAllPnl();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);


    const handleGetAllPnlId = async () => {
        try {
            console.log("AllPnlId", allIdPnlSelectd);
            if (validate()) {
                console.log("VADLIDTE", allIdPnlSelectd);

                if (allIdPnlSelectd.length > 0) {
                    for (let p of allIdPnlSelectd) {
                        console.log("p", p);
                        await axiosPrivate.put(`/api/pnl/${p}`, values)
                        console.log("lasa niova ", values);
                    }
                }
                window.location.reload()
                toast.success("PNL modifié avec succès", { closeOnClick: true })
                setChangeOk(true)
            }

        } catch (error) {
            console.log("error", error);
        }
    }

    return (
        <>
            <TopBar menus={sideMenus.menusAdmin(t)} />
            <div className="flex flex-row flex-grow ">
                <Sidebar menus={sideMenus.menusAdmin(t)} />
                <div className="flex flex-row flex-1  mt-2 md:ml-52">
                    <div className="flex flex-col w-full p-4">
                        <div className="flex-none h-auto">
                            <div className="flex items-center  gap-x-1">
                                <ChevronLeftIcon className="h-6 rounded-full hover:bg-slate-300 cursor-pointer" onClick={() => goBack()} />
                                <div className="font-semibold text-2xl text-slate-900 dark:text-white">{t("button.edit")} {t("label.PNL")} </div>
                            </div>
                            <h2 className="font-bold mt-2"> {decryptCBData(user?.email)}</h2>
                            <span className="text-xs text-slate-500 dark:text-slate-400  ">{moment().format("Do MMMM YYYY")}</span>
                            <hr />
                            <div className="w-full flex rounded-sm my-2 bg-slate-50">

                                {/* <div className='w-full bg-green-200  space-y-2 container'> */}
                                <div className={`flex-col justify-center items-center rounded-sm p-2 border-2  min-w-[20rem] border-gray-100`}>
                                    <h4 className="font-normal">
                                        Total PNL séléctionné(s)
                                    </h4>
                                    <h5 className="text-xl font-semibold"> {selectedPnl.toFixed(2) || " 0 "} USDT</h5>
                                    <span className="text-xs text-slate-400">* Pour commencer, veuillez sélectionner les PNL à modifier.</span>
                                </div>

                                <div className={`transition-width duration-500 ease-in-out ${selectedPnl <= 0 ? "opacity-0 w-0" : "opacity-100 w-1/2 block"} flex-1 p-2`}>
                                    <h4 className="font-normal text-sm">
                                        Veuillez choisir le nouveau status du PNL ainsi que la date de transfert
                                    </h4>
                                    <div className="flex space-x-2 ">
                                        <div>
                                            <Controls.Select
                                                name="status"
                                                label={"Statut"}
                                                value={values.status}
                                                options={stat}
                                                disabled={selectedPnl <= 0 ? true : false}
                                                onChange={handleInputChange}
                                                error={errors.status}
                                            />
                                        </div>
                                        <div>
                                            <Controls.Input type="date"
                                                onChange={handleInputChange}
                                                label="Date de transfert"
                                                name="transferDate"
                                                disabled={selectedPnl <= 0 ? true : false}
                                                value={values.transferDate}
                                                error={errors.transferDate} />
                                        </div>


                                        <div>
                                            {/* <Controls.Button text={"Modifier status PNL"} onClick={handleGetAllPnlId} widthFull disabled={selectedPnl <= 0 ? true : false} /> */}
                                            <BtnShad
                                                mt
                                                text="Modifier status PNL" onClick={handleGetAllPnlId}
                                                wFull
                                                disabled={selectedPnl <= 0 ? true : false}
                                                sendHistory={{ action: `Nouveau status PNL : ${values.status} - date de transfert : ${values.transferDate}`, who: `${user?.id} ` }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* </div> */}
                            </div>

                        </div>
                        <div className="h-[400px] bg-slate-100 dark:bg-slate-800">
                            {pnl?.length > 0 ? (
                                <DataGrid
                                    isRowSelectable={(params) => params.row.paidByCB != 2}
                                    checkboxSelection
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: false,
                                            quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                    getRowId={(row) => row.id}
                                    // componentsProps={{
                                    //     footer: { total }
                                    // }}
                                    //autoPageSize={true}
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    initialState={{
                                        sorting: {
                                            sortModel: [{ field: "id", sort: "desc" }],
                                        },
                                    }}
                                    rows={pnl}
                                    // rows={paymentParent && paymentParent.all.filter((f) => f.totalOnePercentUSDT > 0)}
                                    columns={column}
                                    //pagination={true}
                                    //onCellClick={(value) => console.log("yes", value.id)}
                                    disableColumnSelector
                                    disableDensitySelector
                                    disableColumnFilter
                                    onSelectionModelChange={(ids) => {
                                        const selectedIDs = new Set(ids);
                                        console.log("id", selectedIDs);
                                        const selectedRowsTotal = pnl.filter((row) => selectedIDs.has(row.id));
                                        setSelectedRowsId(ids);
                                        if (selectedRowsTotal.length != 0) {
                                            setAllIdPnlSelected(selectedRowsTotal.map(s => s.id))
                                            setSelectedPnl(selectedRowsTotal.map((s) => JSON.parse(s.totalFivePercent).usdt).reduce((acc, amount) => Number(acc) + Number(amount)));
                                        } else {
                                            setSelectedPnl(0);
                                        }
                                    }}
                                />
                            ) : (
                                // <Loading />
                                <div className="text-center text-sm w-full mt-4">Pas de résultat .</div>
                            )
                            }
                        </div>
                        <div className="flex-none mt-2">
                            {/* {selectedPnl} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PnlUserScreen;
