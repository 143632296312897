import React from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

function SimpleBarChart(props) {
  const { data, color1, color2, width, height, datakey1, datakey2, keyLength, onClick, name } = props;
  return (
    <>
      <div className={`p-4 flex gap-x-3 w-full md:w-[${width}px] h-[${height}px] `}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            onClick={(e) => onClick(e)}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={keyLength && keyLength[0]} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={datakey1 ? datakey1 : "uv"} name={name[0]} fill={color1} />
            <Bar dataKey={datakey2 ? datakey2 : "pv"} name={name[1]} fill={color2} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export default SimpleBarChart;
