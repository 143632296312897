import React, { useState, useEffect } from "react";
import Controls from "../components/controls/Controls";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import * as sideMenus from "../utils/sideMenus";
import { useFOrm } from "../components/useFOrm";
import DialogBox from "../components/DialogBox";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../features/authSlice";
import { zapSelector, setZap, updateZap } from "../features/zapSlice";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { ArrowNarrowLeftIcon, ExclamationCircleIcon, ExclamationIcon } from "@heroicons/react/solid";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import ExpressRebalancing from "../components/ExpressRebalancing";
import { updateUserDetails, userDataSelector } from "../features/user/userSlice";
import BtnShad from "../components/client/btnShadcn";

//Synthèse securisation
const CustomRender = ({ text, value }) => (
    <div className="font-light text-sm ">
        {text}: <span className="font-medium">{value}</span>
    </div>
);

// ats : amountToSecure
// st : stopTrigger
const initialFvalues = {
    coin: null,
    type: null,
    trigger: null,
    ats: null,
    st: 999999999,
    wantStop: false,
    seuil: null,
};

const zapCollection = [
    { id: 1, title: "Récolte" },
    { id: 2, title: "Palier" },
    { id: 3, title: "Dynamique" },
    // { id: 4, title: "Autres" },
];

const zapCoins = [
    { id: "USDT", title: "USDT" },
    { id: "BTC", title: "BTC" },
    { id: "ETH", title: "ETH" },
];

const ZapCase = ({ title, content }) => (
    <div className="pt-2">
        <div className="font-semibold ">{title} </div>
        <div className="text-xs text-justify mx-2">{content}</div>
    </div>
);
function SecureScreen2(props) {
    const { t } = props;
    const axiosPrivate = useAxiosPrivate();
    const auth = useSelector(authSelector);
    const dispacth = useDispatch();
    const location = useLocation();
    // console.log("LOOOOOOOOO", location);
    const zapSelect = useSelector(zapSelector);
    const { id } = useParams(); // maka id
    const [hasRebal, sethasRebal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [userData, setUserData] = useState();
    const [isOpen3, setIsOpen3] = useState(false);
    const [totalInvested, setTotalInvested] = useState(0);
    const [capitalActif, setCapitalActif] = useState(0);
    let amountSeuil = location.state.seuil || 10000

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        let test = values.trigger || null;
        if ("coin" in fieldValues) temp.coin = fieldValues.coin ? "" : t("error.required");
        if ("ats" in fieldValues && fieldValues.type === 2) temp.ats = Number(fieldValues.ats) >= 10 && Number(fieldValues.ats) <= test ? "" : t("error.benefice");
        if ("ats" in fieldValues && fieldValues.type === 3) temp.ats = fieldValues.ats ? "" : t("error.required");

        if ("trigger" in fieldValues && fieldValues.type !== 1) temp.trigger = fieldValues.trigger > 0 ? "" : t("error.required");

        // if ("seuil" in fieldValues) temp.seuil = fieldValues.seuil >= 10000 ? "" : t("error.seuilminimum");
        if ("seuil" in fieldValues)
            temp.seuil = fieldValues.seuil >= (totalInvested > amountSeuil ? totalInvested : amountSeuil) ? "" : `${t("error.seuilminimum")} ${totalInvested > amountSeuil ? totalInvested : amountSeuil} USDT`;

        //if ("trigger" in fieldValues) temp.trigger = fieldValues.trigger ? "" : t("error.required");

        //  if ("trigger" in fieldValues) temp.trigger = /[+-]?[0-9]+\./.test(fieldValues.trigger) ? "" : t("error.required");

        //if ("ats" in fieldValues) temp.ats = fieldValues.ats ? "" : t("error.required");

        setErrors({
            ...temp,
        });
        // console.log("test qq", temp);
        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const handleValidate = () => {
        // console.log("HERE", values);
        if (validate()) {
            setIsOpen(!isOpen);
            // open 1st modal are you sure
        }
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const gestUserDetails = async (uid) => {
            try {
                const { data } = await axiosPrivate.get(`/api/details/findBy/${uid}`);
                isMounted && setUserData(data);
                // console.log("DATA", data);
            } catch (error) {
                console.log(error, "Error");
            }
        };
        gestUserDetails(id);
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const checkifUserHasDetails = async (uid, finalObjtToUpdate) => {
        try {
            // checkifExist in database
            const detailsData = await axiosPrivate.get(`/api/details/findBy/${uid}`);

            if (detailsData.data) {
                try {
                    await axiosPrivate.put(`/api/details/${uid}`, finalObjtToUpdate);
                    dispacth(updateUserDetails(finalObjtToUpdate));
                    //toast.success("Details updated");
                } catch (error) {
                    //toast.error(error.response?.data.msg);
                }
            } else {
                try {
                    await axiosPrivate.post(`/api/details/create/`, finalObjtToUpdate);
                    dispacth(updateUserDetails(finalObjtToUpdate));
                    //toast.success("Details Added");
                } catch (error) {
                    //toast.error(error.response?.data.msg);
                }
            }
            //setIsLoading(false);
        } catch (error) {
            //toast.error(error.response?.data.msg);
        }

        // VAOVAO
    };
    const handleConfirmMethod = async () => {
        setIsOpen(!isOpen);
        if (validate()) {
            let uID;

            if (auth?.role.includes(process.env.REACT_APP_CLIENT) || auth?.role.includes(process.env.REACT_APP_PARTENAIRE)) {
                uID = auth?.id;
            } else {
                uID = id;
            }
            const newData = {
                uid: uID,
                ...values,
                lastAth: "0",
                nextAth: "0",
                status: true,
                st: values.wantStop ? values.st : null,
            };
            const response = await axiosPrivate.post(`/api/zap/create`, newData);
            await dispacth(setZap(response.data));
            await checkifUserHasDetails(uID, { securedAsset: values.coin });

            // values = initialFvalues;
            resetForm();
            //console.log("HERE", newData, values, selected);
        }
    };

    const handleValidateStop = () => {
        setIsOpen2(!isOpen2);
    };
    const handleConfirmStop = async () => {
        setIsOpen2(!isOpen2);
        dispacth(updateZap({ st: values.st }));
        await axiosPrivate.put(`/api/zap/${zapSelect.id}`, { st: values.st });
    };

    useEffect(() => {
        if (values.wantStop == false) {
            values.st = "";
        }
    }, [values.wantStop]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getZap = async (myid) => {
            try {
                const response = await axiosPrivate.get(`/api/zap/active/${myid}`, {
                    signal: controller.signal,
                });
                //set methode secure based on db data
                if (response.data) {
                    //const dataArraY = plans.filter((p) => p.id === response.data.type);
                    isMounted && dispacth(setZap(response.data));
                }
                //console.log("test", dataArraY);
            } catch (error) { }
        };

        const getTotalInvested = async (myid) => {
            try {
                const response = await axiosPrivate.get(`/api/users/totalInvested/${myid}`, {
                    signal: controller.signal,
                });
                // console.log("EEEEEEEEEEEeeeeeee");
                if (response.data) {
                    // console.log("SUPPORT ========= Total Invested", response.data.totalInvested);
                }
                const seuil = response.data.totalInvested;
                setTotalInvested(seuil);
            } catch (error) {
                console.log(error);
            }
        };

        const getCurrentActifCapital = async (myid) => {
            try {
                const response = await axiosPrivate.get(`/api/zap/currentActifCapital/${myid}`, {
                    signal: controller.signal,
                });
                if (response.data) {
                    // console.log("SUPPORT ========= capital Actif", response.data.capitalActif);
                    //const dataArraY = plans.filter((p) => p.id === response.data.type);
                    isMounted && setCapitalActif(response.data.capitalActif);
                    isMounted && setUserData(response.data.userdata);
                }
            } catch (error) { }
        };

        // Rebalancaing exist ? zap : noZap
        const getRebalancing = async (myid) => {
            try {
                // make api request
                const res = await axiosPrivate.get(`/api/comparison/test/${myid}`);
                // if true
                isMounted && sethasRebal(res.data);
            } catch (error) {
                // setInfoMessage(error);
            }
        };
        // getZap();
        // getRebalancing();

        // get details affiliate payment
        if (auth?.role.includes(process.env.REACT_APP_CLIENT) || auth?.role.includes(process.env.REACT_APP_PARTENAIRE)) {
            getZap(auth?.id);
            getTotalInvested(auth?.id);
            getRebalancing(auth?.id);
            getCurrentActifCapital(auth?.id);
        } else {
            getZap(id);
            getTotalInvested(id);
            getRebalancing(id);
            getCurrentActifCapital(id);
        }
        // getRebalancing();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrors({});
        setValues({
            coin: values.coin,
            type: values.type,
            trigger: null,
            ats: null,
            st: 999999999,
            wantStop: false,
            seuil: null,
        });
    }, [values.type, isOpen3]);

    return (
        <>
            <div className="p-4">
                <div className="flex items-center ">
                    <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
                        <ArrowNarrowLeftIcon className="h-5 w-5 hover:cursor-pointer" onClick={goBack} />
                    </div>
                    {/* <div className="flex items-center gap-x-2"> */}
                    <div className="font-semibold text-2xl text-slate-700 ml-2 dark:text-white">{t(`label.securePnl`)}</div>
                </div>

                <div className=" mb-1">
                    <div className=" ml-5 mt-4 text-slate-700 text-sm dark:text-white">
                        <div>
                            <span className="font-light">Pour le client </span>
                            {location.state.fullName}
                        </div>

                        <div>
                            <span className="font-light">{t(`label.email`)} : </span>
                            {location.state.email}
                        </div>
                        <div className="flex gap-x-2">
                            {/* <div> Status :</div> */}
                            {location.state.status == 1 ? (
                                <div className=" w-fit px-2 rounded-full bg-green-400 text-white text-sm">Active</div>
                            ) : location.state.status == 10 ? (
                                <div className="w-fit px-2 rounded-full bg-indigo-400 text-white text-sm">Rééquilibré</div>
                            ) : location.state.status == 5 ? (
                                <div className="w-fit px-2 rounded-full bg-red-400 text-white text-sm">API invalide</div>
                            ) : location.state.status == 0 ? (
                                <div className="w-fit px-2 rounded-full bg-slate-400 text-white text-sm">Stop</div>
                            ) : location.state.status == 3 ? (
                                <div className="w-fit px-2 rounded-full bg-orange-400 text-white text-sm">{"<500€"}</div>
                            ) : (
                                <div className="w-fit px-2 rounded-full bg-pink-400 text-white text-sm">Autres</div>
                            )}
                        </div>
                        {/* <div> Status : {location.state.status == 10 ? "yes" : "aaa"}</div> */}
                    </div>
                </div>
                {auth?.role.includes(process.env.REACT_APP_CLIENT) && (
                    <div className="text-right mb-2">
                        <Controls.Button text={isOpen3 ? "Annuler" : "Express"} onClick={() => setIsOpen3(!isOpen3)} color={isOpen3 ? "bg-slate-500 text-white" : null} />
                    </div>
                )}

                {/* Test btn express */}
                {/* <div className="text-right mb-2">
          <Controls.Button text={isOpen3 ? "Annuler" : "Express"} onClick={() => setIsOpen3(!isOpen3)} color={isOpen3 ? "bg-slate-500 text-white" : null} />
        </div> */}

                <div className={`flex flex-col md:flex-row  transition-all duration-200`}>

                    <div className={`flex flex-1 w-full bg-white justify-center scrollbar ${isOpen3 ? "hidden" : "block"}`}>
                        <div className="flex justify-center p-4 text-sm ">
                            <DialogBox title={t(`label.validateSecure`)} isOpen={isOpen} setIsOpen={setIsOpen} customClassName="max-w-[600px]">
                                {/* {values.type === 1 && <div className="text-sm font-light"></div>} */}
                                {values.type === 1 && (
                                    <>
                                        <div className="text-sm font-light">
                                            Je souhaite sécuriser tout montant au dela de <strong>{values.seuil} USDT</strong>
                                        </div>
                                    </>
                                )}
                                {values.type === 2 && (
                                    <>
                                        <div className="text-sm font-light">
                                            Je souhaite déclencher ma sécurisation uniquement si mon capital actif du mois est d'au moins <strong>{values.seuil} USDT</strong>, et si mon bénéfice net du mois est supérieur
                                            ou égal à <strong>{values.trigger} USDT</strong>.
                                        </div>
                                        <div className="text-sm font-light mt-3">
                                            Une fois ces conditions remplies, je sécurise <strong>{values.ats} USDT</strong> du bénéfice net
                                        </div>
                                    </>
                                )}
                                {values.type === 3 && (
                                    <>
                                        <div className="text-sm font-light">
                                            Je souhaite déclencher ma sécurisation uniquement si mon capital actif du mois est d'au moins <strong>{values.seuil} USDT</strong>, et si l'évolution de mon capital actif net sur
                                            mois est supérieur ou égal à <strong>{values.trigger}%</strong>.
                                        </div>
                                        <div className="text-sm font-light mt-3">
                                            Une fois ces conditions remplies, je sécurise <strong>{values.ats}%</strong> du bénéfice net
                                        </div>
                                    </>
                                )}
                                {values.st && values.st != 999999999 && (
                                    <div className="text-sm font-light mt-5">
                                        Le montant maximum que je souhaite sécuriser est de <strong> {values.st} USDT</strong>. Une fois atteint, je souhaite mettre fin à ma sécurisation
                                    </div>
                                )}

                                <div className="mt-2 text-right">
                                    {/* <Controls.Button text={"Je confirme"} onClick={handleConfirmMethod} /> */}
                                    <BtnShad text={"Je confirme "}
                                        onClick={handleConfirmMethod}
                                        sendHistory={{ action: `Securisation type ${values.type} `, who: `${id}` }}
                                    />
                                </div>
                            </DialogBox>
                            <DialogBox title={t(`label.validateSecure`)} isOpen={isOpen2} setIsOpen={setIsOpen2}>
                                {values.type === 1 && (
                                    <>
                                        <div className="text-sm font-light">
                                            Je souhaite sécuriser tout montant au dela de <strong>{values.seuil} USDT</strong>
                                        </div>
                                    </>
                                )}
                                {values.type === 2 && (
                                    <>
                                        <div className="text-sm font-light">
                                            Je souhaite déclencher ma sécurisation uniquement si mon capital actif du mois est d'au moins <strong>{values.seuil} USDT</strong>, et si mon bénéfice net du mois est supérieur
                                            ou égal à <strong>{values.trigger} USDT</strong>.
                                        </div>
                                        <div className="text-sm font-light mt-3">
                                            Une fois ces conditions remplies, je sécurise <strong>{values.ats} USDT</strong> du bénéfice net
                                        </div>
                                    </>
                                )}
                                {values.type === 3 && (
                                    <>
                                        <div className="text-sm font-light">
                                            Je souhaite déclencher ma sécurisation uniquement si mon capital actif du mois est d'au moins <strong>{values.seuil} USDT</strong>, et si l'évolution de mon capital actif net sur
                                            mois est supérieur ou égal à <strong>{values.trigger}%</strong>.
                                        </div>
                                        <div className="text-sm font-light mt-3">
                                            Une fois ces conditions remplies, je sécurise <strong>{values.ats}%</strong> du bénéfice net
                                        </div>
                                    </>
                                )}
                                {values.st && values.st != 999999999 && (
                                    <div className="text-sm font-light mt-5">
                                        Le montant maximum que je souhaite sécuriser est de{" "}
                                        <strong>
                                            {" "}
                                            {values.st} {userData?.securedAsset || "USDT"}
                                        </strong>
                                        . Une fois atteint, je souhaite mettre fin à ma sécurisation
                                    </div>
                                )}
                                <div className="mt-2 text-right">
                                    <Controls.Button text={"Je confirme"} onClick={handleConfirmStop} />
                                </div>
                            </DialogBox>

                            <div className=" w-full p-4 bg-white">
                                {/* Check if zapSelect data exist */}
                                {zapSelect && Object.keys(zapSelect).length ? (
                                    <div className="flex flex-col items-center w-full py-2">
                                        <div className="bg-blue-50 border-2 w-full md:w-[600px] mt-2 p-4  rounded">
                                            <div className="flex items-center font-bold text-lg gap-x-1">
                                                {" "}
                                                {zapSelect.type === 1
                                                    ? t(`label.recolte`)
                                                    : zapSelect.type === 2
                                                        ? t(`label.palier`)
                                                        : zapSelect.type === 3
                                                            ? t(`label.dynamique`)
                                                            : // : zapSelect.type === 4
                                                            // ? "Autres "
                                                            null}
                                                <div className="text-xs">({userData?.securedAsset || "USDT"})</div>
                                            </div>
                                            <div className="pt-3 pl-2 ">
                                                <CustomRender text="Déclencheur" value={`${zapSelect.trigger} USDT`} />
                                                {zapSelect.ats ? (
                                                    zapSelect.type === 2 ? (
                                                        <CustomRender text={t(`label.pnlToSecure`)} value={zapSelect.ats} />
                                                    ) : (
                                                        <CustomRender text={`% de ${t("label.pnlToSecure")}`} value={zapSelect.ats} />
                                                    )
                                                ) : null}
                                                {zapSelect.st && zapSelect.st != 999999999 ? (
                                                    <CustomRender text="Max gains" value={zapSelect.st} />
                                                ) : (
                                                    <div className="py-4 ">
                                                        <div>
                                                            <Controls.Checkbox name={"wantStop"} value={values.wantStop || false} label={"Je souhaite mettre une limite à mon capital sécurisé"} onChange={handleInputChange} />
                                                        </div>
                                                        {values.wantStop && (
                                                            <div className="pt-2">
                                                                Stopper la sécurisation à quand le total du montant sécurisé (en {userData?.securedAsset || "USDT"}) est égal à<span className="font-extralight">(optionnel)</span>
                                                                <div className="flex gap-x-2 mt-4 items-center ">
                                                                    <Controls.Input type="number" label={"max Gains"} name="st" value={values.st === 999999999 ? "" : values.st} onChange={handleInputChange} error={errors.st} />
                                                                    {values.st !== "" && values.st !== 999999999 ? (
                                                                        <div className=" text-right">
                                                                            <Controls.Button text={"OK"} onClick={handleValidateStop} />
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="flex flex-col gap-y-4 w-full md:w-[500px] mx-auto ">
                                            <div>
                                                <div className="font-light pb-4 text-xs">Veuillez choisir le CRYPTO de sécurisation qui vous convient</div>
                                                <Controls.Select
                                                name="coin"
                                                label={"CRYPTO"}
                                                value={values.coin || ""}
                                                onChange={handleInputChange}
                                                 options={zapCoins}
                                                 error={errors.coin}
                                                 noneValue={"Aucun"} />
                                            </div>
                                            <div>
                                                {values.coin && (
                                                    <>
                                                        <div className="font-light pb-4 text-xs">Veuillez choisir le type de sécurisation qui vous convient</div>
                                                        <Controls.Select
                                                            name="type"
                                                            label={"Type sécurisation"}
                                                            value={values.type || ""}
                                                            onChange={handleInputChange}
                                                            options={zapCollection}
                                                            error={errors.type}
                                                            noneValue={"Aucune sécurisation"}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        {values.coin && values.type ? (
                                            <div className="flex items-center justify-center">
                                                <div className="w-0.5 h-10 bg-slate-300" />
                                            </div>
                                        ) : null}

                                        {/* Options */}
                                        {values.coin && values.type && (
                                            <div className="flex flex-col items-center w-full py-2">
                                                {values.type === 1 ? (
                                                    <div className=" w-full md:w-[500px] ">
                                                        <div>
                                                            Sécuriser tout montant au dela de <strong>{values.seuil || "___"} USDT</strong> de mon capital actif
                                                        </div>
                                                        <div className="flex flex-col mt-4 rounded-lg  gap-y-2">
                                                            <Controls.Input
                                                                type="number"
                                                                label={"Seuil de déclenchement"}
                                                                name="seuil"
                                                                value={values.seuil || ""}
                                                                onChange={handleInputChange}
                                                                error={errors.seuil}
                                                                min={totalInvested < amountSeuil ? amountSeuil : totalInvested}
                                                            />

                                                            {/* <Controls.Button text={"Valider"} onClick={handleValidate} /> */}
                                                        </div>
                                                    </div>
                                                ) : values.type === 2 ? (
                                                    <div className="flex flex-col mt-4  w-full md:w-[500px] rounded-lg gap-y-3">
                                                        <div>
                                                            Je souhaite déclencher ma sécurisation à partir de <strong>{values.seuil || "___"} USDT</strong>
                                                            <div className="flex flex-col mt-4 rounded-lg  gap-y-2">
                                                                <Controls.Input type="number" label={"Seuil de déclenchement"} name="seuil" value={values.seuil || ""} onChange={handleInputChange} error={errors.seuil} />
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            Lorsque mon bénéfice net est supérieur ou égal à <strong>{values.trigger || "___"} USDT</strong>{" "}
                                                            <div className="mt-2">
                                                                <Controls.Input type="number" label={"Bénéfice"} name="trigger" value={values.trigger || ""} onChange={handleInputChange} error={errors.trigger} min={0} />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            Je veux sécuriser <strong>{values.ats || "___"} USDT </strong>du bénéfice net
                                                            <div className="mt-2">
                                                                <Controls.Input type="number" label={"Gains à sécuriser"} name="ats" value={values.ats || ""} onChange={handleInputChange} error={errors.ats} min={0} />
                                                            </div>
                                                        </div>

                                                        {/* <Controls.Button text={"Valider"} onClick={handleValidate} /> */}
                                                    </div>
                                                ) : values.type === 3 ? (
                                                    <div className="flex  flex-col gap-y-3 w-full md:w-[500px] ">
                                                        <div>
                                                            Je souhaite déclencher ma sécurisation à partir de <strong>{values.seuil || "___"} USDT</strong>
                                                            <div className="flex flex-col mt-4 rounded-lg  gap-y-2">
                                                                <Controls.Input type="number" label={"Seuil de déclenchement"} name="seuil" value={values.seuil || ""} onChange={handleInputChange} error={errors.seuil} />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                Lorsque l'évolution de mon capital actif net sur le mois est supérieur ou égal à <strong>{values.trigger || "___"} %</strong>
                                                            </div>

                                                            <div className="mt-3">
                                                                <Controls.Input type="number" label={"Évolution du capital"} name="trigger" value={values.trigger || ""} onChange={handleInputChange} error={errors.trigger} />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                Je veux sécuriser
                                                                <strong> {values.ats || "___"} %</strong> du bénéfice net
                                                            </div>
                                                            <div>
                                                                <Controls.RadioGroup
                                                                    items={[
                                                                        { id: "20", title: "20% (audacieux)" },
                                                                        { id: "50", title: "50% (prudent)" },
                                                                    ]}
                                                                    key={""}
                                                                    label={""}
                                                                    value={values.ats}
                                                                    onChange={handleInputChange}
                                                                    name={"ats"}
                                                                    error={errors.ats}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* <Controls.Button text={"Valider"} onClick={handleValidate} /> */}
                                                    </div>
                                                ) : values.type === 4 ? (
                                                    <div className=" w-full md:w-[500px] ">
                                                        <div>1. Sécuriser le montant:</div>
                                                        <div className="flex flex-col mt-4 rounded-lg  gap-y-2">
                                                            <Controls.Input type="text" label={"Montant à sécuriser"} name="trigger" value={values.trigger || ""} onChange={handleInputChange} error={errors.trigger} />
                                                            {/* <Controls.Button text={"Valider"} onClick={handleValidate} /> */}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}

                                        {values.type && values.coin && (
                                            <div className="w-full md:w-[500px]">
                                                <div>
                                                    <Controls.Checkbox name={"wantStop"} value={values.wantStop || false} label={"Je souhaite mettre une limite à mon capital sécurisé"} onChange={handleInputChange} />
                                                </div>

                                                {values.wantStop && (
                                                    <div>
                                                        {" "}
                                                        <div className="py-4 ">
                                                            Stopper la sécurisation quand le total du montant sécurisé (en {values.coin}) est égal à {/* <span className="font-extralight">(optionnel)</span> */}
                                                            <div className="flex gap-x-2 mt-2">
                                                                <Controls.Input
                                                                    type="number"
                                                                    label={"Max gains (optionnel)"}
                                                                    name="st"
                                                                    value={values.st === 999999999 ? "" : values.st}
                                                                    onChange={handleInputChange}
                                                                    error={errors.st}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="">
                                                    <Controls.Button widthFull text={"Valider"} onClick={handleValidate} />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}

                                {/* Options */}
                            </div>
                        </div>
                    </div>

                    <div className={`${isOpen3 ? "block" : "hidden"} h-screen w-full`}>
                        <ExpressRebalancing isOpen={isOpen3} setIsOpen={setIsOpen3} auth={auth} t={t} data={userData} totalInvested={totalInvested} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SecureScreen2;
