import { CircleUser } from "lucide-react"
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from "../ui/dropdown-menu"
import { decryptCBData } from "../../utils/service"

export function ProfileNav(props) {
    const { auth, info } = props
    console.log("info", info);


    return (
        <DropdownMenu>
            {info?.map((m, index) => (
                <>
                    <DropdownMenuTrigger asChild className="hover:cursor-pointer">
                        {m.label}
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56" align="end" forceMount>
                        <DropdownMenuLabel className="font-normal">
                            <div className="flex flex-col space-y-1">
                                <p className="text-sm font-medium leading-none">{decryptCBData(auth?.name)}</p>
                                <p className="text-xs leading-none text-muted-foreground">
                                    {decryptCBData(auth?.email)}
                                </p>
                            </div>
                        </DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                            {m.info?.map((p, index) => (
                                <DropdownMenuItem key={index} onClick={p.onClick}>
                                    <div className="flex items-center space-x-2 hover:cursor-pointer">
                                        <div>
                                            {p.icon}
                                        </div>
                                        <div>
                                            {p.title}
                                        </div>
                                    </div>
                                </DropdownMenuItem>
                            ))}




                        </DropdownMenuGroup>

                    </DropdownMenuContent>
                </>
            ))}

        </DropdownMenu>
    )
}
