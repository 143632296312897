import React, { useState } from 'react'
import SelectShad from './client/SelectShad';
import { useNavigate } from 'react-router-dom';
import { useToast } from "../components/ui/use-toast"
import { useFOrm } from './useFOrm';
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useEffect } from 'react';
import Loading from './Loading';
import BtnShad from '../components/client/btnShadcn';


function AddVoucherToUser(props) {
    const { user, setIsOpen, isOpen } = props
    console.log("voucher user", user);
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [promoCode, setPromoCode] = useState([])
    const axiosPrivate = useAxiosPrivate()

    const { toast } = useToast()
    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("code" in fieldValues) temp.code = fieldValues.code ? "" : t("error.required");
        if ("description" in fieldValues) temp.description = fieldValues.description ? "" : t("error.required");
        if ("discountValue" in fieldValues) temp.discountValue = fieldValues.discountValue ? "" : t("error.required");
        if ("validTo" in fieldValues) temp.validTo = fieldValues.validTo ? "" : t("error.required");
        setErrors({
            ...temp,
        });
        console.log("Temp", temp);

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };
    const initialValues = {
        voucher: ""
    }
    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getAllCoupons = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/coupons`, { signal: controller.signal })
                isMounted && setPromoCode(data.map((m) => ({ id: m.id, item: m.code })))
                console.log("BOOOOOO", data, "promoCode", data.map((m) => ({ id: m.id, item: m.code })));

            } catch (error) {
                console.log("error", error);

            }
        }
        if (isOpen) {
            getAllCoupons()
        }
    }, [isOpen])

    const handleSaveVoucherClient = async () => {
        try {
            console.log("******* voucher id", values.code, "array", user.email);
            // set voucher id and all enmail in a state and send this in back
            const dataToPost = {
                uid: user.id,
                idCoupon: values.voucher
            }
            const { data } = await axiosPrivate.post(`/api/coupons/assign`, dataToPost)

            console.log("voucher id", dataToPost);
            // set voucher id and all enmail in a state and send this in back
            toast({
                title: `Coupon : ${values.voucher}  `,
                description: `${user.email}`,
            })
            console.log("navigate", values);
            setIsOpen(false)

        } catch (error) {
            console.log("NOOOOOO", error);
            toast({
                title: "Assignation de coupon échoué",
                variant: "destructive",
                duration: 30000,
                description: <div>
                    {user.email} : {error.response.data.details.error}
                </div>
            })
            setIsOpen(false)
        }
    }

    const aiza = () => {
        console.log("eo ndray");
    }
    return (
        <div className=' '>
            <SelectShad
                name="voucher"
                option={promoCode}
                placeholder="Choisir la code promo"
                onChange={handleInputChange}
                label="Choisir la code promo"
                value={values.voucher} />
            <BtnShad text={t(`button.save`)} onClick={handleSaveVoucherClient} wFull
                sendHistory={{ action: `Add voucher >> ${values.voucher} << to client `, who: `${user?.id}` }}

            />
        </div>
    )
}

export default AddVoucherToUser;




function DisplayVoucher(props) {
    const { user, isOpen } = props
    const axiosPrivate = useAxiosPrivate()
    const [listCouponUser, setListCouponUser] = useState([])

    const getCouponUser = async () => {
        const { data } = await axiosPrivate.get(`/api/coupons/${user.id}`)
        setListCouponUser(data.coupons)
        console.log("setListCouponUser", data.coupons);
    }
    useEffect(() => {
        if (isOpen) {
            getCouponUser()
        }
    }, [isOpen])

    return (
        <div>

            {listCouponUser ?

                listCouponUser.length > 0 ?
                    listCouponUser?.map((m, i) => (

                        <div key={i} className=' p-2 mx-auto text-sm border-2 mb-2 rounded-lg  '>
                            <div className="scroll-m-20 text-sm font-semibold tracking-tight flex ">
                                <div className='flex-1'>
                                    {m.code}
                                </div>
                                <div>

                                    {m.discountValue} USDT
                                </div>

                            </div>
                            <div className='text-muted-foreground '>
                                {m.description}
                            </div>

                        </div>
                    ))

                    :

                    <div className='text-center text-xs'>
                        Pas de résultats .
                    </div>

                : <Loading />
            }

        </div>
    )
}
export { DisplayVoucher }
