import { ArrowLeftIcon, XIcon } from '@heroicons/react/solid';
import { DataGrid, frFR, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Controls from '../components/controls/Controls';
import DialogBox from '../components/DialogBox';
import Loading from '../components/Loading';
import TableauGrid from '../components/TableauGrid';
import { useFOrm } from '../components/useFOrm';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { decryptCBData } from '../utils/service';
import BtnShad from './client/btnShadcn';

const initialFvalues = {
    paymentMethodId: "",
    status: "",
    transactionDate: "",
    comment: ""
};

function TransactionListUser(props) {
    const { oneSub } = props
    console.log("oneSub", oneSub);
    const axiosPrivate = useAxiosPrivate()
    const { t } = useTranslation()
    const [openEditTransaction, setOpenEditTransaction] = useState(false)
    const [pm, setPm] = useState()
    const [oneRow, setOneRow] = useState()
    const [listTransaction, setListTransaction] = useState()

    const validate = (fieldValues = values) => {
        const temp = { ...errors };

        if ("paymentMethodId" in fieldValues) temp.paymentMethodId = fieldValues.paymentMethodId ? "" : t("error.required");
        if ("status" in fieldValues) temp.status = fieldValues.status ? "" : t("error.required");
        if ("transactionDate" in fieldValues) temp.transactionDate = fieldValues.transactionDate ? "" : t("error.required");
        if ("comment" in fieldValues) temp.comment = fieldValues.comment ? "" : t("error.required");

        setErrors({
            ...temp,
        });
        if (fieldValues == values) {
            return Object.values(temp).every((x) => x == "");
        }
    };

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    const getPaymentMeth = async () => {
        try {
            const { data } = await axiosPrivate.get(`/api/payments/m/`)
            setPm(data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const handleOpenTransaction = async () => {
            try {

                // setOpenTransaction(true)
                const { data } = await axiosPrivate.get(`api/transactions?uid=${oneSub.uid}&sid=${oneSub.id}`)
                setListTransaction(data)
                console.log("LIST TRANS", data);

            } catch (error) {
                console.log(error);
            }
        }

        getPaymentMeth()
        handleOpenTransaction(oneSub)
    }, [])

    const handleEditTransaction = async (id) => {
        setOpenEditTransaction(true)
        // find Id from selectedRow
        const oneRowToFind = listTransaction.find(s => s.id == id)
        setOneRow(oneRowToFind)
        console.log("oneRow to edit", oneRowToFind);
        initialFvalues.paymentMethodId = oneRowToFind.paymentMethodId
        initialFvalues.status = oneRowToFind.status
    }


    const columnTransaction = [
        {
            field: "createdAt", headerName: "Date de création", type: "date", headerClassName: "dark:text-slate-100", width: 130,
            renderCell: (cellValues) => {
                return (
                    <div className='text-xs font-bold'>
                        {moment(cellValues?.row?.createdAt).format("DD-MM-YYYY")}
                    </div>
                );
            },
        },
        { field: "id", headerName: "ID", type: "string", headerClassName: "dark:text-slate-100", hide: true },
        {
            field: "reference", headerName: "Référence", type: "string", headerClassName: "dark:text-slate-100", width: 120,
            renderCell: (cellValues) => {
                return (
                    <div className='text-xs font-bold text-slate-500'>
                        {cellValues?.row?.reference
                        }
                    </div>
                );
            },
        },
        { field: "amount", headerName: "Mtt (USDT)", type: "number", headerClassName: "dark:text-slate-100", width: 70 },
        {
            field: "paymentMethodId", headerName: "Type paiement", headerClassName: "dark:text-slate-100", width: 150,
            type: 'text',
            valueGetter: (cellValues) => {
                let pmName = pm?.find(p => p.id == cellValues?.row?.paymentMethodId)
                return `${pmName?.name}`;
            },
            renderCell: (cellValues) => {
                let pmName = pm?.find(p => p.id == cellValues?.row.paymentMethodId)
                return (
                    <span className='text-xs font-bold'>
                        {/* {cellValues?.row?.payment_method?.name} */}
                        {pmName.name}
                    </span>
                );
            },
        },
        {
            field: "transactionDate", headerName: "Date de paiement", type: "date", headerClassName: "dark:text-slate-100", width: 130,
            renderCell: (cellValues) => {
                return (
                    <div className='text-xs font-bold'>
                        {moment(cellValues?.row?.transactionDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
                    </div>
                );
            },
        },
        {
            field: "status", headerName: "Status", type: "text", headerClassName: "dark:text-slate-100", width: 70,
            valueGetter: (cellValues) => {
                switch (cellValues.row.status) {
                    case 2:
                        return "Failed";
                        break;
                    case 3:
                        return "Canceled";
                        break;
                    case 4:
                        return "Success";
                        break;

                    default:
                        return "Undefined"
                        break;
                }

            },
            renderCell: (cellValues) => {
                let text = ""
                let color = ""
                switch (cellValues.row.status) {
                    case 1:
                        text = "Processing";
                        color = "text-yellow-500"
                        break;
                    case 2:
                        text = "Failed";
                        color = "text-red-500"
                        break;
                    case 3:
                        text = "Annulé";
                        color = "text-gray-500"
                        break;
                    case 4:
                        text = "Success";
                        color = "text-green-500"
                        break;
                    case 5:
                        text = "Récupéré";
                        color = "text-blue-500"
                        break;

                    default:
                        text = "Undefined"
                        color = "text-orange-200"
                        break;
                }

                return (
                    <div className={`${color} text-xs font-bold`}>{text}</div>
                )


            },
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-primary dark:text-slate-100 hover:text-secondary"}
                        text={t(`button.edit`)}
                        onClick={() => handleEditTransaction(cellValues.row.id)}
                    />
                );
            },
        },
        {
            field: "comment",
            headerName: "Raison",
            headerClassName: "dark:text-slate-100",
            type: "text",
            width: 200

        },

    ]

    const status = [
        // { id: 2, title: "Failed" },
        { id: 3, title: "Annulé" },
        { id: 5, title: "Récupéré" },
    ];

    const handleConfirmTransaction = async (tid) => {
        try {
            if (validate()) {
                await axiosPrivate.put(`/api/transactions/${tid}`, values)
                setOpenEditTransaction(false)
                resetForm()
                setListTransaction(prevList => {
                    const indexToUpdate = prevList.findIndex(transaction => transaction.id === tid);

                    if (indexToUpdate !== -1) {
                        // Create a new array with the updated values
                        const updatedListTransaction = [...prevList];
                        updatedListTransaction[indexToUpdate] = { ...prevList[indexToUpdate], ...values };

                        return updatedListTransaction;
                    }

                    // If the item is not found, return the original array
                    return prevList;
                });
                //window.location.reload()
                console.log("VALUES DATYYYY", values);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="" >
            <div className='flex flex-col space-y-2 text-xs'>
                {/* <div className=''>

                    Transaction pour :  <strong>{decryptCBData(oneSub && oneSub?.u?.email)}</strong>
                </div>
                <div className='text-xs'>
                    <div>
                        Produit : <strong>{oneSub && oneSub?.product?.name}</strong>
                    </div>
                </div> */}
                {openEditTransaction ?
                    <div className='bg-slate-100 py-2'>
                        <div className='flex justify-start pl-2 w-full'>
                            <ArrowLeftIcon className='h-4 w-4 hover:cursor-pointer ' onClick={() => setOpenEditTransaction(false)} />
                        </div>
                        <div className='w-full flex flex-col items-center '>
                            <div className='w-1/2 flex flex-col space-y-2'>
                                <div>Ref : <strong> {oneRow && oneRow?.reference}</strong></div>


                                <Controls.Select
                                    name="paymentMethodId" label="Méthode de paiement" value={values.paymentMethodId} onChange={handleInputChange} error={errors.paymentMethodId}
                                    options={pm}
                                />
                                <Controls.Select
                                    name="status" label={"Status"} value={values.status} onChange={handleInputChange} options={status} error={errors.status} noneValue={"Aucun"} />
                                <Controls.Input type="date" name={"transactionDate"} label={"Date de paiement"} value={values.transactionDate} onChange={handleInputChange} error={errors.transactionDate} />
                                <Controls.Input type="text" name={"comment"} label={"Raison du changement"} value={values.comment} onChange={handleInputChange} error={errors.comment} />

                                {/* <Controls.Button
                                    text={`${t(`button.save`)}`}
                                    onClick={() => { handleConfirmTransaction(oneRow?.id) }}
                                /> */}
                                <BtnShad
                                    onClick={() => { handleConfirmTransaction(oneRow?.id) }}
                                    text={`${t(`button.save`)}`}
                                    sendHistory={{ action: `Modif Transaction Abonnement Ref : ${oneRow && oneRow?.reference} `, who: ` ${oneRow && oneRow?.uid} ` }}
                                />
                            </div>
                        </div>
                    </div> :
                    <div className='h-[300px]'>
                        {listTransaction ?
                            <TableauGrid column={columnTransaction} dataRows={listTransaction && listTransaction} showQuickFilter />
                            : <Loading />}

                    </div>
                }
            </div>
        </div>
    )
}

export default TransactionListUser
