import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function BoardScreen(props) {
  const { t, id } = props;
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const [allLists, setAllLists] = useState();
  const [allBoard, setAllBoard] = useState([]);
  const [oneBoard, setOneBoard] = useState();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getAllBoard = async () => {
      try {
        const resp = await axiosPrivate.get(`/api/planit/board/findAll`, { signal: controller.signal });
        isMounted && setAllBoard(resp.data);
        console.log("BOOOOOO", resp.data);
        console.log("=====", allBoard[1].name, "list", allBoard[0].listPlanit[0].name);
      } catch (error) {
        console.log("error", error);
      }
    };

    const getOneBoard = async () => {
      try {
        const resp = await axiosPrivate.get(`/api/planit/board/findOne/${id}`, { signal: controller.signal });
        isMounted && setOneBoard(resp.data);
        console.log("BOARD 3", resp.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    getAllBoard();
    getOneBoard();
  }, []);

  const handleDisplayList = async (board) => {
    console.log("Heeee", board.id, board.name);
    try {
      navigate(`/board/${board.id}`, {state: {boardId: board.id}});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
    <div>Planit</div>
      <div className="flex space-x-4 justify-center bg-green-500 p-2">
        {allBoard.map((board, i) => {
          return (
            <div onClick={() => handleDisplayList(board)} key={i} className="hover:cursor-pointer p-2  rounded-md bg-white w-fit hover:bg-red-900 hover:text-white">
              {board.name}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default BoardScreen;
