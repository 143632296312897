import React, { useState } from 'react'
import Controls from './controls/Controls';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { authSelector } from '../features/authSlice';
import { useSelector } from 'react-redux';
import DialogBox from './DialogBox';
import { Icon } from "coinmarketcap-cryptocurrency-icons";
import Loading from './Loading';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateUser } from '../features/user/userSlice';


function BtnClientOk(props) {
    const { uid, userData } = props
    const axiosPrivate = useAxiosPrivate()
    const { t } = useTranslation()
    const auth = useSelector(authSelector);
    const dispacth = useDispatch();
    const today = moment(new Date()).format("YYYY-MM-DD");
    const [balance, setBalance] = useState()
    const [isUserOkLoading, setIsUserOkLoading] = useState(false);
    const [isUserOkOpen, setIsUserOkOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [testApiW, setTestApiW] = useState(
        {
            status: false,
            loading: true,
            error: null
        }
    );
    const [testApiT, setTestApiT] = useState({
        status: false,
        loading: true,
        error: null

    });

    const handleCheckBalance = async () => {

        setIsUserOkLoading(true);
        const response = await axiosPrivate.get(`/api/lastbalance/now/${uid}`);
        // if (response.status != 200) throw response
        if (response.data) {
            const totalJSon = JSON.parse(response?.data?.total) || 0;
            setBalance(totalJSon)
            // if Ro = 200 show popup
            if (response.status == 200) {
                setIsUserOkOpen(true)
                try {
                    // test W
                    const responseW = await axiosPrivate.get(`/api/details/testw/${uid}`);
                    setTestApiW(
                        {
                            status: true,
                            loading: false,
                            error: null

                        }
                    );

                } catch (error) {
                    const stringifyErr = JSON.stringify(error?.response?.data)
                    setTestApiW(
                        {
                            status: false,
                            loading: false,
                            error: stringifyErr || error

                        })

                }

                // test T
                try {

                    const responseT = await axiosPrivate.get(`/api/details/testt/${uid}`);
                    setTestApiT({
                        status: true,
                        loading: false,
                        error: null
                    });

                } catch (error) {
                    const stringifyErr = JSON.stringify(error?.response?.data)

                    setTestApiT({
                        status: false,
                        loading: false,
                        error: stringifyErr || error

                    });

                }
                setIsUserOkLoading(false);
            } else {
                toast.error(`${t("error.checkAPIRO")}`);

                setIsUserOkLoading(false);
            }
        }
        else {
            toast.error("Ce compte a un problème API")
            setIsUserOkLoading(false);

        }

    };

    const handleUpdateUserStatus = async () => {
        let status;
        setIsLoading(true)
        try {
            if (userData.status == 8) {
                status = 10;
            } else {
                status = 6;
            }
            const response = await axiosPrivate.put(`/api/users/${userData.id}`, { status: status, by_followup: auth.name, date_followup: today, comment_followup: null });
            if (response.data[0] === 1) {
                dispacth(updateUser({ status: status, by_followup: auth.name, date_followup: today, comment_followup: null }));
                toast.success(`${userData?.email} is OK`);
            }

            //get tickets
            const resTicket = await axiosPrivate.get(`/api/ticket/findBy/${userData.id}`);

            if (!resTicket) throw "No ticket found";

            resTicket.data.forEach(async (t) => {
                await axiosPrivate.put(`/api/ticket/${t.id}`, { status: true });
                toast.success(`${t.id} closed`);
            });
            setIsLoading(false)
            setIsUserOkOpen(false)
            //update tickets already done
        } catch (error) {
            setIsLoading(false)
            toast.error(`Error occurs , please try again , ${error}`);
        }
    };


    return (
        <>

            <div>
                <Controls.Button
                    xsFont
                    isLoading={isUserOkLoading}
                    small
                    color={"bg-green-300 hover:bg-green-400"}
                    text={"Client OK"}
                    onClick={handleCheckBalance}
                />
            </div>
            
            <DialogBox isOpen={isUserOkOpen} setIsOpen={setIsUserOkOpen} customClassName={"w-2/3 py-2"}>
                <div className="flex justify-center items-center  space-x-2">
                    <div className="font-bold ">{userData.email}</div>
                    <div className="flex space-x-4">
                        <div className="flex space-x-2 items-center ">
                            <div>{balance?.usdt || 0}</div>
                            <Icon i="usdt" size={20} />
                        </div>
                        <div className="flex space-x-2 items-center ">
                            <div>{balance?.eur || 0}</div>
                            <Icon i="eur" size={20} />
                        </div>
                    </div>
                </div>
                <div className="flex gap-x-2  mt-2">
                    {testApiW.loading ? <Loading /> :
                        <>

                            <h6 className="flex-none font-semibold text-sm ">Crypto-bulot-W</h6>

                            {testApiW.status ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />
                            }                                        </>
                    }
                </div>
                <div className="text-xs text-red-500">{testApiW.error}</div>

                <div className="flex gap-x-2  mt-2">
                    {testApiT.loading ? <Loading /> :
                        <>
                            <h6 className="flex-none font-semibold text-sm">Crypto-bulot-T</h6>
                            {testApiT.status ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />
                            }
                        </>
                    }
                    {/* {testApiT ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />} */}
                </div>
                {testApiT.error && <div className="text-xs text-red-500">{testApiT.error}</div>}

                {balance?.usdt > 500 && testApiT.status ?
                    <>

                        {/* <div className="flex gap-x-2 justify-center mt-2">
                                            <h6 className="flex-none font-semibold text-sm">Crypto-bulot-W</h6>
                                            {testApiW ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />}
                                        </div>
                                        <div className="flex gap-x-2 justify-center mt-2">
                                            <h6 className="flex-none font-semibold text-sm">Crypto-bulot-T</h6>
                                            {testApiT ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />}
                                        </div> */}

                        <div className="flex space-x-4 justify-center items-center my-2">
                            <Controls.Button
                                disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                // xsFont
                                isLoading={isLoading}
                                // small
                                color={"bg-green-300 hover:bg-green-400"}
                                text={"Confirmer Client OK "}
                                onClick={handleUpdateUserStatus}
                            />
                        </div>
                    </>
                    :
                    <>
                        <div className="flex justify-center ">

                            <div className=" text-center mt-4 font-bold text-red-600 px-2 w-fit ">!! Ce client ne rempli pas les conditions nécéssaires au rééquilibrage</div>
                        </div>
                        {/* <div>Veuillez choisir le cas du client</div> */}
                        <div className="flex space-x-4 justify-center items-center mt-4">

                            <Controls.Button
                                disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                // xsFont
                                // small
                                color={"bg-red-500 text-white"}
                                text={<div>
                                    Annuler le "Client OK"
                                </div>}
                                onClick={() => setIsUserOkOpen(false)}
                            />
                        </div>
                    </>

                }
            </DialogBox>
        </>

    )
}

export default BtnClientOk
