import React, { useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import TopBar from "../TopBar";
import Sidebar from "../Sidebar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Controls from "../controls/Controls";
import moment from "moment";
import "moment/locale/fr";
import { DataGrid, frFR, GridToolbar } from "@mui/x-data-grid";
import Loading from "../Loading";
import * as sideMenus from "../../utils/sideMenus";
import LinearProgress from "@mui/material/LinearProgress";
import DashboradCard from "../cards/DashboardCard";
import { decryptCBData } from "../../utils/service";

function Payment(props) {
    const { t } = props;
    const [payments, setPayments] = useState();
    const [totalPayed, setTotalPayed] = useState();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const goBack = () => navigate(-1);
    const column = [
        { field: "parent", headerName: "ID", type: "number" },
        {
            field: "email",
            headerName: t("label.email"),
            width: 200,
            valueGetter: (cellValues) => {
                return `${decryptCBData(cellValues.row.email)}`;
            },
            renderCell: (cellValues) => {

                return `${decryptCBData(cellValues.row.email)} `;

            },
        },
        {
            field: "fullName",
            headerName: t("label.lastName"),
            width: 200,
            valueGetter: (cellValues) => {
                return `${decryptCBData(cellValues.row.firstName)} ${decryptCBData(cellValues.row.lastName)}`;
            },
            renderCell: (cellValues) => {
                const { firstName, lastName } = cellValues.row;
                if (firstName == "CB") {
                    return "CB USER";
                } else {
                    return `${decryptCBData(cellValues.row.firstName)} ${decryptCBData(cellValues.row.lastName)}`;
                }
            },
        },
        { field: "sumUnpaidAll", headerName: t("label.gainsPayés"), width: 200, type: "number" },
        { field: "sumPaidAll", headerName: t("label.unpaid"), width: 200, type: "number" },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            filterable: false,
            disableExport: true,
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-primary dark:text-slate-100 hover:text-secondary"}
                        text={t(`button.details`)}
                        onClick={(event) => {
                            //handleClick(event, cellValues);
                            navigate(`/admin/payment/${cellValues.row.parent}`, {
                                state: { fullName: cellValues.row.firstName == "CB" ? "CB USER" : `${decryptCBData(cellValues.row.firstName)} ${decryptCBData(cellValues.row.lastName)}` },
                            });
                            console.log(cellValues.row.parent);
                        }}
                    />
                );
            },
        },
    ];

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get(`/api/pnl/affiliate/all`, {
                    signal: controller.signal,
                });
                isMounted && setPayments(response.data);
                const test = response.data
                    ?.map((p) => Number(p.sumPaidAll + p.sumUnpaidAll))
                    .reduce((acc, amount) => Number(acc) + Number(amount), 0)
                    .toFixed(2);
                response.data?.map((r, i) => console.log("---------------", r.sumPaidAll));
                console.log("PAYMENTS", response.data);
            } catch (error) {
                if (error.response.status != 403) {
                    navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    return (
        <>
            <TopBar menus={sideMenus.menusAdmin(t)} />
            <div className="flex flex-row flex-grow ">
                <Sidebar menus={sideMenus.menusAdmin(t)} />
                <div className="flex flex-row flex-1  mt-2 md:ml-52">
                    <div className="flex flex-col w-full p-4">
                        <div className="flex-none h-auto">
                            <div className="flex items-center  gap-x-1">
                                <ChevronLeftIcon className="h-6 rounded-full hover:bg-slate-300 cursor-pointer" onClick={() => goBack()} />
                                <div className="font-semibold text-2xl text-slate-900 dark:text-white">{t("label.paymentList")}</div>
                            </div>
                            <span className="text-xs text-slate-500 dark:text-slate-400  ">{moment().format("Do MMMM YYYY")}</span>

                            <hr />

                            {/* <div className='m-2 flex space-x-2 justify-end'>
                                <Controls.Button text={t('button.addClient')} small onClick={() => navigate('/admin/user/new')} xsFont />

                            </div> */}

                            <div className="flex justify-end gap-x-2 ">
                                <DashboradCard
                                    bg={"bg-indigo-600"}
                                    number={payments
                                        ?.map((p) => Number(p.sumPaidAll + p.sumUnpaidAll))
                                        .reduce((acc, amount) => Number(acc) + Number(amount), 0)
                                        .toFixed(2)}
                                    description={t(`label.globalSum`)}
                                />

                                <DashboradCard
                                    bg={"bg-sky-500"}
                                    number={payments
                                        ?.map((p) => p.sumUnpaidAll)
                                        .reduce((acc, amount) => Number(acc) + Number(amount), 0)
                                        .toFixed(2)}
                                    description={t("label.gainsPayés")}
                                />

                                <DashboradCard
                                    bg={"bg-blue-500"}
                                    number={payments
                                        ?.map((p) => p.sumPaidAll)
                                        .reduce((acc, amount) => Number(acc) + Number(amount), 0)
                                        .toFixed(2)}
                                    description={t("label.unpaid")}
                                />
                            </div>
                        </div>

                        <div className="h-[450px] bg-slate-100 dark:bg-slate-800 mt-2">
                            {payments ? (
                                <DataGrid
                                    rows={payments}
                                    components={{
                                        Toolbar: GridToolbar,
                                        LoadingOverlay: LinearProgress,
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                    getRowId={(row) => row.parent}
                                    // componentsProps={{
                                    //     footer: { total }
                                    // }}
                                    //autoPageSize={true}
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    initialState={{
                                        sorting: {
                                            sortModel: [{ field: "id", sort: "desc" }],
                                        },
                                    }}
                                    columns={column}
                                    autoPageSize
                                    //pagination={true}
                                    //onCellClick={(value) => console.log("yes", value.id)}
                                    disableColumnSelector
                                    disableColumnFilter
                                    disableDensitySelector
                                    density="compact"
                                    onStateChange={(state) => {
                                        const visibleRows = state.filter.visibleRowsLookup;

                                        let visibleItems = [];
                                        for (const [id, value] of Object.entries(visibleRows)) {
                                            if (value === true) {
                                                visibleItems.push(Number(id));
                                            }
                                        }

                                        const res = payments?.filter((item) => visibleItems.includes(item.parent));
                                        const total = res.map((item) => item.sumPaidAll).reduce((a, b) => Number(a) + Number(b), 0);
                                        setTotalPayed(total);
                                    }}
                                />
                            ) : (
                                <Loading />
                            )}
                        </div>
                        {/* {totalPayed != 0 ? (

                            <div className="flex-none mt-2">
                                <div className='flex items-center space-x-2'>
                                    <div className='text-sm font-semibold'>Total: </div>
                                    <div className='px-2 bg-slate-300 min-w-64 w-32 rounded'>
                                        {totalPayed && totalPayed}
                                    </div>

                                </div>

                            </div>
                        ) : null} */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Payment;
