import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: null,
  capital: null,
  capitalManaged: null,
  pnl: null,
  affiliate: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardData: (state, action) => {
      return action.payload;
    },
  },
});

export const { setDashboardData } = dashboardSlice.actions;
export const dashboardDataSelector = (state) => state.dashboard;

export default dashboardSlice.reducer;
