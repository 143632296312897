import React from "react";
import EquipeCB from "../components/EquipeCB";
import TopBar from "../components/TopBar";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import { authSelector } from "../features/authSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectPlanitCardToday } from "../features/planitSlice";
function TeamScreen(props) {
  const { t } = props;
  const { id } = useParams(); // maka
  const auth = useSelector(authSelector);
    const todayCard = useSelector(selectPlanitCardToday)


  return (
    <div className="bg-cover bg-center bg-no-repeat" style={{ backgroundImage: 'url("../asset/bgblue.jpeg")' }}>
      {auth.role.includes(process.env.REACT_APP_SUPPORT) ? (
              <TopBar menus={sideMenus.menusSupport(t, id, auth.role, todayCard.length)} />
      ) : auth.role.includes(process.env.REACT_APP_CLIENT) ? (
        <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />
      ) : null}
      <div className="flex flex-row">
        {auth.role.includes(process.env.REACT_APP_SUPPORT) ? (
          <Sidebar menus={sideMenus.menusSupport(t, id, auth.role)} />
        ) : auth.role.includes(process.env.REACT_APP_CLIENT) ? (
          <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />
        ) : null}
        <div className="flex-1 max-w-[100%] ">
          <div className={`flex flex-col gap-y-4 ${auth.role.includes(process.env.REACT_APP_SUPADMIN) ? null : "md:ml-52"}  p-4`}>
            <div className="flex gap-x-2">
              <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.team`)}</h1>
              <hr />
            </div>
            <div className=" bg-slate-100 dark:bg-slate-800 p-2 rounded-sm ">
              <EquipeCB t={t} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamScreen;
