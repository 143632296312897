import React from 'react'
import { useTranslation } from 'react-i18next'

function Strategy(props) {
    const {t} = useTranslation()
    const { type } = props
    return (
        <div>
            {type == "ai" ? <div className="text-cyan-600 font-medium text-xs p-1 px-2 rounded-lg text-center w-fit">{t(`strategy.ai`)} </div>
                : type == "normal" ? <div className="text-cyan-600 font-medium text-xs px-2 p-1 rounded-lg text-center w-fit">{t(`strategy.cbdt`)} </div>
                    : null}
        </div>
    )
}

export default Strategy
