import React from "react";
import { Progress } from "../ui/progress"
import { TypographySmall } from "../Typo";
import { useTranslation } from "react-i18next";

const ProgressBarOb = ({ steps, currentStep }) => {
    const percentage = ((currentStep - 1) / (steps - 1)) * 100;
    const { t } = useTranslation()
    return (
        <div className="flex flex-col items-center w-[24rem] mb-2">
            <div className="w-autu flex justify-center mr-4">
                <TypographySmall>{t(`label.step`)} {currentStep}/{steps}</TypographySmall>
            </div>
            <Progress value={percentage} />
        </div>
    )

};
export default ProgressBarOb;
