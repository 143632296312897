import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: "",
    role: [],
    token: "",
    name: "",
    id: 0,
    lang: "fr"
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            // ...action.payload;
            // state.token = action.payload.token;
            // state.role = action.payload.role.split(",");
            // state.email = action.payload.email;
            // state.name = action.payload.name;
            // state.id = action.payload.id;
            const { token, role, email, name, id, strategy } = action.payload;

            state.token = token;
            state.role = role.split(",");
            state.email = email;
            state.name = name;
            state.id = id;
            state.strategy = strategy;
            // Add all remaining properties from action.payload to state
            // state = { ...state, ...remainingPayload };
        },
        logout: (state, action) => {
            return initialState;
        },
        updateToken: (state, action) => {
            state.token = action.payload;
        },
        updateLang: (state, action) => {
            state.lang = action.payload;
        },
    },
});

export const { login, logout, updateToken, updateLang } = authSlice.actions;
export const authSelector = (state) => state.auth;
export const authTokenSelector = (state) => state.auth?.token;

export default authSlice.reducer;
