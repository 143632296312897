import React, { useMemo } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  RectangleProps,
  Scatter,
} from "recharts";

const DotBar = (props) => {
  const { x, y, width, height } = props;

  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  return (
    <line
      // y1={x + width / 2}
      // x1={y + height}
      // y2={x + width / 2}
      // x2={y}
      x1={x + width / 2}
      y1={y + height}
      x2={x + width / 2}
      y2={y}
      stroke={"#000"}
      strokeWidth={5}
      stroke-dasharray={"5"}
    />
  );
};
//RectangleProps;
const HorizonBar = (props) => {
  const { x, y, width, height } = props;

  if (x == null || y == null || width == null || height == null) {
    return null;
  }
  // return <Bar barSize={20} />;
  //return <line y1={x} x1={y} y2={x + width} x2={y} stroke={"#000"} strokeWidth={3} />;
  return <line x1={x} y1={y} x2={x + width} y2={y} stroke={"#000"} strokeWidth={3} />;
};

// type BoxPlot = {
//   min: number,
//   lowerQuartile: number,
//   median: number,
//   upperQuartile: number,
//   max: number,
//   average?: number,
// };

// type BoxPlotData = {
//   min: number,
//   bottomWhisker: number,
//   bottomBox: number,
//   topBox: number,
//   topWhisker: number,
//   average?: number,
//   size: number, // for average dot size
// };

const useBoxPlot = (boxPlots) => {
  const data = useMemo(
    () =>
      boxPlots.map((v) => {
        return {
          name: v.name,
          min: v.min,
          bottomWhisker: v.lowerQuartile - v.min,
          bottomBox: v.median - v.lowerQuartile,
          topBox: v.upperQuartile - v.median,
          topWhisker: v.max - v.upperQuartile,
          average: v.average,
          size: 200,
        };
      }),
    [boxPlots]
  );

  return data;
};

function BoxplotCustom(props) {
  const { boxPlots } = props;
  const data = useBoxPlot(boxPlots);
  return (
    <>
      {/* <ResponsiveContainer width={"100%"} minHeight={"100px"}>
        <ComposedChart data={data} layout={"vertical"}>
          <CartesianGrid strokeDasharray="3 3" />
          <Bar stackId={"a"} dataKey={"min"} shape={<DotBar />} />
          <Bar stackId={"a"} dataKey={"bar"} shape={<HorizonBar />} />
          <Bar stackId={"a"} dataKey={"bottomWhisker"} shape={<DotBar />} />
          <Bar stackId={"a"} dataKey={"bottomBox"} fill={"#ef4444"} />
          <Bar stackId={"a"} dataKey={"bar"} shape={<HorizonBar />} />
          <Bar stackId={"a"} dataKey={"topBox"} fill={"#8884d8"} />
          <Bar stackId={"a"} dataKey={"topWhisker"} shape={<DotBar />} />
          <Bar stackId={"a"} dataKey={"bar"} shape={<HorizonBar />} />
          <ZAxis /> */}
      {/* <ZAxis type="number" dataKey="size" range={[0, 250]} />
          <Scatter dataKey="average" fill={"red"} stroke={"#FFF"} />
          <XAxis type="number" dataKey="size" />
          <YAxis type="category" dataKey="name" />
        </ComposedChart>
      </ResponsiveContainer> */}

      <ResponsiveContainer width={"100%"} minHeight={"100px"}>
        <ComposedChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <Bar stackId={"a"} dataKey={"min"} fill={"none"} />
          <Bar stackId={"a"} dataKey={"bar"} shape={<HorizonBar />} />
          <Bar stackId={"a"} dataKey={"bottomWhisker"} shape={<DotBar />} />
          <Bar stackId={"a"} dataKey={"bottomBox"} fill={"#ef4444"} />
          <Bar stackId={"a"} dataKey={"bar"} shape={<HorizonBar />} />
          <Bar stackId={"a"} dataKey={"topBox"} fill={"#8884d8"} />
          <Bar stackId={"a"} dataKey={"topWhisker"} shape={<DotBar />} />
          <Bar stackId={"a"} dataKey={"bar"} shape={<HorizonBar />} />
          <ZAxis type="category" dataKey="name" />

          <Scatter dataKey="average" fill={"red"} stroke={"#FFF"} />
          <XAxis type="category" dataKey="name" />
          <YAxis />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
}

export default BoxplotCustom;
