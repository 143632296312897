import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useDarkMode from "../../hooks/useDarkMode";
import useOpen from "../../hooks/useOpen";
import { MoonIcon, SunIcon, LogoutIcon, ArrowNarrowLeftIcon } from "@heroicons/react/outline";
import { UsersIcon } from "@heroicons/react/solid";
import { sideBarSelector } from "../../features/sideBarSlice";

import { updateShow } from "../../features/sideBarSlice";
import { authSelector } from "../../features/authSlice";
import { useTranslation } from "react-i18next";
import { Sparkles } from "lucide-react";

function Sidebar(props) {
    const { menus, hide } = props;
    const navigate = useNavigate();
    const location = useLocation();
    //const values = useSelector(sideBarSelector)
    const value = useSelector(sideBarSelector);
    const auth = useSelector(authSelector);
    const { t } = useTranslation()

    const dispacth = useDispatch();

    return (
        //  <div className='bg-slate-100 dark:bg-slate-800
        //      md:flex flex-col justify-between items-center
        //       w-20 md:w-52
        //      border-r-2 border-r-slate-300
        //       dark:border-r-slate-600
        //       min-h-92
        //     hidden md:fixed h-screen top-12
        //       '>

        //       </div>
        <div
            className={`
        ${hide
                    ? "hidden w-0"
                    : "bg-slate-100 dark:bg-slate-800 md:flex flex-col justify-between items-center  w-20 md:w-52 border-r-2 border-r-slate-300 dark:border-r-slate-600 min-h-92 hidden md:fixed h-[94vh] top-12"
                }
transition-all duration-500 ease-in-out
          `}
        >
            {/* // <div className={`
        // bg-slate-100 dark:bg-slate-800
        // w-52
        // z-10
        //  flex flex-col justify-between items-center
        //  border-r-2 border-r-slate-300
        //  dark:border-r-slate-600
        //  min-h-92
        //  fixed top-0 h-screen md:h-auto ${value === 'hide' ? '-translate-x-full md:translate-x-0' : 'translate-x-0'} md:relative md:top-0
        //  transition-translate duration-300
        //  `} > */}
            <div className="flex flex-col w-full flex-grow ">
                {/* <div className='h-12 md:hidden flex justify-end items-center mx-3'>
                    <ViewListIcon className='h-5 w-5' onClick={() => { dispacth(updateShow('hide')) }} />
                </div> */}

                {/* <div className='rounded-full bg-borderGrey w-8 h-8 mx-auto '></div> */}
                {/* <a onClick={() => navigate('/admin/dashboard/')}>
                    <div
                        className={`bg-orange-400 border-l-4 w-full flex space-x-2 py-2 pl-3 hover:bg-slate-200 cursor-pointer text-sm items-center text-black dark:text-white`}
                    >
                        <span>
                            <ArrowNarrowLeftIcon className='text-red-500 h-5 w-5' />
                        </span>
                        <span className=''>
                            Revenir dans Admin
                        </span>

                    </div>
                </a> */}
                {/* dispacth(updateShow('hide')) */}
                {menus?.map((menu) => (
                    <a
                        key={menu.name}
                        onClick={() => {
                            navigate(menu.path);
                        }}
                    >
                        <div
                            className={`${location.pathname == menu.path ? "bg-slate-200 dark:bg-slate-300  border-l-indigo-500 font-medium" : "border-l-slate-100"
                                } border-l-4 w-full flex space-x-2 py-2 pl-3 hover:bg-slate-200 cursor-pointer text-sm items-center text-black dark:text-white`}
                        >
                            <span>{menu.icon}</span>
                            <span className="text-xs">{menu.name}</span>
                            {menu?.notif ? <span className="text-xs flex bg-red-500 rounded-full  h-4 w-4 justify-center items-center font-bold text-white">{menu.notif}</span>
                                : null}
                        </div>
                    </a>
                ))}

                {auth.role.includes(process.env.REACT_APP_CLIENT) || auth.role.includes(process.env.REACT_APP_SUPPORT) ? (
                    <div className="absolute bottom-5 w-full">
                        {auth.role.includes(process.env.REACT_APP_CLIENT) && <a
                            onClick={() => {
                                navigate("/onboarding-ai");
                            }}
                        >
                            <div className="w-full flex space-x-2 py-2 pl-3 text-sm items-center cursor-pointer bg-sky-300 hover:bg-blue-400 text-black hover:text-white  ">
                                <Sparkles className="w-4" />
                                <div>{t(`strategy.ob`)}</div>
                            </div>
                        </a>}

                        <a
                            onClick={() => {
                                navigate("/team");
                            }}
                        >

                            <div className="w-full flex space-x-2 py-2 pl-3 text-sm items-center cursor-pointer hover:bg-blue-400 text-black hover:text-white  ">
                                <UsersIcon className="w-4" />
                                <div>{t(`label.team`)}</div>
                            </div>
                        </a>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default Sidebar;
