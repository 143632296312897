import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

export const zapSlice = createSlice({
  name: "zap",
  initialState,
  reducers: {
    setZap: (state, action) => {
      return action.payload;
    },
    updateZap: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setZap, updateZap } = zapSlice.actions;
export const zapSelector = (state) => state.zap;

export default zapSlice.reducer;
