import { Input } from "../ui/input"
import { cn } from '../../lib/utils';
import { Label } from "../ui/label";
import { TypographyXSError } from "../Typo";


export function InputShadcn(props) {
    const { name, label, value, type, error = null, onChange, fullWidth, readOnly, placeholder, onKeyDown, max, min, helpText, className, labelInput, noMb } = props;
    return (
        <div className={`${noMb ? "" : "mb-2"}`}>
            {labelInput && <Label htmlFor="name" className="text-right font-normal text-xs ml-2">{label}</Label>}

            <Input
                className={cn(`mt-1`, className)}
                type={type}
                placeholder={placeholder}
                disabled={readOnly}
                fullWidth={fullWidth}
                label={label}
                name={name}
                value={value}
                defaultValue={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                // inputProps={{ readOnly: readOnly, max: max, min: min }}
                min={min}
            />
            {error && <TypographyXSError className="">{error}</TypographyXSError>}
            {helpText && <span className="text-xs text-green-700">{helpText}</span>}

        </div>

    )
}
