import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Controls from "../controls/Controls";
import Sidebar from "../Sidebar";
import moment from "moment";
import "moment/locale/fr";
import { Tab } from "@headlessui/react";
import { getUserDetails, userDataSelector, resetUserDetails, updateUser } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import InfoUser from "../userDetails/InfoForm";
import KeyUser from "../userDetails/KeyForm";
import DocumentsForm from "../userDetails/DocumentsFormSimple";
import BinanceForm from "../userDetails/BinanceForm";
import KrakenForm from "../userDetails/KrakenForm";
import CapitalForm from "../userDetails/CapitalForm";
import Parrainage from "../userDetails/Parrainage";
import { CheckCircleIcon, ChevronLeftIcon, UserCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import Loading from "../Loading";
import { useNavigate, useLocation } from "react-router-dom";
import * as sideMenus from "../../utils/sideMenus";
import TopBar from "../TopBar";
import Rebalancing from "../userDetails/Rebalancing";
import { authSelector } from "../../features/authSlice";
import { toast } from "react-toastify";
import * as service from "../../utils/service";
import * as ticketService from "../../utils/ticketService";
import { Icon } from "coinmarketcap-cryptocurrency-icons";


import DialogBox from "../DialogBox";
import BannerInfo from "../BannerInfo";
import Subscription from "../userDetails/Subscription";
import { selectPlanitCardToday } from "../../features/planitSlice";
import BtnShad from "../client/btnShadcn";
import { DialogDemo } from "../client/dialogShad";
import { TypographyMuted, TypographyMutedXS, TypographyS, TypographySmall, TypographyXS } from "../Typo";
import useCountDown from "../../hooks/useCountDown";
import Autonome from "../userDetails/Autonome";
import { Badge } from "../ui/badge";
import NotificationScreen from "../../screens/NotificationScreen";
import { Star } from "lucide-react";
import { axiosBQ } from "../../axios";
import * as apiService from "../../utils/apiService"
import { useResetRecoilState } from "recoil";
import { userInfoAutonome } from "../../RecoilState";



function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
function OneUser(props) {
    const { t } = props;
    const { id } = useParams();
    const userData = useSelector(userDataSelector);
    console.log("userData", userData)
    const auth = useSelector(authSelector);
    const [listUser, setListUser] = useState([]);
    const [motifNotOk, setMotifNotOk] = useState();
    const [balance, setBalance] = useState();
    const [errorInputnotOk, setErrorInputnotOk] = useState();
    const [otherMotifNotOk, setOtherMotifNotOk] = useState();
    const [errorOther, setErrorOther] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdateStatusLoading, setIsUpdateStatusLoading] = useState(false);
    const [isUserOkLoading, setIsUserOkLoading] = useState(false);
    const [isUserOkOpen, setIsUserOkOpen] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const dispacth = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const goBack = () => navigate(-1);
    const today = moment(new Date()).format("YYYY-MM-DD");
    const [selectedTab, setSelectedTab] = useState(localStorage.getItem("activeTab"));
    const todayCard = useSelector(selectPlanitCardToday)
    const [isOnboarderLoading, setIsOnboarderLoading] = useState(false);
    const [testApiW, setTestApiW] = useState(
        {
            status: false,
            loading: true,
            error: null
        }
    );
    const [testApiT, setTestApiT] = useState({
        status: false,
        loading: true,
        error: null

    });
    const { secondsLeft, start } = useCountDown()
    const [respTest, setRespTest] = useState([]);
    const [isPlayLoading, setIsPlayLoading] = useState(false);
    const resetUserInfoAutonome = useResetRecoilState(userInfoAutonome)



    const handleTabClick = (category) => {
        setSelectedTab(category);
        localStorage.setItem("activeTab", category)
        console.log("Active Tab", selectedTab, "Local", localStorage.getItem("activeTab"))
    };



    useEffect(() => {
        dispacth(resetUserDetails());
        resetUserInfoAutonome()
        let isMounted = true;
        const controller = new AbortController();

        const getUser = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/users/genericFindOne/${id}`, {
                    signal: controller.signal,
                });

                // Get and decrypt All User
                const decryptAllUsers = JSON.parse(service.decryptCBData(data.all));

                const formatData = decryptAllUsers.map((resp) => ({
                    ...resp,
                    firstName: service.decryptCBData(resp.firstName),
                    lastName: service.decryptCBData(resp.lastName),
                }));
                isMounted && setListUser(formatData);

                const decrypt = service.decryptOneUserInfo(data.oneUser);

                dispacth(getUserDetails(decrypt));
                // console.log("decrypt--------------------------------", decrypt);
            } catch (error) {
                console.log(error);
                if (error.status != 403) {
                    navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };
        getUser();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const handleUpdateUserStatus = async () => {
        //const today = moment(new Date()).format("YYYY-MM-DD")
        // console.log("BLA BLA");
        let status;
        setIsUpdateStatusLoading(true)
        try {
            if (userData.status == 8) {
                status = 10;
            } else {
                status = 6;
            }
            const response = await axiosPrivate.put(`/api/users/${userData.id}`, { status: status, by_followup: auth.name, date_followup: today, comment_followup: null });
            if (response.data[0] === 1) {
                dispacth(updateUser({ status: status, by_followup: auth.name, date_followup: today, comment_followup: null }));
                toast.success(`${userData.firstName} is OK`);
            }

            //get tickets

            const resTicket = await axiosPrivate.get(`/api/ticket/findBy/${userData.id}`);

            console.log(resTicket);

            if (!resTicket) throw "No ticket found";

            resTicket.data.forEach(async (t) => {
                await axiosPrivate.put(`/api/ticket/${t.id}`, { status: true });
                toast.success(`${t.id} closed`);
            });
            setIsUpdateStatusLoading(false)
            setIsUserOkOpen(false)
            //update tickets already done
        } catch (error) {
            setIsUpdateStatusLoading(false)
            toast.error(`Error occurs , please try again , ${error}`);
        }
        setIsUpdateStatusLoading(false)
    };
    const handleUpdateUserTransferStatus = async () => {
        // console.log("BLA BLA");
        let status;
        setIsUpdateStatusLoading(true)
        try {
            if (userData.status == 8) {
                status = 10;
            } else {
                status = 14;
            }
            const response = await axiosPrivate.put(`/api/users/${userData.id}`, { status: status, by_followup: auth.name, date_followup: today, comment_followup: null });
            if (response.data[0] === 1) {
                dispacth(updateUser({ status: status, by_followup: auth.name, date_followup: today, comment_followup: null }));
                toast.success(`${userData.firstName} to transfer`);
            }
            console.log("status", status);

            //get tickets

            const resTicket = await axiosPrivate.get(`/api/ticket/findBy/${userData.id}`);

            console.log("resTicket", resTicket);

            // if (!resTicket) throw "No ticket found";

            // resTicket.data.forEach(async (t) => {
            //     await axiosPrivate.put(`/api/ticket/${t.id}`, { status: true });
            //     toast.success(`${t.id} closed`);
            // });
            // setIsUpdateStatusLoading(false)
            //update tickets already done
        } catch (error) {
            setIsUpdateStatusLoading(false)
            toast.error(`Error occurs , please try again , ${error}`);
        }
    };

    const handleSaveSwitch = async () => {
        try {
            console.log("SELF to ACTUVE");
            // mila mandefa id
            await axiosPrivate.get(`/api/onboarding/toActive/${userData?.id}`)
            toast({
                title: `Autonome => Active `,
                description: `Le status du client ${userData.email} est désormais ACTIVE`,
                duration: 30000,
            })
            console.log(`Lasa le switch ehhh 😎 ${userData.email}`);
            window.location.reload()

        } catch (error) {
            console.error(`TSY NETY LE AUTONOME +> ACTIVE, ${userData?.id}`);
            toast({
                title: ` ECHEC de l'opération !! `,
                description: `  ${error.response.data.msgCode}`,
                variant: "destructive",
                duration: 30000,
            })
        }
        start(0)

    }


    const handleCheckBalance = async () => {

        setIsUserOkLoading(true);
        const response = await axiosPrivate.get(`/api/lastbalance/now/${userData.id}`);
        // if (response.status != 200) throw response
        if (response.data) {
            console.log(" =============", response);
            const totalJSon = JSON.parse(response?.data?.total) || 0;
            setBalance(totalJSon)
            // if Ro = 200 show popup
            if (response.status == 200) {
                setIsUserOkOpen(true)
                const { data } = await axiosPrivate.get(`/api/admin/client-ok/${userData.id}`)
                console.log("TEST DEV", typeof data, data);
                const splitData = data.split(/(?=\{)/)
                const dataArray = splitData.map(d => JSON.parse(d))
                setRespTest(dataArray)
                console.log("RO", dataArray);

                // test W
                /* try {
                     let responseW = await apiService.testApi("w", userData.id)
                     if (responseW.response.data) {


                         const resp = responseW.response.data.filter((r) => !r.isIpWorking)

                         // const responseW = await axiosPrivate.get(`/api/details/testw/${userData.id}`);
                         console.log("ATO ATO hahahha +++++++");
                         if (resp.length > 0) {
                             console.log("Throw API W");
                             throw "Error Api W"

                         }
                         // if (responseW.status === 200) {
                         setTestApiW(
                             {
                                 status: true,
                                 loading: false,
                                 error: null

                             }
                         );



                         // test T
                         try {
                             let { statusApi, response } = await apiService.testApi("t", userData.id)

                             const resp = response.data.filter((r) => !r.isIpWorking)

                             if (resp.length > 0) {
                                 throw "Error Api T"
                             }
                             // const responseT = await axiosPrivate.get(`/api/details/testt/${userData.id}`);
                             // console.log("API T", responseT.status);
                             setTestApiT({
                                 status: true,
                                 loading: false,
                                 error: null
                             });

                         } catch (error) {
                             console.log(error);
                             // const stringifyErr = JSON.stringify(error?.response?.data)

                             setTestApiT({
                                 status: false,
                                 loading: false,
                                 error: "Error Api T"

                             });

                         }
                     }
                 } catch (error) {
                     console.log(error);
                     // const stringifyErr = JSON.stringify(error?.response?.data)
                     // setErrorW(stringifyErr);
                     setTestApiW(
                         {
                             status: false,
                             loading: false,
                             error: "Error Api W"
                         })

                 }*/

                setIsUserOkLoading(false);
            } else {
                toast.error(`${t("error.checkAPIRO")}`);

                setIsUserOkLoading(false);
            }
        }
        else {
            toast.error("Ce compte a un problème API")
            setIsUserOkLoading(false);

        }

    };

    //when rest password button is clicked
    const handleResetPassword = async (email) => {
        await service.requestNewPassword(email);
    };

    //if not ok is clicked
    const handleNotOk = async (uid) => {
        console.log(uid, motifNotOk);
        try {
            if (!motifNotOk) return setErrorInputnotOk("Obligatoire");
            if (motifNotOk === "other" && !otherMotifNotOk) return setErrorOther("Obligatoire");

            if (motifNotOk === "other" && otherMotifNotOk) {
                await axiosPrivate.put(`/api/users/${uid}`, { comment_followup: otherMotifNotOk, by_followup: auth.name, date_followup: today });
                setErrorInputnotOk("");
            } else {
                await axiosPrivate.put(`/api/users/${uid}`, { comment_followup: motifNotOk, by_followup: auth.name, date_followup: today });
                setErrorOther("");
            }
            toast.success(`Modification enregistrer`);
            setIsOpen(false);
        } catch (error) {
            toast.error(`Error try again later`);
        }
    };

    const handleUnblockUser = async () => {
        try {
            setIsLoading(true)
            const dataToUpdate = { attempt: 0 }
            const { data } = await axiosPrivate.put(`/api/users/${userData.id}`, dataToUpdate);
            if (data[0] === 1) {
                dispacth(updateUser(dataToUpdate));
                toast.success(`Le compte de ${userData.firstName} est débloqué `);
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            toast.error(`error`);

        }

    }
    useEffect(() => {
        if (motifNotOk) return setErrorInputnotOk("");
    }, [motifNotOk]);

    useEffect(() => {
        if (otherMotifNotOk) return setErrorOther("");
    }, [otherMotifNotOk]);

    let disabled = [2, 6, 9, 10];

    let categories = ["Profil", "Documents", "Adresse", "Capital", "APIs", "Parrain", "Rééq", "Notification"];
    let categoriesAutonome = ["Profil", "Documents", "Adresse", "Capital", "APIs", "Parrain", "Rééq", "Autonome", "Notification"];
    let categoriesAI = ["Profil", "Adresse", "Capital", "APIs", "Rééq", "Notification"];
    let categoriesAIAutonome = ["Profil", "Adresse", "Capital", "APIs", "Rééq", "Autonome", "Notification"];
    // let categories = ["Profil", "Documents", "Adresse", "Capital", "APIs", "Parrain", "Rééq", "Subscription"];
    let supp = ""
    useEffect(() => {
        const storedTab = localStorage.getItem("activeTab");
        if (storedTab && categories.includes(storedTab)) {
            setSelectedTab(storedTab);
        }

    }, []);

    const AddressExchanger = () => {
        switch (userData?.platform) {
            case 1:
                return <BinanceForm data={(userData && userData?.details) || ""} t={t} uid={id} />;
            case 2:
                return <KrakenForm data={(userData && userData?.details) || []} t={t} uid={id} />;
        }

    }
    const renderTabContent = () => {

        switch (selectedTab) {
            case "Documents":
                return <DocumentsForm data={userData && userData?.documents} t={t} uid={id} />;
            case "Adresse":
                return AddressExchanger
            case "Capital":
                return <CapitalForm data={(userData && userData?.details) || ""} t={t} uid={id} />;
            case "APIs":
                return <KeyUser data={(userData && userData?.details) || ""} platform={(userData && userData?.platform)} t={t} uid={id} />;
            case "Parrain":
                return <Parrainage data={listUser && listUser} t={t} uid={id} />;
            case "Rééq":
                return <Rebalancing data={userData && userData} t={t} uid={id} />;
            case "Autonome":
                return <Autonome t={t} uid={id} data={(userData && userData) || ""} />;
            case "Notification":
                return <NotificationScreen userId={id} />;
            // case "Subscription":
            //   return <Subscription t={t} uid={id} />;
            default:
                return <InfoUser data={userData && userData} t={t} uid={id} />;
        }
    };

    const sendHistoryToBigQuery = async (btn, action, userId) => {
        let dataToPost = {
            button: btn,
            location: window.location.pathname,
            by: auth?.id,
            role: Number(auth?.role[0]),
            action: action,
            who: userId,
        }
        const response = await axiosBQ.post(`${process.env.REACT_APP_BASE_URL_BQ}/action-logs/insert`, {
            // const response = await axios.post('https://bg.flow-corporation.space/history/action-logs/insert', {
            dataToPost,
        });
        console.log("Send History", dataToPost);

    }


    const updateOnboarder = async (stat) => {
        setIsOnboarderLoading(true)
        try {
            const responseOneUser = await axiosPrivate.get(`/api/users/genericFindOne/${userData.id}?limit=1`);
            console.log("responseOneUser---", responseOneUser);
            const decrypt = service.decryptOneUserInfo(responseOneUser.data.oneUser);
            dispacth(getUserDetails(decrypt));
            console.log("deccc---", decrypt);

            if (stat == "exist") {

                const dataToPut = {
                    onboarder: null
                }
                const data = await axiosPrivate.put(`/api/users/${userData.id}`, dataToPut);
                console.log("EXIST", "dataToPut", dataToPut, "updateOnboarder", data);
                dispacth(updateUser({ onboarder: null }));
                await sendHistoryToBigQuery("Fin du call", `REMOVE onboarder - current step ${decrypt?.onboardingStep} `, userData.id)
                // sendHistory={{ action: `REMOVE onboarder - current step ${userData?.onboardingStep} `, who: `${userData.id}` }}

            } else if (stat == "null") {
                const dataToPut = {
                    onboarder: auth.id
                }
                const data = await axiosPrivate.put(`/api/users/${userData.id}`, dataToPut);
                console.log("NULL", "dataToPut", dataToPut, "updateOnboarder", data);
                dispacth(updateUser({ onboarder: auth.id }));
                await sendHistoryToBigQuery("Début du call", `ADD onboarder - current step ${decrypt?.onboardingStep} `, userData.id)
                // sendHistory={{ action: `ADD onboarder - current step ${userData?.onboardingStep} `, who: `${userData.id}` }}

            }
            // setTimeout(() => {
            //     window.location.reload()
            // }, 5000);

            setIsOnboarderLoading(false)

        } catch (error) {
            console.error("updateOnboarder", updateOnboarder);
            setIsOnboarderLoading(false)

        }
    }

    const namesMap = {
        "8dc2a399-7e40-410e-8483-26c7d2a00d60": "Kim",
        "87e4efd1-376f-4741-8cc7-76b771b05b76": "Sitraka",
        "0e5c7589-ca34-4226-ae57-74bdf49789c2": "Mombris",
        "11e88e9f-7a99-4123-9323-4b59d39e755d": "Melanie",
        "14e022fc-3a6c-4257-8f30-8c1bfce6dbe8": "Annaelle",
        "3cc53c7c-7bf9-4bd8-8d95-c284bc10e762": "Damien",
        "ed3dd483-8cd6-4f8f-97f7-03cee5d3ae7f": "Treesi"
    };
    // console.log("Update user", userData?.onboarder);
    const suppName = namesMap[userData?.onboarder] || "Support"

    const testApiDev = async () => {
        setIsPlayLoading(true)
        try {
            const { data } = await axiosPrivate.get(`/api/admin/client-ok/${userData.id}`)
            console.log("TEST DEV", typeof data, data);
            const splitData = data.split(/(?=\{)/)
            const dataArray = splitData.map(d => JSON.parse(d))
            console.log("RO", dataArray);
        } catch (error) {
            console.error("testApiDev", error);
        }
        setIsPlayLoading(false)

    }

    function getMessageByKey(array, key) {
        const foundObject = array.find(obj => obj.key === key);
        return foundObject || null;  // Return the msg or null if not found
    }

    return (
        <>
            <TopBar menus={auth.role.includes(process.env.REACT_APP_ADMIN) ? sideMenus.menusAdmin(t)
                : auth.role.includes(process.env.REACT_APP_SUPPORT) ? sideMenus.menusSupport(t, todayCard.length)
                    : null
            } />
            <div className="flex flex-row">
                {auth.role.includes(process.env.REACT_APP_SUPPORT) ?
                    <Sidebar menus={auth.role.includes(process.env.REACT_APP_ADMIN) ? sideMenus.menusAdmin(t) : sideMenus.menusSupport(t, todayCard.length)} />
                    : null}


                <div className={`flex flex-col w-full p-4  ${auth.role.includes(process.env.REACT_APP_SUPPORT) ? "md:ml-52" : ""} mt-2 `}>
                    <div className="flex items-center mb-2">
                        <div className="flex gap-x-4">
                            <ChevronLeftIcon className="h-6 rounded-full dark:text-slate-100 hover:bg-slate-300 cursor-pointer" onClick={() => goBack()} />
                            <UserCircleIcon className="h-6 dark:text-slate-100" />
                        </div>
                        <div className="flex-1 font-semibold text-sm ml-1 text-slate-700 dark:text-slate-100">{userData.email} </div>


                        <div className="flex gap-x-2 text-xs font-semibold ">
                            {/* {userData.status == 18 &&
                                <DialogDemo
                                    trigger={<BtnShad text={t(`Prendre en main`)} mt size={"small"} />
                                    }
                                    title={"Prendre en main"}
                                    text={"Cette action permet de changer le status du client autonome en active, et cela permet au support de prendre en main la suite de l'onboarding. Le client pourrait entrer dans la zone membre après cette action"}
                                >
                                    <>
                                        {
                                            // userData.strategy == "normal" &&
                                            userData?.onboardingStep && userData?.onboardingStep > 3 ?
                                                <TypographyMuted>Le client peut se connecter avec le mot de passe qu'il a choisi lors de l'onboarding autonome</TypographyMuted> : <TypographyMuted>
                                                    Le client doit réinitialiser son mot de passe lors de la première connexion.</TypographyMuted>
                                        }
                                        <div className="flex justify-center w-full ">

                                            {secondsLeft > 0 ? <BtnShad text={`${t(`button.confirm`)} (${secondsLeft} s)`} onClick={handleSaveSwitch} /> :
                                                <BtnShad text={t(`button.continue`)} xsFont onClick={() => start(30)} variant="tertiary" />

                                            }
                                        </div>
                                    </>

                                </DialogDemo>
                            } */}
                            {userData?.onboarder ?
                                <div className="flex items-center px-4 gap-x-2">
                                    <TypographyS>Début call par {suppName}</TypographyS>
                                    <BtnShad text="Fin du call" className="" size="small" onClick={() => updateOnboarder("exist")}
                                        isLoading={isOnboarderLoading}
                                        variant="warning"
                                    // sendHistory={{ action: `REMOVE onboarder - current step ${userData?.onboardingStep} `, who: `${userData.id}` }}
                                    />
                                </div>
                                :
                                <BtnShad text="Début du call" className="" size="small" onClick={() => updateOnboarder("null")}
                                    isLoading={isOnboarderLoading}
                                // sendHistory={{ action: `ADD onboarder - current step ${userData?.onboardingStep} `, who: `${userData.id}` }}
                                />
                            }


                            {userData.email ? (
                                userData.length != 0 && (userData.status === 2 || userData.status == 6 || userData.status == 12 || userData.status === 9 || userData.status === 10) ? null : (
                                    <>
                                        {/* <Controls.Button
                                            disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                            xsFont
                                            small
                                            color={"bg-red-300 hover:bg-red-400"}
                                            text={"Motif si not OK"}
                                            onClick={() => setIsOpen(true)}
                                        /> */}

                                        <BtnShad
                                            disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                            variant="destructive"
                                            text={"Motif si not OK"}
                                            size="small"
                                            onClick={() => setIsOpen(true)}
                                        />
                                    </>

                                )
                            ) : // <Controls.Button xsFont small color={'bg-green-300 hover:bg-green-400'} text={'Client OK'} onClick={handleCheckBalance} />
                                null}
                        </div>
                    </div>

                    {/* <span className='text-xs '>{userData.fistName} {userData.lastName} {userData?.details?.startSubscription} <span> {t('label.referalCode')}</span> {userData?.code}</span> */}
                    <span className="flex flex-col space-y-2 text-xs font-semibold">
                        <div className="flex space-x-1 ">

                            {/* <span className='font-medium'> {t('label.referalCode')}: </span>{userData?.code} */}
                            <Badge variant="secondary">{userData?.type}</Badge>
                            <Badge variant={"default"}>{userData?.status && t(`uStatus.${userData?.status}`)}</Badge>

                            {userData.platform == 1 ?
                                <Badge variant="binance">Binance</Badge>
                                :
                                userData.platform == 2 ?
                                    <Badge variant="kraken">Kraken</Badge>
                                    : null
                            }
                            {userData.strategy == "ai" && <Badge variant="ai">{t(`strategy.ai`)}</Badge>}
                        </div>

                        {/* <BtnShad text="Support" size="small" /> */}
                        {userData.parent ? (
                            <div>
                                <span className=" p-1 rounded-lg font-medium ml-2 dark:text-slate-100">Parrainé par : </span>
                                <Badge variant="warning">
                                    {userData.parent ? `${service.decryptCBData(userData.parent?.firstName)} ${service.decryptCBData(userData.parent?.lastName)}` : null}{" "}
                                </Badge>

                            </div>
                        ) : null}

                    </span>
                    {userData.attempt >= 5 ?

                        <div className="flex items-center space-x-2">
                            <div className="flex-1">
                                <BannerInfo
                                    text={
                                        "!! Ce compte a été bloqué en raison de plusieures tentatives de connexion avec un mot de passe incorrect .  "
                                    }
                                    type="danger"
                                />
                            </div>
                            <div>   <Controls.Button
                                isLoading={isLoading}
                                disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                xsFont
                                // small
                                color={"bg-red-500 text-white hover:bg-red-600"}
                                text={"Débloquer user"}
                                onClick={() => handleUnblockUser()}
                            /></div>
                        </div>
                        : null}
                    {userData.length != 0 ? (
                        <div className="flex flex-col w-full px-2 sm:px-0 mt-2">
                            <Tab.Group>
                                {/* <Tab.List className="flex space-x-1 rounded bg-blue-900/20 p-1 max-w-md"> */}
                                <Tab.List className="flex space-x-1 rounded bg-blue-900/20 min-w-md">
                                    {userData.strategy == "ai" ?
                                        userData.status == 18 ?
                                            categoriesAIAutonome.map((category) => (
                                                <Tab
                                                    key={category}
                                                    className={({ selected }) =>
                                                        classNames(
                                                            "w-full rounded py-2 text-sm font-medium leading-5  text-primary dark:text-slate-100 focus:outline-none transition-colors ",
                                                            selectedTab === category ? "bg-white dark:bg-secondary shadow-sm  border-b-2 border-b-secondary " : " hover:bg-white/[0.12] hover:text-white "
                                                        )
                                                    }
                                                    onClick={() => handleTabClick(category)}

                                                >
                                                    {category}
                                                </Tab>
                                            ))
                                            :
                                            categoriesAI.map((category) => (
                                                <Tab
                                                    key={category}
                                                    className={({ selected }) =>
                                                        classNames(
                                                            "w-full rounded py-2 text-sm font-medium leading-5  text-primary dark:text-slate-100 focus:outline-none transition-colors ",
                                                            selectedTab === category ? "bg-white dark:bg-secondary shadow-sm  border-b-2 border-b-secondary " : " hover:bg-white/[0.12] hover:text-white "
                                                        )
                                                    }
                                                    onClick={() => handleTabClick(category)}

                                                >
                                                    {category}
                                                </Tab>
                                            ))
                                        :

                                        userData.status == 18 ?
                                            categoriesAutonome.map((category) => (
                                                <Tab
                                                    key={category}
                                                    className={({ selected }) =>
                                                        classNames(
                                                            "w-full rounded py-2 text-sm font-medium leading-5  text-primary dark:text-slate-100 focus:outline-none transition-colors ",
                                                            selectedTab === category ? "bg-white dark:bg-secondary shadow-sm  border-b-2 border-b-secondary " : " hover:bg-white/[0.12] hover:text-white "
                                                        )
                                                    }
                                                    onClick={() => handleTabClick(category)}

                                                >
                                                    {category}
                                                </Tab>
                                            ))
                                            :

                                            categories.map((category) => (
                                                <Tab
                                                    key={category}
                                                    className={({ selected }) =>
                                                        classNames(
                                                            "w-full rounded py-2 text-sm font-medium leading-5  text-primary dark:text-slate-100 focus:outline-none transition-colors ",
                                                            selectedTab === category ? "bg-white dark:bg-secondary shadow-sm  border-b-2 border-b-secondary " : " hover:bg-white/[0.12] hover:text-white "
                                                        )
                                                    }
                                                    onClick={() => handleTabClick(category)}

                                                >
                                                    {category}
                                                </Tab>
                                            ))
                                    }
                                </Tab.List>
                                <Tab.Panels className="h-full shadow py-2 bg-slate-100  dark:bg-slate-900 " onClick={text => console.log("text", text)}>
                                    {renderTabContent()}
                                </Tab.Panels>
                            </Tab.Group>

                            <div className="mt-5 flex justify-between">
                                <Controls.Button
                                    disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                    xsFont
                                    small
                                    color={"bg-red-300 hover:bg-red-400"}
                                    text={"Demande de réinitialisation MDP"}
                                    onClick={() => handleResetPassword(userData.email)}
                                />
                                {/* <BtnShad
                                    text={"test"}
                                    isLoading={isPlayLoading}
                                    onClick={testApiDev}
                                /> */}
                                {userData.email ? (
                                    userData.length != 0 && (userData.status === 2 || userData.status == 6 || userData.status == 12 || userData.status === 9 || userData.status === 10 || userData.status === 18) ? null : (
                                        <Controls.Button
                                            disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                            xsFont
                                            isLoading={isUserOkLoading}
                                            small
                                            color={"bg-green-300 hover:bg-green-400"}
                                            text={"Client OK"}
                                            onClick={handleCheckBalance}
                                        />
                                    )
                                ) : null}
                            </div>

                            {/*  dialog box for not ok client */}
                            <DialogBox isOpen={isOpen} setIsOpen={setIsOpen}>
                                {/* {userData.status != 1 ? ( */}
                                <div className="mt-6 flex flex-col max-w-md w-md gap-5">
                                    <Controls.Select
                                        name="type_id"
                                        label={"Motif"}
                                        value={motifNotOk || ""}
                                        onChange={(e) => setMotifNotOk(e.target.value)}
                                        options={ticketService.getTicketErrorCollection()}
                                        error={errorInputnotOk}
                                    />
                                    {motifNotOk === "other" ? <Controls.Input label="Autre Motif" value={otherMotifNotOk} onChange={(e) => setOtherMotifNotOk(e.target.value)} error={errorOther} /> : null}
                                    {/* <Controls.Button
                                        disabled={auth.role.includes(process.env.REACT_APP_ADMIN || auth.role.includes(process.env.REACT_APP_SUPADMIN))? true : false}
                                        xsFont
                                        small
                                        color={"bg-red-300 hover:bg-red-400"}
                                        text={"Not OK "}
                                        onClick={() => handleNotOk(userData.id)}
                                    /> */}
                                    <BtnShad text={"Not OK"}
                                        onClick={() => handleNotOk(userData.id)}
                                        disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                        sendHistory={{ action: `Not OK - motif : ${motifNotOk}`, who: `${userData.id}` }}
                                    />
                                </div>
                                {/* ) : null} */}
                            </DialogBox>

                            {/*  dialog box for user ok  */}
                            <DialogBox isOpen={isUserOkOpen} setIsOpen={setIsUserOkOpen} customClassName={"w-2/3 py-2"}>
                                <div className="flex justify-center items-center  space-x-2">
                                    <div className="font-bold ">{userData.email}</div>
                                    <div className="flex space-x-4">
                                        <div className="flex space-x-2 items-center ">
                                            <div>{balance?.usdt || 0}</div>
                                            <Icon i="usdt" size={20} />
                                        </div>
                                        <div className="flex space-x-2 items-center ">
                                            <div>{balance?.eur || 0}</div>
                                            <Icon i="eur" size={20} />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-x-2  mt-2">
                                    {respTest.length == 0 ? <Loading /> :
                                        <>

                                            <h6 className="flex-none font-semibold text-sm ">Crypto-bulot-W</h6>
                                            {getMessageByKey(respTest, "W")?.status == 200 ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />}
                                            {/* {testApiW.status ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />
                                            } */}
                                        </>
                                    }
                                </div>
                                {/* <div className="text-xs text-red-500">{testApiW.error}</div> */}

                                <div className="flex gap-x-2  mt-2">
                                    {respTest?.length == 0 ? <Loading /> :
                                        <>
                                            <h6 className="flex-none font-semibold text-sm">Crypto-bulot-T</h6>
                                            {getMessageByKey(respTest, "T")?.status == 200 ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />}

                                            {/* {testApiT.status ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />
                                            } */}
                                        </>
                                    }
                                    {/* {testApiT ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />} */}
                                </div>
                                {/* {testApiT.error && <div className="text-xs text-red-500">{testApiT.error}</div>} */}

                                {respTest?.length == 0 ? null :

                                    balance?.usdt > 500 && getMessageByKey(respTest, "T")?.status == 200 ?
                                        <>
                                            <div className="flex space-x-4 justify-center items-center my-2">
                                                <BtnShad text={"Confirmer Client OK "}
                                                    onClick={handleUpdateUserStatus}
                                                    isLoading={isUpdateStatusLoading}
                                                    disabled={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                                    sendHistory={{ action: `Client OK - ${balance?.usdt || 0} USDT `, who: `${id}` }}
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="flex justify-center ">

                                                <div className=" text-center mt-4 font-bold text-red-600 px-2 w-fit ">!! Ce client ne rempli pas les conditions nécéssaires au rééquilibrage</div>
                                            </div>
                                            {/* <div>Veuillez choisir le cas du client</div> */}
                                            <div className="flex space-x-4 justify-center items-center mt-4">

                                                <Controls.Button
                                                    disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                                    isLoading={isUpdateStatusLoading}
                                                    // xsFont
                                                    // small
                                                    // color={" bg-blue-500 hover:bg-blue-400"}
                                                    // onlyBorder
                                                    text={<div>
                                                        Le client est à basculer, il faut faire son tranfert (Binance {"=>"} Kraken)
                                                    </div>}
                                                    onClick={handleUpdateUserTransferStatus}
                                                />

                                                <Controls.Button
                                                    disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                                    // xsFont
                                                    // small
                                                    color={"bg-red-500 text-white"}
                                                    text={<div>
                                                        Annuler le "Client OK"
                                                    </div>}
                                                    onClick={() => setIsUserOkOpen(false)}
                                                />
                                            </div>
                                        </>
                                }
                            </DialogBox>

                        </div>
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>
        </>
    );
}

export default OneUser;
