import React, { useState } from "react";
import { useFOrm, Form } from "../useFOrm";
import Controls from "../controls/Controls";
import { Grid } from "@mui/material";
import { login } from "../../features/authSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosGlobal from "../../axios";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import * as services from "../../utils/service.js";
import TwoFAScreen from "../../screens/TwoFAScreen";
const initialFvalues = {
    email: "",
    password: "",
};

function LoginForm(props) {
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const cookies = new Cookies();
    const { t, attempt, setAttempt } = props;
    const dispacth = useDispatch();
    const [hasTfa, setHasTfa] = useState(false);
    const [userDataLogin, setUserDataLogin] = useState();
    const [tempToken, setTempToken] = useState();
    const [isFirstLogin, setIsFirstLogin] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        // if ("email" in fieldValues) temp.email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fieldValues.email) ? "" : t("error.invalidEmail");
        if ("email" in fieldValues) temp.email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(fieldValues.email) ? "" : t("error.invalidEmail");
        //temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : t("error.invalidEmail")
        if ("password" in fieldValues) temp.password = fieldValues.password ? "" : t("error.required");
        setErrors({
            ...temp,
        });

        if (fieldValues == values) return Object.values(temp).every((x) => x == "");
    };

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    const formLogin = [
        {
            type: "email",
            name: "email",
            label: t("label.email"),
            placeholder: "Enter your email",
            password: false,
            required: true,
        },
        {
            type: "password",
            name: "password",
            label: t("label.password"),
            placeholder: "******",
            password: true,
            required: true,
        },
    ];

    const formLoginCheckEmail = [
        {
            type: "email",
            name: "email",
            label: t("label.email"),
            placeholder: "Enter your email",
            password: false,
            required: true,
        },
    ];

    let navigate = useNavigate();
    const handleSubmit = async (e) => {
        console.log("ato");
        e.preventDefault();
        if (validate()) {
            setIsLoading(true);
            const data = {
                email: values.email,
                password: values.password,
            };
            try {
                const linkUser = `https://app.capstellar.com`
                const linkSupp = `https://intern.capstellar.com`

                const response = await axiosGlobal.post("/api/users/login", data);
                if (response) {
                    const dataLogin = {
                        token: services.encryptCB(response.data.token),
                        role: response.data.role,
                        email: response.data.email,
                        name: response.data.name,
                        id: response.data.userId,
                        isFirstLogin: response.data.isFirstLogin,
                        strategy: response.data.strategy
                    };

                    const cryptToken = services.encryptCB(response.data.token);
                    //localStorage.setItem("accesstoken", response.data.token);
                    setUserDataLogin(dataLogin);
                    if (response.data.tfa) {
                        setHasTfa(response.data.tfa);
                        setTempToken(cryptToken);
                    } else {
                        if (!dataLogin.isFirstLogin) {
                            dispacth(login(dataLogin));
                            const rolesArray = dataLogin.role.split(",");

                            switch (true) {
                                case rolesArray.length == 1 && rolesArray.includes(process.env.REACT_APP_PARTENAIRE):
                                    localStorage.setItem("token", cryptToken);
                                    // navigate("/affiliate");
                                    window.open(linkUser)

                                    break;
                                case rolesArray.includes(process.env.REACT_APP_SUPADMIN):
                                    localStorage.setItem("token", cryptToken);
                                    navigate("/hello");
                                    break;
                                case rolesArray.includes(process.env.REACT_APP_SUPPORT) && dataLogin?.id == "87e4efd1-376f-4741-8cc7-76b771b05b76":
                                    localStorage.setItem("token", cryptToken);
                                    navigate("/bg");
                                    break;
                                case rolesArray.includes(process.env.REACT_APP_ADMIN):
                                    localStorage.setItem("token", cryptToken);
                                    navigate("/admin/dashboard");
                                    break;
                                case rolesArray.includes(process.env.REACT_APP_CLIENT):
                                    localStorage.setItem("token", cryptToken);
                                    window.open(linkUser)
                                    break;
                                case rolesArray.includes(process.env.REACT_APP_SUPPORT):
                                    window.open(linkSupp)

                                    break;
                                case rolesArray.includes(process.env.REACT_APP_SUPPORT):
                                    window.open(linkSupp)
                                    break;
                                default:
                                    navigate(from, { replace: true });
                                    break;
                            }
                            /*if (rolesArray.includes("1")) {
                            navigate("/admin/dashboard");
                          } else if (rolesArray.includes("3")) {
                            navigate("/support/dashboard");
                          } else if (rolesArray.includes("2")) {
                            navigate(`/dashboard`);
                          } else if (rolesArray.includes("0")) {
                            navigate("/admin/dashboard");
                          } else {
                            navigate(from, { replace: true });
                          }*/
                            //navigate(from, { replace: true });
                        } else {
                            navigate("/reset", { state: { isFirstLogin: dataLogin.isFirstLogin } });
                        }
                    }

                    //cookies.set('refreshToken', response.data.refreshToken, { path: '/' });
                    setIsLoading(false);
                }
            } catch (error) {

                console.log("err", error)
                if (error.response.status == 403) {
                    toast.error(
                        t(`error.banned`), {
                        autoClose: 5000,
                    });
                } else {
                    toast.error(
                        error.response.data.msg, {
                        autoClose: 5000,
                    });
                }
                // toast.error("Email ou Mot de passe incorrect", {
                //   autoClose: 5000,
                // });
                console.log("err", error.response.data);
                console.log("err", error.response.data?.attempt);
                setAttempt((prev) => error.response.data?.attempt);
                setIsLoading(false);
            }
        } else {
            //setAlert("Veuillez remplir les champs vides", 'danger');
        }
    };

    const checkFirstLogin = async (e) => {
        try {
            setIsLoading(true);
            if (!values.email) throw "Error";
            try {
                const data = {
                    email: values.email,
                };
                const response = await axiosGlobal.post("/api/users/check", data);
                // console.log(response.data)
                setIsFirstLogin(response.data.isFirstLogin);
                if (response.data.isFirstLogin) navigate("/reset", { state: { isFirstLogin: response.data.isFirstLogin } });
                setIsLoading(false);
            } catch (error) {
                //toast.error('Impossible de trouver votre compte');
                setErrors({ email: t("error.notFound") });
                setIsLoading(false);
            }
        } catch (error) {
            setErrors({ email: t("error.invalidEmail") });
            setIsLoading(false);
        }
    };
    let attempt5 = attempt >= 5;
    return (
        <>

            {!hasTfa && (
                <Form onSubmit={handleSubmit} noValidate>
                    {isFirstLogin ? (
                        <div>

                            <Grid container spacing={2}>
                                {formLogin.map((item, i) => {
                                    return (
                                        <Grid item xs={12} key={i}>
                                            <Controls.Input
                                                readOnly={attempt5 ? true : false}
                                                fullWidth={true}
                                                label={item.label}
                                                value={values[item.name]}
                                                onChange={handleInputChange}
                                                name={item.name}
                                                type={item.type}
                                                error={errors[item.name]}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>

                            <div style={{ marginTop: "20px" }}>
                                {/* <Controls.Button type="submit" text={t('button.login')} fullWidth /> */}
                                <Controls.Button disabled={attempt5 ? true : false} type="submit" text={t("button.login")} widthFull isLoading={isLoading} />
                            </div>
                        </div>
                    ) : null}
                    {/* {isFirstLogin ? (
            <div className="space-y-2">
              {formLoginCheckEmail.map((item, i) => {
                return (
                  <Grid item xs={12} key={i}>
                    <Controls.Input fullWidth={true} label={item.label} value={values[item.name]} onChange={handleInputChange} name={item.name} type={item.type} error={errors[item.name]} />
                  </Grid>
                );
              })}
              <Controls.Button type="button" onClick={checkFirstLogin} text={t("button.continue")} widthFull isLoading={isLoading} />
            </div>
          ) : null} */}

                    {!isFirstLogin && (
                        <div>
                            <Grid container spacing={2}>
                                {formLogin.map((item, i) => {
                                    return (
                                        <Grid item xs={12} key={i}>
                                            <Controls.Input fullWidth={true} label={item.label} value={values[item.name]} onChange={handleInputChange} name={item.name} type={item.type} error={errors[item.name]} />
                                        </Grid>
                                    );
                                })}
                            </Grid>

                            <div style={{ marginTop: "20px" }}>
                                {/* <Controls.Button type="submit" text={t('button.login')} fullWidth /> */}
                                <Controls.Button disabled={attempt5 ? true : false} type="submit" text={t("button.login")} widthFull isLoading={isLoading} />
                            </div>
                        </div>
                    )}
                    {/* <div>a{attempt}</div> */}
                </Form>
            )}
            {hasTfa && <TwoFAScreen token={tempToken} t={t} dataLogin={userDataLogin} />}
        </>
    );
}

export default LoginForm;
