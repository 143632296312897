import React, { useState } from 'react'

const IconHoverText = (props) => {
    const { icon, text, classIcon, onClick, textClass } = props
    const [isHover, setIsHover] = useState(false)

    const handleMouseEnter = () => {
        setIsHover(true)
    }

    const handleMouseLeave = () => {
        setIsHover(false)
    }

    return (
        <div className={`relative ${isHover? "z-10" :""}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onClick}>
            <div className={`w-5 h-5 ${classIcon ? classIcon :""}`}>
                {icon}
            </div>
            {isHover && <div className={`${textClass ? textClass : "max-w-32 bottom-3 left-4 " }absolute rounded-md px-1 bg-black/75 text-white text-xs`}>{text}</div>}
        </div>
    )
}

export default IconHoverText
