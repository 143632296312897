import React, { useState, useEffect } from "react";
import { ArrowNarrowLeftIcon, ExclamationCircleIcon, ExclamationIcon } from "@heroicons/react/solid";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import * as sideMenus from "../utils/sideMenus";
import { useSelector } from "react-redux";
import { authSelector } from "../features/authSlice";
import Tuto from "../components/tuto/Tuto";
import UserSecurisationInfo from "../components/UserSecurisationInfo";
import { ReactComponent as HeritageFreepik } from '../asset/heritage.svg'
import FileResource from "../components/FileResource";
import Title from "../components/title/Title";
import { DocumentDuplicateIcon, DocumentTextIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import Faq from "../components/Faq";
import AccordionCustom from "../components/AccordionCustom";
import Controls from "../components/controls/Controls";
import Tarif from "../components/Tarif";

const resources = [
    {
        id: 1,
        url: "https://drive.google.com/file/d/1xvNuFr6pL6OBWbg-OJJ1LYl-Q3VHrTDm/view?usp=sharing",
        title: "Whitelister une adresse de retrait Binance - Web",
        platform: 1
    },
    {
        id: 2,
        url: "https://drive.google.com/file/d/1UBWV_xK_ZG4MnCZuAf1ZHhzNds9oJoah/view?usp=drive_link",
        title: "Trouver l'identifiant du compte Binance - Web",
        platform: 1
    },
    {
        id: 3,
        url: "https://drive.google.com/file/d/1pByXCTabLvqeb0OqstKkiL-jwTTp6PcK/view?usp=drive_link",
        title: "Créer une adresse de retrait Binance - Web",
        platform: 1
    },
    {
        id: 4,
        url: "https://drive.google.com/file/d/1vvZqWvfqdc5QtXwn1XJhHjGpCmx0p9lt/view?usp=drive_link",
        title: "Whitelister une adresse de retrait Binance - Mobile",
        platform: 1
    },
    {
        id: 5,
        url: "https://drive.google.com/file/d/11gYbo3NGisgHbpeLe8lxALcVSmmGDPxO/view?usp=drive_link",
        title: "Trouver l'identifiant du compte Binance - Mobile",
        platform: 1
    },
    {
        id: 6,
        url: "https://drive.google.com/file/d/1gbPFJyjI73B4xez7cH_x7hUJoDSCoG-_/view?usp=drive_link",
        title: "Créer une adresse de retrait Binance - Mobile",
        platform: 1
    },
    {
        id: 7,
        url: "https://drive.google.com/file/d/1ZatI_r_nkVBLTZi44UERFbt2-wD0bP2y/view?usp=drive_link",
        title: "Whitelister une adresse de retrait Kraken - Web",
        platform: 2
    },
    {
        id: 8,
        url: "https://drive.google.com/file/d/1rJJMeHkRScFElfeQszJ6T1dtqJxSXbOV/view?usp=drive_link",
        title: "Trouver l'identifiant du compte Kraken - Web",
        platform: 2
    },
    {
        id: 9,
        url: "https://drive.google.com/file/d/1BXeJ_XmGn70X6e3yQXoMId-cYPsygBqd/view?usp=drive_link",
        title: "Créer une adresse de retrait Kraken - Web",
        platform: 2
    },
    {
        id: 10,
        url: "https://drive.google.com/file/d/1ij1jsIPZ2E5ydkd7wjLXJVdyAAtAB-S0/view?usp=drive_link",
        title: "Trouver l'identifiant du compte Kraken - Mobile",
        platform: 2
    },
    {
        id: 11,
        url: "https://drive.google.com/file/d/1BY5Pa3PhelKaiGfatb5Ne6o79jzo6tvv/view?usp=drive_link",
        title: "Créer une adresse de retrait Kraken - Mobile",
        platform: 2
    },

]

const tarifPrevoyance = [
    {
        id: 1,
        title: "Autonome",
        price: "Offert",
        url: "noUrl",
        contenu: [
            { id: 1, label: "Modèle de testament + Annexe codes + Process" }
        ]
    },
    {
        id: 2,
        title: "Personnelle",
        price: "197€",
        url: "https://bdc.crypto-bulot.com/formulaire-prevoyance-perso/",
        urlTitle: " Prévoyance Personnelle",
        contenu: [
            { id: 1, label: "Modèle de testament + Annexe codes + Process" },
            { id: 2, label: "Accompagnement dans la mise en place du testament + Annexe + Process" },
            { id: 3, label: "Archivage du testament signé" },
            { id: 4, label: "Enregistrement de la liste des proches + testamentaire" },
            { id: 5, label: "Suivi d'inactivité" },
            { id: 6, label: "Contact par sms/ te/ Whatsapp des proches + testamentaire" },
            { id: 7, label: "Assistance du testamentaire dans la récupération des fonds" },
        ]
    },
    {
        id: 3,
        title: "Totale",
        price: "297€",
        url: "https://bdc.crypto-bulot.com/formulaire-prevoyance-totale/",
        urlTitle: "Prévoyance Totale",
        contenu: [
            { id: 1, label: "Modèle de testament + Annexe codes + Process" },
            { id: 2, label: "Accompagnement dans la mise en place du testament + Annexe + Process" },
            { id: 3, label: "Archivage du testament signé" },
            { id: 4, label: "Enregistrement de la liste des proches + testamentaire" },
            { id: 5, label: "Suivi d'inactivité" },
            { id: 6, label: "Contact par sms/ te/ Whatsapp des proches + testamentaire" },
            { id: 7, label: "Assistance du testamentaire dans la récupération des fonds" },
            { id: 8, label: "Contact et Relance des proches + testametaire au-delà des 15 mois*", },
            { id: 9, label: "Responsabilité de l'exécution testamentaire spéciale" },
            { id: 10, label: "Transfert des fonds" },
        ]
    }
]


function HeritageScreen(props) {

    const TarifPrev = () => (
        <div className="flex flex-row justify-center gap-2">
            {tarifPrevoyance?.map((c) => (
                <Tarif title={c?.title} t={t} price={c?.price} contenu={c?.contenu} url={c?.url} urlTitle={c?.urlTitle} />
            ))}
        </div>
    )

    const { t } = props;
    const { id } = useParams(); // maka id
    const auth = useSelector(authSelector);
    const [isAutonome, setIsAutonome] = useState(true);
    const [isPersonnel, setIsPersonel] = useState(true);
    const [isTotale, setIsTotale] = useState(true);
    console.log(isAutonome, isPersonnel, isTotale);
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const faq = [
        {
            quest: t(`faq.prevoyanceOffer`),
            rep: <div>
                <img src={`https://media.cryptobulot.net/price_heritage.jpeg`} alt="offre de prévoyance" className="" />
                {/* <div className="w-full ">
                    <TarifPrev />
                </div> */}
                <div className="flex space-x-2 justify-center p-2 ">
                    <a href="https://bdc.crypto-bulot.com/formulaire-prevoyance-perso/" target="_blank">
                        <div className="bg-black text-white rounded-lg text-center text-base py-2 w-fit min-w-[24rem] hover:bg-slate-700">
                            Prévoyance Personnelle
                        </div>
                    </a>
                    <a href="https://bdc.crypto-bulot.com/formulaire-prevoyance-totale/" target="_blank">
                        <div className="bg-black text-white text-center rounded-lg px-20 text-base py-2 w-fit min-w-[24rem] hover:bg-slate-700">
                            Prévoyance Totale
                        </div>
                    </a>
                </div>

            </div>
        },
        {
            quest: t(`faq.preRequis`),
            rep: <ul>
                <li> • {t(`faq.civilStatus`)} </li>
                <li> • {t(`faq.numberAccountWhitelist`)} </li>
                <li> • {t(`faq.contactList`)} </li>
                <li> • {t(`faq.accountNumberToWhitelist`)} </li>
                <li> • {t(`faq.maxHoldingPeriod`)} </li>
                <li> • {t(`faq.beneficiaryChoice`)} </li>
                <li> • {t(`faq.orderCB`)} </li>
            </ul>
        },
        {
            quest: t(`faq.whyWait13monts`),
            rep: t(`faq.responseOf13monts`)
        },
        {
            quest: t(`faq.wait9years`),
            rep: t(`faq.responeof9years`),
        },
        {
            quest: t(`faq.steps`),
            rep: <ol>
                <li>
                    1 - {t(`faq.specialExecutor`)}
                    <br /> {t(`faq.specialTestament`)}
                </li>
                <li>
                    2 - {t(`faq.testamentCrypto`)}.
                </li>
                <li>
                    3 - {t(`faq.sendCopyOfTestament`)}.
                </li>
                <li>
                    4 - {t(`faq.writeAnnexDoc`)}
                </li>
                <li>
                    5 -{t(`faq.secureDoc`)}
                </li>
                <li>
                    6 - {t(`faq.informProche`)}
                </li>
            </ol>
        },


    ]

    return (
        <>
            <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />
            <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />
            <div className="p-4 md:ml-52">
                <div className="flex space-x-4 items-center ">
                    <div className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.prevoyance`)}</div>
                </div>
                <div className="flex flex-col space-y-3 justify-center items-center mt-4 ">


                    {/* informations */}
                    <div className="text-justify text-xs rounded-md border border-blue-600/25 bg-white p-4 ">
                        <div className="flex w-full space-x-1 justify-center items-center">
                            <ExclamationIcon className="w-5 text-red-500" />
                            <div className="text-blue-600 font-bold  ">DISCLAIMER</div>
                        </div>
                        <br />
                        {/* <div className="font-semibold text-center mb-2">Clause de non-responsabilité</div> */}
                        {/* Veuillez noter que ce modèle de testament concernant la gestion des actifs en crypto-monnaies est fourni à titre gracieux par Flow Corporation. Ce document est proposé en tant que courtoisie et ne doit pas être interprété comme un conseil juridique. Flow Corporation ne garantit pas la conformité de ce document avec les lois spécifiques ou la juridiction de chaque utilisateur. Il est de la responsabilité de l'utilisateur de ce modèle de s'assurer que son testament est conforme aux lois de la juridiction dans laquelle il réside ou dont il relève. En conséquence, Flow Corporation décline expressément toute responsabilité pour tout préjudice ou perte découlant de l'utilisation de ce modèle ou en relation avec celui-ci. Nous recommandons fortement à chaque utilisateur de consulter un avocat ou son notaire pour obtenir des conseils juridiques personnalisés afin de préparer un testament valide et exécutoire selon les lois applicables à sa situation personnelle. */}
                        {t(`text.disclaimerPrevoyance`)}
                        <br /> <br />
                        {/* <strong >  Pré-requis : </strong>
                        <ul>
                            <li> • Etat civil + adresse du testamentaire général </li>
                            <li> • N° de compte du testamentaire général white listé sur le compte du membre </li>
                            <li> • Liste des proches à contacter lors des notifications</li>
                            <li>• N° de compte des proches à whitelister sur le compte du membre en cas d’incapacité du testamentaire principal</li>
                            <li>• Durée de détention maximum : au-delà de laquelle transmettre à une fondation ou la caisse des dépôts (propre à chaque pays : pour la France c’est 10 ans max d’inactivité)</li>
                            <li>• Choix du bénéficiaire au-delà de la durée maximale de détention ? fondation ou caisse des dépôts ?</li>
                            <li>• Avoir commandé l’offre Crypto Bulot Prévoyance</li>
                        </ul> */}
                    </div>

                    {/* images */}
                    {/* <div className="w-full px-20 bg-white p-2">
                        <img src={`https://media.cryptobulot.net//price_heritage`} alt="image" className="" />
                    </div> */}


                    {/* Docs */}
                    {isAutonome ? <div className="bg-white p-2 w-full">
                        <div className=" text-center text-sm ">
                            {/* Vous trouverez ci-dessous les documents relatifs à l'héritage qui sont prêts à être téléchargés et remplis. */}
                            <Title.SectionTitle
                                text={t(`label.docToDownload`)}
                                icon={<DocumentDuplicateIcon className="h-5 w-5 text-blue-700 text-xs" />}
                            />
                        </div>
                        <div className="flex flex-col lg:flex-row items-center justify-center  ">
                            <FileResource title={"Modèle Annexe d'accès à mon compte "} fit={"w-fit"} pdfLink={`https://media.cryptobulot.net/Modele_annexe.docx`} />
                            <FileResource title={"Modèle Testament V  Autonome "} fit={"w-fit"} pdfLink={`https://media.cryptobulot.net/Modele_autonome.docx`} />
                            {/* <FileResource title={"Modèle Testament V Personnelle "} fit={"w-fit"} pdfLink={`https://media.cryptobulot.net/Modele_perso.docx`} />
                            <FileResource title={"Modèle Testament V Totale "} fit={"w-fit"} pdfLink={`https://media.cryptobulot.net/Modele_total.docx`} /> */}
                        </div>
                    </div> : null}

                    {/* Mode operatoire */}
                    {isPersonnel ? <div className="bg-white p-2 w-full">
                        <Title.SectionTitle
                            text={t(`label.operatingProcedure`)}
                            // unit={`(${users && users.all.length})`}
                            icon={<DocumentTextIcon className="h-5 w-5 text-blue-700 text-xs" />}
                        />
                        <div className="my-4 ">
                            <div className="flex overflow-x-auto mt-4 border space-x-2">
                                {/* Row */}
                                {resources &&
                                    resources
                                        // .filter((f) => f.category == "Mode opératoire" && f.type == "PDF")
                                        .map((r, i) => (
                                            <div className="m-2 w-60 md:w-80 " key={i}>
                                                <FileResource
                                                    pdfLink={r.url}
                                                    title={r.title}
                                                    color={r.platform == 1 ? "border-2 border-yellow-500 hover:bg-yellow-500/25" : "border-2 border-purple-500 hover:bg-purple-500/25"}
                                                />
                                            </div>
                                        ))}
                            </div>
                        </div>
                    </div> : null}


                    {/* FAQ */}
                    {isTotale ?
                        <div className="bg-white p-2 w-full">
                            <div className="flex space-x-1 font-bold mb-2 text-slate-600">
                                <QuestionMarkCircleIcon className="w-5 text-blue-700" />
                                <div>FAQ </div>
                            </div>
                            {faq.map((f) => (
                                <AccordionCustom title={f.quest} children={f.rep} isFaq design={'text-sm font-semibold text-slate-600 bg-slate-50 dark:bg-slate-800'} designTitle={'text-blue-600 text-sm font-semibold flex items-center gap-x-3'} />
                            ))}
                        </div> : null}

                </div>

            </div>

        </>
    );
}

export default HeritageScreen;
