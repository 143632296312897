import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Flag from 'react-world-flags';
import { axiosPrivate } from '../axios';
import { authSelector, updateLang } from '../features/authSlice';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import * as service from "../utils/service";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './ui/dropdown-menu';
import BtnShad from './client/btnShadcn';
import { Button } from './ui/button';


const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const availableLanguages = i18n.options.whitelist || [];
    const currentLanguage = i18n.language;
    const dispatch = useDispatch()
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const auth = useSelector(authSelector);
    // console.log("auth", auth?.lang);
    const axiosPrivate = useAxiosPrivate()

    const getUser = async (uid) => {
        try {
            const { data } = await axiosPrivate.get(`/api/users/genericFindOne/${uid}`)
            const decryptOneUsers = JSON.parse(service.decryptCBData(data.oneUser));
            dispatch(updateLang(decryptOneUsers.lang))
            // console.log("data", data, "lang", decryptOneUsers.lang);

        } catch (error) {

        }
    }

    useEffect(() => {
        if (auth?.id) {
            getUser(auth?.id)
        }
    }, [auth])

    useEffect(() => {
        i18n.changeLanguage(auth?.lang)
    }, [auth])

    const handleChangeLanguage = async (lang) => {
        try {
            // console.log("ato");
            if (auth) {
                const response = await axiosPrivate.put(`/api/member`,
                    { lang: lang }
                )
                console.log("response", response);

                getUser(auth?.id)

            }
        } catch (error) {

        }
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                    <Flag className='h-[1.5rem] w-[1.5rem]' code={auth?.lang == "en" ? "GB" : auth?.lang?.toUpperCase()} />
                    <span className="sr-only">Toggle theme</span>
                </Button>

            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => handleChangeLanguage('en')}>
                    English
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleChangeLanguage('fr')}>
                    Français
                </DropdownMenuItem>

            </DropdownMenuContent>
        </DropdownMenu>
    )

    return (
        <div className='flex items-center space-x-2'>
            {/* {availableLanguages.map((lang) => {
                if (lang !== currentLanguage) {
                    return (
                        <button key={lang} onClick={() => changeLanguage(lang)}>
                            {lang}
                        </button>
                    );

                }
                return null;
            })} */}
            <div className='w-6'>
                <Flag code={auth?.lang == "en" ? "GB" : auth?.lang?.toUpperCase()} />
            </div>

            <select value={currentLanguage} onChange={(e) => handleChangeLanguage(e)}
                className="rounded-lg border border-blue-500/50 text-sm">

                {availableLanguages.map((lang) => (
                    <option key={lang} value={lang}>
                        {lang == "en" ? "English" : lang == "fr" ? "Français" : ""}

                    </option>
                ))}
            </select>

        </div>
    );

    return (
        <div>

            <button onClick={() => changeLanguage('fr')}>French</button>
            <button onClick={() => changeLanguage('en')}>English</button>
        </div>
    );
};

export default LanguageSwitcher;
