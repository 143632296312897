
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogClose
} from "../ui/dialog"

export function DialogDemo(props) {
    const { title, text, trigger, children, closeComponent, isControlled, isOpen, setIsOpen, classy } = props
    if (isControlled) {

        return (
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogTrigger asChild>
                    {trigger}
                </DialogTrigger>
                <DialogContent className={`max-w-full md:w-1/2 ${classy} `}>
                    <DialogHeader>
                        <DialogTitle className="">{title}</DialogTitle>
                        <DialogDescription> {text} </DialogDescription>
                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                        <div className="text-sm">
                            {children}
                        </div>

                    </div>
                    <DialogFooter>
                        {closeComponent && <DialogClose asChild>
                            {closeComponent}
                        </DialogClose>}


                    </DialogFooter>
                </DialogContent>
            </Dialog>
        )
    }
    return (
        <Dialog>
            <DialogTrigger asChild>
                {trigger}
            </DialogTrigger>
            <DialogContent className={`max-w-full md:w-1/2 ${classy}`}>
                <DialogHeader>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogDescription> {text} </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="">

                        {children}
                    </div>

                </div>
                <DialogFooter>
                    {closeComponent && <DialogClose asChild>
                        {closeComponent}
                    </DialogClose>}


                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
