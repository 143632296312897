import { AlertCircle } from "lucide-react"

import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "../../components/ui/alert"

export function AlertCustom(props) {
    const { title, text, variant } = props
    return (
        <Alert variant={variant}>
            <AlertCircle className="h-4 w-4" />
            <AlertDescription  className={"text-sm"}>{title}</AlertDescription>
            <AlertDescription>
                {text}
            </AlertDescription>
        </Alert>
    )
}
