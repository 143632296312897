import axios from 'axios';
import { axiosPrivate } from "../axios";
const setAuthToken = async (token) => {
    if (token) {
        console.log("TOKEN header", token);
        axiosPrivate.defaults.headers.common['x-auth-token'] = token;
    } else {
        delete axiosPrivate.defaults.headers.common['x-auth-token'];
    }
};

export default setAuthToken;
