import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import CustomAvatar from "../components/CustomAvatar";
import moment from "moment";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import Controls from "../components/controls/Controls";
import { toast } from "react-toastify";
import { decryptCBData } from "../utils/service";
const TicketsScreen = (props) => {
  const { t, uidSelected } = props;
  const axiosPrivate = useAxiosPrivate();
  const [users, setUsers] = useState();
  const [oneUser, setOneUser] = useState();
  const [userSelectedId, setUserSelectedId] = useState(uidSelected);
  const [ticketsSelected, setTicketSelected] = useState();
  const [tFID, setTFID] = useState();
  const [parentTicket, setParentTicket] = useState();
  const [hasRebal, sethasRebal] = useState();
  const [ticketsForSelectedUser, setTicketsForSelectedUser] = useState();
  const [tFollowUp, setTfollowUp] = useState();
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getAllUsers = async () => {
      try {
        const response = await axiosPrivate.get(`/api/users/hasTickets`, {
          signal: controller.signal,
        });
        console.log("sfsdfs", response.data);
        const formatData = response?.data.map((resp) => ({
          ...resp,
          firstName: decryptCBData(resp.firstName),
          lastName: decryptCBData(resp.lastName),
          email: decryptCBData(resp.email),
          tel: decryptCBData(resp.tel),
        }));
        isMounted && setUsers(formatData);
      } catch (error) {
        if (error.response.status != 403) {
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getAllUsers();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const getRebalancing = async (uidSelected) => {
    try {
      // make api request
      const res = await axiosPrivate.get(`/api/comparison/test/${uidSelected}`);
      // if true
      sethasRebal(res.data);
      console.log("REBAL", hasRebal);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectUser = async (uid) => {
    /* try {
             const response = await axiosPrivate.get(`/api/ticket/findBy/${uid}`);
             setUserSelectedId(uid)
             setTicketsForSelectedUser(response.data)
             selectCard(response.data.t[0].id)
         } catch (error) {
             console.log('error', error)
         }*/
    console.log("============gg===========", uid);
    const userFiter = users.filter((u) => u.id === uid);
    getRebalancing(uid);
    //console.log(userFiter[0].tickets)
    setOneUser(userFiter[0]);
    setUserSelectedId(uid);
    setTicketSelected(userFiter[0].tickets.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)));
    setParentTicket();
  };
  useEffect(() => {
    console.log("=======================", uidSelected);
    if (uidSelected) handleSelectUser(uidSelected);
  }, [uidSelected]);

  //custom card render
  const UserCard = ({ data }) => (
    <div
      className={`h-auto hover:cursor-pointer transition-all duration-100
         flex flex-col `}
      onClick={() => handleSelectUser(data.id)}
    >
      <div className="flex space-x-2 items-center p-1 px-4">
        <CustomAvatar name={`${data.firstName} ${data.lastName}`} />
        <div className="flex flex-col flex-1  truncate">
          <div className={`text-xs  ${userSelectedId === data.id ? "text-blue-500 font-semibold" : "font-regular"}`}>
            {" "}
            {data.firstName} {data.lastName}
          </div>
          <div className="text-xs font-light text-slate-500 "> {data && data.email} </div>
        </div>

        <div className="text-xs font-semibold text-slate-500"> {data && data.tickets.length} </div>
      </div>
    </div>
  );
  //${select === data.id ? 'border-blue-500 bg-blue-100' : 'border-transparent'}
  const CustomTicketCard = ({ data }) => (
    <div
      className={`block border-b hover:cursor-pointer`}
      onClick={() => {
        setTfollowUp(data.t);
        setTFID(data.id);
        setParentTicket(data);
      }}
    >
      <div className={`border-l-2 ${tFID === data.id ? "border-blue-500 bg-blue-100" : "border-transparent"} hover:bg-gray-100  p-2 space-y-3`}>
        <div className="flex flex-row items-center  space-x-2">
          <strong className="flex-grow text-sm truncate"> {data.mt?.motif} </strong>
        </div>
        <div className="flex">
          <div className="flex flex-col gap-y-2 flex-1">
            <span className="text-xs flex-1"> Créer le: {moment(data.createdAt).format("DD/MM/YY HH:MM:ss")}</span>
            <span className="text-xs flex-1"> Modifier le: {moment(data.updatedAt).format("DD/MM/YY HH:MM:ss")}</span>
          </div>

          {data.status ? <span className="text-xs text-red-500">Close</span> : <span className="text-xs text-green-500">Open</span>}
        </div>
      </div>
    </div>
  );

  const CustomChildrenTicket = ({ data }) => (
    <div className={`h-auto transition-all duration-100 rounded-sm flex flex-col gap-y-2 p-1 pt-2 bg-slate-100 shadow`}>
      <div className="flex text-[11px] gap-2 items-center">
        <span className="bg-teal-200 px-1 rounded-sm ">Ref: {moment(data.referenceDate).format("DD/MM/YYYY")}</span>
        <span className="bg-blue-300 px-1 rounded-sm">Enable: {moment(data.enableButtonDate).format("DD/MM/YYYY")}</span>
        {data.onClickDate && <span className="bg-green-300 px-1 rounded-sm ">Click: {moment(data.onClickDate).format("DD/MM/YYYY") || "-"}</span>}
        {data.late && <span className="bg-red-300 px-1 rounded-sm">late: {data.late} days</span>}
      </div>

      <div className="flex items-center space-x-2">
        <div className={`text-xs text-white  ${data.late != null && data.late > 0 ? "bg-yellow-500" : "bg-green-500"} rounded-full p-1`}>{data.title}</div>
        <div className="bg-blue-100 text-xs text-slate-800 rounded-lg p-2 min-w-20 w-fit h-full">
          {data.comment}
          {/* {data.referenceDate}
                {data.onClickDate}
                {data.status}
                {data.title}
                {data.type} */}
        </div>
      </div>

      <div className="text-xs text-slate-500 flex justify-between">
        <div>{data.late && <span> {data.tf?.motif ? <div className="text-black bg-yellow-300 rounded-sm px-1">{data.tf?.motif}</div> : null} </span>}</div>
        <div>{data.late && <span> {data.commentLate ? <div className="text-black bg-orange-300 rounded-sm px-1">{data.commentLate}</div> : null} </span>}</div>
        {data.by} {moment(data.updatedAt).format("DD/MM/YYYY HH:MM:ss")}
      </div>
    </div>
  );

  useEffect(() => {
    setTicketsForSelectedUser();
    setTfollowUp([]);
  }, [ticketsSelected]);

  const InfoCustom = ({ label, value }) => (
    <div className="flex space-x-1">
      <span className="font-light">{label}:</span>
      <span className="font-semibold truncate">{value}</span>
    </div>
  );

  const IsOk = () => <CheckCircleIcon className="w-5 h-5 text-green-500" />;
  const NotOk = () => <XCircleIcon className="w-5 h-5 text-red-500" />;

  const handelCloseTicket = async () => {
    try {
      //update status card to true
      const resp = await axiosPrivate.put(`/api/ticket/${tFID}`, { status: true });

      if (!resp) throw "Erroe occur try again later";
      toast.success("Ticket status is close");
      //console.log(tFID)
    } catch (error) {
      toast.error(error);
    }
  };

  const filtered = !search ? users : users.filter((person) => person.email.toLowerCase().includes(search.toLowerCase()));
  return (
    <>
      <div className="flex justify-center items-center md:hidden h-full w-full">
        <h3>Please use desktop to view this page</h3>
      </div>
      <div className="hidden md:flex flex-row grow ">
        <div className="w-64 bg-grey-200  flex flex-col">
          <div className=" flex-none h-auto mx-2 font-light">
            <div className="flex justify-between items-center">Client en erreur</div>

            <div>
              <input
                onChange={(e) => setSearch(e.target.value)}
                placeholder={"Search..."}
                type="text"
                id="last_name"
                className="text-xs bg-gray-50 border border-gray-300 text-gray-900 rounded-lg !focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>

          <div className="h-[89vh] flex-auto space-y-2 mt-0 overflow-y-auto scrollbar">{filtered && filtered.map((a, i) => <UserCard key={i} data={a} />)}</div>

          <div className="flex-none h-2  "></div>
        </div>

        <div className="flex flex-row flex-auto bg-white rounded-tl-xl border-l shadow-xl">
          <div className="w-64 flex flex-col ">
            <div className=" flex-none h-20 mx-2 border-b-[1px]">
              <div className="flex flex-col justify-between h-full p-2">
                <span className="font-semibold text-sm">Tickets</span>

                <div className="text-slate-800 font-semibold text-xs">
                  {" "}
                  nb:
                  {ticketsSelected && ticketsSelected.length}
                </div>
              </div>
            </div>
            <div className="h-[80vh] flex-auto space-y-2 mt-0 overflow-y-auto scrollbar">
              {ticketsSelected && ticketsSelected.length !== 0 ? (
                ticketsSelected.map((a, i) => <CustomTicketCard key={i} data={a} />)
              ) : (
                <div className="w-full h-full flex justify-center items-center font-light text-slate-700">aucun résultat</div>
              )}
            </div>
            <div className="flex-none h-2  "></div>
          </div>
          <div className="flex-1 border-l border-r border-gray-400 ">
            <div className={`flex-none mx-2 h-20  p-2  ${tFollowUp && tFollowUp.length !== 0 ? "block h-20  " : "hidden"} transition-all  duration-600`}>
              <div className="flex flex-col justify-between h-full">
                <div className="flex">
                  <span className="text-xs text-slate-500 flex-1">Ref: {parentTicket && parentTicket.id}</span>
                  {parentTicket && parentTicket.status === false ? <Controls.Button text="Close Tickets" small xsFont color={"bg-red-200 hover:bg-red-300"} onClick={handelCloseTicket} /> : null}
                </div>
                <span className="text-xs text-red-500 break-all">{parentTicket && parentTicket.mt?.motif}</span>
                <div className="text-xs text-amber-500">{parentTicket && parentTicket.commentMotif ? `Comment : ${parentTicket && parentTicket.commentMotif}` : null}</div>
                <span className="text-xs text-green-500">{parentTicket && parentTicket.rdvDate ? `Rdv: ${moment(parentTicket.rdvDate).format("LLLL")}` : null}</span>
              </div>
            </div>

            <div className="h-[80vh] flex-auto space-y-2 mt-10 px-1 overflow-y-auto scrollbar ">
              {tFollowUp && tFollowUp.length !== 0 ? (
                tFollowUp.map((a, i) => <CustomChildrenTicket key={i} data={a} />)
              ) : (
                <div className="w-full h-full flex justify-center items-center font-light text-slate-700">aucun résultat</div>
              )}
            </div>
            <div className="flex-none h-2"></div>
          </div>

          <div className="w-64 bg-gray-200 overflow-auto flex flex-col">
            <div className=" flex-none mx-2 h-10 mt-2 text-center"> Détails </div>
            <div className="flex flex-col space-y-2 border-b-2 border-slate-300 "></div>
            <div className=" h-[82vh] flex flex-col space-y-2 scrollbar text-xs px-2">
              {oneUser && (
                <>
                  <div className="text-center font-medium text-xs mt-2">
                    {" "}
                    {hasRebal ? <div className="text-green-500">Déja rééquilibré</div> : <div className="text-red-500">Jamais rééquilibré</div>}
                  </div>
                  <div className="border-b-2 border-slate-300  flex flex-col space-y-2">
                    <InfoCustom label={"ID"} value={oneUser.id} />
                    <InfoCustom label={"Nom"} value={oneUser.lastName} />
                    <InfoCustom label={"Prénom"} value={oneUser.firstName} />
                    <InfoCustom label={"Email"} value={oneUser.email} />
                    <InfoCustom label={"Tel"} value={oneUser.tel} />
                    <InfoCustom label={"Start"} value={moment(oneUser.details.startSubscription).format("DD/MM/YYYY") || "-"} />
                    <InfoCustom label={"Ajout dans DB"} value={moment(oneUser.createdAt).format("DD/MM/YYYY HH:MM:ss")} />
                  </div>
                  <div className="flex flex-col space-y-2 border-b-2 border-slate-300 pb-2">
                    <div className="flex-none font-medium">APIs</div>
                    <div className="grid grid-cols-3">
                      <div className="flex items-center">
                        <span>Api RO: </span> {oneUser.details.testApi.ro ? <IsOk /> : <NotOk />}
                      </div>
                      <div className="flex items-center">
                        <span>Api W: </span> {oneUser.details.testApi.w ? <IsOk /> : <NotOk />}
                      </div>
                      <div className="flex items-center">
                        <span>Api T: </span> {oneUser.details.testApi.t ? <IsOk /> : <NotOk />}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col space-y-2 border-b-2 border-slate-300 pb-2">
                    <div className="flex-none font-medium">Motif not OK : </div> <div className="">{oneUser.comment_followup}</div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketsScreen;
