import React from 'react'
import TableBody from './TableBody'
import TableHead from './TableHead'
function Table(props) {
    const { columns, tableData } = props
    return (
        <table className="table-auto">
            <TableHead columns={columns} />
            <TableBody columns={columns} tableData={tableData} />
        </table>

    )
}

export default Table