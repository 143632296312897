import React from 'react'

function Tooltip(props) {
    const { message, children } = props
    return (
        <div className="relative group">
            {children}
            <div className="absolute left-1/2 top-0 flex flex-col items-center hidden mb-6 group-hover:flex ">
                <span className="relative z-10 p-1 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">{message}</span>
                {/* <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600"></div> */}
            </div>
        </div>
    )
}

export default Tooltip 