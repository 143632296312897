import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"
import { ReactComponent as UnauthorizedLogo } from '../asset/unauthorized.svg';
import Controls from "../components/controls/Controls";

function UnauthorizedScreen() {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const { t } = useTranslation()

    return (
        <div className='flex flex-col justify-center items-center space-y-4'>

            <UnauthorizedLogo />
            <Controls.Button text={t(`button.revenir`)} onClick={() => goBack()} />
        </div>
    );
}

export default UnauthorizedScreen;
