import React from "react";
function DashboardCard(props) {
    const { icon, number, description, price, unit, bg, bgLight } = props;
    return (
        <>
            {/* <div
                className={`
        flex flex-col
        p-2
        max-w-fit
        min-w-[140px]
        md:min-w-[140px]
        w-full
        sm:w-1/2
        md:w-1/8
        rounded-sm gap-y-1

        ${bg ? bg : bgLight ? bgLight : "bg-slate-200  dark:bg-slate-800"}

              `}
            >
                <div className="flex items-center space-x-1">
                    {icon ? icon : null}
                    <span className={`text-xs font-light ${bg ? "text-slate-100 " : bgLight ? bgLight : "text-slate-800 "} dark:text-slate-100 `}>{description}</span>
                </div>
                {number ? (
                    <>
                        <div className={`font-bold text-xl ${bg ? "text-slate-100 " : "text-slate-800 "}  dark:text-slate-100 `}>
                            {number}

                            {unit ? <span className="text-xs text-slate-900 ml-1">{unit}</span> : null}
                        </div>

                        {price ? <div className="text-xs flex  text-slate-500"> ≈ {(number * price).toFixed(2)}€ </div> : null}
                    </>
                ) : number == 0 ? (
                    <>
                        <div className={`font-bold text-xl ${bg ? "text-slate-100 " : "text-slate-800 "}  dark:text-slate-100 `}>0{unit ? <span className="text-xs text-slate-900 ml-1">{unit}</span> : null}</div>

                        {price ? <div className="text-xs flex  text-slate-500"> ≈ {(number * price).toFixed(2)}€ </div> : null}
                    </>
                ) : (
                    <>
                        <div className="h-7 bg-slate-300 animate-pulse opacity-75 rounded"></div>
                        {price ? <div className="h-3 bg-slate-300 animate-pulse opacity-75 rounded"> </div> : null}
                    </>
                )}
            </div> */}

            <div
                className={`
        flex flex-col
        p-2
        max-w-fit
        min-w-[140px]
        md:min-w-[140px]
        w-full
        sm:w-1/2
        md:w-1/8
        rounded-lg gap-y-1

        ${bg ? bg : bgLight ? bgLight : "bg-slate-200  dark:bg-slate-800"}

              `}
            >
                <div className="flex items-center space-x-1">

                    {icon ? <div className={`${bg ? "text-slate-100 " : "text-slate-800 "}  dark:text-slate-100 w-5 h-5 `}>{icon}</div> : null}

                    <div className=" w-full flex flex-col items-center justify-center ">
                    {number ? (
                        <>
                            <div className={`font-bold text-xl ${bg ? "text-slate-100 " : "text-slate-800 "}  dark:text-slate-100 `}>
                                {number}

                                {unit ? <span className="text-xs text-slate-900 ml-1">{unit}</span> : null}
                            </div>

                            {price ? <div className="text-xs flex  text-slate-500"> ≈ {(number * price).toFixed(2)}€ </div> : null}
                        </>
                    ) : number == 0 ? (
                        <>
                            <div className={`font-bold text-xl ${bg ? "text-slate-100 " : "text-slate-800 "}  dark:text-slate-100 `}>0{unit ? <span className="text-xs text-slate-900 ml-1">{unit}</span> : null}</div>

                            {price ? <div className="text-xs flex  text-slate-500"> ≈ {(number * price).toFixed(2)}€ </div> : null}
                        </>
                    ) : (
                        <>
                            <div className="h-7 bg-slate-300 animate-pulse opacity-75 rounded"></div>
                            {price ? <div className="h-3 bg-slate-300 animate-pulse opacity-75 rounded"> </div> : null}
                        </>
                    )}
                    <div className="flex items-center space-x-1">
                        <span className={`text-xs font-light ${bg ? "text-slate-100 " : bgLight ? bgLight : "text-slate-800 "} dark:text-slate-100 `}>{description}</span>
                    </div>
                </div>
                </div>


            </div>
        </>
    );
}

export default DashboardCard;
