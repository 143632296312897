import { useEffect, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
const useBinancePrice = (symbol) => {
    const [price, setPrice] = useState(0);
    useEffect(() => {
        // Construct the WebSocket URL based on the passed symbol
        const wsUrl = `wss://stream.binance.com:9443/ws/${symbol.toLowerCase()}@trade`;
        const ws = new ReconnectingWebSocket(wsUrl);
        // Event listener for receiving messages
        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            const { p: currentPrice } = message;
            setPrice(parseFloat(currentPrice));
        };
        // Clean up function to close websocket connection when component unmounts
        return () => {
            ws.close();
        };
    }, [symbol]); // Depend on symbol so changes re-establish the connection
    return price;
};
export default useBinancePrice;
