import React from "react";
import ReactPlayer from "react-player";
import FileResource from './FileResource';

const VideoResource = (props) => {
  const { url, title, xsFont, support, platform } = props;
  return (
    <>
          <div className={` p-2 w-60 md:w-80 rounded-lg hover:cursor-pointer border-2 ${platform == 1 ? " border-yellow-500 hover:bg-yellow-300/25" : platform == 2 ? " border-purple-500 hover:bg-purple-300/25" : "border-blue-500 hover:bg-blue-200/25"} `}>
        <div className={`font-bold text-center text-slate-600 mb-2 ${xsFont ? "text-xs" : "text-sm "} md:text-sm`}>
          {title}
        </div>
        <div className="relative pt-[56.25%] ">
          <ReactPlayer className="absolute top-0 left-0 " url={url} width="100%" height="100%" controls={true} config={{
            youtube: {
              youtube: {
                playerVars: { showinfo: 1 }
              },
            },
          }} />
        </div>
      </div>
      {support &&
        <div className="-m-2">
          <FileResource pdfLink={support} title="PDF" />
        </div>
      }
    </>

  );
};

export default VideoResource;
