import React, { useState, useEffect } from "react";
import Controls from "../components/controls/Controls";
import { useFOrm } from "../components/useFOrm";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { authSelector } from "../features/authSlice";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { ClipboardIcon, CurrencyDollarIcon, ServerIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import * as service from "../utils/service";
import Currency from "../components/Users/Currency";
import Title from "../components/title/Title";
import Lang from "../components/Users/Lang";
import { Languages } from "lucide-react";
import BtnShad from "../components/client/btnShadcn";


const initialFvalues = {
    isAbsolute: false,
    absoluteMonth: 0,
};
function BulotAbsoluScreen(props) {
    const { t } = props;
    const [isLoading, setIsLoading] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const auth = useSelector(authSelector);
    const [user, setUser] = useState();
    const goBack = () => navigate(-1);
    const navigate = useNavigate();
    const [bulotAbsIsOpen, setBulotAbsIsOpen] = useState(false)
    const [currencyIsOpen, setCurrencyIsOpen] = useState(false)
    const [langIsOpen, setLangIsOpen] = useState(false)
    // console.log("BABS", bulotAbsIsOpen);

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("absoluteMonth" in fieldValues) {
            temp.absoluteMonth = (fieldValues.isAbsolute == true || fieldValues.isAbsolute == "true") && fieldValues.absoluteMonth == 0 ? t("error.required") : "";
        }

        setErrors({
            ...temp,
        });
        console.log("Abs", temp);
        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const handleValidate = async () => {
        setIsLoading(true);
        console.log("HERE", values);
        if (validate()) {
            try {
                const dataToUpdate = {
                    isAbsolute: values.isAbsolute,
                    absoluteMonth: values.absoluteMonth,
                };
                console.log("Abs==========", dataToUpdate);
                let typeAbsolute;
                switch (true) {
                    case values.absoluteMonth == 3:
                        typeAbsolute = "trimestre";
                        break;
                    case values.absoluteMonth == 6:
                        typeAbsolute = "semestre";
                        break;
                    case values.absoluteMonth == 12:
                        typeAbsolute = "annuel";
                        break;

                    default:
                        break;
                }

                await axiosPrivate.put(`/api/details/${auth.id}`, dataToUpdate);

                let dataToPost = { type: 0, email: auth.email, absolu: null };
                if (values.absoluteMonth == 0) {
                    await axiosPrivate.post(`/api/mailerlite`, dataToPost);
                } else {
                    dataToPost.type = 1;
                    dataToPost.absolu = typeAbsolute;
                    await axiosPrivate.post(`/api/mailerlite`, dataToPost);
                }

                toast.success("Modification fait");
                setIsLoading(false);
                // setIsOpen(true);
                // setTimeout(() => {
                //   setIsOpen(false);
                // }, 5000);
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
            // setIsOpen(!isOpen);
        }
        setIsLoading(false);
    };

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getOneUser = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/users/genericFindOne/${auth.id}`, {
                    signal: controller.signal,
                });
                const decrypt = service.decryptOneUserInfo(data.oneUser);

                console.log("====users", data);
                isMounted && setUser(decrypt.details);
            } catch (error) {
                toast.error("error");
                console.log(error);
            }
        };
        getOneUser();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    if (user) {
        for (const key in initialFvalues) {
            initialFvalues[key] = user[key];
        }
    }
    useEffect(() => {
        console.log("bbbb", values);
        if (values.isAbsolute == "false" || values.isAbsolute == false) {
            values.absoluteMonth = 0;
        }
    }, [values]);

    return (
        <>

            <div className=" mx-2 md:mx-auto bg-slate-50  mt-4 pt-2 px-4 md:w-[70%] py-4">
                <div className="flex items-center mb-4">
                    <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
                        <ArrowNarrowLeftIcon className="h-4 w-4 hover:cursor-pointer" onClick={goBack} />
                    </div>
                    <div className="text-sm font-medium">{t("label.myAccount")}</div>
                </div>


                <div className="flex flex-col space-y-4 pt-2">
                    {/* BULOT ABSOLU */}
                    <div className="border p-2 rounded-md">

                        <div className="flex font-semibold text-slate-600 items-center py-1 hover:cursor-pointer hover:bg-blue-200/25 " onClick={() => setBulotAbsIsOpen(!bulotAbsIsOpen)}>
                            <ClipboardIcon className="h-5 w-5 text-blue-700 text-xs" />
                            <div>{t(`label.cbAbsolute`)}</div>
                        </div>

                        <div className={`${bulotAbsIsOpen ? "block" : "hidden"}`}>
                            <hr />

                            {user ? (
                                <div className={` flex flex-col items-center justify-center space-y-10`}>
                                    <div className="text-sm pt-4">
                                        {t(`info.becomeCBAbsolute`)}
                                    </div>
                                    <div className=" p-2 w-full md:w-[50%] text-center bg-white drop-shadow-sm rounded-xl ">

                                        <div className="">
                                            <Controls.RadioGroup
                                                items={[
                                                    { id: true, title: t(`button.oui`) },
                                                    { id: false, title: t(`button.non`) },
                                                ]}
                                                key={""}
                                                label={t(`placeholder.becomeCBAbsolute`)}
                                                value={values.isAbsolute}
                                                onChange={handleInputChange}
                                                name={"isAbsolute"}
                                                error={errors.isAbsolute}
                                            />
                                        </div>
                                        <div className="mt-10">
                                            {values.isAbsolute == true || values.isAbsolute == "true" ? (
                                                <div>
                                                    <div className="">
                                                        <Controls.RadioGroup
                                                            items={[
                                                                { id: 3, title: t(`placeholder.trimestre`) },
                                                                { id: 6, title: t(`placeholder.Semestre`) },
                                                                { id: 12, title: t(`placeholder.annuel`) },
                                                            ]}
                                                            key={""}
                                                            label={`${t(`placeholder.summaryAbsolu`)}* :`}
                                                            value={values.absoluteMonth}
                                                            onChange={handleInputChange}
                                                            name={"absoluteMonth"}
                                                            error={errors.absoluteMonth}
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div>
                                            <div className="flex flex-col items-center">
                                                <div className="w-64 pt-2">
                                                    {/* <Controls.Button disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false} widthFull text={t(`button.validate`)} onClick={handleValidate} isLoading={isLoading} /> */}
                                                    <BtnShad
                                                        disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                                        sendHistory={{ action: `Bulot Absolu  `, who: `${auth?.id}` }}
                                                        text={t(`button.validate`)}
                                                        onClick={handleValidate}
                                                        isLoading={isLoading}
                                                        wFull
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <Loading />
                            )}
                            {/*                 {values.isAbsolute == true || values.isAbsolute == "true" ? (
         */}
                            {values.isAbsolute == true || values.isAbsolute == "true" ? (
                                <div className="mt-10 text-slate-500">
                                    {/* <InformationCircleIcon className="w-5 text-yellow-400 " /> */}
                                    <div className="text-sm">
                                        <span className="font-medium">* {t(`text.summaryTrim`)}:</span> {t(`month.mars`)}, {t(`month.juin`)}, {t(`month.sept`)}, {t(`month.dec`)}
                                    </div>
                                    <div className="text-sm">
                                        <span className="font-medium">* {t(`text.summarySem`)} :</span> {t(`month.juin`)} & {t(`month.dec`)}
                                    </div>
                                    <div className="text-sm">
                                        <span className="font-medium">* {t(`text.summaryAnnuelle`)} :</span>  {t(`month.dec`)}
                                    </div>
                                </div>
                            ) : null}
                        </div>

                    </div>

                    {/* CURRENCY  ------ DEVISES */}
                    <div className="border p-2 rounded-md ">

                        <div className="flex font-semibold text-slate-600 items-center py-1 hover:cursor-pointer hover:bg-blue-200/25"
                            onClick={() => setCurrencyIsOpen(!currencyIsOpen)}>
                            <CurrencyDollarIcon className="h-5 w-5 text-blue-700 text-xs" />
                            <div>{t(`label.currency`)}</div>
                        </div>

                        <div className={`${currencyIsOpen ? "block" : "hidden"} `}>
                            <hr />
                            {user ? <Currency t={t} u={user && user} auth={auth} /> :
                                <Loading />
                            }
                            {/* <Currency t={t} /> */}
                        </div>
                    </div>

                    {/* LANGUAGE */}
                    <div className="border p-2 rounded-md ">

                        <div className="flex font-semibold text-slate-600 items-center py-1 hover:cursor-pointer hover:bg-blue-200/25"
                            onClick={() => setLangIsOpen(!langIsOpen)}>
                            <Languages className="h-5 w-5 text-blue-700 text-xs" />
                            <div>{t(`label.lang`)}</div>
                        </div>

                        <div className={`${langIsOpen ? "block flex space-x-2 items-center text-sm" : "hidden"} `}>
                            <hr />
                            {t(`info.chooseLang`)}
                            <Lang />
                            {/* <Currency t={t} /> */}
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default BulotAbsoluScreen;
