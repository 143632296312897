import React, { useState, useEffect, useMemo, useCallback } from "react";
import Controls from "../components/controls/Controls";
import moment from "moment";
import case_1 from "../utils/case_1.json";
import case_2 from "../utils/case_2.json";

//import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import Loading from "../components/Loading";

import { useParams, useSearchParams } from "react-router-dom";
import { authSelector } from "../features/authSlice";
import { useSelector } from "react-redux";
import MyLineIndex from "../components/chart/MyLineIndex";
import { exportExcel } from "../utils/service";
import { useFOrm } from "../components/useFOrm";
import axios from "axios";
import { useDebouncedCallback } from 'use-debounce';

const initialValues = {
    dateIndex: 1,
    mmValue: 20,
    ecartType: 0
};

const IndexScreenAdmin = (props) => {
    const { t } = props;
    const { id } = useParams();
    const auth = useSelector(authSelector);
    const axiosPrivate = useAxiosPrivate();
    const [mm, setMm] = useState();
    const [dataRow, setDataRow] = useState();
    const [allIndexRow, setAllIndexRow] = useState();
    const [cbd, setCbd] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("currency" in fieldValues) temp.currency = fieldValues.currency ? "" : t("error.required");

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);


    // useEffect(() => {
    //     let isMounted = true;
    //     const controller = new AbortController();

    //     // const getAlldata = async () => {
    //     //     try {
    //     //         const query = `{ clientRowData { id date evolution } }`;
    //     //         const { data } = await axiosPrivate.post(`/graphql`, {
    //     //             signal: controller.signal,
    //     //             query,
    //     //         });

    //     //         let allIndexdata = await data.data.clientRowData.map((d) => {
    //     //             return {
    //     //                 //id: d.id,
    //     //                 date: d.date,
    //     //                 //index: Number(JSON.parse(d.evolution).index).toFixed(0),
    //     //                 indexCB: d.evolution && Number(JSON.parse(d.evolution).indexCB) ? Number(JSON.parse(d.evolution).indexCB).toFixed(0) : 0,
    //     //                 /*percentageEvolution: Number(JSON.parse(d.evolution).percentageEvolution),
    //     //                 mm20m: d.mm20m,
    //     //                 bbsup: d.bbsup,
    //     //                 bbinf: d.bbinf,
    //     //                 order: d.order,
    //     //                 coefOrder: d.coefOrder,
    //     //                 coefBbsupIndex: d.coefBbsupIndex,
    //     //                 temperateOrder: d.temperateOrder,*/
    //     //             };
    //     //         });

    //     //         isMounted && setAllIndexRow(allIndexdata);
    //     //         console.log("sdfsdfsdf ========================================", data.data.clientRowData);
    //     //     } catch (error) {
    //     //         console.log("==================", error);
    //     //     }
    //     // };

    //     const getData2 = async () => {
    //         try {
    //             const query = `{ getRowdata(id: 1) { id date bbinf bbsup evolution order mm20m coefOrder temperateOrder coefBbsupIndex } }`;
    //             const { data } = await axiosPrivate.post(`/graphql`, {
    //                 signal: controller.signal,
    //                 query,
    //             });
    //             let formatOriginalData = await data.data.getRowdata.map((d) => {
    //                 return {
    //                     id: d.id,
    //                     date: d.date,
    //                     index: Number(JSON.parse(d.evolution).index).toFixed(0),
    //                     indexCB: Number(JSON.parse(d.evolution).indexCB).toFixed(0),
    //                     percentageEvolution: Number(JSON.parse(d.evolution).percentageEvolution),
    //                     mm20m: d.mm20m,
    //                     bbsup: d.bbsup,
    //                     bbinf: d.bbinf,
    //                     order: d.order,
    //                     coefOrder: d.coefOrder,
    //                     coefBbsupIndex: d.coefBbsupIndex,
    //                     temperateOrder: d.temperateOrder,
    //                 };
    //             });
    //             /*  let formatData = {
    //                 labels: data.data.getRowdata.map((d) => d.date),
    //                 index: data.data.getRowdata.map((d) => JSON.parse(d.evolution).index),
    //                 indexCB: data.data.getRowdata.map((d) => JSON.parse(d.evolution).indexCB),
    //                 percentageEvolution: data.data.getRowdata.map((d) => JSON.parse(d.evolution).percentageEvolution),
    //                 mm20m: data.data.getRowdata.map((d) => d.mm20m),
    //                 bbsup: data.data.getRowdata.map((d) => d.bbsup),
    //                 bbinf: data.data.getRowdata.map((d) => d.bbinf),
    //                 order: dataRow && data.data.getRowdata.map((d) => d.order),
    //                 coefOrder: dataRow && data.data.getRowdata.map((d) => d.coefOrder),
    //                 coefBbsupIndex: dataRow && data.data.getRowdata.map((d) => d.coefBbsupIndex),
    //                 temperateOrder: dataRow && data.data.getRowdata.map((d) => d.temperateOrder),
    //               };*/

    //             let formToexcel = await data.data.getRowdata.map((d) => {
    //                 let tempObj = {};

    //                 if (d.date > "2016-07-01") {
    //                     tempObj = {
    //                         date: moment(d.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    //                         index: Number(JSON.parse(d.evolution).index).toFixed(0),
    //                         CBS: Number(JSON.parse(d.evolution).indexCB).toFixed(0),
    //                     };
    //                 }
    //                 return tempObj;
    //             });

    //             isMounted && setDataRow(formToexcel.filter((obj) => Object.keys(obj).length));
    //             isMounted && setOriginaldata(formatOriginalData.filter((f) => f.id > 639));

    //             console.log("resp", data.data.getRowdata);
    //         } catch (error) {
    //             console.log("err", error);
    //         }
    //     };
    //     getData2();
    //     // getAlldata();
    //     return () => {
    //         isMounted = false;
    //         controller.abort();
    //     };
    // }, []);

    const getCBD = async () => {
        setIsLoading(true)
        const { data } = await axiosPrivate.get(`/api/rowdata/getDataCdb?day=${values.dateIndex}`)
        setCbd(data)
        setIsLoading(false)
    }

    useEffect(() => {
        getCBD()
        // Set a query parameter


    }, [values.dateIndex])

    let headersList = {
        "Accept": "*/*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }

    let bodyContent = JSON.stringify({
        "mm_length": 20,
        "coeff_stdev": 1
    });




    const sendMM = useDebouncedCallback(async () => {
        try {
            //let response = await axios.get(`https://bg.flow-corporation.space/functionalities/index/compute-mm-stdev?mm_length=${values.mmValue}&coeff_stdev=${values.ecartType}`);
            let response = await fetch(`https://bg.flow-corporation.space/functionalities/index/compute-mm-stdev?mm_length=${values.mmValue}&coeff_stdev=${values.ecartType}`, {
                method: "GET",
                //headers: headersList
            });

            let data = await response.json();
            setMm(data)
            console.log("sendMM*******", data);
        } catch (error) {
            console.error("Error while fetching data:", error);
        }

    }, 1000)

    useEffect(() => {
        console.log(" ato");
        sendMM()
        // Set a query parameter
        setSearchParams({ mm: values.mmValue, ecartType: values.ecartType });

    }, [values.mmValue, values.ecartType])

    const dateIndexList = [
        { "id": 1, "title": "1" },
        { "id": 2, "title": "2" },
        { "id": 3, "title": "3" },
        { "id": 4, "title": "4" },
        { "id": 5, "title": "5" },
        { "id": 6, "title": "6" },
        { "id": 7, "title": "7" },
        { "id": 8, "title": "8" },
        { "id": 9, "title": "9" },
        { "id": 10, "title": "10" },
        { "id": 11, "title": "11" },
        { "id": 12, "title": "12" },
        { "id": 13, "title": "13" },
        { "id": 14, "title": "14" },
        { "id": 15, "title": "15" },
        { "id": 16, "title": "16" },
        { "id": 17, "title": "17" },
        { "id": 18, "title": "18" },
        { "id": 19, "title": "19" },
        { "id": 20, "title": "20" },
        { "id": 21, "title": "21" },
        { "id": 22, "title": "22" },
        { "id": 23, "title": "23" },
        { "id": 24, "title": "24" },
        { "id": 25, "title": "25" },
        { "id": 26, "title": "26" },
        { "id": 27, "title": "27" },
        { "id": 28, "title": "28" }
    ]
    // if (isLoading) {
    //     return <Loading/>
    // }

    return (
        <div className="p-4 flex gap-3 h-[93vh]">
            <div className="flex-1 bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 ">
                <div className="flex space-x-2 items-center mb-2 ">
                    <div className="flex space-x-2">

                        <div className="font-bold text-2xl text-slate-700 dark:text-slate-100 ">{t("label.indexCbt")}</div>
                        <Controls.Select
                            name="dateIndex"
                            label="Date"
                            value={values.dateIndex}
                            onChange={handleInputChange}
                            options={dateIndexList}
                            disabled={isLoading}
                        />
                    </div>

                    <div>
                        <Controls.Input
                            name="mmValue"
                            type="number"
                            value={values.mmValue}
                            onChange={handleInputChange}
                            label="Valeur du MM" />
                    </div>
                    <div>
                        <Controls.Input
                            name="ecartType"
                            type="number"
                            value={values.ecartType}
                            onChange={handleInputChange}
                            label="Ecart type" />
                    </div>

                </div>
                <div className="flex justify-center">
                    <div className="w-full h-[75vh]">
                        {cbd && !isLoading ? (
                            <MyLineIndex
                                //values={values && values}
                                t={t}
                                // data={originaldata && originaldata}
                                screenName={"admin"}
                                cbdData={cbd && cbd}
                                mm={mm}
                            />
                        ) : (
                            <Loading />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndexScreenAdmin;
