import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { authSelector } from '../../features/authSlice';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { decryptCBData } from '../../utils/service';
import Controls from '../controls/Controls';
import { useFOrm } from '../useFOrm';
import BtnShad from '../client/btnShadcn';

const initialFvalues = {

    password: "",
    confPassword: "",
    code: ""
};

function UpdateEmail(props) {
    const { data, setIsOpen } = props
    console.log("dddddd=========dddd ;) :p", data);
    const { t } = useTranslation()
    const auth = useSelector(authSelector);
    const axiosPrivate = useAxiosPrivate()
    const [showCode, setShowCode] = useState(false);
    const [loading, setLoading] = useState(false)

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ('password' in fieldValues)
            temp.password = (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/).test(fieldValues.password) ? "" : t("error.mdp")
        if ('confPassword' in fieldValues)
            temp.confPassword = (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/).test(fieldValues.confPassword) ? "" : t("error.mdp")
        if ("country" in fieldValues) temp.country = fieldValues.country ? "" : t("error.required");

        setErrors({
            ...temp,
        });

        if (fieldValues == values) return Object.values(temp).every((x) => x == "");
    };


    initialFvalues.email = data?.email;

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);


    const formInfo = [

        {
            type: "password",
            name: "password",
            label: t("label.newPassword"),
            placeholder: t("label.newPassword"),
            password: false,
            required: true,
        },
        {
            type: "password",
            name: "confPassword",
            label: `${t("button.confirm")} ${t(`label.newPassword`)}`,
            placeholder: `${t("button.confirm")} ${t(`label.newPassword`)}`,
            password: false,
            required: true,
        },

    ];



    const handleSaveEmail = async () => {
        if (validate()) {
            try {
                //data : subAccount
                setLoading(true)
                const response = await axiosPrivate.post(`/api/users/updateSub?reference=${data?.id}`, values)
                setLoading(false)
                console.log("RESPPPPPPPP_______", response.data);
                if (response.data.msg == "next") {
                    setShowCode(true)
                }
            } catch (error) {
                console.log("error", error);
                toast.error(`${t(`error.errorPassword`)}`)
                setLoading(false)
            }
        }
    }

    const handleSendCode = async () => {
        try {
            await axiosPrivate.put(`/api/users/resetSub?reference=${data?.id}`, values)
            setShowCode(false)
            setIsOpen(false)
            toast.success(t(`toast.successModif`))

        } catch (error) {
            setIsOpen(false)
            console.log("error", error);
            toast.error(`${t(`error.errorOccurred`)}`)
        }
    }

    const emailDecrypt = decryptCBData(auth.email)
    console.log("decrypttttt==================", emailDecrypt);

    return (
        <div className='w-[367px]'>
            <div className={`${showCode ? "hidden" : "flex flex-col items-center justify-center "}`}>

                <div className="space-y-4 w-full my-4 ">
                    {formInfo.map((item, i) => {
                        // console.log(data[item.name])
                        return (
                            <Controls.Input
                                fullWidth={true}
                                key={i}
                                // disabled={disabled}
                                // readOnly={disabled}
                                // readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) || auth.role.includes(process.env.REACT_APP_SUPADMIN) ? true : false}
                                label={item.label}
                                value={values[item.name] || ""}
                                onChange={handleInputChange}
                                name={item.name}
                                type={item.type}
                                error={errors[item.name]}
                            />
                        );
                    })}
                    {/*
                    <Controls.Button
                        widthFull
                        onClick={handleSaveEmail}
                        text={t(`button.next`)}
                        isLoading={loading}
                    /> */}
                    <BtnShad text={t(`button.next`)}
                        wFull
                        onClick={handleSaveEmail}
                        isLoading={loading}
                    />
                </div>
            </div>

            {/* <div className={`flex flex - col items - center bg - pink - 400 `}> */}
            <div className={`${showCode ? "flex flex-col items-center justify-center" : "hidden"}`}>
                <div className="w-full my-4 space-y-4 ">
                    <div className='text-sm text-center'>
                        {t(`text.6digitCode`)} <strong>{emailDecrypt}</strong>
                    </div>

                    <Controls.Input
                        fullWidth={true}
                        // key={i}
                        // disabled={disabled}
                        // readOnly={disabled}
                        label={t(`label.verifCode`)}
                        value={values["code"] || ""}
                        onChange={handleInputChange}
                        name={t(`label.code`)}
                        type={"number"}
                        error={errors["code"]}
                    />
                    {/* <Controls.Button
                        widthFull
                        onClick={handleSendCode}
                        text={t(`button.confirm`)}
                        isLoading={loading}
                        disabled={values.code.length == 6 ? false : true}
                    /> */}
                    <BtnShad text={t(`button.confirm`)}
                        wFull
                        onClick={handleSendCode}
                        isLoading={loading}
                        disabled={values.code.length == 6 ? false : true}
                        sendHistory={{ action: `Reset MDP sub-account  `, who: `${data?.id}` }}
                    />
                </div>

            </div>
            {/*
            <div className='text-xs mt-2'>
                {t(`info.note`)} : {t(`info.noteChangeEmail`)}
            </div> */}
        </div>
    )
}

export default UpdateEmail
