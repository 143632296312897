import React, { PureComponent } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from "recharts";
// keyLength = ["date","new", "end"]
function BarChartStackedBySign(props) {
  const { data, colors, width, height, keyLength, name } = props;
  //console.log("KEYSSS === ", keyLength);
  return (
    <div className={`p-4 flex gap-x-3 w-full md:w-[${width}px] h-[${height}px] `}>
      <>
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <BarChart
            width={200}
            height={300}
            data={data}
            stackOffset="sign"
            // margin={{
            //   top: 5,
            //   right: 30,
            //   left: 20,
            //   bottom: 5,
            // }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={keyLength && keyLength[0]} />
            <YAxis />
            <Tooltip />
            <Legend />
            <ReferenceLine y={0} stroke="#000" />
            {keyLength &&
              keyLength.map((k, i) => {
                if (i > 0 && i <= 2) {
                  return <Bar dataKey={k} fill={colors[i - 1]} stackId="stack" name={name[i - 1]} />;
                }
              })}
          </BarChart>
        </ResponsiveContainer>
      </>
    </div>
  );
}

export default BarChartStackedBySign;
