import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { DataGrid, frFR, GridToolbar } from "@mui/x-data-grid";
import Controls from "../controls/Controls";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../Loading";
import DialogBox from "../DialogBox";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { authSelector } from "../../features/authSlice";
import moment from "moment";
import * as service from "../../utils/service";
import BtnShad from "../client/btnShadcn";
const InsideCard = ({ text, label }) => (
    <div className="">
        <span className="text-xs font-semibold text-slate-800 dark:text-slate-100">{label}:</span>
        <span className="text-sm ml-2 text-slate-700 dark:text-slate-100">{text}</span>
    </div>
);

function LearnyBoxUsers(props) {
    const { t } = props;
    const [lbUsers, setLbUsers] = useState();
    const [users, setUsers] = useState();
    const [selected, setSelected] = useState();
    const [code, setCode] = useState();
    const [parent, setParent] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [plateform, setPlateform] = useState('');
    const [errorPlateform, setErrorPlateform] = useState("");
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const auth = useSelector(authSelector);
    const location = useLocation();

    const column = [
        { field: "lastName", headerName: t("label.lastName") },
        {
            field: "firstName",
            headerName: t("label.firstName"),
            width: 150,
        },
        { field: "email", headerName: t("label.email"), width: 200 },
        { field: "tel", headerName: t("label.tel"), width: 200 },
        {
            field: "date",
            headerName: "Date",
            width: 200,
            type: "date",
            valueGetter: (params) => {
                if (params.row.date) {
                    const date = moment(params.row.date).utcOffset("+04:00");
                    return moment(date).format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },

            renderCell: (cellValues) => {
                if (cellValues.row.date) {
                    return moment(cellValues.row.date, "DD-MM-YYYY").format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            filterable: false,
            disableExport: true,

            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        color={"text-white bg-green-500 hover:bg-green-600 dark:text-slate-100"}
                        text={t("button.add")}
                        onClick={(event) => {
                            //handleClick(event, cellValues);
                            //navigate(`/admin/user/${cellValues.row.id}`)
                            console.log(cellValues.row);
                            setIsOpen(!isOpen);
                            setSelected(cellValues.row);
                        }}
                    />
                );
            },
        },
    ];

    const plateformList = [
        { id: 1, title: "Binance" },
        { id: 2, title: "Kraken" },
    ];

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getLBUsers = async () => {
            try {
                const response = await axiosPrivate.get(`/api/lb/findAll`, {
                    signal: controller.signal,
                });
                const decrypt = JSON.parse(service.decryptCBData(response.data.lb));
                isMounted && setLbUsers(decrypt);
                isMounted && setUsers(response.data.all);

                console.log("here", response.data);
            } catch (error) {
                console.log(error);
                setTimeout(() => {
                    //navigate('/login', { state: { from: location }, replace: true })
                }, 5000);
            }
        };

        getLBUsers();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);
    const VISIBLE_FIELDS = ["name", "rating", "country", "dateCreated", "isAdmin"];
    const NewToolbar = () => {
        return (
            <div className="flex flex-col md:flex-row p-2">
                <GridToolbar />
            </div>
        );
    };

    const linkCb = async (values) => {
        const dataToadd = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            tel: values.tel,
            parent: parent?.id || null,
            addedBy: auth.name,
            platform: plateform
        };

        console.log(dataToadd);

        try {
            setIsLoading(true);
            const response = await axiosPrivate.post(`/api/users/create`, dataToadd);

            // setTimeout(() => {
            //     console.log("Retardée d'une seconde.", response.data.id);
            // }, "5000")

            if (response.data) {
                const detailsToadd = {
                    uid: response.data.id,
                    startSubscription: selected.date,
                };
                try {
                    const responseDetails = await axiosPrivate.post(`/api/details/create`, detailsToadd);
                    if (responseDetails.data) {
                        navigate(`/support/user/${response.data.id}`);
                    }
                } catch (error) {
                    toast.error(error.response?.data.msg);
                }
            }
            setIsLoading(false);
        } catch (error) {
            toast.error(error.response?.data.msg);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        if (code) {
            const getOneUser = async () => {
                try {
                    const response = await axiosPrivate.get(`/api/users/findBy/code/${code}`, {
                        signal: controller.signal,
                    });
                    //selected.parent = response.data.id
                    isMounted && setParent(response.data);
                } catch (error) {
                    //console.log(error)
                    if (error.response.status != 403) {
                        navigate("/login", { state: { from: location }, replace: true });
                    }
                }
            };

            getOneUser();
        } else {
            setParent("");
        }

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [code]);

    const handleInputChange = (event, value) => {
        /* setParent(value)*/
        //selected.parent = value || ""
        setCode(value);
    };



    return (
        <>
            <DialogBox isOpen={isOpen} setIsOpen={setIsOpen} title={"Ajout Client"}>
                <div className="flex flex-col gap-y-1">
                    <div className="grid grid-cols-2 gap-x-5">
                        <div className="flex flex-col">
                            <InsideCard label={t("label.lastName")} text={selected?.lastName} />
                            <InsideCard label={t("label.firstName")} text={selected?.firstName} />
                            <InsideCard label={t("label.email")} text={selected?.email} />
                            <InsideCard label={t("label.tel")} text={selected?.tel} />
                            <InsideCard label={t("label.subscriptionDate")} text={selected?.date} />
                        </div>

                        <div className="border-l-2 px-2">
                            <Controls.AutoComplete
                                options={
                                    users &&
                                    users?.map((u) => {
                                        return {
                                            key: u.id,
                                            id: u.id,
                                            label: u.code,
                                        };
                                    })
                                }
                                label={"Parrain"}
                                onInputChange={handleInputChange}
                            //onChange={(event, value) => setParent(users.filter(uf => (uf.id === value.id)))}
                            //onChange={(event, value) => handleInputChange(value)}
                            />
                            <InsideCard label={t("label.lastName")} text={parent && service.decryptCBData(parent?.lastName)} />
                            <InsideCard label={t("label.firstName")} text={parent && service.decryptCBData(parent?.firstName)} />
                            <InsideCard label={t("label.email")} text={parent && service.decryptCBData(parent?.email)} />
                            <div className="mt-2">
                                <Controls.Select name="coin"
                                    label={"Plateforme"}
                                    value={plateform}
                                    onChange={(e) => setPlateform(e.target.value)}
                                    options={plateformList}
                                    error={errorPlateform}
                                    noneValue={"Aucun"}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="flex gap-x-2 justify-end mt-5">
                        {/* <Controls.Button text={t("button.cancel")} xsFont color={"bg-red-500 text-white hover:bg-red-700"} onClick={() => setIsOpen(false)} /> */}
                        <BtnShad text={t("button.cancel")} onClick={() => setIsOpen(false)} size="sm" variant="destructive"  />
                        <BtnShad isLoading={isLoading} text={t("button.next")} onClick={() => linkCb(selected)} size="sm"  disabled={(plateform == null || plateform == '') ? true : false} />
                        {/* <Controls.Button isLoading={isLoading} text={t("button.next")} xsFont onClick={() => linkCb(selected)} disabled={(plateform == null || plateform == '') ? true : false} /> */}
                    </div>
                </div>
            </DialogBox>
            {lbUsers ? (
                <DataGrid
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    getRowId={(row) => row.email}
                    // componentsProps={{
                    //     footer: { total }
                    // }}
                    autoPageSize={true}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    // initialState={{
                    //     sorting: {
                    //         sortModel: [{ field: 'id', sort: 'desc' }],
                    //     },
                    // }}
                    rows={lbUsers}
                    columns={column}
                    disableColumnSelector
                    disableDensitySelector
                />
            ) : (
                <Loading />
            )}
        </>
    );
}

export default LearnyBoxUsers;
