import React, { useEffect, useState } from "react";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import DashboardCard from "../components/cards/DashboardCard";
import TableauGrid from "../components/TableauGrid";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { authSelector } from "../features/authSlice";
import Loading from "../components/Loading";
import Controls from "../components/controls/Controls";
import BannerInfo from "../components/BannerInfo";
import DialogBox from "../components/DialogBox";
import * as service from "../utils/service";

function ClientTransactionScreen(props) {
  const { t } = props;
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const auth = useSelector(authSelector);
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(false);
  const { id } = useParams(); // maka id
  const [selectedRow, setSelectedRow] = useState([]);
  const [total, setTotal] = useState();
  const [allData, setAllData] = useState();
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    // get affiliate payment
    const getDetails = async (myId) => {
      try {
        const { data } = await axiosPrivate.get(`/api/affiliate-payment/find/${myId}`, {
          signal: controller.signal,
        });
        const decrypt = JSON.parse(service.decryptCBData(data));

        console.log("trhrthrhtrhrt", decrypt);
        const decryptFilleuls = decrypt?.map((f, i) => ({
          ...f,
          pnl: f?.pnl?.map((p, i) => ({
            ...p,
            users: {
              firstName: service.decryptCBData(p.users.firstName),
              lastName: service.decryptCBData(p.users.lastName),
            },
          })),
        }));
        isMounted && setAllData(decryptFilleuls);
        isMounted && setTotal(decrypt?.map((p) => JSON.parse(p.total).usdt).reduce((acc, amount) => Number(acc) + Number(amount), 0));
      } catch (error) {
        console.log("err", error);

        if (error.status !== 403) {
          // navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    // get details affiliate payment
    if (auth?.role.includes(process.env.REACT_APP_CLIENT) || auth?.role.includes(process.env.REACT_APP_PARTENAIRE)) {
      getDetails(auth?.id);
    } else {
      getDetails(id);
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const columns = [
    { field: "id", headerName: "ID" },

    {
      field: "date",
      headerName: t(`label.date`),
      width: 180,
      type: "date",
      valueGetter: (params) => {
        return moment(params.row.date, "DD-MM-YYYY").format("DD/MM/YYYY");
      },
    },
    {
      field: "pnl",
      headerName: t(`label.NbPnl`),
      width: 200,
      valueGetter: (cellValues) => {
        return cellValues.row.pnl.length;
      },
    },
    {
      field: "total",
      headerName: t(`label.totalUsdt`),
      valueGetter: (params) => {
        return JSON.parse(params.row?.total).usdt;
      },
    },
    { field: "fees", headerName: t("label.transfertFees"), width: 200 },
    {
      field: "action",
      headerName: t(`label.action`),
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",
      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={"Détail"}
            onClick={(event) => {
              const dataToShow = cellValues.row.pnl;
              console.log("data", dataToShow);
              setSelectedRow(dataToShow);
              setIsOpen(true);
            }}
          />
        );
      },
    },
  ];

  const columnsDetails = [
    { field: "id", headerName: "ID", width: 200, hide: true },
    {
      field: "paidDate",
      headerName: t(`label.PaidDate`),
      width: 160,
      type: "date",
      valueGetter: (params) => {
        return moment(params.row.paidDate).format("DD/MM/YYYY");
      },
    },
    {
      field: "firstName",
      headerName: "Filleuls",
      width: 200,
      valueGetter: (params) => {
        return params.row.users.firstName + " " + params.row.users.lastName;
      },
    },

    { field: "paymentId", headerName: "payment Id", width: 200, hide: true },
    // { field: "status", headerName: "Statut", width: 200 },
    {
      field: "totalOnePercent",
      headerName: t(`label.amountUsdt`),
      width: 200,
      valueGetter: (params) => {
        return JSON.parse(params.row?.totalOnePercent).usdt;
      },
    },

    { field: "uid", headerName: "uid", width: 200, hide: true },
  ];

  return (
    <>
      <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />

      <div className="flex flex-row flex-grow">
        <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />

        <div className="flex flex-row flex-1 md:ml-52">
          <div className="flex flex-col w-full p-4 ">
            <div className="flex gap-x-2">
              <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.payment`)}</h1>

              <hr />
            </div>

            {/* Card Total payé */}
            <div className="flex justify-end gap-x-2 mb-2">
              <DashboardCard bg={"bg-indigo-600"} number={total && total} description={t("label.sumPaidAll")} />
            </div>

            <div>
              {allData && allData?.length === 0 ? <BannerInfo text={t(`info.noPayment`)} type="info" /> : null}
              {/* Tableau liste payement */}
              <div className="h-[80vh] bg-slate-100">{allData && allData ? <TableauGrid column={columns} dataRows={allData && allData} /> : <Loading />}</div>

              {/* tableau détaillé de payment */}
              <DialogBox
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                // title={"Détails"}
                customClassName={"md:w-1/2 w-full  min-h-[300px]"}
              >
                {selectedRow.length > 0 ? (
                  <>
                    <div className="h-[80vh] bg-slate-100 my-2 p-2 flex flex-col ">
                      <div className=" flex justify-between text-lg font-light">
                        <div className="  text-md font-light mb-3">
                          {t(`label.billDetail`)}
                          {/* id de la facture no avoaka eto zany */}
                          <span className="font-semibold ml-3">{selectedRow[0]?.paymentId}</span>
                        </div>

                        <div className="flex flex-wrap justify-end gap-x-2 my-2"></div>
                      </div>
                      {selectedRow && selectedRow ? <TableauGrid column={columnsDetails} dataRows={selectedRow && selectedRow} /> : null}
                    </div>
                  </>
                ) : null}
              </DialogBox>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientTransactionScreen;
