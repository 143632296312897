
import { ColumnDef, flexRender, getCoreRowModel, useReactTable, getPaginationRowModel, getSortedRowModel, SortingState, VisibilityState } from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Button } from "../ui/button"
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "../ui/dropdown-menu"

import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
} from "../../components/ui/pagination"


import React from "react";
import { useTranslation } from "react-i18next";
import PaginationComponent from "../PaginationComponent";
import { useMemo } from "react";

export function DataTable({ columns, data, setCurrentPage, currentPage, page }) {
    const { t } = useTranslation()
    const [sorting, setSorting] = React.useState([]);
    const [columnVisibility, setColumnVisibility] = React.useState([]);
    const dataMemo = useMemo(() => data, [data]);
    const columnsMemo = useMemo(() => columns, [columns]);
    const table = useReactTable({
        data: dataMemo,
        columns: columnsMemo,
        getCoreRowModel: getCoreRowModel(),
        //getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,

        state: {
            sorting,
            columnVisibility
        },
        //override default column sizing
        defaultColumn: {
            size: 200, //starting column size
            minSize: 50, //enforced during column resizing
            maxSize: 500, //enforced during column resizing
        },
    });

    const handlePageChange = (page) => {
        setCurrentPage(page);
        // Fetch new data based on the new page
    };


    return (
        <div>
            <div>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="outline" className="ml-auto my-2">
                            {t(`button.columns`)}
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        {table
                            .getAllColumns()
                            .filter(
                                (column) => column.getCanHide()
                            )
                            .map((column) => {
                                return (
                                    <DropdownMenuCheckboxItem
                                        key={column.id}
                                        className="capitalize"
                                        checked={column.getIsVisible()}
                                        onCheckedChange={(value) =>
                                            column.toggleVisibility(!!value)
                                        }
                                    >
                                        {column.id}
                                    </DropdownMenuCheckboxItem>
                                )
                            })}
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
            {React.createElement("div", { className: "rounded-md border" },
                React.createElement(Table, null,
                    React.createElement(TableHeader, null,
                        table.getHeaderGroups().map(headerGroup =>
                            React.createElement(TableRow, { key: headerGroup.id },
                                headerGroup.headers.map(header =>
                                    React.createElement(TableHead, { key: header.id, style: { width: header.column.columnDef.width } },
                                        header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())
                                    )
                                )
                            )
                        )
                    ),
                    React.createElement(TableBody, null,
                        table.getRowModel().rows && table.getRowModel().rows.length ?
                            table.getRowModel().rows.map(row =>
                                React.createElement(TableRow, {
                                    key: row.id,
                                    "data-state": row.getIsSelected() ? "selected" : null
                                },
                                    row.getVisibleCells().map(cell =>
                                        React.createElement(TableCell, { key: cell.id, style: { width: cell.width } },
                                            flexRender(cell.column.columnDef.cell, cell.getContext())
                                        )
                                    )
                                )
                            ) :
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { colSpan: columns.length, className: "h-24 text-center" }, `${t(`label.noResult`)}`)
                            )
                    )
                )
            )}
            {/* <div className="flex items-center justify-end space-x-2 py-4">
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    Previous
                </Button>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => { table.nextPage(); setCurrentPage(2) }}
                    disabled={!table.getCanNextPage()}
                >
                    Next
                </Button>
            </div> */}
            {page && <PaginationComponent totalItems={100} itemsPerPage={10} currentPage={currentPage} onPageChange={handlePageChange} />}


        </div>
    );
}
