import { PencilAltIcon, PlusCircleIcon, ShieldCheckIcon, EyeIcon, IdentificationIcon } from '@heroicons/react/outline';
import { DataGrid, frFR, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StopSubscription from '../components/admin/StopSubscription';
import Controls from '../components/controls/Controls';
import IconHoverText from '../components/IconHoverText';
import Loading from '../components/Loading';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import * as service from "../utils/service";
import DialogBox from '../components/DialogBox';
import { PhoneIcon, ServerIcon } from 'lucide-react';
import { FingerPrintIcon } from '@heroicons/react/solid';
import CardHistory from '../components/Planit/CardHistory';
import CreateTicket from '../components/admin/CreateTicket';
import { Badge } from '../components/ui/badge';

const ComponentChip = ({ params, t }) => {
    if (params.value === t("uStatus.15")) {
        return <span className="rounded-full border-2 border-amber-500 w-fit text-center px-1 text-amber-500 text-xs ">{t("uStatus.15")}</span>;
    }
};

function WaittingStopScreen() {
    const [users, setUsers] = useState()
    const { t } = useTranslation()
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate();
    const [ticketIsOpen, setTicketIsOpen] = useState(false);
    const [cardUser, setCardUser] = useState([]);
    const [openTicketModal, setOpenTicketModal] = useState(false);
    const [uidNewTicket, setUidNewTicket] = useState();
    const [openStopModal, setOpenStopModal] = useState(false);
    const [dataUser, setDataUser] = useState();


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/users/userslist?status=15`, {
                    signal: controller.signal,
                });
                // const decryptAllUsers = JSON.parse(service.decryptCBData(data.all));

                const formatData = data.map((resp) => ({
                    ...resp,
                    firstName: service.decryptCBData(resp.firstName),
                    lastName: service.decryptCBData(resp.lastName),
                    email: service.decryptCBData(resp.email),
                }));
                isMounted && setUsers(formatData);

                console.log("USER Decrypt", data);
                console.log("USER 1234559", formatData);
            } catch (error) {
                console.log(error);

            }
        };

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const openUserTicket = async (uid) => {
        setTicketIsOpen(true)

        try {
            const { data } = await axiosPrivate.get(`/api/planit/card/list/${uid}`)
            console.log("Ticket de", uid, data)
            setCardUser(data)
            console.log(cardUser)
        } catch (error) {
            console.log(error)
        }
    }

    const columnSuperAdmin = [
        { field: "id", headerName: "ID", type: "number", headerClassName: "dark:text-slate-100" },

        {
            field: "strategy", headerName: t("label.strategy"), headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                if (cellValues.row.strategy == "ai") {
                    return (
                        <div className='flex justify-center  w-full'>
                            <Badge variant="ai">{t(`strategy.ai`)}</Badge>
                        </div>
                    )
                }
                return (
                    <div className='flex justify-center  w-full'>
                        <Badge variant="normal">{t(`strategy.cbdt`)}</Badge>
                    </div>
                )
            }
        },
        {
            field: "platform", headerName: t("label.platform"), headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                if (cellValues.row.platform == 1) {
                    return (
                        <div className='flex justify-center  w-full'>
                            <Badge variant="binance">Binance</Badge>
                        </div>
                    )
                }
                return (
                    <div className='flex justify-center  w-full'>
                        <Badge variant="kraken">Kraken</Badge>
                    </div>
                )
            }
        },
        {
            field: "firstName",
            headerName: t("label.firstName"),
            headerClassName: "dark:text-slate-100",
            width: 200,
            renderCell: (cellValues) => {
                return (
                    `${cellValues.row.firstName} ${cellValues.row.lastName}`
                )
            }
        },
        { field: "email", headerName: t("label.email"), width: 200, headerClassName: "dark:text-slate-100" },

        {
            field: "status",
            headerName: t("label.status"),
            width: 150,
            headerClassName: "dark:text-slate-100",

            valueGetter: (params) => {
                let parameter = params.value;
                let cell = "";
                if (parameter == 15) {
                    cell = t("uStatus.15");
                } else {
                    cell = "Check";
                }
                return cell;
            },
            renderCell: (params) => {
                return <ComponentChip params={params} t={t} />;
            },
        },
        {
            field: "clients",
            headerName: "Clients",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100 ",
            width: 150,

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 ">
                        {/* Vue Clients */}
                        <IconHoverText
                            icon={<EyeIcon />}
                            classIcon={"w-5 h-5 text-indigo-500 hover:bg-indigo-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Vue Clients"}
                            onClick={(event) => {
                                navigate(`/dashboard/${cellValues.row.id}`);
                            }}
                        />
                        {/* Modifier client */}
                        <IconHoverText
                            icon={<PencilAltIcon />}
                            classIcon={"w-5 h-5 text-purple-500 hover:bg-purple-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Modifier"}
                            onClick={(event) => {
                                navigate(`/support/user/${cellValues.row.id}`);
                            }}
                        />
                        {/* Sécurisation */}
                        <IconHoverText
                            icon={<ShieldCheckIcon />}
                            classIcon={"w-5 h-5 text-emerald-500 hover:bg-emerald-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Sécurisation"}
                            onClick={(event) => {
                                navigate(`/support/secure/${cellValues.row.id}`, {
                                    state: {
                                        fullName: `${cellValues.row.firstName} ${cellValues.row.lastName} `,
                                        email: cellValues.row.email,
                                        status: cellValues.row.status,
                                    },
                                });
                            }}
                        />
                    </div>
                );
            },
        },
        // icone tickets
        {
            field: "tickets",
            headerName: t(`label.tickets`),
            width: 80,
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 ">
                        <IconHoverText
                            icon={<EyeIcon />}
                            classIcon={"w-5 h-5 text-indigo-500 hover:bg-indigo-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"voir "}
                            onClick={(event) => {
                                openUserTicket(cellValues.row.id);
                            }}
                        />
                        <IconHoverText
                            icon={<PlusCircleIcon />}
                            classIcon={"w-5 h-5 text-emerald-500 hover:bg-emerald-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"créer "}
                            onClick={(event) => {
                                setOpenTicketModal(true);
                                setUidNewTicket(cellValues.row.id);

                            }}
                        />

                    </div>
                );
            },
        },
     
        {
            field: "stopSubsiption",
            headerName: "Stop Client",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-red-500 dark:text-slate-100 hover:text-secondary"}
                        text={"Stop"}
                        onClick={(event) => {
                            setOpenStopModal(true);
                            setUidNewTicket(cellValues.row.id);
                            setDataUser(cellValues.row)
                            localStorage.setItem("userStatus", cellValues.row.status)

                        }}
                    />
                );
            },
        },
    ];

    return (
        <div className="p-4  gap-3 h-[93vh]">
            <StopSubscription openStopModal={openStopModal} setOpenStopModal={setOpenStopModal} uid={uidNewTicket} t={t} user={dataUser && dataUser} />
            <div className=" bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 h-full ">
                <h3 className="font-bold text-xl text-slate-700 dark:text-slate-100">{t(`button.waitStop`)}</h3>



                <div className="h-[450px] bg-slate-100 dark:bg-slate-800 mt-2">
                    {users ? (
                        <DataGrid
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: "id", sort: "desc" }],
                                },
                            }}
                            rows={users}
                            columns={columnSuperAdmin}
                            onCellClick={(value) => console.log("yes", value.id)}
                            disableColumnSelector
                            disableDensitySelector
                            getRowClassName={(params) => `text-slate-800 dark:text-slate-100`}
                            density="compact"
                            className="text-red-500"
                            autoPageSize
                        />
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>

            {/* List ticket for oneUser */}
            <DialogBox isOpen={ticketIsOpen} setIsOpen={setTicketIsOpen}>
                {cardUser[0] ?
                    <>

                        <div className="flex flex-col text-sm mb-4 ml-2 ">
                            <div className="flex items-center space-x-1 text-slate-600">
                                <div><ServerIcon className="w-5 h-5 " /></div>
                                <div className="font-semibold text-slate-600">
                                    {service.decryptCBData(cardUser[0]?.u?.firstName)} {service?.decryptCBData(cardUser[0]?.u?.lastName)}
                                </div>
                            </div>

                            <div className="flex items-center space-x-1 text-slate-600">
                                <div> <IdentificationIcon className="w-4 h-4" /></div>
                                <div> {service.decryptCBData(cardUser[0]?.u?.email)}</div>
                            </div>

                            <div className="flex items-center space-x-1 text-slate-600">
                                <div><PhoneIcon className="w-4 h-4" /></div>
                                <div> {service.decryptCBData(cardUser[0]?.u?.tel)}</div>
                            </div>
                            <div className="flex items-center space-x-1 text-slate-600">
                                <div><FingerPrintIcon className="w-4 h-4 " /></div>
                                <div className="truncate  text-xs">{cardUser[0]?.uid}</div>
                            </div>
                        </div>

                        {cardUser?.map((c, i) => (
                            <div className="my-2">
                                <CardHistory card={c} key={i} />
                            </div>
                        ))}
                    </>
                    :
                    <div className=" m-4 text-xs font-light">
                        Pas de résultat ...
                    </div>
                }


            </DialogBox>

            {/* create tickets for user */}
            <CreateTicket openTicketModal={openTicketModal} setOpenTicketModal={setOpenTicketModal} uid={uidNewTicket} t={t} />

        </div>
    );
}

export default WaittingStopScreen
