import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as services from "../utils/service.js";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL}/api/`,
  prepareHeaders: (headers, { getState }) => {
    const token = services.decryptCB(localStorage.getItem("token"));

    console.log("volony", token); // Replace with your own method to retrieve the auth token
    if (token) {
      headers.set("x-auth-token", token);
    }
    return headers;
  },
});

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery,
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => "reporting/findAllUsers",
    }),
  }),
});
export const { useGetAllUsersQuery } = userApi;

//  headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//     "x-auth-token": services.decryptCB(localStorage.getItem("token")),
//   },
