import { CheckCircleIcon } from '@heroicons/react/outline';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { authSelector } from '../features/authSlice';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { decryptCB, encryptCB, specificKeyCode } from '../utils/service';
import Controls from './controls/Controls';
import { useFOrm } from './useFOrm';
import axios, { axiosBQ } from '../axios';


const initialFvalues = {
    apiKeyRo: "",
    secureKeyRo: "",
    apiKeyTransfer: "",
    secureKeyTransfer: "",
    apiKeyTrading: "",
    secureKeyTrading: "",
    source_income: "",
    otherSourceOfIncome: "",
    // isSousCompte: false,
};

function ManageSubApi(props) {
    const { id } = props
    const { t } = useTranslation()
    const auth = useSelector(authSelector);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [play, setPlay] = useState("");
    const [testApi, setTestApi] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isClicked, setIsClicked] = useState("");




    const axiosPrivate = useAxiosPrivate()

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const checkApiUpdate = async () => {
            try {
                setIsPageLoading(true);
                const { data } = await axiosPrivate.get(`/api/manage/check?reference=${id}`, { signal: controller.signal });
                if (isMounted) {
                    switch (true) {
                        case data.updateApi == "ON":
                            setPlay("ON");
                            setTestApi(JSON.parse(data.testApi));
                            setIsPageLoading(false);
                            break;
                        default:
                            break;
                    }
                }
                setIsPageLoading(false);
                // console.log("Play ++++", data);
            } catch (error) {
                setIsPageLoading(false);
                console.log(error, "Error Api Update");
            }
        };
        checkApiUpdate();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const validate = (fieldValues = values) => {
        const temp = { ...errors };

        setErrors({
            ...temp,
        });

        if (fieldValues == values) {
            return Object.values(temp).every((x) => x == "");
        }
    };

    const decryptDataAPI = ["apiKeyRo", "apiKeyTransfer", "apiKeyTrading"];
    const decryptData = ["secureKeyRo", "secureKeyTransfer", "secureKeyTrading"];

    // initialFvalues.apiKeyRo = decryptCB(data?.apiKeyRo);
    // initialFvalues.apiKeyTransfer = decryptCB(data?.apiKeyTransfer);
    // initialFvalues.apiKeyTrading = decryptCB(data?.apiKeyTrading);
    // initialFvalues.isSousCompte = data.isSousCompte;
    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

    const formKeysRo = [
        {
            type: "text",
            name: "apiKeyRo",
            label: t("label.apiKeyRo"),
            placeholder: t("label.apiKeyRo"),
        },
        {
            type: "text",
            name: "secureKeyRo",
            label: t("label.secureKeyRo"),
            placeholder: t("label.secureKeyRo"),
        },
    ];

    const formKeysTransfer = [
        {
            type: "text",
            name: "apiKeyTransfer",
            label: t("label.apiKeyTransfer"),
            placeholder: t("label.apiKeyTransfer"),
        },
        {
            type: "text",
            name: "secureKeyTransfer",
            label: t("label.secureKeyTransfer"),
            placeholder: t("label.secureKeyTransfer"),
        },
    ];

    const formKeysTrading = [
        {
            type: "text",
            name: "apiKeyTrading",
            label: t("label.apiKeyTrading"),
            placeholder: t("label.apiKeyTrading"),
        },
        {
            type: "text",
            name: "secureKeyTrading",
            label: t("label.secureKeyTrading"),
            placeholder: t("label.secureKeyTrading"),
        },
    ];

    const sendHistoryToBigQuery = async (key) => {
        let dataToPost = {
            button: "save",
            location: window.location.pathname,
            by: auth?.id,
            role: Number(auth?.role[0]),
            action: `setting API ${key} for subAccount `,
            who: id,
        }
        const response = await axiosBQ.post(`${process.env.REACT_APP_BASE_URL_BQ}/action-logs/insert`, {
        // const response = await axios.post('https://bg.flow-corporation.space/history/action-logs/insert', {
          dataToPost,
        });
        console.log("Send History", dataToPost);

    }

    const handleSaveApi = async (type) => {
        setIsLoading(true)
        try {
            let dataToUpdate;
            switch (type) {
                case "RO":
                    dataToUpdate = {
                        // uid: auth.id,
                        apiKeyRo: encryptCB(values.apiKeyRo),
                        secureKeyRo: encryptCB(values.secureKeyRo),
                    };
                    break;

                case "T":
                    dataToUpdate = {
                        // uid: auth.id,
                        apiKeyTrading: encryptCB(values.apiKeyTrading),
                        secureKeyTrading: encryptCB(values.secureKeyTrading),
                    };
                    break;

                case "W":
                    dataToUpdate = {
                        // uid: auth.id,
                        apiKeyTransfer: encryptCB(values.apiKeyTransfer),
                        secureKeyTransfer: encryptCB(values.secureKeyTransfer),
                    };
                    break;

                default:
                    break;
            }

            await axiosPrivate.put(`/api/manage/updateSubAccountApi?type=${type}&reference=${id}`,
                dataToUpdate)
            setIsLoading(false)
            toast.success("OK")
            sendHistoryToBigQuery(type)
            console.log("API");
        } catch (error) {
            setIsLoading(false)
            console.log("error", error);
            toast.error(error.response?.data.msg);

        }
        setIsLoading(false)

    }

    return (
        <div className="h-auto bg-slate-100 dark:bg-slate-800 p-2 pb-4">

            {play && testApi?.ro && testApi?.w && testApi?.t ?
                <div className="flex flex-col  space-y-6 px-4 text-slate-600 rounded-lg drop-shadow-md pt-4 mx-auto items-center">
                    <CheckCircleIcon className="w-40 h-40 text-green-300" />
                    <div className="font-semibold text-slate-500">Votre API semble correcte, veuillez contacter nos supports pour configurer votre API</div>
                </div>
                : play && testApi?.ro == false || testApi?.w == false || testApi?.t == false ?
                    <div>

                        {/* PLAY RO */}
                        {play && testApi?.ro == false &&
                            <div className={`space-y-3 px-4 rounded-lg drop-shadow-md pt-2`}>
                                <div className="flex gap-x-2 ">
                                    <h6 className="flex-none text-lg text-slate-600 font-light">
                                        <strong>API RO </strong> ({t(`label.readOnly`)})
                                    </h6>
                                    {/* {testApiRo ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />} */}
                                </div>

                                <div className="space-y-2 md:flex md:space-x-4 md:space-y-0 ">
                                    {formKeysRo.map((item, i) => {
                                        return (
                                            <>
                                                <Controls.Input
                                                    fullWidth={true}
                                                    key={i}
                                                    // disabled={disabled}
                                                    readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                                    label={item.label}
                                                    value={values[item.name] || ""}
                                                    onChange={handleInputChange}
                                                    name={item.name}
                                                    type={item.type}
                                                    onKeyDown={(event) => {
                                                        specificKeyCode(event);
                                                    }}
                                                    error={errors[item.name]}
                                                />
                                            </>
                                        );
                                    })}

                                    <Controls.Button
                                        // enable after ok du support
                                        disabled={values.apiKeyRo && values.secureKeyRo ? false : true}
                                        text={t(`button.save`)}
                                        xsFont
                                        onClick={() => handleSaveApi("RO")}
                                        isLoading={isLoading}
                                    />
                                </div>

                                {/* <div className="text-xs text-red-500">{errorApiRo && errorApiRo}</div> */}
                                <div className="h-[0.5px] w-full bg-slate-200 mx-auto" />
                            </div>
                        }


                        {/* PLAY TRANSFER W*/}
                        {play && testApi?.w == false &&
                            <div className={`space-y-3 px-4 rounded-lg drop-shadow-md pt-4`}>
                                <div className="flex gap-x-2 ">
                                    <h6 className="flex-none text-lg text-slate-600 font-light">
                                        <strong>API W </strong> ({t(`label.transfer`)})
                                    </h6>{" "}
                                </div>

                                <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
                                    {formKeysTransfer.map((item, i) => {
                                        return (
                                            <>
                                                <Controls.Input
                                                    fullWidth={true}
                                                    key={i}
                                                    // disabled={disabled}
                                                    readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                                    label={item.label}
                                                    value={values[item.name] || ""}
                                                    onChange={handleInputChange}
                                                    name={item.name}
                                                    type={item.type}
                                                    onKeyDown={(event) => {
                                                        specificKeyCode(event);
                                                    }}
                                                    error={errors[item.name]}
                                                />
                                            </>
                                        );
                                    })}
                                    <Controls.Button
                                        // enable after ok du support
                                        disabled={values.apiKeyTransfer && values.secureKeyTransfer ? false : true}
                                        // disabled={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                        text={t(`button.save`)}
                                        xsFont
                                        onClick={() => handleSaveApi("W")}
                                        isLoading={isLoading}
                                    />
                                </div>
                                {/* <div className="flex gap-x-3">
                    <div className="text-xs text-red-400">{errorApiW && errorApiW}</div>
                </div> */}
                                <div className="h-[0.5px] w-full bg-slate-200 mx-auto" />
                            </div>}

                        {/* PLAY TRADING T */}
                        {play && testApi?.t == false &&
                            <div className={`space-y-3 px-4 rounded-lg drop-shadow-md pt-4`}>
                                <div className="flex gap-x-2 ">
                                    <h6 className="flex-none text-lg text-slate-600 font-light">
                                        <strong>API T </strong> ({t(`label.trading`)})
                                    </h6>{" "}
                                    {/* {testApiT ? <CheckCircleIcon className="h-4 w-4 text-green-500" /> : <XCircleIcon className="h-4 w-4 text-red-500" />} */}
                                </div>

                                <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
                                    {formKeysTrading.map((item, i) => {
                                        return (
                                            <>
                                                <Controls.Input
                                                    fullWidth={true}
                                                    key={i}
                                                    // disabled={disabled}
                                                    readOnly={auth.role.includes(process.env.REACT_APP_ADMIN) ? true : false}
                                                    label={item.label}
                                                    value={values[item.name] || ""}
                                                    onChange={handleInputChange}
                                                    name={item.name}
                                                    type={item.type}
                                                    onKeyDown={(event) => {
                                                        specificKeyCode(event);
                                                    }}
                                                    error={errors[item.name]}
                                                />
                                            </>
                                        );
                                    })}
                                    <Controls.Button
                                        // enable after ok du support
                                        disabled={values.apiKeyTrading && values.secureKeyTrading ? false : true}
                                        text={t(`button.save`)}
                                        xsFont
                                        onClick={() => handleSaveApi("T")}
                                        isLoading={isLoading}
                                    />
                                </div>
                                {/* <div className="flex gap-x-3">
                    <div className="text-xs text-red-500"> {errorApiT && errorApiT}</div>
                </div> */}
                            </div>
                        }

                    </div>
                    :
                    <div className={`flex flex-col  space-y-3 px-4 text-slate-600 rounded-lg drop-shadow-md pt-4`}>
                        Pour configurer les paramètres de votre API, veuillez prendre un rendez-vous avec l'un de nos supports afin qu'ils puissent activer la configuration de votre API .
                    </div>
            }



        </div>
    )
}

export default ManageSubApi
