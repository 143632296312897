import { TextField, OutlinedInput } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from "@mui/material";

const ValidationTextField = styled(TextField)({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.6)", // (default alpha is 0.38)
    },
  },

  "& .Mui-disabled input ": {
    color: "red!important",
  },

  /*'& .Mui-disabled input': {
        borderColor: 'red',
        borderWidth: 2,
        background: '#f4f4f4',
        color: 'black !important'
    }*/
});

export default function Input(props) {
  const { name, label, value, type, error = null, onChange, fullWidth, readOnly, onKeyDown, max, min } = props;
  return (
    <>
      {/* <TextField
                size="small"
                disabled={readOnly}
                fullWidth={fullWidth}
                variant='outlined'
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                type={type}
                onKeyDown={onKeyDown}
                inputProps={

                    { readOnly: readOnly }
                }
                className="dark:text-white"
                {...(error && { error: true, helperText: error })}
            /> */}

      <FormControl variant="outlined" sx={{ width: "100%" }} {...(error && { error: true })} size="small">
        {type == "date" || type === "datetime-local" ? (
          <InputLabel className="dark:text-white" shrink>
            {label}
          </InputLabel>
        ) : (
          <InputLabel className="dark:text-white">{label}</InputLabel>
        )}

        <OutlinedInput
          size="small"
          disabled={readOnly}
          fullWidth={fullWidth}
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          onKeyDown={onKeyDown}
          inputProps={{ readOnly: readOnly, max: max, min: min }}
          className="dark:text-white dark:bg-slate-800"
        />
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </>
  );
}
