import React from 'react'
import { useTranslation } from 'react-i18next'

function EmailsDetails() {
    const { t } = useTranslation()
    const Detail = ({ title, desc }) => (
        <div>
            <div className='font-bold'> {title} </div>
            <div>{desc}</div>
            <br />
        </div>
    )

    const details = t(`infoEmail`, { returnObjects: true })
    console.log("email", details);



    return (
        <div className='text-sm py-2 px-4 text-justify'>
            <div className='font-bold text-center pb-6 text-lg'>{t(`label.syntheseDetails`)}</div>
            {details.map((d) => (
                <Detail title={d.title} desc={d.desc} />
            ))}
        </div>
    )
}

export default EmailsDetails
