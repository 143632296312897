import React, { useState, useEffect, useRef, PureComponent } from "react";
import Sidebar from "../components/Sidebar";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import moment from "moment";
import Loading from "../components/Loading";
import DialogBox from "../components/DialogBox";
import "moment/locale/fr";
import { UserGroupIcon } from "@heroicons/react/solid";
import DashboradCard from "../components/cards/DashboardCard";
//import { w3cwebsocket as W3CWebSocket } from "websocket";
import Title from "../components/title/Title";

import TopBar from "../components/TopBar";
import { Icon } from "coinmarketcap-cryptocurrency-icons";
import * as sideMenus from "../utils/sideMenus";
import PieChartCustom from "../components/chart/PieChartCustom";
import BarChartStackedBySign from "../components/chart/BarChartStackedBySign";
import TableauGrid from "../components/TableauGrid";

moment.locale("fr");

const Myth = ({ text }) => <th className="p-3 text-sm font-semibold tracking-wide text-left"> {text}</th>;

const Mytd = ({ text }) => <td className="p-3 text-sm text-gray-700 whitespace-nowrap"> {text}</td>;
function Dashboard(props) {
  const { t } = props;
  const [price, setPrice] = useState(0);
  const [users, setUsers] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [capital, setCapital] = useState();
  const [ticket, setTicket] = useState();
  const [capitalManaged, setCapitalManaged] = useState();
  const [pnl, setPnl] = useState();
  const [affiliate, setAffiliate] = useState();
  const client = useRef(null);
  const prevCountRef = useRef();
  const axiosPrivate = useAxiosPrivate();
  const endPoint = `wss://stream.binance.com:9443/ws/eurusdt@trade`;

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(`/api/reporting/findAllUsers`, {
          signal: controller.signal,
        });
        isMounted && setUsers(response.data);
        console.log("USERS", response.data);
      } catch (error) {
        console.log(error);
        //navigate('/login', { state: { from: location }, replace: true })
      }
    };
    const getTickets = async () => {
      try {
        const response = await axiosPrivate.get(`/api/analytics/ticket`, {
          signal: controller.signal,
        });
        isMounted && setTicket(response.data);
        console.log("TICKET", response.data);
      } catch (error) {
        console.log(error);
        //navigate('/login', { state: { from: location }, replace: true })
      }
    };

    const getCapital = async () => {
      try {
        const response = await axiosPrivate.get(`/api/reporting/findCurrentCapital`, {
          signal: controller.signal,
        });
        console.log("K=====", response.data);
        isMounted && setCapital(response.data);
      } catch (error) {
        console.log(error);
        //navigate('/login', { state: { from: location }, replace: true })
      }
    };

    const getCapitalManaged = async () => {
      try {
        const response = await axiosPrivate.get(`/api/reporting/findCurrentCapitalManaged`, {
          signal: controller.signal,
        });
        isMounted && setCapitalManaged(response.data);
      } catch (error) {
        //console.log(error)
        //navigate('/login', { state: { from: location }, replace: true })
      }
    };

    const getPnl = async () => {
      try {
        const response = await axiosPrivate.get(`/api/reporting/findAllPnl`, {
          signal: controller.signal,
        });
        isMounted && setPnl(response.data);
      } catch (error) {
        console.log(error);
        //navigate('/login', { state: { from: location }, replace: true })
      }
    };

    const getAffiliate = async () => {
      try {
        const response = await axiosPrivate.get(`/api/reporting/findAllAffiliate`, {
          signal: controller.signal,
        });
        isMounted && setAffiliate(response.data);
      } catch (error) {
        console.log(error);
        //navigate('/login', { state: { from: location }, replace: true })
      }
    };

    getPnl();
    getTickets();
    getCapitalManaged();

    getUsers();
    getCapital();
    getAffiliate();

    //clean up function
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const columnsMotifLate = [
    {
      field: "id",
      headerName: "id",
      hide: true,
    },
    {
      field: "motif",
      headerName: "Motifs",
      flex: 1,
    },
    {
      field: "number",
      headerName: "Nombre",
    },
  ];

  const columnsMotifTicket = [
    {
      field: "id",
      headerName: "id",
      hide: true,
    },
    {
      field: "motif",
      headerName: "Motifs",
      flex: 1,
    },
    {
      field: "number",
      headerName: "Nombre",
    },
  ];

  return (
    <>
      <TopBar menus={sideMenus.menusAdmin(t)} />

      <div className="flex flex-row flex-grow ">
        <Sidebar menus={sideMenus.menusAdmin(t)} />
        <div className="flex flex-row flex-1  mt-2 md:ml-52">
          {/* <div className='flex flex-col w-full md:w-4/5 p-4'> */}
          <div className="flex flex-col w-full px-2">
            {/* Title section */}
            <div className="flex-none h-auto md:p-4 space-y-3">
              <h1 className="font-semibold text-2xl text-slate-900 dark:text-white">{t("label.dashboard")}</h1>
              <span className="text-xs text-slate-500 dark:text-slate-400 ">{moment().format("Do MMMM YYYY")}</span>

              <hr />
            </div>

            <div className="flex w-auto space-x-2 ">
              {/*Générales*/}
              <div className="flex-1  ">
                <div className="flex space-x-2 mb-2">
                  {/* Open & Close ticket */}
                  <div className=" bg-slate-50  w-1/3">
                    <div className="pl-2 pt-2">
                      <Title.SectionTitle text={"Ticket "} icon={<UserGroupIcon className="h-5 w-5 text-blue-700 text-xs" />} />
                    </div>
                    <div className=" ">
                      <PieChartCustom
                        data={[
                          { name: "Ouvert", value: ticket && ticket.finalDataByOpenClose.true },
                          { name: "Fermé", value: ticket && ticket.finalDataByOpenClose.false },
                        ]}
                        colors={["#22c55e", "#f97316", "#0284c7", "#facc15"]}
                        //colors={["#0284c7", "#0ea5e9", "#1d4ed8", "#1e3a8a"]}
                        width={300}
                        height={300}
                      />
                    </div>
                  </div>

                  {/* New ticket/day */}
                  <div className="bg-slate-50  w-2/3">
                    <div className="pl-2 pt-2">
                      <Title.SectionTitle
                        text={"Nouveaux tickets "}
                        // unit={`(${users && users.all.length})`}
                        icon={<UserGroupIcon className="h-5 w-5 text-blue-700 text-xs" />}
                      />
                    </div>
                    <div className=" ">
                      <BarChartStackedBySign
                        data={ticket && ticket.finalDataByDate}
                        colors={["#0284c7", "#0ea5e9", "#1d4ed8", "#1e3a8a"]}
                        keyLength={["date", "new"]}
                        width={300}
                        height={300}
                        name={["Nouveau", "Résilié"]}
                      />
                    </div>
                  </div>
                </div>
                {/* Motif Ticket */}
                <div className="flex space-x-2 mb-2 ">
                  <div className="md:flex-row gap-x-2 gap-y-2  w-full bg-slate-50">
                    <div className="pl-2 pt-2 ">
                      <Title.SectionTitle
                        text={"Motif Ticket "}
                        // unit={`(${users && users.all.length})`}
                        icon={<UserGroupIcon className="h-5 w-5 text-blue-700 text-xs" />}
                      />
                    </div>
                    <div className="h-64 p-2">{ticket && ticket ? <TableauGrid column={columnsMotifTicket} idRows={"id"} dataRows={ticket && ticket.finalDataByMotif} /> : <Loading />}</div>
                  </div>
                </div>
                {/* Ticket Late */}

                <div className="flex space-x-2 mb-2 ">
                  <div className="md:flex-row gap-x-2 gap-y-2  w-1/3 bg-slate-50">
                    <div className="pl-2 pt-2">
                      <Title.SectionTitle
                        text={"Tickets en retards "}
                        // unit={`(${users && users.all.length})`}
                        icon={<UserGroupIcon className="h-5 w-5 text-blue-700 text-xs" />}
                      />
                    </div>
                    <div className=" ">
                      <BarChartStackedBySign
                        data={ticket && ticket.finalDataFollowUpByLate}
                        width={300}
                        height={300}
                        keyLength={["nbLate", "number"]}
                        name={["Nouveau", "Résilié"]}
                        colors={["#3b82f6", "#f87171"]}
                      />
                    </div>
                  </div>
                  {/* Table Motif Retards */}
                  <div className="md:flex-row gap-x-2 gap-y-2  w-2/3 bg-slate-50">
                    <div className="pl-2 pt-2">
                      <Title.SectionTitle
                        text={"Motif Retard "}
                        // unit={`(${users && users.all.length})`}
                        icon={<UserGroupIcon className="h-5 w-5 text-blue-700 text-xs" />}
                      />
                    </div>
                    <div className="h-64 p-2">{ticket && ticket ? <TableauGrid column={columnsMotifLate} idRows={"id"} dataRows={ticket && ticket.finalDataFollowUpLateByMotif} /> : <Loading />}</div>
                  </div>
                </div>
              </div>
              {/* Objectifs */}
              <div className="bg-slate-50 w-[250px] py-4 h-fit">
                <h1 className="font-semibold text-center text-2xl  text-slate-900 dark:text-white">
                  Objectifs <p className="text-xs">(2022)</p>
                </h1>
                <div>
                  <div className="bg-slate-50 dark:bg-slate-800 p-4 rounded-sm ">
                    <Title.SectionTitle
                      text={"Nb clients"}
                      // unit={`(${users && users.all.length})`}
                      icon={<UserGroupIcon className="h-5 w-5 text-blue-700 text-xs" />}
                    />
                  </div>
                  <div className=" ">
                    <PieChartCustom
                      data={[
                        { name: "Ouvert", value: ticket && ticket.finalDataByOpenClose.true },
                        { name: "Fermé", value: ticket && ticket.finalDataByOpenClose.false },
                      ]}
                      colors={["#22c55e", "#f97316", "#0284c7", "#facc15"]}
                      //colors={["#0284c7", "#0ea5e9", "#1d4ed8", "#1e3a8a"]}
                      width={300}
                      height={300}
                      startAngle={180}
                      endAngle={0}
                      noLegend
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
