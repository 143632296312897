import React from "react";
import EquipeCB from "../components/EquipeCB";
import TopBar from "../components/TopBar";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import { authSelector } from "../features/authSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectPlanitCardToday } from "../features/planitSlice";
function ContactUsScreen(props) {
    const { t } = props;
    const { id } = useParams(); // maka
    const auth = useSelector(authSelector);
    const todayCard = useSelector(selectPlanitCardToday)


    return (
        <div className="bg-cover bg-center bg-no-repeat" style={{ backgroundImage: 'url("../asset/bgblue.jpeg")' }}>
            <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />
            <div className="flex flex-row ">
                <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />
                <div className="flex-1 max-w-[100%]">
                    <div className={`flex flex-col gap-y-4 ${auth.role.includes(process.env.REACT_APP_SUPADMIN) ? null : "md:ml-52"}  p-4`}>
                        <div className="flex gap-x-2">
                            <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.contactUs`)}</h1>
                            <hr />
                        </div>

                        <div className="text-xs flex flex-col space-y-1 items-center rounded bg-slate-100 p-6">
                            <div className="font-semibold">{t(`label.aQuestion`)} ?</div>
                            <div>

                                {t(`label.contactByEmail`)} <a href="mailto:support@crypto-bulot.com">support@crypto-bulot.com</a>
                            </div>
                            <div>
                                {t(`label.youCanBookMe`)}
                            </div>
                        </div>
                        <div className="flex items-center ">
                                <iframe
                                    src="https://cryptobulot-1.youcanbook.me/?noframe=true&skipHeaderFooter=true"
                                    id="ycbmiframecryptobulot-1"
                                    style={{ width: "100%", height: "1000px", border: "0px", backgroundColor: "transparent" }}
                                    frameBorder="0"
                                    allowtransparency="true"
                                ></iframe>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUsScreen;
