import React from 'react'
import LanguageSwitcher from '../LanguageSwitcher'

function Lang() {
    return (
        <div>
            <LanguageSwitcher />
        </div>
    )
}

export default Lang
