import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Controls from "../components/controls/Controls";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../features/authSlice";
import ExpressRebalancing from "./ExpressRebalancing";
import DialogBox from "./DialogBox";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { setZap, zapSelector } from "../features/zapSlice";
import * as service from "../utils/service";


function UserSecurisationInfo(props) {
    const { t } = props;
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const auth = useSelector(authSelector);
    const [seuilLimit, setSeuilLimit] = useState(10000)
    const [capitalActif, setCapitalActif] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [hasRebal, sethasRebal] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [totalInvested, setTotalInvested] = useState(0);
    const [userData, setUserData] = useState();
    const [userDetails, setUserDetails] = useState();
    const dispacth = useDispatch();
    const oneActiveZap = useSelector(zapSelector);
    console.log("oneActiveZap", oneActiveZap);

    const navigate = useNavigate();
    const [summaryRebal, setSummaryRebal] = useState({
        startDate: "DD/MM/YYYY",
        endDate: "DD/MM/YYYY",
        endCapitalRebal: "0.00",
        startCapital: "0.00",
        recurringAmount: "0.00",
    });

    const { id } = useParams();
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        dispacth(setZap(null))
        const getCurrentActifCapital = async (myid) => {
            try {
                const response = await axiosPrivate.get(`/api/zap/currentActifCapital/${myid}`, {
                    signal: controller.signal,
                });
                if (response.data) {
                    // console.log("capital Actif", response.data);
                    //const dataArraY = plans.filter((p) => p.id === response.data.type);
                    isMounted && setCapitalActif(response.data.capitalActif);
                    isMounted && setUserData(response.data.userdata);
                }
            } catch (error) { }
        };

        const getTotalInvested = async (myid) => {
            try {
                const response = await axiosPrivate.get(`/api/users/totalInvested/${myid}`, {
                    signal: controller.signal,
                });
                // if (response.data) {
                //   console.log("Total Invested", response.data.totalInvested);
                // }
                const seuil = response.data.totalInvested;
                setTotalInvested(seuil);
            } catch (error) { }
        };

        const getZap = async (myid) => {
            try {
                const response = await axiosPrivate.get(`/api/zap/active/${myid}`, {
                    signal: controller.signal,
                });
                //set methode secure based on db data
                if (response.data) {
                    //const dataArraY = plans.filter((p) => p.id === response.data.type);
                    isMounted && dispacth(setZap(response.data));
                }
                console.log("test ppppp", response);
            } catch (error) { }
        };

        const getRebalancing = async (myid) => {
            try {
                // make api request
                const res = await axiosPrivate.get(`/api/comparison/test/${myid}`);
                // if true
                isMounted && sethasRebal(res.data);
            } catch (error) {
                // setInfoMessage(error);
            }
        };

        const getUser = async (myId) => {
            try {
                const { data } = await axiosPrivate.get(`/api/users/genericFindOne/${myId}`, {
                    signal: controller.signal,
                });
                const decrypt = service.decryptOneUserInfo(data.oneUser);

                console.log("test", decrypt);
                isMounted && setSeuilLimit(decrypt.strategy == "ai" ? 5000 : 10000)
                isMounted && setUserDetails(decrypt);
                // console.log("**************** USER DATA", decrypt)
                if (decrypt.rebalancing.length > 0) {
                    isMounted &&
                        setSummaryRebal({
                            startDate: decrypt.rebalancing[0]?.date,
                            endDate: decrypt.rebalancing[decrypt.rebalancing.length - 1]?.date,
                            endCapitalRebal: JSON.parse(decrypt.rebalancing[decrypt.rebalancing.length - 1].totalAssets).usdt || 0,
                            startCapital: Number(JSON.parse(decrypt.rebalancing[0].totalAssets).usdt).toFixed(2) || 0,
                            recurringAmount: Number(decrypt.details.recurringAmount) || 0,
                        });
                }
                //dispacth(getUserDetails(data));
            } catch (error) {
                //console.log("error", error);
                if (error.response.status != 403) {
                    navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };

        if (auth?.role.includes(process.env.REACT_APP_CLIENT) || auth?.role.includes(process.env.REACT_APP_PARTENAIRE)) {
            //getZap(auth?.id);
            getRebalancing(auth?.id);
            getCurrentActifCapital(auth?.id);
            getTotalInvested(auth?.id);
            getZap(auth?.id);
            getUser(auth?.id)
            // getRebalancing(auth?.id);
        } else {
            //getZap(id);

            getRebalancing(id);
            getCurrentActifCapital(id);
            getTotalInvested(id);
            getZap(id);
            getUser(id)
            // getRebalancing(id);
        }

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const zapCollection = [
        { id: 1, title: "Récolte" },
        { id: 2, title: "Palier" },
        { id: 3, title: "Dynamique" },
        // { id: 4, title: "Autres" },
    ];



    return (
        <>
            <DialogBox isOpen={isOpen2} setIsOpen={setIsOpen2} title="Etapes de demande de sous compte Binance">
                <div className="flex flex-col">
                    <a
                        href="https://www.binance.com/fr/survey/e222293e45de4540b9c452ecf61e1bdd?ns=subacct-appform"
                        target={"_blank"}
                        className="bg-blue-500 text-white hover:bg-white hover:border-[1px] hover:border-blue-500 p-2 rounded text-center w-full md:w-1/2 mx-auto"
                    >
                        {t(`label.linkFormRequestSubAccountBinance`)}
                    </a>

                    <div className="text-sm mt-2 flex flex-col gap-y-2">
                        <div> {t(`label.needToCompleteForm`)}:</div>

                        <div className="font-semibold">1.{t(`label.completeSumActif`)}</div>

                        <img className="object-contains w-[90%] mx-auto" src="https://lh4.googleusercontent.com/poj7FjBrElJHyDGTPknRfDskZxCdyfnu7jAwQ4yv_55qBP_I2Zl6R_6u8OOyxQqg_us=w2400?source=screenshot.guru" />
                        <div className="font-semibold">4. {t(`label.indicateGoalOfSubAccount`)} :</div>
                        <div>
                            {"-->"} {t(`label.multistrategyInvestment`)}
                        </div>
                        <div className="font-semibold">5.{t(`label.courtier`)}.</div>
                        <div>
                            {"-->"} {t(`label.CB`)}
                        </div>
                    </div>
                </div>
            </DialogBox>

            {/* <div className={`my-2 ${capitalActif < totalInvested ? "block" : "hidden"}`}>
        <BannerInfo text={"Vous n'avez pas accès à la fonctionnalité de sécurisation"} type="info" />
      </div> */}
            {/* {userDetails && userDetails?.strategy == "normal" && */}
            <div className={`text-right mb-2 `}>
                <Controls.Button text={isOpen ? "Annuler" : "Express"} onClick={() => setIsOpen(!isOpen)} color={isOpen ? "bg-slate-500 text-white" : null} />
            </div>
            {/* } */}


            <div className="bg-slate-100 w-full h-[72vh] ">
                {/* Securisation express */}
                <div className={`my-2 ${capitalActif > totalInvested && capitalActif >= 10000 ? "hidden" : "block bg-sky-300 text-sm text-center text-slate-600 py-2 font-normal"}`}>
                    {t(`info.noAccessSecurisation`)} {isOpen ? "Express" : null}
                </div>

                {/* <Tuto t={t} isVideoOpen={isVideoOpen} setIsVideoOpen={setIsVideoOpen} url={"https://www.youtube.com/watch?v=iL0rTfwJ3ww"} title={t("label.securePnl")} /> */}
                {oneActiveZap ?
                    <div className="flex flex-col gap-y-4 mx-auto  w-fit md:w-1/2 py-4 text-sm">

                        <div className="mx-auto">
                            {t(`info.securisationType`)} : <strong>{zapCollection.find((zap => zap.id == oneActiveZap.type)).title}</strong>
                        </div>
                        {oneActiveZap.type == 1 && <div>
                            {t(`info.triggerNextSecRecolte`)}
                        </div>}

                        {oneActiveZap.type == 2 && <div>
                            {t(`info.nextSec`)} <strong>{oneActiveZap.nextAth} USDT</strong>  {t(`info.pnlInPeriode`)} <strong> {oneActiveZap.trigger} USDT</strong> .
                            <div className="">
                                <strong>{oneActiveZap.ats} USDT  </strong> {t(`info.securedNetGains`)}
                            </div>
                        </div>}

                        {oneActiveZap.type == 3 &&
                            <div className="flex- flex-col space-y-2 ">
                                <div className="">
                                    {t(`info.nextSec`)} <strong>{oneActiveZap.nextAth} USDT</strong>  {t(`info.pnlInPeriode`)}  <strong> {oneActiveZap.trigger} %</strong> .
                                </div>
                                <div className="">
                                    <strong>{oneActiveZap.ats} %  </strong> {t(`info.securedNetGains`)}
                                </div>
                            </div>
                        }


                    </div>
                    :
                    <div>
                        <div className={` mx-auto  w-fit md:w-1/2 py-4  ${isOpen ? "hidden" : "block"}`}>
                            {capitalActif > totalInvested && capitalActif >= 10000 ? (
                                // AFAKA manao sécurisation , RDV
                                <div className="text-sm">
                                    <div>
                                        <div className="flex justify-center">
                                            {t(`label.yourKActif`)} <strong className="pl-1"> {capitalActif} USDT</strong>.
                                        </div>
                                        <div className="flex justify-center">
                                            {t(`label.yourKinvested`)} <strong className="pl-1"> {totalInvested} USDT</strong>.
                                        </div>
                                        <div className="my-4">
                                            {t(`label.setUpSecure`)} {t(`label.RDVForSetting`)}
                                        </div>{" "}
                            
                                    </div>

                                    <div className={` bg-blue-300 hover:bg-blue-600 p-2 w-full rounded text-center `}>
                                        <a className="text-black hover:text-white " href="https://cryptobulot-1.youcanbook.me/" target={"_blank"}>
                                            {t(`label.linkRDV`)}
                                        </a>
                                    </div>

                                </div>
                            ) : (
                                // securisation OFF / tsy afaka manao sécurisation
                                <>

                                    <div className="text-sm ">
                                        <div className="text-center">
                                            {t(`label.yourKActif`)} <strong>{capitalActif} USDT</strong>{" "}
                                        </div>
                                        {totalInvested < seuilLimit ? (
                                            <div className="text-center">
                                                {t(`label.youNeed`)}
                                                    <strong className="pl-1">{seuilLimit - capitalActif} USDT </strong>
                                            </div>
                                        ) : (
                                            <div className="text-center">
                                                {t(`label.youNeed`)} <strong className="pl-1">{Number((totalInvested - capitalActif).toFixed(2))} USDT</strong> {t(`label.toArriveIn`)} <strong>{totalInvested} USDT</strong> (total
                                                investi){" "}
                                            </div>
                                        )}
                                        <div>
                                            {/* <div className={`mx-auto bg-blue-300 hover:bg-blue-600 p-2 w-fit rounded text-center `}>
                    <a className="text-black hover:text-white " href="https://cryptobulot-1.youcanbook.me/" target={"_blank"}>
                      {t(`label.linkRDV`)}
                    </a>
                  </div> */}
                                        </div>
                                    </div>


                                    <div className="h-64 flex justify-center mt-4">
                                        <svg width="373" height="295" viewBox="0 0 373 295" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M335.673 272.384H310.997V272.582H335.673V272.384Z" fill="#EBEBEB" />
                                            <path d="M247.252 274.541H240.777V274.739H247.252V274.541Z" fill="#EBEBEB" />
                                            <path d="M310.252 265.025H295.955V265.223H310.252V265.025Z" fill="#EBEBEB" />
                                            <path d="M71.7418 266.357H39.5634V266.556H71.7418V266.357Z" fill="#EBEBEB" />
                                            <path d="M83.0963 266.357H78.3801V266.556H83.0963V266.357Z" fill="#EBEBEB" />
                                            <path d="M168.225 269.704H98.4292V269.902H168.225V269.704Z" fill="#EBEBEB" />
                                            <path
                                                d="M177.054 224.257H33.1932C32.0662 224.255 30.9861 223.777 30.1899 222.928C29.3937 222.079 28.9465 220.929 28.9465 219.729V4.48832C28.9563 3.29568 29.4078 2.15533 30.2029 1.31495C30.9981 0.474571 32.0727 0.00204305 33.1932 0H177.054C178.182 0 179.264 0.477053 180.062 1.32621C180.86 2.17537 181.308 3.32708 181.308 4.52797V219.729C181.308 220.93 180.86 222.082 180.062 222.931C179.264 223.78 178.182 224.257 177.054 224.257V224.257ZM33.1932 0.158598C32.1156 0.160699 31.0828 0.61779 30.3216 1.42954C29.5603 2.2413 29.1327 3.34138 29.1327 4.48832V219.729C29.1327 220.876 29.5603 221.976 30.3216 222.788C31.0828 223.6 32.1156 224.057 33.1932 224.059H177.054C178.132 224.057 179.165 223.6 179.928 222.789C180.69 221.977 181.12 220.877 181.122 219.729V4.48832C181.12 3.34065 180.69 2.24059 179.928 1.42906C179.165 0.617533 178.132 0.160695 177.054 0.158598H33.1932Z"
                                                fill="#EBEBEB"
                                            />
                                            <path
                                                d="M338.214 224.257H194.346C193.218 224.255 192.137 223.778 191.34 222.929C190.543 222.08 190.094 220.93 190.092 219.729V4.48832C190.104 3.29496 190.557 2.15461 191.353 1.31445C192.149 0.474294 193.225 0.00202077 194.346 0H338.214C339.333 0.00412711 340.406 0.477577 341.199 1.31773C341.993 2.15789 342.443 3.29705 342.453 4.48832V219.729C342.453 220.928 342.007 222.077 341.212 222.926C340.418 223.774 339.34 224.253 338.214 224.257V224.257ZM194.346 0.158598C193.268 0.160695 192.234 0.617533 191.472 1.42906C190.709 2.24059 190.28 3.34065 190.278 4.48832V219.729C190.28 220.877 190.709 221.977 191.472 222.789C192.234 223.6 193.268 224.057 194.346 224.059H338.214C339.292 224.057 340.326 223.6 341.088 222.789C341.851 221.977 342.28 220.877 342.282 219.729V4.48832C342.28 3.34065 341.851 2.24059 341.088 1.42906C340.326 0.617533 339.292 0.160695 338.214 0.158598H194.346Z"
                                                fill="#EBEBEB"
                                            />
                                            <path d="M373 259.625H0.478394V259.823H373V259.625Z" fill="#EBEBEB" />
                                            <path d="M111.624 148.781H94.935V224.083H111.624V148.781Z" fill="#E6E6E6" />
                                            <path d="M139.444 123.104H122.755V224.083H139.444V123.104Z" fill="#E0E0E0" />
                                            <path d="M167.264 148.781H150.575V224.083H167.264V148.781Z" fill="#EBEBEB" />
                                            <path d="M55.9841 174.45H39.2952V224.075H55.9841V174.45Z" fill="#F0F0F0" />
                                            <path d="M83.804 162.055H67.1151V224.075H83.804V162.055Z" fill="#EBEBEB" />
                                            <path d="M195.084 174.45H178.395V224.075H195.084V174.45Z" fill="#F5F5F5" />
                                            <path d="M334.176 97.4268H317.487V224.083H334.176V97.4268Z" fill="#E0E0E0" />
                                            <path d="M222.904 148.781H206.215V224.083H222.904V148.781Z" fill="#F0F0F0" />
                                            <path d="M306.356 123.104H289.667V224.083H306.356V123.104Z" fill="#E6E6E6" />
                                            <path d="M278.543 136.378H261.854V224.075H278.543V136.378Z" fill="#EBEBEB" />
                                            <path d="M250.724 162.055H234.035V224.075H250.724V162.055Z" fill="#F0F0F0" />
                                            <path
                                                d="M62.4078 127.661C63.8851 120.999 60.0085 114.323 53.7491 112.751C47.4897 111.178 41.2179 115.304 39.7405 121.967C38.2632 128.629 42.1398 135.304 48.3992 136.877C54.6586 138.449 60.9304 134.323 62.4078 127.661Z"
                                                fill="#E0E0E0"
                                            />
                                            <path
                                                d="M60.7081 133.581C65.2557 128.741 65.2557 120.893 60.7081 116.053C56.1604 111.212 48.7872 111.212 44.2395 116.053C39.6919 120.893 39.6919 128.741 44.2395 133.581C48.7872 138.421 56.1604 138.421 60.7081 133.581Z"
                                                fill="#E6E6E6"
                                            />
                                            <path
                                                d="M52.4749 134.095C57.2892 134.095 61.1919 129.941 61.1919 124.817C61.1919 119.692 57.2892 115.539 52.4749 115.539C47.6607 115.539 43.7579 119.692 43.7579 124.817C43.7579 129.941 47.6607 134.095 52.4749 134.095Z"
                                                fill="#F0F0F0"
                                            />
                                            <path
                                                d="M56.3045 126.791C56.6398 128.845 55.3136 130.217 52.9295 130.431L52.8922 132.128H51.4394L51.4841 130.431C50.2363 130.33 49.0275 129.923 47.9526 129.241L48.6082 127.33C49.4828 127.951 50.4842 128.34 51.5288 128.464L51.5958 125.863C50.1728 125.427 48.6157 124.856 48.2953 122.993C47.96 120.939 49.3086 119.551 51.7448 119.369V117.672H53.2275V119.385C54.2121 119.474 55.1698 119.774 56.0437 120.265L55.4328 122.12C54.7337 121.682 53.9531 121.411 53.1455 121.327L53.0859 124.039C54.4866 124.499 56.0065 125.023 56.3045 126.791ZM51.6331 123.619L51.6927 121.399C50.8955 121.565 50.5081 122.041 50.59 122.628C50.672 123.215 51.0743 123.381 51.6331 123.619V123.619ZM54.0172 127.196C53.9502 126.744 53.5702 126.466 53.0263 126.268L52.9816 128.377C53.7416 128.211 54.1215 127.782 54.0172 127.196V127.196Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M328.335 20.2053H267.696C264.614 20.2053 262.115 22.8645 262.115 26.1448V50.5372C262.115 53.8175 264.614 56.4767 267.696 56.4767H328.335C331.417 56.4767 333.915 53.8175 333.915 50.5372V26.1448C333.915 22.8645 331.417 20.2053 328.335 20.2053Z"
                                                fill="#F0F0F0"
                                            />
                                            <path
                                                d="M280.756 25.1456H275.705C275.409 25.1456 275.168 25.4013 275.168 25.7166V51.4729C275.168 51.7882 275.409 52.0438 275.705 52.0438H280.756C281.053 52.0438 281.293 51.7882 281.293 51.4729V25.7166C281.293 25.4013 281.053 25.1456 280.756 25.1456Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M322.106 25.1456H317.055C316.758 25.1456 316.518 25.4013 316.518 25.7166V51.4729C316.518 51.7882 316.758 52.0438 317.055 52.0438H322.106C322.402 52.0438 322.643 51.7882 322.643 51.4729V25.7166C322.643 25.4013 322.402 25.1456 322.106 25.1456Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M302.146 34.0193H297.095C296.799 34.0193 296.558 34.2749 296.558 34.5902V51.4809C296.558 51.7962 296.799 52.0519 297.095 52.0519H302.146C302.443 52.0519 302.683 51.7962 302.683 51.4809V34.5902C302.683 34.2749 302.443 34.0193 302.146 34.0193Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M291.544 38.3411H286.493C286.197 38.3411 285.957 38.5967 285.957 38.912V51.473C285.957 51.7883 286.197 52.0439 286.493 52.0439H291.544C291.841 52.0439 292.081 51.7883 292.081 51.473V38.912C292.081 38.5967 291.841 38.3411 291.544 38.3411Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M312.13 48.8719H307.079C306.782 48.8719 306.542 49.1276 306.542 49.4429V51.473C306.542 51.7883 306.782 52.0439 307.079 52.0439H312.13C312.426 52.0439 312.666 51.7883 312.666 51.473V49.4429C312.666 49.1276 312.426 48.8719 312.13 48.8719Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M319.67 24.9871C319.603 24.9518 319.529 24.9316 319.455 24.9278C319.38 24.924 319.305 24.9366 319.235 24.9649C319.166 24.9932 319.102 25.0365 319.048 25.0922C318.995 25.1479 318.953 25.2147 318.925 25.2884L309.984 45.9062C309.946 46.0053 309.882 46.0912 309.801 46.1543C309.72 46.2173 309.623 46.2551 309.523 46.2633C309.422 46.2715 309.322 46.2499 309.232 46.2008C309.143 46.1518 309.068 46.0772 309.016 45.9855L300.731 32.5681C300.654 32.4395 300.533 32.3482 300.393 32.3141C300.254 32.2799 300.107 32.3057 299.986 32.3857L289.555 36.8264C289.471 36.8811 289.374 36.91 289.276 36.91C289.177 36.91 289.08 36.8811 288.996 36.8264L277.634 24.6461C277.569 24.6087 277.498 24.586 277.424 24.5794C277.351 24.5728 277.276 24.5825 277.206 24.6078C277.137 24.6331 277.072 24.6735 277.018 24.7265C276.963 24.7795 276.919 24.8439 276.889 24.9157V24.9792C276.835 25.1107 276.828 25.259 276.871 25.3953C276.914 25.5316 277.003 25.6462 277.12 25.7167L289.093 38.2697C289.177 38.3261 289.274 38.356 289.372 38.356C289.471 38.356 289.568 38.3261 289.652 38.2697L299.621 34.0986C299.681 34.0579 299.748 34.0303 299.818 34.0175C299.888 34.0046 299.96 34.0067 300.029 34.0237C300.098 34.0407 300.164 34.0722 300.222 34.1163C300.279 34.1605 300.328 34.2164 300.366 34.2809L309.179 48.6103C309.231 48.7023 309.305 48.7773 309.395 48.827C309.484 48.8767 309.584 48.899 309.684 48.8916C309.785 48.8842 309.881 48.8474 309.963 48.7851C310.045 48.7228 310.109 48.6376 310.148 48.5389L319.908 25.7404C319.964 25.6059 319.971 25.4538 319.927 25.3143C319.883 25.1747 319.791 25.0579 319.67 24.9871V24.9871Z"
                                                fill="#E0E0E0"
                                            />
                                            <path
                                                d="M165.282 55.5806C171.993 55.5806 177.434 49.79 177.434 42.6469C177.434 35.5039 171.993 29.7133 165.282 29.7133C158.571 29.7133 153.13 35.5039 153.13 42.6469C153.13 49.79 158.571 55.5806 165.282 55.5806Z"
                                                fill="#E0E0E0"
                                            />
                                            <path
                                                opacity="0.8"
                                                d="M165.14 53.0272C170.527 53.0272 174.893 48.3798 174.893 42.647C174.893 36.9141 170.527 32.2667 165.14 32.2667C159.754 32.2667 155.388 36.9141 155.388 42.647C155.388 48.3798 159.754 53.0272 165.14 53.0272Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M165.111 44.566C165.658 44.566 166.102 44.0938 166.102 43.5113C166.102 42.9289 165.658 42.4567 165.111 42.4567C164.563 42.4567 164.12 42.9289 164.12 43.5113C164.12 44.0938 164.563 44.566 165.111 44.566Z"
                                                fill="#E0E0E0"
                                            />
                                            <path d="M164.999 43.8999L160.804 39.697L161.214 39.2371L165.014 43.0435L171.823 36.6361L172.218 37.1119L164.999 43.8999Z" fill="#E0E0E0" />
                                            <path
                                                d="M186.739 295C266.52 295 331.196 290.981 331.196 286.023C331.196 281.066 266.52 277.047 186.739 277.047C106.958 277.047 42.2827 281.066 42.2827 286.023C42.2827 290.981 106.958 295 186.739 295Z"
                                                fill="#F5F5F5"
                                            />
                                            <path d="M211.49 148.844H162.302V286.61H211.49V148.844Z" fill="#407BFF" />
                                            <path opacity="0.5" d="M211.646 148.844H174.968V286.61H211.646V148.844Z" fill="black" />
                                            <path d="M275.75 195.813H226.562V286.602H275.75V195.813Z" fill="#407BFF" />
                                            <path opacity="0.3" d="M275.75 195.599H239.071V286.61H275.75V195.599Z" fill="black" />
                                            <path d="M146.917 173.411H97.7289V286.602H146.917V173.411Z" fill="#407BFF" />
                                            <path opacity="0.4" d="M146.917 173.133H110.238V286.602H146.917V173.133Z" fill="black" />
                                            <path
                                                d="M116.876 286.753C116.809 288.442 121.824 290.036 128.052 290.321C134.281 290.607 139.429 289.465 139.496 287.776C139.563 286.087 134.549 284.501 128.32 284.215C122.092 283.93 116.944 285.072 116.876 286.753Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M116.998 283.693L116.877 286.743L139.52 287.766L139.642 284.716L116.998 283.693Z" fill="#407BFF" />
                                            <path
                                                d="M116.996 283.7C116.929 285.389 121.943 286.983 128.171 287.268C134.4 287.554 139.548 286.412 139.615 284.723C139.682 283.034 134.668 281.448 128.44 281.162C122.211 280.877 117.063 282.019 116.996 283.7Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M116.996 283.7C116.929 285.389 121.943 286.983 128.171 287.268C134.4 287.554 139.548 286.412 139.615 284.723C139.682 283.034 134.668 281.448 128.44 281.162C122.211 280.877 117.063 282.019 116.996 283.7Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M118.441 283.652C118.441 283.761 118.475 283.867 118.538 283.954C119.283 282.962 123.433 282.368 128.38 282.598C133.327 282.828 137.417 283.779 138.066 284.834C138.139 284.756 138.182 284.651 138.185 284.54C138.229 283.399 133.849 282.257 128.387 282.011C122.926 281.765 118.486 282.51 118.441 283.652Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M118.441 283.652C118.441 283.761 118.475 283.867 118.538 283.954C119.283 282.962 123.433 282.368 128.38 282.598C133.327 282.828 137.417 283.779 138.066 284.834C138.139 284.756 138.182 284.651 138.185 284.54C138.229 283.399 133.849 282.257 128.387 282.011C122.926 281.765 118.486 282.51 118.441 283.652Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M118.538 283.954C119.216 285.008 123.291 285.96 128.223 286.182C133.156 286.404 137.32 285.825 138.065 284.834C137.402 283.779 133.327 282.828 128.38 282.598C123.433 282.368 119.298 282.962 118.538 283.954Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M114.47 282.13C114.403 283.819 119.417 285.413 125.646 285.698C131.874 285.984 137.022 284.842 137.09 283.161C137.157 281.48 132.142 279.878 125.914 279.592C119.685 279.307 114.567 280.449 114.47 282.13Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M114.622 279.085L114.5 282.136L137.144 283.159L137.265 280.108L114.622 279.085Z" fill="#407BFF" />
                                            <path
                                                d="M114.619 279.085C114.552 280.766 119.566 282.36 125.795 282.645C132.023 282.931 137.171 281.789 137.239 280.108C137.306 278.427 132.291 276.825 126.063 276.539C119.834 276.254 114.686 277.396 114.619 279.085Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M114.619 279.085C114.552 280.766 119.566 282.36 125.795 282.645C132.023 282.931 137.171 281.789 137.239 280.108C137.306 278.427 132.291 276.825 126.063 276.539C119.834 276.254 114.686 277.396 114.619 279.085Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M116.064 279.029C116.061 279.139 116.096 279.246 116.161 279.331C116.906 278.339 121.056 277.745 126.003 277.982C130.95 278.22 135.041 279.156 135.689 280.211C135.757 280.132 135.799 280.032 135.808 279.925C135.853 278.783 131.472 277.634 126.011 277.388C120.55 277.142 116.109 277.887 116.064 279.029Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M116.064 279.029C116.061 279.139 116.096 279.246 116.161 279.331C116.906 278.339 121.056 277.745 126.003 277.982C130.95 278.22 135.041 279.156 135.689 280.211C135.757 280.132 135.799 280.032 135.808 279.925C135.853 278.783 131.472 277.634 126.011 277.388C120.55 277.142 116.109 277.887 116.064 279.029Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M116.161 279.331C116.832 280.385 120.915 281.337 125.847 281.559C130.779 281.781 134.944 281.202 135.689 280.211C135.026 279.156 130.95 278.205 126.003 277.983C121.056 277.761 116.914 278.339 116.161 279.331Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M114.805 276.563C114.738 278.252 119.752 279.846 125.981 280.132C132.209 280.417 137.358 279.275 137.425 277.586C137.492 275.897 132.478 274.311 126.249 274.026C120.021 273.74 114.872 274.882 114.805 276.563Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M114.93 273.513L114.808 276.563L137.452 277.586L137.574 274.536L114.93 273.513Z" fill="#407BFF" />
                                            <path
                                                d="M114.924 273.51C114.857 275.199 119.872 276.793 126.1 277.078C132.329 277.364 137.47 276.222 137.537 274.541C137.604 272.86 132.589 271.258 126.361 270.972C120.132 270.687 114.992 271.829 114.924 273.51Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M114.924 273.51C114.857 275.199 119.872 276.793 126.1 277.078C132.329 277.364 137.47 276.222 137.537 274.541C137.604 272.86 132.589 271.258 126.361 270.972C120.132 270.687 114.992 271.829 114.924 273.51Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M116.37 273.462C116.37 273.572 116.404 273.678 116.467 273.764C117.212 272.773 121.362 272.178 126.309 272.416C131.256 272.654 135.346 273.589 135.994 274.644C136.068 274.569 136.11 274.467 136.114 274.359C136.158 273.217 131.777 272.067 126.316 271.821C120.855 271.575 116.415 272.321 116.37 273.462Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M116.37 273.462C116.37 273.572 116.404 273.678 116.467 273.764C117.212 272.773 121.362 272.178 126.309 272.416C131.256 272.654 135.346 273.589 135.994 274.644C136.068 274.569 136.11 274.467 136.114 274.359C136.158 273.217 131.777 272.067 126.316 271.821C120.855 271.575 116.415 272.321 116.37 273.462Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M116.467 273.764C117.145 274.818 121.22 275.77 126.152 275.992C131.084 276.214 135.249 275.635 135.994 274.644C135.331 273.589 131.256 272.638 126.309 272.416C121.362 272.194 117.227 272.788 116.467 273.764Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M112.429 271.948C112.362 273.629 117.376 275.223 123.604 275.508C129.833 275.794 134.981 274.652 135.048 272.971C135.115 271.29 130.101 269.688 123.872 269.402C117.644 269.117 112.496 270.259 112.429 271.948Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M112.546 268.874L112.424 271.924L135.068 272.947L135.189 269.897L112.546 268.874Z" fill="#407BFF" />
                                            <path
                                                d="M112.548 268.895C112.481 270.576 117.495 272.17 123.723 272.455C129.952 272.741 135.1 271.599 135.167 269.918C135.234 268.237 130.22 266.635 123.992 266.349C117.763 266.064 112.615 267.237 112.548 268.895Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M112.548 268.895C112.481 270.576 117.495 272.17 123.723 272.455C129.952 272.741 135.1 271.599 135.167 269.918C135.234 268.237 130.22 266.635 123.992 266.349C117.763 266.064 112.615 267.237 112.548 268.895Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M113.993 268.823C113.989 268.933 114.023 269.041 114.09 269.125C114.835 268.134 118.985 267.539 123.932 267.777C128.879 268.015 132.969 268.95 133.618 270.005C133.685 269.926 133.727 269.826 133.737 269.72C133.782 268.578 129.401 267.428 123.94 267.182C118.478 266.936 114.038 267.697 113.993 268.823Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M113.993 268.823C113.989 268.933 114.023 269.041 114.09 269.125C114.835 268.134 118.985 267.539 123.932 267.777C128.879 268.015 132.969 268.95 133.618 270.005C133.685 269.926 133.727 269.826 133.737 269.72C133.782 268.578 129.401 267.428 123.94 267.182C118.478 266.936 114.038 267.697 113.993 268.823Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M114.09 269.141C114.761 270.195 118.843 271.147 123.776 271.369C128.708 271.591 132.873 271.012 133.618 270.021C132.955 268.966 128.879 268.015 123.932 267.793C118.985 267.571 114.843 268.149 114.09 269.141Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M116.057 268.03C115.99 269.712 121.004 271.305 127.233 271.591C133.461 271.876 138.609 270.734 138.676 269.053C138.744 267.372 133.729 265.77 127.501 265.485C121.272 265.199 116.124 266.341 116.057 268.03Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M116.182 264.976L116.06 268.026L138.704 269.049L138.826 265.999L116.182 264.976Z" fill="#407BFF" />
                                            <path
                                                d="M116.184 264.977C116.117 266.659 121.131 268.252 127.359 268.538C133.588 268.823 138.736 267.681 138.803 266C138.87 264.319 133.856 262.717 127.627 262.432C121.399 262.146 116.251 263.272 116.184 264.977Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M116.184 264.977C116.117 266.659 121.131 268.252 127.359 268.538C133.588 268.823 138.736 267.681 138.803 266C138.87 264.319 133.856 262.717 127.627 262.432C121.399 262.146 116.251 263.272 116.184 264.977Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M117.622 264.922C117.624 265.031 117.658 265.136 117.718 265.223C118.463 264.232 122.621 263.637 127.56 263.875C132.5 264.113 136.605 265.049 137.246 266.103C137.319 266.027 137.363 265.926 137.373 265.818C137.417 264.676 133.036 263.526 127.575 263.28C122.114 263.035 117.674 263.78 117.622 264.922Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M117.622 264.922C117.624 265.031 117.658 265.136 117.718 265.223C118.463 264.232 122.621 263.637 127.56 263.875C132.5 264.113 136.605 265.049 137.246 266.103C137.319 266.027 137.363 265.926 137.373 265.818C137.417 264.676 133.036 263.526 127.575 263.28C122.114 263.035 117.674 263.78 117.622 264.922Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M117.718 265.223C118.396 266.278 122.472 267.229 127.404 267.452C132.336 267.674 136.508 267.095 137.246 266.103C136.59 265.049 132.508 264.097 127.56 263.875C122.613 263.653 118.478 264.232 117.718 265.223Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M113.68 263.407C113.613 265.088 118.627 266.69 124.856 266.968C131.084 267.245 136.233 266.111 136.3 264.43C136.367 262.749 131.353 261.147 125.124 260.862C118.896 260.576 113.725 261.687 113.68 263.407Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M113.798 260.345L113.676 263.396L136.32 264.418L136.442 261.368L113.798 260.345Z" fill="#407BFF" />
                                            <path
                                                d="M113.799 260.354C113.732 262.035 118.747 263.637 124.975 263.915C131.204 264.192 136.352 263.066 136.419 261.377C136.486 259.688 131.472 258.094 125.243 257.809C119.015 257.523 113.867 258.665 113.799 260.354Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M113.799 260.354C113.732 262.035 118.747 263.637 124.975 263.915C131.204 264.192 136.352 263.066 136.419 261.377C136.486 259.688 131.472 258.094 125.243 257.809C119.015 257.523 113.867 258.665 113.799 260.354Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M115.215 260.299C115.213 260.408 115.247 260.515 115.312 260.6C116.057 259.609 120.207 259.014 125.154 259.252C130.101 259.49 134.191 260.426 134.84 261.488C134.912 261.41 134.955 261.305 134.959 261.195C135.003 260.053 130.623 258.911 125.161 258.657C119.7 258.404 115.29 259.157 115.215 260.299Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M115.215 260.299C115.213 260.408 115.247 260.515 115.312 260.6C116.057 259.609 120.207 259.014 125.154 259.252C130.101 259.49 134.191 260.426 134.84 261.488C134.912 261.41 134.955 261.305 134.959 261.195C135.003 260.053 130.623 258.911 125.161 258.657C119.7 258.404 115.29 259.157 115.215 260.299Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M115.342 260.6C116.02 261.655 120.095 262.606 125.027 262.836C129.959 263.066 134.124 262.479 134.869 261.488C134.206 260.426 130.131 259.474 125.184 259.252C120.237 259.03 116.102 259.609 115.342 260.6Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M83.1111 285.278C83.0366 286.967 88.0507 288.561 94.2868 288.838C100.523 289.116 105.656 287.99 105.723 286.301C105.79 284.612 100.784 283.018 94.5475 282.74C88.3115 282.463 83.1782 283.589 83.1111 285.278Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M83.2256 282.21L83.1039 285.26L105.748 286.283L105.869 283.233L83.2256 282.21Z" fill="#407BFF" />
                                            <path
                                                d="M83.2303 282.225C83.1633 283.914 88.1774 285.508 94.406 285.793C100.635 286.079 105.783 284.937 105.85 283.248C105.917 281.559 100.903 279.965 94.6742 279.687C88.4456 279.41 83.2974 280.536 83.2303 282.225Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M83.2303 282.225C83.1633 283.914 88.1774 285.508 94.406 285.793C100.635 286.079 105.783 284.937 105.85 283.248C105.917 281.559 100.903 279.965 94.6742 279.687C88.4456 279.41 83.2974 280.536 83.2303 282.225Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M84.6683 282.169C84.6622 282.28 84.697 282.388 84.7651 282.471C85.5102 281.48 89.6601 280.885 94.6072 281.123C99.5543 281.361 103.645 282.304 104.293 283.359C104.361 283.277 104.402 283.174 104.412 283.066C104.457 281.924 100.076 280.782 94.6146 280.536C89.1534 280.29 84.7204 281.028 84.6683 282.169Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M84.6683 282.169C84.6622 282.28 84.697 282.388 84.7651 282.471C85.5102 281.48 89.6601 280.885 94.6072 281.123C99.5543 281.361 103.645 282.304 104.293 283.359C104.361 283.277 104.402 283.174 104.412 283.066C104.457 281.924 100.076 280.782 94.6146 280.536C89.1534 280.29 84.7204 281.028 84.6683 282.169Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M84.7726 282.471C85.4431 283.525 89.526 284.485 94.4581 284.707C99.3903 284.929 103.555 284.35 104.3 283.359C103.637 282.304 99.5617 281.345 94.6146 281.123C89.6675 280.901 85.5251 281.511 84.7726 282.471Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M92.9606 282.304C92.5881 283.946 97.2297 286.523 103.317 288.061C109.404 289.6 114.641 289.52 115.006 287.879C115.371 286.237 110.73 283.66 104.643 282.122C98.5559 280.583 93.3257 280.663 92.9606 282.304Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M93.6223 279.349L92.9585 282.319L115.008 287.902L115.671 284.932L93.6223 279.349Z" fill="#407BFF" />
                                            <path
                                                d="M93.6088 279.331C93.2363 280.972 97.8779 283.549 103.965 285.096C110.052 286.642 115.29 286.555 115.655 284.913C116.02 283.272 111.4 280.718 105.306 279.132C99.2116 277.546 93.9888 277.689 93.6088 279.331Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M93.6088 279.331C93.2363 280.972 97.8779 283.549 103.965 285.096C110.052 286.642 115.29 286.555 115.655 284.913C116.02 283.272 111.4 280.718 105.306 279.132C99.2116 277.546 93.9888 277.689 93.6088 279.331Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M95.0467 279.568C95.0247 279.676 95.0406 279.789 95.0914 279.886C96.0078 279.093 100.18 279.33 104.993 280.552C109.806 281.773 113.658 283.533 114.12 284.699C114.204 284.636 114.265 284.543 114.291 284.437C114.537 283.327 110.439 281.321 105.12 279.973C99.8001 278.625 95.3 278.458 95.0467 279.568Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M95.0467 279.568C95.0247 279.676 95.0406 279.789 95.0914 279.886C96.0078 279.093 100.18 279.33 104.993 280.552C109.806 281.773 113.658 283.533 114.12 284.699C114.204 284.636 114.265 284.543 114.291 284.437C114.537 283.327 110.439 281.321 105.12 279.973C99.8001 278.625 95.3 278.458 95.0467 279.568Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M95.0989 279.925C95.5758 281.099 99.4053 282.859 104.226 284.073C109.046 285.286 113.218 285.563 114.127 284.739C113.665 283.573 109.828 281.813 105.001 280.591C100.173 279.37 96.0079 279.061 95.0989 279.925Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M121.697 262.4C127.334 262.4 131.904 257.536 131.904 251.536C131.904 245.536 127.334 240.672 121.697 240.672C116.06 240.672 111.49 245.536 111.49 251.536C111.49 257.536 116.06 262.4 121.697 262.4Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M122.919 262.4C128.556 262.4 133.126 257.536 133.126 251.536C133.126 245.536 128.556 240.672 122.919 240.672C117.282 240.672 112.712 245.536 112.712 251.536C112.712 257.536 117.282 262.4 122.919 262.4Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.5"
                                                d="M122.919 262.4C128.556 262.4 133.126 257.536 133.126 251.536C133.126 245.536 128.556 240.672 122.919 240.672C117.282 240.672 112.712 245.536 112.712 251.536C112.712 257.536 117.282 262.4 122.919 262.4Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M128.325 257.289C131.31 254.111 131.31 248.96 128.325 245.783C125.34 242.605 120.5 242.605 117.514 245.783C114.529 248.96 114.529 254.111 117.514 257.289C120.5 260.466 125.34 260.466 128.325 257.289Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.3"
                                                d="M126.011 254.224C125.702 254.565 125.329 254.832 124.918 255.01C124.506 255.187 124.063 255.271 123.619 255.255C122.939 255.279 122.268 255.079 121.698 254.683C121.127 254.288 120.685 253.715 120.43 253.043H124.543V252.107H120.207C120.199 251.965 120.199 251.822 120.207 251.679C120.199 251.536 120.199 251.393 120.207 251.251H124.536V250.315H120.43C120.685 249.642 121.129 249.068 121.701 248.672C122.273 248.277 122.945 248.078 123.627 248.103C124.07 248.086 124.512 248.169 124.924 248.345C125.336 248.521 125.709 248.787 126.018 249.126L126.137 249.252L126.964 248.388L126.853 248.261C126.429 247.8 125.919 247.439 125.355 247.201C124.792 246.963 124.188 246.854 123.582 246.881C122.583 246.84 121.601 247.155 120.79 247.777C119.98 248.4 119.389 249.293 119.112 250.315H117.659V251.251H118.94C118.94 251.393 118.94 251.536 118.94 251.679C118.94 251.822 118.94 251.964 118.94 252.107H117.644V253.043H119.097C119.374 254.065 119.965 254.958 120.775 255.58C121.586 256.203 122.569 256.518 123.567 256.477C124.174 256.501 124.778 256.389 125.342 256.149C125.905 255.909 126.415 255.545 126.838 255.081L126.95 254.954L126.123 254.09L126.011 254.224Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M265.915 288.244C265.855 289.925 260.744 291.099 254.486 290.861C248.228 290.623 243.213 289.061 243.266 287.379C243.318 285.698 248.444 284.517 254.694 284.755C260.945 284.992 265.974 286.555 265.915 288.244Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M243.359 284.3L243.257 287.351L265.906 288.214L266.009 285.163L243.359 284.3Z" fill="#407BFF" />
                                            <path
                                                d="M266.019 285.191C265.96 286.872 260.849 288.045 254.59 287.808C248.332 287.57 243.318 286.007 243.37 284.326C243.422 282.645 248.541 281.464 254.799 281.701C261.057 281.939 266.079 283.502 266.019 285.191Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M266.019 285.191C265.96 286.872 260.849 288.045 254.59 287.808C248.332 287.57 243.318 286.007 243.37 284.326C243.422 282.645 248.541 281.464 254.799 281.701C261.057 281.939 266.079 283.502 266.019 285.191Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M264.581 285.016C264.577 285.126 264.535 285.231 264.462 285.31C263.784 284.255 259.701 283.327 254.777 283.145C249.852 282.962 245.68 283.565 244.942 284.556C244.876 284.474 244.84 284.371 244.838 284.263C244.882 283.121 249.308 282.344 254.791 282.55C260.275 282.756 264.619 283.89 264.581 285.016Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M264.581 285.016C264.577 285.126 264.535 285.231 264.462 285.31C263.784 284.255 259.701 283.327 254.777 283.145C249.852 282.962 245.68 283.565 244.942 284.556C244.876 284.474 244.84 284.371 244.838 284.263C244.882 283.121 249.308 282.344 254.791 282.55C260.275 282.756 264.619 283.89 264.581 285.016Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M264.462 285.31C263.717 286.301 259.575 286.896 254.627 286.721C249.68 286.547 245.583 285.611 244.942 284.556C245.687 283.565 249.814 282.97 254.776 283.145C259.738 283.319 263.784 284.255 264.462 285.31Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M268.627 283.835C268.567 285.516 263.456 286.689 257.198 286.452C250.94 286.214 245.925 284.651 245.978 282.962C246.03 281.273 251.156 280.108 257.407 280.345C263.657 280.583 268.694 282.146 268.627 283.835Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M246.095 279.935L245.991 282.986L268.641 283.853L268.744 280.802L246.095 279.935Z" fill="#407BFF" />
                                            <path
                                                d="M268.731 280.782C268.672 282.463 263.561 283.636 257.302 283.399C251.044 283.161 246.022 281.598 246.082 279.909C246.141 278.22 251.252 277.055 257.511 277.293C263.769 277.53 268.783 279.132 268.731 280.782Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M268.731 280.782C268.672 282.463 263.561 283.636 257.302 283.399C251.044 283.161 246.022 281.598 246.082 279.909C246.141 278.22 251.252 277.055 257.511 277.293C263.769 277.53 268.783 279.132 268.731 280.782Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M267.293 280.607C267.29 280.715 267.247 280.818 267.174 280.893C266.496 279.846 262.413 278.918 257.488 278.736C252.564 278.553 248.384 279.156 247.654 280.147C247.588 280.065 247.552 279.961 247.55 279.854C247.594 278.712 252.02 277.935 257.503 278.141C262.987 278.347 267.33 279.465 267.293 280.607Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M267.293 280.607C267.29 280.715 267.247 280.818 267.174 280.893C266.496 279.846 262.413 278.918 257.488 278.736C252.564 278.553 248.384 279.156 247.654 280.147C247.588 280.065 247.552 279.961 247.55 279.854C247.594 278.712 252.02 277.935 257.503 278.141C262.987 278.347 267.33 279.465 267.293 280.607Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M267.204 280.893C266.459 281.892 262.316 282.479 257.369 282.312C252.422 282.146 248.324 281.202 247.684 280.147C248.429 279.156 252.556 278.561 257.518 278.736C262.48 278.91 266.496 279.846 267.204 280.893Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M268.731 278.252C268.672 279.941 263.553 281.115 257.302 280.877C251.051 280.639 246.022 279.077 246.082 277.388C246.141 275.699 251.252 274.533 257.511 274.771C263.769 275.009 268.783 276.571 268.731 278.252Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M246.191 274.358L246.088 277.409L268.738 278.276L268.841 275.225L246.191 274.358Z" fill="#407BFF" />
                                            <path
                                                d="M268.836 275.167C268.776 276.856 263.657 278.03 257.407 277.792C251.156 277.554 246.127 275.992 246.186 274.303C246.246 272.614 251.357 271.448 257.608 271.686C263.859 271.924 268.888 273.518 268.836 275.167Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M268.836 275.167C268.776 276.856 263.657 278.03 257.407 277.792C251.156 277.554 246.127 275.992 246.186 274.303C246.246 272.614 251.357 271.448 257.608 271.686C263.859 271.924 268.888 273.518 268.836 275.167Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M267.398 275.033C267.388 275.139 267.346 275.239 267.278 275.318C266.593 274.271 262.51 273.344 257.593 273.153C252.676 272.963 248.496 273.573 247.758 274.573C247.693 274.488 247.659 274.381 247.661 274.271C247.661 273.129 252.132 272.36 257.615 272.566C263.099 272.773 267.435 273.891 267.398 275.033Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M267.398 275.033C267.388 275.139 267.346 275.239 267.278 275.318C266.593 274.271 262.51 273.344 257.593 273.153C252.676 272.963 248.496 273.573 247.758 274.573C247.693 274.488 247.659 274.381 247.661 274.271C247.661 273.129 252.132 272.36 257.615 272.566C263.099 272.773 267.435 273.891 267.398 275.033Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M267.278 275.318C266.533 276.317 262.391 276.904 257.444 276.737C252.497 276.571 248.399 275.627 247.758 274.573C248.503 273.573 252.631 272.987 257.593 273.153C262.555 273.32 266.593 274.271 267.278 275.318Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M271.443 273.843C271.384 275.532 266.265 276.706 260.014 276.468C253.763 276.23 248.734 274.668 248.794 272.979C248.853 271.29 253.964 270.124 260.223 270.362C266.481 270.6 271.495 272.162 271.443 273.843Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M248.878 269.912L248.775 272.963L271.425 273.826L271.527 270.774L248.878 269.912Z" fill="#407BFF" />
                                            <path
                                                d="M271.54 270.79C271.488 272.479 266.369 273.653 260.118 273.415C253.868 273.177 248.839 271.615 248.898 269.926C248.958 268.237 254.069 267.071 260.32 267.309C266.571 267.547 271.6 269.109 271.54 270.79Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M271.54 270.79C271.488 272.479 266.369 273.653 260.118 273.415C253.868 273.177 248.839 271.615 248.898 269.926C248.958 268.237 254.069 267.071 260.32 267.309C266.571 267.547 271.6 269.109 271.54 270.79Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M270.11 270.624C270.1 270.73 270.058 270.83 269.99 270.909C269.305 269.862 265.222 268.934 260.305 268.744C255.388 268.554 251.208 269.164 250.47 270.164C250.405 270.079 250.371 269.972 250.373 269.862C250.411 268.72 254.844 267.951 260.327 268.157C265.811 268.364 270.184 269.482 270.11 270.624Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M270.11 270.624C270.1 270.73 270.058 270.83 269.99 270.909C269.305 269.862 265.222 268.934 260.305 268.744C255.388 268.554 251.208 269.164 250.47 270.164C250.405 270.079 250.371 269.972 250.373 269.862C250.411 268.72 254.844 267.951 260.327 268.157C265.811 268.364 270.184 269.482 270.11 270.624Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M269.99 270.909C269.245 271.908 265.103 272.495 260.156 272.328C255.209 272.162 251.111 271.218 250.47 270.164C251.215 269.164 255.343 268.578 260.305 268.744C265.267 268.911 269.305 269.862 269.99 270.909Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M181.524 284.85C181.457 286.539 186.464 288.133 192.7 288.418C198.936 288.704 204.069 287.562 204.136 285.873C204.203 284.184 199.196 282.59 192.96 282.312C186.724 282.035 181.569 283.169 181.524 284.85Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M181.608 281.786L181.487 284.837L204.131 285.859L204.252 282.809L181.608 281.786Z" fill="#407BFF" />
                                            <path
                                                d="M181.621 281.797C181.554 283.486 186.568 285.08 192.796 285.365C199.025 285.651 204.173 284.509 204.24 282.82C204.307 281.131 199.293 279.537 193.065 279.259C186.836 278.982 181.688 280.115 181.621 281.797Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M181.621 281.797C181.554 283.486 186.568 285.08 192.796 285.365C199.025 285.651 204.173 284.509 204.24 282.82C204.307 281.131 199.293 279.537 193.065 279.259C186.836 278.982 181.688 280.115 181.621 281.797Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M183.066 281.749C183.062 281.857 183.097 281.962 183.163 282.043C183.908 281.051 188.058 280.457 193.005 280.695C197.952 280.932 202.042 281.876 202.691 282.931C202.759 282.849 202.8 282.746 202.81 282.637C202.854 281.495 198.474 280.354 193.012 280.108C187.551 279.862 183.111 280.607 183.066 281.749Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M183.066 281.749C183.062 281.857 183.097 281.962 183.163 282.043C183.908 281.051 188.058 280.457 193.005 280.695C197.952 280.932 202.042 281.876 202.691 282.931C202.759 282.849 202.8 282.746 202.81 282.637C202.854 281.495 198.474 280.354 193.012 280.108C187.551 279.862 183.111 280.607 183.066 281.749Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M183.163 282.043C183.834 283.105 187.916 284.057 192.849 284.279C197.781 284.501 201.946 283.922 202.691 282.931C202.027 281.876 197.952 280.924 193.005 280.694C188.058 280.464 183.915 281.051 183.163 282.043Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M179.117 280.227C179.05 281.916 184.065 283.51 190.293 283.795C196.522 284.081 201.67 282.939 201.737 281.25C201.804 279.56 196.79 277.967 190.561 277.689C184.333 277.411 179.184 278.545 179.117 280.227Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M179.239 277.187L179.117 280.237L201.761 281.26L201.883 278.21L179.239 277.187Z" fill="#407BFF" />
                                            <path
                                                d="M179.244 277.174C179.177 278.863 184.191 280.457 190.42 280.742C196.648 281.028 201.797 279.886 201.864 278.197C201.931 276.508 196.917 274.922 190.688 274.636C184.459 274.351 179.289 275.492 179.244 277.174Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M179.244 277.174C179.177 278.863 184.191 280.457 190.42 280.742C196.648 281.028 201.797 279.886 201.864 278.197C201.931 276.508 196.917 274.922 190.688 274.636C184.459 274.351 179.289 275.492 179.244 277.174Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M180.682 277.126C180.686 277.234 180.72 277.339 180.779 277.427C181.524 276.436 185.681 275.841 190.621 276.071C195.561 276.301 199.666 277.253 200.306 278.308C200.38 278.228 200.424 278.125 200.433 278.014C200.478 276.872 196.097 275.73 190.636 275.485C185.175 275.239 180.734 275.96 180.682 277.126Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M180.682 277.126C180.686 277.234 180.72 277.339 180.779 277.427C181.524 276.436 185.681 275.841 190.621 276.071C195.561 276.301 199.666 277.253 200.306 278.308C200.38 278.228 200.424 278.125 200.433 278.014C200.478 276.872 196.097 275.73 190.636 275.485C185.175 275.239 180.734 275.96 180.682 277.126Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M180.779 277.427C181.457 278.482 185.532 279.434 190.464 279.656C195.397 279.878 199.569 279.299 200.306 278.308C199.651 277.253 195.568 276.301 190.621 276.071C185.674 275.841 181.524 276.436 180.779 277.427Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M179.43 274.66C179.363 276.349 184.377 277.943 190.606 278.228C196.835 278.514 201.983 277.372 202.05 275.683C202.117 273.994 197.103 272.4 190.874 272.122C184.646 271.845 179.497 272.979 179.43 274.66Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M179.547 271.606L179.426 274.657L202.07 275.68L202.191 272.629L179.547 271.606Z" fill="#407BFF" />
                                            <path
                                                d="M179.549 271.607C179.482 273.296 184.497 274.89 190.725 275.175C196.954 275.461 202.102 274.319 202.169 272.63C202.236 270.941 197.222 269.347 190.993 269.069C184.765 268.792 179.617 269.926 179.549 271.607Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M179.549 271.607C179.482 273.296 184.497 274.89 190.725 275.175C196.954 275.461 202.102 274.319 202.169 272.63C202.236 270.941 197.222 269.347 190.993 269.069C184.765 268.792 179.617 269.926 179.549 271.607Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M180.995 271.559C180.991 271.667 181.025 271.772 181.092 271.853C181.837 270.869 185.987 270.267 190.934 270.505C195.881 270.742 199.971 271.686 200.619 272.741C200.687 272.659 200.729 272.556 200.739 272.447C200.783 271.305 196.402 270.164 190.941 269.918C185.48 269.672 181.04 270.409 180.995 271.559Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M180.995 271.559C180.991 271.667 181.025 271.772 181.092 271.853C181.837 270.869 185.987 270.267 190.934 270.505C195.881 270.742 199.971 271.686 200.619 272.741C200.687 272.659 200.729 272.556 200.739 272.447C200.783 271.305 196.402 270.164 190.941 269.918C185.48 269.672 181.04 270.409 180.995 271.559Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M181.092 271.853C181.762 272.915 185.845 273.867 190.777 274.089C195.71 274.311 199.874 273.732 200.619 272.741C199.956 271.686 195.881 270.735 190.934 270.505C185.987 270.275 181.844 270.869 181.092 271.853Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M177.054 270.037C176.987 271.726 182.001 273.32 188.229 273.605C194.458 273.891 199.606 272.749 199.673 271.06C199.74 269.371 194.726 267.785 188.498 267.499C182.269 267.214 177.113 268.356 177.054 270.037Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M177.163 266.976L177.041 270.026L199.685 271.049L199.807 267.999L177.163 266.976Z" fill="#407BFF" />
                                            <path
                                                d="M177.173 266.984C177.106 268.673 182.12 270.267 188.349 270.552C194.577 270.838 199.725 269.696 199.792 268.007C199.859 266.318 194.845 264.732 188.617 264.446C182.388 264.161 177.24 265.303 177.173 266.984Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M177.173 266.984C177.106 268.673 182.12 270.267 188.349 270.552C194.577 270.838 199.725 269.696 199.792 268.007C199.859 266.318 194.845 264.732 188.617 264.446C182.388 264.161 177.24 265.303 177.173 266.984Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M178.618 266.936C178.616 267.044 178.647 267.15 178.708 267.237C179.453 266.246 183.61 265.651 188.557 265.889C193.504 266.127 197.595 267.063 198.243 268.118C198.312 268.037 198.354 267.933 198.362 267.824C198.407 266.69 194.026 265.54 188.565 265.295C183.103 265.049 178.663 265.794 178.618 266.936Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M178.618 266.936C178.616 267.044 178.647 267.15 178.708 267.237C179.453 266.246 183.61 265.651 188.557 265.889C193.504 266.127 197.595 267.063 198.243 268.118C198.312 268.037 198.354 267.933 198.362 267.824C198.407 266.69 194.026 265.54 188.565 265.295C183.103 265.049 178.663 265.794 178.618 266.936Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M178.708 267.238C179.386 268.292 183.461 269.244 188.393 269.466C193.325 269.688 197.498 269.109 198.243 268.118C197.58 267.063 193.497 266.111 188.557 265.889C183.617 265.667 179.468 266.246 178.708 267.238Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M180.682 266.119C180.615 267.808 185.629 269.402 191.858 269.688C198.086 269.973 203.234 268.831 203.302 267.142C203.369 265.453 198.354 263.867 192.126 263.582C185.897 263.296 180.779 264.438 180.682 266.119Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M180.807 263.07L180.685 266.12L203.329 267.143L203.451 264.093L180.807 263.07Z" fill="#407BFF" />
                                            <path
                                                d="M180.779 263.066C180.712 264.755 185.726 266.349 191.954 266.635C198.183 266.92 203.331 265.778 203.398 264.089C203.465 262.4 198.451 260.814 192.223 260.529C185.994 260.243 180.868 261.385 180.779 263.066Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M180.779 263.066C180.712 264.755 185.726 266.349 191.954 266.635C198.183 266.92 203.331 265.778 203.398 264.089C203.465 262.4 198.451 260.814 192.223 260.529C185.994 260.243 180.868 261.385 180.779 263.066Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M182.269 263.019C182.269 263.128 182.303 263.234 182.366 263.32C183.111 262.329 187.261 261.734 192.208 261.972C197.155 262.21 201.245 263.146 201.893 264.2C201.931 264.162 201.96 264.117 201.981 264.066C202.001 264.016 202.012 263.962 202.013 263.907C202.057 262.773 197.676 261.623 192.215 261.377C186.754 261.131 182.269 261.877 182.269 263.019Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M182.269 263.019C182.269 263.128 182.303 263.234 182.366 263.32C183.111 262.329 187.261 261.734 192.208 261.972C197.155 262.21 201.245 263.146 201.893 264.2C201.931 264.162 201.96 264.117 201.981 264.066C202.001 264.016 202.012 263.962 202.013 263.907C202.057 262.773 197.676 261.623 192.215 261.377C186.754 261.131 182.269 261.877 182.269 263.019Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M182.344 263.32C183.021 264.375 187.097 265.326 192.029 265.548C196.961 265.77 201.126 265.191 201.871 264.2C201.208 263.146 197.133 262.194 192.186 261.972C187.238 261.75 183.103 262.329 182.344 263.32Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M178.305 261.504C178.238 263.185 183.252 264.779 189.481 265.065C195.709 265.35 200.85 264.208 200.925 262.527C200.999 260.846 195.978 259.244 189.749 258.959C183.521 258.673 178.372 259.815 178.305 261.504Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M178.415 258.439L178.294 261.489L200.938 262.512L201.059 259.462L178.415 258.439Z" fill="#407BFF" />
                                            <path
                                                d="M178.425 258.451C178.358 260.132 183.372 261.726 189.6 262.012C195.829 262.297 200.977 261.155 201.044 259.474C201.111 257.793 196.097 256.191 189.868 255.906C183.64 255.62 178.492 256.762 178.425 258.451Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M178.425 258.451C178.358 260.132 183.372 261.726 189.6 262.012C195.829 262.297 200.977 261.155 201.044 259.474C201.111 257.793 196.097 256.191 189.868 255.906C183.64 255.62 178.492 256.762 178.425 258.451Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M179.87 258.396C179.865 258.505 179.9 258.613 179.967 258.697C180.712 257.706 184.862 257.111 189.809 257.349C194.756 257.587 198.846 258.522 199.494 259.577C199.562 259.498 199.604 259.398 199.614 259.292C199.658 258.15 195.277 257 189.816 256.754C184.355 256.508 179.915 257.254 179.87 258.396Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M179.87 258.396C179.865 258.505 179.9 258.613 179.967 258.697C180.712 257.706 184.862 257.111 189.809 257.349C194.756 257.587 198.846 258.522 199.494 259.577C199.562 259.498 199.604 259.398 199.614 259.292C199.658 258.15 195.277 257 189.816 256.754C184.355 256.508 179.915 257.254 179.87 258.396Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M179.967 258.697C180.637 259.752 184.72 260.703 189.652 260.925C194.585 261.147 198.749 260.568 199.494 259.577C198.831 258.522 194.756 257.571 189.809 257.349C184.862 257.127 180.719 257.722 179.967 258.697Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M203.316 258.221C203.383 259.902 198.369 261.504 192.141 261.782C185.912 262.059 180.779 260.894 180.675 259.244C180.57 257.595 185.614 255.961 191.85 255.676C198.086 255.39 203.242 256.532 203.316 258.221Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M202.688 255.963L180.024 256.928L180.139 259.981L202.803 259.016L202.688 255.963Z" fill="#407BFF" />
                                            <path
                                                d="M203.19 255.168C203.257 256.849 198.243 258.451 192.014 258.729C185.786 259.006 180.637 257.88 180.57 256.191C180.503 254.502 185.517 252.908 191.746 252.623C197.974 252.337 203.13 253.479 203.19 255.168Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M203.19 255.168C203.257 256.849 198.243 258.451 192.014 258.729C185.786 259.006 180.637 257.88 180.57 256.191C180.503 254.502 185.517 252.908 191.746 252.623C197.974 252.337 203.13 253.479 203.19 255.168Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M201.744 255.113C201.751 255.223 201.716 255.331 201.648 255.414C200.903 254.423 196.753 253.828 191.806 254.066C186.858 254.304 182.768 255.239 182.12 256.302C182.053 256.219 182.012 256.117 182.001 256.009C181.956 254.867 186.337 253.725 191.798 253.479C197.259 253.233 201.7 253.971 201.744 255.113Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M201.744 255.113C201.751 255.223 201.716 255.331 201.648 255.414C200.903 254.423 196.753 253.828 191.806 254.066C186.858 254.304 182.768 255.239 182.12 256.302C182.053 256.219 182.012 256.117 182.001 256.009C181.956 254.867 186.337 253.725 191.798 253.479C197.259 253.233 201.7 253.971 201.744 255.113Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M201.64 255.414C200.97 256.469 196.887 257.42 191.955 257.65C187.022 257.88 182.858 257.293 182.112 256.302C182.776 255.24 186.851 254.288 191.798 254.066C196.745 253.844 200.895 254.423 201.64 255.414Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M205.693 253.598C205.76 255.287 200.746 256.881 194.517 257.159C188.289 257.436 183.141 256.31 183.074 254.621C183.007 252.932 188.021 251.338 194.249 251.06C200.478 250.783 205.626 251.909 205.693 253.598Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M205.572 250.538L182.928 251.56L183.05 254.611L205.694 253.588L205.572 250.538Z" fill="#407BFF" />
                                            <path
                                                d="M205.566 250.585C205.633 252.274 200.627 253.868 194.391 254.153C188.155 254.439 183.021 253.297 182.954 251.608C182.887 249.919 187.894 248.325 194.13 248.047C200.366 247.77 205.499 248.856 205.566 250.585Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M205.566 250.585C205.633 252.274 200.627 253.868 194.391 254.153C188.155 254.439 183.021 253.297 182.954 251.608C182.887 249.919 187.894 248.325 194.13 248.047C200.366 247.77 205.499 248.856 205.566 250.585Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M204.129 250.49C204.127 250.598 204.093 250.704 204.032 250.791C203.287 249.8 199.129 249.205 194.19 249.443C189.25 249.681 185.145 250.624 184.504 251.679C184.43 251.601 184.388 251.496 184.385 251.386C184.333 250.244 188.721 249.102 194.175 248.856C199.629 248.61 204.076 249.348 204.129 250.49Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M204.129 250.49C204.127 250.598 204.093 250.704 204.032 250.791C203.287 249.8 199.129 249.205 194.19 249.443C189.25 249.681 185.145 250.624 184.504 251.679C184.43 251.601 184.388 251.496 184.385 251.386C184.333 250.244 188.721 249.102 194.175 248.856C199.629 248.61 204.076 249.348 204.129 250.49Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M204.032 250.791C203.354 251.845 199.278 252.805 194.346 253.027C189.414 253.249 185.242 252.67 184.504 251.679C185.16 250.624 189.243 249.665 194.19 249.443C199.137 249.221 203.272 249.792 204.032 250.791Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M205.365 248.031C205.432 249.713 200.426 251.314 194.19 251.592C187.954 251.869 182.82 250.743 182.753 249.054C182.686 247.365 187.693 245.771 193.929 245.486C200.165 245.2 205.313 246.342 205.365 248.031Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M205.261 244.982L182.617 246.004L182.739 249.055L205.383 248.032L205.261 244.982Z" fill="#407BFF" />
                                            <path
                                                d="M205.261 244.978C205.328 246.667 200.314 248.261 194.085 248.539C187.857 248.816 182.709 247.69 182.641 246.001C182.574 244.312 187.589 242.718 193.817 242.441C200.046 242.163 205.194 243.289 205.261 244.978Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M205.261 244.978C205.328 246.667 200.314 248.261 194.085 248.539C187.857 248.816 182.709 247.69 182.641 246.001C182.574 244.312 187.589 242.718 193.817 242.441C200.046 242.163 205.194 243.289 205.261 244.978Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M203.816 244.923C203.822 245.033 203.787 245.141 203.719 245.224C202.974 244.233 198.824 243.638 193.877 243.876C188.93 244.114 184.839 245.057 184.191 246.112C184.124 246.029 184.083 245.927 184.072 245.819C184.027 244.677 188.408 243.535 193.869 243.289C199.33 243.043 203.771 243.781 203.816 244.923Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M203.816 244.923C203.822 245.033 203.787 245.141 203.719 245.224C202.974 244.233 198.824 243.638 193.877 243.876C188.93 244.114 184.839 245.057 184.191 246.112C184.124 246.029 184.083 245.927 184.072 245.819C184.027 244.677 188.408 243.535 193.869 243.289C199.33 243.043 203.771 243.781 203.816 244.923Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M203.719 245.224C203.048 246.279 198.965 247.238 194.033 247.46C189.101 247.682 184.936 247.103 184.191 246.112C184.854 245.057 188.93 244.098 193.877 243.876C198.824 243.654 202.966 244.241 203.719 245.224Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M207.764 243.448C207.831 245.137 202.817 246.731 196.589 247.016C190.36 247.302 185.212 246.16 185.145 244.471C185.078 242.782 190.092 241.188 196.32 240.91C202.549 240.633 207.697 241.719 207.764 243.448Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M207.623 240.366L184.979 241.389L185.101 244.439L207.744 243.417L207.623 240.366Z" fill="#407BFF" />
                                            <path
                                                d="M207.638 240.355C207.705 242.044 202.691 243.638 196.462 243.924C190.233 244.209 185.085 243.067 185.018 241.378C184.951 239.689 189.965 238.095 196.194 237.818C202.422 237.54 207.6 238.69 207.638 240.355Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M207.638 240.355C207.705 242.044 202.691 243.638 196.462 243.924C190.233 244.209 185.085 243.067 185.018 241.378C184.951 239.689 189.965 238.095 196.194 237.818C202.422 237.54 207.6 238.69 207.638 240.355Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M206.2 240.276C206.197 240.382 206.163 240.485 206.103 240.569C205.358 239.578 201.201 238.983 196.261 239.221C191.321 239.459 187.216 240.403 186.575 241.457C186.502 241.378 186.457 241.274 186.449 241.164C186.404 240.022 190.785 238.88 196.246 238.634C201.707 238.389 206.148 239.166 206.2 240.276Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M206.2 240.276C206.197 240.382 206.163 240.485 206.103 240.569C205.358 239.578 201.201 238.983 196.261 239.221C191.321 239.459 187.216 240.403 186.575 241.457C186.502 241.378 186.457 241.274 186.449 241.164C186.404 240.022 190.785 238.88 196.246 238.634C201.707 238.389 206.148 239.166 206.2 240.276Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M206.11 240.601C205.432 241.664 201.357 242.615 196.425 242.837C191.493 243.059 187.32 242.48 186.583 241.489C187.238 240.434 191.321 239.475 196.268 239.253C201.215 239.031 205.365 239.61 206.11 240.601Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M204.128 239.483C204.196 241.172 199.181 242.766 192.953 243.051C186.724 243.337 181.524 242.203 181.524 240.514C181.524 238.825 186.471 237.231 192.7 236.953C198.928 236.676 204.061 237.81 204.128 239.483Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M204.013 236.434L181.369 237.457L181.491 240.507L204.135 239.484L204.013 236.434Z" fill="#407BFF" />
                                            <path
                                                d="M204.009 236.438C204.076 238.127 199.062 239.721 192.834 240.006C186.605 240.292 181.457 239.15 181.39 237.461C181.323 235.772 186.337 234.178 192.565 233.9C198.794 233.623 203.942 234.725 204.009 236.438Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M204.009 236.438C204.076 238.127 199.062 239.721 192.834 240.006C186.605 240.292 181.457 239.15 181.39 237.461C181.323 235.772 186.337 234.178 192.565 233.9C198.794 233.623 203.942 234.725 204.009 236.438Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M202.564 236.39C202.568 236.498 202.533 236.603 202.467 236.684C201.722 235.692 197.572 235.098 192.625 235.336C187.678 235.573 183.588 236.517 182.939 237.572C182.869 237.492 182.826 237.388 182.82 237.278C182.776 236.136 187.156 234.995 192.618 234.749C198.079 234.503 202.519 235.248 202.564 236.39Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M202.564 236.39C202.568 236.498 202.533 236.603 202.467 236.684C201.722 235.692 197.572 235.098 192.625 235.336C187.678 235.573 183.588 236.517 182.939 237.572C182.869 237.492 182.826 237.388 182.82 237.278C182.776 236.136 187.156 234.995 192.618 234.749C198.079 234.503 202.519 235.248 202.564 236.39Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M202.467 236.684C201.789 237.746 197.714 238.698 192.782 238.92C187.849 239.142 183.685 238.563 182.94 237.572C183.603 236.517 187.678 235.565 192.625 235.335C197.572 235.105 201.707 235.692 202.467 236.684Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M206.513 234.868C206.58 236.557 201.566 238.151 195.337 238.436C189.108 238.722 183.968 237.58 183.901 235.891C183.834 234.202 188.84 232.608 195.076 232.33C201.312 232.053 206.438 233.187 206.513 234.868Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M206.383 231.827L183.739 232.849L183.86 235.9L206.504 234.877L206.383 231.827Z" fill="#407BFF" />
                                            <path
                                                d="M206.386 231.815C206.453 233.504 201.439 235.098 195.21 235.383C188.982 235.669 183.834 234.527 183.766 232.838C183.699 231.148 188.714 229.563 194.942 229.277C201.171 228.992 206.319 230.133 206.386 231.815Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M206.386 231.815C206.453 233.504 201.439 235.098 195.21 235.383C188.982 235.669 183.834 234.527 183.766 232.838C183.699 231.148 188.714 229.563 194.942 229.277C201.171 228.992 206.319 230.133 206.386 231.815Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M204.941 231.767C204.944 231.877 204.909 231.984 204.844 232.068C204.099 231.077 199.949 230.482 195.002 230.712C190.055 230.942 185.964 231.894 185.316 232.949C185.248 232.867 185.206 232.764 185.197 232.655C185.152 231.513 189.533 230.371 194.994 230.126C200.455 229.88 204.896 230.625 204.941 231.767Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M204.941 231.767C204.944 231.877 204.909 231.984 204.844 232.068C204.099 231.077 199.949 230.482 195.002 230.712C190.055 230.942 185.964 231.894 185.316 232.949C185.248 232.867 185.206 232.764 185.197 232.655C185.152 231.513 189.533 230.371 194.994 230.126C200.455 229.88 204.896 230.625 204.941 231.767Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M204.844 232.068C204.173 233.123 200.09 234.075 195.158 234.297C190.226 234.519 186.061 233.94 185.316 232.949C185.979 231.894 190.055 230.942 195.002 230.712C199.949 230.482 204.091 231.077 204.844 232.068Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M197.076 231.413C202.355 229.306 205.03 223.045 203.051 217.426C201.072 211.808 195.189 208.961 189.91 211.067C184.632 213.173 181.957 219.435 183.936 225.053C185.915 230.672 191.798 233.519 197.076 231.413Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M202.392 228.818C206.045 224.249 205.527 217.392 201.234 213.503C196.941 209.615 190.499 210.166 186.846 214.735C183.192 219.305 183.71 226.161 188.003 230.05C192.296 233.939 198.738 233.387 202.392 228.818Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M202.392 228.818C206.045 224.249 205.527 217.392 201.234 213.503C196.941 209.615 190.499 210.166 186.846 214.735C183.192 219.305 183.71 226.161 188.003 230.05C192.296 233.939 198.738 233.387 202.392 228.818Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M201.573 225.122C200.947 226.585 199.928 227.817 198.644 228.663C197.361 229.51 195.87 229.932 194.361 229.877C192.852 229.822 191.392 229.292 190.165 228.354C188.939 227.415 188.002 226.111 187.472 224.606C186.942 223.101 186.843 221.463 187.188 219.898C187.533 218.333 188.305 216.912 189.409 215.815C190.512 214.718 191.896 213.993 193.386 213.733C194.877 213.473 196.406 213.688 197.781 214.353C199.625 215.247 201.06 216.883 201.771 218.902C202.482 220.921 202.411 223.158 201.573 225.122Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.3"
                                                d="M198.161 224.17C198.043 223.675 197.782 223.231 197.416 222.901C197.805 222.884 198.183 222.749 198.503 222.513C198.829 222.274 199.087 221.945 199.248 221.561C199.389 221.251 199.454 220.909 199.439 220.566C199.423 220.222 199.327 219.888 199.159 219.595C198.839 219.059 198.395 218.619 197.87 218.318L198.131 217.707L197.252 217.287L197.013 217.85L196.134 217.422L196.373 216.859L195.493 216.431L195.255 217.002L193.348 216.074L189.771 224.456L191.671 225.383L191.418 225.978L192.297 226.398L192.55 225.804L193.43 226.232L193.176 226.827L194.055 227.247L194.324 226.628C194.987 226.945 195.718 227.063 196.44 226.969C196.771 226.904 197.081 226.751 197.342 226.523C197.602 226.296 197.805 226.002 197.93 225.669C198.164 225.212 198.245 224.683 198.161 224.17V224.17ZM194.555 218.413L196.365 219.293C196.731 219.44 197.049 219.696 197.282 220.031C197.357 220.168 197.396 220.324 197.396 220.483C197.396 220.642 197.357 220.798 197.282 220.935C197.23 221.085 197.141 221.218 197.024 221.318C196.908 221.419 196.767 221.484 196.618 221.506C196.227 221.549 195.833 221.466 195.486 221.268L193.676 220.388L194.555 218.413ZM195.538 225.177C195.124 225.207 194.711 225.111 194.346 224.9L192.223 223.877L193.102 221.799L195.233 222.83C196.194 223.298 196.529 223.885 196.238 224.575C196.187 224.738 196.094 224.883 195.971 224.995C195.847 225.107 195.698 225.181 195.538 225.209V225.177Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M290.822 283.272C290.591 284.263 287.432 284.271 283.766 283.272C280.101 282.273 277.314 280.695 277.545 279.711C277.776 278.728 280.943 278.712 284.608 279.711C288.274 280.71 291.045 282.304 290.822 283.272Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M277.975 277.95L277.553 279.742L290.83 283.281L291.251 281.489L277.975 277.95Z" fill="#407BFF" />
                                            <path
                                                d="M291.246 281.511C291.008 282.503 287.849 282.51 284.183 281.511C280.518 280.512 277.739 278.942 277.97 277.951C278.201 276.96 281.36 276.952 285.025 277.951C288.691 278.95 291.477 280.496 291.246 281.511Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M291.246 281.511C291.008 282.503 287.849 282.51 284.183 281.511C280.518 280.512 277.739 278.942 277.97 277.951C278.201 276.96 281.36 276.952 285.025 277.951C288.691 278.95 291.477 280.496 291.246 281.511Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M290.412 281.186C290.406 281.219 290.393 281.25 290.375 281.277C290.357 281.304 290.334 281.327 290.308 281.345C290.032 280.631 287.73 279.537 284.832 278.768C281.933 277.998 279.408 277.8 278.871 278.292C278.857 278.229 278.857 278.164 278.871 278.101C279.035 277.427 281.755 277.57 284.958 278.419C288.162 279.267 290.576 280.52 290.412 281.186Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M290.412 281.186C290.406 281.219 290.393 281.25 290.375 281.277C290.357 281.304 290.334 281.327 290.308 281.345C290.032 280.631 287.73 279.537 284.832 278.768C281.933 277.998 279.408 277.8 278.871 278.292C278.857 278.229 278.857 278.164 278.871 278.101C279.035 277.427 281.755 277.57 284.958 278.419C288.162 279.267 290.576 280.52 290.412 281.186Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M290.3 281.345C289.741 281.828 287.231 281.638 284.34 280.869C281.449 280.1 279.125 278.997 278.856 278.292C279.415 277.8 281.926 277.99 284.817 278.768C287.707 279.545 290.032 280.631 290.3 281.345Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M297.199 286.523C297.244 287.538 294.219 288.505 290.434 288.672C286.65 288.839 283.565 288.157 283.528 287.142C283.491 286.127 286.508 285.159 290.293 284.985C294.078 284.81 297.162 285.476 297.199 286.523Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M297.111 284.694L283.437 285.311L283.51 287.157L297.184 286.54L297.111 284.694Z" fill="#407BFF" />
                                            <path
                                                d="M297.125 284.683C297.17 285.706 294.145 286.666 290.367 286.84C286.59 287.015 283.498 286.325 283.453 285.302C283.409 284.279 286.434 283.319 290.218 283.153C294.003 282.986 297.088 283.66 297.125 284.683Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M297.125 284.683C297.17 285.706 294.145 286.666 290.367 286.84C286.59 287.015 283.498 286.325 283.453 285.302C283.409 284.279 286.434 283.319 290.218 283.153C294.003 282.986 297.088 283.66 297.125 284.683Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M296.261 284.683C296.264 284.716 296.26 284.75 296.25 284.781C296.239 284.813 296.223 284.842 296.201 284.866C295.746 284.271 293.221 283.914 290.241 284.073C287.26 284.231 284.779 284.786 284.377 285.421C284.336 285.373 284.312 285.311 284.31 285.246C284.31 284.556 286.925 283.867 290.226 283.716C293.526 283.565 296.261 283.954 296.261 284.683Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M296.261 284.683C296.264 284.716 296.26 284.75 296.25 284.781C296.239 284.813 296.223 284.842 296.201 284.866C295.746 284.271 293.221 283.914 290.241 284.073C287.26 284.231 284.779 284.786 284.377 285.421C284.336 285.373 284.312 285.311 284.31 285.246C284.31 284.556 286.925 283.867 290.226 283.716C293.526 283.565 296.261 283.954 296.261 284.683Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M296.193 284.826C295.791 285.46 293.325 286.039 290.337 286.174C287.35 286.309 284.846 285.96 284.377 285.381C284.779 284.747 287.246 284.168 290.241 284.033C293.236 283.898 295.739 284.231 296.193 284.826Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M290.004 282.889C293.097 277.873 291.784 271.138 287.071 267.846C282.359 264.553 276.031 265.95 272.937 270.966C269.844 275.982 271.157 282.718 275.87 286.01C280.582 289.302 286.91 287.905 290.004 282.889Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M290.017 285.064C288.674 286.668 286.914 287.812 284.96 288.352C283.005 288.891 280.945 288.802 279.038 288.095C277.132 287.388 275.465 286.095 274.249 284.38C273.033 282.664 272.322 280.604 272.206 278.458C272.091 276.313 272.575 274.179 273.599 272.327C274.623 270.475 276.14 268.988 277.957 268.053C279.775 267.119 281.813 266.779 283.812 267.077C285.812 267.375 287.683 268.297 289.19 269.727C291.21 271.645 292.432 274.337 292.587 277.213C292.742 280.089 291.818 282.913 290.017 285.064V285.064Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M290.017 285.064C288.674 286.668 286.914 287.812 284.96 288.352C283.005 288.891 280.945 288.802 279.038 288.095C277.132 287.388 275.465 286.095 274.249 284.38C273.033 282.664 272.322 280.604 272.206 278.458C272.091 276.313 272.575 274.179 273.599 272.327C274.623 270.475 276.14 268.988 277.957 268.053C279.775 267.119 281.813 266.779 283.812 267.077C285.812 267.375 287.683 268.297 289.19 269.727C291.21 271.645 292.432 274.337 292.587 277.213C292.742 280.089 291.818 282.913 290.017 285.064V285.064Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M288.065 283.248C287.059 284.45 285.741 285.307 284.277 285.711C282.814 286.115 281.27 286.049 279.842 285.519C278.414 284.99 277.166 284.021 276.255 282.737C275.345 281.452 274.812 279.908 274.726 278.302C274.639 276.695 275.002 275.097 275.769 273.71C276.536 272.323 277.672 271.209 279.034 270.509C280.396 269.809 281.922 269.555 283.419 269.779C284.917 270.003 286.318 270.694 287.447 271.765C288.196 272.476 288.807 273.336 289.243 274.298C289.68 275.26 289.935 276.304 289.992 277.37C290.049 278.437 289.909 279.505 289.578 280.513C289.248 281.522 288.733 282.451 288.065 283.248V283.248Z"
                                                fill="#407BFF"
                                            />
                                            <g opacity="0.3">
                                                <path
                                                    d="M283.818 281.511C282.909 283.097 281.293 283.137 279.616 281.797L278.663 282.883L277.709 281.987L278.67 280.901C277.906 280.064 277.337 279.048 277.009 277.935L278.499 277.086C278.743 278.028 279.192 278.894 279.81 279.616L281.3 277.951C280.607 276.793 279.892 275.437 280.712 274.025C281.613 272.479 283.26 272.439 284.951 273.795L285.912 272.709L286.858 273.613L285.897 274.707C286.492 275.376 286.954 276.167 287.253 277.031L285.83 277.919C285.613 277.195 285.252 276.53 284.772 275.968L283.245 277.713C283.9 278.855 284.593 280.163 283.818 281.511ZM282.507 276.539L283.759 275.12C283.14 274.731 282.626 274.803 282.358 275.239C282.275 275.449 282.245 275.679 282.271 275.906C282.297 276.133 282.378 276.348 282.507 276.531V276.539ZM282.105 280.361C282.186 280.163 282.215 279.945 282.19 279.73C282.165 279.516 282.087 279.312 281.963 279.14L280.771 280.488C281.36 280.837 281.844 280.79 282.105 280.354V280.361Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <path
                                                d="M156.028 287.53C155.991 288.545 159.009 289.513 162.793 289.679C166.578 289.846 169.663 289.164 169.7 288.149C169.737 287.134 166.72 286.166 162.942 285.992C159.165 285.817 156.073 286.507 156.028 287.53Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M156.107 285.69L156.034 287.536L169.708 288.154L169.782 286.308L156.107 285.69Z" fill="#407BFF" />
                                            <path
                                                d="M156.103 285.682C156.058 286.705 159.083 287.665 162.868 287.839C166.653 288.014 169.737 287.324 169.775 286.301C169.812 285.278 166.794 284.318 163.01 284.152C159.225 283.985 156.14 284.683 156.103 285.682Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M156.103 285.682C156.058 286.705 159.083 287.665 162.868 287.839C166.653 288.014 169.737 287.324 169.775 286.301C169.812 285.278 166.794 284.318 163.01 284.152C159.225 283.985 156.14 284.683 156.103 285.682Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M156.975 285.651C156.975 285.717 156.996 285.781 157.034 285.833C157.489 285.238 160.015 284.881 162.995 285.04C165.975 285.199 168.448 285.754 168.851 286.396C168.893 286.345 168.919 286.281 168.925 286.214C168.925 285.524 166.303 284.834 163.01 284.691C159.716 284.548 157.005 284.961 156.975 285.651Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M156.975 285.651C156.975 285.717 156.996 285.781 157.034 285.833C157.489 285.238 160.015 284.881 162.995 285.04C165.975 285.199 168.448 285.754 168.851 286.396C168.893 286.345 168.919 286.281 168.925 286.214C168.925 285.524 166.303 284.834 163.01 284.691C159.716 284.548 157.005 284.961 156.975 285.651Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M157.034 285.833C157.444 286.475 159.903 287.046 162.89 287.181C165.878 287.316 168.389 286.967 168.851 286.388C168.448 285.746 165.99 285.175 162.995 285.032C160 284.89 157.489 285.238 157.034 285.833Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M154.591 284.739C154.591 285.754 157.571 286.721 161.356 286.888C165.14 287.054 168.225 286.372 168.262 285.357C168.299 284.342 165.282 283.375 161.505 283.2C157.727 283.026 154.635 283.724 154.591 284.739Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M154.656 282.875L154.582 284.721L168.256 285.338L168.33 283.492L154.656 282.875Z" fill="#407BFF" />
                                            <path
                                                d="M154.702 282.891C154.702 283.914 157.683 284.873 161.467 285.048C165.252 285.222 168.337 284.532 168.374 283.509C168.411 282.487 165.394 281.527 161.616 281.36C157.839 281.194 154.702 281.876 154.702 282.891Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M154.702 282.891C154.702 283.914 157.683 284.873 161.467 285.048C165.252 285.222 168.337 284.532 168.374 283.509C168.411 282.487 165.394 281.527 161.616 281.36C157.839 281.194 154.702 281.876 154.702 282.891Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M155.537 282.859C155.537 282.925 155.558 282.99 155.596 283.042C156.051 282.447 158.577 282.098 161.557 282.249C164.537 282.399 167.01 282.955 167.413 283.597C167.455 283.546 167.481 283.482 167.487 283.414C167.487 282.732 164.865 282.035 161.572 281.892C158.279 281.749 155.567 282.169 155.537 282.859Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M155.537 282.859C155.537 282.925 155.558 282.99 155.596 283.042C156.051 282.447 158.577 282.098 161.557 282.249C164.537 282.399 167.01 282.955 167.413 283.597C167.455 283.546 167.481 283.482 167.487 283.414C167.487 282.732 164.865 282.035 161.572 281.892C158.279 281.749 155.567 282.169 155.537 282.859Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M155.596 283.042C156.006 283.684 158.465 284.255 161.452 284.39C164.44 284.525 166.951 284.176 167.413 283.597C167.01 282.954 164.552 282.383 161.557 282.249C158.562 282.114 156.051 282.447 155.596 283.042Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M154.777 281.376C154.777 282.391 157.757 283.359 161.542 283.525C165.327 283.692 168.411 283.01 168.448 281.995C168.486 280.98 165.468 280.012 161.691 279.838C157.913 279.664 154.822 280.361 154.777 281.376Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M154.838 279.513L154.764 281.359L168.438 281.976L168.512 280.13L154.838 279.513Z" fill="#407BFF" />
                                            <path
                                                d="M154.851 279.529C154.851 280.552 157.832 281.511 161.616 281.686C165.401 281.86 168.486 281.17 168.523 280.147C168.56 279.124 165.543 278.165 161.765 277.998C157.988 277.832 154.896 278.514 154.851 279.529Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M154.851 279.529C154.851 280.552 157.832 281.511 161.616 281.686C165.401 281.86 168.486 281.17 168.523 280.147C168.56 279.124 165.543 278.165 161.765 277.998C157.988 277.832 154.896 278.514 154.851 279.529Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M155.723 279.497C155.724 279.563 155.744 279.627 155.783 279.68C156.237 279.085 158.763 278.736 161.743 278.887C164.723 279.037 167.197 279.592 167.599 280.235C167.642 280.184 167.668 280.12 167.674 280.052C167.674 279.37 165.051 278.672 161.758 278.53C158.465 278.387 155.753 278.815 155.723 279.497Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M155.723 279.497C155.724 279.563 155.744 279.627 155.783 279.68C156.237 279.085 158.763 278.736 161.743 278.887C164.723 279.037 167.197 279.592 167.599 280.235C167.642 280.184 167.668 280.12 167.674 280.052C167.674 279.37 165.051 278.672 161.758 278.53C158.465 278.387 155.753 278.815 155.723 279.497Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M155.783 279.68C156.192 280.322 158.651 280.893 161.639 281.028C164.626 281.162 167.137 280.814 167.599 280.235C167.197 279.592 164.738 279.021 161.743 278.887C158.748 278.752 156.237 279.085 155.783 279.68Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M153.339 278.585C153.339 279.6 156.319 280.568 160.104 280.734C163.889 280.901 166.973 280.219 167.018 279.204C167.063 278.189 164.038 277.221 160.253 277.047C156.468 276.872 153.384 277.546 153.339 278.585Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M153.409 276.728L153.335 278.574L167.009 279.192L167.083 277.346L153.409 276.728Z" fill="#407BFF" />
                                            <path
                                                d="M153.413 276.753C153.376 277.776 156.394 278.736 160.178 278.91C163.963 279.085 167.048 278.395 167.085 277.372C167.122 276.349 164.105 275.397 160.327 275.223C156.55 275.048 153.458 275.722 153.413 276.753Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M153.413 276.753C153.376 277.776 156.394 278.736 160.178 278.91C163.963 279.085 167.048 278.395 167.085 277.372C167.122 276.349 164.105 275.397 160.327 275.223C156.55 275.048 153.458 275.722 153.413 276.753Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M154.285 276.706C154.286 276.772 154.307 276.836 154.345 276.888C154.807 276.293 157.325 275.945 160.305 276.095C163.285 276.246 165.766 276.801 166.161 277.443C166.205 277.397 166.232 277.335 166.236 277.269C166.236 276.579 163.613 275.881 160.32 275.738C157.027 275.596 154.315 276.024 154.285 276.706Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M154.285 276.706C154.286 276.772 154.307 276.836 154.345 276.888C154.807 276.293 157.325 275.945 160.305 276.095C163.285 276.246 165.766 276.801 166.161 277.443C166.205 277.397 166.232 277.335 166.236 277.269C166.236 276.579 163.613 275.881 160.32 275.738C157.027 275.596 154.315 276.024 154.285 276.706Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M154.345 276.888C154.755 277.531 157.213 278.101 160.201 278.236C163.188 278.371 165.699 278.022 166.161 277.443C165.766 276.801 163.3 276.23 160.305 276.095C157.31 275.96 154.807 276.293 154.345 276.888Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M155.537 276.222C155.492 277.237 158.517 278.205 162.302 278.371C166.087 278.538 169.171 277.856 169.208 276.841C169.246 275.826 166.228 274.858 162.443 274.684C158.658 274.509 155.574 275.167 155.537 276.222Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M155.597 274.354L155.523 276.2L169.198 276.818L169.271 274.972L155.597 274.354Z" fill="#407BFF" />
                                            <path
                                                d="M155.611 274.374C155.567 275.397 158.591 276.357 162.369 276.531C166.146 276.706 169.238 276.016 169.283 274.993C169.328 273.97 166.303 273.01 162.518 272.844C158.733 272.677 155.649 273.359 155.611 274.374Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M155.611 274.374C155.567 275.397 158.591 276.357 162.369 276.531C166.146 276.706 169.238 276.016 169.283 274.993C169.328 273.97 166.303 273.01 162.518 272.844C158.733 272.677 155.649 273.359 155.611 274.374Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M156.483 274.374C156.48 274.408 156.484 274.441 156.494 274.473C156.504 274.504 156.521 274.533 156.543 274.557C156.997 273.962 159.523 273.605 162.503 273.764C165.483 273.922 167.964 274.477 168.366 275.112C168.408 275.064 168.432 275.002 168.433 274.937C168.433 274.248 165.818 273.558 162.518 273.407C159.217 273.256 156.505 273.653 156.483 274.374Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M156.483 274.374C156.48 274.408 156.484 274.441 156.494 274.473C156.504 274.504 156.521 274.533 156.543 274.557C156.997 273.962 159.523 273.605 162.503 273.764C165.483 273.922 167.964 274.477 168.366 275.112C168.408 275.064 168.432 275.002 168.433 274.937C168.433 274.248 165.818 273.558 162.518 273.407C159.217 273.256 156.505 273.653 156.483 274.374Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M156.543 274.525C156.945 275.159 159.411 275.738 162.399 275.873C165.386 276.008 167.89 275.659 168.359 275.08C167.957 274.446 165.491 273.867 162.496 273.732C159.5 273.597 156.997 273.93 156.543 274.525Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M145.896 287.364C146.127 288.355 149.293 288.363 152.959 287.364C156.625 286.364 159.404 284.794 159.173 283.803C158.942 282.812 155.783 282.804 152.117 283.803C148.451 284.802 145.665 286.38 145.896 287.364Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M158.754 282.033L145.478 285.572L145.9 287.364L159.176 283.825L158.754 282.033Z" fill="#407BFF" />
                                            <path
                                                d="M145.479 285.579C145.71 286.563 148.869 286.579 152.534 285.579C156.2 284.58 158.986 283.01 158.748 282.027C158.51 281.043 155.358 281.028 151.692 282.027C148.027 283.026 145.24 284.588 145.479 285.579Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M145.479 285.579C145.71 286.563 148.869 286.579 152.534 285.579C156.2 284.58 158.986 283.01 158.748 282.027C158.51 281.043 155.358 281.028 151.692 282.027C148.027 283.026 145.24 284.588 145.479 285.579Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M146.306 285.286C146.322 285.348 146.359 285.402 146.41 285.437C146.686 284.731 148.988 283.636 151.894 282.859C154.799 282.082 157.31 281.892 157.854 282.384C157.876 282.322 157.876 282.254 157.854 282.193C157.697 281.527 154.971 281.662 151.774 282.518C148.578 283.375 146.149 284.612 146.306 285.286Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M146.306 285.286C146.322 285.348 146.359 285.402 146.41 285.437C146.686 284.731 148.988 283.636 151.894 282.859C154.799 282.082 157.31 281.892 157.854 282.384C157.876 282.322 157.876 282.254 157.854 282.193C157.697 281.527 154.971 281.662 151.774 282.518C148.578 283.375 146.149 284.612 146.306 285.286Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M146.41 285.476C146.969 285.968 149.487 285.778 152.37 285C155.254 284.223 157.586 283.137 157.854 282.423C157.295 281.932 154.784 282.13 151.893 282.899C149.003 283.668 146.686 284.731 146.41 285.476Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M139.556 287.53C139.556 288.545 142.536 289.513 146.321 289.679C150.105 289.846 153.19 289.164 153.227 288.149C153.264 287.134 150.247 286.166 146.47 285.992C142.692 285.817 139.6 286.507 139.556 287.53Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M139.622 285.667L139.548 287.513L153.222 288.13L153.296 286.284L139.622 285.667Z" fill="#407BFF" />
                                            <path
                                                d="M139.63 285.682C139.593 286.705 142.61 287.665 146.395 287.839C150.18 288.014 153.264 287.324 153.302 286.301C153.339 285.278 150.321 284.318 146.544 284.152C142.767 283.985 139.675 284.683 139.63 285.682Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M139.63 285.682C139.593 286.705 142.61 287.665 146.395 287.839C150.18 288.014 153.264 287.324 153.302 286.301C153.339 285.278 150.321 284.318 146.544 284.152C142.767 283.985 139.675 284.683 139.63 285.682Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M140.502 285.651C140.502 285.717 140.523 285.781 140.561 285.833C141.016 285.238 143.542 284.881 146.522 285.04C149.502 285.199 151.975 285.754 152.378 286.396C152.42 286.345 152.446 286.281 152.452 286.214C152.452 285.524 149.83 284.834 146.537 284.691C143.244 284.548 140.547 284.961 140.502 285.651Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M140.502 285.651C140.502 285.717 140.523 285.781 140.561 285.833C141.016 285.238 143.542 284.881 146.522 285.04C149.502 285.199 151.975 285.754 152.378 286.396C152.42 286.345 152.446 286.281 152.452 286.214C152.452 285.524 149.83 284.834 146.537 284.691C143.244 284.548 140.547 284.961 140.502 285.651Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M140.547 285.833C140.956 286.475 143.415 287.046 146.403 287.181C149.39 287.316 151.901 286.967 152.363 286.388C151.961 285.746 149.502 285.175 146.507 285.032C143.512 284.89 141.016 285.238 140.547 285.833Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M210.178 287.443C210.134 288.49 213.248 289.481 217.13 289.655C221.011 289.83 224.193 289.124 224.237 288.069C224.282 287.015 221.16 286.039 217.279 285.857C213.397 285.675 210.216 286.396 210.178 287.443Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M210.238 285.523L210.162 287.417L224.224 288.052L224.299 286.159L210.238 285.523Z" fill="#407BFF" />
                                            <path
                                                d="M210.253 285.548C210.208 286.594 213.322 287.586 217.204 287.76C221.086 287.934 224.267 287.229 224.312 286.174C224.356 285.119 221.242 284.144 217.353 283.962C213.464 283.779 210.29 284.501 210.253 285.548Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M210.253 285.548C210.208 286.594 213.322 287.586 217.204 287.76C221.086 287.934 224.267 287.229 224.312 286.174C224.356 285.119 221.242 284.144 217.353 283.962C213.464 283.779 210.29 284.501 210.253 285.548Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M211.147 285.516C211.148 285.584 211.169 285.651 211.206 285.706C211.676 285.088 214.246 284.731 217.323 284.866C220.4 285.001 222.933 285.595 223.343 286.253C223.389 286.205 223.415 286.14 223.418 286.071C223.418 285.365 220.728 284.652 217.338 284.485C213.948 284.319 211.177 284.81 211.147 285.516Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M211.147 285.516C211.148 285.584 211.169 285.651 211.206 285.706C211.676 285.088 214.246 284.731 217.323 284.866C220.4 285.001 222.933 285.595 223.343 286.253C223.389 286.205 223.415 286.14 223.418 286.071C223.418 285.365 220.728 284.652 217.338 284.485C213.948 284.319 211.177 284.81 211.147 285.516Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M211.206 285.706C211.631 286.357 214.187 286.951 217.234 287.094C220.281 287.237 222.881 286.872 223.343 286.253C222.933 285.595 220.4 285.008 217.323 284.866C214.246 284.723 211.676 285.088 211.206 285.706Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M208.696 284.572C208.658 285.619 211.773 286.61 215.654 286.793C219.536 286.975 222.717 286.261 222.755 285.207C222.792 284.152 219.685 283.169 215.803 282.986C211.922 282.804 208.74 283.525 208.696 284.572Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M208.769 282.672L208.694 284.566L222.755 285.201L222.831 283.308L208.769 282.672Z" fill="#407BFF" />
                                            <path
                                                d="M208.778 282.677C208.733 283.724 211.847 284.715 215.729 284.897C219.611 285.08 222.792 284.366 222.837 283.311C222.881 282.257 219.76 281.273 215.878 281.091C211.996 280.909 208.815 281.63 208.778 282.677Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M208.778 282.677C208.733 283.724 211.847 284.715 215.729 284.897C219.611 285.08 222.792 284.366 222.837 283.311C222.881 282.257 219.76 281.273 215.878 281.091C211.996 280.909 208.815 281.63 208.778 282.677Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M209.672 282.645C209.673 282.714 209.694 282.78 209.731 282.836C210.201 282.217 212.771 281.86 215.841 281.995C218.91 282.13 221.458 282.725 221.868 283.383C221.911 283.332 221.937 283.268 221.942 283.2C221.942 282.495 219.245 281.781 215.855 281.614C212.466 281.448 209.701 281.939 209.672 282.645Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M209.672 282.645C209.673 282.714 209.694 282.78 209.731 282.836C210.201 282.217 212.771 281.86 215.841 281.995C218.91 282.13 221.458 282.725 221.868 283.383C221.911 283.332 221.937 283.268 221.942 283.2C221.942 282.495 219.245 281.781 215.855 281.614C212.466 281.448 209.701 281.939 209.672 282.645Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M209.731 282.836C210.148 283.486 212.711 284.081 215.751 284.223C218.791 284.366 221.406 284.001 221.868 283.383C221.458 282.725 218.925 282.138 215.841 281.995C212.756 281.852 210.201 282.217 209.731 282.836Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M211.87 282.455C211.825 283.494 214.939 284.485 218.821 284.667C222.703 284.85 225.884 284.136 225.929 283.081C225.973 282.027 222.852 281.043 218.97 280.869C215.088 280.694 211.907 281.408 211.87 282.455Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M211.93 280.54L211.855 282.433L225.916 283.068L225.992 281.175L211.93 280.54Z" fill="#407BFF" />
                                            <path
                                                d="M211.944 280.552C211.899 281.599 215.014 282.59 218.895 282.772C222.777 282.955 225.958 282.241 226.003 281.186C226.048 280.132 222.926 279.148 219.044 278.974C215.163 278.799 211.981 279.513 211.944 280.552Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M211.944 280.552C211.899 281.599 215.014 282.59 218.895 282.772C222.777 282.955 225.958 282.241 226.003 281.186C226.048 280.132 222.926 279.148 219.044 278.974C215.163 278.799 211.981 279.513 211.944 280.552Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M212.816 280.52C212.817 280.588 212.838 280.655 212.875 280.71C213.345 280.092 215.915 279.735 218.985 279.87C222.054 280.005 224.602 280.599 225.012 281.257C225.055 281.207 225.081 281.143 225.087 281.075C225.087 280.369 222.39 279.656 219 279.489C215.61 279.323 212.868 279.814 212.816 280.52Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M212.816 280.52C212.817 280.588 212.838 280.655 212.875 280.71C213.345 280.092 215.915 279.735 218.985 279.87C222.054 280.005 224.602 280.599 225.012 281.257C225.055 281.207 225.081 281.143 225.087 281.075C225.087 280.369 222.39 279.656 219 279.489C215.61 279.323 212.868 279.814 212.816 280.52Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M212.898 280.718C213.315 281.376 215.878 281.963 218.918 282.106C221.957 282.249 224.572 281.884 225.034 281.265C224.625 280.607 222.091 280.02 219.007 279.878C215.922 279.735 213.367 280.092 212.898 280.718Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M211.907 279.584C211.862 280.631 214.976 281.622 218.858 281.797C222.74 281.971 225.921 281.265 225.966 280.211C226.01 279.156 222.889 278.173 219.007 277.998C215.125 277.824 211.944 278.538 211.907 279.584Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M211.712 276.708L211.641 278.604L225.715 279.203L225.786 277.308L211.712 276.708Z" fill="#407BFF" />
                                            <path
                                                d="M211.981 277.689C211.937 278.736 215.051 279.727 218.933 279.902C222.814 280.076 225.996 279.37 226.04 278.316C226.085 277.261 222.963 276.278 219.082 276.103C215.2 275.929 212.019 276.642 211.981 277.689Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M211.981 277.689C211.937 278.736 215.051 279.727 218.933 279.902C222.814 280.076 225.996 279.37 226.04 278.316C226.085 277.261 222.963 276.278 219.082 276.103C215.2 275.929 212.019 276.642 211.981 277.689Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M212.875 277.657C212.877 277.723 212.898 277.787 212.935 277.84C213.404 277.221 215.975 276.864 219.044 276.999C222.114 277.134 224.662 277.737 225.072 278.387C225.117 278.338 225.144 278.273 225.146 278.204C225.146 277.499 222.457 276.785 219.059 276.618C215.662 276.452 212.905 276.944 212.875 277.657Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M212.875 277.657C212.877 277.723 212.898 277.787 212.935 277.84C213.404 277.221 215.975 276.864 219.044 276.999C222.114 277.134 224.662 277.737 225.072 278.387C225.117 278.338 225.144 278.273 225.146 278.204C225.146 277.499 222.457 276.785 219.059 276.618C215.662 276.452 212.905 276.944 212.875 277.657Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M212.935 277.84C213.352 278.498 215.915 279.085 218.962 279.227C222.01 279.37 224.61 279.005 225.072 278.387C224.662 277.737 222.129 277.142 219.044 276.999C215.96 276.856 213.404 277.221 212.935 277.84Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M209.016 277.237C208.971 278.284 212.086 279.275 215.967 279.449C219.849 279.624 223.03 278.918 223.075 277.863C223.12 276.809 220.005 275.825 216.124 275.651C212.242 275.477 209.09 276.19 209.016 277.237Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M209.1 275.36L209.025 277.254L223.086 277.891L223.162 275.998L209.1 275.36Z" fill="#407BFF" />
                                            <path
                                                d="M209.091 275.342C209.046 276.389 212.16 277.38 216.042 277.554C219.923 277.729 223.105 277.023 223.15 275.968C223.194 274.914 220.08 273.93 216.198 273.756C212.317 273.581 209.135 274.295 209.091 275.342Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M209.091 275.342C209.046 276.389 212.16 277.38 216.042 277.554C219.923 277.729 223.105 277.023 223.15 275.968C223.194 274.914 220.08 273.93 216.198 273.756C212.317 273.581 209.135 274.295 209.091 275.342Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M209.984 275.31C209.986 275.376 210.007 275.44 210.044 275.492C210.521 274.882 213.091 274.517 216.161 274.66C219.23 274.803 221.771 275.389 222.181 276.04C222.226 275.994 222.253 275.931 222.255 275.865C222.255 275.151 219.566 274.446 216.176 274.279C212.786 274.113 210.014 274.596 209.984 275.31Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M209.984 275.31C209.986 275.376 210.007 275.44 210.044 275.492C210.521 274.882 213.091 274.517 216.161 274.66C219.23 274.803 221.771 275.389 222.181 276.04C222.226 275.994 222.253 275.931 222.255 275.865C222.255 275.151 219.566 274.446 216.176 274.279C212.786 274.113 210.014 274.596 209.984 275.31Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M210.044 275.492C210.469 276.151 213.024 276.745 216.072 276.88C219.119 277.015 221.719 276.658 222.181 276.04C221.771 275.389 219.238 274.795 216.161 274.66C213.084 274.525 210.521 274.882 210.044 275.492Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M227.165 282.947C226.755 283.874 229.199 286.008 232.619 287.705C236.039 289.402 239.146 290.083 239.548 289.132C239.95 288.18 237.514 286.079 234.094 284.374C230.674 282.669 227.568 282.027 227.165 282.947Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M227.905 281.304L227.167 282.977L239.554 289.172L240.292 287.5L227.905 281.304Z" fill="#407BFF" />
                                            <path
                                                d="M227.903 281.281C227.493 282.201 229.937 284.334 233.356 286.039C236.776 287.744 239.883 288.418 240.285 287.467C240.688 286.515 238.251 284.406 234.832 282.709C231.412 281.012 228.305 280.353 227.903 281.281Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M227.903 281.281C227.493 282.201 229.937 284.334 233.356 286.039C236.776 287.744 239.883 288.418 240.285 287.467C240.688 286.515 238.251 284.406 234.832 282.709C231.412 281.012 228.305 280.353 227.903 281.281Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M228.715 281.606C228.693 281.668 228.693 281.736 228.715 281.797C229.348 281.44 231.785 282.138 234.489 283.494C237.194 284.85 239.265 286.396 239.406 287.149C239.462 287.122 239.507 287.074 239.533 287.015C239.809 286.388 237.618 284.636 234.631 283.169C231.643 281.702 228.991 280.988 228.715 281.606Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M228.715 281.606C228.693 281.668 228.693 281.736 228.715 281.797C229.348 281.44 231.785 282.138 234.489 283.494C237.194 284.85 239.265 286.396 239.406 287.149C239.462 287.122 239.507 287.074 239.533 287.015C239.809 286.388 237.618 284.636 234.631 283.169C231.643 281.702 228.991 280.988 228.715 281.606Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M228.708 281.797C228.849 282.59 230.943 284.096 233.617 285.452C236.292 286.808 238.766 287.514 239.399 287.149C239.257 286.396 237.164 284.85 234.482 283.494C231.799 282.138 229.341 281.44 228.708 281.797Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M219.976 286.222C219.938 287.237 222.956 288.196 226.741 288.371C230.525 288.545 233.61 287.855 233.655 286.84C233.699 285.825 230.674 284.858 226.89 284.683C223.105 284.509 220.02 285.199 219.976 286.222Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M220.04 284.348L219.967 286.194L233.641 286.812L233.715 284.966L220.04 284.348Z" fill="#407BFF" />
                                            <path
                                                d="M220.05 284.374C220.05 285.389 223.03 286.356 226.815 286.531C230.6 286.705 233.684 286.008 233.722 284.993C233.759 283.977 230.741 283.01 226.964 282.844C223.187 282.677 220.095 283.359 220.05 284.374Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M220.05 284.374C220.05 285.389 223.03 286.356 226.815 286.531C230.6 286.705 233.684 286.008 233.722 284.993C233.759 283.977 230.741 283.01 226.964 282.844C223.187 282.677 220.095 283.359 220.05 284.374Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M220.922 284.342C220.922 284.408 220.943 284.472 220.981 284.525C221.443 283.922 223.962 283.573 226.942 283.732C229.922 283.89 232.403 284.445 232.798 285.08C232.843 285.034 232.87 284.972 232.872 284.905C232.872 284.215 230.25 283.525 226.957 283.375C223.664 283.224 220.952 283.652 220.922 284.342Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M220.922 284.342C220.922 284.408 220.943 284.472 220.981 284.525C221.443 283.922 223.962 283.573 226.942 283.732C229.922 283.89 232.403 284.445 232.798 285.08C232.843 285.034 232.87 284.972 232.872 284.905C232.872 284.215 230.25 283.525 226.957 283.375C223.664 283.224 220.952 283.652 220.922 284.342Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M221.011 284.525C221.421 285.159 223.88 285.738 226.867 285.873C229.855 286.008 232.366 285.659 232.828 285.08C232.433 284.445 229.967 283.866 226.972 283.732C223.977 283.597 221.443 283.89 221.011 284.525Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M99.517 82.4708V87.2288C102.332 87.4682 105.005 88.6477 107.154 90.599L110.335 87.2129C107.331 84.3858 103.521 82.7156 99.517 82.4708Z" fill="#407BFF" />
                                            <path
                                                d="M97.334 115.848C94.1489 115.576 91.1588 114.106 88.8994 111.701C86.6399 109.296 85.2588 106.114 85.0035 102.724H80.5333C80.796 107.378 82.6495 111.769 85.7444 115.069C88.8393 118.369 92.9616 120.35 97.334 120.638V115.848Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M85.0184 100.408C85.2762 97.0218 86.6561 93.843 88.9119 91.4392C91.1677 89.0353 94.1526 87.5628 97.334 87.2843V82.5264C92.9569 82.8085 88.8283 84.7863 85.7275 88.0866C82.6268 91.3869 80.7685 95.7812 80.5035 100.44L85.0184 100.408Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.2"
                                                d="M85.0184 100.408C85.2762 97.0218 86.6561 93.843 88.9119 91.4392C91.1677 89.0353 94.1526 87.5628 97.334 87.2843V82.5264C92.9569 82.8085 88.8283 84.7863 85.7275 88.0866C82.6268 91.3869 80.7685 95.7812 80.5035 100.44L85.0184 100.408Z"
                                                fill="black"
                                            />
                                            <path
                                                d="M111.87 88.9021L108.688 92.2882C110.295 94.2965 111.349 96.7381 111.735 99.3436C112.12 101.949 111.822 104.617 110.874 107.054C109.925 109.491 108.363 111.601 106.359 113.153C104.355 114.705 101.987 115.637 99.517 115.848V120.606C102.873 120.388 106.105 119.17 108.843 117.092C111.581 115.014 113.715 112.159 115.003 108.853C116.29 105.547 116.679 101.922 116.125 98.3916C115.571 94.8614 114.097 91.5678 111.87 88.8862V88.9021Z"
                                                fill="#407BFF"
                                            />
                                            <g opacity="0.8">
                                                <path d="M99.517 82.4708V87.2288C102.332 87.4682 105.005 88.6477 107.154 90.599L110.335 87.2129C107.331 84.3858 103.521 82.7156 99.517 82.4708Z" fill="white" />
                                            </g>
                                            <g opacity="0.6">
                                                <path
                                                    d="M97.334 115.848C94.1489 115.576 91.1588 114.106 88.8994 111.701C86.6399 109.296 85.2588 106.114 85.0035 102.724H80.5333C80.796 107.378 82.6495 111.769 85.7444 115.069C88.8393 118.369 92.9616 120.35 97.334 120.638V115.848Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <g opacity="0.7">
                                                <path
                                                    d="M85.0184 100.408C85.2762 97.0218 86.6561 93.843 88.9119 91.4392C91.1677 89.0353 94.1526 87.5628 97.334 87.2843V82.5264C92.9569 82.8085 88.8283 84.7863 85.7275 88.0866C82.6268 91.3869 80.7685 95.7812 80.5035 100.44L85.0184 100.408Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <path
                                                opacity="0.5"
                                                d="M111.87 88.9021L108.688 92.2882C110.295 94.2965 111.349 96.7381 111.735 99.3436C112.12 101.949 111.822 104.617 110.874 107.054C109.925 109.491 108.363 111.601 106.359 113.153C104.355 114.705 101.987 115.637 99.517 115.848V120.606C102.873 120.388 106.105 119.17 108.843 117.092C111.581 115.014 113.715 112.159 115.003 108.853C116.29 105.547 116.679 101.922 116.125 98.3916C115.571 94.8614 114.097 91.5678 111.87 88.8862V88.9021Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M131.084 77.4433L136.784 85.6746L169.625 71.1629L201.64 83.2797L230.645 63.3202L232.723 66.2542L239.615 54.2484L224.17 52.9637L226.539 56.2864L197.512 76.0635L165.975 64.1528L131.084 77.4433Z"
                                                fill="#407BFF"
                                            />
                                            <g opacity="0.5">
                                                <path
                                                    d="M131.084 77.4433L136.784 85.6746L169.625 71.1629L201.64 83.2797L230.645 63.3202L232.723 66.2542L239.615 54.2484L224.17 52.9637L226.539 56.2864L197.512 76.0635L165.975 64.1528L131.084 77.4433Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <path
                                                d="M265.714 82.566C264.285 82.566 262.887 83.0172 261.699 83.8624C260.51 84.7076 259.584 85.9089 259.037 87.3144C258.49 88.72 258.347 90.2666 258.626 91.7587C258.905 93.2508 259.593 94.6214 260.604 95.6971C261.614 96.7729 262.902 97.5054 264.304 97.8022C265.706 98.099 267.159 97.9467 268.479 97.3645C269.8 96.7823 270.929 95.7964 271.723 94.5315C272.517 93.2665 272.941 91.7794 272.941 90.258C272.941 88.218 272.179 86.2615 270.824 84.819C269.469 83.3764 267.631 82.566 265.714 82.566ZM265.714 94.104C264.999 94.1056 264.3 93.8814 263.704 93.4597C263.109 93.0381 262.645 92.438 262.37 91.7353C262.096 91.0327 262.023 90.2592 262.161 89.5126C262.3 88.766 262.644 88.08 263.149 87.5413C263.654 87.0027 264.298 86.6356 264.999 86.4865C265.7 86.3375 266.427 86.4132 267.087 86.7041C267.748 86.9949 268.313 87.4879 268.71 88.1205C269.108 88.7531 269.32 89.497 269.32 90.258C269.318 91.2761 268.938 92.2519 268.262 92.9725C267.586 93.6931 266.67 94.0999 265.714 94.104V94.104Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M283.93 105.135C282.5 105.133 281.102 105.583 279.913 106.427C278.724 107.271 277.796 108.472 277.248 109.877C276.7 111.283 276.556 112.83 276.834 114.322C277.112 115.815 277.799 117.186 278.81 118.263C279.82 119.339 281.108 120.073 282.51 120.37C283.912 120.668 285.366 120.516 286.687 119.934C288.007 119.352 289.137 118.366 289.931 117.101C290.725 115.836 291.15 114.348 291.15 112.827C291.15 110.788 290.389 108.833 289.035 107.39C287.682 105.948 285.845 105.137 283.93 105.135ZM283.93 116.665C283.215 116.666 282.515 116.442 281.92 116.02C281.324 115.598 280.86 114.997 280.586 114.294C280.311 113.591 280.239 112.817 280.378 112.07C280.517 111.323 280.862 110.637 281.367 110.099C281.873 109.561 282.518 109.194 283.219 109.046C283.921 108.898 284.648 108.975 285.309 109.267C285.969 109.559 286.534 110.053 286.93 110.687C287.327 111.321 287.538 112.065 287.536 112.827C287.534 113.844 287.154 114.819 286.478 115.538C285.802 116.257 284.886 116.662 283.93 116.665V116.665Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M268.418 120.519H263.613L280.309 82.566H285.115L268.418 120.519Z" fill="#407BFF" />
                                            <g opacity="0.5">
                                                <path
                                                    d="M265.714 82.566C264.285 82.566 262.887 83.0172 261.699 83.8624C260.51 84.7076 259.584 85.9089 259.037 87.3144C258.49 88.72 258.347 90.2666 258.626 91.7587C258.905 93.2508 259.593 94.6214 260.604 95.6971C261.614 96.7729 262.902 97.5054 264.304 97.8022C265.706 98.099 267.159 97.9467 268.479 97.3645C269.8 96.7823 270.929 95.7964 271.723 94.5315C272.517 93.2665 272.941 91.7794 272.941 90.258C272.941 88.218 272.179 86.2615 270.824 84.819C269.469 83.3764 267.631 82.566 265.714 82.566ZM265.714 94.104C264.999 94.1056 264.3 93.8814 263.704 93.4597C263.109 93.0381 262.645 92.438 262.37 91.7353C262.096 91.0327 262.023 90.2592 262.161 89.5126C262.3 88.766 262.644 88.08 263.149 87.5413C263.654 87.0027 264.298 86.6356 264.999 86.4865C265.7 86.3375 266.427 86.4132 267.087 86.7041C267.748 86.9949 268.313 87.4879 268.71 88.1205C269.108 88.7531 269.32 89.497 269.32 90.258C269.318 91.2761 268.938 92.2519 268.262 92.9725C267.586 93.6931 266.67 94.0999 265.714 94.104V94.104Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M283.93 105.135C282.5 105.133 281.102 105.583 279.913 106.427C278.724 107.271 277.796 108.472 277.248 109.877C276.7 111.283 276.556 112.83 276.834 114.322C277.112 115.815 277.799 117.186 278.81 118.263C279.82 119.339 281.108 120.073 282.51 120.37C283.912 120.668 285.366 120.516 286.687 119.934C288.007 119.352 289.137 118.366 289.931 117.101C290.725 115.836 291.15 114.348 291.15 112.827C291.15 110.788 290.389 108.833 289.035 107.39C287.682 105.948 285.845 105.137 283.93 105.135ZM283.93 116.665C283.215 116.666 282.515 116.442 281.92 116.02C281.324 115.598 280.86 114.997 280.586 114.294C280.311 113.591 280.239 112.817 280.378 112.07C280.517 111.323 280.862 110.637 281.367 110.099C281.873 109.561 282.518 109.194 283.219 109.046C283.921 108.898 284.648 108.975 285.309 109.267C285.969 109.559 286.534 110.053 286.93 110.687C287.327 111.321 287.538 112.065 287.536 112.827C287.534 113.844 287.154 114.819 286.478 115.538C285.802 116.257 284.886 116.662 283.93 116.665V116.665Z"
                                                    fill="white"
                                                />
                                                <path d="M268.418 120.519H263.613L280.309 82.566H285.115L268.418 120.519Z" fill="white" />
                                            </g>
                                            <path
                                                d="M183.014 85.968C183.208 86.9513 183.394 87.8077 183.61 88.7038C183.826 89.5999 184.057 90.4405 184.303 91.3445C184.787 93.0811 185.346 94.8098 185.964 96.5623C187.2 100.203 188.892 103.649 190.993 106.808C191.612 107.69 192.3 108.515 193.05 109.274C193.89 110.132 194.847 110.851 195.888 111.407C197.885 112.441 200.103 112.898 202.318 112.731C204.257 112.589 206.162 112.112 207.958 111.32C208.804 110.952 209.63 110.534 210.432 110.067C210.832 109.826 211.22 109.561 211.594 109.274C211.966 108.981 212.339 108.735 212.756 108.41L210.767 104.675L209.724 104.968L208.629 105.246C207.884 105.42 207.138 105.571 206.393 105.674C205.049 105.893 203.684 105.922 202.333 105.761C201.251 105.644 200.212 105.247 199.308 104.603C198.918 104.305 198.559 103.965 198.235 103.588C197.84 103.086 197.476 102.556 197.147 102.002C196.391 100.738 195.731 99.4126 195.173 98.0373C194.562 96.602 194.026 95.0874 193.519 93.5331C193.012 91.9789 192.558 90.3612 192.104 88.7752L191.463 86.3962C191.262 85.6032 191.038 84.7627 190.882 84.0728L183.014 85.968Z"
                                                fill="#FF8B7B"
                                            />
                                            <path
                                                d="M182.336 81.1624C185.719 76.5869 190.844 78.2601 193.728 85.5397C195.321 89.4117 196.588 93.4268 197.513 97.5376L185.488 102.034C182.329 95.1587 178.864 85.849 182.336 81.1624Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.1"
                                                d="M182.336 81.1624C185.719 76.5869 190.844 78.2601 193.728 85.5397C195.321 89.4117 196.588 93.4268 197.513 97.5376L185.488 102.034C182.329 95.1587 178.864 85.849 182.336 81.1624Z"
                                                fill="black"
                                            />
                                            <path
                                                opacity="0.3"
                                                d="M187.358 88.8148C186.374 88.1011 184.34 92.2643 183.401 97.165C184.064 98.8778 184.772 100.519 185.48 102.042L192.714 99.3377C191.53 94.7305 189.585 90.4008 187.358 88.8148Z"
                                                fill="black"
                                            />
                                            <path
                                                d="M211.378 102.795L214.008 101.265L214.932 107.513C214.932 107.513 210.536 110.336 209.277 108.005L209.411 106.419C209.481 105.702 209.693 105.009 210.032 104.384C210.371 103.76 210.829 103.218 211.378 102.795V102.795Z"
                                                fill="#FF8B7B"
                                            />
                                            <path d="M217.308 100.955L219.61 105.983L214.909 107.521L213.985 101.265L217.308 100.955Z" fill="#FF8B7B" />
                                            <path
                                                d="M182.269 77.0706L176.13 76.127L168.463 76.8406C167.3 76.9487 166.201 77.4603 165.337 78.2969C164.473 79.1335 163.891 80.2489 163.68 81.4717C162.821 87.4298 162.31 93.4391 162.153 99.4646C161.885 106.245 162.592 116.252 163.643 125.292L189.257 121.993C189.503 98.2038 191.195 90.8369 191.403 84.5326C191.445 83.2939 191.103 82.0749 190.429 81.0633C189.756 80.0518 188.79 79.3036 187.678 78.9341L182.269 77.0706Z"
                                                fill="#407BFF"
                                            />
                                            <path d="M190.032 195.036L195.486 196.645L200.366 183.014L194.927 181.412L190.032 195.036Z" fill="#FF8B7B" />
                                            <path
                                                d="M197.915 194.837C197.484 194.241 196.86 193.837 196.164 193.703L190.554 192.546C190.429 192.515 190.297 192.536 190.185 192.603C190.074 192.671 189.99 192.78 189.95 192.91L188.229 198.358C188.133 198.657 188.152 198.984 188.28 199.269C188.408 199.553 188.636 199.773 188.915 199.881H188.974C191.046 200.753 192.446 200.452 195.68 201.118C197.669 201.53 201.856 202.942 204.62 202.823C207.384 202.704 207.414 199.754 206.237 199.532C202.065 198.842 198.868 196.178 197.915 194.837Z"
                                                fill="#263238"
                                            />
                                            <g opacity="0.2">
                                                <path d="M200.366 183.022L197.766 190.27L192.401 188.438L194.92 181.412L200.366 183.022Z" fill="black" />
                                            </g>
                                            <path
                                                d="M213.963 142.825C213.759 144.984 213.39 147.122 212.86 149.217C211.422 155.132 208.964 161.159 206.9 167.035C202.907 178.645 199.405 188.731 199.405 188.731L191.954 185.988C191.954 185.988 198.66 152.397 203.182 148.963H175.452C174.933 148.963 174.416 148.926 173.902 148.852C178.678 148.194 186.121 145.062 193.027 141.929C199.129 139.154 204.836 136.378 207.928 135.332C211.713 137.052 214.254 139.439 213.963 142.825Z"
                                                fill="#263238"
                                            />
                                            <path
                                                opacity="0.1"
                                                d="M213.963 142.825C213.759 144.984 213.39 147.122 212.86 149.217C211.422 155.132 208.964 161.159 206.9 167.035C202.907 178.645 199.405 188.731 199.405 188.731L191.954 185.988C191.954 185.988 198.66 152.397 203.182 148.963H175.452C174.933 148.963 174.416 148.926 173.902 148.852C178.678 148.194 186.121 145.062 193.027 141.929C199.129 139.154 204.836 136.378 207.928 135.332C211.713 137.052 214.254 139.439 213.963 142.825Z"
                                                fill="black"
                                            />
                                            <path
                                                opacity="0.6"
                                                d="M207.936 135.363C204.836 136.434 199.129 139.185 193.035 141.961C186.128 145.093 178.685 148.226 173.91 148.884C165.863 147.742 162.838 137.536 171.25 125.768C176.711 125.768 182.202 131.319 182.202 131.319C182.202 131.319 198.712 131.232 207.936 135.363Z"
                                                fill="black"
                                            />
                                            <path d="M228.007 183.323H234.102L227.717 167.035L223.574 174.513L228.007 183.323Z" fill="#FF8B7B" />
                                            <path
                                                d="M233.602 181.785L226.443 180.048C226.321 180.013 226.192 180.029 226.081 180.092C225.97 180.156 225.886 180.262 225.847 180.389L223.85 186.249C223.769 186.547 223.802 186.867 223.941 187.138C224.081 187.41 224.316 187.612 224.595 187.701H224.632C227.143 188.264 228.357 188.398 231.524 189.152C233.461 189.628 238.363 190.992 241.038 191.642C243.713 192.292 244.413 189.501 243.348 188.969C238.579 186.59 235.987 184.37 234.601 182.451C234.352 182.11 233.999 181.874 233.602 181.785V181.785Z"
                                                fill="#263238"
                                            />
                                            <g opacity="0.2">
                                                <path d="M227.717 167.035L230.436 173.974L224.945 177.233L223.574 174.513L227.717 167.035Z" fill="black" />
                                            </g>
                                            <path
                                                d="M230.518 171.508L223.813 175.83C223.813 175.83 218.217 163.324 212.868 149.217C213.397 147.122 213.766 144.984 213.97 142.825C214.261 139.439 211.735 137.052 207.951 135.371C204.851 136.442 199.144 139.193 193.05 141.969C186.143 145.101 178.7 148.234 173.924 148.892C173.376 148.97 172.824 149.012 172.27 149.019C163.092 149.019 159.925 140.454 163.628 125.927C169.089 125.927 178.439 125.3 178.439 125.3C178.439 125.3 196.484 120.685 209.47 122.255C212.552 122.642 215.439 124.059 217.715 126.304C219.991 128.548 221.539 131.504 222.136 134.745C225.481 152.381 230.518 171.508 230.518 171.508Z"
                                                fill="#263238"
                                            />
                                            <path
                                                d="M230.518 171.508L223.813 175.83C223.813 175.83 218.217 163.324 212.868 149.217C213.397 147.122 213.766 144.984 213.97 142.825C214.261 139.439 211.735 137.052 207.951 135.371C204.851 136.442 199.144 139.193 193.05 141.969C186.143 145.101 178.7 148.234 173.924 148.892C173.376 148.97 172.824 149.012 172.27 149.019C163.092 149.019 159.925 140.454 163.628 125.927C169.089 125.927 178.439 125.3 178.439 125.3C178.439 125.3 196.484 120.685 209.47 122.255C212.552 122.642 215.439 124.059 217.715 126.304C219.991 128.548 221.539 131.504 222.136 134.745C225.481 152.381 230.518 171.508 230.518 171.508Z"
                                                fill="#263238"
                                            />
                                            <path
                                                d="M182.269 77.0706L176.13 76.127L168.463 76.8406C167.3 76.9487 166.201 77.4603 165.337 78.2969C164.473 79.1335 163.891 80.2489 163.68 81.4717C162.821 87.4298 162.31 93.4391 162.153 99.4646C161.885 106.245 162.592 116.252 163.643 125.292L189.257 121.993C189.503 98.2038 191.195 90.8369 191.403 84.5326C191.445 83.2939 191.103 82.0749 190.429 81.0633C189.756 80.0518 188.79 79.3036 187.678 78.9341L182.269 77.0706Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M163.583 123.786L162.92 125.998C162.838 126.165 163.017 126.347 163.293 126.355L188.863 124.729C189.071 124.729 189.25 124.626 189.265 124.483L189.541 122.263C189.541 122.104 189.377 121.97 189.153 121.962L163.993 123.603C163.916 123.592 163.837 123.603 163.765 123.635C163.693 123.667 163.63 123.719 163.583 123.786V123.786Z"
                                                fill="#263238"
                                            />
                                            <path
                                                opacity="0.1"
                                                d="M163.583 123.786L162.92 125.998C162.838 126.165 163.017 126.347 163.293 126.355L188.863 124.729C189.071 124.729 189.25 124.626 189.265 124.483L189.541 122.263C189.541 122.104 189.377 121.97 189.153 121.962L163.993 123.603C163.916 123.592 163.837 123.603 163.765 123.635C163.693 123.667 163.63 123.719 163.583 123.786V123.786Z"
                                                fill="black"
                                            />
                                            <path
                                                opacity="0.3"
                                                d="M166.176 79.2513C171.279 78.2997 171.026 90.6466 168.702 99.6787C168.031 102.296 165.125 101.201 164.142 100.519C160.916 98.3306 159.828 83.2004 166.176 79.2513Z"
                                                fill="black"
                                            />
                                            <path
                                                d="M165.558 97.5377C165.751 102.875 164.157 105.674 166.832 107.458C169.506 109.242 185.309 114.817 185.696 114.119C184.951 115.404 184.206 116.498 183.461 117.799C178.906 117.069 174.349 116.395 169.789 115.776C166.459 115.332 161.087 114.42 158.48 112.168C156.11 110.138 156.535 101.225 156.937 94.8019C159.031 93.4776 165.558 97.5377 165.558 97.5377Z"
                                                fill="#FF8B7B"
                                            />
                                            <path
                                                d="M164.194 77.0785C169.41 75.437 171.429 79.9095 169.834 87.5936C169.031 91.7377 167.876 95.7959 166.385 99.7184L155.403 96.031C156.595 88.4896 158.815 78.7597 164.194 77.0785Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M171.838 77.0389C171.699 78.2239 171.984 79.4212 172.636 80.3932C173.277 81.3524 174.028 82.2234 174.871 82.9863C175.906 83.9934 177.143 85.0798 178.544 84.8815C179.833 84.6992 180.57 84.0093 181.092 82.3757C182.194 84.271 182.247 85.2146 183.461 85.3336C183.855 85.3111 184.239 85.198 184.587 85.0023C184.936 84.8065 185.241 84.5327 185.48 84.1996C185.927 83.8077 186.298 83.3259 186.57 82.7816C186.842 82.2374 187.011 81.6413 187.067 81.0276C187.358 78.6486 185.532 76.4124 183.401 75.5639C181.232 74.791 178.979 74.3166 176.696 74.1524C174.707 73.9304 172.203 74.5885 171.838 77.0389Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.3"
                                                d="M171.838 77.0389C171.699 78.2239 171.984 79.4212 172.636 80.3932C173.277 81.3524 174.028 82.2234 174.871 82.9863C175.906 83.9934 177.143 85.0798 178.544 84.8815C179.833 84.6992 180.57 84.0093 181.092 82.3757C182.194 84.271 182.247 85.2146 183.461 85.3336C183.855 85.3111 184.239 85.198 184.587 85.0023C184.936 84.8065 185.241 84.5327 185.48 84.1996C185.927 83.8077 186.298 83.3259 186.57 82.7816C186.842 82.2374 187.011 81.6413 187.067 81.0276C187.358 78.6486 185.532 76.4124 183.401 75.5639C181.232 74.791 178.979 74.3166 176.696 74.1524C174.707 73.9304 172.203 74.5885 171.838 77.0389Z"
                                                fill="black"
                                            />
                                            <path
                                                d="M177.173 65.5327L179.952 68.9505L183.729 73.5974C183.391 74.096 183.139 74.6544 182.984 75.2468C182.727 75.8755 182.692 76.5818 182.885 77.2359C183.078 77.89 183.486 78.448 184.035 78.8074C184.608 79.0928 184.906 82.3044 181.107 81.9793C180.023 81.9594 178.955 81.702 177.969 81.2231C176.984 80.7442 176.101 80.0541 175.377 79.1959C174.684 78.2047 174.498 77.6734 175.213 76.587C177.24 73.5181 177.404 69.0694 177.173 65.5327Z"
                                                fill="#FF8B7B"
                                            />
                                            <path
                                                opacity="0.2"
                                                d="M182.82 72.4475L180.436 69.0931C180.436 69.1883 180.377 69.2993 180.347 69.4103C180.23 69.8836 180.15 70.3663 180.108 70.8536C179.967 72.5189 181.434 74.652 182.865 75.5639L182.977 75.2309C183.153 74.6673 183.404 74.1333 183.722 73.6449C183.74 73.6173 183.763 73.5931 183.789 73.5735L182.82 72.4475Z"
                                                fill="black"
                                            />
                                            <path
                                                d="M177.434 59.2442C177.001 64.7555 176.525 67.9671 178.745 71.0914C182.105 75.8494 188.796 74.1524 190.748 68.8552C192.506 64.0973 192.871 55.874 188.118 52.9954C186.444 52.0118 184.472 51.7759 182.634 52.3394C180.797 52.903 179.245 54.22 178.32 56.0009C177.799 56.9972 177.496 58.1059 177.434 59.2442V59.2442Z"
                                                fill="#FF8B7B"
                                            />
                                            <path
                                                d="M176.674 64.1211L178.909 65.144C179.654 64.811 179.825 63.8514 179.773 62.9792C179.549 59.6169 185.249 55.5092 185.249 55.5092C185.249 55.5092 179.222 53.3999 177.411 53.0827C176.778 52.9716 174.498 54.7321 174.148 55.4616C173.679 56.5321 173.501 57.7206 173.632 58.8932C173.764 60.0658 174.201 61.1761 174.893 62.0989C175.423 62.8329 176.02 63.5101 176.674 64.1211Z"
                                                fill="#263238"
                                            />
                                            <path
                                                d="M174.766 63.1298C174.877 64.3606 175.407 65.5056 176.256 66.3494C177.359 67.4279 178.708 66.2542 178.872 64.6841C179.006 63.2885 178.343 61.2505 177.001 60.7826C175.66 60.3147 174.64 61.5915 174.766 63.1298Z"
                                                fill="#FF8B7B"
                                            />
                                            <path
                                                d="M185.204 62.551C185.122 63.003 185.279 63.4233 185.555 63.4867C185.83 63.5501 186.091 63.2409 186.203 62.7889C186.315 62.3369 186.121 61.9166 185.845 61.8531C185.569 61.7897 185.249 62.091 185.204 62.551Z"
                                                fill="#263238"
                                            />
                                            <path
                                                d="M189.98 63.669C189.898 64.129 190.055 64.5492 190.338 64.6127C190.621 64.6761 190.897 64.3589 190.979 63.9149C191.06 63.4708 190.897 63.0426 190.621 62.9791C190.345 62.9157 190.062 63.217 189.98 63.669Z"
                                                fill="#263238"
                                            />
                                            <path
                                                d="M188.386 63.6454C188.535 64.9399 188.841 66.2086 189.295 67.42C189.006 67.5307 188.698 67.5718 188.393 67.5402C188.087 67.5087 187.792 67.4053 187.529 67.2376L188.386 63.6454Z"
                                                fill="#FF5652"
                                            />
                                            <path
                                                d="M186.322 69.7831C185.847 69.6078 185.411 69.3309 185.041 68.9695C184.671 68.6081 184.374 68.1698 184.169 67.6817C184.156 67.649 184.156 67.6125 184.169 67.5799C184.181 67.5473 184.205 67.5212 184.236 67.5072C184.269 67.496 184.304 67.4979 184.336 67.5125C184.367 67.5271 184.393 67.5534 184.407 67.5865C184.637 68.1128 184.977 68.5756 185.402 68.9387C185.828 69.3019 186.326 69.5556 186.858 69.68C186.875 69.682 186.891 69.6874 186.906 69.6961C186.921 69.7048 186.933 69.7164 186.944 69.7305C186.954 69.7445 186.962 69.7606 186.966 69.7778C186.971 69.795 186.972 69.8129 186.97 69.8307C186.965 69.8672 186.946 69.9 186.918 69.9222C186.89 69.9444 186.856 69.9542 186.821 69.9496C186.65 69.9109 186.483 69.8551 186.322 69.7831V69.7831Z"
                                                fill="#263238"
                                            />
                                            <path
                                                d="M184.549 60.7272C184.507 60.7181 184.47 60.6926 184.444 60.6558C184.397 60.6053 184.371 60.5368 184.371 60.4655C184.371 60.3941 184.397 60.3257 184.444 60.2751C184.743 59.9896 185.104 59.7872 185.494 59.6862C185.884 59.5852 186.291 59.5886 186.68 59.6963C186.744 59.7165 186.798 59.7629 186.83 59.8252C186.862 59.8876 186.869 59.961 186.851 60.0293V60.0293C186.829 60.0964 186.783 60.1516 186.723 60.1827C186.663 60.2139 186.594 60.2186 186.531 60.1959C186.226 60.1214 185.909 60.125 185.606 60.2063C185.303 60.2876 185.022 60.4444 184.787 60.6637C184.757 60.6971 184.719 60.7208 184.677 60.732C184.634 60.7432 184.59 60.7415 184.549 60.7272V60.7272Z"
                                                fill="#263238"
                                            />
                                            <path
                                                d="M190.524 60.735C190.513 60.6908 190.513 60.6443 190.524 60.6002C190.533 60.5657 190.548 60.5334 190.568 60.5051C190.588 60.4768 190.614 60.4531 190.643 60.4354C190.672 60.4177 190.704 60.4063 190.738 60.402C190.771 60.3976 190.805 60.4002 190.837 60.4098C191.222 60.5075 191.578 60.7027 191.877 60.9789C192.175 61.255 192.406 61.6039 192.551 61.9958C192.569 62.0652 192.562 62.1396 192.53 62.2033C192.498 62.267 192.444 62.3149 192.379 62.3368C192.315 62.3565 192.246 62.3483 192.187 62.3142C192.129 62.28 192.085 62.2226 192.066 62.1544C191.951 61.8429 191.767 61.5656 191.53 61.346C191.292 61.1265 191.009 60.9713 190.703 60.8936C190.663 60.8849 190.626 60.8659 190.595 60.8382C190.563 60.8105 190.539 60.7751 190.524 60.735Z"
                                                fill="#263238"
                                            />
                                            <path
                                                d="M192.104 123.215L224.796 123.318C225.366 123.308 225.913 123.077 226.334 122.668C226.755 122.259 227.022 121.701 227.083 121.097L228.573 103.477C228.602 103.193 228.573 102.905 228.49 102.634C228.406 102.362 228.269 102.112 228.088 101.901C227.908 101.69 227.687 101.521 227.44 101.408C227.194 101.294 226.927 101.237 226.659 101.241L193.966 101.138C193.396 101.15 192.849 101.383 192.428 101.793C192.007 102.203 191.74 102.762 191.679 103.366L190.189 120.979C190.16 121.263 190.189 121.55 190.272 121.822C190.356 122.094 190.493 122.344 190.674 122.555C190.855 122.766 191.076 122.934 191.322 123.048C191.569 123.162 191.835 123.219 192.104 123.215Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.3"
                                                d="M192.104 123.215L224.796 123.318C225.366 123.308 225.913 123.077 226.334 122.668C226.755 122.259 227.022 121.701 227.083 121.097L228.573 103.477C228.602 103.193 228.573 102.905 228.49 102.634C228.406 102.362 228.269 102.112 228.088 101.901C227.908 101.69 227.687 101.521 227.44 101.408C227.194 101.294 226.927 101.237 226.659 101.241L193.966 101.138C193.396 101.15 192.849 101.383 192.428 101.793C192.007 102.203 191.74 102.762 191.679 103.366L190.189 120.979C190.16 121.263 190.189 121.55 190.272 121.822C190.356 122.094 190.493 122.344 190.674 122.555C190.855 122.766 191.076 122.934 191.322 123.048C191.569 123.162 191.835 123.219 192.104 123.215Z"
                                                fill="black"
                                            />
                                            <path
                                                d="M193.065 123.143L225.757 123.246C226.328 123.236 226.876 123.004 227.297 122.594C227.719 122.183 227.984 121.623 228.044 121.018L229.534 103.406C229.564 103.122 229.537 102.835 229.454 102.563C229.371 102.291 229.235 102.041 229.055 101.83C228.874 101.618 228.654 101.45 228.408 101.336C228.162 101.222 227.896 101.166 227.627 101.17L194.935 101.067C194.365 101.08 193.818 101.313 193.398 101.723C192.977 102.133 192.71 102.692 192.647 103.295L191.157 120.907C191.128 121.191 191.155 121.478 191.238 121.75C191.321 122.022 191.457 122.272 191.637 122.483C191.817 122.695 192.038 122.863 192.284 122.977C192.53 123.091 192.796 123.147 193.065 123.143V123.143Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.2"
                                                d="M193.065 123.143L225.757 123.246C226.328 123.236 226.876 123.004 227.297 122.594C227.719 122.183 227.984 121.623 228.044 121.018L229.534 103.406C229.564 103.122 229.537 102.835 229.454 102.563C229.371 102.291 229.235 102.041 229.055 101.83C228.874 101.618 228.654 101.45 228.408 101.336C228.162 101.222 227.896 101.166 227.627 101.17L194.935 101.067C194.365 101.08 193.818 101.313 193.398 101.723C192.977 102.133 192.71 102.692 192.647 103.295L191.157 120.907C191.128 121.191 191.155 121.478 191.238 121.75C191.321 122.022 191.457 122.272 191.637 122.483C191.817 122.695 192.038 122.863 192.284 122.977C192.53 123.091 192.796 123.147 193.065 123.143V123.143Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M212.644 112.406C212.598 112.89 212.418 113.348 212.126 113.723C211.835 114.097 211.446 114.371 211.009 114.511C210.571 114.65 210.105 114.648 209.668 114.506C209.232 114.363 208.845 114.085 208.556 113.708C208.268 113.332 208.091 112.872 208.047 112.388C208.004 111.904 208.097 111.418 208.314 110.99C208.53 110.562 208.861 110.212 209.265 109.984C209.668 109.756 210.126 109.661 210.581 109.71C210.882 109.743 211.175 109.839 211.442 109.992C211.71 110.145 211.946 110.353 212.137 110.603C212.329 110.853 212.472 111.142 212.559 111.451C212.646 111.76 212.675 112.085 212.644 112.406V112.406Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M226.226 123.223C226.226 124.277 225.638 125.134 224.923 125.134L191.18 124.991C190.815 124.991 190.517 124.563 190.517 124.039C190.517 123.516 190.815 123.072 191.18 123.072L226.226 123.223Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                opacity="0.3"
                                                d="M226.226 123.223C226.226 124.277 225.638 125.134 224.923 125.134L191.18 124.991C190.815 124.991 190.517 124.563 190.517 124.039C190.517 123.516 190.815 123.072 191.18 123.072L226.226 123.223Z"
                                                fill="black"
                                            />
                                            <path
                                                d="M190.971 124.999H174.729C174.314 124.999 173.916 124.823 173.623 124.511C173.329 124.199 173.165 123.775 173.165 123.334V123.334C173.164 123.3 173.169 123.266 173.181 123.234C173.192 123.202 173.209 123.173 173.232 123.149C173.254 123.125 173.281 123.105 173.31 123.092C173.339 123.079 173.371 123.072 173.403 123.072H190.971C191.09 123.072 191.208 123.097 191.318 123.146C191.428 123.194 191.527 123.266 191.611 123.356C191.695 123.446 191.761 123.552 191.806 123.67C191.851 123.787 191.874 123.913 191.873 124.039V124.039C191.873 124.294 191.778 124.538 191.609 124.718C191.439 124.898 191.21 124.999 190.971 124.999V124.999Z"
                                                fill="#407BFF"
                                            />
                                            <path
                                                d="M182.194 111.653L184.891 110.812L184.646 117.878C184.646 117.878 178.79 119.345 178.424 116.474L179.17 114.928C179.818 113.596 180.712 112.042 182.194 111.653Z"
                                                fill="#FF8B7B"
                                            />
                                            <path d="M188.93 112.771L188.132 116.736L184.646 117.87L184.891 110.812L188.93 112.771Z" fill="#FF8B7B" />
                                            <path d="M189.995 185.409L201.171 189.445L203.458 184.529L191.098 180.35L189.995 185.409Z" fill="#407BFF" />
                                            <path d="M222.099 176.805L232.403 170.683L230.51 165.584L219.312 172.547L222.099 176.805Z" fill="#407BFF" />
                                            <path
                                                d="M192.469 59.1887C194.003 59.0935 194.629 57.1666 194.443 56.3419C194.216 55.2538 193.595 54.305 192.714 53.7012C193.206 52.1152 192.543 50.1803 190.695 48.8402C190.023 48.4265 189.272 48.1803 188.498 48.1199C187.724 48.0594 186.947 48.1864 186.225 48.4913C186.225 47.0559 183.744 46.1123 182.15 47.9044C181.493 47.472 180.721 47.2777 179.952 47.3506C179.182 47.4235 178.456 47.7597 177.881 48.3089C177.68 48.4953 177.53 48.7356 177.446 49.0048C177.362 49.274 177.347 49.5622 177.404 49.8393C176.77 49.8476 176.15 50.0377 175.609 50.3896C175.068 50.7415 174.626 51.2422 174.33 51.8389C174.034 52.4356 173.895 53.1062 173.927 53.78C173.959 54.4539 174.162 55.1061 174.513 55.6678C175.012 56.4608 176.197 56.4608 177.091 56.2546C177.338 56.7066 177.67 57.0997 178.066 57.4094C178.462 57.719 178.914 57.9387 179.393 58.0547C182.336 59.0935 186.382 58.1182 187.097 58.0547C187.812 57.9282 188.545 58.0832 189.161 58.4908C189.026 58.6907 188.851 58.8562 188.648 58.9755C188.446 59.0948 188.222 59.1649 187.991 59.1808C187.548 59.2283 187.101 59.2043 186.665 59.1094C187.661 59.809 188.872 60.0731 190.047 59.8469C190.551 59.577 191.068 59.3361 191.597 59.1252C191.954 59.0697 192.506 59.2442 192.469 59.1887Z"
                                                fill="#263238"
                                            />
                                        </svg>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                }

                <div className={`${isOpen ? "block" : "hidden"}  w-full`}>
                    <ExpressRebalancing isOpen={isOpen} setIsOpen={setIsOpen} auth={auth} t={t} data={userData} totalInvested={totalInvested} capitalActif={capitalActif} hasRebal={hasRebal} />
                </div>
            </div>
        </>
    );
}

export default UserSecurisationInfo;
