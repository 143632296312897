import { createSlice } from '@reduxjs/toolkit';

import io from 'socket.io-client';
//import * as services from "../utils/service.js"


const socketSlice = createSlice({
    name: 'socket',
    initialState: null,
    reducers: {
        connect: (state, action) => {

            //console.log("test", io(action.payload.url, { path: '/dev/socket.io' }))
            // const here = services.decryptCB(localStorage.getItem("token"))
            //const token = localStorage.getItem("accesstoken")
            // const authState = authSelector()

            // const { token } = authState
            const path = process.env.REACT_APP_SOCKET_PATH || undefined            // Create the socket connection
            const conn = io(action.payload.url, {
                path,
                transports: ['polling']
                // Other options...
            });
            return conn
        },
        disconnect: (state) => {
            //state.disconnect();
            return null;
        },
    },
});

export const { connect, disconnect } = socketSlice.actions;
export const socketSelector = (state) => state.socket;

export default socketSlice.reducer;
