import React, { useEffect, useState } from "react";
import TableauGrid from "../TableauGrid";
import Controls from "../controls/Controls";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Loading from "../Loading";
import moment from "moment";
import EmailPreview from "../EmailPreview";
import AccordionCustom from "../AccordionCustom";
import { ArrowDownIcon, ArrowUpIcon, CreditCardIcon } from "@heroicons/react/solid";
import { Icon } from "coinmarketcap-cryptocurrency-icons";
import * as service from "../../utils/service";
import axios from "../../axios";
import { useSelector } from "react-redux";
import { authSelector } from "../../features/authSlice";
import IconHoverText from "../IconHoverText";
import { EyeIcon, Send, SendHorizontal } from "lucide-react";
import { DialogDemo } from "../client/dialogShad";
import BtnShad from "../client/btnShadcn";
const Rebalancing = (props) => {
    const { t, uid, data } = props;
    // console.log("REballl", data);
    const axiosPrivate = useAxiosPrivate();
    const [allEmail, setAllEmail] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [compareDetails, setCompareDetails] = useState();
    const [showDetails, setShowDetails] = useState(false);
    const [sendLoading, sendSendLoading] = useState(false);
    const [hasActiveZap, setHasActiveZap] = useState(false);
    const [allWalletHistory, setAllWalletHistory] = useState();
    const [prevEmailObject, setPrevEmailObject] = useState()
    const auth = useSelector(authSelector);


    const [taux, setTaux] = useState(1);

    const getTaux = async (symbol) => {
        try {
            const response = await axios.get(`/api/trade/${symbol}`);
            setTaux(response.data.price)
        } catch (error) {
            console.log("Errors on averagePricePublic", error);
        }
    }

    const navigate = useNavigate();
    const location = useLocation();

    const ResendEmail = async (emailId) => {
        sendSendLoading(true)
        try {
            await axiosPrivate.get(`/api/emails/generic/${emailId}`)
            console.log("Resend email", emailId);
            console.log("Email resend SUCCESS");
            window.location.reload()
        } catch (error) {
            console.error("ResendEmail", error);
        }
        sendSendLoading(false)
    }

    const columns = [
        (auth?.id == "87e4efd1-376f-4741-8cc7-76b771b05b76" || auth?.id == "7c7fbd68-a81b-4bc6-a9de-cde9617a9ce3") &&
        {
            field: "id",
            headerName: "id",
            // hide: true,
            width: 70
        },
        {
            field: "date",
            headerName: t("label.date"),
            type: "date",
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                if (cellValues.row.date) {
                    return moment(cellValues.row.date, "DD-MM-YYYY").format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },

        },
        {
            field: "endCapital",
            headerName: t("label.endCapital"),
            headerClassName: "dark:text-slate-100",
            // flex: 1,
            width: 170

        },
        {
            field: "capitalToRebalance",
            headerName: t("label.capitalToRebalance"),
            headerClassName: "dark:text-slate-100",
            // flex: 1,
            width: 160
        },
        {
            field: "totalRebalancedValue",
            headerName: t("label.totalRebalancedValue"),
            headerClassName: "dark:text-slate-100",
            width: 180
        },
        {
            field: "startCapitalCurrentMonth",
            headerName: t("label.startCapitalCurrentMonth"),
            headerClassName: "dark:text-slate-100",
            width: 200
        },

        {
            field: "action",
            headerName: t(`label.action`),
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            width: 200,
            renderCell: (cellValues) => {
                return (
                    <div className="flex space-x-2">
                        <IconHoverText
                            icon={<EyeIcon className="w-4 hover:cursor-pointer text-indigo-500" />}
                            // classIcon={"w-4 h-5 text-indigo-500 hover:bg-indigo-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Aperçu"}
                            onClick={async (event) => {
                                //handleClick(event, cellValues);
                                // navigate(`/support/user/${cellValues.row.id}`);
                                console.log("PPPPPPPPPP======", cellValues)
                                const dataToShow = allEmail.filter((a) => a.id == cellValues.row.id);
                                setSelectedRow(dataToShow[0]);
                                let previousRecordEmail = allEmail.filter((f) => f.id < cellValues.row.id)
                                let previd = previousRecordEmail[previousRecordEmail.length - 1]
                                setPrevEmailObject(previd)


                                const securedAsset = dataToShow[0].users.details.securedAsset
                                console.log("securedAsset", securedAsset);
                                if (securedAsset != "USDT") {
                                    const symbol = `${securedAsset}USDT`
                                    getTaux(symbol)
                                }

                                if (auth?.role.includes(process.env.REACT_APP_SUPPORT)) {

                                    try {
                                        const responseCompare = await axiosPrivate.get(`/api/comparison/findBy/date/${cellValues.row.date}/${uid}`, {});
                                        setCompareDetails([]);
                                        setShowDetails(true);
                                        setCompareDetails(responseCompare.data);

                                        //get PNL data
                                        const idPnlComputedJSON = JSON.parse(cellValues.row.idPnlComputed);
                                        //combine pnl ids
                                        //const arr = [];
                                        const arr = idPnlComputedJSON.current;
                                        console.log(arr.join("-"));
                                        // setPnlDetails(responsePnl.data)
                                    } catch (error) { }
                                }


                            }}
                        />

                        {(auth?.id == "87e4efd1-376f-4741-8cc7-76b771b05b76" || auth?.id == "7c7fbd68-a81b-4bc6-a9de-cde9617a9ce3") &&

                            <DialogDemo
                                trigger={
                                    <IconHoverText
                                        icon={<SendHorizontal className="w-4 hover:cursor-pointer text-indigo-500" />}
                                        text={"Resend email "}
                                    />
                                }
                                title={"Resend email"}
                                closeComponent={
                                    <BtnShad text={t(`button.confirm`)} onClick={() => ResendEmail(cellValues.id)} isLoading={sendLoading}
                                        sendHistory={{ action: `Resend email rebal for ${moment(cellValues?.row.date, "DD-MM-YYYY").format("DD/MM/YYYY")} `, who: `${cellValues?.row.users.id}` }} />
                                }

                            >
                                <div className="w-full  px-2 pb-2">
                                    id email <strong>{cellValues?.id}</strong>
                                </div>
                                <div className="w-full  px-2 pb-2">
                                    Rebalancing du <strong>{moment(cellValues?.row.date, "DD-MM-YYYY").format("DD/MM/YYYY")}</strong>
                                </div>
                                <div className="w-full  px-2 pb-2">
                                    Resend email for <strong>{data?.email}</strong>
                                </div>
                            </DialogDemo>}
                    </div>
                );
            },
        },
    ];



    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/emails/find/${uid}`, {
                    signal: controller.signal,
                });
                const decrypt = JSON.parse(service.decryptCBData(data));

                // console.log("test", decrypt);
                isMounted && setAllEmail(decrypt);
            } catch (error) { }
        };
        const checkIfUserHasActiveZap = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/zap/active/${uid}`, {
                    signal: controller.signal,
                });
                // console.log("check If User Has ActiveZap", data);
                if (data) {
                    isMounted && setHasActiveZap(true);
                }
            } catch (error) {
                navigate("/login", { state: { from: location }, replace: true });
            }
        };

        const getWalletHistory = async (myId) => {
            try {
                const { data } = await axiosPrivate.get(`/api/wallet-history/${myId}`, {
                    signal: controller.signal,
                });
                // console.log("test", data);
                isMounted && setAllWalletHistory(data);
                // console.log("WH ", data);
            } catch (error) {
                /*if (error.response.status != 403) {
                  navigate("/login", { state: { from: location }, replace: true });
                }*/
            }
        }

        getWalletHistory(uid)
        fetchData();
        checkIfUserHasActiveZap();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    useEffect(() => {
        if (selectedRow) {
            const securedAsset = selectedRow.users.details.securedAsset
            // console.log("securedAsset", securedAsset);
            if (securedAsset != "USDT") {
                const symbol = `${securedAsset}USDT`
                getTaux(symbol)
            }
        }
    }, [selectedRow])

    return (
        <div>
            <div className="h-[300px] bg-slate-100 dark:bg-slate-800">{allEmail && allEmail ? <TableauGrid column={columns} dataRows={allEmail && allEmail} /> : <Loading />}</div>

            <div className="flex gap-x-3  m-3">
                {/* <EmailPreview selectedRow={(selectedRow && selectedRow) || ""} hasActiveZap={hasActiveZap} /> */}
                <EmailPreview
                    selectedRow={(selectedRow && selectedRow) || ""}
                    hasActiveZap={hasActiveZap}
                    taux={taux}
                    allWalletHistory={allWalletHistory && allWalletHistory}
                    prevEmailObject={prevEmailObject && prevEmailObject} />


                <div className="max-w-md">
                    {showDetails && showDetails ? (
                        <>
                            {compareDetails && compareDetails.length != 0 ? (
                                <>
                                    <div className="flex-none bg-white dark:bg-slate-800 p-2">
                                        <div className="flex gap-x-2">
                                            <h3 className="text-slate-700 dark:text-slate-100">{t(`label.historique`)} </h3>
                                            <Icon i="usdt" size={20} />
                                            <div className="text-slate-700 dark:text-slate-100">{JSON.parse(selectedRow.rate).eur}</div>
                                        </div>

                                        <div className="grid grid-cols-2 gap-x-1 mb-2 ">
                                            <div className="text-slate-800 flex bg-green-200 p-2">
                                                <ArrowDownIcon className="text-green-500 h-5" />
                                                {compareDetails &&
                                                    compareDetails
                                                        .map((c) => JSON.parse(c.depotTotal).usdt)
                                                        .reduce((acc, curr) => Number(acc) + Number(curr), 0)
                                                        .toFixed(2)}
                                            </div>

                                            <div className="text-slate-800 flex bg-red-200 p-2">
                                                <ArrowUpIcon className="text-red-500 h-5" />
                                                {compareDetails &&
                                                    compareDetails
                                                        .map((c) => JSON.parse(c.withdrawTotal).usdt)
                                                        .reduce((acc, curr) => Number(acc) + Number(curr), 0)
                                                        .toFixed(2)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" h-[1200px] overflow-auto">
                                        {compareDetails &&
                                            compareDetails.map((c) => (
                                                <>
                                                    <AccordionCustom
                                                        title={`${moment(c.createdAt).format("DD/MM/YYYY HH:MM:ss")}  `}
                                                        subTitle={`${JSON.parse(c.differenceTotal).usdt} USDT`}
                                                        depotTotal={`${JSON.parse(c.depotTotal).usdt}`}
                                                        withdrawTotal={`${JSON.parse(c.withdrawTotal).usdt}`}
                                                        differenceTotal={`${JSON.parse(c.differenceTotal).usdt}`}
                                                    >
                                                        {JSON.parse(c.differences).map((c2) => (
                                                            <div className={`text-xs ${c2.free > 0 ? "text-green-600" : "text-red-500"}`}>
                                                                {c2.asset} : {c2.free}
                                                            </div>
                                                        ))}
                                                    </AccordionCustom>
                                                </>
                                            ))}
                                    </div>
                                </>
                            ) : (
                                <Loading />
                            )}
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Rebalancing;
