import axios from "axios";
import * as services from "./utils/service.js";
axios.defaults.withCredentials = true;
export default axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
        'x-secret-token': 'TESTME'
    },
    proxy: {
        host: process.env.REACT_APP_BASE_URL,
    },
});

export const axiosPrivate = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
        'x-secret-token': 'TESTME'
    },
    proxy: {
        host: process.env.REACT_APP_BASE_URL,
    },
});

export const axiosPrivate2 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": services.decryptCB(localStorage.getItem("token")),
        "ngrok-skip-browser-warning": "any",
    },
    proxy: {
        host: process.env.REACT_APP_BASE_URL,
    },
});

export const axiosBQ = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_BQ,
    withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});
