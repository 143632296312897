import React from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { ArrowDownToLine, ExternalLink, FileText, Link2, Video, VideoIcon } from "lucide-react";
import { TypoTutoLink } from "./Typo";
import { DialogDemo } from "./client/dialogShad";

export function LinkTuto(props) {
    const { isVideoOpen, setIsVideoOpen, url, title, text, bgColor, type, pdfLink, noPadding, color, fit } = props;
    const { t } = useTranslation()
    return (
        <>
            {type == "pdf" ?
                <div className={`${noPadding ? null : " "} w-60 md:w-fit`}>
                    <a href={`${pdfLink}`} target={"_blank"} download style={{ textDecoration: "none !important" }}>
                        <div className="text-slate-600 font-semibold">
                            <div style={{ textDecoration: "none" }} className={`flex flex-row items-center space-x-1 text-xs hover:scale-105 transition-all ${color ? color : " hover:bg-sky-100 "} file:hover:cursor-pointer p-1 rounded ${fit ? fit : "w-60 md:w-80"} `}>
                                <FileText className="h-4 w-4 ml-2 text-blue-500" />
                                <TypoTutoLink> {title}
                                </TypoTutoLink>
                                {/* <ExternalLink className="h-4 w-4 ml-2 text-blue-500" /> */}
                            </div>
                        </div>
                    </a>
                </div>
                :

                <DialogDemo
                    trigger={
                        <div className="text-sm flex ">
                            {/* <TypographyXS>{t(`linkTuto.tuto`)}</TypographyXS> */}
                            <div style={{ textDecoration: "none" }} className={`flex flex-row items-center space-x-1 text-xs hover:scale-105 transition-all ${color ? color : " hover:bg-sky-100 "} file:hover:cursor-pointer p-1 rounded ${fit ? fit : "w-60 md:w-80"} `}>

                                <VideoIcon className="h-4 w-4 ml-2 text-blue-500" />
                                <TypoTutoLink onClick={() => { setIsVideoOpen(!isVideoOpen); console.log("HOLA"); }}>
                                    {text}
                                </TypoTutoLink>
                            </div>
                            {/* <Controls.Button text={text} small xsFont icon={<ExternalLinkIcon className="w-4  h-4 rounded-xl p-" />} color={`${bgColor ? bgColor : "bg-sky-300 hover:bg-sky-400"} `} onClick={() => setIsVideoOpen(!isVideoOpen)} /> */}
                        </div>

                    }
                    title={title}>
                    <div className="w-full  px-2 pb-2">
                        <div className="relative pt-[56.25%] ">
                            <ReactPlayer className="absolute top-0 left-0 " url={url} width="100%" height="100%" controls={true} />
                        </div>
                    </div>
                </DialogDemo>
            }
        </>
    );
}
