import React from "react";
import LanguageSwitcher from "../components/LanguageSwitcher";
import Login from "../components/Login";
import Logo from "../asset/H-Logo-fond-blanc.png"

function LoginScreen(props) {
    const { t } = props
    return (
        <>
            <div className="flex px-4 items-center">
                <div className="flex-1 ">

                    <img className="object-contains h-4" src={Logo} />
                </div>

                <LanguageSwitcher />
            </div>
            <Login t={t} />
        </>
    );
}

export default LoginScreen;
