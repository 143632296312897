import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Outlet } from "react-router-dom"
import { authSelector } from '../features/authSlice';
import { connect, disconnect, socketSelector } from '../features/socketSlice';
import Header from './Header'
import LanguageSwitcher from './LanguageSwitcher';
import CarrousselNotif from './CarrousselNotif';
function LayoutNoBanner(props) {
    const { t } = props
    const socket = useSelector(socketSelector);
    const auth = useSelector(authSelector);
    console.log("auuthhh ========= %%%%%%%%%", auth);
    const dispatch = useDispatch();
    const handleCustomEvent = (data) => {
        console.log("received: ", data);
    }

    return (
        <main className='bg-slate-200 dark:bg-slate-900 flex flex-col min-h-screen'>
            <Header t={t} />
            <Outlet />
        </main>
    )
}

export default LayoutNoBanner
