import React from "react";
import { useFOrm, Form } from "../useFOrm";
import Controls from "../controls/Controls";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../axios";

const initialFvalues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};
function NewForm(props) {
  const { t } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("firstName" in fieldValues) temp.firstName = fieldValues.firstName ? "" : t("error.required");
    if ("lastName" in fieldValues) temp.lastName = fieldValues.lastName ? "" : t("error.required");
    if ("email" in fieldValues) temp.email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(fieldValues.email) ? "" : t("error.invalidEmail");
    // temp.email = fieldValues.email == "" ? t("error.required") : (/$^|.+@.+..+/).test(fieldValues.email) ? "" : t("error.invalidEmail")
    if ("tel" in fieldValues) temp.tel = fieldValues.tel > 9 ? "" : t("error.mobileLength");

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

  const formNew = [
    {
      type: "text",
      name: "lastName",
      label: t("label.lastName"),
      placeholder: "Nom",
      password: false,
      required: true,
    },
    {
      type: "text",
      name: "firstName",
      label: t("label.firstName"),
      placeholder: "Prénom",
      password: false,
      required: true,
    },
    {
      type: "email",
      name: "email",
      label: t("label.email"),
      placeholder: "Enter your email",
      password: false,
      required: true,
    },
  ];
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await axiosPrivate.post(`/api/users/create`, values);
        if (response.data) {
          navigate(`/admin/user/${response.data.id}`);
        }
      } catch (error) {
        toast.error(error.response?.data.msg);
      }
    }
  };

  return (
    <div className="max-w-md p-4">
      <Form onSubmit={handleSubmit} noValidate className=" space-y-4">
        {formNew.map((item, i) => {
          // console.log(data[item.name])
          return (
            <>
              <Controls.Input fullWidth={true} key={i} label={item.label} value={values[item.name] || ""} onChange={handleInputChange} name={item.name} type={item.type} error={errors[item.name]} />
            </>
          );
        })}

        <div className="flex justify-end ">
          <Controls.Button text={t(`button.save`)} widthFull />
        </div>
      </Form>
    </div>
  );
}

export default NewForm;
