import React, { useEffect, useState } from "react";
import { authSelector } from "../features/authSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tab } from "@headlessui/react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import BannerInfo from "../components/BannerInfo";
import { toast } from "react-toastify";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const Myth = ({ text }) => <th className="p-3 text-sm w-[200px] font-semibold tracking-wide text-left"> {text}</th>;

const Mytd = ({ text }) => <td className="p-3 text-sm text-gray-700 whitespace-nowrap"> {text}</td>;

function WalletScreen(props) {
    const { t } = props;
    const { id } = useParams(); // maka
    const auth = useSelector(authSelector);
    const [actif, setActif] = useState();
    const [secured, setSecured] = useState();
    const [reserved, setReserved] = useState();
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [createdAt, setCreatedAt] = useState("00:00");

    // let categories = ["Actif", "Réserve", "Securisé"];
    let categories = ["active", "reserve", "secured"];

    const columns = [
        {
            field: "id",
            headerName: "id",
            hide: true,
        },
        // {
        //   field: "assets",
        //   headerName: "",
        //   with: 20,
        //   renderCell: (cellValues) => {
        //     const { asset } = cellValues.row;
        //     //return <CryptoLogo symbol="BTC" />;

        //     return <Icon i={asset.toLowerCase()} size={20} />;
        //   },
        // },
        {
            field: "asset",
            headerName: "Crypto",
            flex: 1,
        },
        {
            field: "free",
            headerName: t(`label.quantity`),
            flex: 1,
            type: "number",
            valueFormatter: ({ value }) => value.toString().replace(",", ""),
        },
        {
            field: "value",
            headerName: "≈ USDT",
            flex: 1,
            type: "number",
            renderCell: (cellValues) => {
                const { free, asset, value } = cellValues.row;
                return <span>{cellValues.row.asset === "USDT" ? free.toFixed(2) : value.toFixed(2)}</span>;
            },
        },
    ];

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getWalletDetails = async (id) => {
            try {
                const response = await axiosPrivate.get(`/api/rebalancing/walletDetails/${id}`, {
                    signal: controller.signal,
                });
                const { active, secured, reserved, dateExchange } = response.data;
                console.log("active", active, "secured", secured, "reserve", reserved);
                isMounted && setActif(active);
                isMounted && setSecured(secured);
                isMounted && setReserved(reserved);
                isMounted && setCreatedAt(dateExchange);
            } catch (error) {
                if (error.response.status == 401) {
                    navigate("/login", { state: { from: location }, replace: true });
                } else {
                    toast.error("Une erreur est survenue");
                }
                console.log("tete", error);
            }
        };

        if (auth?.role.includes(process.env.REACT_APP_CLIENT) || auth?.role.includes(process.env.REACT_APP_PARTENAIRE)) {
            getWalletDetails(auth?.id);
        } else {
            getWalletDetails(id);
        }
    }, []);

    const getTotalValue = (array) => {
        let total = 0;
        array?.forEach((a) => {
            total += a.value;
        });

        return total;
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsLoading(false);
        }, 3000); // time out after 1 second

        // clean up function
        return () => {
            clearTimeout(timeoutId);
        };
    }, []); // empty array as dependencies to run the effect only once

    return (
        <>
            {" "}
            {/* <TopBar menus={sideMenus.menusClient(t, id, auth.role)} /> */}
            <div className="flex flex-row">
                {/* <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} /> */}
                <div className="flex-1 max-w-[100%] ">
                    <div className="flex flex-col gap-y-4 p-4">
                        {/* <div className="flex gap-x-2">
              <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.wallet`)}</h1>
              <hr />
            </div> */}
                        {/* Cards */}
                        {/* <div className="">
              <div className="grid  grid-cols-2 place-items-center sm:flex sm:justify-start gap-y-2 sm:gap-x-2 justify-center mt-2">
                <DashboardCard bg={"bg-indigo-500 "} number={isLoading ? walletDetails?.test : actif && getTotalValue(actif).toFixed(2)} description={"Total Actif"} unit={"USDT"} />
                <DashboardCard bg={"bg-indigo-500 "} number={isLoading ? walletDetails?.test : reserved && getTotalValue(reserved).toFixed(2)} description={"Total Réserve"} unit={"USDT"} />
                <DashboardCard
                  bg={"bg-indigo-500 "}
                  number={isLoading ? walletDetails?.test : secured && getTotalValue(secured).toFixed(2)}
                  description={"Total Sécurisé"}
                  unit={(secured && secured[0]?.asset) || "USDT"}
                />
              </div>
            </div> */}
                        {actif && actif.length == 0 ? (
                            <BannerInfo text={t(`info.noRebal`)} type="info" noMarging />
                        ) : (
                            <div className="">
                                <BannerInfo text={`${t(`info.walletMessage`)} (${t(`label.lastUpdate`)}: ${createdAt} )`} type="success" noMarging />
                            </div>
                        )}

                        {/* TAB */}
                        <div>
                            <Tab.Group>
                                <Tab.List className={"flex space-x-1 w-full rounded bg-blue-900/20 min-w-md"}>
                                    {categories.map((category) => (
                                        <Tab
                                            key={category}
                                            className={({ selected }) =>
                                                classNames(
                                                    "w-full rounded py-2 text-sm font-medium leading-5  text-primary dark:text-slate-100 focus:outline-none transition-colors ",
                                                    selected ? "bg-white dark:bg-secondary shadow-sm  border-b-2 border-b-secondary " : " hover:bg-white/[0.12] hover:text-white "
                                                )
                                            }
                                        >
                                            {t(`label.${category}`)}
                                        </Tab>
                                    ))}
                                </Tab.List>

                                {/* Wallet Actif */}
                                <Tab.Panel>
                                    {/* <div className="my-2 flex justify-end">
                    <DashboardCard bg={"bg-slate-600"} number={walletDetails?.lastRebal} unit={"USDT"} description={`${t(`label.lastRebal`)} actif`} />
                  </div> */}
                                    {/* <div className="h-[55vh] min-w-200px bg-slate-100 ">{actif ? <TableauGrid asc column={columns} dataRows={actif} /> : <Loading />}</div> */}
                                    <div className="">
                                        {actif?.length > 0 ? (
                                            <table className="w-full bg-white ">
                                                <thead className="bg-gray-50 border-b-2 border-gray-200">
                                                    <tr className="bg-slate-100 ">
                                                        <Myth text={"Crypto"} />
                                                        <Myth text={t(`label.quantity`)} />
                                                        <Myth text={"≈ USDT"} />
                                                        <Myth text={"≈ EURO"} />
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-100">
                                                    {actif
                                                        ? actif.map((a, i) => (
                                                            <tr>
                                                                <Mytd text={a.asset} />
                                                                <Mytd text={a.free} />
                                                                <Mytd text={a.value.toFixed(2)} />
                                                                <Mytd text={a.eurValue.toFixed(2)} />
                                                            </tr>
                                                        ))
                                                        : null}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className="w-full h-20 flex items-center justify-center text-xs bg-white">{t(`label.noData`)}</div>
                                        )}
                                    </div>
                                </Tab.Panel>

                                {/* Wallet Reserve */}
                                <Tab.Panel>
                                    {/* <div className="h-[55vh] bg-slate-100">{reserved ? <TableauGrid asc column={columns} dataRows={reserved} /> : <Loading />}</div> */}
                                    <div className="">
                                        {reserved?.length > 0 ? (
                                            <table className="w-full bg-white ">
                                                <thead className="bg-gray-50 border-b-2 border-gray-200">
                                                    <tr className="bg-slate-100 ">
                                                        <Myth text={"Crypto"} />
                                                        <Myth text={t(`label.quantity`)} />
                                                        <Myth text={"≈ USDT"} />
                                                        <Myth text={"≈ EURO"} />
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-100">
                                                    {reserved?.length > 0
                                                        ? reserved.map((r, i) => (
                                                            <tr>
                                                                <Mytd text={r.asset} />
                                                                <Mytd text={r.free} />
                                                                <Mytd text={r.value.toFixed(2)} />
                                                                <Mytd text={r.eurValue.toFixed(2)} />
                                                            </tr>
                                                        ))
                                                        : null}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className="w-full h-20 flex items-center justify-center text-xs bg-white">{t(`label.noData`)}</div>
                                        )}
                                    </div>
                                </Tab.Panel>

                                {/* Wallet Sécuriser */}
                                <Tab.Panel>
                                    {/* <div className="h-[55vh] bg-slate-100">{secured ? <TableauGrid column={columns} dataRows={secured} /> : <Loading />}</div> */}
                                    <div className="">
                                        {secured?.length > 0 ? (
                                            <table className="w-full bg-white ">
                                                <thead className="bg-gray-50 border-b-2 border-gray-200">
                                                    <tr className="bg-slate-100 ">
                                                        <Myth text={"Crypto"} />
                                                        <Myth text={t(`label.quantity`)} />
                                                        <Myth text={"≈ USDT"} />
                                                        <Myth text={"≈ EURO"} />
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-100">
                                                    {secured?.length > 0
                                                        ? secured.map((s, i) => (
                                                            <tr>
                                                                <Mytd text={s.asset} />
                                                                <Mytd text={s.free} />
                                                                <Mytd text={s.value.toFixed(2)} />
                                                                <Mytd text={s.eurValue.toFixed(2)} />
                                                            </tr>
                                                        ))
                                                        : null}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className="w-full  h-20 flex items-center justify-center text-xs bg-white">{t(`label.noData`)}</div>
                                        )}
                                    </div>
                                </Tab.Panel>
                            </Tab.Group>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WalletScreen;
