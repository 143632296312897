import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { DownloadIcon } from "@heroicons/react/solid";
function FileResource(props) {
  const { pdfLink, title, color, noPadding, fit } = props;
  return (
    <div className={`${noPadding ? null : "p-2 "} w-60 md:w-fit`}>
      <a href={`${pdfLink}`} target={"_blank"} download style={{ textDecoration: "none !important" }}>
        <div className="text-slate-600 font-semibold">
                  <div style={{ textDecoration: "none" }} className={`flex flex-row items-center space-x-1 text-xs hover:scale-105 transition-all ${color ? color : "border border-sky-600 hover:bg-sky-100 "} file:hover:cursor-pointer p-1 rounded ${fit ? fit : "w-60 md:w-80"} `}>
            <DownloadIcon className="h-4 w-4 text-slate-600 mr-2" />
            {title}
          </div>
        </div>
      </a>
    </div>
  );
}

export default FileResource;
