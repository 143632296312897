import { PartyPopper } from 'lucide-react'
import React from 'react'
import { TypographyH3, TypographyH4, TypographySmall } from '../Typo'
import { useTranslation } from 'react-i18next'

function Congrats() {
    const { t } = useTranslation()
    const text = [
        // {
        //     id: 1,
        //     item: t(`obAi.obFinish`)
        // },
        {
            id: 2,
            item: t(`obAi.obOk`)
        },
        {
            id: 3,
            item: t(`obAi.rebal`)
        },
        {
            id: 4,
            item: t(`obAi.synthese`)
        },
        {
            id: 5,
            item: t(`obAi.support`)
        }

    ]
    return (
        <div>
            <TypographyH3 className="text-center ">   {t(`obAi.obFinish`)} </TypographyH3>

            <div className='flex space-x-2 mt-6'>
                <PartyPopper />
                <TypographyH4>
                    {t(`obAi.congrats`)}
                </TypographyH4>
            </div>
            {text.map((m) => (
                <div className='mt-2'>
                    <TypographySmall>
                        {m.item}
                    </TypographySmall>
                </div>
            ))}
        </div>
    )
}

export default Congrats
