import React, { useState } from "react";
import Sidebar from "../Sidebar";
import moment from "moment";
import "moment/locale/fr";
import { ViewGridIcon, UserGroupIcon } from "@heroicons/react/solid";
import Header from "../Header";
import LearnyBoxUsers from "../learnyBox/LearnyBoxUsers";
import NewForm from "../userDetails/NewForm";
import TopBar from "../TopBar";
import * as sideMenus from "../../utils/sideMenus";
import { useSelector } from "react-redux";
import { selectPlanitCardToday } from "../../features/planitSlice";
import { ControlCamera } from "@mui/icons-material";
import Controls from "../controls/Controls";
import { useFOrm } from "../useFOrm";
import { axiosPrivate } from "../../axios";
import { toast } from "react-toastify";
import { authSelector } from "../../features/authSlice";


const initialValues = {
    email: ""
};

function AddUser(props) {
    const { t } = props;
    const todayCard = useSelector(selectPlanitCardToday)
    const [isTestLoading, setIsTestLoading] = useState(false);
    const auth = useSelector(authSelector)


    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        // if ("strategy" in fieldValues) temp.strategy = fieldValues.strategy ? "" : t("error.required");
        // if ("platform" in fieldValues) temp.platform = fieldValues.platform ? "" : t("error.required");
        setErrors({
            ...temp,
        });
        console.log("Temp", temp);

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);

    const handleSearchLb = async () => {
        console.log("BTB");
        try {
            setIsTestLoading(true)
            const { data } = await axiosPrivate.post(`/api/lb`, values)
            console.log("LB ====", values);
            setIsTestLoading(false)
            toast.success(data.msg)
        } catch (error) {
            console.log("error", error);
            setIsTestLoading(false)
            toast.info(error.response.data.msg)
        }
    }


    return (
        <>
            <TopBar menus={sideMenus.menusSupport(t, todayCard.length)} />
            <div className="flex flex-row flex-grow">
                <Sidebar menus={sideMenus.menusSupport(t, todayCard.length)} />
                <div className="flex flex-col flex-1 mt-2 md:ml-52 ">
                    <div className="flex flex-col w-full p-4">
                        <div className="flex-none h-auto md:p-4 space-y-3">
                            <h1 className="font-semibold text-2xl text-slate-900 dark:text-white">{t("label.lbUsers")}</h1>
                            <span className="text-xs text-slate-500 dark:text-slate-400 ">{moment().format("Do MMMM YYYY")}</span>

                            <hr />
                        </div>

                        {auth?.id == "87e4efd1-376f-4741-8cc7-76b771b05b76" &&
                            <div className=" flex space-x-2 w-full  mr-1">
                                <Controls.Input
                                    label={t(`button.search`)}
                                    name={"email"}
                                    type="text"
                                    value={values.email}
                                    onChange={handleInputChange} />
                                <Controls.Button text={t(`button.search`)} onClick={handleSearchLb} isLoading={isTestLoading} />
                            </div>}
                        <div className="bg-slate-100 dark:bg-slate-700 min-h-[500px] shadow">
                            <LearnyBoxUsers t={t} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddUser;
