import { createSlice } from "@reduxjs/toolkit";

const initialState = []

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getallUsers: (state, action) => {
            return {
                ...state,
                all: [
                    ...action.payload
                ]
            };
        },
        getUserDetails: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        resetUserDetails: (state, action) => {
            return initialState
        },
        updateUser: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        updateUserDetails: (state, action) => {
            return {
                ...state,
                details: {
                    ...state.details,
                    ...action.payload
                }

            };
        },
        updateDoc: (state, action) => {

            return {
                ...state,
                documents: {
                    ...state.documents,
                    ...action.payload
                }

            };
        },

        login: (state) => {

        },
        logout: (state) => {

        }
    }
});

export const { login, logout, getUserDetails, updateUser, updateUserDetails, updateDoc, getallUsers , resetUserDetails } = userSlice.actions
export const userDataSelector = (state) => state.user;
export default userSlice.reducer
