import React, { useState } from 'react'
import { toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useCountDown from '../hooks/useCountDown';
import Controls from './controls/Controls'
import { useFOrm } from './useFOrm';
import BtnShad from './client/btnShadcn';

const initialValues = {
    emailFrom: '',
    emailTo: '',
    onlyDocs: false,
    onlyChild: false
};

function SwitchPlatform(props) {
    const { t } = props;
    const axiosPrivate = useAxiosPrivate()
    const { secondsLeft, start } = useCountDown()

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("emailFrom" in fieldValues) temp.emailFrom = fieldValues.emailFrom ? "" : t("error.required");
        if ("emailTo" in fieldValues) temp.emailTo = fieldValues.emailTo ? "" : t("error.required");

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);

    const formSwitch = [
        {
            type: "email",
            name: "emailFrom",
            label: "Ancien email",
            placeholder: "Enter your email",
            password: false,
            required: true,
        },
        {
            type: "email",
            name: "emailTo",
            label: "Nouveau email",
            placeholder: "Enter your email",
            password: false,
            required: true,
        },

    ];

    const handleSaveSwitch = async () => {
        if (validate()) {
            try {
                console.log("Values", values);
                await axiosPrivate.post(`/api/users/maj`, values)
                toast.success(`Les infos et filleules de 👤 ${values.emailFrom} a basculer vers 👤 ${values.emailTo} `)
                console.log("Lasa le switch ehhh 😎");
            } catch (error) {
                console.log(error);
                toast.error(error.response.data.msg)
            }
            start(0)
        }

    }

    return (
        <div>
            <h2>Changement de plateforme</h2>
            <div className='text-xs mb-4 px-6'>
                Cette étape facilite la transition des informations de l'ancien compte (via l'adresse e-mail) vers le nouveau compte, c'est-à-dire les <strong>filleuls</strong>, les <strong>documents</strong>  et le <strong>pays</strong> . Cette procédure s'applique aux comptes <strong>déjà présents</strong>  dans notre base de données.
                <br />
                <br />
                PS : Le statut de l'ancien compte changera en {"B <=> K"}
                <br />
                <span className='text-red-500 '> * Cette action est irreversible</span>
            </div>
            <div className=' flex flex-col items-center py-4 text-sm'>
                <div className=''> Veuillez cocher les éléments à basculer</div>
                <div>
                    <Controls.Checkbox name={"onlyDocs"} value={values.onlyDocs} label="Documents" onChange={handleInputChange} />
                    <Controls.Checkbox name={"onlyChild"} value={values.onlyChild} label="Filleuls" onChange={handleInputChange} />
                </div>
            </div>
            <div className='md:flex justify-center items-end  gap-x-2 '>
                {formSwitch.map((item, i) => {
                    return (
                        <div className='w-[300px]' key={i}>
                            <Controls.Input
                                fullWidth={true}
                                label={item.label}
                                value={values[item.name]}
                                onChange={handleInputChange}
                                name={item.name}
                                type={item.type}
                                error={errors[item.name]}
                            />
                        </div>
                    );
                })}
                <>
                    {secondsLeft > 0 ?
                        // <Controls.Button type="submit" color="bg-green-600 text-white" text={`${t(`button.confirm`)} (${secondsLeft} s)`} xsFont onClick={handleSaveSwitch} sendHistory={{ action: `test ` }} />
                        <BtnShad text={`${t(`button.confirm`)} (${secondsLeft} s)`} onClick={handleSaveSwitch} variant="warning" />
                        :
                        <BtnShad text={t(`button.save`)} onClick={() => start(30)} />
                        //    <Controls.Button type="submit" text={t(`button.save`)} xsFont onClick={() => start(30)} />
                    }

                </>
            </div>
        </div>
    )
}

export default SwitchPlatform
