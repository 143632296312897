import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import moment from "moment";

import "moment/locale/fr";
import { CurrencyDollarIcon, GlobeAltIcon, TrendingUpIcon, InformationCircleIcon, FilterIcon, TrashIcon, ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import DashboradCard from "../components/cards/DashboardCard";
import Title from "../components/title/Title";

import TopBar from "../components/TopBar";

import * as sideMenus from "../utils/sideMenus";
import PieChartCustom from "../components/chart/PieChartCustom";

import BarChartStackedBySign from "../components/chart/BarChartStackedBySign";
import Controls from "../components/controls/Controls";
import { addFilter, cleanFilter, filterSelector } from "../features/filterSlice";
import { useSelector, useDispatch } from "react-redux";
import SimpleLineCustom from "../components/chart/SimpleLineCustom";
import { useNavigate } from "react-router-dom";
import { dashboardDataSelector } from "../features/dashboardSlice";

moment.locale("fr");

const Myth = ({ text }) => <th className="p-3 text-sm font-semibold tracking-wide text-left"> {text}</th>;

const Mytd = ({ text }) => <td className="p-3 text-sm text-gray-700 whitespace-nowrap"> {text}</td>;
function AdminClientAnalytics(props) {
    const { t } = props;
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isDayClicked, setIsDayClicked] = useState(true);
    const [hasFilter, setHasFilter] = useState(false);
    const [isWeekClicked, setIsWeekClicked] = useState(false);
    const [isMonthClicked, setIsMonthClicked] = useState(false);
    const dispacth = useDispatch();
    const [analytics, setAnalytics] = useState();
    const [originalAnalytics, setOriginalAnalytics] = useState();
    const [firstId, setFirstId] = useState();
    const [lastId, setLastId] = useState();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getAnalytics = async () => {
            try {
                const response = await axiosPrivate.get(`/api/analytics/client`, {
                    signal: controller.signal,
                });
                let dayFormat2 = response.data.map((o) => {
                    return {
                        date: moment(o.date, "YYYY-MM-DD").format("ll"),
                        new: o.new,
                        end: o.end,
                        cummulative: o.cummulative,
                    };
                });
                isMounted && setAnalytics(dayFormat2);
                isMounted && setOriginalAnalytics(response.data);
                console.log("ANALYTICS ======", dayFormat2);

            } catch (error) {
                console.log(error);
            }
        };

        getAnalytics();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const { users } = useSelector(dashboardDataSelector);
    console.log("EEEEEeeeeeee ------------", users);

    const reset = () => {
        try {
            //setAnalytics(analytics);
            setHasFilter(false);
            dispacth(cleanFilter());
        } catch (error) { }
    };

    const showAnalyticsDay = () => {
        reset();
        let dayFormat = originalAnalytics.map((o) => {
            return {
                date: moment(o.date, "YYYY-MM-DD").format("ll"),
                new: o.new,
                end: o.end,
                cummulative: o.cummulative,
            };
        });
        setAnalytics(dayFormat);
        setIsDayClicked(true);
    };

    //onClick day de miverina 0 ny week & month
    useEffect(() => {
        if (isDayClicked) {
            setIsWeekClicked(false);
            setIsMonthClicked(false);
        }
    }, [isDayClicked]);

    const showAnalytics = () => {
        // formatage [] , get week number
        reset();
        let newAnalytics = originalAnalytics.map((a) => {
            return {
                ...a,
                new2: a.new,
                weekNumber: `${moment(a.date, "YYYY-MM-DD").week()}-${moment(a.date, "YYYY-MM-DD").format("YYYY")}`,
            };
        });

        let mergeObj = newAnalytics.reduce((p, c) => {
            const { weekNumber, date, new2, end, cummulative } = c;

            if (!(weekNumber in p)) {
                p[weekNumber] = {
                    dates: [],
                    news: [],
                    ends: [],
                    cummulatives: [],
                };
            }

            p[weekNumber].dates.push(moment(date, "YYYY-MM-DD").format("ll"));
            p[weekNumber].news.push(new2);
            p[weekNumber].ends.push(end);
            p[weekNumber].cummulatives.push(cummulative);
            return p;
        }, {});

        mergeObj = Object.keys(mergeObj).map((key, i) => {
            return {
                // data: mergeObj[key].data,
                date: `${mergeObj[key].dates[0]} - ${mergeObj[key].dates[mergeObj[key].dates.length - 1]}`,
                new: mergeObj[key].news.reduce((acc, curr) => Number(acc) + Number(curr), 0),
                end: mergeObj[key].ends.reduce((acc, curr) => Number(acc) + Number(curr), 0),
                cummulative: mergeObj[key].cummulatives[mergeObj[key].cummulatives.length - 1],
                week: key,
                id: i,
            };
        });
        setIsWeekClicked(true);
        //setAnalytics(k == null ? mergeObj : mergeObj.filter((f) => f.id >= firstId && f.id <= lastId));

        setAnalytics(mergeObj);

        //console.log("Analytics week", mergeObj);
    };

    useEffect(() => {
        if (isWeekClicked) {
            setIsDayClicked(false);
            setIsMonthClicked(false);
        }
    }, [isWeekClicked]);

    const showAnalyticsMonth = () => {
        // formatage [] , get week number
        reset();
        let newAnalytics = originalAnalytics.map((a) => {
            return {
                ...a,
                new2: a.new,
                monthNumber: `${moment(a.date, "YYYY-MM-DD").format("MM-YYYY")}`,
            };
        });

        let mergeObj = newAnalytics.reduce((p, c) => {
            const { monthNumber, date, new2, end, cummulative } = c;

            if (!(monthNumber in p)) {
                p[monthNumber] = {
                    dates: [],
                    news: [],
                    ends: [],
                    cummulatives: [],
                };
            }

            p[monthNumber].dates.push(moment(date, "YYYY-MM-DD").format("ll"));
            p[monthNumber].news.push(new2);
            p[monthNumber].ends.push(end);
            p[monthNumber].cummulatives.push(cummulative);
            return p;
        }, {});

        mergeObj = Object.keys(mergeObj).map((key, i) => {
            return {
                // data: mergeObj[key].data,
                date: `${mergeObj[key].dates[0]} - ${mergeObj[key].dates[mergeObj[key].dates.length - 1]}`,
                new: mergeObj[key].news.reduce((acc, curr) => Number(acc) + Number(curr), 0),
                end: mergeObj[key].ends.reduce((acc, curr) => Number(acc) + Number(curr), 0),
                cummulative: mergeObj[key].cummulatives[mergeObj[key].cummulatives.length - 1],
                month: key,
                id: i,
            };
        });
        // console.log("mergeObjMONth", mergeObj);
        setAnalytics(mergeObj);
        setIsMonthClicked(!isMonthClicked);
    };

    useEffect(() => {
        if (isMonthClicked) {
            setIsDayClicked(false);
            setIsWeekClicked(false);
        }
    }, [isMonthClicked]);

    //filters
    const filters = useSelector(filterSelector);

    const checkRange = (value) => {
        if (value[0] && value[1]) {
            dispacth(addFilter(value));
            setHasFilter(true);

            switch (true) {
                case isDayClicked:
                    analytics.filter((f) => f.date > moment(filters[0], "DD-MM-YYYY").format("YYYY-MM-DD") && f.date < moment(filters[1], "DD-MM-YYYY").format("YYYY-MM-DD"));
                    break;
                case isWeekClicked:
                    const startWeekNumber = `${moment(value[0], "DD-MM-YYYY").week()}-${moment(value[0], "DD-MM-YYYY").format("YYYY")}`;
                    const endWeekNumber = `${moment(value[1], "DD-MM-YYYY").week()}-${moment(value[1], "DD-MM-YYYY").format("YYYY")}`;
                    const firstIdWeek = analytics && analytics.filter((af) => af.week == startWeekNumber);

                    const lastIdWeek = analytics && analytics.filter((af) => af.week == endWeekNumber);

                    if (firstIdWeek) setFirstId(firstIdWeek[0].id);
                    if (lastIdWeek) setLastId(lastIdWeek[0].id);
                    // console.log("MY ID", firstIdWeek, lastIdWeek);
                    break;
                case isMonthClicked:
                    const startMonthNumber = `${moment(value[0], "DD-MM-YYYY").format("MM-YYYY")}`;
                    const endMonthNumber = `${moment(value[1], "DD-MM-YYYY").format("MM-YYYY")}`;
                    const firstIdMonth = analytics && analytics.filter((af) => af.month == startMonthNumber);

                    const lastIdMonth = analytics && analytics.filter((af) => af.month == endMonthNumber);

                    if (firstIdMonth) setFirstId(firstIdMonth[0].id);
                    if (lastIdMonth) setLastId(lastIdMonth[0].id);
                    // console.log("MY ID", firstIdMonth, lastIdMonth);
                    break;
                default:
                    break;
            }
        }
        if (value === null) {
            dispacth(cleanFilter());
        }
    };

    const goBack = () => navigate(-1);
    return (
        <>
            <TopBar menus={sideMenus.menusAdmin(t)} />

            <div className="flex flex-row flex-grow ">
                <Sidebar menus={sideMenus.menusAdmin(t)} />
                <div className="flex flex-row flex-1  mt-2 md:ml-52">
                    <div className="flex flex-col w-full px-2">
                        {/* Title section */}
                        <div className="flex-none h-auto md:p-4 space-y-3">
                            <div className="flex items-center ">
                                <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
                                    <ArrowNarrowLeftIcon className="h-5 w-5 hover:cursor-pointer" onClick={goBack} />
                                </div>
                                <div className="font-semibold text-2xl text-slate-900 dark:text-white">{t("label.dataAnalysis")}</div>
                            </div>
                            <span className="text-xs text-slate-500 dark:text-slate-400 ">{moment().format("Do MMMM YYYY")}</span>

                            <hr />
                        </div>

                        <div className="flex w-auto space-x-2">
                            {/*Tableau de Bord*/}
                            <div className="flex-1 space-y-2 ">
                                {/* Cards */}
                                <div className=" flex flex-col space-y-2  p-4 bg-slate-100">
                                    <Title.SectionTitle
                                        text={"Générales"}
                                        icon={
                                            <div className="flex space-x-2 items-center">
                                                <GlobeAltIcon className="h-5 w-5 text-blue-700 text-xs" />
                                                <InformationCircleIcon className="h-4 w-4 text-slate-600 hover:cursor-pointer" onClick={() => setIsOpen(true)} />
                                            </div>
                                        }
                                    />
                                    <div className="flex gap-x-3 rounded-sm">
                                        <div className="w-20 text-indigo-500 text-sm text-center font-bold">Total</div>
                                        <DashboradCard number={users && users.active} description={t("label.sum")} />
                                    </div>
                                    <div className="flex gap-x-3 rounded-sm">
                                        <div className="w-20 text-cyan-600 text-sm text-center font-bold">CBDT</div>
                                        <DashboradCard
                                            number={users && users.activeCbdt}
                                            description={t("label.userActif")}
                                        />
                                        <DashboradCard
                                            number={users && users.notRebalancedCbdt}
                                            description={t("label.onBoardingInProgress")}
                                        />
                                        <DashboradCard
                                            number={users && users.problemsCbdt}
                                            description={t("label.withProblem")}
                                        />
                                        <DashboradCard number={users && users.inactiveCbdt} description={t("label.unsuscribe")} />
                                    </div>

                                    <div className="flex gap-x-3 rounded-sm">
                                        <div className="w-20 text-cyan-400 text-sm text-center font-bold">AI style</div>
                                        {/* <DashboradCard number={users && users.all} description={t("label.sum")} /> */}
                                        <DashboradCard
                                            number={users && users.activeAI}
                                            description={t("label.userActif")}
                                        />
                                        <DashboradCard
                                            number={users && users.notRebalancedAi}
                                            description={t("label.onBoardingInProgress")}
                                        />
                                        <DashboradCard
                                            number={users && users.problemsAi}
                                            description={t("label.withProblem")}
                                        />
                                        <DashboradCard number={users && users.inactiveAI} description={t("label.unsuscribe")} />
                                    </div>
                                </div>

                                {/* BarChartChurn */}
                                <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm ">
                                    <div className="flex space-x-3 py-2 justify-end mb-3">
                                        <div className="flex-1">
                                            <div className="flex space-x-2 items-center">
                                                <FilterIcon className=" flex-none w-4 h-4 text-blue-500" />
                                                <Controls.RangePicker
                                                    onCalendarChange={(value) => checkRange(value)}
                                                    defaultStart={(filters && filters[0]) || ""}
                                                    defaultEnd={(filters && filters[1]) || ""}
                                                />
                                                {hasFilter && (
                                                    <div className="bg-red-500 p-1 rounded hover:cursor-pointer">
                                                        <TrashIcon className="h-4 w-4 text-white" onClick={reset} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <Controls.Button xsFont small variant="none" color={isDayClicked ? "bg-sky-400 text-white" : "bg-blue-600 text-white"} onClick={showAnalyticsDay} text={t("label.day")} />
                                        <Controls.Button xsFont small variant="none" color={isWeekClicked ? "bg-sky-400 text-white" : "bg-blue-600 text-white"} onClick={showAnalytics} text={t("label.week")} />
                                        <Controls.Button xsFont small variant="none" color={isMonthClicked ? "bg-sky-400 text-white" : "bg-blue-600 text-white"} onClick={showAnalyticsMonth} text={t("label.month")} />
                                    </div>

                                    <Title.SectionTitle text={t("label.NbNewUserAndUnsuscribe")} icon={<TrendingUpIcon className="h-5 w-5 text-blue-700 text-xs" />} />
                                    <div className="h-fit " onClick={() => setIsOpen(true)}>
                                        <BarChartStackedBySign
                                            data={
                                                analytics && hasFilter && firstId && lastId
                                                    ? analytics.filter((f) => f.id >= firstId && f.id <= lastId)
                                                    : analytics && hasFilter && isDayClicked
                                                        ? analytics.filter((f) => f.date > moment(filters[0], "DD-MM-YYYY").format("ll") && f.date < moment(filters[1], "DD-MM-YYYY").format("ll"))
                                                        : analytics
                                            }
                                            // width={300}
                                            height={300}
                                            keyLength={["date", "new", "end", "cummulative", "week", "id"]}
                                            colors={["#3b82f6", "#f87171"]}
                                            name={[t("label.newUser"), t("label.unsuscribe")]}
                                        />
                                    </div>
                                    <Title.SectionTitle
                                        text={"Evolutions clients actifs"}
                                        icon={<TrendingUpIcon className="h-5 w-5 text-blue-700 text-xs" />}
                                    />
                                    <div className="h-fit " onClick={() => setIsOpen(true)}>
                                        <SimpleLineCustom
                                            data={
                                                analytics && hasFilter && firstId && lastId
                                                    ? analytics.filter((f) => f.id >= firstId && f.id <= lastId)
                                                    : analytics && hasFilter && isDayClicked
                                                        ? analytics.filter((f) => f.date > moment(filters[0], "DD-MM-YYYY").format("ll") && f.date < moment(filters[1], "DD-MM-YYYY").format("ll"))
                                                        : analytics
                                            }
                                            // width={300}
                                            height={300}
                                            keyLength={["date", "new", "end", "cummulative", "week", "id"]}
                                            colors={["#3b82f6", "#f87171"]}
                                        />
                                    </div>
                                </div>
                            </div>{" "}
                            {/* Objectifs */}
                            <div className="bg-slate-100 w-[250px] py-4 h-fit">
                                <h1 className="font-semibold text-center text-2xl  text-slate-900 dark:text-white">
                                    {t("label.goal")} <p className="text-xs">{moment().format("YYYY")}</p>
                                </h1>

                                <div>
                                    <div className="bg-slate-100 dark:bg-slate-800 p-4 rounded-sm ">
                                        <Title.SectionTitle text={t("label.monthlyCA")} unit={"(EUR)"} icon={<CurrencyDollarIcon className="h-5 w-5 text-blue-700 text-xs" />} />
                                    </div>
                                    <div className=" w-full -mt-16 h-40">
                                        <PieChartCustom
                                            data={[
                                                { name: t("label.actually"), value: users && (users.all - users.inactive) * 27 },
                                                { name: t("label.goal"), value: 1000 * 27 },
                                            ]}
                                            width={100}
                                            height={300}
                                            startAngle={180}
                                            endAngle={0}
                                            outerRadius={100}
                                            colors={["#3730a3", "#3b82f6"]}
                                            noLegend
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminClientAnalytics;
