import { dataError } from 'bfj/src/events';
import React, { useEffect, useState } from 'react'
import { axiosBQ } from '../axios';
import { TypographyH3, TypographySmall, TypographyXS } from '../components/Typo';
import { DataTable } from '../components/client/data-table';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import BtnShad from '../components/client/btnShadcn';
import axios from 'axios';

function ListActionFromBigQuery() {
    const [history, setHistory] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation()

    const getHistory = async () => {
        try {
            // TENA IZY
            // const { data } = await axios.get(`https://bg.flow-corporation.space/history/action-logs/find?func=all_desc`)
            // DEV
            const { data } = await axiosBQ.get(`${process.env.REACT_APP_BASE_URL_BQ}/action-logs/find?func=all_desc`)
            console.log("BIG QUERY", data);


            setHistory(data);

        } catch (error) {
            console.log(error);

        }
    };

    useEffect(() => {
        getHistory()
    }, [])


    const columns = [
        {
            accessorKey: "date",
            header: "Date",
            cell: ({ row: { original } }) => {
                const date = original.date;
                return moment(date).format("YYYY-MM-DD HH:mm:ss")
            }
        },
        {
            accessorKey: "button",
            header: "Bouton"
        },
        {
            accessorKey: "action",
            header: "Actions"
        },
        {
            accessorKey: "location",
            header: "Path"
        },
        {
            accessorKey: "who",
            header: "pour qui"
        },
        {
            accessorKey: "by",
            header: "par qui",
            cell: ({ row: { original } }) => {
                const by = original.by;

                const namesMap = {
                    "8dc2a399-7e40-410e-8483-26c7d2a00d60": "Kim",
                    "87e4efd1-376f-4741-8cc7-76b771b05b76": "Sitraka",
                    "0e5c7589-ca34-4226-ae57-74bdf49789c2": "Mombris",
                    "11e88e9f-7a99-4123-9323-4b59d39e755d": "Melanie",
                    "14e022fc-3a6c-4257-8f30-8c1bfce6dbe8": "Annaelle",
                    "3cc53c7c-7bf9-4bd8-8d95-c284bc10e762": "Damien",
                    "ed3dd483-8cd6-4f8f-97f7-03cee5d3ae7f": "Treesi"
                };

                const name = namesMap[by] || by;

                return <TypographyXS>{name}</TypographyXS>;

            }
        },
        {
            accessorKey: "role",
            header: "Role",
            cell: ({ row: { original } }) => {
                const role = original.role;

                if (role == "3329") {
                    return <TypographyXS>Support</TypographyXS>
                } else if (role == "2") {
                    return <TypographyXS>Client</TypographyXS>
                } else if (role == "1135") {
                    return <TypographyXS>SuperAdmin</TypographyXS>
                }
            }
        },

    ]

    const testLoad = () => {
        console.log("Manomboka ary");
        setIsLoading(true);

        setTimeout(() => {
            console.log("ato");
            setIsLoading(false);
        }, 5000);
        console.log("Vita");
    }

    return (
        <div className="p-4  ">
            <div className=" bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 h-full ">
                <TypographyH3>Historique from Big Query</TypographyH3>
                <BtnShad text="test load" isLoading={isLoading} onClick={testLoad} />
                <div className="container mx-auto ">
                    <DataTable columns={columns} data={history?.length > 0 ? history : []} setCurrentPage={setCurrentPage} currentPage={currentPage} />
                </div>
            </div>
        </div>
    )
}

export default ListActionFromBigQuery
