import React, { useState, useEffect, useMemo, useCallback } from "react";
import Controls from "../components/controls/Controls";
import moment from "moment";
import case_1 from "../utils/case_1.json";
import case_2 from "../utils/case_2.json";
//import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Loading from "../components/Loading";
import { useParams } from "react-router-dom";
import { authSelector } from "../features/authSlice";
import { useSelector } from "react-redux";
import MyLineAdminSimulator from "../components/chart/MyLineAdminSimulator";
import MyLineAdminSimulator2 from "../components/chart/MyLineAdminSimulator2";
import MyLineAdminSimulator3 from '../components/chart/MyLineAdminSimulator3';
import MyLineAdminSimulator4 from '../components/chart/MyLineAdminSimulator4';
import MyLineAdminSimulator5 from "../components/chart/MyLineAdminSimulator5";
import MyLineAdminSimulator4V2 from "../components/chart/MyLineAdminSimulator4V2";
import MyLineAdminSimulator5V2 from "../components/chart/MyLineAdminSimulator5V2";

const SimulatorScreenAdmin = (props) => {
    const { t } = props;
    const { id } = useParams();
    const auth = useSelector(authSelector);
    const [tokens, setTokens] = useState([
        { id: "BTC", title: "BTC" },
        { id: "ETH", title: "ETH" },
        { id: "BNB", title: "BNB" },
        { id: "ADA", title: "ADA" },
        { id: "DOT", title: "DOT" },
        { id: "XRP", title: "XRP" },
    ]);

    const [values, setValues] = useState({
        actifK: 10000,
        totalK: 10000,
        reserveK: 0,
        recurringK: 300,
        startDate: "2016-08-01",
        endDate: moment().format('YYYY-MM-DD')
    });

    /* var fromDate = moment("29/05/2016", "DD/MM/YYYY");
       var toDate = moment('11/08/2022', "DD/MM/YYYY");
       var results = enumerateDaysBetweenDates(fromDate, toDate);*/
    const axiosPrivate = useAxiosPrivate();
    const [originaldata, setOriginaldata] = useState();
    const [dataRow, setDataRow] = useState();
    const [isLoading, setIsLoading] = useState(false);
    //const calculation =  useMemo(() => formatdata(cbindex), [dataNumber]);

    /*useEffect(()=>{
          console.log("calculation")

      },[calculation])*/
    //startTransition(()=>setDataRow(calculation))

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getData = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/indexHistory/getAllRow`, {
                    signal: controller.signal,
                });

                let formatOriginalData = await data.map((d) => {
                    return {
                        id: d.id,
                        date: d.date,
                        index: Number(JSON.parse(d.evolution).index).toFixed(0),
                        mm20m: d.mm20m,
                        bbsup: d.bbsup,
                        bbinf: d.bbinf,
                        order: d.order,
                        coefOrder: d.coefOrder,
                        indexCB: Number(JSON.parse(d.evolution).indexCB).toFixed(0),
                    };
                });

                let formatData = {
                    labels: data.map((d) => d.date),
                    index: data.map((d) => JSON.parse(d.evolution).index),
                    mm20m: data.map((d) => d.mm20m),
                    bbsup: data.map((d) => d.bbsup),
                    bbinf: data.map((d) => d.bbinf),
                };

                //isMounted && dispatch(setListIndex(formatData))
                //isMounted && startTransition(()=>setDataRow(formatData))
                isMounted && setDataRow(formatData);
                isMounted && setOriginaldata(formatOriginalData);
            } catch (error) {
                console.log("ewfef", error);
            }
        };
        //getData()

        const getData2 = async () => {
            try {
                const query = `{ getRowdata(id: 1) { id date bbinf bbsup evolution order mm20m coefOrder temperateOrder } }`;
                const { data } = await axiosPrivate.post(`/graphql`, {
                    signal: controller.signal,
                    query,
                });
                let formatOriginalData = await data.data.getRowdata.map((d) => {
                    return {
                        id: d.id,
                        date: d.date,
                        index: Number(JSON.parse(d.evolution).index).toFixed(0),
                        indexCB: Number(JSON.parse(d.evolution).indexCB).toFixed(0),
                        percentageEvolution: Number(JSON.parse(d.evolution).percentageEvolution),
                        mm20m: d.mm20m,
                        bbsup: d.bbsup,
                        bbinf: d.bbinf,
                        order: d.order,
                        coefOrder: d.coefOrder,
                        temperateOrder: d.temperateOrder,
                    };
                });
                let formatData = {
                    labels: data.data.getRowdata.map((d) => d.date),
                    index: data.data.getRowdata.map((d) => JSON.parse(d.evolution).index),
                    indexCB: data.data.getRowdata.map((d) => JSON.parse(d.evolution).indexCB),
                    percentageEvolution: data.data.getRowdata.map((d) => JSON.parse(d.evolution).percentageEvolution),
                    mm20m: data.data.getRowdata.map((d) => d.mm20m),
                    bbsup: data.data.getRowdata.map((d) => d.bbsup),
                    bbinf: data.data.getRowdata.map((d) => d.bbinf),
                    order: dataRow && data.data.getRowdata.map((d) => d.order),
                    coefOrder: dataRow && data.data.getRowdata.map((d) => d.coefOrder),
                    temperateOrder: dataRow && data.data.getRowdata.map((d) => d.temperateOrder),
                };

                isMounted && setDataRow(formatData);
                isMounted && setOriginaldata(formatOriginalData.filter((f) => f.id > 639));

                console.log("resp", data.data.getRowdata);
            } catch (error) {
                console.log("err", error);
            }
        };
        //getData2();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const [cbd, setCbd] = useState()
    const getCBD = async () => {
        const { data } = await axiosPrivate.get(`/api/rowdata/getDataCdb-simulator/${values.actifK}/${values.recurringK}/${values.totalK}/?start=${values.startDate}&end=${values.endDate}`)
        setCbd(data)
    }

    useEffect(() => {
        console.log("LALAL", values);
        getCBD()
    }, [values])

    const handleSimulation = () => {
        setIsLoading(true)
        setValues({
            actifK: 10000,
            totalK: 10000,
            reserveK: 0,
            recurringK: 300,
            startDate: "2016-08-01",
            endDate: moment().format('YYYY-MM-DD')
        })
        setIsLoading(false)

    }

    return (
        <div className="p-4 flex flex-col gap-3 h-[93vh]">
            <div className="bg-white p-4  rounded-lg flex flex-col  gap-y-3 w-full">
                <h3 className="font-bold text-sm text-slate-700">{t("label.settings")}</h3>

                <div className="flex  gap-5">
                    {cbd ? (
                        <>
                            <Controls.Input name="totalK" label={"Total Capital"} value={values.totalK} type="number" onChange={(e) => setValues({ ...values, totalK: Number(e.target.value) })} />
                            <Controls.Input name="actifK" label={"Capital actif"} value={values.actifK} type="number" onChange={(e) => setValues({ ...values, actifK: Number(e.target.value) })} />
                            <Controls.Input
                                name="reserveK"
                                label={"Capital de réserve"}
                                value={values.totalK - values.actifK <= 0 ? 0 : values.totalK - values.actifK}
                                type="number"
                                readOnly
                                onChange={(e) => setValues({ ...values, reserveK: Number(e.target.value) })}
                            />
                            <Controls.Input name="recurringK" label={"Ajout récurrent"} value={values.recurringK} type="number" onChange={(e) => setValues({ ...values, recurringK: Number(e.target.value) })} />
                            <Controls.Input name="startDate" label={"Date de début"} value={values.startDate} type="date" min={'2016-08-01'} max={moment().format('YYYY-MM-DD')} onChange={(e) => setValues({ ...values, startDate: (e.target.value) })} />
                            <Controls.Input name="endDate" label={"Date de fin"} value={values.endDate} type="date" min={'2016-08-01'} max={moment().format('YYYY-MM-DD')} onChange={(e) => setValues({ ...values, endDate: (e.target.value) })} />

                            <Controls.Button text="Réinitialiser " onClick={handleSimulation} small xsFont isLoading={isLoading} />
                        </>
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>

            <div className="flex-1 bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 ">
                <h3 className="font-bold text-sm text-slate-700 dark:text-slate-100">Simulateur</h3>
                <div className="flex flex-row justify-center">
                    <div className="w-full h-[60vh]">{cbd ? <MyLineAdminSimulator3 values={values && values} cbdData={cbd} data={originaldata && originaldata} /> : <Loading />}</div>
                </div>
            </div>



            {/* <div className="flex-1 bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 ">
                <h3 className="font-bold text-sm text-slate-700 dark:text-slate-100"></h3>
                <div className="flex flex-row justify-center">
                    <div className="w-full h-[60vh]">{originaldata ? <MyLineAdminSimulator4 values={values && values} cbData={cbd} data={originaldata && originaldata} /> : <Loading />}</div>

                    <div className="w-full h-[60vh]">{originaldata ? <MyLineAdminSimulator5 values={values && values} cbData={cbd} data={originaldata && originaldata} /> : <Loading />}</div>
                </div>
            </div> */}
            <div className="flex-1 bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 ">
                <h3 className="font-bold text-sm text-slate-700 dark:text-slate-100"></h3>
                <div className="flex flex-row justify-center">
                    <div className="w-full h-[60vh]">{cbd ? <MyLineAdminSimulator4V2 values={values && values} cbData={cbd} data={originaldata && originaldata} /> : <Loading />}</div>

                    <div className="w-full h-[60vh]">{cbd ? <MyLineAdminSimulator5V2 values={values && values} cbData={cbd} data={originaldata && originaldata} /> : <Loading />}</div>
                </div>
            </div>

        </div>
    );
};

export default SimulatorScreenAdmin;
