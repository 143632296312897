import React, { useState, useEffect } from "react";
import Controls from "../components/controls/Controls";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Loading from "../components/Loading";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { authSelector } from "../features/authSlice";
import { useSelector } from "react-redux";
import TopBar from "../components/TopBar";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import MyLineCapital from "../components/chart/MyLineCapital";
import MyLineQty from "../components/chart/MyLineQty";
import DashboardCard from "../components/cards/DashboardCard";
import { toast } from "react-toastify";
import * as service from "../utils/service";
import Evolution from "../components/Evolution";

/*
const expensiveCalculation = (dataRow) => {

    let newArray = []
    let previous10k;
    dataRow && dataRow?.sort((a, b) => a.id - b.id).map((d, i) => {
        console.log("HERe", d.date)


        if (d.id == 669) {
            console.log("669", d.id)
            previous10k = 10000
            let tempObj = {
                ...d,
                cb10k: 10000
            }
            newArray.push(tempObj)
        }

        if (d.id > 669) {
            console.log(">669 id", d.id)
            // let previousIndex = dataRow.filter(f => f.id == (d.id - 1))
            //  console.log(">669" ,previousIndex )
            let tempcb10k = Number((Number(previous10k) * Number(d.index)) / Number(dataRow[i - 1].index))
            previous10k = tempcb10k
            console.log("previous10k", previous10k)
            let tempObj2 = {
                ...d,
                cb10k: tempcb10k
            }
            newArray.push(tempObj2)
        }

    })

    return newArray
};


const expensiveCalculation2 = (dataRow, values) => {

    let newArray = []
    let previous10k;
    console.log("HERe", values)
    dataRow && dataRow?.sort((a, b) => a.id - b.id).map((d, i) => {
        //console.log("HERe" , d.date )

        if (d.id == 669) {
            //console.log("669" , d.id )
            previous10k = values.totalK
            let temp0 = {
                ...d,
                cb10k: previous10k
            }
            newArray.push(temp0)
        } else if (d.id > 669) {
            //console.log(">669 id" , d.id )
            let tempcb10k = Number((Number(previous10k) * Number(d.index)) / Number(dataRow[i - 1].index))
            previous10k = tempcb10k
            let temp1 = {
                ...d,
                cb10k: tempcb10k
            }
            newArray.push(temp1)
        } else {

            let temp2 = {
                ...d,
                cb10k: null
            }
            newArray.push(temp2)
        }

        //return d

    })

    let formatData = {
        labels: newArray && newArray.map(d => d.date),
        index: newArray && newArray.map(d => d.index),
        mm20m: newArray && newArray.map(d => d.mm20m),
        bbsup: newArray && newArray.map(d => d.bbsup),
        bbinf: newArray && newArray.map(d => d.bbinf),
        cb10k: newArray && newArray.map(d => d.cb10k)
    }


    return formatData
};*/

const EvolutionScreen = (props) => {
    const { t } = props;
    const { id } = useParams();
    const auth = useSelector(authSelector);
    const location = useLocation();

    const [tokens, setTokens] = useState([
        { id: "BTC", title: "BTC" },
        { id: "ETH", title: "ETH" },
        { id: "BNB", title: "BNB" },
        { id: "ADA", title: "ADA" },
        { id: "DOT", title: "DOT" },
        { id: "XRP", title: "XRP" },
    ]);

    const [values, setValues] = useState({
        actifK: 5000,
        totalK: 1000,
        reserveK: 0,
        recurringK: 150,
    });
    const axiosPrivate = useAxiosPrivate();
    // const [originaldata, setOriginaldata] = useState();
    // const [dataRow, setDataRow] = useState();
    // const [dataAf, setDataAf] = useState();
    // const [dataCapital, setDataCapital] = useState();
    // const [recalculate, setRecalculate] = useState(false);
    // const [graphType, setGraphType] = useState("logarithmic");
    // const [isVideoOpen, setIsVideoOpen] = useState(false);
    // const [actif, setActif] = useState();
    // const [secured, setSecured] = useState();
    // const [reserved, setReserved] = useState();
    // const [walletDetails, setWalletDetails] = useState();
    const [isLoading, setIsLoading] = useState(true);
    // const [addedK, setAddedK] = useState(0);
    // const [totalInvested, setTotalInvested] = useState(0);
    const [idToShow, setIdToShow] = useState()

    const navigate = useNavigate();

    const [summaryRebal, setSummaryRebal] = useState({
        startDate: "DD/MM/YYYY",
        endDate: "DD/MM/YYYY",
        endCapitalRebal: "0.00",
        startCapital: "0.00",
        recurringAmount: "0.00",
    });

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        /* const getData = async () => {
           try {
             const { data } = await axiosPrivate.get(`/api/indexHistory/getAllRow`, {
               signal: controller.signal,
             });

             let formatOriginalData = await data.map((d) => {
               return {
                 id: d.id,
                 date: d.date,
                 index: JSON.parse(d.evolution).index,
                 mm20m: d.mm20m,
                 bbsup: d.bbsup,
                 bbinf: d.bbinf,
                 order: d.order,
                 coefOrder: d.coefOrder,
               };
             });

             let formatData = {
               labels: data.map((d) => d.date),
               index: data.map((d) => JSON.parse(d.evolution).index),
               mm20m: data.map((d) => d.mm20m),
               bbsup: data.map((d) => d.bbsup),
               bbinf: data.map((d) => d.bbinf),
             };
             isMounted && setDataRow(formatData);
             isMounted && setOriginaldata(formatOriginalData);
           } catch (error) {
             console.log("ewfef", error);
           }
         };*/

        // const getUser = async (myId) => {
        //     try {
        //         const { data } = await axiosPrivate.get(`/api/users/genericFindOne/${myId}`, {
        //             signal: controller.signal,
        //         });
        //         const decrypt = service.decryptOneUserInfo(data.oneUser);

        //         // console.log("test", data);

        //         if (decrypt.rebalancing.length > 0) {
        //             isMounted &&
        //                 setSummaryRebal({
        //                     startDate: decrypt.rebalancing[0]?.date,
        //                     endDate: decrypt.rebalancing[decrypt.rebalancing.length - 1]?.date,
        //                     endCapitalRebal: JSON.parse(decrypt.rebalancing[decrypt.rebalancing.length - 1].totalAssets).usdt || 0,
        //                     startCapital: Number(JSON.parse(decrypt.rebalancing[0].totalAssets).usdt).toFixed(2) || 0,
        //                     recurringAmount: Number(decrypt.details.recurringAmount).toFixed(2) || 0,
        //                 });
        //         }
        //         //dispacth(getUserDetails(data));
        //     } catch (error) {
        //         //console.log("error", error);
        //         if (error.response.status != 403) {
        //             navigate("/login", { state: { from: location }, replace: true });
        //         }
        //     }
        // };

        // const getTotalInvested = async (myid) => {
        //     try {
        //         const response = await axiosPrivate.get(`/api/users/totalInvested/${myid}`, {
        //             signal: controller.signal,
        //         });
        //         if (response.data) {
        //             console.log("Total Invested", response.data);
        //         }
        //         const seuil = response.data.totalInvested;
        //         setTotalInvested(seuil);
        //         setAddedK(response.data.addedK);
        //     } catch (error) { }
        // };

        // const getData2 = async (myId) => {
        //     try {
        //         const lastRebalDay = await axiosPrivate.get(`/api/emails/getLast/${myId}`);

        //         const query = `{ getRowdata(id: ${lastRebalDay.data.day}) { id date bbinf bbsup evolution order mm20m coefOrder temperateOrder coefBbsupIndex } }`;
        //         const { data } = await axiosPrivate.post(`/graphql`, {
        //             signal: controller.signal,
        //             query,
        //         });
        //         let formatOriginalData = await data.data.getRowdata.map((d) => {
        //             return {
        //                 id: d.id,
        //                 date: d.date,
        //                 index: Number(JSON.parse(d.evolution).index).toFixed(0),
        //                 indexCB: Number(JSON.parse(d.evolution).indexCB).toFixed(0),
        //                 percentageEvolution: Number(JSON.parse(d.evolution).percentageEvolution),
        //                 mm20m: d.mm20m,
        //                 bbsup: d.bbsup,
        //                 bbinf: d.bbinf,
        //                 order: d.order,
        //                 coefOrder: d.coefOrder,
        //                 coefBbsupIndex: d.coefBbsupIndex,
        //                 temperateOrder: d.temperateOrder,
        //             };
        //         });
        //         // let formatData = {
        //         //   labels: data.data.getRowdata.map((d) => d.date),
        //         //   index: data.data.getRowdata.map((d) => JSON.parse(d.evolution).index),
        //         //   indexCB: data.data.getRowdata.map((d) => JSON.parse(d.evolution).indexCB),
        //         //   percentageEvolution: data.data.getRowdata.map((d) => JSON.parse(d.evolution).percentageEvolution),
        //         //   mm20m: data.data.getRowdata.map((d) => d.mm20m),
        //         //   bbsup: data.data.getRowdata.map((d) => d.bbsup),
        //         //   bbinf: data.data.getRowdata.map((d) => d.bbinf),
        //         //   order: dataRow && data.data.getRowdata.map((d) => d.order),
        //         //   coefOrder: dataRow && data.data.getRowdata.map((d) => d.coefOrder),
        //         //   coefBbsupIndex: dataRow && data.data.getRowdata.map((d) => d.coefBbsupIndex),
        //         //   temperateOrder: dataRow && data.data.getRowdata.map((d) => d.temperateOrder),
        //         // };

        //         // isMounted && setDataRow(formatData);
        //         isMounted && setOriginaldata(formatOriginalData.filter((f) => f.id > 639));

        //         // console.log("resp", data.data.getRowdata);
        //     } catch (error) {
        //         console.log("err", error);
        //     }
        // };

        // const getAf = async (myId) => {
        //     try {
        //         const response = await axiosPrivate.get(`/api/rebalancing/findBy/af/${myId}`, {
        //             signal: controller.signal,
        //         });
        //         isMounted && setDataAf(response.data);

        //         console.log("sddddddddd===============", response.data);

        //         isMounted && setDataCapital(response.data.filter((f) => f.capital !== null));
        //     } catch (error) {
        //         console.log("here", error);
        //     }
        // };

        // const getWalletDetails = async (id) => {
        //     try {
        //         const response = await axiosPrivate.get(`/api/rebalancing/walletDetails/${id}`, {
        //             signal: controller.signal,
        //         });
        //         const { active, secured, reserved, dateExchange } = response.data;
        //         isMounted && setActif(active);
        //         isMounted && setSecured(secured);
        //         isMounted && setReserved(reserved);
        //         console.log("=========fgafs======", dateExchange);
        //     } catch (error) {
        //         if (error.response.status == 401) {
        //             navigate("/login", { state: { from: location }, replace: true });
        //         } else {
        //             toast.error(t(`error.errorOccurred`));
        //         }
        //         console.log("tete", error);
        //     }
        // };

        // getData()
        //getData2();
        if (auth?.role.includes(process.env.REACT_APP_CLIENT) || auth?.role.includes(process.env.REACT_APP_PARTENAIRE)) {
            // getAf(auth?.id);
            //getData2(auth?.id);
            // getUser(auth?.id);
            // getWalletDetails(auth?.id);
            // getTotalInvested(auth?.id);
            setIdToShow(auth?.id)
        } else {
            // getAf(id);
            // getData2(id);
            // getUser(id);
            // getWalletDetails(id);
            // getTotalInvested(id);
            setIdToShow(id)
        }

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    /* const handleSimulation = () => {
           setRecalculate(!recalculate)
       }*/

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsLoading(false);
        }, 2000); // time out after 1 second

        // clean up function
        return () => {
            clearTimeout(timeoutId);
        };
    }, []); //

    const getTotalValue = (array) => {
        let total = 0;
        array?.forEach((a) => {
            total += a.value;
        });

        return total;
    };

    return (
        <>
            <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />
            <div className="flex flex-row flex-grow">
                <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />
            </div>
            <div className="flex flex-row flex-1 mt-2 md:ml-52 ">
                <div className="p-4 flex-1 gap-3 h-[93vh]">
                    <div className="  dark:bg-slate-800 gap-y-5 rounded-lg  ">
                        <div className="flex space-x-4 items-center pb-2 ">
                            <div className="font-bold text-2xl text-slate-700 dark:text-slate-100">{t("label.evolution")}</div>
                        </div>



                        {/* => summary */}
                        {/* <div className="flex flex-wrap justify-end gap-x-2 my-2">
                            <DashboardCard
                                bg={"bg-blue-600"}
                                description={t(`label.initialCapital`)} // Capital Initial
                                number={summaryRebal && summaryRebal?.startCapital}
                                unit={"USDT"}
                            />
                            <DashboardCard
                                bg={"bg-blue-600"}
                                description={t(`label.depositTotal`)} // Capital Réccurent
                                number={Number(addedK).toFixed(2) || 0}
                                unit={"USDT"}
                            />
                            <DashboardCard
                                bg={"bg-blue-600"}
                                description={t(`label.sumTotalInvested`)} // Capital Réccurent
                                number={((summaryRebal && Number(summaryRebal?.startCapital)) + (addedK || 0)).toFixed(2)}
                                unit={"USDT"}
                            />
                        </div> */}

                        <div className="flex flex-col justify-center gap-y-5">
                            {/* <div className="flex space-x-2 hidden">
                                <Controls.Button small color={`${graphType == "linear" ? "bg-gray-100" : ""} `} xsFont text="Linear" onClick={() => setGraphType("linear")} />
                                <Controls.Button small color={`${graphType == "logarithmic" ? "bg-gray-100" : ""} `} xsFont text="Logarithmic" onClick={() => setGraphType("logarithmic")} />
                            </div> */}


                            {/* <div className="w-full h-[400px] bg-white dark:bg-slate-800 p-2 rounded">
                {originaldata ? <EvolutionLineIndexUser graphType={"logarithmic"} values={values && values} data={originaldata && originaldata} /> : <Loading />}
              </div> */}
                            {/* <div className="flex flex-col space-y-5 md:flex-row  md:gap-x-5 md:space-y-0">
                                <div className=" w-full md:w-1/2 h-[70vh] bg-white dark:bg-slate-800 p-2 rounded">

                                    {dataCapital ? <MyLineCapital graphType={"logarithmic"} values={values && values} data={dataCapital && dataCapital} /> : <Loading />}
                                </div>

                                <div className="w-full md:w-1/2 h-[70vh] bg-white dark:bg-slate-800 p-2 rounded">
                                    {dataCapital ? <MyLineQty graphType={"linear"} values={values && values} data={dataCapital && dataCapital} /> : <Loading />}
                                </div>
                            </div> */}

                            <Evolution id={idToShow} />


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EvolutionScreen;
